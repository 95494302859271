





























import { Vue, Component, Prop } from 'vue-property-decorator';
import { VbTooltipSlot } from 'bag-of-holding-library';

@Component({ components: { VbTooltipSlot } })
export default class FilePickerButton extends Vue {
  @Prop() text!: String;

  @Prop() color!: String;

  @Prop() dark!: Boolean;

  @Prop() font?: Boolean;

  @Prop() disabled?: Boolean;

  @Prop() tipText?: string;

  @Prop() ariaLabel!: string;

  @Prop({
    required: true,
    default: false,
  })
  isUploading!: boolean;

  ref: any = this.$refs;

  onButtonClick() {
    window.addEventListener('focus', () => {
    }, { once: true })

    this.ref.uploader.click()
  }

  onFileChanged(event: any) {
    this.$emit('fileSelected', event.target.files[0])
    event.target.files = null;
  }
}

