


























import { VbSnackbarService } from 'bag-of-holding-library';
import { Vue, Component, Prop } from 'vue-property-decorator';
import Formation from '@/domain/drd/entity/formation.entity';
import DrdService from '@/domain/drd/service/drd.service';
import AddButton from '@/shared/buttons/add-button.component.vue';
import Loader from '@/views/drd/commons/loader.component.vue';
import FormationComponent from '@/views/drd/form/tabs/formation/formation.component.vue';

@Component({
  components: {
    AddButton,
    FormationComponent,
    Loader,
  },
})
export default class FormationIndex extends Vue {
  @Prop({
    type: String,
    required: true,
  })
  private performanceDescriptionId!: string;

  private loading: boolean = true;

  private formations: Formation[] = [];

  private addNewFormation(): void {
    this.formations.push(new Formation());
  }

  private deleteFormation(index: number): void {
    this.formations.splice(index, 1);
  }

  private updateField(index: number, field: string, value: any): void {
    this.formations[index][field] = value;
  }

  private save(): Promise<void> {
    const { form }: any = this.$refs;
    return new Promise((resolve, reject) => {
      if (!form.validate()) {
        reject();
        return;
      }
      DrdService.updateFormations(this.performanceDescriptionId, this.formations)
        .then(({ data }) => {
          VbSnackbarService.showSuccess('Formações e capacitações salvas com sucesso!');
          this.formations = data.map(it => new Formation(it));
          resolve();
        }).catch((err) => {
          VbSnackbarService.handleHttpError(err);
          reject();
        });
    });
  }

  private created(): void {
    this.loadFormations();
  }

  private loadFormations(): void {
    this.loading = true;
    DrdService.getFormations(this.performanceDescriptionId).then((data) => {
      this.formations = data.map(it => new Formation(it));
    }).catch(VbSnackbarService.handleHttpError)
      .finally(() => {
        this.loading = false;
      });
  }
}
