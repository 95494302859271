






























import {
  Vue, Component, Prop, Watch,
} from 'vue-property-decorator';

@Component
export default class MonthYearSelect extends Vue {
  @Prop() value!: string;

  @Prop({
    required: false,
    default: () => [],
  }) rules!: any;

  @Prop({
    required: false,
    default: false,
  }) private disablePastMonths!: boolean;

  @Prop({
    required: false,
    default: false,
  }) private isReadonly!: boolean;

  @Prop({
    required: false,
    default: true,
  })
  private clearable!: boolean;

  modal = false;

  date = '';

  dateFormatedAux = '';

  get dateFormated() {
    return this.dateFormatedAux ? this.dateFormatedAux : this.value;
  }

  private get minDate(): string {
    if (this.disablePastMonths) {
      const today: Date = new Date();
      const formatMin: string = `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`;
      return formatMin;
    }
    return '';
  }

  @Watch('modal')
  watchModal(value: boolean) {
    if (this.modal && this.value && !this.date) {
      this.date = this.changeFormat(this.value, '/', '-');
    }
  }

  @Watch('date')
  watchDate(date: string) {
    if (date && date.length === 7 && date.includes('-')) {
      this.dateFormatedAux = this.changeFormat(date, '-', '/');
    }
  }

  private changeFormat(value: string, separator: string, newSeparator: string) {
    if (value && value.includes(separator)) {
      const splited = value.split(separator);
      return `${splited[1]}${newSeparator}${splited[0]}`;
    }
    return '';
  }

  selectedDate() {
    const refs: any = this.$refs;
    refs.dialog.save(this.date);
    this.$emit('input', this.dateFormated);
  }

  clearDate() {
    this.$emit('input', '');
  }
}
