










































import { Vue, Component, Prop } from 'vue-property-decorator';
import AddButton from '@/shared/buttons/add-button.component.vue';
import Rules from '@/shared/rules/rules';

@Component({ components: { AddButton } })
export default class FeedbackComponent extends Vue {
  @Prop({ type: Array, required: true })
  points!: string[];

  @Prop({ type: String, required: true })
  title!: string;

  @Prop({ type: String, required: true })
  addButtonLabel!: string;

  @Prop() hasEditPermission!: boolean;

  rules: Rules = new Rules();

  newPoint(): void {
    this.$emit('newPoint');
  }

  deletePoint(index: number): void {
    this.$emit('delete', index);
  }

  input(value: string, index: number): void {
    this.$emit('input', {
      value,
      index,
    });
  }
}
