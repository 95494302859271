



























import { Vue, Component } from 'vue-property-decorator';
import { VbSnackbarService } from 'bag-of-holding-library';
import AssessmentForm from '@/domain/assessment/entity/assignment/assessment-form.entity';
import AssessmentService from '@/domain/assessment/service/assessment.service'
import NotFoundComponent from '@/shared/not-found/not-found.component.vue';
import EmployeeService from '@/domain/employees/service/employee.service';
import Employee from '@/domain/employees/entity/employee.entity';
import AuthenticationService from '@/domain/security/authentication.service';
import AutocompleteComponent from '@/shared/fields/autocomplete-component.vue';
import Assignment from '@/domain/drd/entity/assignment.entity';
import CardTitle from '@/shared/card/card-title.component.vue';
import SelectSingleAssignmentComponent from '@/shared/assignment/select-sigle-assignment.component.vue';
import StartAssessmentComponent from './components/start-assessment.component.vue';
import AssessmentFormComponent from './assessment-form.component.vue';

@Component({
  components: {
    AssessmentFormComponent,
    NotFoundComponent,
    AutocompleteComponent,
    CardTitle,
    SelectSingleAssignmentComponent,
    StartAssessmentComponent,
  },
})
export default class AssessmentView extends Vue {
  assessmentForm: AssessmentForm = new AssessmentForm();

  employeeId: string = this.$route.params.employeeId;

  loading: boolean = false;

  employee: Employee = new Employee({});

  loggedUserEmail: string = '';

  assignments: Assignment[] = [];

  assessmentFormFetched: boolean = false;

  assignmentId: any = this.$route.query.assignmentId;

  created() {
    this.$destroy();
    this.validateAccessPermition();
  }

  get title() {
    return `Assessment ${this.assessmentForm.assignmentDescription
      ? `- ${this.assessmentForm.assignmentDescription}` : ''}`
  }

  getAssessmentFormTemplate() {
    this.loading = true;
    AssessmentService.getFormTemplateByAssignmentId(this.assignmentId)
      .then(assessmentForm => this.assessmentForm = assessmentForm)
      .catch((error) => {
        VbSnackbarService.handleHttpError(error);
        window.history.go(-1);
      })
      .finally(() => {
        this.loading = false;
        this.assessmentFormFetched = true;
      });
  }

  async getEmployee() {
    await EmployeeService.getEmployee(this.employeeId)
      .then(employee => this.employee = employee);
  }

  async getUserLoggedInEmail() {
    this.loggedUserEmail = await AuthenticationService.userLoggedIn().userName.toLowerCase();
  }

  async validateAccessPermition() {
    await this.getUserLoggedInEmail();
    await this.getEmployee();
    if (this.employee.email.includes(this.loggedUserEmail)) return;
    this.$router.push('/denied');
  }

  updateAssignmentId(assignmentId: string) {
    this.$router.replace({ query: { assignmentId } });
    this.assignmentId = assignmentId;
  }
}
