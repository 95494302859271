























import { Vue, Component, Prop } from 'vue-property-decorator';
import Workplace from '@/domain/workplace/entity/workplace.entity';
import WorkplaceStore from '@/domain/workplace/workplace.store';
import LabelSlot from '../slots/label-slot.component.vue';
import Rules from '../rules/rules';

@Component({
  components: {
    LabelSlot,
  },
})
export default class WorkplaceSelect extends Vue {
  @Prop() workplace: any;

  @Prop({
    type: Boolean,
    default: false,
  }) returnObject!: boolean;

  @Prop({
    type: Boolean,
    default: true,
  }) clearable!: boolean;

  @Prop({
    type: Boolean,
    default: false,
  }) disabled!: boolean;

  workplaces: Workplace[] = [];

  loading: boolean = false;

  @Prop() rules!: Rules[];

  created() {
    this.getWorkPlaces();
  }

  getWorkPlaces() {
    this.loading = true;
    WorkplaceStore.fetchedWorkplaces()
      .then(workplaces => this.workplaces = workplaces)
      .finally(() => this.loading = false);
  }

  update(workplaceSelected: any) {
    this.$emit('update', workplaceSelected);
  }

  disabledItems(items: any): boolean {
    return !items.active;
  }
}
