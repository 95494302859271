import { render, staticRenderFns } from "./godfather-export.component.vue?vue&type=template&id=3a6a82ea&scoped=true&"
import script from "./godfather-export.component.vue?vue&type=script&lang=ts&"
export * from "./godfather-export.component.vue?vue&type=script&lang=ts&"
import style0 from "./godfather-export.component.vue?vue&type=style&index=0&id=3a6a82ea&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3a6a82ea",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
installComponents(component, {VAutocomplete,VBtn,VCheckbox,VForm,VIcon})
