



















import EmployeeService from '@/domain/employees/service/employee.service';
import { VbSnackbarService } from 'bag-of-holding-library';
import { Vue, Component } from 'vue-property-decorator';
import ModalWarning from '@/shared/modal/modal-warning.component.vue';
import ModalOptions from '../modal/modal-options.entity';

@Component({
  components: {
    ModalWarning,
  },
})
export default class DrdReviewCheckboxComponent extends Vue {
  reviewed: boolean = false;

  showReviewModalWarning: boolean = false;

  loadingConludeReview: boolean = false;

  get modalWarningOptions(): ModalOptions {
    return {
      title: 'Atenção',
      description: 'Você realmente deseja finalizar a revisão da sua DRD?',
      showOk: true,
      disabledOk: false,
      labelOk: 'Sim, finalizar',
      loadingOk: this.loadingConludeReview,
      okColor: 'tabBlue',
      showCancel: true,
      labelCancel: 'Cancelar',
      width: 400,
    };
  }

  conludeReview() {
    this.loadingConludeReview = true;
    EmployeeService.concludeReview()
      .then(() => VbSnackbarService.showSuccess('DRD revisada com sucesso!'))
      .catch((error) => {
        VbSnackbarService.showError(error.response.data.messages.toString())
      }).finally(() => {
        this.loadingConludeReview = false;
        this.showReviewModalWarning = false;
      });
  }

  openConcludeModalWarning() {
    this.showReviewModalWarning = true;
  }

  closeModalWarning() {
    this.reviewed = false;
    this.showReviewModalWarning = false;
  }
}
