















import { Vue, Component, Prop } from 'vue-property-decorator';
import AreaKey from '@/domain/drd/entity/area-key/area-key.entity';
import DrdPanel from '../common-components/drd-panel.component.vue';
import AreaKeyItemComponent from './area-key-item.component.vue';

@Component({
  components: { AreaKeyItemComponent, DrdPanel },
})
export default class AreaKeyComponent extends Vue {
  @Prop({
    type: AreaKey,
    required: true,
  })
  private areaKey!: AreaKey;
}
