import EmployeeSummary from '@/domain/schedule/employee/employee-summary.entity';
import ManagerService from '@/domain/employees/service/employee.service';
import EmployeeService from '@/domain/schedule/employee/employee.service';

export default class FilterEmployeeService {
  managers: EmployeeSummary[] = [];

  lastFilterManager: string = '';

  lastFilterEmployee: string = '';

  employees: EmployeeSummary[] = [];

  findManagers(value: string) {
    if ((this.lastFilterManager !== value) && value) {
      this.lastFilterManager = value;
      if (value.length > 2) {
        ManagerService.findManagersByName(value)
          .then(el => this.mergeManagers(el));
      }
    }
  }

  mergeManagers(employees: EmployeeSummary[]): void {
    const addedIds: string[] = this.managers.map((employee: EmployeeSummary) => employee.id)
    employees.forEach((employee: EmployeeSummary) => {
      if (!addedIds.includes(employee.id)) {
        this.managers.push(employee);
      }
    })
  }

  private findEmployeesByName(value: string) {
    if ((this.lastFilterEmployee !== value) && value) {
      this.lastFilterEmployee = value;
      if (value.length > 2) {
        sessionStorage.setItem('employeeStore', value)
        EmployeeService.findEmployeesByName(value)
          .then(this.mergeEmployees);
      }
    }
  }

  private mergeEmployees(employees: EmployeeSummary[]): void {
    const addedIds: string[] = this.employees.map((employee: EmployeeSummary) => employee.id)
    employees.forEach((employee: EmployeeSummary) => {
      if (!addedIds.includes(employee.id)) {
        this.employees.push(employee);
      }
    })
  }
}
