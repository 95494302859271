





















import {
  Vue, Prop, Component, Emit,
} from 'vue-property-decorator';
import LabelSlot from '@/shared/slots/label-slot.component.vue';

@Component({
  components: {
    'label-slot': LabelSlot,
  },
})
export default class TextArea extends Vue {
  @Prop({
    required: true,
  }) private label!: string;

  @Prop({
    required: false,
    default: false,
  }) private requiredField!: boolean;

  @Prop({
    required: false,
    default: '',
  }) private placeholder!: string;

  @Prop({
    required: false,
    default: '',
  })
  private value?: string;

  @Prop({
    required: false,
  })
  private labelColor?: string;

  @Prop({
    required: false,
    default: '',
  })
  private height?: string;

  @Prop({
    required: false,
    default: 100,
  })
  private maxLength?: number;

  @Prop({
    type: Boolean,
  }) private disabled!: boolean;

  private get requiredRules(): any {
    const rules: any = [(v: any) => (!!v && v.trim().length !== 0) || 'O campo é obrigatório'];
    if (this.requiredField) {
      return rules;
    }
    return [];
  }

  @Emit('input')
  private sendInput(reason: string): string {
    return reason;
  }
}
