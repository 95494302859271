
















import { Vue, Component } from 'vue-property-decorator';
import { scoreSource } from '@/views/employees/profile/tab-components/score/score-utils';
import ScoreComponent from '@/views/employees/profile/tab-components/score/score.component.vue'
import Authority from '@/domain/security/authority.entity';
import EmployeeService from '@/domain/employees/service/employee.service';
import ScoreScheduleService from '@/domain/schedule/score/score-schedule.service';
import EmployeeHappiness from '@/domain/happiness-level/entity/employee-happiness.entity';
import AuthorityStore from '@/domain/security/authority.store';

@Component({ components: { ScoreComponent } })
export default class ScoreSchedule extends Vue {
  activityId: string = this.$route.params.activityId;

  source = scoreSource.activity;

  employeeId: string = this.$route.params.employeeId;

  hasCreateOrUpdate: boolean = false;

  authority!: Authority;

  isEmployeeAdministrator: boolean = false;

  isBusinessPartner: boolean = false;

  isPersonalDepartament: boolean = false;

  isFacilitiesAdministrator: boolean = false;

  isAdministrator: boolean = false;

  subordinateList: string[] = [];

  employee: EmployeeHappiness = new EmployeeHappiness({});

  isSubordinate: boolean = false;

  isloading: boolean = false;

  async getEmployeeByActivity() {
    await ScoreScheduleService.getActivityScore(this.activityId)
      .then(response => this.employee = response.employee);
  }

  async checkIsSubordinate() {
    await EmployeeService.getAllSubordinates().then((response) => {
      this.subordinateList = response;
      this.isSubordinate = this.subordinateList.includes(this.employeeId);
    });
  }

  async validadeAuthorities() {
    await this.getAuthority();
    this.hasCreateOrUpdate = this.authority.hasCreateOrUpdateEmployeeScore();
    this.isAdministrator = this.authority.isAdministrator();
    this.isEmployeeAdministrator = this.authority.isEmployeeAdministrator();
    this.isBusinessPartner = this.authority.isBusinnesPartner();
    this.isPersonalDepartament = this.authority.isPersonalDepartment();
    this.isFacilitiesAdministrator = this.authority.isFacilitiesAdministrator();
  }

  async getAuthority() {
    await AuthorityStore.fetchedAuthority().then(authority => this.authority = authority);
  }

  verifyAccessIfNotManager(): boolean {
    return this.isEmployeeAdministrator
        || this.isBusinessPartner
        || this.isPersonalDepartament
        || this.isFacilitiesAdministrator
        || this.isAdministrator
  }

  checkAccessScoreSchedule() {
    return (this.hasCreateOrUpdate && this.isSubordinate)
    || (this.hasCreateOrUpdate && this.verifyAccessIfNotManager());
  }

  async created() {
    this.$destroy();
    this.isloading = true;
    await this.checkIsSubordinate();
    await this.validadeAuthorities();
    if (!this.checkAccessScoreSchedule()) {
      this.$router.push('/denied');
    }
    await this.getEmployeeByActivity();
    this.isloading = false;
  }
}
