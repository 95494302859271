



































































import {
  Vue, Component, Prop,
} from 'vue-property-decorator';
import PaginationComponent from '@/shared/pagination.component.vue';
import LabelSlot from '@/shared/slots/label-slot.component.vue';
import MobileHandler from '@/shared/utils/mobile-handler';
import splitContent from '@/shared/directive/split-content';
import PerformanceDescriptionFiltered from '@/domain/drd/entity/performance-description-filtered.entity';
import AuthorityStore from '@/domain/security/authority.store';
import { VbTooltipSlot } from 'bag-of-holding-library';
import Header from './header.json';

@Component({
  components: { PaginationComponent, LabelSlot, VbTooltipSlot },
  filters: {
    splitContent,
  },
})
export default class PerformanceDescriptionTableComponent extends Vue {
  @Prop() items!: PerformanceDescriptionFiltered[];

  @Prop() loading!: boolean;

  @Prop() page!: any;

  @Prop() updateDrds!: boolean;

  isMobile = false;

  header = Header;

  isAdmin: boolean = false;

  updateTable(pagination: any): void {
    this.$emit('update', pagination);
  }

  onResize() {
    this.isMobile = MobileHandler.isMobile();
  }

  select(item: any): void {
    this.$router.push({
      path: `/performance-description/${item.id}/view`,
    })
  }

  editItem(item: any): void {
    this.$router.push({
      path: `/performance-description/${item.id}`,
    })
  }

  updateSortBy(sortBy: string): void {
    this.updateTable({
      ...this.page,
      sortBy,
    });
  }

  updateSortDesc(sortDesc: string): void {
    this.updateTable({
      ...this.page,
      sortDesc,
    });
  }

  created(): void {
    AuthorityStore.isAdministrator().then((resp: boolean) => {
      this.isAdmin = resp;
    });
  }

  openReplicateDialog(item: any): void {
    this.$emit('openReplicateDialog', item);
  }
}
