import { VbDateFormat } from 'bag-of-holding-library'

export default class ActivityFile {
    id: string;

    originalName: string;

    externalBucketPath: string;

    lastModifiedDate: string;

    constructor(obj: any = {}) {
      this.id = obj.id;
      this.originalName = obj.originalName;
      this.externalBucketPath = obj.externalBucketPath;
      this.lastModifiedDate = obj.lastModifiedDate;
    }

    public static of(obj: any): ActivityFile {
      return new ActivityFile(obj || {});
    }

    public static ofList(obj: any): ActivityFile[] {
      return obj.map((value: any) => this.of(value));
    }

    get formatedName(): string {
      return `${VbDateFormat.fullDateAndTime(this.lastModifiedDate)} - ${this.originalName}`
    }
}
