import DateFormat from '@/shared/utils/date-format';

export default class EmployeeTalentLevel {
  id: string;

  name: string;

  description: string;

  color: string;

  active: boolean;

  notify: boolean;

  createdBy: string;

  createdDate: string;

  activity: string;

  constructor(obj: any = {}) {
    this.id = obj.id || '';
    this.name = obj.name || '';
    this.description = obj.description || '';
    this.color = obj.color || '';
    this.active = obj.active;
    this.notify = obj.notify;
    this.createdBy = obj.createdBy || '';
    this.createdDate = obj.createdDate || '';
    this.activity = obj.activity || '';
  }
}
