





























































import { Vue, Component } from 'vue-property-decorator';
import { VbSnackbarService } from 'bag-of-holding-library';
import CardTitle from '@/shared/card/card-title.component.vue';
import AssessmentService from '@/domain/assessment/service/assessment.service';
import Loading from '@/shared/loading/loading.component.vue';
import NotFoundComponent from '@/shared/not-found/not-found.component.vue';
import EmployeeAssessmentResult from '@/domain/assessment/entity/employee-assessment-result';
import SelectLanguageComponent from '@/views/survey/select-language.component.vue';
import AssessmentResultItem from './assessment-result-item.component.vue';

@Component({
  components: {
    CardTitle,
    Loading,
    NotFoundComponent,
    AssessmentResultItem,
    SelectLanguageComponent,
  },
})
export default class EmployeeAssessmentResultView extends Vue {
  value: number = 80;

  employeeAssessmentResults: EmployeeAssessmentResult = new EmployeeAssessmentResult();

  employeeId: string = this.$route.params.employeeId;

  loading: boolean = false;

  selected: boolean = false;

  languages = [
    {
      text: 'Brasil - pt-br',
      acronym: 'PT_BR',
      acronymFlag: 'BRA',
    },
    {
      text: 'Espanhol - es',
      acronym: 'ES',
      acronymFlag: 'ES',
    },
    {
      text: 'English - en',
      acronym: 'EN',
      acronymFlag: 'US',
    },
  ];

  languageSelectedAcronym: string = 'PT_BR';

  created() {
    this.$destroy();
    this.getResults();
  }

  getResults() {
    this.loading = true;
    AssessmentService.getResultByEmployeeId(this.employeeId)
      .then(response => this.employeeAssessmentResults = response)
      .catch((error) => {
        VbSnackbarService.handleHttpError(error);
        window.history.go(-1);
      })
      .finally(() => this.loading = false);
  }
}
