



















































































































































































































































import { Vue, Component } from 'vue-property-decorator';
import MainButton from '@/shared/buttons/main-button.component.vue';
import TextAreaComponent from '@/shared/fields/text-area.component.vue';
import { VbLoadingService, VbSnackbarService } from 'bag-of-holding-library';
import MicrosoftService from '@/domain/microsoft/service/microsoft.service';
import { VueEditor } from 'vue2-editor';
import ModalWarning from '@/shared/modal/modal-warning.component.vue';
import MessageFilterRequest from '@/domain/microsoft/entity/message-filter.request';
import AutocompleteComponent from '@/shared/fields/autocomplete-component.vue';
import FilterEmployeeService from '@/domain/employees/service/filter-employee.service';
import UpdateAtribute from '@/domain/employees/entity/update-atribute.entity';
import CareerService from '@/domain/employees/service/career.service';
import CheckboxComponent from '@/shared/checkbox/checkbox.component.vue';
import LabelSlot from 'bag-of-holding-library/src/slot/label-slot.component.vue';
import SelectStatesComponent from '@/shared/states/select-states.component.vue';
import CountrySelect from '@/shared/country-select.component.vue';
import Country from '@/domain/countries/entity/country.entity';
import SelectCityComponent from '@/shared/city/city.component.vue';
import GenderTypes from '../employees/profile/tab-components/personal-data/gender-types.json';
import ToolbarConfig from './toolbar-config.json';

const BRA = 'BRA';

@Component({
  components: {
    MainButton,
    TextAreaComponent,
    VueEditor,
    ModalWarning,
    AutocompleteComponent,
    CheckboxComponent,
    LabelSlot,
    SelectStatesComponent,
    CountrySelect,
    SelectCityComponent,
  },
})
export default class MessageSenderView extends Vue {
  message: string = '';

  openModal: boolean = false;

  filter: MessageFilterRequest = MessageFilterRequest.of({});

  filterService = new FilterEmployeeService();

  businessUnits: UpdateAtribute[] = [];

  roles: UpdateAtribute[] = [];

  workplaces: UpdateAtribute[] = [];

  resultCenters: UpdateAtribute[] = [];

  resultCenterInput = '';

  lastResultCenterFilter = '';

  filterMenu: boolean = false;

  gender: string = '';

  genderTypes = GenderTypes;

  hasChildren = false;

  notChildren = false;

  editorToolbar = ToolbarConfig;

  showForeignState: boolean = false;

  created() {
    CareerService.getRoles().then((resp: any) => this.roles = resp.data);
    CareerService.getWorkplace().then((resp: any) => this.workplaces = resp.data);
    CareerService.getBusinessUnit().then(resp => this.businessUnits = resp);
  }

  get hasContent(): Boolean {
    return Boolean(this.message);
  }

  findResultCenter(value: string) {
    this.resultCenterInput = value;
    if ((this.lastResultCenterFilter !== value) && value) {
      this.lastResultCenterFilter = value;
      if (value.length > 2) {
        CareerService.getResultCentersByName(value)
          .then(el => this.mergeResultCenters(el));
      }
    }
  }

  mergeResultCenters(resultCenters: UpdateAtribute[]): void {
    const addedIds: string[] = this.resultCenters
      .map((resultCenter: UpdateAtribute) => resultCenter.id!!)
    resultCenters.forEach((resultCenter: UpdateAtribute) => {
      if (!addedIds.includes(resultCenter.id!!)) {
        this.resultCenters.push(resultCenter);
      }
    })
  }

  openWarningSendMessage() {
    this.openModal = true;
  }

  sendMessage() {
    VbLoadingService.show();
    MicrosoftService.sendMessage(this.message, this.filter)
      .then(() => {
        VbSnackbarService.showSuccess('Solcitação enviada com sucesso');
      })
      .catch((err: any) => VbSnackbarService.handleHttpError(err))
      .finally(() => VbLoadingService.hide());
  }

  closeAndSend() {
    this.closeWarningModal();
    this.sendMessage();
  }

  closeWarningModal() {
    this.openModal = false;
  }

  home() {
    this.$destroy();
    this.$router.push({
      path: '/',
    });
  }

  handleHasChidren(value: boolean) {
    this.filter.hasChildren = value || null;
    this.hasChildren = value;
  }

  handleNotChidren(value: boolean) {
    this.filter.hasChildren = value ? !value : null;
    this.notChildren = value;
  }

  updateContry(value: Country) {
    if (value.acronym !== BRA) {
      this.showForeignState = true;
      this.filter.country = value.id;
      this.filter.states = [];
      return;
    }
    this.showForeignState = false;
    this.filter.country = value.id;
    this.filter.foreignState = '';
  }
}
