import DateFormat from '@/shared/utils/date-format';

export default class ResignationDetails {
  id: string;

  accessExpiration: string;

  businessUnit: string;

  createdBy: string;

  createdDate: string;

  demissionType: string;

  devolutionType: string;

  directorName: string;

  employeeName: string;

  managerName: string;

  observation: string;

  requestDate: string;

  shutdownTime: string;

  shutdownDate: string;

  backupTypes : string[] = [];

  employeeAdmissionDate: string;

  recommendRehiring: boolean | undefined;

  recommendRehiringJustification: string;

  constructor(obj: any) {
    this.id = obj.id || '';
    this.businessUnit = obj.businessUnit || '';
    this.createdBy = obj.createdBy || '';
    this.createdDate = obj.createdDate || '';
    this.demissionType = obj.demissionType || '';
    this.devolutionType = obj.devolutionType || '';
    this.directorName = obj.directorName || '';
    this.employeeName = obj.employeeName || '';
    this.managerName = obj.managerName || '';
    this.observation = obj.observation || '';
    this.requestDate = obj.requestDate || '';
    this.shutdownTime = '';
    this.shutdownDate = '';
    this.accessExpiration = '';
    this.backupTypes = obj.backupTypes || ['EMAIL', 'ONE_DRIVE', 'LOCAL_FILES'];
    this.employeeAdmissionDate = obj.employeeAdmissionDate || '';
    if (obj.accessExpiration) {
      const [accessExpiration, accessExpirationTime] = obj.accessExpiration.split('T');
      this.accessExpiration = accessExpirationTime;
    }
    if (obj.shutdownTime) {
      const [shutdownDate, shutdownTime] = obj.shutdownTime.split('T');
      this.shutdownDate = shutdownDate;
      this.shutdownTime = shutdownTime;
    }
    this.recommendRehiring = obj.recommendRehiring === null ? undefined : obj.recommendRehiring;
    this.recommendRehiringJustification = obj.recommendRehiringJustification || '';
  }

  public static of(obj: any): ResignationDetails {
    return new ResignationDetails(obj || {})
  }

  public toRequest(): any {
    const ret: any = {
      ...this,
      accessExpiration: `${this.shutdownDate} ${this.accessExpiration}:00`,
      shutdownTime: `${this.shutdownDate} ${this.shutdownTime}:00`,
    }
    delete ret.shutdownDate;
    return ret;
  }

  get EmployeeAdmissionDateFormated() {
    return DateFormat.standardDate(this.employeeAdmissionDate);
  }
}
