










































import { Vue, Component, Prop } from 'vue-property-decorator';
import Birthday from '@/domain/birthday/birthday.entity';
import EmployeeAvatar from './employee-avatar/employee-avatar.component.vue';

@Component({
  components: { EmployeeAvatar },
})
export default class BirthdayComponent extends Vue {
    @Prop({
      type: Array,
      required: true,
    })
    birthdays!: Birthday[];

    @Prop({
      type: String,
      required: true,
    })
    title!: string;
}
