











import { Component, Mixins } from 'vue-property-decorator';
import Modal from '@/shared/modal/modal.component.vue';
import ModalMixin from '@/shared/modal/modal.mixin';
import ModalOptions from '@/shared/modal/modal-options.entity';
import EmployeeHistory from './employee-history.component.vue';

@Component({
  components: {
    'modal-component': Modal,
    'employee-history': EmployeeHistory,
  },
})
export default class ModalEmployeeHistory extends Mixins(ModalMixin) {
  private get historyModalOptions(): ModalOptions {
    return {
      title: 'Histórico',
      showOk: true,
      labelOk: 'Fechar',
      width: 1000,
    };
  }
}
