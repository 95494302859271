













import { Vue, Component, Prop } from 'vue-property-decorator';
import { VbSnackbarService } from 'bag-of-holding-library';
import DrdService from '@/domain/drd/service/drd.service';
import Loader from '@/views/drd/commons/loader.component.vue';
import AreaKey from '@/domain/drd/entity/area-key/area-key.entity';
import AreaKeyComponent from './area-key.component.vue';

@Component({
  components: { AreaKeyComponent, Loader },
})
export default class AreaKeyIndex extends Vue {
  @Prop({
    type: String,
    required: true,
  })
  private performanceDescriptionId!: string;

  private loading: boolean = true;

  private areaKeys: AreaKey[] = [];

  private filterActiveItems(areaKey: AreaKey): any {
    return {
      ...areaKey,
      items: areaKey.items.filter(it => it.active),
    };
  }

  private loadAreaKeys(): void {
    this.loading = true;
    DrdService.getAreaKeys(this.performanceDescriptionId).then((data) => {
      this.areaKeys = data.filter(it => it.active)
        .map(it => new AreaKey(this.filterActiveItems(it)));
    }).catch(VbSnackbarService.handleHttpError)
      .finally(() => {
        this.loading = false;
      });
  }

  private created(): void {
    this.loadAreaKeys();
  }
}
