class Debounce {
  timeout!: number;

  debounce(func: Function, duration: number = 500) {
    clearTimeout(this.timeout)
    this.timeout = setTimeout(func, duration)
  }
}

export default new Debounce()
