



























import {
  Component, Emit, Prop, Vue,
} from 'vue-property-decorator';
import CardTitle from '@/shared/card/card-title.component.vue';
import { VbTooltipSlot } from 'bag-of-holding-library';

@Component({
  components: { CardTitle, VbTooltipSlot },
})
export default class CheckboxComponent extends Vue {
  @Prop() buttonText!: string;

  @Prop({ default: 'primary' }) buttonColor?: string;

  @Prop() label!: string;

  @Prop() value!: boolean;

  @Emit('input')
  click() {
    return !this.value;
  }
}
