export default class Priority {
  id: string;

  position: number;

  description: string;

  color: string;

  active: boolean;

  constructor(obj: any = {}) {
    this.id = obj.id || '';
    this.position = obj.position;
    this.description = obj.description || '';
    this.color = obj.color || '';
    this.active = obj.active || '';
  }
}
