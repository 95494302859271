




























import Wages from '@/domain/wages/entity/wages.entity';
import currencyFilter from '@/shared/filters/currency.filter';
import {
  Vue, Component, Prop,
} from 'vue-property-decorator';
import headers from './remuneration-agreent-table-headers.json';

@Component
export default class RemunerationAgreementTable extends Vue {
  @Prop() seniorityRemunerations!: Wages[];

  @Prop() loading!: boolean;

  headers: any = headers;

  toCurrency(value) {
    const numericValue = parseFloat(value);
    if (!numericValue) {
      return '-';
    }
    return `R$ ${currencyFilter(numericValue)}`;
  }
}
