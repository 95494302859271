var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"table"},[_c('v-data-table',{directives:[{name:"resize",rawName:"v-resize",value:(_vm.onResize),expression:"onResize"}],staticClass:"data-table",attrs:{"hide-default-footer":"","loading-text":"Carregando...","no-data-text":"Nenhum colaborador foi encontrado com esses filtros","headers":_vm.headersAndExtraColumns,"items":_vm.items,"loading":_vm.loading,"page":_vm.page.page,"sort-by":_vm.page.sortBy,"sort-desc":_vm.page.sortDesc,"items-per-page":_vm.page.itemsPerPage,"page-count":_vm.page.pageCount},on:{"update:sortBy":function($event){return _vm.$set(_vm.page, "sortBy", $event)},"update:sort-by":[function($event){return _vm.$set(_vm.page, "sortBy", $event)},_vm.updateTable],"update:sortDesc":function($event){return _vm.$set(_vm.page, "sortDesc", $event)},"update:sort-desc":[function($event){return _vm.$set(_vm.page, "sortDesc", $event)},_vm.updateTable],"update:itemsPerPage":function($event){return _vm.$set(_vm.page, "itemsPerPage", $event)},"update:items-per-page":function($event){return _vm.$set(_vm.page, "itemsPerPage", $event)},"update:pageCount":function($event){return _vm.$set(_vm.page, "pageCount", $event)},"update:page-count":function($event){return _vm.$set(_vm.page, "pageCount", $event)},"update":_vm.updateTable,"click:row":_vm.select},scopedSlots:_vm._u([{key:"item.allKnowledgeDTO.hardSkills",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"custom-cell"},[(!item.expanded)?_c('div',[_vm._l((item.allKnowledgeDTO.hardSkills.slice(0, 3)),function(skill,index){return _c('div',{key:index,staticClass:"rounded-container"},[_vm._v(" "+_vm._s(skill.description)+" ")])}),(item.allKnowledgeDTO.hardSkills.length > 3)?_c('span',{staticClass:"rounded-container"},[_vm._v(" +"+_vm._s(item.allKnowledgeDTO.hardSkills.length - 3)+" ")]):_vm._e()],2):_c('div',_vm._l((item.allKnowledgeDTO.hardSkills),function(skill,index){return _c('div',{key:index,staticClass:"rounded-container"},[_vm._v(" "+_vm._s(skill.description)+" ")])}),0)])]}},{key:"item.allKnowledgeDTO.frameworksOrMethodologies",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"custom-cell"},[(!item.expanded)?_c('div',[_vm._l((item.allKnowledgeDTO.frameworksOrMethodologies.slice(0, 3)),function(skill,index){return _c('div',{key:index,staticClass:"rounded-container"},[_vm._v(" "+_vm._s(skill.description)+" ")])}),(item.allKnowledgeDTO.frameworksOrMethodologies.length > 3)?_c('span',{staticClass:"rounded-container"},[_vm._v(" +"+_vm._s(item.allKnowledgeDTO.frameworksOrMethodologies.length - 3)+" ")]):_vm._e()],2):_c('div',_vm._l((item.allKnowledgeDTO.frameworksOrMethodologies),function(skill,index){return _c('div',{key:index,staticClass:"rounded-container"},[_vm._v(_vm._s(skill.description))])}),0)])]}},{key:"item.allKnowledgeDTO.businessExperience",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"custom-cell"},[(!item.expanded)?_c('div',[_vm._l((item.allKnowledgeDTO.businessExperience.slice(0, 3)),function(skill,index){return _c('div',{key:index,staticClass:"rounded-container"},[_vm._v(" "+_vm._s(skill.description)+" ")])}),(item.allKnowledgeDTO.businessExperience.length > 3)?_c('span',{staticClass:"rounded-container"},[_vm._v(" +"+_vm._s(item.allKnowledgeDTO.businessExperience.length - 3)+" ")]):_vm._e()],2):_c('div',_vm._l((item.allKnowledgeDTO.businessExperience),function(skill,index){return _c('div',{key:index,staticClass:"rounded-container"},[_vm._v(" "+_vm._s(skill.description)+" ")])}),0)])]}},{key:"item.allKnowledgeDTO.certifications",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"certification-cell"},[_c('div',{staticClass:"button-container"},[(!item.expanded)?_c('div',{staticClass:"certifications-container"},[_vm._l((item.allKnowledgeDTO.certifications.slice(0, 3)),function(skill,index){return _c('div',{key:index,staticClass:"rounded-container"},[_vm._v(" "+_vm._s(skill.description)+" ")])}),(item.allKnowledgeDTO.certifications.length > 3)?_c('span',{staticClass:"rounded-container"},[_vm._v(" +"+_vm._s(item.allKnowledgeDTO.certifications.length - 3)+" ")]):_vm._e()],2):_c('div',{staticClass:"certifications-container"},_vm._l((item.allKnowledgeDTO.certifications),function(skill,index){return _c('div',{key:index,staticClass:"rounded-container"},[_vm._v(" "+_vm._s(skill.description)+" ")])}),0)])])]}},{key:"item.expandir",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"certification-cell"},[_c('div',{staticClass:"button-container"},[(item.expanded)?_c('v-icon',{staticClass:"mr-1",on:{"click":function($event){$event.stopPropagation();return _vm.toggleExpansion(item)}}},[_vm._v(" mdi-arrow-up ")]):_vm._e(),(!item.expanded)?_c('v-icon',{staticClass:"mr-1",on:{"click":function($event){$event.stopPropagation();return _vm.toggleExpansion(item)}}},[_vm._v(" mdi-arrow-down ")]):_vm._e()],1)])]}}],null,true)}),_c('pagination-component',{staticClass:"mt-8 ml-md-2",attrs:{"pagination":_vm.page,"totalVisible":_vm.isMobile ? 5 : 8},on:{"input":_vm.updateTable}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }