import Option from './option.entity';

export default class RadioData {
  position: string;

  title: string;

  required: boolean;

  options: Option[];

  id: string;

  index: number;

  constructor(obj: any = {}) {
    this.position = obj.position;
    this.title = obj.title;
    this.required = obj.required;
    this.options = obj.options ? obj.options.map((option: Option) => new Option(option)) : [];
    this.id = obj.id || '';
    this.index = obj.index;
  }
}
