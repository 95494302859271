import OrderBy from './order-by.entity';
import SkillStatus from './skill-type.entity copy';
import Skill from './skill.entity';

export default class Filter {
  employee: string[] = Array.of();

  manager: any[] = Array.of();

  role: string[] = Array.of();

  businessUnit: string[] | string = Array.of();

  resultCenter: string[] = Array.of();

  hardSkill: string[] = Array.of();

  frameworks: string[] = Array.of();

  businessExps: string[] = Array.of();

  certifications: string[] = Array.of();

  assignment: string[] = Array.of();

  status: SkillStatus

  orderBy!: OrderBy

  active: boolean;

  isAccompanied: boolean;

  hardSkillInput = '';

  frameworkInput = '';

  businessExpInput = '';

  certificationInput = '';

  employeeInput = '';

  managerInput = '';

  businessUnitInput = '';

  roleInput = '';

  indirectSubordinates: boolean = false;

  allSkillFilter: string[] = [];

  assignmentInput = '';

  hasSkill = true;

  constructor(obj: any) {
    this.role = obj.role || [];
    this.employee = obj.employee || [];
    this.manager = obj.manager || [];
    this.businessUnit = obj.businessUnit || [];
    this.hardSkill = obj.hardSkill || [];
    this.frameworks = obj.frameworks || [];
    this.businessExps = obj.businessExps || [];
    this.certifications = obj.certifications || [];
    this.active = obj.active || true;
    this.orderBy = obj.orderBy || OrderBy.of({});
    this.isAccompanied = obj.isAccompanied || true;
    this.status = obj.status || [];
    this.assignment = obj.assignment || [];
  }

  public static of(value: any): Filter {
    return new Filter(value || {});
  }

  public static ofList(response: any): Filter[] {
    return response.data.map(this.of);
  }

  get hasHardSkill() {
    return this.hardSkill.length > 0
  }

  get hasEmployee() {
    return this.employee.length > 0
  }

  get hasManager() {
    return this.manager.length > 0
  }

  get hasRole() {
    return this.role.length > 0
  }

  get hasBusinessUnit() {
    return this.businessUnit !== undefined
      && this.businessUnit !== null
      && this.businessUnit.length > 0
  }

  get hasResultCenter() {
    return this.resultCenter.length > 0
  }

  get hasAssignment() {
    return this.assignment.length > 0
  }

  public equals(obj: Filter) {
    return this.businessUnit === obj.businessUnit
            && this.employee === obj.employee
            && this.manager === obj.manager
            && this.role === obj.role
            && this.active === obj.active
            && this.indirectSubordinates === obj.indirectSubordinates;
  }
}
