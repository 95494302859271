export default class SeniorityDrd {
  public id: string;

  public seniorityId: string;

  public percentage: number;

  public active: boolean;

  constructor(obj: any = {}) {
    this.id = obj.id;
    this.seniorityId = obj.seniorityId || '';
    this.percentage = obj.percentage || 0;
    this.active = obj.active === null || obj.active === undefined ? true : obj.active;
  }

  public static of(obj: any): SeniorityDrd {
    return new SeniorityDrd(obj || {})
  }

  public static ofList(obj: any[]): SeniorityDrd[] {
    return obj.map(this.of);
  }

  equals(obj: SeniorityDrd): boolean {
    return this.seniorityId === obj.seniorityId
    && this.percentage === obj.percentage
    && this.active === obj.active
  }
}
