













































































import {
  Vue, Component, Prop, Watch,
} from 'vue-property-decorator';
import { VbDateFormat } from 'bag-of-holding-library';
import ModalComponent from '@/shared/modal/modal.component.vue';
import ModalOptions from '@/shared/modal/modal-options.entity';
import LabelSlot from '@/shared/slots/label-slot.component.vue';
import CurrentCareerUpdate from '@/domain/employees/entity/current-career-update.entity';
import Headers from './header.json';

  @Component({
    components: { ModalComponent, LabelSlot },
    filters: {
      formatDate(value: string) {
        return VbDateFormat.monthYearToFormated(value);
      },
    },
  })

export default class EmployeeInfoModal extends Vue {
  @Prop() show!: boolean;

  @Prop() seniority!: any[];

  @Prop() employeeInfo?: CurrentCareerUpdate;

  @Prop() benefits?: string

  loading = false;

  header = Headers;

  employeeArray: any = [];

  @Watch('employeeInfo')
  teste() {
    this.employeeArray = Array.of(this.employeeInfo);
  }

  private get optionsHandled(): ModalOptions {
    return {
      title: 'Dados do colaborador',
      titleSize: '20px',
      description: '',
      showOk: false,
      disabledOk: true,
      labelOk: 'Salvar',
      okColor: 'tabBlue',
      showCancel: true,
      labelCancel: 'Cancelar',
      width: 1200,
    };
  }

  close() {
    this.$emit('close');
  }
}
