import DateFormat from '@/shared/utils/date-format';

export default class SelectiveProcessDetailsReport {
  candidateId: string;

  name: string;

  email: string;

  cpf: string;

  mentalGrade: string;

  socialGrade: string;

  motivationalGrade: string;

  cultureGrade: string;

  mentalGradeDate: string;

  cultureGradeDate: string;

  locusOfControlGrade: string;

  beFairGrade: string;

  constructor(obj: any = {}) {
    this.candidateId = obj.candidateId || '';
    this.name = obj.name || '';
    this.email = obj.email || '';
    this.cpf = obj.cpf || '';
    this.mentalGrade = obj.mentalGrade || '';
    this.socialGrade = obj.socialGrade || '';
    this.motivationalGrade = obj.motivationalGrade || '';
    this.cultureGrade = obj.cultureGrade || '';
    this.mentalGradeDate = obj.mentalGradeDate || '';
    this.cultureGradeDate = obj.cultureGradeDate || '';
    this.locusOfControlGrade = obj.locusOfControlGrade || '';
    this.beFairGrade = obj.beFairGrade || '';
  }

  public static of(obj: any): SelectiveProcessDetailsReport {
    return new SelectiveProcessDetailsReport(obj);
  }

  public static ofList(obj: any[] = []): SelectiveProcessDetailsReport[] {
    return obj.map(this.of);
  }

  get mentalGradeDateFormated(): string {
    return DateFormat.dateTimeFormated(this.mentalGradeDate);
  }

  get cultureGradeDateFormated(): string {
    return DateFormat.dateTimeFormated(this.cultureGradeDate);
  }
}
