export default class AuthorityProfile {
    id: string;

    name: string;

    description: string;

    constructor(obj: any = []) {
      this.id = obj.id || '';
      this.name = obj.name || '';
      this.description = obj.description || '';
    }
}
