import Vue from 'vue';
import msal from 'vue-msal';
import axios from 'axios';
import AuthenticationService from '../domain/security/authentication.service';

Vue.use(msal, {
  auth: {
    tenantId: process.env.VUE_APP_TENANT_ID,
    clientId: process.env.VUE_APP_CLIENT_ID,
    redirectUri: process.env.VUE_APP_REDIRECT_URL,
    autoRefreshToken: true,
  },
});

axios.interceptors.request.use(
  async (config: any) => {
    const newConfig: any = {};
    Object.assign(newConfig, config);
    if (!config.url.includes('/public')) {
      const token: any = await AuthenticationService.token();
      newConfig.headers.Authorization = `Bearer ${token.accessToken}`;
      return newConfig;
    }
    return newConfig;
  },
);
