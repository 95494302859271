



























































import {
  Vue, Prop, Component,
} from 'vue-property-decorator';
import ModalWarning from '@/shared/modal/modal-warning.component.vue';
import { VbTooltipSlot } from 'bag-of-holding-library';
import ActivityDetailed from '@/domain/schedule/activitie/activity-detailed.entity';

@Component({
  components: {
    'modal-warning': ModalWarning,
    VbTooltipSlot,
  },
})
export default class ManageActivityListItemActionButtons extends Vue {
  @Prop() currentStatus!: string;

  @Prop() disabled!: boolean;

  @Prop() deleteLoading!: boolean;

  @Prop({
    required: true,
  })
  private activity!: ActivityDetailed;

  @Prop({
    type: Boolean,
    default: false,
  })
  private isUpdateActivity!: boolean;

  @Prop({
    type: Boolean,
    default: false,
  })
  private isDeleteActivity!: boolean;

  @Prop({
    type: Boolean,
    default: false,
  })
  private isUpdateActivityStatus!: boolean;

  @Prop({
    type: Boolean,
    default: false,
  })
  private isAdm!: boolean;

  private warningModal: boolean = false;

  private statusReferenceForWarningModal: string = '';

  private openWarningModal(statusReferenceForWarningModal: string): void {
    this.warningModal = true;
    this.statusReferenceForWarningModal = statusReferenceForWarningModal;
  }

  private closeWarningModal(): void {
    this.warningModal = false;
  }

  private openUpdateStatusModal() {
    this.$emit('updateActivityStatus')
  }

  private confirm(): void {
    if (this.statusReferenceForWarningModal === 'Aprovada') this.$emit('approveActivity');
    if (this.statusReferenceForWarningModal === 'Recusada') this.$emit('rejectActivity');
    if (this.statusReferenceForWarningModal === 'Deletada') this.$emit('deletedActivity');
    this.closeWarningModal();
  }

  get showReject() {
    return this.isUpdateActivity && (!this.currentStatus || this.currentStatus === 'Aprovada' || this.currentStatus === 'Atrasada');
  }

  get showAprove() {
    return this.isAdm && this.isUpdateActivity && (!this.currentStatus || this.currentStatus === 'Recusada' || this.currentStatus === 'Atrasada');
  }

  get showDelete() {
    return !this.currentStatus || this.currentStatus === 'Recusada' || this.currentStatus === 'Atrasada'
  }

  approveTooltip = this.disabled ? 'Favor preencher campo Resultado' : 'Aprovar';

  rejectTooltip = this.disabled ? 'Favor preencher campo Resultado' : 'Rejeitar';
}
