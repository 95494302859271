interface OrderBy {
  description?: string;

  field: string;

  descending: boolean;
}

export default class Filter {
  assignment: string[] = Array.of();

  role: string[] = Array.of();

  ecosystem: string[] = Array.of();

  active: boolean;

  assignmentInput = '';

  roleInput = '';

  ecosystemInput = '';

  orderBy!: OrderBy;

  constructor(obj: any) {
    this.assignment = obj.assignment || [];
    this.role = obj.role || [];
    this.ecosystem = obj.ecosystem || [];
    this.active = obj.active || true;
  }

  public static of(value: any): Filter {
    return new Filter(value || {});
  }

  public static ofList(response: any): Filter[] {
    return response.data.map(this.of);
  }

  get hasAssignment() {
    return this.assignment.length > 0
  }

  get hasRole() {
    return this.role.length > 0
  }

  get hasEcosystem() {
    return this.ecosystem.length > 0
  }

  public equals(obj: Filter) {
    return this.assignment === obj.assignment
      && this.role === obj.role
      && this.ecosystem === obj.ecosystem
      && this.active === obj.active;
  }
}
