export default function replaceVariableOnText(text: string, object: any) {
  // eslint-disable-next-line no-restricted-syntax
  for (const key in object) {
    if (Object.prototype.hasOwnProperty.call(object, key)) {
      const pattern = new RegExp(`{{${key}}}`, 'g');
      text = text.replace(pattern, object[key]);
    }
  }
  return text;
}
