import { render, staticRenderFns } from "./talent-level-list.component.vue?vue&type=template&id=143abbff&scoped=true&"
import script from "./talent-level-list.component.vue?vue&type=script&lang=ts&"
export * from "./talent-level-list.component.vue?vue&type=script&lang=ts&"
import style0 from "./talent-level-list.component.vue?vue&type=style&index=0&id=143abbff&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "143abbff",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTooltip } from 'vuetify/lib/components/VTooltip';
installComponents(component, {VBtn,VCol,VDataTable,VIcon,VRow,VTooltip})
