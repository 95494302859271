export default class UpdatePermission {
  action: string;

  name: string;

  constructor(obj: any = {}) {
    this.action = obj.action || 'ADD';
    this.name = obj.name || '';
  }

  public static newAdd(name: string): UpdatePermission {
    return new UpdatePermission({ action: 'ADD', name });
  }

  public static newRemove(name: string): UpdatePermission {
    return new UpdatePermission({ action: 'REMOVE', name });
  }
}
