























import { Vue, Component } from 'vue-property-decorator';
import LabelSlot from '@/shared/slots/label-slot.component.vue';
import UpdateAtribute from '@/domain/employees/entity/update-atribute.entity';
import CareerService from '@/domain/employees/service/career.service';

@Component({
  components: {
    LabelSlot,
  },
})
export default class RoleSingleFilter extends Vue {
  roleSelected: string = '';

  roles: UpdateAtribute[] = [];

  loading: boolean = false;

  lastFilterRole: string = '';

  roleName: string = '';

  selected() {
    this.$emit('selected', this.roleSelected);
  }

  findRoles(value: string) {
    if ((this.lastFilterRole !== value) && value) {
      this.lastFilterRole = value;
      if (value.length > 2) {
        this.loading = true;
        CareerService.getRoles()
          .then((resp: any) => this.roles = resp.data)
          .finally(() => this.loading = false);
      }
    }
  }
}
