























import { Vue, Component } from 'vue-property-decorator';
import LabelSlot from '@/shared/slots/label-slot.component.vue';
import DrdService from '@/domain/drd/service/drd.service';
import Ecosystem from '@/domain/drd/entity/ecosystem.entity';

@Component({
  components: {
    LabelSlot,
  },
})
export default class EcosystemSingleFilter extends Vue {
  ecossytemSelected: string = '';

  ecosystems: Ecosystem[] = [];

  loading: boolean = false;

  lastFilterEcossytem: string = '';

  ecossytemName: string = '';

  selected() {
    this.$emit('selected', this.ecossytemSelected);
  }

  findEcosystem(value: string) {
    if ((this.lastFilterEcossytem !== value) && value) {
      this.lastFilterEcossytem = value;
      if (value.length > 2) {
        this.loading = true;
        DrdService.getAllEcosystem()
          .then((resp) => {
            this.ecosystems = resp
          }).finally(() => this.loading = false);
      }
    }
  }
}
