import ScreenEntity from './screen.entity';

export default class AuthorityScreenPermisson {
  name: string;

  screens: ScreenEntity[];

  constructor(obj: any = []) {
    this.name = obj.name || '';
    this.screens = obj.screens || [];
  }
}
