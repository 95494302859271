var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticClass:"remuneration-agreement__data-table",attrs:{"items":_vm.seniorityRemunerations,"headers":_vm.headers,"loading":_vm.loading,"hide-default-footer":"","no-data-text":"Nenhum faixa salarial encontrada para este cargo","loading-text":"Carregando..."},scopedSlots:_vm._u([{key:"item.minWage",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.toCurrency(item.minWage))+" ")]}},{key:"item.mediumWage",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.toCurrency(item.mediumWage))+" ")]}},{key:"item.maxWage",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.toCurrency(item.maxWage))+" ")]}},{key:"item.benefit",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.toCurrency(item.benefit))+" ")]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }