import RouteCheckerTo from '@/views/route-checker';
import VacationAdministrator from './vacation-administrator/vacation-administrator.view.vue'
import VacationBalance from './vacation-balance/vacation-balance.view.vue'

export default [
  {
    path: '/vacations',
    name: 'Férias',
    component: VacationAdministrator,
    beforeEnter: RouteCheckerTo.hasAccessVacationAdministrator,
  },
  {
    path: '/vacations-balance',
    name: 'SaldoDeFérias',
    component: VacationBalance,
    beforeEnter: RouteCheckerTo.hasAccessVacationBalance,
  },
];
