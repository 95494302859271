














import { Vue, Component } from 'vue-property-decorator';
import BirthdayService from '@/domain/birthday/birthday.service';
import Birthday from '../../domain/birthday/birthday.entity';
import BirthdayComponent from './birthday-component.vue';

@Component({ components: { BirthdayComponent } })
export default class Birthdays extends Vue {
  private birthdays: Birthday[] = []

  private nextBirthdays: Birthday[] = [];

  private getPersonalBirthday() {
    BirthdayService.getBirthdays()
      .then((response: Birthday[]) => this.birthdays = response)
  }

  private getNextBirthdays() {
    BirthdayService.getNextBirthdays()
      .then((response: Birthday[]) => this.nextBirthdays = response)
  }

  private created() {
    this.getPersonalBirthday()
    this.getNextBirthdays()
  }
}

