export default class VacationPayslipListItem {
  public id: string;

  public competence: string;

  constructor(obj: any = {}) {
    this.id = obj.id || '';
    this.competence = obj.competence || '';
  }
}
