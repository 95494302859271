















import { Vue, Component, Prop } from 'vue-property-decorator';
import { VbSnackbarService } from 'bag-of-holding-library';
import DrdService from '@/domain/drd/service/drd.service';
import Loader from '@/views/drd/commons/loader.component.vue';
import Formation from '@/domain/drd/entity/formation.entity';
import FormationComponent from './formation.component.vue';

@Component({
  components: { FormationComponent, Loader },
})
export default class FormationIndex extends Vue {
  @Prop({
    type: String,
    required: true,
  })
  private performanceDescriptionId!: string;

  private loading: boolean = true;

  private formations: Formation[] = [];

  private loadFormations(): void {
    this.loading = true;
    DrdService.getFormations(this.performanceDescriptionId).then((data) => {
      this.formations = data.map(it => new Formation(it))
        .filter(it => it.active);
    }).catch(VbSnackbarService.handleHttpError)
      .finally(() => {
        this.loading = false;
      })
  }

  private created(): void {
    this.loadFormations();
  }
}
