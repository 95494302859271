import AssessmentAdherenceTotalSkillType from './assessment-adherence-total-skill-type.entity';
import SkillType from './skill/entity/skill-type.enum';

export default class AssessmentResult {
  employeeId: string;

  employeeName: string;

  employeeAdherenceTotal: number;

  employeeResultBySkillType: AssessmentAdherenceTotalSkillType[];

  constructor(obj: any = {}) {
    this.employeeId = obj.employeeId || '';
    this.employeeName = obj.employeeName || '';
    this.employeeAdherenceTotal = obj.employeeAdherenceTotal || '';
    this.employeeResultBySkillType = obj.employeeResultBySkillType || [];
  }

  get knowledgeAdherence() {
    return this.filterAdherenceByType(SkillType.KNOWLEDGE);
  }

  get skillAdherence() {
    return this.filterAdherenceByType(SkillType.SKILL);
  }

  get behaviorAdherence() {
    return this.filterAdherenceByType(SkillType.BEHAVIOR);
  }

  filterAdherenceByType(type: string) {
    return this.employeeResultBySkillType.filter(it => it.skillType === type)[0].adherence;
  }
}
