const requestReason = [
  {
    value: 'EMPLOYEE_REQUEST',
    description: 'Colaborador(a) solicitou',
  },
  {
    value: 'MANAGER_REQUEST',
    description: 'Gestor identificou a necessidade',
  },
  {
    value: 'TEAM_REQUEST',
    description: 'Equipe identificou a necessidade',
  },
  {
    value: 'BUSINESS_PARTNER_REQUEST',
    description: 'BP Identificou a necessidade',
  },
  {
    value: 'DISSATISFACTION',
    description: 'Visto insatisfação no felicitômetro',
  },
]

export default requestReason;
