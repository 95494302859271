var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('modal-warning',{attrs:{"value":_vm.showDeleteConfirmationDialog,"question":"Deseja mesmo inativar esse nível de talento?"},on:{"ok":_vm.deleteItem,"cancel":_vm.closeDeleteConfirmationDialog}}),_c('v-data-table',{staticClass:"talent-level-list__data-table",attrs:{"items":_vm.talentLevels,"headers":_vm.headers,"loading":_vm.loading,"hide-default-footer":"","no-data-text":"Nenhum nível de talento foi cadastrado ainda","loading-text":"Carregando..."},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('talent-level-label',{staticClass:"mr-4",attrs:{"name":item.name,"color":item.color}})]}},{key:"item.color",fn:function(ref){
var item = ref.item;
return [_c('v-row',{attrs:{"justify":"center","no-gutters":""}},[_c('v-col',{attrs:{"cols":"auto"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_c('talent-level-color',{staticClass:"mr-4",attrs:{"color":item.color}})],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.color))])])],1)],1)]}},{key:"item.active",fn:function(ref){
var item = ref.item;
return [_c('p',{staticClass:"text-center mb-0 mr-4"},[_vm._v(" "+_vm._s(item.active ? 'Sim' : 'Não')+" ")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"talent-level-list__actions-container"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary","icon":"","disabled":!_vm.isUpdate},on:{"click":function($event){return _vm.editItem(item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-pencil-outline")])],1)]}}],null,true)},[_c('span',[_vm._v("Editar")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-1",attrs:{"color":"error","icon":"","disabled":!item.active || !_vm.isInactive},on:{"click":function($event){return _vm.openDeleteDialog(item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-cancel")])],1)]}}],null,true)},[_c('span',[_vm._v("Inativar")])])],1)]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }