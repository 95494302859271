









import { Vue, Component, Prop } from 'vue-property-decorator'

@Component
export default class Loading extends Vue {
  @Prop({
    type: Number,
    default: 36,
  }) size!: number;

  @Prop({
    type: String,
    default: 'primary',
  }) color!: string;
}
