













import { Vue, Component, Prop } from 'vue-property-decorator';

@Component
export default class SelectionComponent extends Vue {
  @Prop({ type: Number, default: 0 })
  private length!: number;

  @Prop({ type: Object, required: true })
  private item!: any;

  @Prop({ type: Number, default: 0 })
  private index!: number;
}
