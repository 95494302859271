
























import { Vue, Component, Prop } from 'vue-property-decorator';
import OperationModalityService from '@/domain/operation-modality/service/operation-modality.service';
import OperationModality from '@/domain/operation-modality/entity/operation-modality.entity';
import LabelSlot from '../slots/label-slot.component.vue';
import Rules from '../rules/rules';

@Component({
  components: {
    LabelSlot,
  },
})
export default class OperationModalitySelect extends Vue {
  @Prop({
    required: true,
  }) value!: any | string;

  @Prop({
    type: Boolean,
    default: false,
  }) disabled!: boolean;

  @Prop({
    type: Boolean,
    default: false,
  }) returnObject!: boolean;

  @Prop() rules!: Rules[];

  operationModalities: OperationModality[] = [];


  created() {
    this.getOperationModalities();
  }

  getOperationModalities() {
    OperationModalityService
      .getOperationModalities()
      .then((operationModalities: OperationModality[]) => {
        this.operationModalities = operationModalities
      });
  }

  change(value: any | string) {
    this.$emit('change', value);
  }

  itensDisabled(item: any) {
    return !item.active
  }
}
