



















import {
  Vue, Component, Prop, Emit,
} from 'vue-property-decorator';
import LabelSlot from '@/shared/slots/label-slot.component.vue';
import ActivitySummary from '@/domain/schedule/activitie/activity-summary.entity';
import DateFormat from 'bag-of-holding-library/src/utils/date-format';

@Component({
  components: {
    'label-slot': LabelSlot,
  },
})
export default class ModalFieldSelect extends Vue {
    @Prop({
      required: true,
    }) private label!: string;

    @Prop({
      required: false,
      default: () => [],
    }) private items!: string[];

    @Prop({
      required: false,
      default: 'Selecione uma opção',
    }) private placeholder !: string;

    @Prop({
      required: false,
      default: () => {},
    }) private value!: any;

    @Emit('changeSelect')
    private changeSelect(activity: ActivitySummary): ActivitySummary {
      return activity;
    }

    itemText(item: any): string {
      return `${item.name} - ${DateFormat.monthYear(item.expectedDate)}`
    }
}
