






import { Vue, Component } from 'vue-property-decorator';
import VacationComponent from './vacation-payslip.component.vue'

@Component({ components: { VacationComponent } })
export default class VacationPayslipView extends Vue {
}

