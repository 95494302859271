
































































































































































































import { Vue, Component, Prop } from 'vue-property-decorator';
import { VbTooltipSlot } from 'bag-of-holding-library';
import LabelSlot from '@/shared/slots/label-slot.component.vue';
import DateFormat from 'bag-of-holding-library/src/utils/date-format';
import moment from 'moment';
import SelectionComponent from '@/shared/fields/selection.componet.vue';
import SelectiveProcessFilter from '@/domain/selective-process/selective-process-filter.entity';

@Component({
  components: {
    LabelSlot,
    SelectionComponent,
    VbTooltipSlot,
  },
})
export default class SelectiveProcessFilterComponent extends Vue {
  @Prop() filter!: SelectiveProcessFilter;

  mentalGradePeriodMenu: boolean = false;

  cultureGradePeriodMenu: boolean = false;

  locale = 'pt-BR';

  isFilterCandidateEmail: boolean = false;

  isFilterJobExternalId: boolean = false;

  jobStatus: string[] = [
    'Triados',
    'Rejected',
    'Rejected Raciocício',
    'Rejected Cultura',
    'Validação de perfil',
    'Rejected Raciocínio',
    'Mapeamento de perfil',
    'Hired',
    'Perfis para Movimentação',
    'Contratado',
    'Entrevista inicial',
    'Due Diligence ',
    'Entrevista com gestor',
    'Verificar interesse',
    'Testes potenciais',
    'Inscrição',
    'Entrevista com o time',
    'Fechamento',
    'Entrevista com o Time',
  ]

  datePeriodFormat(dates: string[]) {
    return dates.map((el: string) => DateFormat.timestampToLocal(el));
  }

  setMentalGradePeriod() {
    const initialDateIsBefore = moment(this.filter.mentalGradePeriod[1])
      .isBefore(this.filter.mentalGradePeriod[0]);
    if (initialDateIsBefore) {
      this.filter.mentalGradePeriod.reverse();
    }
  }

  setCultureGradePeriod() {
    const initialDateIsBefore = moment(this.filter.cultureGradePeriod[1])
      .isBefore(this.filter.cultureGradePeriod[0]);
    if (initialDateIsBefore) {
      this.filter.cultureGradePeriod.reverse();
    }
  }

  get mentalGradePeriodText() {
    return this.datePeriodFormat(this.filter.mentalGradePeriod).join(' ~ ');
  }

  get cultureGradePeriodText() {
    return this.datePeriodFormat(this.filter.cultureGradePeriod).join(' ~ ');
  }

  clearMentalGradePeriod() {
    this.filter.mentalGradePeriod = [];
    this.sendFilter();
  }

  clearCultureGradePeriod() {
    this.filter.cultureGradePeriod = [];
    this.sendFilter();
  }

  clearFilter() {
    this.filter = new SelectiveProcessFilter();
    this.sendFilter();
  }

  sendFilter() {
    this.$emit('sendFilter', this.filter);
  }

  changeCandidateFilterFormat() {
    this.filter.candidateName = '';
    this.filter.candidatesEmails = '';
    this.isFilterCandidateEmail = !this.isFilterCandidateEmail;
  }

  get candateFilterField() {
    return this.isFilterCandidateEmail ? 'candidatesEmails' : 'candidateName';
  }

  get jobFilterField() {
    return this.isFilterJobExternalId ? 'jobsExternalId' : 'jobName';
  }

  get candateFilterInputPlaceholder() {
    return this.isFilterCandidateEmail ? 'Digite o e-mail do candidato...' : 'Digite o nome do candidato...';
  }

  get candidateFilterInputHint() {
    return this.isFilterCandidateEmail ? 'Utilize virgula para filtrar mais de um candidato por email' : '';
  }

  get jobFilterInputPlaceholder() {
    return this.isFilterJobExternalId ? 'Digite o id da vaga...' : 'Digite o nome da vaga';
  }

  get jobFilterInputHint() {
    return this.isFilterJobExternalId ? 'Utilize vigula para filtrar mais de uma vaga por id' : '';
  }
}
