export default class Assignment {
  public id: string;

  public description: string;

  public ecosystemId: string;

  public inactive: boolean;

  public roleId: string;

  public techLead: boolean;

  constructor(obj: any = {}) {
    this.id = obj.id;
    this.description = obj.description || '';
    this.ecosystemId = obj.ecosystem === null || obj.ecosystem === undefined ? '' : obj.ecosystem.id;
    this.inactive = obj.inactive === null || obj.inactive === undefined ? false : obj.inactive;
    this.roleId = obj.role === null || obj.role === undefined ? '' : obj.role.id;
    this.techLead = obj.techLead === null || obj.techLead === undefined ? false : obj.techLead;
  }
}
