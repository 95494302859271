import RouteCheckerTo from '@/views/route-checker';
import SalaryReadjustmentParametrizations from './salary-readjustment-parametrizations.view.vue';

export default [
  {
    path: '/salary-readjustment-parametrizations',
    name: 'Parametrizações de reajustes salariais',
    component: SalaryReadjustmentParametrizations,
    beforeEnter: RouteCheckerTo.hasAccessSalaryReadjustmentParametrizations,
  },
];
