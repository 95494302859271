















import { Vue, Prop, Component } from 'vue-property-decorator';

@Component
export default class ObservationIcon extends Vue {
  @Prop({
    required: false,
    default: 'mdi-alert-circle',
  })
  private icon?: string;

  @Prop({
    required: false,
    default: 'primary',
  })
  private colorIcon?: string;

  @Prop({
    required: true,
  })
  private messageTooltip!: string;
}
