import SeniorityDrd from './seniorityDrd.entity';

export default class RoleDrd {
  public id: string;

  public name: string;

  public roleType: string;

  public assignmentIds: string[];

  public seniorities: SeniorityDrd[];

  public active: boolean;

  public resignationResponsible: boolean;

  public description: string;

  public cbo: string;

  constructor(obj: any = {}) {
    this.id = obj.id;
    this.name = obj.name || '';
    this.roleType = obj.roleType || '';
    this.assignmentIds = obj.assignmentIds || [];
    this.seniorities = obj.seniorities ? SeniorityDrd.ofList(obj.seniorities) : [];
    this.active = obj.active === null || obj.active === undefined ? false : obj.active;
    this.resignationResponsible = obj.resignationResponsible === null
     || obj.resignationResponsible === undefined
      ? false : obj.resignationResponsible;
    this.description = obj.description || '';
    this.cbo = obj.cbo || '-'
  }
}
