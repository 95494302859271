/* eslint-disable no-use-before-define */
import AuthorityEnum from '@/domain/security/authority.enum';
import AuthorityStore from '@/domain/security/authority.store';

async function respIsAdmin(isAdministrator: boolean) {
  await AuthorityStore.isAdministrator()
    .then((resp: any) => isAdministrator = resp);
  return isAdministrator;
}

async function respIsBusinnesPartner(isBusinnesPartner: boolean) {
  await AuthorityStore.isBusinnesPartner()
    .then((resp: any) => isBusinnesPartner = resp);
  return isBusinnesPartner;
}

async function respIsManager(isManager: boolean) {
  await AuthorityStore.isManager()
    .then((resp: any) => isManager = resp);
  return isManager;
}

async function respIsEmployeeAdministrator(isEmployeeAdministrator: boolean) {
  await AuthorityStore.isEmployeeAdministrator()
    .then((resp: any) => isEmployeeAdministrator = resp);
  return isEmployeeAdministrator;
}

async function respIsFacilitiesAdministrator(isFacilitiesAdministrator: boolean) {
  await AuthorityStore.isFacilitiesAdministrator()
    .then((resp: any) => isFacilitiesAdministrator = resp);
  return isFacilitiesAdministrator;
}

async function respIsPersonalDepartment(isPersonalDepartment: boolean) {
  await AuthorityStore.isPersonalDepartment()
    .then((resp: any) => isPersonalDepartment = resp);
  return isPersonalDepartment;
}

async function respIsSkillManagement(isSkillManagement: boolean) {
  await AuthorityStore.isSkillManagement()
    .then((resp: any) => isSkillManagement = resp);
  return isSkillManagement;
}

async function respIsSkillMap(isSkillMap: boolean) {
  await AuthorityStore.isSkillMap()
    .then((resp: any) => isSkillMap = resp);
  return isSkillMap;
}

const isBusinnesPartnerOrAdm = async (to, from, next) => {
  let isAdministrator = false;
  let isBusinnesPartner = false;

  isAdministrator = await respIsAdmin(isAdministrator);
  isBusinnesPartner = await respIsBusinnesPartner(isBusinnesPartner);

  const authorized = isAdministrator || isBusinnesPartner;
  if (!authorized) {
    next(deniedPath);
    return;
  }
  next();
}

const isBusinnesPartnerOrManager = async (to, from, next) => {
  let isAdministrator = false;
  let isBusinnesPartner = false;
  let isManager = false;

  isAdministrator = await respIsAdmin(isAdministrator);
  isBusinnesPartner = await respIsBusinnesPartner(isBusinnesPartner);
  isManager = await respIsManager(isManager);

  const authorized = isAdministrator || isManager || isBusinnesPartner;

  if (!authorized) {
    next(deniedPath);
    return;
  }
  next();
}

const isBusinnesPartnerOrEmployeeAdministrator = async (to, from, next) => {
  let isAdministrator = false;
  let isBusinnesPartner = false;
  let isEmployeeAdministrator = false;

  isAdministrator = await respIsAdmin(isAdministrator);
  isBusinnesPartner = await respIsBusinnesPartner(isBusinnesPartner);
  isEmployeeAdministrator = await respIsEmployeeAdministrator(isEmployeeAdministrator);

  const authorized = isAdministrator || isBusinnesPartner || isEmployeeAdministrator;

  if (!authorized) {
    next(deniedPath);
    return;
  }
  next();
}

const isBusinnesPartnerOrEmployeeAdministratorOrManager = async (to, from, next) => {
  let isAdministrator = false;
  let isBusinnesPartner = false;
  let isEmployeeAdministrator = false;
  let isManager = false;

  isAdministrator = await respIsAdmin(isAdministrator);
  isBusinnesPartner = await respIsBusinnesPartner(isBusinnesPartner);
  isManager = await respIsManager(isManager);
  isEmployeeAdministrator = await respIsEmployeeAdministrator(isEmployeeAdministrator);

  const authorized = isAdministrator || isManager || isBusinnesPartner || isEmployeeAdministrator;

  if (!authorized) {
    next(deniedPath);
    return;
  }
  next();
}

const isEmployeeAdministratorOrManager = async (to, from, next) => {
  let isAdministrator = false;
  let isEmployeeAdministrator = false;
  let isManager = false;

  isAdministrator = await respIsAdmin(isAdministrator);
  isManager = await respIsManager(isManager);
  isEmployeeAdministrator = await respIsEmployeeAdministrator(isEmployeeAdministrator);

  const authorized = isAdministrator || isManager || isEmployeeAdministrator;

  if (!authorized) {
    next(deniedPath);
    return;
  }
  next();
}

const isBusinnesPartnerOrEmployeeAdminOrFacilitiesAdmin = async (to, from, next) => {
  let isAdministrator = false;
  let isBusinnesPartner = false;
  let isEmployeeAdministrator = false;
  let isFacilitiesAdministrator = false;

  isAdministrator = await respIsAdmin(isAdministrator);
  isBusinnesPartner = await respIsBusinnesPartner(isBusinnesPartner);
  isEmployeeAdministrator = await respIsEmployeeAdministrator(isEmployeeAdministrator);
  isFacilitiesAdministrator = await respIsFacilitiesAdministrator(isFacilitiesAdministrator);

  const authorized = isAdministrator
  || isBusinnesPartner
  || isEmployeeAdministrator
  || isFacilitiesAdministrator;

  if (!authorized) {
    next(deniedPath);
    return;
  }
  next();
}

const isBusinnesPartnerOrPersonalDepartmentOrManagerAdmin = async (to, from, next) => {
  let isAdministrator = false;
  let isBusinnesPartner = false;
  let isManager = false;
  let isPersonalDepartment = false;

  isAdministrator = await respIsAdmin(isAdministrator);
  isBusinnesPartner = await respIsBusinnesPartner(isBusinnesPartner);
  isManager = await respIsManager(isManager);
  isPersonalDepartment = await respIsPersonalDepartment(isPersonalDepartment);

  const authorized = isAdministrator
  || isBusinnesPartner
  || isManager
  || isPersonalDepartment;

  if (!authorized) {
    next(deniedPath);
    return;
  }
  next();
}

const isBusPartnerOrEmployeeAdminOrPersonalDepartmentOrManagerAdmin = async (to, from, next) => {
  let isAdministrator = false;
  let isBusinnesPartner = false;
  let isEmployeeAdministrator = false;
  let isManager = false;
  let isPersonalDepartment = false;

  isAdministrator = await respIsAdmin(isAdministrator);
  isBusinnesPartner = await respIsBusinnesPartner(isBusinnesPartner);
  isEmployeeAdministrator = await respIsEmployeeAdministrator(isEmployeeAdministrator);
  isManager = await respIsManager(isManager);
  isPersonalDepartment = await respIsPersonalDepartment(isPersonalDepartment);

  const authorized = isAdministrator
  || isBusinnesPartner
  || isEmployeeAdministrator
  || isManager
  || isPersonalDepartment;

  if (!authorized) {
    next(deniedPath);
    return;
  }
  next();
}

const isPersonalDepartmentAdmin = async (to, from, next) => {
  let isAdministrator = false;
  let isPersonalDepartment = false;

  isAdministrator = await respIsAdmin(isAdministrator);
  isPersonalDepartment = await respIsPersonalDepartment(isPersonalDepartment);

  const authorized = isAdministrator || isPersonalDepartment;

  if (!authorized) {
    next(deniedPath);
    return;
  }
  next();
}

const isPersonalDepartmentOrManagerAdmin = async (to, from, next) => {
  let isAdministrator = false;
  let isPersonalDepartment = false;
  let isManager = false;

  isAdministrator = await respIsAdmin(isAdministrator);
  isPersonalDepartment = await respIsPersonalDepartment(isPersonalDepartment);
  isManager = await respIsManager(isManager);

  const authorized = isAdministrator || isPersonalDepartment || isManager;

  if (!authorized) {
    next(deniedPath);
    return;
  }
  next();
}

const isManagerAdmin = async (to, from, next) => {
  let isAdministrator = false;
  let isManager = false;

  isAdministrator = await respIsAdmin(isAdministrator);
  isManager = await respIsManager(isManager);

  const authorized = isAdministrator || isManager
  if (!authorized) {
    next(deniedPath);
    return;
  }
  next();
}

const isAdmin = async (to, from, next) => {
  let isAdministrator = false;
  isAdministrator = await respIsAdmin(isAdministrator);
  if (!isAdministrator) {
    next(deniedPath);
    return;
  }
  next();
}

const hasAccessSkillManagement = async (to, from, next) => {
  let isSkillManagement = false;
  isSkillManagement = await respIsSkillManagement(isSkillManagement);
  if (!isSkillManagement) {
    next(deniedPath);
    return;
  }
  next();
}

const hasAccessSkillMap = async (to, from, next) => {
  let isSkillMap = false;
  isSkillMap = await respIsSkillMap(isSkillMap);
  if (!isSkillMap) {
    next(deniedPath);
    return;
  }
  next();
}

const deniedPath = '/denied';

const hasAccessUpdateDrd = async (to, from, next) => {
  let hasUpdateDrd = false
  await AuthorityStore.hasUpdateDrd().then(resp => hasUpdateDrd = resp)
  return hasUpdateDrd ? next() : next(deniedPath)
}

const hasAccessGodfatherExport = async (to, from, next) => {
  let hasGodfatherExport = false;
  await AuthorityStore.hasAccessGodfatherExport().then(resp => hasGodfatherExport = resp);
  return hasGodfatherExport ? next() : next(deniedPath);
}

const hasAccessPersonalDataExport = async (to, from, next) => {
  let hasPersonalDataExport = false;
  await AuthorityStore.hasAccessPersonalDataExport().then(resp => hasPersonalDataExport = resp);
  return hasPersonalDataExport ? next() : next(deniedPath);
}

const hasAccessAttendance = async (to, from, next) => {
  const hasAttendance = await AuthorityStore.hasAccessAttendance();
  return hasAttendance ? next() : next(deniedPath);
}

const hasAccessResignations = async (to, from, next) => {
  const hasResignations = await AuthorityStore.hasAccessResignations();
  return hasResignations ? next() : next(deniedPath);
}

const hasAccessAssignments = async (to, from, next) => {
  const hasAssignments = await AuthorityStore.hasAccessAssignments();
  return hasAssignments ? next() : next(deniedPath);
}

const hasAccessRoles = async (to, from, next) => {
  const hasRoles = await AuthorityStore.hasAccessRoles();
  return hasRoles ? next() : next(deniedPath);
}

const hasAccessRiskAttendance = async (to, from, next) => {
  let hasRiskAttendance = false;
  await AuthorityStore.hasAccessRiskAttendance().then(resp => hasRiskAttendance = resp);
  return hasRiskAttendance ? next() : next(deniedPath);
}

const hasAccessWages = async (to, from, next) => {
  let hasWages = false;
  await AuthorityStore.hasAccessWages().then(resp => hasWages = resp);
  return hasWages ? next() : next(deniedPath);
}

const hasAccessWagasTable = async (to, from, next) => {
  let hasWagesTable = false;
  await AuthorityStore.hasAccessWagesTable().then(resp => hasWagesTable = resp);
  return hasWagesTable ? next() : next(deniedPath);
}

const hasAccessSecurity = async (to, from, next) => {
  const hasSecurity = await AuthorityStore.hasAccessSecurity();
  return hasSecurity ? next() : next(deniedPath);
}

const hasAccessVacationAdministrator = async (to, from, next) => {
  const hasVacationAdministrator = await AuthorityStore.hasAccessVacationAdministrator();
  return hasVacationAdministrator ? next() : next('denied');
}

const hasAccessVacationBalance = async (to, from, next) => {
  const hasVacationBalance = await AuthorityStore.hasAccessVacationBalance();
  return hasVacationBalance ? next() : next(deniedPath);
}

const hasAccessTalentLevel = async (to, from, next) => {
  const hasTalentLevel = await AuthorityStore.hasAccessTalentLevel();
  return hasTalentLevel ? next() : next(deniedPath);
}

const hasAccessMessageDaisy = async (to, from, next) => {
  const hasMessageDaisy = await AuthorityStore.hasAccessMessageDaisy();
  return hasMessageDaisy ? next() : next(deniedPath);
}

const hasAccessImportVacationPayslip = async (to, from, next) => {
  const hasImportVacationPayslip = await AuthorityStore.hasAccessImportVacationPayslip()
  return hasImportVacationPayslip ? next() : next(deniedPath)
}

const hasAccessReadEmployeeRemuneration = async (to, from, next) => {
  const hasAccessEmployeeRemuneration = await AuthorityStore.hasReadEmployeeRemunaration();
  return hasAccessEmployeeRemuneration ? next() : next(deniedPath);
}

const hasAccessReadAgreement = async (to, from, next) => {
  const hasReadAgreement = await AuthorityStore.hasReadAgreement();
  return hasReadAgreement ? next() : next(deniedPath);
}

const hasAccessSalaryReadjustmentParametrizations = async (to, from, next) => {
  const hasSalaryReadjustmentParametrization = await AuthorityStore
    .hasAccessSalaryReadjustmentParametrization();
  return hasSalaryReadjustmentParametrization ? next() : next(deniedPath);
}

const hasAccessCreateInitialScore = async (to, from, next) => {
  const hasCreateInitialScore = await AuthorityStore
    .hasAccessCreateInitialScore();
  return hasCreateInitialScore ? next() : next(deniedPath);
}

const hasAccessSelectiveProcess = async (to, from, next) => {
  const hasReadSelectiveProcess = await AuthorityStore
    .hasAccessSelectiveProcess();
  return hasReadSelectiveProcess ? next() : next(deniedPath);
}

const hasAccessReadIndicatorDefaultManagement = async (to, from, next) => {
  const hasReadIndicatorDefaultManagement = await AuthorityStore
    .hasAccessReadIndicatorDefaultManagement();
  return hasReadIndicatorDefaultManagement ? next() : next(deniedPath);
}

const hasAccessReadAssessmentResult = async (to, from, next) => {
  const hasReadAssessmentResult = await AuthorityStore
    .hasReadAssessmentResult();
  return hasReadAssessmentResult ? next() : next(deniedPath);
}

const hasAccessReadSkillKeyArea = async (to, from, next) => {
  const hasReadSkillKeyArea = await AuthorityStore
    .hasReadSkillKeyArea();
  return hasReadSkillKeyArea ? next() : next(deniedPath);
}

const hasAccessReadKeyAreasList = async (to, from, next) => {
  const hasReadKeyArea = await AuthorityStore
    .hasAuthority([AuthorityEnum.READ_KEYAREA]);
  return hasReadKeyArea ? next() : next(deniedPath);
}

const hasAccessCreateKeyAreas = async (to, from, next) => {
  const hasCreateKeyArea = await AuthorityStore
    .hasAuthority([AuthorityEnum.CREATE_KEYAREA]);
  return hasCreateKeyArea ? next() : next(deniedPath);
}

const hasAccessUpdateKeyAreas = async (to, from, next) => {
  const hasUpdateKeyArea = await AuthorityStore
    .hasAuthority([AuthorityEnum.UPDATE_KEYAREA]);
  return hasUpdateKeyArea ? next() : next(deniedPath);
}

const hasAccessUpdateDRD = async (to, from, next) => {
  const hasUpdateDRD = await AuthorityStore
    .hasAuthority([AuthorityEnum.UPDATE_DRD]);
  return hasUpdateDRD ? next() : next(deniedPath);
}

const hasAccessCreateDRD = async (to, from, next) => {
  const hasCreateDRD = await AuthorityStore
    .hasAuthority([AuthorityEnum.CREATE_DRD]);
  return hasCreateDRD ? next() : next(deniedPath);
}

export default {
  isAdmin,
  isManagerAdmin,
  isBusinnesPartnerOrAdm,
  isPersonalDepartmentAdmin,
  isPersonalDepartmentOrManagerAdmin,
  isBusinnesPartnerOrManager,
  isBusinnesPartnerOrEmployeeAdministrator,
  isBusinnesPartnerOrEmployeeAdministratorOrManager,
  isBusinnesPartnerOrEmployeeAdminOrFacilitiesAdmin,
  isBusinnesPartnerOrPersonalDepartmentOrManagerAdmin,
  isBusPartnerOrEmployeeAdminOrPersonalDepartmentOrManagerAdmin,
  isEmployeeAdministratorOrManager,
  hasAccessUpdateDrd,
  hasAccessGodfatherExport,
  hasAccessPersonalDataExport,
  hasAccessAttendance,
  hasAccessResignations,
  hasAccessAssignments,
  hasAccessRoles,
  hasAccessRiskAttendance,
  hasAccessWages,
  hasAccessSecurity,
  hasAccessWagasTable,
  hasAccessVacationAdministrator,
  hasAccessVacationBalance,
  hasAccessTalentLevel,
  hasAccessSalaryReadjustmentParametrizations,
  hasAccessMessageDaisy,
  hasAccessImportVacationPayslip,
  hasAccessReadEmployeeRemuneration,
  hasAccessReadAgreement,
  hasAccessCreateInitialScore,
  hasAccessSelectiveProcess,
  hasAccessSkillManagement,
  hasAccessSkillMap,
  hasAccessReadIndicatorDefaultManagement,
  hasAccessReadAssessmentResult,
  hasAccessReadSkillKeyArea,
  hasAccessReadKeyAreasList,
  hasAccessCreateKeyAreas,
  hasAccessUpdateKeyAreas,
  hasAccessUpdateDRD,
  hasAccessCreateDRD,
}
