










































































import { Vue, Component, Prop } from 'vue-property-decorator';
import Assessment from '@/domain/selective-process/entity/assessment/assessment.entity';
import CopyClipboardService from '@/shared/utils/copy-clipboard.service';
import DepartmentsModal from './modal/departments-modal.component.vue';
import ExportCultureGradeButton from './components/export-culture-grade-button.component.vue';
import ExportSelectiveProcessDetailsButton from './components/export-selective-process-details-button.component.vue';

@Component({
  components: {
    DepartmentsModal,
    ExportCultureGradeButton,
    ExportSelectiveProcessDetailsButton,
  },
})
export default class SelectiveProcessHeader extends Vue {
  @Prop({
    required: true,
  }) assessments!: Assessment[];

  @Prop({
    type: Boolean,
    default: false,
  }) loading!: boolean;

  openDepartmentsModal: boolean = false;

  copyLink(acronymLanguage: string) {
    CopyClipboardService
      .copyToClipboard(
        `${process.env.VUE_APP_REDIRECT_URL}survey?lang=${acronymLanguage}`,
      );
  }
}
