export default class RoleFilter {
  roles: string[] = [];

  roleName: string = '';

  assignments: string[] = [];

  assignmentDescription = '';

  cbo: string[] = [];

  cboInput: string = '';

  active: boolean = true;

  constructor(obj: any = {}) {
    this.roles = obj.roles || [];
    this.assignments = obj.assignments || [];
    this.cbo = obj.cbo || [];
  }

  get hasRoles() {
    return this.roles.length > 0;
  }

  get hasAssignments() {
    return this.assignments.length > 0;
  }

  get hasCbo() {
    return this.cbo.length > 0;
  }
}
