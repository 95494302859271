

























































import { Vue, Component } from 'vue-property-decorator';
import TranslateModal from './translate-modal.component.vue'

@Component({
  components: {
    TranslateModal,
  },
})
export default class TranslateMenuComponent extends Vue {
  items = [
    {
      title: 'Chrome',
      source: 'chrome',
      icon: 'mdi-google-chrome',
    },
    {
      title: 'Edge',
      source: 'edge',
      icon: 'mdi-microsoft-edge',
    },
    {
      title: 'Safari',
      source: 'safari',
      icon: 'mdi-apple-safari',
    },
    {
      title: 'Firefox',
      source: 'firefox',
      icon: 'mdi-firefox',
    },
  ]

  private openModal: boolean = false;

  private source: string = '';

  openTranslateModal(item: any) {
    this.source = item.source;
    this.openModal = true;
  }

  closeModal() {
    this.openModal = false;
  }
}
