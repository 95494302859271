import axios from 'axios';
import Response from '../entity/response.entity';
import Wages from '../entity/wages.entity';
import PersistSeniorityRemuneration from '../entity/persist-seniority-remuneration.entity';
import WageHistory from '../entity/wage-history.entity';

export default class WageService {
  public static findWages(page: number, size: number,
    roleId?: string, seniorityId?: string): Promise<Response> {
    return axios.get('/api/wage', {
      params: {
        page: page - 1,
        size,
        seniorityId,
        roleId,
      },
    })
      .then((response: any) => Response.of(response.data));
  }

  public static findWagesByRole(roleId: string): Promise<Wages[]> {
    return axios.get(`/api/wage/${roleId}`)
      .then((response: any) => Wages.ofList(response.data));
  }

  public static async save(roleId: string, body: PersistSeniorityRemuneration): Promise<void> {
    return axios.post(`/api/wage/${roleId}`, body);
  }

  public static async getHistory(roleId: string): Promise<WageHistory[]> {
    return axios.get(`/api/wage/${roleId}/history`)
      .then(({ data }) => data.map(it => new WageHistory(it)));
  }
}
