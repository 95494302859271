import moment from 'moment';
import VacationEmployee from './vacation-employee.entity';

export default class VacationPeriod {
  id: string;

  employeeAdmissionDate: string;

  endPeriod: string;

  startPeriod: string;

  limitVacation: string;

  rightDays: number;

  daysOff: number;

  period: number;

  balance: number;

  vacations: VacationEmployee[]

  constructor(obj: any = {}) {
    this.id = obj.id || null;
    this.endPeriod = obj.endPeriod || ''
    this.employeeAdmissionDate = obj.employeeAdmissionDate || '';
    this.startPeriod = obj.startPeriod || '';
    this.limitVacation = obj.limitVacation || '';
    this.rightDays = obj.rightDays || 0;
    this.daysOff = obj.daysOff || 0;
    this.vacations = this.listOrderVacation(obj.vacations);
    this.period = obj.period || moment().year();
    this.balance = obj.balance || 0;
    this.calculateBalanceOfVacation();
  }

  private listOrderVacation(vacations): VacationEmployee[] {
    return vacations
      ? vacations.sort((a: VacationEmployee, b: VacationEmployee) => parseInt(moment(a.startDate).format('YYYYMMDD'), 0)
        - parseInt(moment(b.startDate).format('YYYYMMDD'), 0))
        .map(el => new VacationEmployee(el))
      : [];
  }

  private calculateBalanceOfVacation(): void {
    let { rightDays } = this;
    this.vacations.forEach((vacation) => {
      if (vacation.vacationType === 'NORMAL') {
        const start = moment(vacation.startDate, 'YYYY-MM-DD');
        const end = moment(vacation.endDate, 'YYYY-MM-DD');
        vacation.daysWithTypeNormal = end.diff(start, 'days') + 1;
        vacation.balance = rightDays - vacation.daysWithTypeNormal;
      } else {
        vacation.balance = rightDays - vacation.days;
      }
      rightDays = vacation.balance;
    })
  }

  get year() {
    return this.endPeriod.substring(0, 4);
  }
}
