import moment from 'moment';
import BackupTypesSelect from '@/views/employees/profile/resignation/backup-types-select.json';

export default class Resignation {
  employee: string;

  demissionType: any = 'VOLUNTARY'

  requestDate: string;

  accessExpiration: string;

  accessTimeExpiration: string;

  shutdownTime: string;

  director: string;

  devolutionType: string;

  responsible: string;

  city: string;

  state: string;

  employeeCellPhone: string;

  employeeEmail: string;

  complyNotice: boolean = false;

  noticeStartDate: any;

  observation: string;

  backupTypesSelect = BackupTypesSelect;

  backupTypes : string[];

  recommendRehiring: boolean | undefined = undefined;

  recommendRehiringJustification: string;

  constructor(obj: any) {
    this.employee = obj.employee || '';
    this.requestDate = obj.requestDate || '';
    this.accessExpiration = obj.accessExpiration || '';
    this.shutdownTime = obj.shutdownTime || '';
    this.director = obj.director || '';
    this.devolutionType = obj.devolutionType || '';
    this.responsible = obj.responsible || '';
    this.city = obj.city || '';
    this.state = obj.state || '';
    this.employeeCellPhone = obj.employeeCellPhone || '';
    this.employeeEmail = obj.employeeEmail || '';
    this.noticeStartDate = obj.noticeStartDate || '';
    this.observation = obj.observation || '';
    this.accessTimeExpiration = obj.accessTimeExpiration || '';
    this.backupTypes = obj.backupTypes || ['EMAIL', 'ONE_DRIVE', 'LOCAL_FILES'];
    this.recommendRehiring = obj.recommendRehiring;
    this.recommendRehiringJustification = obj.recommendRehiringJustification || '';
  }

  public static of(obj: any): Resignation {
    return new Resignation(obj || {})
  }

  public static ofList(obj: any[]): Resignation[] {
    return obj.map(this.of);
  }

  isVoluntary(): boolean {
    return this.demissionType === 'VOLUNTARY';
  }

  isAccessExpirationValid(): boolean {
    const accessExpiration = moment(this.accessExpiration).format('YYYY-MM-DD');
    const requestDate = moment(this.requestDate).format('YYYY-MM-DD');
    return Boolean(requestDate <= accessExpiration);
  }

  isAccessExpirationBiggerOrEqualsThanNow() {
    const accessExpiration = moment(this.accessExpiration).format('YYYY-MM-DD');
    const currentTime = moment().format('YYYY-MM-DD');
    return Boolean(accessExpiration >= currentTime);
  }

  accessExpiratiosRules() {
    const errorMessages: string[] = [];
    if (!this.accessExpiration) {
      errorMessages.push('Valor Obrigatório');
    }
    if (!this.isAccessExpirationValid()) {
      errorMessages.push('Data deve ser maior ou igual que a do pedido')
    }
    if (!this.isAccessExpirationBiggerOrEqualsThanNow()) {
      errorMessages.push('Data deve ser maior ou igual a atual')
    }
    return errorMessages;
  }

  isNoticeStartDateValid(): boolean {
    const noticeStartDate = new Date(this.noticeStartDate);
    const requestDate = new Date(this.requestDate);
    return Boolean(requestDate.getTime() <= noticeStartDate.getTime());
  }

  isObservationRequired() {
    const timeLimit = new Date('1970-01-01T16:30:00Z');
    const accessExpiration = new Date(`1970-01-01T${this.accessTimeExpiration}:00Z`);
    return Boolean(timeLimit.getTime() < accessExpiration.getTime());
  }

  isShutDownTimeValid(): boolean {
    const shutdownTime = new Date(`1970-01-01T${this.shutdownTime}:00Z`);
    const accessTimeExpiration = new Date(`1970-01-01T${this.accessTimeExpiration}:00Z`);
    return Boolean(shutdownTime.getTime() > accessTimeExpiration.getTime());
  }

  observationRules() {
    const errorMessages: string[] = [];
    if (this.isObservationRequired()) {
      if (!this.observation) {
        errorMessages.push('Valor Obrigatório');
      }
      if (this.observation.length < 20) {
        errorMessages.push('Observação deve conter no mínimo 20 caracteres');
      }
    }
    return errorMessages;
  }

  isObservationValid() {
    let isValid = true;
    if (this.isObservationRequired()) {
      if (!this.observation) {
        isValid = false;
      }
      if (this.observation.length < 20) {
        isValid = false;
      }
    }
    return isValid;
  }

  noticeStartRules() {
    const errorMessages: string[] = [];
    if (this.complyNotice) {
      if (!this.isNoticeStartDateValid()) {
        errorMessages.push('Data menor à do pedido')
      }
      if (this.noticeStartDate === '' || !this.noticeStartDate) {
        errorMessages.push('Valor Obrigatório');
      }
    }
    return errorMessages;
  }

  shutDownRules() {
    const errorMessages: string[] = [];
    if (!this.shutdownTime || !this.accessTimeExpiration) {
      errorMessages.push('Valor Obrigatório');
    }
    if (!this.isShutDownTimeValid()) {
      errorMessages.push('Horário precisa ser maior que o horário de saída');
    }
    return errorMessages;
  }
}
