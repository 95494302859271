import ActionPlan from './action-plan.entity';
import FormFeedbackPoint from './form-feedback-point.entity';

export default class FormFeedback {
  id: string;

  points: FormFeedbackPoint[];

  constructor(obj: any = {}) {
    this.id = obj.id || null;
    this.points = obj.points ? obj.points.map(it => new FormFeedbackPoint(it)) : [];
  }

  get positivePoints() {
    return this.points.filter(point => point.positive === true);
  }

  get negativePoints() {
    return this.points.filter(point => point.positive === false);
  }
}
