




















import { Vue, Component, Prop } from 'vue-property-decorator';
import DateFormat from '@/shared/utils/date-format';
import VacationEmployee from '@/domain/vacation/entity/vacation-employee.entity';
import headers from './vacation-details-header.json';

@Component
export default class VacationEmployeeListComponent extends Vue {
  @Prop({
    type: Boolean,
    default: false,
  })
  loading!: boolean;

  @Prop({
    type: Array,
    required: true,
  })
  vacations!: VacationEmployee[];

  headers: any = headers;

  formatFullDate(date: string): string {
    return DateFormat.timestampToLocal(date);
  }
}
