import City from '@/domain/states/entity/city.entity';
import State from '@/domain/states/entity/state.entity';
import DatesCelebrate from '@/views/employees/profile/tab-components/personal-data/dates-celebrate.json';
import ChildrenData from './children-data.entity';

export default class PersonalData {
  companyEmail: string;

  privateEmail: string;

  birthDate: string;

  cellPhone: string;

  residentialPhone: string;

  address: string;

  addressNumber: string;

  district: string;

  complement: string;

  city: City;

  state: State;

  country: string;

  postalCode: string;

  gender: any;

  genderDetail: string;

  maritalStatus: any;

  religion: string | null;

  specifiedReligion: string;

  hasChildren: boolean = false;

  children: ChildrenData[];

  observation: string;

  emergencyName: string;

  emergencyPhone: string;

  emergencyRelationship: string;

  cityAux: string = '';

  nickname: string;

  usesIfood: boolean;

  shirtSize: string;

  consumeAlcoholicDrink: boolean;

  foodAllergies: string[];

  foodDoNotLike: string;

  commemorativeDates : string[]

  commemorativeDatesAux : string[] = Array.of()

  datesCelebrate = DatesCelebrate;

  usesIfoodAux: string;

  consumeAlcoholicDrinkAux: string;

  lastModifiedDate: string;

  foreignState: string;

  foreignCity: string;

  constructor(obj: any) {
    this.companyEmail = obj.companyEmail || '';
    this.privateEmail = obj.privateEmail || '';
    this.birthDate = obj.birthDate || '';
    this.cellPhone = obj.cellPhone || '';
    this.residentialPhone = obj.residentialPhone || '';
    this.address = obj.address || '';
    this.addressNumber = obj.addressNumber || '';
    this.district = obj.district || '';
    this.complement = obj.complement || '';
    this.city = obj.city || City.of({});
    this.state = obj.state || State.of({});
    this.country = obj.country || null;
    this.postalCode = obj.postalCode || '';
    this.gender = obj.gender || '';
    this.genderDetail = obj.genderDetail || '';
    this.maritalStatus = obj.maritalStatus || '';
    this.religion = obj.religion || '';
    this.specifiedReligion = obj.specifiedReligion || '';
    this.hasChildren = obj.hasChildren || false;
    this.children = obj.children
      ? ChildrenData.ofList(obj.children) : [];
    this.observation = obj.observation || '';
    this.emergencyName = obj.emergencyName || '';
    this.emergencyPhone = obj.emergencyPhone || '';
    this.emergencyRelationship = obj.emergencyRelationship || '';
    this.nickname = obj.nickname || '';
    this.usesIfood = this.defaultOptionTrue(obj.usesIfood);
    this.shirtSize = obj.shirtSize || '';
    this.consumeAlcoholicDrink = this.defaultOptionTrue(obj.consumeAlcoholicDrink);
    this.foodAllergies = obj.foodAllergies || ['NO_RESTRICTIONS'];
    this.foodDoNotLike = obj.foodDoNotLike || '';
    this.commemorativeDates = obj.commemorativeDates || this.setCommemorativeDates();
    this.consumeAlcoholicDrinkAux = this.consumeAlcoholicDrink ? 'Sim' : 'Não';
    this.usesIfoodAux = this.usesIfood ? 'Sim' : 'Não';
    this.lastModifiedDate = obj.lastModifiedDate || '';
    this.foreignState = obj.foreignState || '';
    this.foreignCity = obj.foreignCity || '';
  }

  setCommemorativeDates(): string[] {
    this.datesCelebrate.filter(date => date.value !== 'NONE').map(el => this.commemorativeDatesAux.push(el.value))
    return this.commemorativeDatesAux
  }

  private defaultOptionTrue(value: any): boolean {
    if (value === undefined || value === null || value) return true;
    return false;
  }

  public static of(obj: any): PersonalData {
    return new PersonalData(obj || {})
  }

  public static ofList(obj: any[]): PersonalData[] {
    return obj.map(this.of);
  }

  hasChildrenOption() {
    if (this.hasChildren) {
      return this.hasChildren;
    }
    return this.hasChildren;
  }

  addNewChild() {
    this.children.push(ChildrenData.of({}));
  }

  verifyHasChildrenOption() {
    if (!this.hasChildren) {
      this.children = [];
    }
  }

  verifyStateChange() {
    if (!this.state) {
      this.city.id = '';
      this.cityAux = '';
    }
  }

  verifyMissingData() {
    return ((this.emergencyName == null || !this.emergencyName) && this.birthDate);
  }

  public equals(obj: PersonalData): boolean {
    return this.companyEmail === obj.companyEmail
    && this.privateEmail === obj.privateEmail
    && this.birthDate === obj.birthDate
    && this.cellPhone === obj.cellPhone
    && this.residentialPhone === obj.residentialPhone
    && this.address === obj.address
    && this.addressNumber === obj.addressNumber
    && this.district === obj.district
    && this.complement === obj.complement
    && this.city === obj.city
    && this.state === obj.state
    && this.postalCode === obj.postalCode
    && this.gender === obj.gender
    && this.genderDetail === obj.genderDetail
    && this.maritalStatus === obj.maritalStatus
    && this.hasChildren === obj.hasChildren
    && this.observation === obj.observation
    && !this.childrenHasChanges(obj.children)
    && this.emergencyName === obj.emergencyName
    && this.emergencyPhone === obj.emergencyPhone
    && this.emergencyRelationship === obj.emergencyRelationship
    && this.nickname === obj.nickname
    && this.usesIfood === obj.usesIfood
    && this.shirtSize === obj.shirtSize
    && this.consumeAlcoholicDrink === obj.consumeAlcoholicDrink
    && this.foodAllergies === obj.foodAllergies
    && this.foodDoNotLike === obj.foodDoNotLike
    && this.commemorativeDates === obj.commemorativeDates;
  }

  childrenHasChanges(children: ChildrenData[]):boolean {
    let hasChanges = false;
    if (this.children) {
      if (this.hasNewChild()) {
        hasChanges = true;
      } else {
        this.children.forEach((child: ChildrenData) => {
          const foundChildren = children.find(el => el.id === child.id)
          if (foundChildren) {
            if (!foundChildren.equals(child)) {
              hasChanges = true;
            }
          }
        })
      }
    }
    return hasChanges;
  }

  hasNewChild(): boolean {
    const newChild = this.children.find((el: ChildrenData) => el.id === '');
    if (newChild) {
      return true;
    }
    return false;
  }
}
