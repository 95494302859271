import axios from 'axios';
import SelectiveProcessFilter from '../selective-process-filter.entity';
import Department from '../entity/job/department.entity';
import CandidatePersonalityResult from '../entity/candidate/candidate-personality-result.entity';
import CandidateResult from '../entity/candidate/candidate-result.entity';

export default class SelectiveProcessService {
  public static findAssessments(
    filter: SelectiveProcessFilter,
    page: number,
    size: number,
    sortBy: string,
  ) {
    return axios.get('/api/selective-process', {
      params: {
        job: filter.hasJobs ? filter.jobs : null,
        candidatesEmails: filter.hasCandidatesEmails ? filter.candidatesEmails : null,
        candidateName: filter.hasCandidateName ? filter.candidateName : null,
        mentalGrade: filter.hasMentalGrade ? parseFloat(filter.mentalGrade) : null,
        mentalGradePeriodStart: filter.hasMentalGradePeriod ? filter.mentalGradePeriod[0] : null,
        mentalGradePeriodEnd: filter.hasMentalGradePeriod ? filter.mentalGradePeriod[1] : null,
        cultureGrade: filter.hasCultureGrade ? parseFloat(filter.cultureGrade) : null,
        cultureGradePeriodStart: filter.hasCultureGradePeriod
          ? filter.cultureGradePeriod[0] : null,
        cultureGradePeriodEnd: filter.hasCultureGradePeriod
          ? filter.cultureGradePeriod[1] : null,
        jobApplicationStatus: filter.hasJobStatus ? filter.jobStatus.toString() : null,
        page: page - 1,
        size,
        orderBy: sortBy,
      },
    });
  }

  public static getCultureGradeDetails(
    listCandidateID: string[],
  ) {
    return axios.get('/api/selective-process/cultureGradeDetails', {
      params: {
        candidates: listCandidateID.toString(),
      },
    });
  }

  public static getCandidateCultureGradeDetails(candidateId: string) {
    return axios.get(`/api/selective-process/candidate/${candidateId}/cultureGradeDetails`);
  }

  public static getDetailsReport(page: number, size: number) {
    return axios.get('/api/selective-process/details/report', {
      params: {
        page: page - 1,
        size,
      },
    })
  }

  public static async findAllDepartment(): Promise<Department[]> {
    return axios.get('/api/selective-process/jobs/departments')
      .then(({ data }) => data.map(it => new Department(it)));
  }

  public static async updateDepartmentThreshold({ id, threshold }) {
    return axios
      .patch(`/api/selective-process/jobs/departments/${id}/threshold`, { threshold })
  }

  public static async importAllDepartment() {
    return axios.post('/api/pinpoint/department/integrate-all');
  }

  public static async getPersonalityResult(
    candidateId: string,
  ): Promise<CandidateResult> {
    return axios.get(`/api/selective-process/candidate/${candidateId}/result`)
      .then(({ data }) => data)
  }
}
