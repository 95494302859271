





























import { Vue, Component } from 'vue-property-decorator';
import { VbSnackbarService } from 'bag-of-holding-library';
import DrdFilter from '@/domain/drd-2.0/entity/drd-filter';
import DrdFiltered from '@/domain/drd-2.0/entity/drd-filtered.entity';
import DrdService from '@/domain/drd-2.0/service/drd.service';
import AuthorityStore from '@/domain/security/authority.store';
import AuthorityEnum from '@/domain/security/authority.enum';
import DrdFilterComponent from './components/drd-filter.component.vue';
import DrdTableComponent from './components/drd-table.component.vue';

@Component({
  components: {
    DrdFilterComponent,
    DrdTableComponent,
  },
})
export default class DrdView extends Vue {
  filter: DrdFilter = new DrdFilter();

  sortBy: Array<string> = [];

  sortDesc: boolean[] = [false];

  pagination: any = {
    itemsPerPage: 10,
    page: 1,
    sortBy: this.sortBy,
    sortDesc: this.sortDesc,
    total: 0,
    pageCount: 0,
  }

  drds: DrdFiltered[] = [];

  loading: boolean = false;

  hasUpdateDRD: boolean = false;

  hasCreateDRD: boolean = false;

  created() {
    this.validateAuthoryties();
    this.search();
  }

  updateFilter({ value, field }) {
    this.filter[field] = value;
  }

  updatePagination(value: any) {
    if (this.pagination.itemsPerPage < value.itemsPerPage) {
      this.pagination.page = 1;
    }
    this.pagination = value;
    this.search();
  }

  search() {
    this.loading = true;
    DrdService.filter(
      this.filter,
      this.pagination.page,
      this.pagination.itemsPerPage,
    ).then((response) => {
      this.drds = response.data.content.map(it => new DrdFiltered(it));
      this.pagination.total = response.data.numberOfElements || 0;
      this.pagination.pageCount = response.data.totalPages;
    }).catch((error) => {
      VbSnackbarService.showError(error.response.data.messages[0]);
    }).finally(() => this.loading = false);
  }

  createNewDrd() {
    this.$router.push('/drd/create');
  }

  async validateAuthoryties() {
    this.hasCreateDRD = await AuthorityStore.hasAuthority([AuthorityEnum.CREATE_DRD]);
    this.hasUpdateDRD = await AuthorityStore.hasAuthority([AuthorityEnum.UPDATE_DRD]);
  }
}
