import moment, { Moment } from 'moment';

export default class VacationPersist {
  id: string;

  employeeId: string;

  employeeName: string;

  vacationType: string;

  startDate: string;

  endDate: string;

  observation: string;

  period: number;

  days: number | null = null;

  constructor(obj: any = {}) {
    this.id = obj.id || null;
    this.employeeId = obj.employeeId || null;
    this.employeeName = obj.employeeName || '';
    this.period = obj.period || moment().year();
    this.startDate = obj.start || '';
    this.endDate = obj.end || '';
    this.vacationType = obj.vacationType || 'NORMAL';
    this.observation = obj.observation || ''
  }

  isNormalType(): boolean {
    return this.vacationType === 'NORMAL'
  }

  verifyEndDateBiggerThanStartDate(): string[] {
    const errorMessages: string[] = [];
    const startDate = moment(this.startDate, 'YYYY-MM-DD');
    const endDate = moment(this.endDate, 'YYYY-MM-DD');

    if (!this.endDate) {
      errorMessages.push('Valor Obrigatório');
    }
    if (!this.isEndDateValid(startDate, endDate)) {
      errorMessages.push('Data menor que a inicial')
    }
    if (this.isDurationVacationBiggerThan30days(startDate, endDate)) {
      errorMessages.push('Período informado maior que 30 dias')
    }

    return errorMessages;
  }

  private isEndDateValid(startDate: Moment, endDate: Moment): boolean {
    return endDate.isSameOrAfter(startDate)
  }

  private isDurationVacationBiggerThan30days(startDate: Moment, endDate: Moment): boolean {
    return (endDate.diff(startDate, 'days') + 1) > 30;
  }
}
