


































































import { Vue, Component, Prop } from 'vue-property-decorator';

@Component
export default class AutocompleteEditableItems extends Vue {
  @Prop({
    type: Array,
    default: [],
  }) itemsSelected!: any[];

  @Prop({
    type: Array,
    required: true,
  }) items!: any[];

  @Prop({
    type: String,
    default: 'name',
  }) itemText!: string;

  itemSearch: string = '';

  editing: any = {};

  editingIndex: number = -1;

  createItem() {
    this.$emit('create', this.itemSearch);
    this.resetEditLevel();
    this.itemSearch = '';
  }

  editLevel(index: number, item: any) {
    if (this.editing !== item) {
      this.editing = item;
      this.editingIndex = index;
      return;
    }
    this.resetEditLevel();
  }

  resetEditLevel() {
    this.editing = { id: '', name: '', disabled: false };
    this.editingIndex = -1;
  }

  updateItem(item: any) {
    this.$emit('update', item);
    this.resetEditLevel();
  }

  deleteItem(item: any) {
    this.$emit('delete', item);
    this.resetEditLevel();
  }

  selected(item: any) {
    this.$emit('selected', item);
  }
}
