import RouteCheckerTo from '@/views/route-checker';
import TalentLevel from './talent-level.view.vue';

export default [
  {
    path: '/talent-levels',
    name: 'Níveis de Talento',
    component: TalentLevel,
    beforeEnter: RouteCheckerTo.hasAccessTalentLevel,
  },
];
