import RouteCheckerTo from '@/views/route-checker';
import DrdView from './drd.view.vue';
import PersistDrdView from './persist-drd-view.vue';
import ReadDrdView from './read-drd.view.vue';
import PrintDrdView from './print-drd.view.vue';

export default [
  {
    path: '/drd',
    name: 'Listagem de DRD',
    component: DrdView,
  },
  {
    path: '/drd/create',
    name: 'Nova DRD',
    component: PersistDrdView,
    beforeEnter: RouteCheckerTo.hasAccessCreateDRD,
  },
  {
    path: '/drd/:id',
    name: 'Atualizar DRD',
    component: PersistDrdView,
    beforeEnter: RouteCheckerTo.hasAccessUpdateDRD,
  },
  {
    path: '/drd/:id/read',
    name: 'Visualizar DRD',
    component: ReadDrdView,
  },
  {
    path: '/drd/:id/print',
    name: 'Imprimir DRD',
    component: PrintDrdView,
    meta: {
      hideMenus: true,
    },
  },
]
