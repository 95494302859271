import axios from 'axios';
import ActionPlan from '../activity/entity/action-plan.entity';
import PlanType from '../activity/entity/plan-types.entity';
import Priority from '../activity/entity/priority.entity';

export default class PdiService {
  public static getAllPlansByEmployeeUsername(username: string, planStates: string) {
    return axios.get(`/api/formPlan/employee/${username}/allPlans?planState=${planStates}`)
      .then(({ data }) => data.map((it: any) => new ActionPlan(it)))
  }

  public static getPlanTypes() {
    return axios.get('/api/formPlan/planTypes').then(({ data }) => data.map((it: any) => new PlanType(it)));
  }

  public static getAllPriority() {
    return axios.get('/api/formPlan/priority').then(({ data }) => data.map((it: any) => new Priority(it)));
  }

  public static updateStatePlan(planId: string, state: string) {
    return axios.put(`/api/formPlan/${planId}/${state}`);
  }

  public static deletePlan(planId: string) {
    return axios.delete(`/api/formPlan/${planId}`);
  }

  public static create(plan: ActionPlan, usernameEmployeeOfPlan: string) {
    return axios.post(`/api/formPlan/${usernameEmployeeOfPlan}`, plan)
  }

  public static update(plan: ActionPlan, usernameEmployeeOfPlan: string) {
    return axios.put(`/api/formPlan/${usernameEmployeeOfPlan}`, plan)
  }
}
