



































import { Vue, Component, Prop } from 'vue-property-decorator';
import EmployeeSummary from '@/domain/schedule/employee/employee-summary.entity';
import EmployeeService from '@/domain/schedule/employee/employee.service';
import SelectionComponent from '@/shared/fields/selection.componet.vue';
import LabelSlot from '@/shared/slots/label-slot.component.vue';

@Component({
  components: {
    SelectionComponent,
    LabelSlot,
  },
})
export default class EmployeeFilter extends Vue {
  @Prop({
    type: String,
    default: 'Colaborador',
  }) label!: string;

  @Prop({
    type: String,
    default: 'Digite o nome...',
  }) placeholder!: string;

  @Prop({
    type: String,
    default: 'Nenhum colaborador encontrado...',
  }) noDataText!: string;

  employeeSelected: string[] = [];

  foundEmployees: EmployeeSummary[] = [];

  employeeName: string = '';

  loading: boolean = false;

  lastFilterEmployee: string = '';

  selected() {
    this.$emit('selected', this.employeeSelected);
  }

  findEmployees(value: string) {
    if ((this.lastFilterEmployee !== value) && value) {
      this.lastFilterEmployee = value;
      if (value.length > 2) {
        this.loading = true;
        EmployeeService.findEmployeesByName(value)
          .then(data => this.foundEmployees = data)
          .finally(() => this.loading = false);
      }
    }
  }
}
