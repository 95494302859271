import axios from 'axios';
import Response from '@/domain/risk-attendance/entity/response.entity';
import RiskAttendanceFilter from '../entity/filter.entity';
import RiskAttendance from '../entity/risk-attendance.entity';

export default class RiskAttendanceService {
  public static findRiskAttendance(
    page: number, size: number, filter: RiskAttendanceFilter,
  ): Promise<Response> {
    return axios.get('/api/riskAttendance', {
      params: {
        employee: filter.employee,
        manager: filter.manager,
        responsible: filter.responsible,
        businessUnit: filter.businessUnit,
        requestDate: filter.requestDate,
        limitDate: filter.limitDate,
        status: filter.status,
        active: filter.active,
        mainResignationReason: filter.mainResignationReason,
        indirectSubordinates: filter.indirectSubordinates,
        types: filter.types,
        sources: filter.sources,
        page: page - 1,
        size,
      },
    })
      .then((response: any) => Response.of(response.data));
  }

  public static getRiskAttendance(id: string): Promise<RiskAttendance> {
    return axios.get(`/api/riskAttendance/${id}`)
      .then((response: any) => RiskAttendance.of(response.data));
  }

  static saveRiskAttendance(id: string, request: RiskAttendance): Promise<void> {
    return axios.put(`/api/riskAttendance/${id}`, request)
      .then((resp: any) => resp);
  }

  public static newRiskAttendance(newRiskAttendance: RiskAttendance): Promise<RiskAttendance> {
    return axios.post('/api/riskAttendance', newRiskAttendance)
      .then((resp: any) => resp);
  }

  public static deleteRiskAttendance(id: string) {
    return axios.delete(`/api/riskAttendance/${id}`)
      .then((resp: any) => resp)
  }
}
