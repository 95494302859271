




























import KeyAreaFilter from '@/domain/drd-2.0/entity/key-area-filter.entity';
import KeyAreaFiltered from '@/domain/drd-2.0/entity/key-area-filtered.entity';
import KeyAreaService from '@/domain/drd-2.0/service/key-area.service';
import { VbSnackbarService } from 'bag-of-holding-library';
import { Vue, Component } from 'vue-property-decorator';
import KeyAreaFilterComponent from './components/key-area-filter.component.vue';
import KeyAreaTableComponent from './components/key-area-table.component.vue';

@Component({
  components: {
    KeyAreaFilterComponent,
    KeyAreaTableComponent,
  },
})
export default class KeyAreaView extends Vue {
  filter: KeyAreaFilter = new KeyAreaFilter();

  keyAreas: KeyAreaFiltered[] = [];

  sortBy: Array<string> = [];

  sortDesc: boolean[] = [false];

  pagination: any = {
    itemsPerPage: 10,
    page: 1,
    sortBy: this.sortBy,
    sortDesc: this.sortDesc,
    total: 0,
    pageCount: 0,
  }

  loading: boolean = false;

  created() {
    this.findKeyAreas();
  }

  updateFilter(event: any) {
    this.filter[event.field] = event.value;
  }

  updatePagination(value: any) {
    if (this.pagination.itemsPerPage < value.itemsPerPage) {
      this.pagination.page = 1;
    }
    this.pagination = value;
    this.findKeyAreas();
  }

  sendFilter() {
    this.findKeyAreas();
  }

  findKeyAreas() {
    this.loading = true;
    KeyAreaService.search(
      this.filter,
      this.pagination.page,
      this.pagination.itemsPerPage,
    ).then((response) => {
      this.keyAreas = response.data.content.map(it => new KeyAreaFiltered(it));
      this.pagination.total = response.data.numberOfElements || 0;
      this.pagination.pageCount = response.data.totalPages;
    })
      .catch((error) => {
        VbSnackbarService.showError(error.response.data.messages.toString());
      }).finally(() => this.loading = false);
  }

  createKeyArea() {
    this.$router.push('/keyarea/create');
  }
}
