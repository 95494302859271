

























































































import { Component, Prop, Vue } from 'vue-property-decorator';
import CardTitle from '@/shared/card/card-title.component.vue';
import {
  hasEmployeeAdministratorAuthority,
  hasAdministratorAuthority,
} from '@/shared/directive/has-authority.directive';
import { VbTooltipSlot } from 'bag-of-holding-library';
import CheckboxComponent from '@/shared/checkbox/checkbox.component.vue';
import LabelSlot from '@/shared/slots/label-slot.component.vue';
import FilterVacationBalance from '@/domain/vacation/entity/filter-vacation-balance.entity';
import EmployeeService from '@/domain/schedule/employee/employee.service';
import EmployeeSummary from '@/domain/schedule/employee/employee-summary.entity';
import UpdateAtribute from '@/domain/employees/entity/update-atribute.entity';
import CareerService from '@/domain/employees/service/career.service'

@Component({
  name: 'filter-balance',
  components: {
    CardTitle,
    VbTooltipSlot,
    CheckboxComponent,
    LabelSlot,
  },
  directives: {
    hasEmployeeAdministratorAuthority,
    hasAdministratorAuthority,
  },
})
export default class FilterVacation extends Vue {
  @Prop() filter!: FilterVacationBalance;

  private employees: EmployeeSummary[] = [];

  private managers: EmployeeSummary[] = [];

  private businessUnits: UpdateAtribute[] = [];

  private lastFilterEmployee: string = '';

  private lastFilterManager: string = '';

  private lastFilterBusinessUnits: string = '';

  private loading: boolean = true;

  employeeInput: string = '';

  managerInput: string = '';

  businessUnitInput: string = '';

  private sendField(field: string, value: any) {
    this.$emit(`update:${field}`, value);
  }

  updateEmployee(value: string[]) {
    this.employeeInput = '';
    this.sendField('employee', value)
  }

  updateManager(value: string[]) {
    this.managerInput = '';
    this.sendField('manager', value)
  }

  updateBusinessUnit(value: string[]) {
    this.businessUnitInput = '';
    this.sendField('businessUnit', value)
  }

  updateIndirectSubordinates(value: boolean): void {
    this.sendField('indirectSubordinates', value);
  }

  findEmployees(value: string) {
    this.employeeInput = value;
    if (this.lastFilterEmployee === value || value === null) return;
    this.lastFilterEmployee = value;
    if (value.length > 2) {
      EmployeeService.findEmployeesByName(value)
        .then(this.mergeEmployees);
    }
  }

  private findManagers(value: string) {
    this.managerInput = value;
    if ((this.lastFilterManager !== value) && value) {
      this.lastFilterManager = value;
      if (value.length > 2) {
        EmployeeService.findManagersByName(value)
          .then(this.mergeManagers);
      }
    }
  }

  private findBusinessUnit(value: string) {
    this.businessUnitInput = value;
    if ((this.lastFilterBusinessUnits !== value) && value) {
      this.lastFilterBusinessUnits = value;
      if (value.length > 2) {
        CareerService.getBusinessUnit()
          .then(resp => this.businessUnits = resp)
      }
    }
  }

  private mergeEmployees(employees: EmployeeSummary[]): void {
    const addedIds: string[] = this.employees.map((employee: EmployeeSummary) => employee.id)
    employees.forEach((employee: EmployeeSummary) => {
      if (!addedIds.includes(employee.id)) {
        this.employees.push(employee);
      }
    })
  }

  private mergeManagers(employees: EmployeeSummary[]): void {
    const addedIds: string[] = this.managers.map((employee: EmployeeSummary) => employee.id)
    employees.forEach((employee: EmployeeSummary) => {
      if (!addedIds.includes(employee.id)) {
        this.managers.push(employee);
      }
    })
  }
}
