

















import City from '@/domain/states/entity/city.entity';
import StateService from '@/domain/states/service/states.service';
import {
  Vue, Component, Prop, Watch,
} from 'vue-property-decorator'
import AutocompleteComponent from '../fields/autocomplete-component.vue'

@Component({
  components: {
    AutocompleteComponent,
  },
})
export default class SelectCityComponent extends Vue {
  @Prop({
    type: Array,
    default: '',
  }) states!: string[];

  @Prop({
    type: String,
    default: 'Cidade',
  }) label!: string;

  @Prop({
    type: String,
    default: 'Selecione a cidade',
  }) placeholder!: string;

  @Prop({
    type: Boolean,
    default: false,
  }) multiple!: boolean;

  @Prop({
    type: Boolean,
    default: false,
  }) disabled!: boolean;

  @Prop({
    default: '',
  }) value!: string | string[];

  @Prop({
    type: Boolean,
    default: true,
  }) hideDetails!: boolean;

  @Prop({
    type: String,
    default: '',
  }) hintMessage!: string;

  cities: City[] = [];

  loading: boolean = false;

  statesFetched: string[] = [];

  @Watch('states')
  handleGetCitites() {
    if (this.states.length > 0) {
      this.states.forEach((state) => {
        if (!this.statesFetched.includes(state)) {
          this.getCities(state)
          this.statesFetched.push(state)
        }
      })
    }
  }

  getCities(stateId: string) {
    this.loading = true;
    StateService.findCities(stateId)
      .then((resp: any) => {
        this.cities.push(...resp);
      }).finally(() => this.loading = false);
  }
}
