export default class PersistSkill {
  whatPTBR: string;

  whatES: string;

  whatEN: string;

  type: string;

  groupId: string;

  constructor(obj: any = {}) {
    this.whatPTBR = obj.whatPTBR || '';
    this.whatES = obj.whatES || '';
    this.whatEN = obj.whatEN || '';
    this.type = obj.type || '';
    this.groupId = obj.groupId || '';
  }
}
