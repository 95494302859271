import { Vue, Component, Prop } from 'vue-property-decorator';

@Component
export default class MenuAuthorityMixin extends Vue {
  @Prop({
    type: Array,
    default: () => ([]),
  })
  private authorities!: string[];

  private text: string[] = [];

  hasAuthority(menu: any): boolean {
    if (menu.children && menu.children.length) {
      return menu.children.some(this.hasAuthority);
    }
    if (!menu.authorities || !menu.authorities.length) return true;
    return this.authorities.some((authority: string) => menu.authorities.includes(authority));
  }

  hasTextUnic(menu: any): boolean {
    if (menu.children) {
      if (menu.children.length) {
        return menu.children.some(this.hasTextUnic);
      }
    }
    return true;
  }
}
