





































































































import { Vue, Component, Prop } from 'vue-property-decorator';
import LabelSlot from '@/shared/slots/label-slot.component.vue';
import AssignmentFilter from '@/domain/drd/entity/filter-assignment.entity';
import SelectionComponent from '@/shared/fields/selection.componet.vue';
import YearMonthPicker from '@/shared/month-year-picker/month-year-select.component.vue';
import Ecosystem from '@/domain/drd/entity/ecosystem.entity';
import Assignment from '@/domain/drd/entity/assignment.entity';
import UpdateAtribute from '../../../../domain/employees/entity/update-atribute.entity';

@Component({ components: { LabelSlot, YearMonthPicker, SelectionComponent } })
export default class FilterAssignmentComponent extends Vue {
  @Prop() options!: AssignmentFilter;

  @Prop() assignments!: Assignment[];

  @Prop() roles!: UpdateAtribute[];

  @Prop() ecosystems!: Ecosystem[];

  @Prop() lastFilter?: AssignmentFilter;

  private filter: AssignmentFilter = new AssignmentFilter({});

  private created() {
    this.getLastFilter();
    this.sendFilter();
    this.$forceUpdate();
  }

  private getLastFilter() {
    if (this.lastFilter) {
      this.filter = this.lastFilter;
    }
  }

  private sendFilter() {
    this.filter.assignmentInput = ''
    this.filter.ecosystemInput = ''
    this.filter.roleInput = ''
    this.$emit('filter', this.filter);
  }

  private findAssignments(value: string) {
    this.filter.assignmentInput = value
    this.$emit('findAssignments', value);
  }

  setRoleInput(value: string): void {
    this.filter.roleInput = value
  }

  setEcossystemInput(value: string): void {
    this.filter.ecosystemInput = value
  }

  getAssignmentText(item: Assignment) {
    return item.inactive ? `${item.description} - (inativo)` : item.description;
  }

  getRoleText(item: UpdateAtribute) {
    return item.active ? item.name : `${item.name} - (inativo)`;
  }
}
