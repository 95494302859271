



























import { Vue, Component, Prop } from 'vue-property-decorator'
import { VbSnackbarService } from 'bag-of-holding-library';
import { ContractType } from '@/views/employees/profile/tab-components/score/contract-type.type';
import ProfessionalDataService from '@/domain/employees/service/professional-data.service';
import ContractTypes from './contract-type.json';
import LabelSlot from '../slots/label-slot.component.vue';

@Component({
  components: {
    LabelSlot,
  },
})
export default class ContractTypeComponent extends Vue {
  @Prop() contractType!: ContractType;

  @Prop({
    type: String,
    default: '',
  }) employeeId!: string;

  @Prop({
    type: Boolean,
    default: false,
  }) disabled!: boolean;

  contractTypes = ContractTypes;

  updateLoading: boolean = false;

  async updateContractType(value: string) {
    this.updateLoading = true;
    ProfessionalDataService.updateContractType(this.employeeId, value)
      .then((contractType: ContractType) => {
        VbSnackbarService.showSuccess('Tipo de contrato atualizado com sucesso!')
        this.$emit('update', contractType)
      }).catch((error) => {
        VbSnackbarService.showError(error.response.message[0]);
      }).finally(() => this.updateLoading = false);
  }
}
