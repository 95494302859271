import axios from 'axios';
import EmployeeSummary from './employee-summary.entity';
import EmployeeHistory from './employee-history.entity';
import EmployeeRestartSchedule from './employee-restart-schedule.entity';
import Reason from '../activitie/reason.entity';
import EmployeeDetailed from './employee-detailed.entity';
import EmployeeDateActivities from './employee-date-activity.entity';

export default class EmployeeService {
  static findEmployeesByName(name: string): Promise<EmployeeSummary[]> {
    return axios.get(`/api/employees?name=${name}`)
      .then((response: any) => EmployeeSummary.ofList(response.data));
  }

  static findEmployeesByNameExcludeBPs(name: string): Promise<EmployeeSummary[]> {
    return axios.get(`/api/employees?name=${name}&excludedIfBp=true`)
      .then((response: any) => EmployeeSummary.ofList(response.data));
  }

  static findEmployeesByNameExcludeNotManaged(name: string): Promise<EmployeeSummary[]> {
    return axios.get(`/api/employees?name=${name}&excludedIfManager=true`)
      .then((response: any) => EmployeeSummary.ofList(response.data));
  }

  static findEmployeesByNameAndIsReponsible(name: string, isResponsible: boolean)
    : Promise<EmployeeSummary[]> {
    return axios.get(`/api/employees?name=${name}&isResignationResponsible=${isResponsible}`)
      .then((response: any) => EmployeeSummary.ofList(response.data));
  }

  static findManagersByName(name: string): Promise<EmployeeSummary[]> {
    return axios.get(`/api/employees/managers?name=${name}`)
      .then((response: any) => EmployeeSummary.ofList(response.data));
  }

  static getEmployeeHistory(id: string): Promise<EmployeeHistory> {
    return axios.get(`/api/employees/${id}/history`)
      .then((response: any) => EmployeeHistory.of(response.data));
  }

  static getEmployeeActivities(id: string): Promise<EmployeeDetailed> {
    return axios.get(`/api/employees/${id}/activities`)
      .then((response: any) => EmployeeDetailed.of(response.data));
  }

  static getEmployeeActivitiesByDate(id: string, date: string): Promise<EmployeeDateActivities> {
    return axios.get(`/api/employees/${id}/activities/${date}`)
      .then((response: any) => EmployeeDateActivities.of(response.data));
  }

  static getEmployeeRestartScheduleInfo(id: string): Promise<EmployeeRestartSchedule> {
    return axios.get(`/api/employees/${id}/schedule/restart`)
      .then((response: any) => EmployeeRestartSchedule.of(response.data));
  }

  static restartEmployeeSchedule(id: string, reason: Reason): Promise<void> {
    return axios.put(`/api/employees/${id}/schedule/restart`, reason);
  }

  public static getEmployeeById(employeeId: string): Promise<any> {
    return axios.get(`/api/employees/${employeeId}`).then(response => response.data);
  }
}
