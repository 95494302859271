















import { Vue, Component, Prop } from 'vue-property-decorator';
import { VbSnackbarService } from 'bag-of-holding-library';
import DrdService from '@/domain/drd/service/drd.service';
import Loader from '@/views/drd/commons/loader.component.vue';
import BehavioralSkill from '@/domain/drd/entity/behavioral-skill.entity';
import BehavioralSkillComponent from './behavioral-skill.component.vue';

@Component({
  components: { BehavioralSkillComponent, Loader },
})
export default class BehaviorSkillIndex extends Vue {
  @Prop({
    type: String,
    required: true,
  })
  private performanceDescriptionId!: string;

  private loading: boolean = true;

  private behavioralSkills: BehavioralSkill[] = [];

  private loadBehaviorSkills(): void {
    this.loading = true;
    DrdService.getBehavioralSkills(this.performanceDescriptionId).then((data) => {
      this.behavioralSkills = data.map(it => new BehavioralSkill(it))
        .filter(it => it.active);
    }).catch(VbSnackbarService.handleHttpError)
      .finally(() => {
        this.loading = false;
      });
  }

  private created(): void {
    this.loadBehaviorSkills();
  }
}
