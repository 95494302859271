import RoleAssignment from './role.assignment.entity';

export default class RoleDrdList {
  id: string;

  name: string;

  assignments: any[];

  active: boolean;

  cbo: string;

  constructor(obj: any = {}) {
    this.id = obj.id || '';
    this.name = obj.name || '';
    this.assignments = obj.assignments || [];
    this.active = obj.active === null || obj.active === undefined ? false : obj.active;
    this.cbo = obj.cbo || '-';
  }

  public static of(obj: any): RoleDrdList {
    return new RoleDrdList(obj || {});
  }

  public static ofList(obj: any): RoleDrdList[] {
    return obj.map(this.of);
  }
}
