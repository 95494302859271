import VacationType from '@/views/vacation/vacation-administrator/vacation-type.json';

const vacationType = VacationType;

export default class VacationEmployee {
  startDate: string;

  endDate: string;

  days: number;

  daysWithTypeNormal: number = 0;

  vacationType: string;

  balance: number = 0;

  observation: string;

  constructor(obj: any = {}) {
    this.startDate = obj.startDate || '';
    this.endDate = obj.endDate || '';
    this.days = obj.days || 0;
    this.vacationType = obj.vacationType || '';
    this.observation = obj.observation || ''
  }

  get daysByVacationType(): Number {
    return this.vacationType === 'NORMAL' ? this.daysWithTypeNormal : this.days
  }

  get vacationTypeName(): string {
    const obj = vacationType.find(it => it.value === this.vacationType)
    return obj ? obj.name : ''
  }
}
