





















































import { Component, Mixins } from 'vue-property-decorator';
import ModalConfirmMixin from '@/shared/modal/modal-confirm.mixin';

@Component
export default class ModalWarning extends Mixins(ModalConfirmMixin) {}
