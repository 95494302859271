import { DirectiveOptions } from 'vue';

const directive: DirectiveOptions = {
  inserted(element, binding) {
    switch (binding.value) {
      case 'Aprovada':
        element.style.color = '#22BE3B';
        break;
      case 'Recusada':
        element.style.color = '#D86277';
        break;
      case 'Atrasada':
        element.style.color = '#F2994A';
        break;
      default:
        element.style.color = 'rgba(0, 0, 0, 0.87)';
        break;
    }
  },
};
export default directive;
