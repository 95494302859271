import RoleDrdList from './roleDrdList.entity';

export default class RoleResponse {
  totalElements: number;

  totalPages: number;

  content: RoleDrdList[];

  constructor(obj: any) {
    this.totalElements = obj.totalElements || '';
    this.totalPages = obj.totalPages || '';
    this.content = obj.content || '';
  }

  public static of(value: any): RoleResponse {
    return new RoleResponse(value || {});
  }
}
