









































































import { Vue, Component, Prop } from 'vue-property-decorator';
import PaginationComponent from '@/shared/pagination.component.vue';
import ModalWarning from '@/shared/modal/modal-warning.component.vue';
import Assignment from '@/domain/drd/entity/assignment.entity';
import MobileHandler from '@/shared/utils/mobile-handler';

@Component({ components: { PaginationComponent, ModalWarning } })
export default class AssignmentList extends Vue {
  @Prop({
    type: Array,
    default: () => ([]),
  }) assignments!: Assignment[];

  @Prop({
    type: Boolean,
    default: false,
  }) loading!: boolean;

  @Prop() page!: any;

  @Prop() updateAssignments!: boolean;

  @Prop() hasInactiveAssigments!: boolean;

  showDeleteConfirmationDialog: boolean = false;

  inactivateAssignmentId: string | null = null;

  isMobile = false;

  headers: any[] = [
    {
      value: 'description',
      text: 'Papel',
      align: 'left',
      width: '40%',
    },
    {
      value: 'ecosystem.description',
      text: 'Ecossistema',
      width: '40%',
    },
    {
      value: 'inactive',
      text: 'Ativo?',
      align: 'center',
      width: '10%',
    },
    {
      value: 'actions',
      text: 'Ações',
      align: 'center',
      sortable: false,
    },
  ];

  onResize() {
    this.isMobile = MobileHandler.isMobile();
  }

  updateSortBy(sortBy: any): void {
    this.updateTable({
      ...this.page,
      sortBy,
    });
  }

  updateSortDesc(sortDesc: any): void {
    this.updateTable({
      ...this.page,
      sortDesc,
    });
  }

  updateTable(pagination: any): void {
    this.$emit('update', pagination);
  }

  editItem(assignment: Assignment): void {
    this.$emit('edit', assignment.id);
  }

  openDeleteDialog(assignment: Assignment): void {
    if (assignment.inactive) return;
    this.inactivateAssignmentId = assignment.id;
    this.showDeleteConfirmationDialog = true;
  }

  closeDeleteConfirmationDialog(): void {
    this.inactivateAssignmentId = null;
    this.showDeleteConfirmationDialog = false;
  }

  deleteItem(): void {
    if (this.inactivateAssignmentId === null) return;
    this.$emit('inactivate', this.inactivateAssignmentId);
    this.closeDeleteConfirmationDialog();
  }
}
