import RouteCheckerTo from '@/views/route-checker';
import InitialScoreView from './initial-score.view.vue';
import PdfContentComponent from './pdf-content.component.vue';
import NewInitialScoreComponent from './new-initial-score.component.vue';
import UpdateInitialScoreComponent from './update-initial-score.component.vue';
import BankAccountDocumentTemplate from './bank-account/bank-account-document-template.component.vue';

export default [
  {
    path: '/initial-score',
    component: InitialScoreView,
    name: 'Acordo Salarial Inicial',
    beforeEnter: RouteCheckerTo.hasAccessCreateInitialScore,
  },
  {
    path: '/initial-score/pdf',
    component: PdfContentComponent,
    beforeEnter: RouteCheckerTo.hasAccessCreateInitialScore,
    name: 'ScoreInitialPDF',
    meta: {
      hideMenus: true,
    },
  },
  {
    path: '/initial-score/bank-account-document',
    component: BankAccountDocumentTemplate,
    beforeEnter: RouteCheckerTo.hasAccessCreateInitialScore,
    name: 'Banck Account Document',
    meta: {
      hideMenus: true,
    },
  },
  {
    path: '/initial-score/new',
    component: NewInitialScoreComponent,
    name: 'Novo Acordo Salarial Inicial',
    beforeEnter: RouteCheckerTo.hasAccessCreateInitialScore,
  },
  {
    path: '/initial-score/:id',
    component: UpdateInitialScoreComponent,
    name: 'Atualizar Acordo Salarial Inicial',
    beforeEnter: RouteCheckerTo.hasAccessCreateInitialScore,
  },
]
