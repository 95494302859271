import ActivityDetailed from '@/domain/schedule/activitie/activity-detailed.entity';

export default class EmployeeDetailed {
    id: string;

    name: string;

    admissionDate: string;

    activities: ActivityDetailed[];

    constructor(obj: any = {}) {
      this.id = obj.id;
      this.name = obj.name;
      this.admissionDate = obj.admissionDate;
      this.activities = ActivityDetailed.ofList(obj.activities || []);
    }

    public static of(obj: any): EmployeeDetailed {
      return new EmployeeDetailed(obj || {});
    }

    public static ofList(obj: any): EmployeeDetailed[] {
      return obj.map((value: any) => this.of(value));
    }
}
