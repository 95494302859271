


















































import { Vue, Component, Prop } from 'vue-property-decorator';
import SalaryReadjustmentParametrization from '@/domain/salary-reajustment-parametrizations/entity/salary-readjustment-parametrization.entity';
import PaginationComponent from '@/shared/pagination.component.vue';
import headers from './salary-readjustment-parametrization-list-headers.json';

@Component({ components: { PaginationComponent } })
export default class SalaryReadjustmentParametrizationsList extends Vue {
  @Prop({ required: true })
  private parametrizations!: SalaryReadjustmentParametrization[];

  @Prop({ type: Boolean, default: false })
  private loading!: boolean;

  @Prop({ type: Number, default: 0 })
  private page!: number;

  @Prop({ type: Number, default: 0 })
  private size!: number;

  @Prop({ type: Number, default: 0 })
  private totalPages!: number;

  @Prop({ type: Number, default: 0 })
  private totalElements!: number;

  @Prop({ type: String, default: '' })
  private sort!: string;

  @Prop({ type: Boolean, default: true })
  private hasUpdateSalaryReadjusment!: boolean;

  @Prop({ type: Boolean, default: false })
  private hasDeletePermission!: boolean;

  private headers: any = headers;

  private get sortBy(): string | null {
    if (!this.sort) return null;
    return this.sort.split(',')[0];
  }

  private get sortDesc(): boolean {
    if (!this.sort) return true;
    return this.sort.split(',')[1] === 'desc';
  }

  updateTable(data: any) {
    this.$emit('update:page', data.page);
    this.$emit('update:size', data.itemsPerPage);
    this.$emit('reloadList');
  }

  updateSortBy(sortBy: string) {
    let sort: string | undefined;
    if (sortBy) {
      const formattedSortDesc = this.sortDesc ? 'desc' : 'asc';
      sort = `${sortBy},${formattedSortDesc}`;
    }
    this.$emit('update:sort', sort);
  }

  updateSortDesc(sortDesc: boolean) {
    let sort: string | undefined;
    if (this.sortBy) {
      const formattedSortDesc = sortDesc ? 'desc' : 'asc';
      sort = `${this.sortBy},${formattedSortDesc}`;
    }
    this.$emit('update:sort', sort);
  }

  openEditDialog(id: string) {
    this.$emit('openEditDialog', id);
  }

  openDeleteDialog(id: string) {
    this.$emit('openDeleteDialog', id);
  }
}
