export default class ResignationFilter {
  employeeId: string;

  managerId: string;

  startDate: string;

  endDate: string;

  businessUnitId: string;

  constructor(obj: any = {}) {
    this.employeeId = obj.employeeId || null;
    this.managerId = obj.managerId || null;
    this.startDate = obj.startDate || '';
    this.endDate = obj.endDate || '';
    this.businessUnitId = obj.businessUnitId || null;
  }
}
