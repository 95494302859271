export default class PlanAgeGroupDTO {
  minimumAge: number;

  maximumAge: number;

  healthPlanRegionalInfirmaryPrice: number;

  healthPlanNationalInfirmaryPrice: number;

  healthPlanRegionalApartmentPrice: number;

  healthPlanNationalApartmentPrice: number;

  dentalOdontoprevPrice: number;

  dentalUniodontoPrice: number;

  constructor(obj: any) {
    this.minimumAge = obj.minimumAge;
    this.maximumAge = obj.maximumAge;
    this.healthPlanRegionalInfirmaryPrice = obj.healthPlanRegionalInfirmaryPrice;
    this.healthPlanNationalInfirmaryPrice = obj.healthPlanNationalInfirmaryPrice;
    this.healthPlanRegionalApartmentPrice = obj.healthPlanRegionalApartmentPrice;
    this.healthPlanNationalApartmentPrice = obj.healthPlanNationalApartmentPrice;
    this.dentalOdontoprevPrice = obj.dentalOdontoprevPrice;
    this.dentalUniodontoPrice = obj.dentalUniodontoPrice;
  }

  public static of(obj: any): PlanAgeGroupDTO {
    return new PlanAgeGroupDTO(obj || {})
  }
}
