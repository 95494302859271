import { render, staticRenderFns } from "./menu-sidebar.component.vue?vue&type=template&id=73603fca&scoped=true&"
import script from "./menu-sidebar.component.vue?vue&type=script&lang=ts&"
export * from "./menu-sidebar.component.vue?vue&type=script&lang=ts&"
import style0 from "./menu-sidebar.component.vue?vue&type=style&index=0&id=73603fca&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "73603fca",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCol } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VCol,VRow})
