export default class AssessmentFilter {
  employees: string[];

  managers: string[];

  businessUnits: string[];

  resultCenters: string[];

  assignments: string[];

  alreadySent: boolean = true;

  indirectSubordinates: boolean = true;

  constructor(obj: any = {}) {
    this.employees = obj.employees || [];
    this.managers = obj.managers || [];
    this.businessUnits = obj.businessUnits || [];
    this.resultCenters = obj.resultCenters || [];
    this.assignments = obj.assignments || [];
    this.alreadySent = obj.alreadySent || true;
    this.indirectSubordinates = obj.indirectSubordinates || true;
  }

  get hasEmployees() {
    return this.employees.length > 0;
  }

  get hasManagers() {
    return this.managers.length > 0;
  }

  get hasBusinessUnits() {
    return this.businessUnits.length > 0;
  }

  get hasResultCenters() {
    return this.resultCenters.length > 0;
  }

  get hasAssignments() {
    return this.assignments.length > 0;
  }
}
