import MemberGroup from './member-group.entity';

export default class Group {
  id!: string;

  name!: string;

  members: MemberGroup[] = [];

  owners: string[] = [];
}
