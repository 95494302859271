



































import { Vue, Component } from 'vue-property-decorator';
import { VbSnackbarService } from 'bag-of-holding-library';
import ScheduleFilter from '@/domain/schedule/employee/schedule-filter.entity';
import EmployeeScheduleService from '@/domain/schedule/employee/employee-schedule.service';
import EmployeeSchedule from '@/domain/schedule/employee/employee-schedule.entity';
import AuthorityStore from '@/domain/security/authority.store';
import Authority from '@/domain/security/authority.entity';
import ActivityEnum from '@/domain/security/authority.enum';
import ScheduleDataTableComponent from './schedule-data-table.component.vue';
import ScheduleHeader from './schedule-header.component.vue';
import ScheduleFilterComponent from './schedule-filter.component.vue';
import ScheduleDataTable from './schedule-data-table.entity';

@Component({ components: { ScheduleHeader, ScheduleFilterComponent, ScheduleDataTableComponent } })
export default class Schedule extends Vue {
  employees: EmployeeSchedule[] = [];

  dataTable: ScheduleDataTable = new ScheduleDataTable([]);

  isManager: boolean = false;

  isResetEmployeeSchedule: boolean = false;

  isReadActivity: boolean = false;

  isUpdateActivity: boolean = false;

  isDeleteActivity: boolean = false;

  isUploadFiles: boolean = false;

  isUpdateActivityStatus: boolean = false;

  isReadActivityHistory: boolean = false;

  isReadEmployeeTalentLevel: boolean = false;

  hasRescheculeActivity: boolean = false;

  pagination = {
    page: 1,
    itemsPerPage: 15,
    total: 0,
    pageCount: 0,
  };

  filter = ScheduleFilter.of({});

  loadingSchedule: boolean = false;

  created() {
    this.loadingSchedule = true;
    this.isManagerAuth();
    this.getSchedulePermissions();
    this.findSchedule();
  }

  handleFilter(filter: ScheduleFilter) {
    if (!this.filter.equals(filter)) {
      this.pagination.page = 1;
      this.filter = filter;
    }
    this.findSchedule();
  }

  paginationChanged(pagination: any) {
    if (this.pagination.itemsPerPage < pagination.itemsPerPage) {
      pagination.page = 1;
    }
    this.pagination = pagination;
    this.findSchedule();
  }

  findSchedule() {
    EmployeeScheduleService.get(this.pagination, this.filter)
      .then((response) => {
        this.employees = response.content;
        this.pagination.total = response.totalElements;
        this.pagination.pageCount = response.totalPages;
        this.dataTable = new ScheduleDataTable(this.employees);
      })
      .catch(error => VbSnackbarService.handleHttpError(error))
      .finally(() => this.loadingSchedule = false);
  }

  isManagerAuth() {
    return AuthorityStore.isManager()
      .then((response) => {
        this.isManager = response;
      });
  }

  getSchedulePermissions(): void {
    AuthorityStore.fetchedAuthority().then((response: Authority) => {
      if (!response.isReadSchedule()) {
        this.$router.push('/');
        return;
      }
      this.isResetEmployeeSchedule = response.isResetEmployeeSchedule();
      this.isReadActivity = response.isReadActivity();
      this.isUpdateActivity = response.isUpdateActivity();
      this.isDeleteActivity = response.isDeleteActivity();
      this.isUploadFiles = response.isUploadFiles();
      this.isUpdateActivityStatus = response.isUpdateActivityStatus();
      this.isReadActivityHistory = response.isReadActivityHistory();
      this.isReadEmployeeTalentLevel = response.isReadEmployeeTalentLevel();
      this.hasRescheculeActivity = response.hasAuthorities([ActivityEnum.RESCHEDULE_ACTIVITY])
    });
  }
}
