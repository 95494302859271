






















import { Vue, Component, Prop } from 'vue-property-decorator';
import LabelSlot from '@/shared/slots/label-slot.component.vue';
import Rules from '@/shared/rules/rules';
import RadioData from './radio-data.entity';

@Component({
  components: { LabelSlot },
})
export default class FormRadioComponent extends Vue {
  @Prop() radioData!: RadioData;

  rules: Rules = new Rules();

  value: any = '';

  update() {
    this.$emit('update', this.radioData, this.value);
  }
}
