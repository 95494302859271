































































































































import {
  Vue,
  Component,
  Prop,
} from 'vue-property-decorator';
import Rules from '@/shared/rules/rules';
import LabelSlot from '@/shared/slots/label-slot.component.vue';
import TalentLevelService from '@/domain/talent-level/service/talent-level.service';
import TalentLevel from '@/domain/talent-level/entity/talent-level.entity';
import TalentLevelColor from '../talent-level-color.component.vue';

@Component({ components: { LabelSlot, TalentLevelColor } })
export default class TalentLevelDialog extends Vue {
  @Prop({
    type: Boolean,
    default: false,
  })
  private value!: boolean;

  @Prop({
    type: String,
    default: null,
  })
  private talentLevelId!: string;

  @Prop() isCreate!: boolean;

  @Prop() isUpdate!: boolean;

  private pulseColor: boolean = false;

  private talentLevel: TalentLevel = new TalentLevel();

  private rules: Rules = new Rules();

  private loadingSave: boolean = false;

  private loadingGet: boolean = false;

  private closeDialog(): void {
    this.$emit('input', false);
  }

  private onColorClick(): void {
    this.pulseColor = true;
    setTimeout(() => {
      this.pulseColor = false;
    }, 1500);
  }

  private updateColor(value: any): void {
    this.talentLevel.color = value.hex;
  }

  private validateAndSaveTalentLevel(): void {
    const { form }: any = this.$refs;
    if (form.validate()) {
      if (this.talentLevelId) {
        this.updateTalentLevel();
        return;
      }
      this.createNewTalentLevel();
    }
  }

  private createNewTalentLevel(): void {
    this.loadingSave = true;
    TalentLevelService.createTalentService(this.talentLevel)
      .then(this.closeDialogAndRefresh)
      .finally(() => {
        this.loadingSave = false;
      });
  }

  private updateTalentLevel(): void {
    this.loadingSave = true;
    TalentLevelService.updateTalentService(this.talentLevel)
      .then(this.closeDialogAndRefresh)
      .finally(() => {
        this.loadingSave = false;
      });
  }

  private closeDialogAndRefresh(): void {
    this.closeDialog();
    this.$emit('refresh');
  }

  private created() {
    this.talentLevel = new TalentLevel();
    if (this.talentLevelId) {
      this.loadingGet = true;
      TalentLevelService.getTalentLevelById(this.talentLevelId).then((talentLevel: TalentLevel) => {
        this.talentLevel = talentLevel;
      }).finally(() => {
        this.loadingGet = false;
      });
    }
  }
}

