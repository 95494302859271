








































































































































import { Component, Prop, Vue } from 'vue-property-decorator';
import CardTitle from '@/shared/card/card-title.component.vue';
import { hasReadWages } from '@/shared/directive/has-authority.directive';
import EmployeeRemunerationFilter from '@/domain/seniority-remuneration/entity/filter.entity';
import { VbSnackbarService, VbTooltipSlot } from 'bag-of-holding-library';
import FilteredSeniorityRemumeration from '@/domain/seniority-remuneration/entity/filtered-seniority-remuneration.entity';
import SeniorityRemunerationService from '@/domain/seniority-remuneration/services/seniority-remuneration.service';
import VueToExcel from 'vue-json-excel';

@Component({
  components: { CardTitle, VbTooltipSlot, VueToExcel },
  directives: { hasReadWages },
})
export default class ScheduleHeader extends Vue {
  @Prop() filter!: EmployeeRemunerationFilter;

  active: boolean = true;

  accompaniment: boolean = true;

  loadingExport: boolean = false;

  input() {
    this.$emit('input', this.active);
  }

  applyFilter() {
    this.$emit('filter', this.filter);
  }

  get exportTooltip() {
    return 'Gerar CSV'
  }

  xlsFields: any = {
    Colaborador: 'name',
    Cargo: 'role',
    Senioridade: 'seniority',
    CLT: 'salary',
    DUCZ: 'ducz',
    'DUCZ (Sintinorp/Livre)': 'duczSyndicate',
    Benefício: 'benefit',
    Remuneração: 'salary',
    Total: 'total',
    Piso: 'minWage',
    Teto: 'maxWage',
    'Análise gestão de pessoas': 'salaryRange.text',
    'Data Ult. Acordo': 'countDateFormated',
    Moeda: 'currency.name',
  }

  async exportToXLS(): Promise<FilteredSeniorityRemumeration[]> {
    this.loadingExport = true;
    const content = await SeniorityRemunerationService.findEmployeeRemunerationExport(this.filter)
      .finally(() => this.loadingExport = false);
    return FilteredSeniorityRemumeration.ofList(content.map(item => ({
      ...item,
      salaryRange: item.returnManagementAnalysis(),
    })));
  }
}
