




















import EmployeeService from '@/domain/employees/service/employee.service';
import EmployeeSummary from '@/domain/schedule/employee/employee-summary.entity';
import { Vue, Component, Prop } from 'vue-property-decorator';
import Rules from '../rules/rules';
import LabelSlot from '../slots/label-slot.component.vue';

@Component({
  components: {
    LabelSlot,
  },
})
export default class ManagerSelect extends Vue {
  @Prop({
    type: String,
    default: 'Gestor',
  }) label!: string

  @Prop() rules!: Rules[];

  @Prop({
    type: Boolean,
    default: false,
  }) returnObject!: boolean;

  @Prop({
    type: Boolean,
    default: false,
  }) clearable!: boolean;

  managers: EmployeeSummary[] = [];

  @Prop({
    required: true,
  }) manager!: EmployeeSummary;

  loading: boolean = false;

  lastFilterManager: string = '';

  created() {
    if (this.manager.id) {
      this.managers.push(this.manager);
    }
  }

  findManagers(value: string) {
    if ((this.lastFilterManager !== value) && value) {
      this.lastFilterManager = value;
      if (value.length > 3) {
        this.loading = true;
        EmployeeService.findManagersByName(value)
          .then((resp: EmployeeSummary[]) => this.managers = resp)
          .finally(() => this.loading = false);
      }
    }
  }

  update(value: EmployeeSummary) {
    this.$emit('update', value);
  }
}
