var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"action"},[(_vm.loadingExport)?_c('div',[_c('v-progress-circular',{attrs:{"rotate":-90,"size":50,"width":4,"value":_vm.exportProgress,"color":"primary"}},[_vm._v(" "+_vm._s(_vm.exportProgress.toFixed())+"% ")]),_c('span',{staticClass:"caption ml-2"},[_vm._v("Exportando, por favor aguarde...")])],1):_c('vue-to-excel',{attrs:{"fetch":_vm.exportToXLS,"fields":_vm.xlsFields,"name":"Acordos salariais.xls","worksheet":"Acordos salariais"}},[_c('vb-tooltip-slot',{attrs:{"content":_vm.exportTooltip}},[_c('v-btn',{staticClass:"white--text mr-1",attrs:{"loading":_vm.loadingExport,"color":"primary","disabled":!_vm.items.length}},[_c('v-icon',{staticClass:"mr-1"},[_vm._v("mdi-cloud-upload")]),_vm._v(" Baixar Arquivo ")],1)],1)],1)],1),_c('v-data-table',{staticClass:"score-table__data-table mt-4",attrs:{"headers":_vm.header,"loading":_vm.loading,"items":_vm.items,"page":_vm.page.page,"sort-by":_vm.page.sortBy,"sort-desc":_vm.page.sortDesc,"items-per-page":_vm.page.itemsPerPage,"page-count":_vm.page.pageCount,"hide-default-footer":"","loading-text":"Carregando...","no-data-text":"Não encontramos dados com os filtros selecionados."},on:{"update:pageCount":function($event){return _vm.$set(_vm.page, "pageCount", $event)},"update:page-count":function($event){return _vm.$set(_vm.page, "pageCount", $event)},"update:sort-by":_vm.updateSortBy,"update:sort-desc":_vm.updateSortDesc},scopedSlots:_vm._u([{key:"item.dateToCount",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatMonthYear(item.dateToCount))+" ")]}},{key:"item.countDate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatToLocal(item.countDate))+" ")]}},{key:"item.validationDate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatToLocal(item.validationDate))+" ")]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('span',{class:{
        'warning--text': item.status === 'PERFORMED',
        'success--text': item.status === 'VALIDATION_COMPLETED',
        'error--text': item.status === 'PERFORMED_RETROACTIVE',
        'font-weight-bold': true,
      }},[_vm._v(" "+_vm._s(_vm.formatStatusName(item.status))+" ")])]}},{key:"item.receipt",fn:function(ref){
      var item = ref.item;
return [_c('v-btn',{attrs:{"color":"primary","icon":""},on:{"click":function($event){return _vm.goToScoreDetails(item.id)}}},[_c('v-icon',[_vm._v("mdi-magnify")])],1)]}}],null,true)}),_c('pagination-component',{staticClass:"mt-8 ml-2",attrs:{"pagination":_vm.page},on:{"input":_vm.updateTable}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }