























































import {
  Vue,
  Component,
  Prop,
  Watch,
} from 'vue-property-decorator';
import { VbLoadingService, VbSnackbarService } from 'bag-of-holding-library';
import { hasEmployeeAdministratorAuthority, hasManagerAuthority } from '@/shared/directive/has-authority.directive';
import TabButton from '@/shared/tab-button.component.vue';
import CardTitle from '@/shared/card/card-title.component.vue';
import EmployeeService from '@/domain/employees/service/employee.service';
import Employee from '@/domain/employees/entity/employee.entity';
import CareerUpdate from '@/domain/employees/entity/career-update.entity';
import CareerService from '@/domain/employees/service/career.service';
import ModalOptions from '@/shared/modal/modal-options.entity';
import ModalWarning from '@/shared/modal/modal-warning.component.vue';
import ProfileMenu from '../../profile-menu.component.vue';
import UpdateList from './career-update-list.component.vue';
import CreateModal from '../../modal/create-update-modal.component.vue';
import HistoryModal from '../../modal/history-modal.component.vue';

@Component({
  components: {
    TabButton,
    UpdateList,
    ProfileMenu,
    CreateModal,
    HistoryModal,
    CardTitle,
    ModalWarning,
  },
  directives: { hasEmployeeAdministratorAuthority, hasManagerAuthority },
})
export default class CarrerDataComponent extends Vue {
  @Prop() unlockUpdateCareerUpdate!: boolean;

  @Prop() checkAccessCreateCareerUpdate!: boolean;

  @Prop() unlockDeleteCareerUpdate!: boolean;

  @Prop() unlockDeletePastCareerUpdate!: boolean;

  @Prop() checkAccessReadUpdateHistory!: boolean;

  @Prop({
    type: Boolean,
  }) hasUpdatePastCareerUpdateEvent!: boolean;

  @Prop({
    type: [Employee || Object],
    default: () => new Employee({}),
  }) employee!: Employee;

  updates: CareerUpdate[] = [];

  aux: CareerUpdate = new CareerUpdate({});

  showCreate: boolean = false;

  showAlert: boolean = false;

  deletableUpdate: CareerUpdate = new CareerUpdate({});

  showHistory: boolean = false;

  authorities: any = '';

  reset: boolean = false;

  edit: boolean = false;

  key: string = '';

  current: CareerUpdate = new CareerUpdate({});

  loadingSaveButton: boolean = false;

  unlockDeleteCareerUpdateForLengthGreaterThanOne: boolean = false;

  get modalWarningOptions(): ModalOptions {
    return {
      title: 'Atenção!',
      description: 'Deseja realmente prosseguir? Ao clicar no botão Sim, o evento será imediatamente descartado.',
      showOk: true,
      disabledOk: false,
      labelOk: 'Sim',
      okColor: 'tabBlue',
      showCancel: true,
      labelCancel: 'Não',
      width: 400,
    };
  }

  back() {
    this.$router.push('/');
  }

  get hasContent() {
    return this.updates.length === 0;
  }

  @Watch('employee')
  getProfile() {
    if (this.employee) {
      this.getUpdates();
    }
  }

  getUpdates() {
    EmployeeService.getUpdates(this.employee.id)
      .then((response: CareerUpdate[]) => {
        this.updates = response;
        this.unlockDeleteCareerUpdateForLengthGreaterThanOne = this.unlockDeleteCareerUpdate
          && this.updates.length > 1;
        this.finallyCareerFetch();
        if (this.updates.length > 0) {
          this.updates[0].select();
        }
      })
      .catch((error) => {
        VbSnackbarService.handleHttpError(error)
      });
  }

  setSelected(): void {
    this.updates.forEach((el: CareerUpdate) => {
      el.unselect();
      this.$forceUpdate();
    });
  }

  saveNewUpdate(update: CareerUpdate) {
    VbLoadingService.show();
    this.loadingSaveButton = true;
    CareerService.saveCareer(this.employee.id, update.setUpdateValues())
      .then((response: any) => {
        VbSnackbarService.showSuccess('Evento salvo com sucesso');
        this.getUpdates();
        this.showCreate = false;
        this.updateKey();
      })
      .catch(error => VbSnackbarService.handleHttpError(error))
      .finally(() => {
        VbLoadingService.hide();
        this.loadingSaveButton = false;
      });
  }

  updateKey() {
    this.key = new Date().toISOString();
  }

  createCareerUpdateEvent() {
    this.edit = false
    this.showCreate = true
    this.current = CareerUpdate.of(this.updates[0]);
  }

  editUpdate(value: CareerUpdate) {
    this.edit = true;
    this.current = value
    this.showCreate = true;
  }

  deleteAlert(value: CareerUpdate) {
    this.showAlert = true;
    this.deletableUpdate = value;
  }

  deleteUpdate() {
    VbLoadingService.show();
    CareerService.deleteCareer(this.employee.id, this.deletableUpdate.id)
      .then(() => {
        VbSnackbarService.showSuccess('Evento excluido com sucesso');
        this.getUpdates();
        this.deletableUpdate = new CareerUpdate({});
      })
      .catch(err => VbSnackbarService.handleHttpError(err))
      .finally(() => VbLoadingService.hide());
  }

  updateCareer(career: CareerUpdate) {
    VbLoadingService.show();
    this.loadingSaveButton = true;
    CareerService.updateCareer(this.employee.id, career.id, career.setUpdateValues())
      .then(() => {
        VbSnackbarService.showSuccess('Evento alterado com sucesso');
        this.getUpdates();
        this.showCreate = false;
        this.updateKey();
      })
      .catch(err => VbSnackbarService.handleHttpError(err))
      .finally(() => {
        VbLoadingService.hide();
        this.loadingSaveButton = false;
      });
  }

  showModalHistory() {
    this.showHistory = true
  }

  finallyCareerFetch() {
    this.$emit('finallyCareerFetch', this.updates[0].assignment);
  }
}
