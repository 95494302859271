





































import { Vue, Component } from 'vue-property-decorator';
import CardTitle from '@/shared/card/card-title.component.vue';
import SelectiveProcessService from '@/domain/selective-process/service/selective-process.service';
import CandidateCultureGradeDetails from '@/domain/selective-process/entity/candidate/candidate-culture-grade-details.entity';
import NotFoundComponent from '@/shared/not-found/not-found.component.vue';
import ExpansionPanelComponent from './expansion-panel.component.vue';

@Component({
  components: {
    ExpansionPanelComponent,
    CardTitle,
    NotFoundComponent,
  },
})
export default class CandidateCultureGradeDetailsView extends Vue {
  items: CandidateCultureGradeDetails[] = [];

  loading: boolean = false;

  error: string = '';

  created() {
    this.$destroy();
    this.loading = true;
    SelectiveProcessService.getCandidateCultureGradeDetails(this.$route.params.candidateId)
      .then((response) => {
        this.items = CandidateCultureGradeDetails.ofList(response.data);
        if (this.items.length === 0) {
          this.error = 'Nenhuma resposta de fit cultural foi encontrada!'
        }
      })
      .catch((error) => {
        // eslint-disable-next-line prefer-destructuring
        this.error = error.response.data.messages[0];
      })
      .finally(() => this.loading = false)
  }

  back() {
    window.history.go(-1);
  }
}
