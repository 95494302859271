









































import ScoreDependentBenefits from '@/domain/employees/entity/score-dependent-benefits';
import Assessment from '@/domain/selective-process/entity/assessment/assessment.entity';
import PaginationComponent from '@/shared/pagination.component.vue';
import MobileHandler from '@/shared/utils/mobile-handler';
import { Vue, Component, Prop } from 'vue-property-decorator';
import SelectiveProcessModal from './modal/selective-process-modal.component.vue';
import Headers from './selective-process-table-headers.json';

@Component({
  components: {
    SelectiveProcessModal,
    PaginationComponent,
  },
})
export default class SelectiveProcessTable extends Vue {
  @Prop() assessments!: Assessment[];

  @Prop({
    type: Boolean,
  }) loading!: boolean;

  @Prop() page!: any;

  headers = Headers;

  openModal: boolean = false;

  assessmentSelected = new Assessment();

  keyItemSelected: number = -1;

  isMobile = false;

  openCandidateModal(item: any) {
    this.openModal = true;
    this.assessmentSelected = item;
    this.keyItemSelected = this.assessments.indexOf(item);
  }

  closeModal(event: any) {
    this.openModal = event;
  }

  onResize() {
    this.isMobile = MobileHandler.isMobile();
  }

  updateSortBy(sortBy: any): void {
    this.updateTable({
      ...this.page,
      sortBy,
    });
  }

  updateSortDesc(sortDesc: any): void {
    this.updateTable({
      ...this.page,
      sortDesc,
    });
  }

  updateTable(pagination: any) {
    this.$emit('update', pagination)
  }
}
