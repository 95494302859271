import KeyAreaSkillType from './key-area-skill-type';
import Level from './level';

export default class AssessmentForm {
  assignmentId: string

  assignmentDescription: string;

  levels: Level[];

  skillsType: KeyAreaSkillType[];

  constructor(obj: any = {}) {
    this.assignmentId = obj.assignmentId || '';
    this.assignmentDescription = obj.assignmentDescription || '';
    this.levels = obj.levels ? obj.levels.map(level => new Level(level)) : [];
    this.skillsType = obj.skillsType ? obj.skillsType
      .map((skillType: KeyAreaSkillType) => new KeyAreaSkillType(skillType)) : [];
  }
}
