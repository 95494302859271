


























































import { Vue, Component } from 'vue-property-decorator';
import { VbNavBar, VbLoadingComponent, VbSnackbarComponent } from 'bag-of-holding-library';
import AuthenticationService from '@/domain/security/authentication.service';
import MenuHeader from '@/shared/menu-header/menu-header.component.vue';
import Card from '@/shared/card/card.component.vue';
import MobileHandler from '@/shared/utils/mobile-handler';
import ReleaseService from '@/domain/release/service/release.service';
import NavbarButtons from './nav-buttons.json';
import AuthorityStore from './domain/security/authority.store';
import MenuSidebar from './shared/main-menu/menu-sidebar.component.vue';
import MenuTopbar from './shared/main-menu/menu-topbar.component.vue';
import Release from './domain/release/entity/release.entity';
import ReleaseComponent from './shared/release/release-component.vue';

@Component({
  components: {
    MenuHeader,
    Card,
    VbNavBar,
    VbLoadingComponent,
    VbSnackbarComponent,
    MenuSidebar,
    MenuTopbar,
    ReleaseComponent,
  },
})
export default class App extends Vue {
  isMobile: boolean = false;

  loadingAuthorities: boolean = true;

  authorities: any = [];

  navButtons = NavbarButtons;

  buttons: any = [];

  showRelease: boolean = false;

  release: Release = new Release();

  showCountdown: boolean = false;

  closeRelease() {
    this.showRelease = false;
  }

  created() {
    this.getRelease();
    if (this.$route.name === 'Formulario') {
      return;
    }
    AuthenticationService.msal = this.$msal;
    AuthenticationService.login();
    this.getAuthorities();
  }

  get isHome(): boolean {
    return this.$route.name === 'Home';
  }

  get isAuthenticated() {
    return AuthenticationService.isAuthenticated();
  }

  onResize() {
    this.isMobile = MobileHandler.isMobile();
  }

  verifyNavigationAuthorities() {
    this.navButtons.forEach((el: any, index) => {
      if (this.verifyAuthorities(el.authorities)) {
        this.buttons.push(el)
      }
    })
  }

  verifyAuthorities(value: any) {
    if (value === 'FACILITIES_ADMINISTRATOR') {
      return this.isFacilitiesAdministrator();
    }
    if (value === 'ADMINISTRATOR') {
      return this.isAdministrator();
    }
    if (value === 'MANAGER') {
      return this.isManager();
    }
    return true;
  }

  getAuthorities() {
    this.loadingAuthorities = true;
    return AuthorityStore.fetchedAuthority()
      .then((resp: any) => {
        this.authorities = resp.authorities;
        this.verifyNavigationAuthorities();
      })
      .catch((err: any) => AuthenticationService.logout())
      .finally(() => { this.loadingAuthorities = false; });
  }

  isAdministrator() {
    return this.authorities.includes('ADMINISTRATOR');
  }

  isFacilitiesAdministrator() {
    return this.isAdministrator()
        || this.authorities.includes('FACILITIES_ADMINISTRATOR');
  }

  isManager() {
    return this.isAdministrator()
            || this.authorities.includes('MANAGER');
  }

  get showHeader() {
    return this.$route.name === 'Perfil'
  }

  get padding() {
    return this.$route.name === 'Cronograma' && !this.isMobile ? 'pl-8' : 'pl-5';
  }

  async getRelease() {
    await ReleaseService.getRelease()
      .then(response => this.release = response);
    this.showCountdown = this.release.showCountdown;
    this.showRelease = this.release.showRelease;
  }
}
