














































import WageHistory from '@/domain/wages/entity/wage-history.entity';
import { VbDirectives } from 'bag-of-holding-library';
import { Vue, Component, Prop } from 'vue-property-decorator'
import HistoryTableHeader from '../history-table-header.json'

@Component({
  filters: {
    toCurrency: VbDirectives.toCurrency,
  },
})
export default class HeaderComponent extends Vue {
  @Prop({
    default: () => [],
  }) items!: WageHistory[];

  header = HistoryTableHeader;
}
