
























































































































































import { Vue, Component, Prop } from 'vue-property-decorator';
import { VbSnackbarService, VbTooltipSlot } from 'bag-of-holding-library';
import ModalComponent from '@/shared/modal/modal.component.vue';
import LabelSlot from '@/shared/slots/label-slot.component.vue';
import Rules from '@/shared/rules/rules';
import DatePickerField from '@/shared/date-picker/date-picker-field.component.vue';
import ModalOptions from '@/shared/modal/modal-options.entity';
import SkillService from '@/domain/employees/service/skill.service';
import Filter from '@/domain/employees/entity/filter.entity';
import Skill from '@/domain/employees/entity/skill.entity';

@Component({
  components: {
    ModalComponent,
    LabelSlot,
    VbTooltipSlot,
    DatePickerField,
  },
})
export default class ResignationModal extends Vue {
  @Prop() show!: boolean;

  @Prop() showResignationModal!: boolean;

  @Prop() hardSkills!: any[];

  @Prop() frameworks!: any[];

  @Prop() businessExperiences!: any[];

  @Prop() certifications!: any[];

  @Prop() skills: Skill[] = [];

  @Prop() checkboxesHardSkill!: any[];

  @Prop() checkboxesFrameworks!: any[];

  @Prop() checkboxesBusiness!: any[];

  @Prop() checkboxesCertification!: any[];

  @Prop() selectedHardCheckboxes!: any[];

  @Prop() selectedBusinessCheckboxes!: any[];

  @Prop() selectedFrameworkCheckboxes!: any[];

  @Prop() selectedCertificationCheckboxes!: any[];

  private filter: Filter = Filter.of({});

  valid: boolean = false;

  rules: Rules = new Rules();

  role: any = '';

  loading = false;

  refs: any = this.$refs;

  selectedIndex = 0;

  private get optionsHandled(): ModalOptions {
    return {
      title: 'Mapeamento de skills',
      titleSize: '25px',
      description: '',
      showOk: true,
      labelOk: 'Salvar',
      disabledOk: !this.valid || this.loading,
      okColor: 'tabBlue',
      showCancel: !this.loading,
      labelCancel: 'Cancelar',
      width: 880,
    };
  }

  async save() {
    let allIds: string[] = [];
    const employeId = sessionStorage.getItem('employeId');

    this.selectedHardCheckboxes.forEach((item) => {
      allIds.push(item);
    });

    this.selectedBusinessCheckboxes.forEach((item) => {
      allIds.push(item);
    });

    this.selectedFrameworkCheckboxes.forEach((item) => {
      allIds.push(item);
    });

    this.selectedCertificationCheckboxes.forEach((item) => {
      allIds.push(item);
    });

    allIds = allIds.filter(str => str !== '');

    this.loading = true;

    SkillService.addSkillToEmployee(employeId, allIds)
      .then((resp: any) => {
        VbSnackbarService.showSuccess('Skill salva com sucesso');
      })
      .catch((err: any) => VbSnackbarService.handleHttpError(err))
      .finally(() => {
        this.loading = false;
        this.$emit('afterSave');
        this.$emit('cancel');
      });
  }

  close() {
    if (!this.loading) {
      this.$emit('cancel');
    }
  }

  openSuggestSkillModal() {
    this.$emit('openSuggestSkill');
  }

  clear() {
    this.role = '';
  }

  selectButton(index) {
    this.selectedIndex = index;
  }
}
