var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{directives:[{name:"resize",rawName:"v-resize",value:(_vm.onResize),expression:"onResize"}],staticClass:"data-table rounded-lg",attrs:{"hide-default-footer":"","loading-text":"Carregando...","no-data-text":"Nenhum colaborador foi encontrado com esses filtros","headers":_vm.headers,"items":_vm.items,"loading":_vm.loading,"page":_vm.page.page,"sort-by":_vm.page.sortBy,"sort-desc":_vm.page.sortDesc,"items-per-page":_vm.page.itemsPerPage,"page-count":_vm.page.pageCount},on:{"update:sortBy":function($event){return _vm.$set(_vm.page, "sortBy", $event)},"update:sort-by":[function($event){return _vm.$set(_vm.page, "sortBy", $event)},_vm.updateTable],"update:sortDesc":function($event){return _vm.$set(_vm.page, "sortDesc", $event)},"update:sort-desc":[function($event){return _vm.$set(_vm.page, "sortDesc", $event)},_vm.updateTable],"update:itemsPerPage":function($event){return _vm.$set(_vm.page, "itemsPerPage", $event)},"update:items-per-page":function($event){return _vm.$set(_vm.page, "itemsPerPage", $event)},"update:pageCount":function($event){return _vm.$set(_vm.page, "pageCount", $event)},"update:page-count":function($event){return _vm.$set(_vm.page, "pageCount", $event)},"update":_vm.updateTable,"click:row":_vm.redirectEmployeeAssessmentResultScreen},scopedSlots:_vm._u([{key:"item.employeeAdherenceTotal",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.employeeAdherenceTotal ? item.employeeAdherenceTotal.toFixed() : 0)+"% ")]}},{key:"item.knowledgeAdherence",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.knowledgeAdherence ? item.knowledgeAdherence.toFixed() : 0)+"% ")]}},{key:"item.skillAdherence",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.skillAdherence ? item.skillAdherence.toFixed() : 0)+"% ")]}},{key:"item.behaviorAdherence",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.behaviorAdherence ? item.behaviorAdherence.toFixed() : 0)+"% ")]}}],null,true)}),_c('pagination-component',{staticClass:"mt-8 ml-md-2",attrs:{"pagination":_vm.page,"totalVisible":_vm.isMobile ? 5 : 8},on:{"input":_vm.updateTable}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }