import AdmissionDateAnniversary from './admission-date-anniversary.view.vue';

export default [
  {
    path: '/admissionDateAnniversary',
    component: AdmissionDateAnniversary,
    name: 'AdmissionDateAnniversary',
    meta: {
      hideMenus: true,
    },
  },
];
