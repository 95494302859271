




















import { Vue, Component, Prop } from 'vue-property-decorator';
import LabelSlot from '@/shared/slots/label-slot.component.vue';
import AuthenticationService from '@/domain/security/authentication.service';
import AuthorityStore from '@/domain/security/authority.store';
import Filter from '@/domain/wages/entity/filter.entity';
import EmployeeSummary from '../../../../domain/schedule/employee/employee-summary.entity';
import UpdateAtribute from '../../../../domain/employees/entity/update-atribute.entity';

@Component({ components: { LabelSlot } })
export default class FilterComponent extends Vue {
  @Prop() options!: Filter;

  @Prop() managers!: EmployeeSummary;

  @Prop() employees!: EmployeeSummary;

  @Prop() roles!: UpdateAtribute;

  @Prop() businessUnits!: UpdateAtribute;

  @Prop() lastFilter?: Filter;

  private filter: Filter = new Filter({});

  isManager: boolean = false;

  managerName: string = '';

  isManagerAuth() {
    return AuthorityStore.isManager()
      .then((response) => {
        this.isManager = response;
        this.applyInitialFilter();
      })
  }

  get isAdministrator() {
    return AuthorityStore.isAdministrator()
      .then(response => response)
  }

  async applyInitialFilter() {
    if (this.isManager) {
      this.searchManagerName();
    } else if (await this.isAdministrator) {
      this.sendFilter()
    }
  }

  searchManagerName() {
    if (this.isManager) {
      this.managerName = AuthenticationService.userLoggedIn().name;
      return this.findManagers(this.managerName);
    }
    return this.sendFilter();
  }

  private created() {
    this.getLastFilter();
    this.sendFilter();
    this.$forceUpdate();
  }

  private getLastFilter() {
    if (this.lastFilter) {
      this.filter = this.lastFilter;
    }
  }

  private sendFilter() {
    this.$emit('filter', this.filter);
  }

  private findManagers(value: string) {
    this.$emit('findManagers', value);
  }

  private findEmployees(value: string) {
    this.$emit('findEmployees', value);
  }
}
