



















import { VbSnackbarService } from 'bag-of-holding-library';
import { Vue, Component } from 'vue-property-decorator';
import DefaultIndicator from '@/domain/drd/entity/default-indicator.entity';
import IndicatorDefaultFilter from '@/domain/drd/entity/indicators/indicator-default-filter.entity';
import IndicatorService from '@/domain/drd/service/indicator/indicator.service'
import Authority from '@/domain/security/authority.entity';
import AuthorityStore from '@/domain/security/authority.store';
import IndicatorDefaultFilterComponent from './indicator-default-filter.component.vue';
import IndicatorDefaultTableComponent from './indicator-default-table.component.vue';

@Component({
  components: {
    IndicatorDefaultFilterComponent,
    IndicatorDefaultTableComponent,
  },
})
export default class IndicatorDefaultView extends Vue {
  indicators: DefaultIndicator[] = [];

  filter: IndicatorDefaultFilter = new IndicatorDefaultFilter();

  loading: boolean = false;

  sortBy: Array<string> = ['indicator'];

  sortDesc: boolean[] = [false];

  direction: string = 'asc';

  sort: string = '';

  pagination: any = {
    itemsPerPage: 10,
    page: 1,
    sortBy: this.sortBy,
    sortDesc: this.sortDesc,
    total: 0,
    pageCount: 0,
  }

  authority!: Authority;

  hasCreateIndicatorDefault: boolean = false;

  hasUpdateIndicatorDefault: boolean = false;

  hasInactiveIndicatorDefault: boolean = false;

  async created() {
    await this.validationAuthorities();
    this.getIndicators();
  }

  async validationAuthorities() {
    await this.getAuthority();
    this.hasCreateIndicatorDefault = this.authority.hasCreateIndicatorDefault();
    this.hasUpdateIndicatorDefault = this.authority.hasUpdateIndicatorDefault();
    this.hasInactiveIndicatorDefault = this.authority.hasInactiveIndicatorDefault();
  }

  async getAuthority() {
    await AuthorityStore.fetchedAuthority().then(authority => this.authority = authority);
  }

  setFilterValues(value: any) {
    if (this.pagination.itemsPerPage < value.itemsPerPage) {
      value.page = 1;
    }
    this.pagination = value;
    this.getIndicators();
  }

  getIndicators() {
    this.loading = true;
    IndicatorService.getIndicatorDefaults(
      this.filter,
      this.pagination.page,
      this.pagination.itemsPerPage,
      this.pagination.sortBy,
      this.pagination.sortDesc,
    ).then(({ data }) => {
      this.indicators = data.content.map(it => new DefaultIndicator(it));
      this.pagination.total = data.totalElements
      this.pagination.pageCount = data.totalPages
    }).catch(VbSnackbarService.handleHttpError)
      .finally(() => {
        this.loading = false;
      })
  }

  sendFilter(filter: IndicatorDefaultFilter) {
    this.filter = filter;
    this.getIndicators();
  }
}
