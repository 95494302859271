import { ContractType } from '@/views/employees/profile/tab-components/score/contract-type.type';
import axios from 'axios';

export default class ProfessionalDataService {
  public static async updateContractType(
    employeeId: string,
    contractType: string,
  ): Promise<ContractType> {
    return axios.patch(`/api/employees/${employeeId}/professional/contract-type/${contractType}`)
      .then(({ data }) => data);
  }

  public static async unlockBenefitPercent(
    employeeId: string,
    unlockBenefitPercent: boolean,
  ): Promise<ContractType> {
    return axios.patch(`/api/employees/${employeeId}/professional/unlock-benefit-percent/${unlockBenefitPercent}`);
  }
}
