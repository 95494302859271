export default class Ecosystem {
  public id: string;

  public description: string;

  constructor(obj: any = {}) {
    this.id = obj.id;
    this.description = obj.description || '';
  }
}
