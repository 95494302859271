export default class Department {
  id: string;

  name: string;

  externalId: string;

  threshold: number;

  editing: boolean = false;

  constructor(obj: any = {}) {
    this.id = obj.id || '';
    this.name = obj.name || '';
    this.externalId = obj.externalId || '';
    this.threshold = obj.threshold;
  }
}
