import EmployeeDTO from '@/domain/employees/entity/employee-dto';

export default class ActivityDTO {
  id: string;

  name: string;

  expectedDate: string

  employee: EmployeeDTO

  private constructor(obj: any) {
    this.id = obj.id;
    this.name = obj.name;
    this.expectedDate = obj.expectedDate || ''
    this.employee = EmployeeDTO.of(obj.employee)
  }

  public static of(obj: any): ActivityDTO {
    return new ActivityDTO(obj || {});
  }

  public static ofList(list: any): ActivityDTO[] {
    return list.map((value: any) => this.of(value));
  }
}
