













import { Vue, Component, Prop } from 'vue-property-decorator';

@Component
export default class LabelSlot extends Vue {
  @Prop() label!: string;

  @Prop() labelClass?: string;

  @Prop({
    default: '#0099B7',
  }) textColor?: string;

  @Prop({
    required: false,
    default: true,
  })
  enabled?: boolean;
}
