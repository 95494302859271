import DateFormat from '@/shared/utils/date-format';
import ChildrenData from './children-data.entity';
import FilteredChildren from './filtered-children.entity';

export default class FilteredPersonalData {
  name: string;

  companyEmail: string;

  privateEmail: string;

  birthDate: string;

  address: string;

  addressNumber: string;

  complement: string;

  district: string;

  city: string;

  state: string;

  countryName: string;

  postalCode: string;

  gender: string;

  religion: string;

  specifiedReligion: string;

  children: String;

  observation: string;

  manager: string;

  role: string;

  workplace: string;

  operationModality: string;

  businessUnit: string;

  resultCenter: string;

  emergencyName: string;

  emergencyPhone: string;

  emergencyRelationship: string;

  cellPhone: string;

  residentialPhone: string;

  usesIfood: string;

  shirtSize: string;

  consumeAlcoholicDrink: string;

  foodAllergies: string;

  foodDoNotLike: string;

  commemorativeDates : string[]

  active: string;

  accompanied: string;

  document: string;

  admissionDate: string;

  seniorityName: string;

  constructor(obj: any) {
    this.name = obj.name || '';
    this.active = obj.active;
    this.accompanied = obj.accompanied;
    this.companyEmail = obj.companyEmail || '';
    this.privateEmail = obj.privateEmail || '';
    this.birthDate = obj.birthDate || '';
    this.address = obj.address || '';
    this.addressNumber = obj.addressNumber || '';
    this.district = obj.district || '';
    this.complement = obj.complement || '';
    this.city = obj.city || '';
    this.state = obj.state || '';
    this.postalCode = obj.postalCode || '';
    this.gender = obj.gender || '';
    this.religion = obj.religion || '';
    this.specifiedReligion = obj.specifiedReligion || '';
    this.observation = obj.observation || '';
    this.children = obj.children || '';
    this.manager = obj.manager || '';
    this.role = obj.role || '';
    this.workplace = obj.workplace || '';
    this.operationModality = obj.operationModality || '';
    this.businessUnit = obj.businessUnit || '';
    this.resultCenter = obj.resultCenter || '';
    this.emergencyName = obj.emergencyName || '';
    this.emergencyPhone = obj.emergencyPhone || '';
    this.emergencyRelationship = obj.emergencyRelationship || '';
    this.cellPhone = obj.cellPhone || '';
    this.residentialPhone = obj.residentialPhone || '';
    this.usesIfood = obj.usesIfood;
    this.shirtSize = obj.shirtSize || '';
    this.consumeAlcoholicDrink = obj.consumeAlcoholicDrink;
    this.foodAllergies = obj.foodAllergies || 'NO_RESTRICTIONS';
    this.foodDoNotLike = obj.foodDoNotLike || '';
    this.commemorativeDates = obj.commemorativeDates || 'ALL';
    this.countryName = obj.countryName || '';
    this.document = obj.document || '';
    this.admissionDate = obj.admissionDate || '';
    this.seniorityName = obj.seniorityName || '';
  }

  public static of(obj: any): FilteredPersonalData {
    return new FilteredPersonalData(obj || {})
  }

  public static ofList(obj: any[]): FilteredPersonalData[] {
    return obj.map(this.of);
  }

  get valueBirthDate() {
    return this.birthDate.slice(0, 10);
  }

  get valueActive() {
    return this.active === '1' ? 'Sim' : 'Não'
  }

  get valueAccompanied() {
    return this.accompanied === '1' ? 'Sim' : 'Não'
  }

  get valueUsesIfood() {
    return this.usesIfood === '1' ? 'Sim' : 'Não';
  }

  get valueConsumeAlcoholicDrink() {
    return this.consumeAlcoholicDrink === '1' ? 'Sim' : 'Não';
  }

  get valueCommemorativeDates() {
    return this.commemorativeDates.includes('ALL,') ? 'ALL' : this.commemorativeDates;
  }

  get admissionDateFormated() {
    return DateFormat.splitLocal(this.admissionDate);
  }
}
