import moment from 'moment';

const getDays = (obj: any): number => {
  if (obj.days !== null && obj.days !== undefined) {
    return obj.days;
  }
  if (obj.start !== null && obj.start !== undefined && obj.end !== null && obj.end !== undefined) {
    const start = moment(obj.start, 'YYYY-MM-DD');
    const end = moment(obj.end, 'YYYY-MM-DD');
    return end.diff(start, 'days') + 1;
  }
  return 0;
}

export default class Vacation {
  id: string;

  employeeName: string;

  period: number;

  start: string;

  end: string;

  days: number;

  constructor(obj: any = {}) {
    this.id = obj.id || null;
    this.employeeName = obj.employeeName || '';
    this.period = obj.period || null;
    this.start = obj.start || '';
    this.end = obj.end || '';
    this.days = getDays(obj);
  }
}
