import Schedules from './schedule.view.vue';

export default [
  {
    path: '/schedule',
    component: Schedules,
    name: 'Cronograma',
    children: [
    ],
  },
];
