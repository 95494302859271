const scoreSource = {
  employee: 'employee-profile',
  activity: 'schedule-activity',
}

const maxDayCreateScoreInMonthNow = () => 20;

enum FixedAgreement {
  ROLE = 'Cargo',
  ASSIGNMENT = 'Papel',
  SENIORITY = 'Senioridade',
  SALARY_IN_CLT = 'Salário em CLT',
  DUCZ = 'Ducz (Alimentação/Flex)',
  DUCZ_SYNDICATE_FREE = 'Ducz (Sintinorp/Livre)',
  EDUCATION_SUBSIDY_ASSISTANCE = 'Subsídio/Auxílio para Educação',
  TOTAL = 'Total'
}

enum CurrencyType {
  BRL = 'BRL',
  USD = 'USD',
  CLP = 'CLP',
  ARS = 'ARS',
}

export {
  scoreSource,
  maxDayCreateScoreInMonthNow,
  FixedAgreement,
  CurrencyType,
}
