






































































































































import {
  Component, Prop, Vue,
} from 'vue-property-decorator';
import { VbSnackbarService } from 'bag-of-holding-library';
import LabelSlot from '@/shared/slots/label-slot.component.vue';
import PersistSkill from '@/domain/drd-2.0/entity/persist-skill.entity'
import SkillGroup from '@/domain/drd-2.0/entity/skill-group.entity';
import SkillService from '@/domain/drd-2.0/service/skill.service';
import Rules from '@/shared/rules/rules';
import AutocompleteEditableItems from '@/shared/autocompletes/autocomplete-editable-items.vue';
import SelectSkillType from '../components/select-skill-type.component.vue';

@Component({
  components: {
    LabelSlot,
    SelectSkillType,
    AutocompleteEditableItems,
  },
})
export default class FormSkillComponent extends Vue {
  @Prop({
    required: true,
  }) skill!: PersistSkill;

  skillGroups: SkillGroup[] = [];

  update(value: string, field: string) {
    this.$emit('update', { field, value })
  }

  group = '';

  groupSearchName: string = '';

  editing = null;

  editingIndex = -1;

  groups: SkillGroup[] = [];

  loadingGroups: boolean = false;

  loadingUpdateGroup: boolean = false;

  loadingCreateGroup: boolean = false;

  loadingDeleteGroup: boolean = false;

  rules: Rules = new Rules();

  created() {
    this.findAllGroups();
  }

  findAllGroups() {
    this.loadingGroups = true;
    SkillService.findAllGroups()
      .then(response => this.groups = response.data)
      .finally(() => this.loadingGroups = false);
  }

  createGroup() {
    const groupFiltered = this.groups
      .filter(item => item.name.toLowerCase() === this.groupSearchName.toLowerCase());
    if (groupFiltered.length > 0) return;
    this.loadingCreateGroup = true;
    SkillService.createGroup(this.groupSearchName)
      .then((response) => {
        this.groups = response.data;
        VbSnackbarService.showSuccess('Grupo criado!');
      }).catch((error) => {
        VbSnackbarService.showError(error.response.data.messages.toString());
      }).finally(() => {
        this.loadingCreateGroup = false;
        this.editing = null;
        this.editingIndex = -1;
      });
    this.groupSearchName = '';
  }

  edit(index, item) {
    if (!this.editing) {
      this.editing = item;
      this.editingIndex = index;
      return;
    }
    this.editing = null;
    this.editingIndex = -1;
  }

  updateGroup(item: SkillGroup) {
    this.loadingUpdateGroup = true;
    SkillService.updateGroup(item)
      .then((response) => {
        this.groups = response.data;
        VbSnackbarService.showSuccess('Grupo atualizado!');
      }).catch((error) => {
        VbSnackbarService.showError(error.response.data.messages.toString());
      }).finally(() => {
        this.loadingUpdateGroup = false;
        this.editing = null;
        this.editingIndex = -1;
      });
  }

  deleteGroup(item: SkillGroup) {
    this.loadingDeleteGroup = true;
    SkillService.deleteGroup(item.id)
      .then((response) => {
        this.groups = response.data;
        this.update('', 'groupId');
        this.groupSearchName = '';
        VbSnackbarService.showSuccess('Grupo deletado!');
      })
      .catch(error => VbSnackbarService.handleHttpError(error))
      .finally(() => this.loadingDeleteGroup = false);
  }
}
