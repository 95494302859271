































































import { Vue, Component, Prop } from 'vue-property-decorator';
import CandidateCultureGradeDetails from '@/domain/selective-process/entity/candidate/candidate-culture-grade-details.entity';
import MobileHandler from '@/shared/utils/mobile-handler';
import moment from 'moment';
import TableHeaders from './headers.json';

@Component
export default class ExpansionPanelComponent extends Vue {
  @Prop() items!: CandidateCultureGradeDetails[];

  @Prop() loading!: boolean;

  private headers = TableHeaders;

  private panel = [0];

  private isMobile = false;

  formatDateTime(date: string) {
    return moment(date).format('DD/MM/YYYY HH:mm');
  }

  private onResize() {
    this.isMobile = MobileHandler.isMobile();
  }
}
