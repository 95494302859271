





















































































import { Vue, Component, Prop } from 'vue-property-decorator';
import EmployeeFilter from '@/shared/filters/employees/employee-filter.component.vue';
import AssessmentFilter from '@/domain/assessment/entity/assessment-filter.entity';
import EmployeeSummary from '@/domain/schedule/employee/employee-summary.entity';
import LabelSlot from '@/shared/slots/label-slot.component.vue';
import ManagerFilter from '@/shared/filters/employees/manager-filter.component.vue';
import ResultCenterFilter from '@/shared/filters/result-center/result-center-filter.component.vue';
import BusinessUnitFilter from '@/shared/filters/business-unit/business-unit-filter.component.vue';
import AssignmentFilter from '@/shared/filters/assignment/assignment-filter.component.vue';

@Component({
  components: {
    EmployeeFilter,
    LabelSlot,
    ManagerFilter,
    ResultCenterFilter,
    BusinessUnitFilter,
    AssignmentFilter,
  },
})
export default class AssessmentResultsFilterComponent extends Vue {
  @Prop({
    type: Boolean,
    default: false,
  }) loading!: boolean;

  @Prop({
    type: Boolean,
    default: false,
  }) isManager!: boolean;

  filter: AssessmentFilter = new AssessmentFilter();

  employees: EmployeeSummary[] = [];

  lastFilterEmployee: string = '';

  selected(values: string[], field: string) {
    this.filter[field] = values;
  }

  sendFilter() {
    this.$emit('sendFilter', this.filter);
  }
}
