


























import { Vue, Component, Prop } from 'vue-property-decorator';
import MainMenu from './main-menu.component.vue';

@Component({ components: { MainMenu } })
export default class MenuSidebar extends Vue {
  @Prop({
    type: Array,
    default: () => ([]),
  })
  private authorities!: string[];

  private goToHomepage(): void {
    this.$router.push('/');
  }
}
