
















import { Vue, Component } from 'vue-property-decorator';
import MenuHeaderOptions from './menu-header-options.component.vue';
import MenuHeaderUser from './menu-header-user.component.vue';
import Card from '../card/card.component.vue';

@Component({ components: { MenuHeaderOptions, MenuHeaderUser, Card } })
export default class MenuHeader extends Vue {
  private get route() {
    const route: any = this.$route.name;
    if (route.includes('Perfil')) {
      return 'Perfil'
    }
    if (route === 'Home') {
      return 'Página Inicial';
    }
    return route;
  }

  private get path(): string {
    const route: any = this.$route.name;
    return route.includes('Perfil') ? 'Página Inicial/Colaboradores' : 'Página Inicial';
  }
}
