import DateFormat from '../../../shared/utils/date-format';

export default class ActivityReassign {
    newDate: string;

    reason: string;

    changeNextActivities: boolean = false;

    inactiveLateHistorys: boolean = false;

    private constructor(obj: any) {
      this.newDate = obj.newDate || '';
      this.reason = obj.reason || '';
    }

    public static of(obj: any): ActivityReassign {
      return new ActivityReassign(obj || {});
    }

    set newDateFormated(value: string) {
      this.newDate = DateFormat.monthYearFromFormated(value);
    }

    get newDateFormated(): string {
      return DateFormat.monthYearToFormated(this.newDate);
    }
}
