export default class GodfatherRequest {
  isGrandFather: boolean = false;

  trainingDate: string;

  constructor(obj: any) {
    this.isGrandFather = obj.isGrandFather;
    this.trainingDate = obj.trainingDate || '';
  }

  public static of(obj: any = {}): GodfatherRequest {
    return new GodfatherRequest(obj);
  }
}
