import axios from 'axios';
import ActivityHappinessAnswer from '../entity/activity-happiness-answer.entity';
import ActivityHappinessResponse from '../entity/activity-happiness-response';

export default class ActivityService {
  public static activeOrInactiveActivityHistoryItem(historyId: string, active: boolean) {
    return axios.patch(`/api/activity/${historyId}/${active}`);
  }

  public static sendActivityHappiness(data: ActivityHappinessAnswer) {
    return axios.post('/api/activity/happiness', data);
  }

  public static getActivityHappinessResponse(
    activityId: string,
  ): Promise<ActivityHappinessResponse[]> {
    return axios.get(`/api/activity/${activityId}/happiness-response`)
      .then(({ data }) => data.map(it => new ActivityHappinessResponse(it)));
  }
}
