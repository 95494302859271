import FilterUtils from '@/shared/utils/filters';

export default class ResignationRequest {
  employee: string;

  demissionType: any;

  requestDate: string;

  accessExpiration: string;

  shutdownTime: string;

  director: string;

  devolutionType: string;

  responsible: string;

  city: string;

  state: string;

  employeeCellPhone: string;

  employeeEmail: string;

  complyNotice: boolean;

  noticeStartDate: string;

  observation: string;

  backupTypes : string[];

  recommendRehiring: boolean;

  recommendRehiringJustification: string;

  constructor(obj: any) {
    const expirationDate = obj.accessExpiration;
    this.employee = obj.employee || '';
    this.demissionType = obj.demissionType || '';
    this.requestDate = obj.requestDate || '';
    this.accessExpiration = `${expirationDate} ${obj.accessTimeExpiration}:00`;
    this.shutdownTime = `${expirationDate} ${obj.shutdownTime}:00`;
    this.director = obj.director || '';
    this.devolutionType = obj.devolutionType || '';
    this.responsible = obj.responsible || '';
    this.city = obj.city || '';
    this.state = obj.state || '';
    this.employeeCellPhone = obj.employeeCellPhone || '';
    this.employeeEmail = obj.employeeEmail || '';
    this.complyNotice = obj.complyNotice;
    this.noticeStartDate = obj.complyNotice ? obj.noticeStartDate : null;
    this.observation = obj.observation || '';
    this.backupTypes = obj.backupTypes;
    this.recommendRehiring = obj.recommendRehiring;
    this.recommendRehiringJustification = obj.recommendRehiringJustification;
  }

  public static of(obj: any): ResignationRequest {
    return new ResignationRequest(obj || {});
  }

  public static ofList(obj: any[]): ResignationRequest[] {
    return obj.map(this.of);
  }
}
