























import { Vue, Prop, Component } from 'vue-property-decorator';
import BusinessUnitEmployeeAmount from '@/domain/employees/entity/business-unit-employee-amount.entity';
import GraphCaptionListItem from './home-graph-caption-list-item.component.vue';

@Component({
  components: {
    GraphCaptionListItem,
  },
})
export default class GraphCaptionList extends Vue {
  @Prop({
    required: true,
  })
  private sections!: object[];

  private showAll: boolean = false;

  private isSectionListLengthGreaterThan(number: number): boolean {
    return this.sections.length > number;
  }

  private get showAllText(): string {
    return this.showAll ? 'Recolher...' : 'Mais...';
  }

  private get captionSections(): object[] {
    if (!this.showAll && this.isSectionListLengthGreaterThan(6)) {
      return this.sections.slice(0, 6);
    }
    return this.sections;
  }
}
