





















import {
  Vue, Component, Prop,
} from 'vue-property-decorator';
import { VbSnackbarService } from 'bag-of-holding-library';
import ModalComponent from '@/shared/modal/modal.component.vue';
import ModalOptions from '@/shared/modal/modal-options.entity';
import LabelSlot from '@/shared/slots/label-slot.component.vue';
import ActivityHappinessResponse from '@/domain/activity/entity/activity-happiness-response';
import ActivityService from '@/domain/activity/service/activity.service';
import Loading from '@/shared/loading/loading.component.vue';

@Component({
  components: {
    ModalComponent,
    LabelSlot,
    Loading,
  },
})
export default class ActivityHappinessResponseModal extends Vue {
  @Prop({
    type: Boolean,
    default: false,
  }) show!: boolean;

  @Prop({
    type: String,
    required: true,
  }) activityId!: string;

  @Prop({
    type: String,
    required: true,
  }) activityName!: string;

  loading = false;

  employeeArray: any = [];

  responses: ActivityHappinessResponse[] = [];

  get optionsHandled(): ModalOptions {
    return {
      title: `Feedbacks sobre o processo ${this.activityName}`,
      titleSize: '20px',
      description: '',
      showOk: false,
      disabledOk: true,
      width: 1200,
    };
  }

  created() {
    this.loading = true;
    ActivityService.getActivityHappinessResponse(this.activityId)
      .then(data => this.responses = data)
      .catch((error) => {
        VbSnackbarService.handleHttpError(error);
        this.close();
      }).finally(() => this.loading = false);
  }

  close() {
    this.$emit('close');
  }
}
