import axios from 'axios';
import { EmployeeChartResponse } from '../entity/employee-chart.entity';

export default class OrganizationalChartService {
  public static getOrganizationData(employeeId?: string): Promise<EmployeeChartResponse> {
    return axios.get('/api/employees/chart', {
      params: {
        employee: employeeId,
      },
    })
      .then((response: any) => response.data);
  }
}
