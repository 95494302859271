import axios from 'axios';
import Response from '../entity/response.entity';
import EmployeeRemunerationFilter from '../entity/filter.entity';
import FilteredSeniorityRemumeration from '../entity/filtered-seniority-remuneration.entity';

export default class SeniorityRemunerationService {
  public static findEmployeeRemuneration(
    page: number, size: number, filter: EmployeeRemunerationFilter,
  ): Promise<Response> {
    return axios.post('/api/wage', filter, {
      params: {
        page: page - 1,
        size,
      },
    })
      .then((response: any) => Response.of(response.data));
  }

  public static findEmployeeRemunerationExport(
    filter: EmployeeRemunerationFilter,
  ): Promise<FilteredSeniorityRemumeration[]> {
    return axios.post('/api/wage/export', filter)
      .then((response: any) => FilteredSeniorityRemumeration.ofList(response.data));
  }
}
