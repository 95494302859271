

























import { Vue, Component } from 'vue-property-decorator';
import { VbSnackbarService } from 'bag-of-holding-library';
import Authority from '@/domain/security/authority.entity';
import InitialScoreFiltered from '@/domain/initial-score/entity/initial-score-filtered.entity';
import InitialScoreService from '@/domain/initial-score/service/initial-score.service';
import InitialScoreFilter from '@/domain/initial-score/entity/initial-score-filter.entity';
import InitialScoreComponent from './new-initial-score.component.vue';
import InitialScoreFilterComponent from './initial-score-filter.component.vue';
import InitialScoreTable from './initial-score-table.component.vue';

@Component({
  components: {
    InitialScoreComponent,
    InitialScoreFilterComponent,
    InitialScoreTable,
  },
})
export default class InitialScore extends Vue {
  hasCreateInitialScore: boolean = false;

  authority!: Authority;

  loading: boolean = false;

  sortBy: Array<string> = ['employeeName'];

  sortDesc: boolean[] = [true];

  direction: string = 'desc';

  sort: string = '';

  pagination: any = {
    itemsPerPage: 10,
    page: 1,
    sortBy: this.sortBy,
    sortDesc: this.sortDesc,
    total: 0,
    pageCount: 0,
  }

  initialScores: InitialScoreFiltered[] = [];

  filter: InitialScoreFilter = new InitialScoreFilter();

  created() {
    this.getInitialScores();
  }

  getInitialScores() {
    this.loading = true;
    InitialScoreService.filter(
      this.filter,
      this.pagination.page,
      this.pagination.itemsPerPage,
      this.orderBy,
    ).then(({ data }) => {
      this.initialScores = data.content.map(it => new InitialScoreFiltered(it));
      this.pagination.total = data.totalElements;
      this.pagination.pageCount = data.totalPages;
    })
      .catch((error) => {
        VbSnackbarService.handleHttpError(error);
      })
      .finally(() => this.loading = false);
  }

  get orderBy() {
    return this.pagination.sortBy.length !== 0 ? this.formatOrderBy() : '';
  }

  formatOrderBy() {
    const orderByFormated: string[] = [];

    this.pagination.sortBy.forEach((field, index) => {
      orderByFormated.push(`${field}|${this.pagination.sortDesc[index] ? 'desc' : 'asc'}`)
    });
    return orderByFormated.toString();
  }

  sendFilter(filter: InitialScoreFilter) {
    this.filter = filter;
    this.getInitialScores();
  }

  resetPagination() {
    this.pagination = {
      itemsPerPage: 10,
      page: 1,
      sortBy: this.sortBy,
      sortDesc: this.sortDesc,
      total: 0,
      pageCount: 0,
    }
  }

  newInitialScore() {
    this.$router.push('/initial-score/new');
  }

  setFilterValues(value: any) {
    if (this.pagination.itemsPerPage < value.itemsPerPage) {
      value.page = 1;
    }
    this.pagination = value;
    this.getInitialScores();
  }
}
