































import { Vue, Component, Prop } from 'vue-property-decorator';
import VacationPeriod from '@/domain/vacation/entity/vacation-period.entity';
import DateFormat from '@/shared/utils/date-format';
import VacationEmployeeListComponent from '@/views/employees/profile/tab-components/vacation-payslip/vacation-employee-list.component.vue';
import headers from './vacation-period-list-header.json';

@Component({ components: { VacationEmployeeListComponent } })
export default class VacationPeriodList extends Vue {
  @Prop({
    type: Boolean,
    default: false,
  })
  loading!: boolean;

  @Prop({
    type: Array,
    required: true,
  })
  periods!: VacationPeriod[];

  headers: any = headers;

  formatDate(date: string): string {
    return DateFormat.dateTimeFormated(date);
  }
}
