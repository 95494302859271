






































import LabelSlot from '@/shared/slots/label-slot.component.vue';
import CardTitle from '@/shared/card/card-title.component.vue';
import { Vue, Component, Prop } from 'vue-property-decorator';
import CheckboxComponent from '@/shared/checkbox/checkbox.component.vue';
import FilterScore from '@/domain/drd/entity/filter-score.entity'

@Component({
  components: {
    LabelSlot,
    CardTitle,
    CheckboxComponent,
  },
})
export default class ScoreHeaderComponent extends Vue {
  @Prop() filter!: FilterScore;

  sendValue(event: string, $event: boolean) {
    this.$emit(`update:${event}`, $event)
  }
}
