export default class OrderBy {
  description?: string;

  field: string;

  descending: boolean;

  constructor(obj: any) {
    this.field = obj.field || 'name';
    this.descending = obj.descending || false;
  }

  public static of(value: any): OrderBy {
    return new OrderBy(value || {});
  }
}
