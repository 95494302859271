


































































import {
  Vue, Component, Prop, Emit,
} from 'vue-property-decorator';

@Component
export default class ScoreModalWarning extends Vue {
  @Prop({
    required: true,
    type: Boolean,
  }) showScoreModalWarning!: boolean;

  @Prop({
    type: String,
    required: true,
  }) modalMessage!: string;

  @Prop({
    type: Boolean,
    default: false,
  }) aware!: boolean;

  @Prop({
    type: Boolean,
    default: false,
  }) loadingSaveButton!: boolean

  save() {
    this.$emit('save');
  }

  close() {
    this.$emit('close');
  }
}
