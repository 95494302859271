






























import { Vue, Component } from 'vue-property-decorator';
import { VbSnackbarService } from 'bag-of-holding-library';
import VueToExcel from 'vue-json-excel';
import SelectiveProcessDetailsReport from '@/domain/selective-process/entity/selective-process-details-report.entity';
import SelectiveProcessService from '@/domain/selective-process/service/selective-process.service';

@Component({
  components: {
    VueToExcel,
  },
})
export default class ExportSelectiveProcessDetailsButton extends Vue {
  showTooltip: boolean = true;

  loadingExportSelectiveProcessButton: boolean = false;

  exportSelectiveProcessProgress: number = 0;

  selectiveProcessDetailsReport: SelectiveProcessDetailsReport[] = [];

  paginationDetailsExport: any = {
    itemsPerPage: 2000,
    page: 1,
    total: 0,
    pageCount: 0,
  }

  exportSelectiveProcessDetailsFields: any = {
    candidato: 'name',
    email: 'email',
    cpf: 'cpf',
    raciocinio: 'mentalGrade',
    social: 'socialGrade',
    motivacional: 'motivationalGrade',
    cultural: 'cultureGrade',
    'Data resp. raciocinio': 'mentalGradeDateFormated',
    'Data resp. fit cultural': 'cultureGradeDateFormated',
    'Locus of Control %': 'locusOfControlGrade',
    'Be Fair do Right Don`t Hide %': 'beFairGrade',
  }

  async exportDetails() {
    // this.loadingExportSelectiveProcessButton = true;
    // await this.getDetailsData();
    // this.resetPaginationDetailsExport();
    // this.loadingExportSelectiveProcessButton = false;
    // return this.selectiveProcessDetailsReport;
  }

  async getDetailsData() {
    this.exportSelectiveProcessProgress = 0;
    this.selectiveProcessDetailsReport = [];
    do {
      // eslint-disable-next-line no-await-in-loop
      await SelectiveProcessService.getDetailsReport(
        this.paginationDetailsExport.page,
        this.paginationDetailsExport.itemsPerPage,
      ).then((response) => {
        response.data.content.forEach((it) => {
          this.selectiveProcessDetailsReport.push(SelectiveProcessDetailsReport.of(it))
        });
        this.paginationDetailsExport.total = response.data.totalElements
        this.paginationDetailsExport.page += 1
      }).catch((error) => {
        this.loadingExportSelectiveProcessButton = false;
        this.resetPaginationDetailsExport();
        VbSnackbarService.handleHttpError(error);
      })
      this.exportSelectiveProcessProgress = (
        this.selectiveProcessDetailsReport.length / this.paginationDetailsExport.total
      ) * 100;
    } while (this.selectiveProcessDetailsReport.length < this.paginationDetailsExport.total)
  }

  resetPaginationDetailsExport() {
    this.paginationDetailsExport.total = 0;
    this.paginationDetailsExport.page = 1;
    this.paginationDetailsExport.pageCount = 1;
  }

  get exportSelectiveProcessButtonText() {
    return this.loadingExportSelectiveProcessButton ? 'Processando...' : 'Exportar Dados'
  }
}
