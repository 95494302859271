


































import { Vue, Component } from 'vue-property-decorator';
import CardTitle from '@/shared/card/card-title.component.vue';
import RiskAttendance from '@/domain/risk-attendance/entity/risk-attendance.entity';
import RiskAttendanceService from '@/domain/risk-attendance/service/risk-attendance.service';
import { VbSnackbarService } from 'bag-of-holding-library';
import EmployeeSummary from '@/domain/schedule/employee/employee-summary.entity';
import Filter from '@/domain/risk-attendance/entity/filter.entity';
import UpdateAtribute from '@/domain/employees/entity/update-atribute.entity';
import Authority from '@/domain/security/authority.entity';
import AuthorityStore from '@/domain/security/authority.store';
import HeaderComponent from './new-risk-attendance.component.vue'
import FormBodyComponent from '../../form-risk-attendance/form-body-component.vue';


@Component({
  components: {
    CardTitle,
    HeaderComponent,
    FormBodyComponent,
  },
})
export default class RiskAttendances extends Vue {
  riskAttendance: RiskAttendance = RiskAttendance.of({});

  filterEmployees: EmployeeSummary[] = [];

  businessUnit: UpdateAtribute[] = [];

  lastFilterManager: string = '';

  lastFilterEmployee: string = '';

  tableLoading: boolean = false;

  isRegularAttendance: boolean = true;

  isFollowUpInterview: boolean = false;

  lastFilter: Filter = new Filter({});

  filter: Filter = Filter.of({});

  managers: EmployeeSummary[] = [];

  typeDescription: string = ''

  status: string = '';

  loading: boolean = false;

  valid: boolean = false;

  hasCreateRiskAttendance: boolean = false;

  hasUpdateRiskAttendance: boolean = false;

  authority!: Authority;

  async created() {
    await this.validateAuthorities();
  }

  async validateAuthorities() {
    await this.getAuthority();
    this.hasCreateRiskAttendance = this.authority.hasCreateRiskAttendance();
    this.hasUpdateRiskAttendance = this.authority.hasUpdateRiskAttendance();
  }

  async getAuthority() {
    await AuthorityStore.fetchedAuthority().then(authority => this.authority = authority);
  }

  close() {
    this.$router.push({
      path: '/risk-attendance',
    })
  }

  async save() {
    this.loading = true;
    const request = RiskAttendance.of(this.riskAttendance);

    try {
      await RiskAttendanceService.newRiskAttendance(request);
      VbSnackbarService.showSuccess('Dados salvos com sucesso!');
    } catch (err) {
      VbSnackbarService.handleHttpError(err);
    } finally {
      this.loading = false;
      this.close();
    }
  }

  setValid(value: boolean): void {
    this.valid = value;
  }
}

