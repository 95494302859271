import RiskAttendance from './risk-attendance.entity';


export default class Response {
  totalElements: number;

  totalPages: number;

  content: RiskAttendance[];

  constructor(obj: any) {
    this.totalElements = obj.totalElements || '';
    this.totalPages = obj.totalPages || '';
    this.content = obj.content || '';
  }

  public static of(value: any): Response {
    return new Response(value || {});
  }
}
