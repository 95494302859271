




















































































































































































































































































import {
  Vue, Component, Prop,
} from 'vue-property-decorator';
import DatePickerField from '@/shared/date-picker/date-picker-field.component.vue'
import CardTitle from '@/shared/card/card-title.component.vue';
import LabelSlot from '@/shared/slots/label-slot.component.vue';
import splitContent from '@/shared/directive/split-content';
import DateHandler from '@/shared/utils/date-format';
import ModalWarning from '@/shared/modal/modal-warning.component.vue';
import Agreement from '@/domain/employees/entity/agreement.entity';
import Rules from '@/shared/rules/rules';
import UpdateAtribute from '@/domain/employees/entity/update-atribute.entity';
import TooltipIcon from '@/shared/tooltip-icon.component.vue';
import CurrencyField from '@/shared/currency-field.component.vue'
import { Currency } from '@/domain/employees/entity/professional-data.entity';
import Assignment from '@/domain/drd/entity/assignment.entity';
import AssignmentRole from '@/domain/drd/entity/assignment.role.entity';
import { FixedAgreement, CurrencyType } from './score-utils';
import { ContractType } from './contract-type.type';

@Component({
  components: {
    CurrencyField,
    LabelSlot,
    DatePickerField,
    CardTitle,
    ModalWarning,
    TooltipIcon,
  },
  filters: {
    splitContent,
    formatDate(value: string) {
      return DateHandler.timestampToLocal(value.split('T')[0]);
    },
  },
})
export default class AgreementListComponent extends Vue {
  @Prop() agreementList!: Agreement[];

  @Prop({
    required: false,
  })
  roles?: UpdateAtribute[];

  @Prop() isUnscheduledScore!: boolean;

  @Prop() showPreviousScore!: boolean;

  @Prop() currency!: Currency;

  @Prop() seniorities!: [];

  @Prop({
    type: Boolean,
  }) editPrevious!: boolean;

  rules: Rules = new Rules();

  @Prop() minimalReadjustment!: number;

  @Prop({
    required: false,
  }) assignments!: Assignment[];

  @Prop({ type: Number, default: 0 })
  currentBusinessUnitCode!: number

  @Prop({
    type: Boolean,
    default: false,
  }) disableAllFields!: boolean;

  @Prop() contractType!: ContractType;

  @Prop({
    type: Boolean,
    default: false,
  }) isFixed!: boolean;

  @Prop({
    type: Boolean,
    default: false,
  }) unlockBenefitPercent!: boolean;

  FixedAgreementEnum = FixedAgreement;

  CurrencyType = CurrencyType;

  educationSubsidyRulesMessage: string = 'Caso não tenha um valor informe o valor 0,00.';

  getDescriptionMessage(description: string): string {
    if (description === this.FixedAgreementEnum.DUCZ) {
      return 'Alimentação/Flex: Valores creditados no Ducz para utilização exclusiva em estabelecimentos de '
      + 'alimentação e demais categorias do Ducz. O valor fixo para alimentação será conforme a '
       + 'tabela salarial e acordo individual. A soma de todos os benefícios não pode ultrapassar 35% do CLT '
       + '(regra válida apenas para moeda BRL Real Brasileiro)'
    }
    if (description === this.FixedAgreementEnum.DUCZ_SYNDICATE_FREE) {
      return 'Sintinorp/Livre: Valores creditados no Ducz para utilização em quaisquer estabelecimentos, '
      + 'sendo possível utilização de Pix. Deste valor, serão descontados do colaborador R$6,00 e repassados ao sindicato. '
      + 'Valor mínimo R$25,00 e Máximo R$3.000, sendo que a soma de todos os benefícios não pode ultrapassar 35% do CLT (regra válida apenas para moeda BRL Real Brasileiro)'
    }
    if (description === this.FixedAgreementEnum.EDUCATION_SUBSIDY_ASSISTANCE) {
      return 'Subsídio/Auxílio Educação: Valores creditados no Ducz ou pagos para '
      + 'instituição de ensino com finalidade de estudo. A soma de todos os benefícios não pode ultrapassar 35% do CLT. (regra válida apenas para moeda BRL Real Brasileiro)'
    }
    return '';
  }

  showDescriptionMessage(description): boolean {
    const showDescriptionMessageTypes: string[] = [
      this.FixedAgreementEnum.DUCZ,
      this.FixedAgreementEnum.DUCZ_SYNDICATE_FREE,
      this.FixedAgreementEnum.EDUCATION_SUBSIDY_ASSISTANCE,
    ]
    return showDescriptionMessageTypes.includes(description)
  }

  exclusionFlag(description): boolean {
    const noExclusionList: string[] = [
      this.FixedAgreementEnum.ASSIGNMENT,
      this.FixedAgreementEnum.ROLE,
      this.FixedAgreementEnum.SENIORITY,
      this.FixedAgreementEnum.SALARY_IN_CLT,
      this.FixedAgreementEnum.DUCZ,
      this.FixedAgreementEnum.DUCZ_SYNDICATE_FREE,
      this.FixedAgreementEnum.EDUCATION_SUBSIDY_ASSISTANCE,
      this.FixedAgreementEnum.TOTAL,
    ]
    return noExclusionList.includes(description)
  }

  disableValidUntil(description: string): boolean {
    const exclusionList: string[] = [
      this.FixedAgreementEnum.ASSIGNMENT,
      this.FixedAgreementEnum.ROLE,
      this.FixedAgreementEnum.SENIORITY,
      this.FixedAgreementEnum.SALARY_IN_CLT,
      this.FixedAgreementEnum.DUCZ,
      this.FixedAgreementEnum.DUCZ_SYNDICATE_FREE,
      this.FixedAgreementEnum.TOTAL,
    ]
    return exclusionList.includes(description)
  }

  getRule(item: Agreement): any[] {
    if (item.previousAgreement === 'NOT_EXISTS') {
      return [];
    }
    if (item.description === this.FixedAgreementEnum.DUCZ) {
      return [];
    }
    if (item.description === this.FixedAgreementEnum.TOTAL) {
      return [];
    }
    if (this.isUnscheduledScore) {
      return [];
    }
    if (this.currency.name === this.CurrencyType.BRL
      && item.description === this.FixedAgreementEnum.SALARY_IN_CLT) {
      return [(value: string) => (!!value && parseFloat(value) >= this.minimalReadjustment) || `Reajuste deve ser maior que ${this.minimalReadjustment}%.`];
    }
    return [];
  }

  getRuleNewAgreementDucz(item: Agreement): any[] {
    if (this.currency.name !== this.CurrencyType.BRL) return this.rules.emptyMoney;
    if (item.description === this.FixedAgreementEnum.DUCZ) {
      return this.getRuleOfDucz(item);
    }
    if (
      item.description === this.FixedAgreementEnum.DUCZ_SYNDICATE_FREE
    ) {
      return this.getRuleNewAgreementDuczSyndicate(item);
    }
    if (item.description === this.FixedAgreementEnum.EDUCATION_SUBSIDY_ASSISTANCE) {
      return this.educationSubsidyAssistanceRules(item)
    }
    return this.rules.emptyMoney;
  }

  getRuleOfDucz(item: Agreement) {
    if (!this.contractType.applyRuleDuczFlex) return this.rules.emptyMoney;

    const seniorityAgreement = this.filterAgreementByType(this.FixedAgreementEnum.SENIORITY);
    if (seniorityAgreement.newAgreement === 'Trainee'
      || seniorityAgreement.newAgreement === 'Estágio') return this.rules.emptyMoney;

    if (this.isEmployeeOfLughy && parseFloat(item.newAgreement) < 200) {
      return ['Valor mínimo R$ 200,00'];
    }
    if (!this.isEmployeeOfLughy && parseFloat(item.newAgreement) < 500) {
      return ['Valor mínimo R$ 500,00'];
    }
    return this.rules.emptyMoney;
  }

  getRuleNewAgreementDuczSyndicate(item: Agreement): any[] {
    if (this.isEmployeeOfLughy) return this.rules.emptyMoney;
    const newAgreementParsed = parseFloat(item.newAgreement);
    if (newAgreementParsed > 3000 && !this.unlockBenefitPercent) {
      return ['Valor máximo R$ 3.000,00'];
    }
    if (newAgreementParsed < 25) {
      return ['Valor mínimo R$ 25,00'];
    }
    return this.checkPercentageSyndicateAndEducationSubsidyValue(newAgreementParsed);
  }

  checkPercentageSyndicateAndEducationSubsidyValue(newAgreement: number): any[] {
    if (this.unlockBenefitPercent) return this.rules.emptyMoney;
    const salaryInCLT = this.agreementList
      .filter((el: Agreement) => el.description === FixedAgreement.SALARY_IN_CLT)[0];
    const educationSubsidy = this.agreementList
      .filter((el: Agreement) => el.description === FixedAgreement.EDUCATION_SUBSIDY_ASSISTANCE)[0];
    const ducz = this.agreementList
      .filter((el: Agreement) => el.description === FixedAgreement.DUCZ)[0];
    const sumSyndicateAndEducation = newAgreement
      + parseFloat(educationSubsidy.newAgreement)
      + parseFloat(ducz.newAgreement);
    if (sumSyndicateAndEducation > (parseFloat(salaryInCLT.newAgreement)) * 0.35) {
      return ['Valor Ducz (Alimentação/Flex) + Ducz Sintinorp + Subsídio/Auxílio para Educação não pode ser maior que 35% do valor do salário base.'];
    }
    return this.rules.emptyMoney;
  }

  getRulePreviousDuczSyndicate(item: Agreement): any[] {
    if (item.description !== this.FixedAgreementEnum.DUCZ_SYNDICATE_FREE) return [];
    if (parseFloat(item.previousAgreement) < 25) {
      return ['Valor mínimo R$ 25,00'];
    }
    return this.rules.emptyMoney;
  }

  applyDate(item: Agreement, value: string) {
    item.validUntil = value;
  }

  clearDate(item: Agreement) {
    item.validUntil = '';
  }

  removeAgreement(item: Agreement) {
    this.$emit('removeAgreement', item)
  }

  updateTotal() {
    this.$emit('updateTotal')
  }

  onRoleChange(role: AssignmentRole) {
    if (this.roles === undefined) return
    if (role.id === undefined) return
    this.setNewAgreementRole(role.name);
    this.$emit('newRoleChange', role.id);
  }

  setNewAgreementRole(roleName: string) {
    this.agreementList.forEach((it) => {
      if (it.isRole()) {
        it.newAgreement = roleName;
      }
    })
  }

  onAssignmentsChange(assignmentDescription: string) {
    if (this.assignments === undefined) return;
    const assignmentFilter = this.assignments
      .filter(assignment => assignment.description === assignmentDescription)[0];
    this.onRoleChange(assignmentFilter.role!);
  }

  isTotal(agreement): boolean {
    return agreement.description === this.FixedAgreementEnum.TOTAL
  }

  assignmentsInactive(assignment: Assignment): boolean {
    return assignment.inactive;
  }

  currencyInputErrorMessages(isTotal: boolean): string[] {
    return this.currency.name === '' && !isTotal ? ['Informe uma moeda antes de informar um valor'] : [];
  }

  currencyFieldHintMessage(agreement: Agreement) {
    if ((this.isEmployeeOfLughy
      && agreement.description === this.FixedAgreementEnum.DUCZ_SYNDICATE_FREE)
      || agreement.description === this.FixedAgreementEnum.EDUCATION_SUBSIDY_ASSISTANCE) {
      return this.educationSubsidyRulesMessage;
    }
    return '';
  }

  educationSubsidyAssistanceRules(agreement: Agreement) {
    return agreement.newAgreement ? [] : [this.educationSubsidyRulesMessage];
  }

  get isEmployeeOfLughy() {
    return this.currentBusinessUnitCode === 61;
  }

  filterAgreementByType(type: string) {
    return this.agreementList.filter(agreement => agreement.description === type)[0]
  }
}
