



















import { Vue, Component } from 'vue-property-decorator';
import { VbSnackbarService } from 'bag-of-holding-library';
import SkillFilter from '@/domain/drd-2.0/entity/skill-filter.entity';
import Skill from '@/domain/drd-2.0/entity/skill.entity';
import SkillService from '@/domain/drd-2.0/service/skill.service';
import CreateSkillButton from './components/create-skill-button.component.vue';
import SkillFilterComponent from './components/filter.component.vue';
import SkillTableComponent from './components/table.component.vue';

@Component({
  components: {
    CreateSkillButton,
    SkillFilterComponent,
    SkillTableComponent,
  },
})
export default class SkillView extends Vue {
  filter: SkillFilter = new SkillFilter();

  sortBy: Array<string> = [];

  sortDesc: boolean[] = [false];

  pagination: any = {
    itemsPerPage: 10,
    page: 1,
    sortBy: this.sortBy,
    sortDesc: this.sortDesc,
    total: 0,
    pageCount: 0,
  }

  skills: Skill[] = [];

  loading: boolean = false;

  created() {
    this.findSkills();
  }

  sendFilter(filter: SkillFilter) {
    this.filter = filter;
    this.findSkills();
  }

  updatePagination(value: any) {
    if (this.pagination.itemsPerPage < value.itemsPerPage) {
      this.pagination.page = 1;
    }
    this.pagination = value;
    this.findSkills();
  }

  findSkills() {
    this.loading = true;
    SkillService.search(
      this.filter,
      this.pagination.page,
      this.pagination.itemsPerPage,
    ).then((response) => {
      this.skills = response.data.content.map(it => new Skill(it));
      this.pagination.total = response.data.totalElements || 0;
      this.pagination.pageCount = response.data.totalPages;
    }).catch((error) => {
      VbSnackbarService.showError(error.response.data.messages[0]);
    }).finally(() => this.loading = false);
  }
}
