





import { Vue, Component } from 'vue-property-decorator';

@Component({ name: 'AttendanceView', components: { } })
export default class Attendance extends Vue {
}
