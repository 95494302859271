

































































































import { Vue, Component, Prop } from 'vue-property-decorator';
import LabelSlot from '@/shared/slots/label-slot.component.vue';
import CareerUpdate from '@/domain/employees/entity/career-update.entity';
import CopyComponent from '@/shared/copy.component.vue';

@Component({ components: { LabelSlot, CopyComponent } })
export default class CareerUpdateListComponent extends Vue {
  @Prop() update!: CareerUpdate;
}
