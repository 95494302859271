































































































import { Vue, Component, Prop } from 'vue-property-decorator';
import { VbSnackbarService } from 'bag-of-holding-library';
import AssessmentResultDetails, { AssessmentResultSkillType } from '@/domain/assessment/entity/emp-assessment-result-details';
import FormPlanModalComponent from '@/views/activity/common/form-plan-modal.component.vue';
import PdiService from '@/domain/pdi/pdi.service';
import Priority from '@/domain/activity/entity/priority.entity';
import ActionPlan from '@/domain/activity/entity/action-plan.entity';
import AssessmentResultDetailsHeader from './assessment-result-details-header.json'

@Component({
  components: {
    FormPlanModalComponent,
  },
})
export default class AssessmentResultDetailsTableComponent extends Vue {
  @Prop() resultDetails!: AssessmentResultDetails[];

  @Prop({
    type: Boolean,
    default: false,
  }) loading!: boolean;

  @Prop({
    type: String,
    required: true,
  }) employeeUsername!: string;

  @Prop({
    type: Boolean,
    default: false,
  }) isCurrent!: boolean;

  @Prop({
    type: Boolean,
    default: false,
  }) simulation!: boolean;

  @Prop({
    type: String,
    required: true,
  }) languageAcronym!: string;

  resultDetailsHeader = AssessmentResultDetailsHeader;

  openPlanModal: boolean = false;

  priorityItems: Priority[] = [];

  itemSelected: AssessmentResultDetails = new AssessmentResultDetails();

  loadingPdi: boolean = false;

  created() {
    this.getAllPriority();
  }

  getResultColorClass(isAdherence: boolean) {
    return isAdherence ? 'is-adherence' : 'not-adherence'
  }

  getItemTypeStyle(type: AssessmentResultSkillType) {
    return {
      background: type.color,
      'border-radius': '50px',
      color: 'white',
    }
  }

  openPDImodal(itemSelected: AssessmentResultDetails) {
    this.itemSelected = itemSelected;
    this.openPlanModal = true;
  }

  closePdiModal() {
    this.openPlanModal = false;
  }

  getAllPriority() {
    PdiService.getAllPriority().then(response => this.priorityItems = response);
  }

  createPlan(plan: ActionPlan) {
    this.loadingPdi = true;
    PdiService.create(plan, this.employeeUsername)
      .then(({ data }) => {
        this.itemSelected.formPlanId = data.planId;
        VbSnackbarService.showSuccess('Plano criado com sucesso!')
      })
      .catch(error => VbSnackbarService.handleHttpError(error))
      .finally(() => {
        this.loadingPdi = false;
        this.closePdiModal();
      })
  }

  updatePlan(plan: ActionPlan) {
    this.loadingPdi = true;
    PdiService.update(plan, this.employeeUsername)
      .then(() => {
        VbSnackbarService.showSuccess('Plano atualizado com sucesso!');
        this.openPlanModal = false;
      })
      .catch((error) => {
        VbSnackbarService.handleHttpError(error)
      }).finally(() => this.loadingPdi = false)
  }

  updatePlanField(eventData: any) {
    this.itemSelected.pdiPlan[eventData.field] = eventData.value;
  }

  redirectToProfile() {
    this.$router.push(`/profile/${this.employeeUsername}`);
  }

  get languageAcronymFieldFormat() {
    return this.languageAcronym.replace('_', '');
  }
}
