import axios from 'axios';
import State from '../entity/state.entity';
import City from '../entity/city.entity';

export default class StateService {
  public static findStates(): Promise<State[]> {
    return axios.get('/api/states')
      .then((response: any) => State.ofList(response.data));
  }

  public static findCities(id: string): Promise<City[]> {
    return axios.get(`/api/states/${id}/cities`)
      .then((response: any) => City.ofList(response.data));
  }
}
