




































































import {
  Vue, Prop, Component, Emit,
} from 'vue-property-decorator';
import { VbSnackbarService } from 'bag-of-holding-library';
import TextArea from '@/shared/fields/textarea.component.vue';
import MonthYearSelect from '@/shared/month-year-picker/month-year-select.component.vue';
import LabelSlot from '@/shared/slots/label-slot.component.vue';
import EmployeeDetailed from '@/domain/schedule/employee/employee-detailed.entity';
import ActivitySummary from '@/domain/schedule/activitie/activity-summary.entity';
import ReadonlyTextField from '@/shared/fields/readonly-text-field.component.vue';
import EmployeeService from '@/domain/schedule/employee/employee.service';
import ActivityReassign from '@/domain/schedule/activitie/activity-reassign.entity';
import DateFormat from '@/shared/utils/date-format';
import FieldSelectActivity from './field-select-activity.component.vue';

@Component({
  components: {
    'readonly-text-field': ReadonlyTextField,
    'field-select-activity': FieldSelectActivity,
    'text-area': TextArea,
    'month-year-select': MonthYearSelect,
    'label-slot': LabelSlot,
  },
})
export default class FormReassignActivity extends Vue {
  @Prop({
    required: true,
  })
  private employeeId!: string;

  private activitySelected: ActivitySummary = new ActivitySummary();

  private requiredRules: any = [(v: any) => !!v || 'Required'];

  private employee: EmployeeDetailed = new EmployeeDetailed();

  private newActivity: ActivityReassign = ActivityReassign.of({});

  private get previousDate(): any {
    if (this.activitySelected.expectedDate) {
      return DateFormat.monthNumberYear(this.activitySelected.expectedDate);
    }
    return '';
  }

  @Emit('updateActivityId')
  private updateActivityId(activity: ActivitySummary): string {
    this.activitySelected = activity;
    return activity.id;
  }

  private get isActivitySelected(): boolean {
    return Object.keys(this.activitySelected).length > 0;
  }

  @Emit('updateActivityToReassign')
  private updateActivityToReassign(): ActivityReassign {
    return this.newActivity;
  }

  private fetchEmployeeActivities(): void {
    EmployeeService.getEmployeeActivities(this.employeeId)
      .then((response: any) => this.employee = response)
      .catch(error => VbSnackbarService.handleHttpError(error));
  }

  private mounted(): void {
    this.fetchEmployeeActivities();
  }
}
