export default class CorreiosAddress {
  cep: string;

  logradouro: string;

  complemento: string;

  bairro: string;

  localidade: string;

  uf: string;

  constructor(obj: any) {
    this.cep = obj.cep || '';
    this.logradouro = obj.logradouro || '';
    this.complemento = obj.complemento || '';
    this.bairro = obj.bairro || '';
    this.localidade = obj.localidade || '';
    this.uf = obj.uf || '';
  }

  public static of(obj: any): CorreiosAddress {
    return new CorreiosAddress(obj || {})
  }

  public static ofList(obj: any[]): CorreiosAddress[] {
    return obj.map(this.of);
  }
}
