import {
  Vue, Prop, Component, Emit,
} from 'vue-property-decorator';

@Component
export default class ModalMixin extends Vue {
  @Prop({
    required: true,
  })
  public value!: boolean;

  @Prop({
    required: true,
  })
  public employeeId!: string;

  @Emit('closeModal')
  public closeModal(): void {}
}
