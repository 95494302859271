

























































































































































































































































































































































































































import DateFormat from 'bag-of-holding-library/src/utils/date-format';
import { Vue, Component } from 'vue-property-decorator'

@Component
export default class PdfContentComponent extends Vue {
  textColor!: string;

  labelsBackgroundColor!: string;

  labelsTextColor!: string;

  employeeName!: string;

  salary!: Number;

  startDate!: string;

  roleName!: string;

  workJourne!: string;

  businessUnitName!: string;

  ducz!: Number;

  educationSubsidy!: number;

  titleColor!: string;

  subtitleColor!: string;

  subtitleLineColor!: string;

  contractType!: string;

  logoWidth!: string;

  logoHeight!: string;

  currencySymbol!: string;

  contractTypes = {
    CLT: 'CLT',
    PJ: 'PJ',
    INTERNSHIP: 'Estágio',
    YOUNG_APPRENTICE: 'Jovem Aprendiz',
  }

  operationModality!: string;

  observation!: string;

  seniority!: string;

  bannerWidth!: string;

  bannerHeight!: string;

  getLogoImage() {
    // eslint-disable-next-line import/no-dynamic-require, global-require
    return require(`@/assets/images/${this.businessUnitName}_LOGO.png`);
  }

  getBannerImage() {
    // eslint-disable-next-line import/no-dynamic-require, global-require
    return require(`@/assets/images/${this.businessUnitName}_BANNER.png`);
  }

  get startDateformated(): string {
    return DateFormat.timestampToLocal(this.startDate);
  }

  created() {
    this.textColor = this.$route.query.textColor.toString();
    this.labelsBackgroundColor = this.$route.query.labelsBackgroundColor.toString();
    this.labelsTextColor = this.$route.query.labelsTextColor.toString();
    this.employeeName = this.$route.query.employeeName.toString();
    this.salary = Number(this.$route.query.salary.toString());
    this.startDate = this.$route.query.startDate.toString();
    this.roleName = this.$route.query.roleName.toString();
    this.workJourne = this.$route.query.workJourne.toString();
    this.contractType = this.$route.query.contractType.toString();
    this.businessUnitName = this.$route.query.businessUnitName.toString();
    this.ducz = Number(this.$route.query.ducz.toString());
    this.educationSubsidy = Number(this.$route.query.educationSubsidy.toString());
    this.titleColor = this.$route.query.titleColor.toString();
    this.subtitleColor = this.$route.query.subtitleColor.toString();
    this.subtitleLineColor = this.$route.query.subtitleLineColor.toString();
    this.logoWidth = this.$route.query.logoWidth.toString();
    this.logoHeight = this.$route.query.logoHeight.toString();
    this.bannerWidth = this.$route.query.bannerWidth.toString();
    this.bannerHeight = this.$route.query.bannerHeight.toString();
    this.currencySymbol = this.$route.query.currencySymbol.toString();
    this.operationModality = this.$route.query.operationModality.toString();
    this.observation = this.$route.query.observation.toString();
    this.seniority = this.$route.query.seniority.toString();
    setTimeout(() => {
      window.print();
    }, 2000);
  }
}
