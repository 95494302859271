






































import { Vue, Prop, Component } from 'vue-property-decorator';
import { VbSnackbarService, VbTooltipSlot } from 'bag-of-holding-library'
import DateFormat from '@/shared/utils/date-format';
import ObservationIcon from '@/shared/observation-icon.component.vue';
import StatusTextColorDirective from '@/shared/directive/status-text-color.directive';
import ActivityService from '@/domain/activity/service/activity.service';

@Component({
  components: {
    VbTooltipSlot,
    'observation-icon': ObservationIcon,
  },
  directives: {
    'status-text-color': StatusTextColorDirective,
  },
})
export default class EmployeeHistoryListItem extends Vue {
  @Prop({
    required: true,
  })
  private listItem!: any;

  @Prop({
    type: Boolean,
  }) private hasActiveOrInactiveActivityHistory!: boolean;

  private monthYearDateFormat(value: string): string {
    return DateFormat.monthYear(value);
  }

  private get dateTimeFormated(): string {
    return DateFormat.dateTimeFormated(this.listItem.date);
  }

  private activeOrInactive() {
    ActivityService.activeOrInactiveActivityHistoryItem(this.listItem.id, this.listItem.active)
      .then(() => {
        VbSnackbarService.showSuccess('Histórico atualizado com sucesso!');
      }).catch((error) => {
        this.listItem.active = !this.listItem.active;
        VbSnackbarService.handleHttpError(error);
      })
  }
}
