import { render, staticRenderFns } from "./menu-header-user.component.vue?vue&type=template&id=32f3cdb4&scoped=true&"
import script from "./menu-header-user.component.vue?vue&type=script&lang=ts&"
export * from "./menu-header-user.component.vue?vue&type=script&lang=ts&"
import style0 from "./menu-header-user.component.vue?vue&type=style&index=0&id=32f3cdb4&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "32f3cdb4",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
installComponents(component, {VAvatar,VBtn,VList,VListItem,VListItemContent,VMenu,VProgressCircular})
