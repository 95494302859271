import EmployeeScheduleItem from './employee-schedule-item.entity';

export default class EmployeeSchedule {
  id: string;

  name: string;

  username: string;

  schedule: EmployeeScheduleItem[];

  private constructor(obj: any) {
    this.id = obj.id;
    this.name = obj.name;
    this.username = obj.username;
    this.schedule = EmployeeScheduleItem.ofList(obj.schedule || []);
  }

  public static of(obj: any): EmployeeSchedule {
    return new EmployeeSchedule(obj || {});
  }

  public static ofList(obj: any): EmployeeSchedule[] {
    return obj.map((value: any) => this.of(value));
  }
}
