import Home from './home.view.vue';

export default [
  {
    path: '/',
    component: Home,
    name: 'Home',
    children: [],
  },
];
