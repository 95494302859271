









































































import MobileHandler from '@/shared/utils/mobile-handler';
import { Vue, Component, Prop } from 'vue-property-decorator';
import { VbSnackbarService } from 'bag-of-holding-library';
import DateFormat from 'bag-of-holding-library/src/utils/date-format';
import PaginationComponent from '@/shared/pagination.component.vue';
import KeyAreaFiltered from '@/domain/drd-2.0/entity/key-area-filtered.entity';
import ModalWarning from '@/shared/modal/modal-warning.component.vue';
import ModalOptions from '@/shared/modal/modal-options.entity';
import KeyAreaService from '@/domain/drd-2.0/service/key-area.service';
import Headers from './header.json';

@Component({
  components: {
    PaginationComponent,
    ModalWarning,
  },
})
export default class KeyAreaTableComponent extends Vue {
  @Prop() items!: KeyAreaFiltered[];

  @Prop({
    type: Boolean,
    default: false,
  }) loading!: boolean;

  @Prop() page!: any;

  isMobile = false;

  headers = Headers;

  openEditSkillModal: boolean = false;

  openModalWarning: boolean = false;

  loadingDelete: boolean = false;

  deleteKeyAreaId: string = '';

  get modalWarningOptions(): ModalOptions {
    return {
      title: 'Atenção',
      description: 'Essa ação irá deletar todos os dados e não será possível recupera-los. Deseja mesmo continuar?',
      showOk: true,
      disabledOk: false,
      labelOk: 'Sim, continuar',
      loadingOk: this.loadingDelete,
      okColor: 'tabBlue',
      showCancel: !this.loadingDelete,
      labelCancel: 'Cancelar',

      width: 400,
    };
  }

  updateTable(pagination: any): void {
    this.$emit('update', pagination);
  }

  onResize() {
    this.isMobile = MobileHandler.isMobile();
  }

  formatDate(value: string) {
    return DateFormat.timestampToLocal(value.split('T')[0]);
  }

  updateKeyArea(id: string) {
    this.$router.push(`/keyarea/${id}`);
  }

  openWarningModal(id: string) {
    this.deleteKeyAreaId = id;
    this.openModalWarning = true;
  }

  deleteKeyArea() {
    this.loadingDelete = true;
    KeyAreaService.disable(this.deleteKeyAreaId)
      .then(() => {
        VbSnackbarService.showSuccess('Area Chave deletada com sucesso!');
        this.updateTable(this.page);
      })
      .catch((error) => {
        VbSnackbarService.showError(error.response.data.messages.toString());
      }).finally(() => {
        this.loadingDelete = false;
        this.openModalWarning = false;
        this.deleteKeyAreaId = '';
      });
  }
}
