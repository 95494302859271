import { render, staticRenderFns } from "./candidate-application-table.component.vue?vue&type=template&id=40dc8e12&scoped=true&"
import script from "./candidate-application-table.component.vue?vue&type=script&lang=ts&"
export * from "./candidate-application-table.component.vue?vue&type=script&lang=ts&"
import style0 from "./candidate-application-table.component.vue?vue&type=style&index=0&id=40dc8e12&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "40dc8e12",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VDataTable } from 'vuetify/lib/components/VDataTable';
installComponents(component, {VDataTable})


/* vuetify-loader */
import installDirectives from "!../../../../node_modules/vuetify-loader/lib/runtime/installDirectives.js"
import Resize from 'vuetify/lib/directives/resize'
installDirectives(component, {Resize})
