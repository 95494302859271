export default class ResignationSummary {
  id: string;

  employeeName: string;

  shutdownTime: string;

  manager: string;

  businessUnit: string;

  constructor(obj: any = {}) {
    this.id = obj.id || '';
    this.employeeName = obj.employeeName || '';
    this.shutdownTime = obj.shutdownTime || '';
    this.manager = obj.manager || '';
    this.businessUnit = obj.businessUnit || '';
  }
}
