





















































import { Vue, Component } from 'vue-property-decorator';
import CardTitle from '@/shared/card/card-title.component.vue';
import RiskAttendance from '@/domain/risk-attendance/entity/risk-attendance.entity';
import RiskAttendanceService from '@/domain/risk-attendance/service/risk-attendance.service';
import { VbSnackbarService } from 'bag-of-holding-library';
import ModalWarning from '@/shared/modal/modal-warning.component.vue';
import Authority from '@/domain/security/authority.entity';
import AuthorityStore from '@/domain/security/authority.store';
import EmployeeSummary from '../../../domain/schedule/employee/employee-summary.entity';
import Filter from '../../../domain/risk-attendance/entity/filter.entity';
import UpdateAtribute from '../../../domain/employees/entity/update-atribute.entity';
import FormComponent from './form-component.vue';
import FormBodyComponent from './form-body-component.vue';


@Component({
  components: {
    CardTitle,
    FormComponent,
    FormBodyComponent,
    ModalWarning,
  },
})
export default class RiskAttendances extends Vue {
  riskAttendance: RiskAttendance = RiskAttendance.of({});

  filterEmployees: EmployeeSummary[] = [];

  businessUnit: UpdateAtribute[] = [];

  lastFilterManager: string = '';

  lastFilterEmployee: string = '';

  tableLoading: boolean = false;

  isRegularAttendance: boolean = true;

  isFollowUpInterview: boolean = false;

  lastFilter: Filter = new Filter({});

  filter: Filter = Filter.of({});

  managers: EmployeeSummary[] = [];

  typeDescription: string = ''

  status: string = '';

  warningModal: boolean = false;

  loading: boolean = false;

  valid: boolean = false;

  hasDeleteRiskAttendance: boolean = false;

  hasUpdateRiskAttendance: boolean = false;

  hasCreateRiskAttendance: boolean = false;

  authority!: Authority;

  loadingDelete: boolean = false;

  created() {
    this.getDate();
    this.verifyAuthorities();
  }

  getDate() {
    if (this.$route.params.id) {
      RiskAttendanceService.getRiskAttendance(this.$route.params.id)
        .then((response: RiskAttendance) => {
          this.riskAttendance = response;
        });
    }
  }

  async verifyAuthorities() {
    await this.getAuthority();
    this.hasCreateRiskAttendance = this.authority.hasCreateRiskAttendance();
    this.hasUpdateRiskAttendance = this.authority.hasUpdateRiskAttendance();
    this.hasDeleteRiskAttendance = this.authority.hasDeleteRiskAttendance();
  }

  async getAuthority() {
    await AuthorityStore.fetchedAuthority().then(authority => this.authority = authority);
  }

  close() {
    this.$router.push({
      path: '/risk-attendance',
    })
  }

  setValid(value: boolean): void {
    this.valid = value;
  }

  closeWarningModal(): void {
    this.warningModal = false;
  }

  openWarningModal(): void {
    this.warningModal = true;
  }

  deleteRiskAttendance() {
    this.closeWarningModal();
    this.loadingDelete = true;

    RiskAttendanceService.deleteRiskAttendance(this.riskAttendance.id)
      .then(() => {
        VbSnackbarService.showSuccess('Risco excluído com sucesso!');
      })
      .catch((err: any) => {
        VbSnackbarService.handleHttpError(err);
      })
      .finally(() => {
        this.loadingDelete = false;
        this.close();
      });
  }

  async save() {
    this.loading = true;
    const request = RiskAttendance.of(this.riskAttendance);
    try {
      await RiskAttendanceService.saveRiskAttendance(this.riskAttendance.id, request);
      VbSnackbarService.showSuccess('Dados salvos com sucesso!');
    } catch (err) {
      VbSnackbarService.handleHttpError(err);
    } finally {
      this.loading = false;
    }
  }
}

