






































import {
  Vue, Component, Prop,
} from 'vue-property-decorator';
import { VbDirectives, VbTooltipSlot } from 'bag-of-holding-library';
import PaginationComponent from '@/shared/pagination.component.vue';
import LabelSlot from '@/shared/slots/label-slot.component.vue';
import MobileHandler from '@/shared/utils/mobile-handler';
import splitContent from '@/shared/directive/split-content';
import Wages from '@/domain/wages/entity/wages.entity';
import Header from './header.json';


@Component({
  components: { PaginationComponent, LabelSlot, VbTooltipSlot },
  filters: {
    splitContent,
    toCurrency: VbDirectives.toCurrency,
  },
})
export default class TableComponent extends Vue {
  @Prop() items!: Wages[];

  @Prop() loading!: boolean;

  @Prop() page!: any;

  header = Header;

  isMobile = false;

  select(item: Wages) {
    this.$emit('openEditModal', item.role);
  }

  updateTable(pagination: any): void {
    this.$emit('update', pagination);
  }

  onResize() {
    this.isMobile = MobileHandler.isMobile();
  }
}
