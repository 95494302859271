





































































































































import { Vue, Component, Prop } from 'vue-property-decorator';
import { VbSnackbarService } from 'bag-of-holding-library';
import LabelSlot from '@/shared/slots/label-slot.component.vue';
import VacationPayslipService from '@/domain/employees/service/vacation-payslip.service';
import DateFormat from '@/shared/utils/date-format';
import VacationPayslipListItem from '@/domain/employees/entity/vacation-payslip-list-item.entity';
import PanelVacation from '@/views/drd/read/common-components/drd-panel.component.vue';
import moment from 'moment';
import VacationPeriod from '@/domain/vacation/entity/vacation-period.entity';
import VacationService from '@/domain/vacation/service/vacation.service';
import headers from './vacation-payslips-header.json';
import headersVacationDetails from './vacation-details-header.json';
import VacationEmployeeListComponent from './vacation-employee-list.component.vue';

@Component({ components: { LabelSlot, PanelVacation, VacationEmployeeListComponent } })
export default class VacationComponent extends Vue {
  @Prop() admissionDate!: string

  @Prop({
    required: true,
  })
  employeeId!: string;

  loading = false;

  payslips: VacationPayslipListItem[] = [];

  headers: any[] = headers;

  headersVacationDetails: any[] = headersVacationDetails;

  vacationPeriod: VacationPeriod = new VacationPeriod();

  year = moment().year();

  get options() {
    const year = moment().year();
    const years: Number[] = [year + 1];
    const admissionDate = moment(this.admissionDate).year()

    for (let i = moment().year(); i > admissionDate; i -= 1) {
      years.push(i);
    }

    return years;
  }

  formatMonth(competence: string): string {
    return DateFormat.monthYearToFormated(competence);
  }

  formatFullDate(date: string): string {
    return DateFormat.timestampToLocal(date);
  }

  downloadPayslip(fileId: string, competence: string) {
    VacationPayslipService.downloadVacationPayslip(fileId)
      .then((file: string) => {
        const name = `Holerite de Férias ${this.formatMonth(competence)}`;
        const link = document.createElement('a');
        link.href = `data:application/pdf;base64,${file}`;
        link.target = '_blank';
        link.download = name;
        link.click();
      })
      .catch(VbSnackbarService.handleHttpError);
  }

  private loadVacationPayslips(): void {
    this.loading = true;
    VacationPayslipService.getVacationPayslips()
      .then((payslips: VacationPayslipListItem[]) => {
        this.payslips = payslips;
      })
      .catch(VbSnackbarService.handleHttpError)
      .finally(() => {
        this.loading = false;
      });
  }

  private getVacationPeriod() {
    VacationService.getVacationPeriod(this.employeeId, this.year)
      .then(resp => this.vacationPeriod = resp)
      .catch(
        () => (this.vacationPeriod = new VacationPeriod({
          period: this.year,
        })),
      );
  }

  created(): void {
    this.loadVacationPayslips();
    this.getVacationPeriod();
  }
}
