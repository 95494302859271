export default class SkillResponse {
  keyAreaSkillId: string;

  response: number = 0;

  constructor(obj: any = {}) {
    this.keyAreaSkillId = obj.keyAreaSkillId || '';
    this.response = obj.response || 0;
  }
}
