




















import { Vue, Component } from 'vue-property-decorator';
import { VbSnackbarService } from 'bag-of-holding-library';
import SelectiveProcessFilter from '@/domain/selective-process/selective-process-filter.entity';
import Assessment from '@/domain/selective-process/entity/assessment/assessment.entity';
import SelectiveProcessService from '@/domain/selective-process/service/selective-process.service';
import CopyClipboardService from '@/shared/utils/copy-clipboard.service';
import CandidateCultureGradeDetails from '@/domain/selective-process/entity/candidate/candidate-culture-grade-details.entity';
import VueToExcel from 'vue-json-excel';
import ExportCultureGradeDetails from '@/domain/selective-process/entity/candidate/export-culture-grade-details.entity'
import SelectiveProcessDetailsReport from '@/domain/selective-process/entity/selective-process-details-report.entity';
import SelectiveProcessFilterComponent from './selective-process-filter.component.vue';
import SelectiveProcessTable from './selective-process-table.component.vue';
import SelectiveProcessHeader from './selective-process-header.component.vue';

@Component({
  components: {
    SelectiveProcessFilterComponent,
    SelectiveProcessTable,
    VueToExcel,
    SelectiveProcessHeader,
  },
})
export default class SelectiveProcessView extends Vue {
  selectivefilter: SelectiveProcessFilter = new SelectiveProcessFilter();

  assessments: Assessment[] = [];

  loading: boolean = false;

  sortBy: Array<string> = ['mentalGrade'];

  sortDesc: boolean[] = [true];

  direction: string = 'desc';

  sort: string = '';

  pagination: any = {
    itemsPerPage: 10,
    page: 1,
    sortBy: this.sortBy,
    sortDesc: this.sortDesc,
    total: 0,
    pageCount: 0,
  }

  updateTable(value: any) {
    if (this.pagination.itemsPerPage < value.itemsPerPage) {
      value.page = 1;
    }
    this.pagination = value;
    this.sendFilter(this.selectivefilter);
  }

  created() {
    this.getAssessments();
  }

  getAssessments() {
    this.loading = true;
    this.getPaginationFields()
    SelectiveProcessService.findAssessments(
      this.selectivefilter,
      this.pagination.page,
      this.pagination.itemsPerPage,
      this.orderBy,
    )
      .then(({ data }) => {
        this.assessments = data.content.map(it => new Assessment(it));
        this.pagination.total = data.totalElements;
        this.pagination.pageCount = data.totalPages;
      })
      .catch((error) => {
        VbSnackbarService.showError(error);
      })
      .finally(() => this.loading = false);
  }

  sendFilter(event: any) {
    this.selectivefilter = event;
    this.getAssessments();
  }

  private getPaginationFields() {
    this.direction = this.pagination.sortDesc[0] ? 'desc' : 'asc';
    this.sort = this.pagination.sortBy.length < 1 ? '' : this.pagination.sortBy[0];
  }

  get orderBy() {
    return this.pagination.sortBy.length !== 0 ? this.formatOrderBy() : ''
  }

  formatOrderBy() {
    const orderByFormated: string[] = [];

    this.pagination.sortBy.forEach((field, index) => {
      orderByFormated.push(`${field}|${this.pagination.sortDesc[index] ? 'desc' : 'asc'}`)
    })
    return orderByFormated.toString();
  }

  copyLink(acronymLanguage: string) {
    CopyClipboardService.copyToClipboard(`${process.env.VUE_APP_REDIRECT_URL}survey?lang=${acronymLanguage}`);
  }

  toPorcentageFormat(value: number): string {
    return `${value.toFixed(2)}%`;
  }

  get exportCultureGradeDetailsButtonText() {
    return this.loading ? 'Aguarde...' : 'Exportar Notas Fit Cultural'
  }
}
