



































import { Vue, Component, Prop } from 'vue-property-decorator';
import SelectionComponent from '@/shared/fields/selection.componet.vue';
import LabelSlot from '@/shared/slots/label-slot.component.vue';
import CareerService from '@/domain/employees/service/career.service';
import UpdateAtribute from '@/domain/employees/entity/update-atribute.entity';

@Component({
  components: {
    SelectionComponent,
    LabelSlot,
  },
})
export default class ResultCenterFilter extends Vue {
  @Prop({
    type: String,
    default: 'Centro de Custo',
  }) label!: string;

  @Prop({
    type: String,
    default: 'Digite o nome...',
  }) placeholder!: string;

  @Prop({
    type: String,
    default: 'Nenhum centro de custo encontrado...',
  }) noDataText!: string;

  resultCenterSelected: string[] = [];

  foundResultCenters: UpdateAtribute[] = [];

  resultCenterName: string = '';

  loading: boolean = false;

  lastFilterResultCenter: string = '';

  selected() {
    this.$emit('selected', this.resultCenterSelected);
  }

  findResultCenters(value: string) {
    if ((this.lastFilterResultCenter !== value) && value) {
      this.lastFilterResultCenter = value;
      if (value.length > 2) {
        const resultCentersFiltered = this.foundResultCenters
          .filter(
            it => (it.name || '').toLocaleLowerCase().indexOf((value || '').toLocaleLowerCase()),
          )
        if (resultCentersFiltered.length > 0) return;
        this.loading = true;
        CareerService.getResultCentersByName(value)
          .then(data => this.foundResultCenters = data)
          .finally(() => this.loading = false);
      }
    }
  }
}
