import DateFormat from '@/shared/utils/date-format';
import moment from 'moment';

export default class Release {
  id: string;

  releaseAt: string;

  description: string;

  link: string;

  constructor(obj: any = {}) {
    this.id = obj.id || '';
    this.releaseAt = obj.releaseAt || '';
    this.description = obj.description || '';
    this.link = obj.link || '';
  }

  get releaseAtDate() {
    return moment(this.releaseAt).toDate();
  }

  get releaseAtFormat() {
    return DateFormat.fullDateFormatMonth(this.releaseAt.split('T')[0]);
  }

  get showRelease() {
    return moment(moment().format('YYYY-MM-DD')).diff(moment(this.releaseAt, 'YYYY-MM-DD'), 'days') <= 10;
  }

  get showCountdown() {
    const currentDate = new Date();
    return !(this.releaseAtDate.getTime() - currentDate.getTime() <= 0);
  }
}
