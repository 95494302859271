




























import {
  Vue, Component, Prop,
} from 'vue-property-decorator';

@Component
export default class PaginationComponent extends Vue {
  @Prop() pagination!: any;

  @Prop() color?: string;

  @Prop({
    required: false,
    default: () => [5, 10, 15, 30, 45, 60],
  }) options!: string[];

  @Prop({
    required: false,
    default: 8,
  }) totalVisible!: number

  get length(): number {
    const pageCount: number = Math.ceil(this.pagination.total / this.pagination.itemsPerPage);
    return pageCount === 0 ? 1 : pageCount;
  }

  get index(): string {
    let end = Math.ceil(this.pagination.page * this.pagination.itemsPerPage);
    const start = end - this.pagination.itemsPerPage + 1;
    if (end > this.pagination.total) {
      end = this.pagination.total;
    }
    return `${start}-${end} de ${this.pagination.total}`;
  }

  private send(pagination: any): void {
    this.$emit('input', pagination);
  }

  updateItemsPerPage(itemsPerPage: number): void {
    const pagination: any = {
      ...this.pagination,
      itemsPerPage,
    };
    this.send(pagination);
  }

  updateSelectedPage(page: number): void {
    const pagination: any = {
      ...this.pagination,
      page,
    };
    this.send(pagination);
  }
}
