













import { Vue, Component, Prop } from 'vue-property-decorator';

@Component
export default class MainButton extends Vue {
  @Prop() text!: String;

  @Prop() color!: String;

  @Prop() dark!: Boolean;

  @Prop() font?: Boolean;

  @Prop() disabled?: Boolean;

  @Prop() height?: string;

  click() {
    this.$emit('click');
  }
}

