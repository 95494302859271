import RouteCheckerTo from '@/views/route-checker';
import SkillView from './skill.view.vue';

export default [
  {
    path: '/skills',
    name: 'Competências',
    component: SkillView,
    beforeEnter: RouteCheckerTo.hasAccessReadSkillKeyArea,
  },
]
