
























import { Vue, Component } from 'vue-property-decorator';
import LottieAnimation from 'lottie-web-vue';
import CheckAnitionJSON from '@/assets/check-animation.json';

@Component({
  components: {
    LottieAnimation,
  },
})
export default class SuccessCardComponent extends Vue {
  checkAnitionJSON = CheckAnitionJSON
}
