




















import { Vue, Component, Prop } from 'vue-property-decorator';

@Component
export default class ActivityHeaderComponent extends Vue {
  @Prop({
    type: String,
  }) activityName!: string;

  @Prop({
    type: String,
  }) employeeName!: string;

  back() {
    this.$emit('back')
  }
}
