import RouteCheckerTo from '@/views/route-checker';
import Assignment from './assignment.view.vue';
import Role from './roles/role.view.vue';
import PerformanceDescription from './performance-description.view.vue';
import PerformanceDescriptionEditComponent from './form/performance-description-edit.component.vue';
import PerformanceDescriptionReadComponent from './read/performance-description-read.component.vue';

export default [
  {
    path: '/assignments',
    name: 'Papéis',
    component: Assignment,
    beforeEnter: RouteCheckerTo.hasAccessAssignments,
  },
  {
    path: '/roles',
    name: 'Cargos',
    component: Role,
    beforeEnter: RouteCheckerTo.hasAccessRoles,
  },
  {
    path: '/performance-description-list',
    name: 'DRDs',
    component: PerformanceDescription,
  },
  {
    path: '/performance-description/:id',
    name: 'DRDs',
    component: PerformanceDescriptionEditComponent,
    beforeEnter: RouteCheckerTo.hasAccessUpdateDrd,
  },
  {
    path: '/performance-description/:id/view',
    name: 'Visualizar DRDs',
    component: PerformanceDescriptionReadComponent,
  },
];
