















import { Vue, Component, Prop } from 'vue-property-decorator';
import State from '@/domain/states/entity/state.entity';
import StateService from '@/domain/states/service/states.service'
import LabelSlot from '../slots/label-slot.component.vue';
import AutocompleteComponent from '../fields/autocomplete-component.vue';

@Component({
  components: {
    LabelSlot,
    AutocompleteComponent,
  },
})
export default class SelectStatesComponent extends Vue {
  @Prop() value!: string[] | string;

  @Prop({
    type: Boolean,
    default: false,
  }) multiple!: boolean;

  @Prop({
    type: Boolean,
    default: false,
  }) disabled!: boolean;

  states: State[] = [];

  loading: boolean = false;

  created() {
    this.findStates();
  }

  async findStates() {
    this.loading = true;
    this.states = await StateService.findStates();
    this.loading = false;
  }
}
