



























































































































































import { Vue, Component, Prop } from 'vue-property-decorator';
import ModalComponent from '@/shared/modal/modal.component.vue';
import ModalOptions from '@/shared/modal/modal-options.entity';
import DefaultIndicator from '@/domain/drd/entity/default-indicator.entity';
import Rules from '@/shared/rules/rules';
import IndicatorCategory from '@/views/drd/form/tabs/indicators/indicator-category.json';
import WeightCategories from '@/views/drd/form/tabs/indicators/weight-categories.json';
import GoalAchievementTypes from '@/views/drd/form/tabs/indicators/goal-achievement-types.json';
import GoalMaskTypes from '@/views/drd/form/tabs/indicators/goal-mask-types';
import UtilityTypes from '@/views/drd/form/tabs/indicators/utility-types.json';
import GoalMeasurementTypes from '@/views/drd/form/tabs/indicators/goal-measurement-types.json';
import LabelSlot from '@/shared/slots/label-slot.component.vue';
import IndicatorService from '@/domain/drd/service/indicator/indicator.service';
import { VbSnackbarService } from 'bag-of-holding-library';

@Component({
  components: {
    ModalComponent,
    LabelSlot,
  },
})
export default class CreateOrUpdateIndicatorDefaultModal extends Vue {
  @Prop({
    type: Boolean,
  }) openModal!: boolean;

  @Prop() indicator!: DefaultIndicator

  loading: boolean = false;

  rules: Rules = new Rules();

  indicatorCategory = IndicatorCategory;

  weightCategories = WeightCategories;

  goalAchievementTypes = GoalAchievementTypes;

  goalMaskTypes = GoalMaskTypes;

  utilityTypes = UtilityTypes;

  goalMeasurementTypes = GoalMeasurementTypes;

  showCancel: boolean = true;

  get options(): ModalOptions {
    return {
      title: this.modalTitle,
      titleSize: '20px',
      description: '',
      showOk: true,
      loadingOk: this.loading,
      labelOk: 'Salvar',
      okColor: 'tabBlue',
      showCancel: this.showCancel,
      labelCancel: 'Fechar',
      width: 800,
    };
  }

  get isUpdate() {
    return this.indicator.id !== '';
  }

  get modalTitle() {
    return this.isUpdate ? 'Atualizar Indicador' : 'Novo Indicador'
  }

  closeModal() {
    this.$emit('closeModal');
  }

  save() {
    const { form }: any = this.$refs
    if (!form.validate()) return;
    if (this.isUpdate) {
      this.update()
      return;
    }
    this.create();
  }

  create() {
    this.loading = true;
    this.showCancel = false;
    IndicatorService.createIndicatorDefault(this.indicator)
      .then(() => VbSnackbarService.showSuccess('Indicador criado!'))
      .catch((error) => {
        VbSnackbarService.handleHttpError(error);
      }).finally(() => {
        this.loading = false;
        this.showCancel = true;
        this.closeModal();
      })
  }

  update() {
    this.loading = true;
    this.showCancel = false;
    IndicatorService.updateIndicatorDefault(this.indicator)
      .then(() => VbSnackbarService.showSuccess('Indicador Atualizado!'))
      .catch((error) => {
        VbSnackbarService.handleHttpError(error);
      }).finally(() => {
        this.loading = false;
        this.showCancel = true;
        this.closeModal();
      })
  }
}
