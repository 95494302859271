import axios from 'axios';
import Response from '../entity/response.entity';
import AttendanceFilter from '../entity/filter.entity';
import Attendance from '../entity/attendance.entity';

export default class AttendanceService {
  public static findAttendance(
    page: number, size: number, filter: AttendanceFilter,
  ): Promise<Response> {
    return axios.get('/api/attendance', {
      params: {
        employee: filter.employee,
        manager: filter.manager,
        businessUnit: filter.businessUnit,
        admissionDate: filter.admissionDate,
        type: filter.type,
        status: filter.status,
        expectedDate: filter.expectedDate,
        critical: filter.critical,
        initialPeriod: filter.initialPeriod,
        finalPeriod: filter.finalPeriod,
        responsible: filter.responsible,
        resultCenter: filter.resultCenter,
        role: filter.role,
        active: filter.active,
        page: page - 1,
        size,
      },
    })
      .then((response: any) => Response.of(response.data));
  }

  public static getAttendance(id: string): Promise<Attendance> {
    return axios.get(`/api/attendance/${id}`)
      .then((response: any) => Attendance.of(response.data));
  }

  public static saveAttendance(id: string, attendance: any): Promise<void> {
    return axios.put(`/api/attendance/${id}`, attendance)
      .then((resp: any) => resp);
  }

  public static newAttendance(newAttendance: any): Promise<Attendance> {
    return axios.post('/api/attendance', newAttendance)
      .then((resp: any) => resp);
  }

  public static deleteAttendance(id: string) {
    return axios.delete(`/api/attendance/${id}`)
      .then((resp: any) => resp)
  }
}
