export default class TalentLevel {
  public id: string;

  public name: string;

  public description: string;

  public color: string;

  public active: boolean;

  public notify: boolean;

  constructor(obj: any = {}) {
    this.id = obj.id;
    this.name = obj.name || '';
    this.description = obj.description || '';
    this.color = obj.color || '';
    this.active = obj.active === null || obj.active === undefined ? true : obj.active;
    this.notify = obj.notify === null || obj.notify === undefined ? false : obj.notify;
  }
}
