




























































































import VueTree from '@ssthouse/vue-tree-chart';
import {
  Vue,
  Component,
  Prop,
} from 'vue-property-decorator';
import { EmployeeOrganization } from '../../../domain/employees/entity/employee-chart.entity';

@Component({
  name: 'OrganizationalChartView',
  components: { VueTree },
})
export default class OrganizationalChartComponent extends Vue {
  @Prop() organizationData!: EmployeeOrganization;

  treeConfig = { nodeWidth: 300, nodeHeight: 80, levelHeight: 200 };

  mounted() {
    this.collapseEmployee();
  }

  collapseAll(): void {
    const { tree }: any = this.$refs;
    const { nodeDataList }: any = tree;

    for (let index = nodeDataList.length - 1; index !== 0; index -= 1) {
      if (!!nodeDataList[index].data.children && nodeDataList[index].data.children.length > 0) {
        if (!nodeDataList[index].data._collapsed) {
          tree.onClickNode(index);
        }
      }
    }
  }

  collapseEmployee(employee?: any) {
    const { tree }: any = this.$refs;
    const { nodeDataList }: any = tree;

    const mainNode = employee || nodeDataList[0].data;

    if (!this.hasChildren(mainNode) || (!mainNode._collapsed && !!employee)) {
      return;
    }

    const children = employee ? mainNode._children : mainNode.children;
    const childrenIds = children.map(child => child.id);

    setTimeout(() => {
      const treeUpdated: any = this.$refs.tree;
      const { nodeDataList: nodeDataListUpdated } = treeUpdated;

      nodeDataListUpdated.forEach((node, index) => {
        if (childrenIds.includes(node.data.id)) {
          if (!node.data._collapsed) {
            tree.onClickNode(index);
          }
        }
      })
    }, 1000);
  }

  zoom(type: 'in' | 'out'): void {
    const { tree }: any = this.$refs;

    const zoomByType = {
      in: () => tree.zoomIn(),
      out: () => tree.zoomOut(),
    }

    zoomByType[type]();
  }

  hasChildren(employee: any): boolean {
    return (
      (!!employee._children && !!employee._children.length)
      || (!!employee.children && !!employee.children.length)
    );
  }
}
