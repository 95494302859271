export default class MobileHandler {
  public static isMobile(): boolean {
    if (window.innerWidth < 1092) {
      return true;
    }
    return false;
  }

  // Dar preferência ao uso dos breakpoints do vuetify. Utilizar este método em último caso.
  public static isLessThan(pixels: number): boolean {
    if (window.innerWidth < pixels) {
      return true;
    }
    return false;
  }
}
