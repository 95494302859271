






import {
  Vue, Component, Prop,
} from 'vue-property-decorator';

@Component
export default class ModalDescriptionComponent extends Vue {
  @Prop() value!: string;
}
