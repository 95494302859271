












import { Vue, Prop, Component } from 'vue-property-decorator';

@Component
export default class TabButtonField extends Vue {
  @Prop() text!: string;

  @Prop() actual!: boolean;

  private click() {
    this.$emit('click');
  }

  private get $actual() {
    return this.actual ? 'actual' : 'disabled';
  }
}
