var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mt-6 pa-4 history-container"},[_c('v-row',{attrs:{"justify":"space-between","align":"center"}},[_c('v-col',{attrs:{"cols":"10"}},[_c('h2',{staticClass:"primary--text"},[_vm._v(" Versão anterior - "+_vm._s(_vm.items[0].lastModifiedDate)+" ")])]),_c('v-col',{staticClass:"d-flex justify-end align-center",attrs:{"cols":"auto"}},[_c('v-btn',{attrs:{"icon":"","color":"black"},on:{"click":function($event){return _vm.$emit('close')}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{staticClass:"history-container__table",attrs:{"headers":_vm.header,"items":_vm.items,"disable-pagination":"","disable-sort":""},scopedSlots:_vm._u([{key:"item.minWage",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm._f("toCurrency")(item.minWage)))])]}},{key:"item.mediumWage",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm._f("toCurrency")(item.mediumWage)))])]}},{key:"item.maxWage",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm._f("toCurrency")(item.maxWage)))])]}},{key:"item.benefit",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm._f("toCurrency")(item.benefit)))])]}}],null,true)})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }