




















import { Vue, Component, Prop } from 'vue-property-decorator'
import { VbSnackbarService } from 'bag-of-holding-library';
import CandidateCultureGradeDetails from '@/domain/selective-process/entity/candidate/candidate-culture-grade-details.entity';
import ExportCultureGradeDetails from '@/domain/selective-process/entity/candidate/export-culture-grade-details.entity';
import SelectiveProcessService from '@/domain/selective-process/service/selective-process.service';
import Assessment from '@/domain/selective-process/entity/assessment/assessment.entity';
import VueToExcel from 'vue-json-excel';

@Component({
  components: {
    VueToExcel,
  },
})
export default class ExportCultureGradeButton extends Vue {
  @Prop({
    required: true,
  }) assessments!: Assessment[];

  @Prop({
    type: Boolean,
    default: false,
  }) loading!: boolean;

  exportCultureGradeDetailsData: CandidateCultureGradeDetails[] = [];

  loadingExportCultureGradeButton: boolean = false;

  exportCultureGradeDetailsFields: any = {
    candidato: 'candidateName',
    Total: 'total',
    'Total %': 'totalPercent',
    'Locus os Control': 'locusOfControlGrade',
    'Locus os Control %': 'locusOfControlGradePercent',
    'Be Fair Do Right Dont Hide': 'beFairDoRightDontRideGrade',
    'Be Fair Do Right Dont Hide %': 'beFairDoRightDontRideGradePercent',
    'Data da Resposta': 'responseDate',
    'N° Questão': 'questionPosition',
    Questão: 'questionTitle',
    Resposta: 'value',
    Comentário: 'comments',
    Nota: 'grade',
    'Nota Maxima': 'maxGrade',
  }

  async exportCultureGradeDetails() {
    const content: ExportCultureGradeDetails[] = [];
    await this.getCultureGradeDetails();
    this.exportCultureGradeDetailsData.forEach((it) => {
      it.answers.forEach((answer) => {
        content.push(new ExportCultureGradeDetails(answer, it))
      });
    })
    this.loadingExportCultureGradeButton = false;
    return content;
  }

  async getCultureGradeDetails() {
    this.loadingExportCultureGradeButton = true;
    const listCandidateId = this.assessments.map(it => it.candidate.id);
    await SelectiveProcessService.getCultureGradeDetails(listCandidateId)
      .then(({ data }) => {
        this.exportCultureGradeDetailsData = CandidateCultureGradeDetails.ofList(data)
      }).catch(VbSnackbarService.handleHttpError);
  }

  get exportCultureGradeDetailsButtonText() {
    return this.loading ? 'Aguarde...' : 'Exportar Notas Fit Cultural'
  }
}
