































import { Component, Prop, Vue } from 'vue-property-decorator';
import CardTitle from '@/shared/card/card-title.component.vue';
import { VbTooltipSlot } from 'bag-of-holding-library';

@Component({
  components: {
    CardTitle, VbTooltipSlot,
  },
})
export default class ListHeader extends Vue {
  @Prop({
    type: Boolean,
  }) value!: boolean;

  @Prop({
    type: String,
  }) title!: string

  @Prop({
    type: String,
  }) checkboxLabel!: string

  input() {
    this.$emit('input', this.value);
  }
}
