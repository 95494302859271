export default class OperationModality {
  id: string;

  name: string;

  active: boolean;

  constructor(obj: any = {}) {
    this.id = obj.id || '';
    this.name = obj.name || '';
    this.active = obj.active;
  }

  public static of(obj: any) {
    return new OperationModality(obj);
  }

  public static ofList(obj: any[] = [{}]) {
    return obj.map(this.of);
  }
}
