














































































































































import { Vue, Component, Prop } from 'vue-property-decorator';
import LabelSlot from '@/shared/slots/label-slot.component.vue';
import IndicatorDefaultFilter from '@/domain/drd/entity/indicators/indicator-default-filter.entity'
import Categories from '@/views/drd/form/tabs/indicators/indicator-category.json'
import Utilities from '@/views/drd/form/tabs/indicators/utility-types.json'
import GoalMeasurementTypes from '@/views/drd/form/tabs/indicators/goal-measurement-types.json'
import DefaultIndicator from '@/domain/drd/entity/default-indicator.entity';
import CheckboxComponent from '@/shared/checkbox/checkbox.component.vue';
import CreateOrUpdateIndicatorDefaultModal from './create-update-indicator-default-modal.component.vue';

@Component({
  components: {
    CreateOrUpdateIndicatorDefaultModal,
    LabelSlot,
    CheckboxComponent,
  },
})
export default class IndicatorDefaultFilterComponent extends Vue {
  filter: IndicatorDefaultFilter = new IndicatorDefaultFilter();

  categories = Categories;

  utilities = Utilities;

  goalMeasurementTypes = GoalMeasurementTypes

  openModal: boolean = false;

  newIndicator: DefaultIndicator = new DefaultIndicator();

  @Prop({
    type: Boolean,
  }) hasCreateIndicatorDefault!: boolean;

  sendfilter() {
    this.$emit('sendFilter', this.filter);
  }

  clearFilter() {
    this.filter = new IndicatorDefaultFilter();
    this.sendfilter();
  }

  openCreateModal() {
    this.newIndicator = new DefaultIndicator();
    this.openModal = true;
  }

  closeModal() {
    this.openModal = false;
    this.sendfilter();
  }

  setFilterActive(value: boolean) {
    this.filter.active = value;
    this.sendfilter();
  }
}
