export default class FilterVacationBalanceDetail {
  employeeName: string;

  managerName: string;

  year: number;

  startPurchasingDate: string;

  limitVacation: string;

  startDate: string;

  endDate: string;

  countDays: number;

  vacationTypeName: string;

  balance: number;

  observation: string;

  constructor(obj: any = {}) {
    this.employeeName = obj.employeeName || '';
    this.managerName = obj.managerName || '';
    this.year = obj.year !== null || obj.year !== undefined ? obj.year : null;
    this.startPurchasingDate = obj.startPurchasingDate || '';
    this.limitVacation = obj.limitVacation || '';
    this.startDate = obj.startDate || '';
    this.endDate = obj.endDate || '';
    this.countDays = obj.countDays || '';
    this.vacationTypeName = obj.vacationTypeName || '';
    this.balance = obj.balance !== null || obj.balance !== undefined ? obj.balance : null;
    this.observation = obj.observation || '';
  }
}
