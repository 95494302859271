























































































import {
  Vue, Component, Prop,
} from 'vue-property-decorator';
import { VbSnackbarService } from 'bag-of-holding-library';
import UpdateAtribute from '@/domain/employees/entity/update-atribute.entity';
import CareerService from '@/domain/employees/service/career.service';
import LabelSlot from '@/shared/slots/label-slot.component.vue';
import Rules from '@/shared/rules/rules';
import CreatePerformanceDescriptionRequest from '@/domain/drd/entity/create-performance-description.request';
import DrdService from '@/domain/drd/service/drd.service';
import Assignment from '@/domain/drd/entity/assignment.entity';

@Component({ components: { LabelSlot } })
export default class CreateDrdModal extends Vue {
  @Prop() showModal!: boolean;

  @Prop() assignments!: Assignment[];

  private selectedAssignment: Assignment = new Assignment({});

  private valid = false;

  private rules: Rules = new Rules()

  private request: CreatePerformanceDescriptionRequest = CreatePerformanceDescriptionRequest.of({});

  private createLoading: boolean = false;

  private close() {
    this.$emit('close');
  }

  private get filteredAssignments(): Assignment[] {
    return this.assignments
      .filter(assignment => assignment !== null && assignment.ecosystem !== null);
  }

  get roleName(): string {
    if (!this.selectedAssignment.role) return '';
    return this.selectedAssignment.role.name;
  }

  getAllAssignments() {
    DrdService.getActiveAssignments()
      .then((resp: any) => {
        this.assignments = resp.data.filter(
          assignment => assignment !== null && assignment.ecosystem !== null,
        );
      });
  }

  saveNewDrd() {
    this.createLoading = true;
    this.request.assignmentId = this.selectedAssignment.id;
    DrdService.createPerformanceDescription(this.request)
      .then((resp: any) => {
        this.close();
        VbSnackbarService.showSuccess('Drd criada com sucesso!')
        this.$router.push({
          path: `/performance-description/${resp.data}`,
        })
      })
      .catch((err: any) => VbSnackbarService.handleHttpError(err))
      .finally(() => this.createLoading = false);
  }
}
