export default class BusinessUnitEmployeeAmount {
  id: string;

  name: string;

  amount: number;

  constructor(obj: any = {}) {
    this.id = obj.id || '';
    this.name = obj.name || '';
    this.amount = obj.amount || 0;
  }

  public static of(value: any = {}): BusinessUnitEmployeeAmount {
    return new BusinessUnitEmployeeAmount(value || {});
  }

  public static ofList(list: any = []): BusinessUnitEmployeeAmount[] {
    return list.map(this.of);
  }
}
