

























import { VbSnackbarService } from 'bag-of-holding-library';
import { Vue, Component, Prop } from 'vue-property-decorator';
import DrdService from '@/domain/drd/service/drd.service';
import TechnicalKnowledge from '@/domain/drd/entity/technical-knowledge.entity';
import AddButton from '@/shared/buttons/add-button.component.vue';
import Loader from '@/views/drd/commons/loader.component.vue';
import TechnicalKnowledgeComponent from './technical-knowledge.component.vue';

@Component({
  components: { AddButton, Loader, TechnicalKnowledgeComponent },
})
export default class TechnicalKnowledgeIndex extends Vue {
  @Prop({
    type: String,
    required: true,
  })
  private performanceDescriptionId!: string;

  private loading: boolean = true;

  private technicalKnowledges: TechnicalKnowledge[] = [];

  private updateField(index: number, field: string, value: any): void {
    this.technicalKnowledges[index][field] = value;
  }

  private addNewTechnicalKnowledge(): void {
    this.technicalKnowledges.push(new TechnicalKnowledge());
  }

  private deleteTechnicalKnowledge(index: number): void {
    this.technicalKnowledges.splice(index, 1);
  }

  private save(): Promise<void> {
    const { form }: any = this.$refs;
    return new Promise((resolve, reject) => {
      if (!form.validate()) {
        reject();
        return;
      }
      DrdService.updateTechnicalKnowledges(this.performanceDescriptionId, this.technicalKnowledges)
        .then(({ data }) => {
          VbSnackbarService.showSuccess('Conhecimentos técnicos salvos com sucesso!');
          this.technicalKnowledges = data.map(it => new TechnicalKnowledge(it));
          resolve();
        }).catch((err) => {
          VbSnackbarService.handleHttpError(err);
          reject();
        });
    });
  }

  private created(): void {
    this.loadTechnocalKnowledges();
  }

  private loadTechnocalKnowledges(): void {
    this.loading = true;
    DrdService.getTechnicalKnowledges(this.performanceDescriptionId).then((data) => {
      this.technicalKnowledges = data.map((it: any) => new TechnicalKnowledge(it));
    }).catch(VbSnackbarService.handleHttpError)
      .finally(() => {
        this.loading = false;
      });
  }
}
