import Godfather from './godfather.entity';

export default class Employee {
  id: string;

  godfather: Godfather

  admissionDate: string;

  name: string;

  username: string;

  businessUnit: string;

  role: string;

  manager: string;

  isAccompanied: boolean;

  hardSkills: string[];

  frameworks: string[];

  businessExperiences: string[];

  certifications: string[];

  managerEmails: string[];

  availableEmails: string[];

  email: string[];

  resignationDate: string;

  techLeadAvailableEmails: string[];

  constructor(obj: any) {
    this.id = obj.id || '';
    this.godfather = Godfather.of(obj.godFatherDTO);
    this.admissionDate = obj.admissionDate || '';
    this.name = obj.name || '';
    this.username = obj.username || '';
    this.businessUnit = obj.businessUnit || '';
    this.role = obj.role || '';
    this.manager = obj.manager || '';
    this.isAccompanied = obj.isAccompanied;
    this.hardSkills = obj.hardSkills || [];
    this.frameworks = obj.frameworks || [];
    this.businessExperiences = obj.businessExperiences || [];
    this.certifications = obj.certifications || [];
    this.managerEmails = obj.managerEmails || [];
    this.availableEmails = obj.availableEmails || [];
    this.email = obj.email || [];
    this.resignationDate = obj.resignationDate || '';
    this.techLeadAvailableEmails = obj.techLeadAvailableEmails || [];
  }

  public static of(obj: any): Employee {
    return new Employee(obj || {});
  }

  public static ofList(obj: any[]): Employee[] {
    return obj.map(this.of);
  }

  isCurrentManager(userEmail: string) {
    const managerEmailsLower = this.managerEmails.map(el => el.toLowerCase());
    return managerEmailsLower.includes(userEmail.toLowerCase());
  }
}
