export default class FilteredGodfather {
  name: string;

  manager: string;

  role: string;

  businessUnit: string;

  resultCenter: string;

  admissionDate: string;

  needTraining: boolean

  observation: string;

  operationModality: string;

  seniority: string;

  status: boolean

  trainingDate: string;

  workPlace: string;

  constructor(obj: any) {
    this.name = obj.name || '';
    this.manager = obj.manager || '';
    this.role = obj.role || '';
    this.businessUnit = obj.businessUnit || '';
    this.resultCenter = obj.resultCenter || '';
    this.observation = obj.observation || '';
    this.operationModality = obj.operationModality || '';
    this.workPlace = obj.workPlace || '';
    this.seniority = obj.seniority || '';
    this.status = obj.status;
    this.trainingDate = obj.trainingDate || '';
    this.admissionDate = obj.admissionDate || '';
    this.needTraining = obj.needTraining;
  }

  public static of(obj: any): FilteredGodfather {
    return new FilteredGodfather(obj || {})
  }

  public static ofList(obj: any[]): FilteredGodfather[] {
    return obj.map(this.of);
  }

  get valueNeedTraining() {
    return this.needTraining ? 'Sim' : 'Não'
  }

  get valueStatus() {
    return this.status ? 'Sim' : 'Não'
  }
}
