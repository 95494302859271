import Indicators from '@/domain/drd/entity/indicators.entity';
import PersistAssignmentKeyArea from './persist-assignment-keyarea.entity';

export default class PersistDrd {
  assignmentId: string;

  assignmentDescription: string;

  objective: string;

  keyAreas: PersistAssignmentKeyArea[];

  indicators: Indicators[];

  constructor(obj: any = {}) {
    this.assignmentId = obj.assignmentId;
    this.assignmentDescription = obj.assignmentDescription;
    this.objective = obj.objective;
    this.keyAreas = obj.keyAreas || [];
    this.indicators = obj.indicators ? obj.indicators
      .map(it => new Indicators({ ...it, saved: true })) : [];
  }
}
