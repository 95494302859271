






























import { Vue, Component, Prop } from 'vue-property-decorator';
import SelectSingleAssignmentComponent from '@/shared/assignment/select-sigle-assignment.component.vue';

@Component({
  components: {
    SelectSingleAssignmentComponent,
  },
})
export default class StartAssessmentComponent extends Vue {
  @Prop({
    type: Boolean,
    default: false,
  }) loading!: boolean;

  @Prop({
    type: String,
    default: '',
  }) assignmentId!: string;
}
