import Answer from './answer.entity';
import Question from './question.entity';

export default class FitCultural {
  name: string;

  email: string;

  document: string;

  answers: Answer[];

  authorizeManipulatePersonalData: boolean;

  surveyId: string;

  constructor(obj: any = {}) {
    this.name = obj.name || '';
    this.email = obj.email || '';
    this.document = obj.document || '';
    this.answers = obj.answers || [];
    this.authorizeManipulatePersonalData = obj.authorizeManipulatePersonalData || false;
    this.surveyId = obj.surveyId || '';
  }

  public static createInitial(questions: Question[], surveyId: string): FitCultural {
    const fitCultural = new FitCultural();
    fitCultural.surveyId = surveyId;
    questions.forEach((it) => {
      fitCultural.answers.push(Answer.of(it));
    })
    return fitCultural;
  }
}
