























import { Vue, Prop, Component } from 'vue-property-decorator';

@Component
export default class ObservationIcon extends Vue {
  @Prop({
    required: false,
    default: '',
  })
  private observation?: string;

  @Prop({
    required: false,
    default: '#3B7CB3',
  })
  private color?: string;
}
