import SkillType from './skill-type.entity'
import SkillStatus from './skill-type.entity copy'

export default class Skill {
  id: string

  active: boolean

  description: string

  lastModifiedBy: string

  observation: string

  status: SkillStatus

  suggestedBy: string

  type: SkillType

  constructor(obj: any = {}) {
    this.id = obj.id || '';
    this.active = obj.active === null || obj.active === undefined ? true : obj.active;
    this.description = obj.description || '';
    this.lastModifiedBy = obj.lastModifiedBy || '';
    this.observation = obj.observation || '';
    this.status = obj.status || SkillStatus.PENDING;
    this.suggestedBy = obj.suggestedBy || '';
    this.type = obj.type || '';
  }

  public static of(obj: any): Skill {
    return new Skill(obj || {});
  }

  public static ofList(obj: any): Skill[] {
    return obj.map(this.of);
  }
}
