export default class Birthday {
  name: string;

  businessUnit: string;

  birthdayCardUrl: string;

  birthdayDate: string;

  hideCard: boolean;

  photo: string;

  employeeId: string;

  constructor(obj: any = {}) {
    this.name = obj.name || '';
    this.businessUnit = obj.businessUnit || '';
    this.birthdayCardUrl = obj.birthdayCardUrl || '';
    this.birthdayDate = obj.birthdayDate || '';
    this.hideCard = obj.hideCard;
    this.photo = obj.photo || '';
    this.employeeId = obj.employeeId || '';
  }

  public static of(obj: any): Birthday {
    return new Birthday(obj || {});
  }

  public static ofList(obj: any[]): Birthday[] {
    return obj.map(this.of);
  }
}
