













import { Vue, Component, Prop } from 'vue-property-decorator';
import { VbSnackbarService } from 'bag-of-holding-library';
import DrdService from '@/domain/drd/service/drd.service';
import Loader from '@/views/drd/commons/loader.component.vue';
import TechnicalKnowledge from '@/domain/drd/entity/technical-knowledge.entity';
import TechnicalKnowledgeComponent from './technical-knowledge.component.vue';

@Component({
  components: { Loader, TechnicalKnowledgeComponent },
})
export default class TechnicalKnowledgeIndex extends Vue {
  @Prop({
    type: String,
    required: true,
  })
  private performanceDescriptionId!: string;

  private loading: boolean = true;

  private technicalKnowledges: TechnicalKnowledge[] = [];

  private loadTechnicalKnowledges(): void {
    this.loading = true;
    DrdService.getTechnicalKnowledges(this.performanceDescriptionId).then((data) => {
      this.technicalKnowledges = data.map(it => new TechnicalKnowledge(it))
        .filter(it => it.active);
    }).catch(VbSnackbarService.handleHttpError)
      .finally(() => {
        this.loading = false;
      });
  }

  private created(): void {
    this.loadTechnicalKnowledges();
  }
}
