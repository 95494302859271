

































































































import {
  Vue,
  Component,
  Prop,
} from 'vue-property-decorator';
import Rules from '@/shared/rules/rules';
import LabelSlot from '@/shared/slots/label-slot.component.vue';
import DrdService from '@/domain/drd/service/drd.service';
import AssignmentDto from '@/domain/drd/entity/assignmentDto.entity';
import Assignment from '@/domain/drd/entity/assignment.entity';
import Ecosystem from '@/domain/drd/entity/ecosystem.entity';
import CareerService from '@/domain/employees/service/career.service';
import UpdateAtribute from '@/domain/employees/entity/update-atribute.entity';

@Component({ components: { LabelSlot } })
export default class AssignmentDialog extends Vue {
  @Prop({
    type: Boolean,
    default: false,
  })
  private value!: boolean;

  @Prop({
    type: String,
    default: null,
  })
  private assignmentId!: string;

  assignment: Assignment = new Assignment();

  private rules: Rules = new Rules();

  private loadingSave: boolean = false;

  private loadingGet: boolean = false;

  private ecosystems: Ecosystem[] = [];

  private roles: UpdateAtribute[] = [];

  private closeDialog(): void {
    this.$emit('input', false);
  }

  private validateAndSaveAssignment(): void {
    const { form }: any = this.$refs;
    if (form.validate()) {
      if (this.assignmentId) {
        this.updateAssignment();
        return;
      }
      this.createNewAssignment();
    }
  }

  private createNewAssignment(): void {
    this.loadingSave = true;
    DrdService.createAssignment(new AssignmentDto(this.assignment))
      .then(this.closeDialogAndRefresh)
      .finally(() => {
        this.loadingSave = false;
      });
  }

  private updateAssignment(): void {
    this.loadingSave = true;
    DrdService.updateAssignment(new AssignmentDto(this.assignment))
      .then(this.closeDialogAndRefresh)
      .finally(() => {
        this.loadingSave = false;
      });
  }

  private closeDialogAndRefresh(): void {
    this.closeDialog();
    this.$emit('refresh');
  }

  private created() {
    this.loadingGet = true;
    this.assignment = new Assignment();
    this.assignment.inactive = false;
    Promise.all([this.getRoles(), this.getEcosystems(), this.getAssignment()]).finally(() => {
      this.loadingGet = false;
    });
  }

  private async getEcosystems() {
    return DrdService.getAllEcosystem()
      .then((ecosystems: Ecosystem[]) => {
        this.ecosystems = ecosystems;
      })
  }

  private async getRoles() {
    return CareerService.getRoles()
      .then((resp: any) => this.roles = resp.data)
  }

  private async getAssignment() {
    if (this.assignmentId) {
      return DrdService.getAssignmentById(this.assignmentId).then((assignment: Assignment) => {
        this.assignment = assignment;
      })
    }
    return Promise.resolve();
  }
}

