import ActivityDetailed from '../activitie/activity-detailed.entity';

export default class EmployeeScheduleItem {
  date: string;

  amount: number;

  activities: ActivityDetailed[] = [];

  private constructor(obj: any) {
    this.date = obj.date || '';
    this.amount = obj.amount || 0;
    this.activities = ActivityDetailed.ofList(obj.activities);
  }

  public static of(obj: any): EmployeeScheduleItem {
    return new EmployeeScheduleItem(obj || {});
  }

  public static ofList(obj: any): EmployeeScheduleItem[] {
    return obj.map(this.of);
  }

  get firstActivity(): ActivityDetailed {
    return this.activities[0];
  }

  get description(): String {
    return this.amount === 1
      ? this.firstActivity.name
      : `${this.amount} Atividades`;
  }

  get hasApproved(): boolean {
    return this.activities.find(activity => activity.status === 'Aprovada') !== undefined;
  }

  get hasLate(): boolean {
    return this.activities.find(activity => activity.status === 'Atrasada' && activity.name !== 'Acordo Salarial Avulso') !== undefined;
  }

  get hasRefused(): boolean {
    return this.activities.find(activity => activity.status === 'Recusada') !== undefined;
  }

  get hasPending(): boolean {
    return this.activities.find(activity => !activity.status) !== undefined;
  }

  get hasFile(): boolean {
    return this.activities.find(activity => activity.files !== undefined
      && activity.files.length > 0 && activity.status !== 'Aprovada' && activity.status !== 'Recusada') !== undefined;
  }

  get hasScore(): boolean {
    return this.activities.find(activity => activity.hasScore) !== undefined
  }

  get hasFeed(): boolean {
    return this.activities.find(activity => activity.hasFeed) !== undefined
  }

  get showStatus(): boolean {
    const current = new Date().getTime();
    const scheduleDate = new Date(`${this.date}-01`).getTime();
    return scheduleDate <= current;
  }
}
