










































import { Vue, Component } from 'vue-property-decorator';
import { VbSnackbarService } from 'bag-of-holding-library';
import AssessmentFilter from '@/domain/assessment/entity/assessment-filter.entity';
import AssessmentService from '@/domain/assessment/service/assessment.service';
import AssessmentAdherenceTotal from '@/domain/assessment/entity/assessment-adherence-total.entity';
import AuthorityStore from '@/domain/security/authority.store';
import AssessmentResultsFilterComponent from './assessment-results-filter.component.vue';
import AssessmentTable from './assessment-table.component.vue';
import Headers from './header.json';
import AssessmentResultCard from '../employee-result/assessment-result-card.component.vue';

@Component({
  components: {
    AssessmentResultsFilterComponent,
    AssessmentTable,
    AssessmentResultCard,
  },
})
export default class AssessmentResultsView extends Vue {
  filter: AssessmentFilter = new AssessmentFilter();

  sortBy: Array<string> = [];

  sortDesc: boolean[] = [false];

  pagination: any = {
    itemsPerPage: 10,
    page: 1,
    sortBy: this.sortBy,
    sortDesc: this.sortDesc,
    total: 0,
    pageCount: 0,
  }

  headers = Headers;

  results: AssessmentAdherenceTotal = new AssessmentAdherenceTotal();

  alreadyCreateHeader: boolean = false;

  loading: boolean = false;

  isManager: boolean = false;

  isAdministrator: boolean = false;

  isFacilitiesAdministrator: boolean = false;

  isEmployeeAdministrator: boolean = false;

  isPersonalDepartament: boolean = false;

  isBusinessPartiner: boolean = false;

  created() {
    this.verifyAuthorities();
    this.getResults();
  }

  updatePagination(value: any) {
    if (this.pagination.itemsPerPage < value.itemsPerPage) {
      this.pagination.page = 1;
    }
    this.pagination = value;
    this.getResults();
  }

  sendFilter(filter: AssessmentFilter) {
    this.filter = filter;
    this.getResults();
  }

  async getResults() {
    this.loading = true;
    await AssessmentService.getResultsAndAdherence(
      this.filter,
      this.pagination.page,
      this.pagination.itemsPerPage,
    ).then((response) => {
      this.pagination.total = response.data.resultsByEmployees.totalElements || 0;
      this.pagination.pageCount = response.data.resultsByEmployees.totalPages;
      this.results = new AssessmentAdherenceTotal(
        {
          ...response.data,
          resultsByEmployees: response.data.resultsByEmployees.content,
        },
      )
    })
      .catch(error => VbSnackbarService.handleHttpError(error))
      .finally(() => this.loading = false);
  }

  verifyAuthorities() {
    AuthorityStore.fetchedAuthority()
      .then((authorities) => {
        this.isManager = authorities.isManager();
        this.isAdministrator = authorities.isAdministrator();
        this.isFacilitiesAdministrator = authorities.isFacilitiesAdministrator();
        this.isEmployeeAdministrator = authorities.isEmployeeAdministrator();
        this.isPersonalDepartament = authorities.isPersonalDepartment();
        this.isBusinessPartiner = authorities.isBusinnesPartner();
      })
  }

  verifyAccessTabIfNotManager(): boolean {
    return this.isEmployeeAdministrator
        || this.isBusinessPartiner
        || this.isPersonalDepartament
        || this.isAdministrator
  }
}
