



























































import LabelSlot from '@/shared/slots/label-slot.component.vue';
import { Vue, Component, Prop } from 'vue-property-decorator';
import DrdFilter from '@/domain/drd/entity/filter-drd.entity'
import UpdateAtribute from '@/domain/employees/entity/update-atribute.entity';
import Assignment from '@/domain/drd/entity/assignment.entity';
import Ecosystem from '@/domain/drd/entity/ecosystem.entity';

@Component({ components: { LabelSlot } })
export default class FilterComponent extends Vue {
 @Prop() filter!: DrdFilter;

 @Prop() roles!: UpdateAtribute[];

 @Prop() assignments!: Assignment[];

 @Prop() ecosystems!: Ecosystem[];

 get assignmentsFiltered(): Assignment[] {
   return this.assignments.filter(el => el.role)
 }

 private sendFilter() {
   this.$emit('filter', this.filter);
 }
}
