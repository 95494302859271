import axios from 'axios';
import UpdatePermission from './update-permission.entity';

export default class AuthorityService {
  static authorities(): Promise<string[]> {
    return axios.get('/api/me/authorities')
      .then(response => response.data);
  }

  static authotitiesPermissions(authorityName: string): Promise<any> {
    return axios.get(`/api/authority/${authorityName}/permissions`)
      .then(response => response.data);
  }

  static getAuthorityProfiles(): Promise<any> {
    return axios.get('/api/authority').then(response => response.data)
  }

  static updatePermissions(
    authorityName: string,
    permissionChanges: UpdatePermission[],
  ): Promise<void> {
    return axios.put(`/api/authority/${authorityName}/permissions`, permissionChanges);
  }
}
