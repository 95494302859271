export default class IndicatorDefaultFilter {
  indicator: string;

  description: string;

  categories: string[] = [];

  utilities: string[] = [];

  goalMeasurementTypes: string[] = [];

  active: boolean = true;

  constructor(obj: any = {}) {
    this.indicator = obj.indicator || '';
    this.description = obj.description || [];
    this.categories = obj.categories || [];
    this.utilities = obj.utilities || [];
    this.goalMeasurementTypes = obj.goalMeasurementTypes || [];
    this.active = obj.active || true;
  }

  get hasIndicator() {
    return this.indicator !== null && this.indicator !== undefined && this.indicator !== '';
  }

  get hasDescription() {
    return this.description !== null && this.description !== undefined && this.description !== '';
  }

  get hasCategories() {
    return this.categories.length > 0;
  }

  get hasUtilities() {
    return this.utilities.length > 0;
  }

  get hasGoalMeasurementTypes() {
    return this.goalMeasurementTypes.length > 0;
  }
}
