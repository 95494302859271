





















































import { Vue, Component, Prop } from 'vue-property-decorator';
import Release from '@/domain/release/entity/release.entity';

@Component
export default class ReleaseComponent extends Vue {
  @Prop() release!: Release;

  @Prop({
    type: Boolean,
    required: true,
  }) showCountdown!: boolean

  countdownRelease: string = '';

  countdownInterval: any;

  timeDifference!: number;

  updateCountdown() {
    const currentDate = new Date();
    this.timeDifference = this.release.releaseAtDate.getTime()
    - currentDate.getTime()
    if (this.timeDifference <= 0) {
      this.countdownRelease = '0d 0h 0m 0s';
      clearInterval(this.countdownInterval);
      return;
    }

    const days = Math.floor(this.timeDifference / (1000 * 60 * 60 * 24));
    const hours = Math.floor((this.timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes = Math.floor((this.timeDifference % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((this.timeDifference % (1000 * 60)) / 1000);

    this.countdownRelease = `${days}d ${hours}h ${minutes}m ${seconds}s`;
  }

  created() {
    if (this.showCountdown) {
      this.countdownInterval = setInterval(this.updateCountdown, 1000);
    }
  }

  closeRelease() {
    this.$emit('close');
  }
}
