import axios from 'axios';
import TalentLevel from '../entity/talent-level.entity';

export default class TalentLevelService {
  public static createTalentService(talentLevel: TalentLevel): Promise<void> {
    return axios.post('/api/talent-levels', talentLevel);
  }

  public static updateTalentService(talentLevel: TalentLevel): Promise<void> {
    return axios.put(`/api/talent-levels/${talentLevel.id}`, talentLevel);
  }

  public static getTalentLevelById(talentLevelId: string): Promise<TalentLevel> {
    return axios.get(`/api/talent-levels/${talentLevelId}`)
      .then(({ data }) => new TalentLevel(data));
  }

  public static getAllTalentLevels(): Promise<TalentLevel[]> {
    return axios.get('/api/talent-levels')
      .then(({ data }) => data.map(it => new TalentLevel(it)));
  }

  public static deleteTalentById(talentLevelId: string): Promise<void> {
    return axios.delete(`/api/talent-levels/${talentLevelId}`);
  }

  public static getTalentLevelByEmployeeId(employeeId: string) {
    return axios.get(`/api/employee/${employeeId}/talent-level`)
      .then(({ data }) => new TalentLevel(data));
  }
}
