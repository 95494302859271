export default [
  {
    text: 'Numérico',
    value: 'NUMERIC',
    symbol: '',
    format: (value: number): string => `${value}`,
  },
  {
    text: 'Percentual (%)',
    value: 'PERCENT',
    symbol: '%',
    format: (value: number): string => `${value}%`,
  },
  {
    text: 'Horas',
    value: 'HOURS',
    symbol: 'h',
    format: (value: number): string => `${value} horas`,
  },
  {
    text: 'Meses',
    value: 'MONTHS',
    symbol: '',
    format: (value: number): string => `${value} meses`,
  },
  {
    text: 'Reais',
    value: 'BRL',
    symbol: 'R$',
    format: (value: number): string => `R$ ${value}`,
  },
  {
    text: 'Dólares',
    value: 'USD',
    symbol: 'US $',
    format: (value: number): string => `USD ${value}`,
  },
  {
    text: 'Monetário',
    value: 'MONEY',
    symbol: '$',
    format: (value: number): string => `$ ${value}`,
  },
]
