































































import { Vue, Component } from 'vue-property-decorator';
import { VbSnackbarService } from 'bag-of-holding-library';
import VueToExcel from 'vue-json-excel';
import FilterVacationBalance from '@/domain/vacation/entity/filter-vacation-balance.entity';
import VacationService from '@/domain/vacation/service/vacation.service';
import ListVacationsBalance from '@/domain/vacation/entity/list-vacation-balance.entity';
import DateFormat from '@/shared/utils/date-format';
import FilterVacationBalanceDetail from '@/domain/vacation/entity/filter-vacation-balance-detail.entity'
import FilterVacation from './filter/filter-balance.component.vue';
import VacationBalanceList from './vacation-balance-list.component.vue';

@Component({
  components: { FilterVacation, VacationBalanceList, VueToExcel },
})
export default class VacationBalance extends Vue {
  filter: any = new FilterVacationBalance({});

  listVacationsBalance: ListVacationsBalance[] = [];

  pagination: any = {
    itemsPerPage: 10,
    page: 1,
    sortBy: ['name'],
    sortDesc: [false],
    total: 0,
    pageCount: 0,
  };

  xlsFields: any = {
    Colaborador: 'employee',
    Cargo: 'role',
    Gestor: 'manager',
    'Unidade de negócio': 'businessUnit',
    'Na DB1 desde': 'admissionDate',
    'Saldo férias': 'balance',
  }

  exportVacationDetailsFields: any = {
    Colaborador: 'employeeName',
    Gestor: 'managerName',
    Ano: 'year',
    'Inicío Per.Aquisitivo': 'startPurchasingDate',
    'Limite de Gozo': 'limitVacation',
    Início: 'startDate',
    Fim: 'endDate',
    'Qtde.dias': 'countDays',
    'Tipo de Baixa': 'vacationTypeName',
    Saldo: 'balance',
    Observação: 'observation',
  }

  loading: boolean = false;

  loadingXls: boolean = false;

  expandedItemsFromList: any[] = [];

  updateField(field: string, value: any) {
    this.filter[field] = value;
    this.findVacationBalance();
  }

  collapseList(): void {
    this.expandedItemsFromList = [];
  }

  findVacationBalance() {
    this.loading = true;
    VacationService.searchVacationsBalance(this.filter, this.pagination)
      .then((response: any) => {
        this.collapseList();
        this.listVacationsBalance = ListVacationsBalance.ofList(response.data.content);
        this.pagination.total = response.data.totalElements || 0;
        this.pagination.pageCount = response.data.totalPages;
      }).catch(VbSnackbarService.handleHttpError)
      .finally(() => this.loading = false);
  }

  updataPage(value: any) {
    if (this.pagination.itemsPerPage < value.itemsPerPage) {
      value.page = 1;
    }
    this.pagination = value;
    this.findVacationBalance();
  }

  exportToXLS(): Promise<void | ListVacationsBalance[]> {
    this.loadingXls = true;
    const pagination = {
      ...this.pagination,
      itemsPerPage: this.pagination.total,
      page: 1,
    };
    return VacationService.searchVacationsBalance(this.filter, pagination)
      .then(({ data }: any) => {
        const content: any[] = data.content.map(it => ({
          ...it,
          employeeAdmissionDate: DateFormat.dateTimeFormated(it.employeeAdmissionDate),
        }));
        return ListVacationsBalance.ofList(content);
      }).catch(VbSnackbarService.handleHttpError)
      .finally(() => this.loadingXls = false);
  }

  exportDetailsVacation() {
    this.loadingXls = true;
    return VacationService.getVacationBalanceDetails(this.filter)
      .then(({ data }) => {
        const content: any[] = data.map(it => new FilterVacationBalanceDetail(it));
        return content;
      }).catch(VbSnackbarService.handleHttpError)
      .finally(() => this.loadingXls = false);
  }

  created() {
    this.$destroy();
    this.findVacationBalance();
  }
}
