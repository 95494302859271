

























import { Vue, Component, Prop } from 'vue-property-decorator';

@Component
export default class FormationCategoryComponent extends Vue {
  @Prop({
    type: String,
    default: '',
  })
  private label!: String;

  @Prop({
    type: String,
    default: 'RECOMMENDED',
  })
  private value!: string;

  @Prop({
    type: Boolean,
    default: false,
  })
  private readonly!: boolean;

  private get selectedValue(): number {
    if (this.value === 'EXPECTED') {
      return 2;
    }
    return 1;
  }

  private get tickLabel(): string {
    if (this.selectedValue === 2) {
      return 'Esperado';
    }
    return 'Recomendado';
  }

  private updateValue(value: number): void {
    const emitValue: string = this.getEmitValue(value);
    this.$emit('input', emitValue);
  }

  private getEmitValue(value: number): string {
    if (value === 2) {
      return 'EXPECTED';
    }
    return 'RECOMMENDED';
  }
}
