















































































































































import { Vue, Component, Prop } from 'vue-property-decorator';
import { VbSnackbarService } from 'bag-of-holding-library';
import EmployeeService from '@/domain/employees/service/employee.service';
import LabelSlot from '@/shared/slots/label-slot.component.vue';
import SkillService from '@/domain/employees/service/skill.service';
import EmployeeSkill from '@/domain/employees/entity/employee-skill.entity';
import SkillType from '@/domain/employees/entity/skill-type.entity';
import Skill from '@/domain/employees/entity/skill.entity';
import ModalUpdateSkillMap from './modal/update-skill-map-modal.component.vue';
import ModalSuggestSkillMap from './modal/suggest-skill-map-modal.component.vue';

@Component({ components: { LabelSlot, ModalUpdateSkillMap, ModalSuggestSkillMap } })
export default class ProfessionalDataComponent extends Vue {
  @Prop({
    type: String,
    required: true,
  })
  private employeeId!: string;

  @Prop() hasUpdateProfessionalData!: boolean;

  private employeesSkills: EmployeeSkill[] = [];

  private hardSkills = [{ id: '', description: '' }];

  private frameworks = [{ id: '', description: '' }];

  private businessExperiences = [{ id: '', description: '' }];

  private certifications = [{ id: '', description: '' }];

  private updateSkillMapModal: boolean = false;

  private suggestSkillMapModal: boolean = false;

  private loadingProfessionalDataGet: boolean = false;

  private loadingProfessionalDataPost: boolean = false;

  checkboxesHardSkill = [{ id: '', label: '', value: false }];

  checkboxesFrameworks = [{ id: '', label: '', value: false }];

  checkboxesBusiness = [{ id: '', label: '', value: false }];

  checkboxesCertification = [{ id: '', label: '', value: false }];

  skills: Skill[] = [];

  selectedHardCheckboxes = [''];

  selectedBusinessCheckboxes = [''];

  selectedFrameworkCheckboxes = [''];

  selectedCertificationCheckboxes = [''];

  private closeUpdateSkillMapModal(): void {
    this.updateSkillMapModal = false;
  }

  openUpdateSkillMapModal(): void {
    this.updateSkillMapModal = true;
  }

  private closeSuggestSkillMapModal(): void {
    this.suggestSkillMapModal = false;
  }

  private openSuggestSkillMapModal(): void {
    this.suggestSkillMapModal = true;
  }

  private created(): void {
    this.loadSkills();
  }

  private afterSave() {
    this.hardSkills = [];
    this.frameworks = [];
    this.businessExperiences = [];
    this.certifications = [];
    this.selectedHardCheckboxes = [];
    this.selectedFrameworkCheckboxes = [];
    this.selectedBusinessCheckboxes = [];
    this.selectedCertificationCheckboxes = [];

    this.loadSkills();
  }

  private loadSkills(): void {
    this.loadingProfessionalDataGet = true;
    SkillService.getAllSkillsByEmployee(this.employeeId)
      .then((response: any) => {
        this.convertObjectSkills(response.content[0]);
        this.getAllSkills();
      })
      .finally(() => this.loadingProfessionalDataGet = false);
  }

  private convertObjectSkills(employeeSkill: EmployeeSkill) {
    if (employeeSkill && employeeSkill.allKnowledgeDTO) {
      this.hardSkills = employeeSkill.allKnowledgeDTO.hardSkills
        .map(item => ({ id: item.id, description: item.description }));

      this.frameworks = employeeSkill.allKnowledgeDTO.frameworksOrMethodologies
        .map(item => ({ id: item.id, description: item.description }));

      this.businessExperiences = employeeSkill.allKnowledgeDTO.businessExperience
        .map(item => ({ id: item.id, description: item.description }));

      this.certifications = employeeSkill.allKnowledgeDTO.certifications
        .map(item => ({ id: item.id, description: item.description }));

      this.selectedHardCheckboxes = this.hardSkills.map((skill: any) => skill.id);
      this.selectedFrameworkCheckboxes = this.frameworks.map((skill: any) => skill.id);
      this.selectedBusinessCheckboxes = this.businessExperiences.map((skill: any) => skill.id);
      this.selectedCertificationCheckboxes = this.certifications.map((skill: any) => skill.id);
    }
  }

  private getAllSkills() {
    this.loadingProfessionalDataGet = true;
    SkillService.getAllActiveAndAproved()
      .then((response: any) => {
        this.skills = response.content;
        this.convertToCheckBox();
      })
      .finally(() => this.loadingProfessionalDataGet = false);
  }

  private convertToCheckBox() {
    this.convertToHardSkill();
    this.convertToBusiness();
    this.convertToFramework();
    this.convertToCertification();
  }

  private convertToHardSkill() {
    this.checkboxesHardSkill = this.skills
      .filter(skill => skill.type === SkillType.HARD_SKILLS)
      .map(skill => ({
        id: skill.id,
        label: skill.description,
        value: false,
      }));
  }

  private convertToBusiness() {
    this.checkboxesBusiness = this.skills
      .filter(skill => skill.type === SkillType.BUSINESS_EXPERIENCE)
      .map(skill => ({
        id: skill.id,
        label: skill.description,
        value: false,
      }));
  }

  private convertToFramework() {
    this.checkboxesFrameworks = this.skills
      .filter(skill => skill.type === SkillType.FRAMEWORKS_OR_METHODOLOGIES)
      .map(skill => ({
        id: skill.id,
        label: skill.description,
        value: false,
      }));
  }

  private convertToCertification() {
    this.checkboxesCertification = this.skills
      .filter(skill => skill.type === SkillType.CERTIFICATIONS)
      .map(skill => ({
        id: skill.id,
        label: skill.description,
        value: false,
      }));
  }
}
