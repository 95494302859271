




















import { Vue, Component, Prop } from 'vue-property-decorator';

@Component
export default class ActiveCheckboxField extends Vue {
  @Prop({
    type: Boolean,
    default: false,
  })
  private value!: boolean;

  private onChange(value: boolean): void {
    this.$emit('input', value);
  }
}
