import axios from 'axios';
import IndicatorDefaultFilter from '../../entity/indicators/indicator-default-filter.entity';
import DefaultIndicator from '../../entity/default-indicator.entity';

export default class IndicatorService {
  public static getIndicatorDefaults(
    filter: IndicatorDefaultFilter,
    page: number,
    size: number,
    sort: string,
    direction: boolean,
  ): Promise<any> {
    return axios.get('/api/indicators/DEFAULT', {
      params: {
        indicator: filter.hasIndicator ? filter.indicator : null,
        description: filter.hasDescription ? filter.description : null,
        categories: filter.hasCategories ? filter.categories.toString() : null,
        utilities: filter.utilities ? filter.utilities.toString() : null,
        goalMeasurementTypes: filter.hasGoalMeasurementTypes
          ? filter.goalMeasurementTypes.toString() : null,
        active: filter.active,
        page: page - 1,
        size,
        orderby: `${sort}|${direction ? 'desc' : 'asc'}`,
      },
    })
  }

  public static createIndicatorDefault(indicator: DefaultIndicator) {
    return axios.post('/api/indicators/DEFAULT', indicator);
  }

  public static updateIndicatorDefault(indicator: DefaultIndicator) {
    return axios.put(`/api/indicators/DEFAULT/${indicator.id}`, indicator);
  }

  public static deactiveIndicatorDefault(indicator: DefaultIndicator) {
    return axios.patch(`/api/indicators/DEFAULT/${indicator.id}?active=${indicator.active}`);
  }
}
