












































































































































import { Vue, Component } from 'vue-property-decorator';
import { VbSnackbarService, VbTooltipSlot } from 'bag-of-holding-library';
import HappinessLevelService from '@/domain/happiness-level/service/happiness-level.service';
import ActivityHappiness from '@/domain/happiness-level/entity/activity-happiness.entity';
import AddButton from '@/shared/buttons/add-button.component.vue';
import FormPDI from '@/domain/activity/entity/form-pdi.entity';
import ModalWarning from '@/shared/modal/modal-warning.component.vue';
import ActionPlan from '@/domain/activity/entity/action-plan.entity';
import EmployeeService from '@/domain/employees/service/employee.service';
import Employee from '@/domain/employees/entity/employee.entity';
import PdiService from '@/domain/pdi/pdi.service';
import Priority from '@/domain/activity/entity/priority.entity';
import CopyClipboardService from '@/shared/utils/copy-clipboard.service';
import LabelSlot from '@/shared/slots/label-slot.component.vue';
import { PlanState } from '@/domain/pdi/entity/plan-state';
import PlanPriorityLegendComponent from '@/shared/plan-priority/plan-priority.component.vue';
import ActivityService from '@/domain/schedule/activitie/activity.service';
import Reason from '@/domain/schedule/activitie/reason.entity';
import ModalOptions from '@/shared/modal/modal-options.entity';
import NotFoundComponent from '@/shared/not-found/not-found.component.vue';
import AuthorityStore from '@/domain/security/authority.store';
import Authority from '@/domain/security/authority.entity';
import ActionPlanComponent from '../common/action-plan.component.vue';
import ActivityUtils from '../common/activity-utils';
import AllPlansModal from '../common/all-plan-modal.component.vue';
import ActivityHeaderComponent from '../common/activity-header.component.vue';
import FormPlanModal from '../common/form-plan-modal.component.vue';
import PdiPlanList from '../common/pdi-plan-list.component.vue';

@Component({
  components: {
    AddButton,
    ActionPlanComponent,
    ModalWarning,
    VbTooltipSlot,
    AllPlansModal,
    ActivityHeaderComponent,
    LabelSlot,
    FormPlanModal,
    PdiPlanList,
    PlanPriorityLegendComponent,
    NotFoundComponent,
  },
})
export default class CreatePDIIndex extends Vue {
  activity: ActivityHappiness = new ActivityHappiness();

  formPdi: FormPDI = new FormPDI();

  employee: Employee = new Employee({});

  loading: boolean = true;

  activityUtils: ActivityUtils = new ActivityUtils();

  allPlans: ActionPlan[] = [];

  planState = PlanState;

  planStateFilterSelected: string = this.planState.OPEN;

  priorityItems: Priority[] = [];

  openFormPlanModal: boolean = false;

  planIndex: number = 0;

  loadingCreatePlan: boolean = false;

  loadingUpdatePlan: boolean = false;

  loadingConludeActivity: boolean = false;

  showModalWarning: boolean = false;

  loadingAllPlans: boolean = false;

  hasCreatePdiPlan: boolean = false;

  hasUpdatePdiPlan: boolean = false;

  hasDeletePdiPlan: boolean = false;

  authority!: Authority;

  get modalWarningOptions(): ModalOptions {
    return {
      title: 'Atenção',
      description: 'Após finalizar a atividade, não será possível criar planos para essa atividade. Deseja continuar?',
      showOk: true,
      disabledOk: false,
      labelOk: 'Sim, continuar',
      loadingOk: this.loadingConludeActivity,
      okColor: 'tabBlue',
      showCancel: true,
      labelCancel: 'Cancelar',
      width: 400,
    };
  }

  async loadActivity(): Promise<void> {
    try {
      const activity: ActivityHappiness = await HappinessLevelService
        .getActivity(this.$route.params.activityIdOfPdi)
      this.activity = activity;
    } catch (error) {
      VbSnackbarService.handleHttpError(error)
    }
  }

  async created() {
    this.$destroy();
    this.loading = true;
    this.validatePDIAuthorities();
    await this.loadActivity();
    await this.getEmployee();
    this.activityUtils = new ActivityUtils(this.employee);
    const havePermissionAccess = await this.activityUtils.checkUserAuthority();
    if (!havePermissionAccess) {
      this.$router.push('/denied');
      return;
    }
    this.loading = false;
    this.getAllPlansByState();
    this.getAllPriority();
  }

  async validatePDIAuthorities() {
    await AuthorityStore.fetchedAuthority().then((authority) => {
      this.authority = authority;
    });
    this.hasCreatePdiPlan = this.authority.hasCreatePdiPlan();
    this.hasUpdatePdiPlan = this.authority.hasUpdatePdiPlan();
    this.hasDeletePdiPlan = this.authority.hasDeletePdiPlan();
  }

  cancel(): void {
    window.history.go(-1)
  }

  getAllPlansByState() {
    this.loadingAllPlans = true;
    PdiService.getAllPlansByEmployeeUsername(
      this.activity.employee.username,
      this.planStateFilterSelected,
    ).then(response => this.allPlans = response)
      .finally(() => this.loadingAllPlans = false);
  }

  copyLink() {
    CopyClipboardService.copyToClipboard(`${process.env.VUE_APP_REDIRECT_URL}activity/${this.$route.params.activityIdOfPdi}/pdi`);
  }

  async getEmployee() {
    await EmployeeService.getEmployeeByUsername(this.activity.employee.username)
      .then((response: Employee) => {
        this.employee = response;
      })
      .catch(error => VbSnackbarService.handleHttpError(error));
  }

  changePlanStateFilter(value: string) {
    this.planStateFilterSelected = value;
    this.getAllPlansByState();
  }

  getAllPriority() {
    PdiService.getAllPriority().then(response => this.priorityItems = response);
  }

  updatePlanField(eventData: any) {
    this.allPlans[eventData.index][eventData.field] = eventData.value;
  }

  openFormPlanModalEditMode(plan: ActionPlan) {
    this.planIndex = this.allPlans.indexOf(plan);
    this.openFormPlanModal = true;
  }

  showFormPlanModal() {
    this.openFormPlanModal = true;
  }

  newPlan() {
    this.allPlans.push(ActionPlan.ofActivity(this.activity.id));
    this.planIndex = this.allPlans.length - 1;
    this.showFormPlanModal();
  }

  createPlan(plan: ActionPlan) {
    this.loadingCreatePlan = true;
    PdiService.create(plan, this.activity.employee.username)
      .then(() => VbSnackbarService.showSuccess('Plano criado com sucesso!'))
      .catch(error => VbSnackbarService.handleHttpError(error))
      .finally(() => {
        this.loadingCreatePlan = false;
        this.openFormPlanModal = false;
        this.getAllPlansByState();
      })
  }

  updatePlan(plan: ActionPlan) {
    this.loadingUpdatePlan = true;
    PdiService.update(plan, this.activity.employee.username)
      .then(() => VbSnackbarService.showSuccess('Plano atualizado com sucesso!'))
      .catch(error => VbSnackbarService.handleHttpError(error))
      .finally(() => {
        this.loadingUpdatePlan = false;
        this.openFormPlanModal = false;
        this.getAllPlansByState();
      })
  }

  conclude() {
    this.loadingConludeActivity = true;
    ActivityService.approveAndSetExecutionDate(this.activity.id, new Reason('Aprovação automática'))
      .then((response) => {
        this.activity = response;
        VbSnackbarService.showSuccess('Atividade finalizada!')
      }).catch(error => VbSnackbarService.handleHttpError(error)).finally(() => {
        this.loadingConludeActivity = false;
        this.closeModalWarning();
      })
  }

  closeModalWarning() {
    this.showModalWarning = false;
  }

  openModalWarning() {
    this.showModalWarning = true;
  }

  getOnSaveRedirectPath(): string {
    return this.activityUtils.isLoggedUserProfile ? '/' : '/schedule';
  }

  redirectUser() {
    this.$router.push({ path: this.getOnSaveRedirectPath() });
  }

  get showConcludeButton() {
    return this.activity.isADM() || this.activity.isApproved();
  }
}
