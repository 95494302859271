import { Currency } from '@/domain/employees/entity/professional-data.entity';
import { CurrencyType } from '@/views/employees/profile/tab-components/score/score-utils';

export default class CurrencyFormater {
  public static toCurrency(value: number, currency: Currency) {
    const formatter = new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: currency != null ? currency.name : CurrencyType.BRL,
      minimumFractionDigits: 2,
    });
    return formatter.format(value);
  }
}
