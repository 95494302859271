export default class EmployeeWage {
  lastBuild: string;

  nextBuild: string;

  wage: number;

  costHelp: number;

  observationProfile: string;

  constructor(obj: any = {}) {
    this.lastBuild = obj.lastBuild;
    this.nextBuild = obj.nextBuild;
    this.wage = obj.wage;
    this.costHelp = obj.costHelp;
    this.observationProfile = obj.observationProfile;
  }
}
