

























































































import { Vue, Component, Prop } from 'vue-property-decorator';
import PlanType from '@/domain/activity/entity/plan-types.entity';
import Priority from '@/domain/activity/entity/priority.entity';
import ActionPlan from '@/domain/activity/entity/action-plan.entity';
import PdiService from '@/domain/pdi/pdi.service';
import NotFoundComponent from '@/shared/not-found/not-found.component.vue';
import PlanPriorityLegendComponent from '@/shared/plan-priority/plan-priority.component.vue';
import { VbSnackbarService } from 'bag-of-holding-library';
import PdiPlanList from '@/views/activity/common/pdi-plan-list.component.vue';
import { PlanState } from '@/domain/pdi/entity/plan-state';
import FormPlanModal from '@/views/activity/common/form-plan-modal.component.vue';

@Component({
  components: {
    NotFoundComponent,
    PlanPriorityLegendComponent,
    PdiPlanList,
    FormPlanModal,
  },
})
export default class AllPlansView extends Vue {
  @Prop({
    type: Boolean,
    default: false,
  }) hasUpdatePdiPlan!: boolean;

  @Prop({
    type: Boolean,
    default: false,
  }) hasDeletePdiPlan!: boolean;

  @Prop({
    type: Boolean,
    default: false,
  }) isLoggedUserProfile!: boolean;

  planTypes: PlanType[] = [];

  priorityItems: Priority[] = [];

  allPlans: ActionPlan[] = [];

  loading: boolean = false;

  planUpdate: ActionPlan[] = [];

  planState = PlanState;

  planStateFilterSelected: string = this.planState.OPEN;

  loadingCreatePlan: boolean = false;

  loadingUpdatePlan: boolean = false;

  planIndex: number = 0;

  openFormPlanModal: boolean = false;

  employeeUsername: string = this.$route.params.username;

  updatePlanField(eventData: any) {
    this.allPlans[eventData.index][eventData.field] = eventData.value;
  }

  created() {
    this.getPlanTypes();
    this.getAllPriority();
    this.getAllPlans();
  }

  getPlanTypes() {
    PdiService.getPlanTypes().then(response => this.planTypes = response);
  }

  getAllPriority() {
    PdiService.getAllPriority().then(response => this.priorityItems = response);
  }

  getAllPlans() {
    this.loading = true;
    PdiService.getAllPlansByEmployeeUsername(
      this.$route.params.username,
      this.planStateFilterSelected,
    )
      .then((response) => {
        this.allPlans = response;
      }).finally(() => this.loading = false);
  }

  openFormPlanModalEditMode(plan: ActionPlan) {
    this.planIndex = this.allPlans.indexOf(plan);
    this.openFormPlanModal = true;
  }

  createPlan(plan: ActionPlan) {
    this.loadingCreatePlan = true;
    PdiService.create(plan, this.employeeUsername)
      .then(() => VbSnackbarService.showSuccess('Plano criado com sucesso!'))
      .catch(error => VbSnackbarService.handleHttpError(error))
      .finally(() => {
        this.loadingCreatePlan = false;
        this.openFormPlanModal = false;
        this.getAllPlans();
      })
  }

  updatePlan(plan: ActionPlan) {
    this.loadingUpdatePlan = true;
    PdiService.update(plan, this.employeeUsername)
      .then(() => {
        VbSnackbarService.showSuccess('Plano atualizado com sucesso!');
        this.openFormPlanModal = false;
        this.getAllPlans();
      })
      .catch((error) => {
        VbSnackbarService.handleHttpError(error)
      }).finally(() => this.loadingUpdatePlan = false)
  }

  showFormPlanModal() {
    this.openFormPlanModal = true;
  }

  newPlan() {
    this.allPlans.push(new ActionPlan());
    this.planIndex = this.allPlans.length - 1;
    this.showFormPlanModal();
  }

  closeFormPlanModal() {
    this.openFormPlanModal = false;
    this.getAllPlans();
  }
}
