

























import { Vue, Component, Prop } from 'vue-property-decorator';

@Component
export default class SeniorityLevelComponent extends Vue {
  @Prop({
    type: String,
    default: '',
  })
  private label!: String;

  @Prop({
    type: String,
    default: 'NOVICE',
  })
  private value!: string;

  @Prop({
    type: Boolean,
    default: false,
  })
  private readonly!: boolean;

  private get selectedValue(): number {
    if (this.value === 'PRACTICING') {
      return 2;
    }
    if (this.value === 'PROFESSIONAL') {
      return 3;
    }
    if (this.value === 'EXPERT') {
      return 4;
    }
    return 1;
  }

  private get tickLabel(): string {
    if (this.selectedValue === 2) {
      return 'Praticante';
    }
    if (this.selectedValue === 3) {
      return 'Profissional';
    }
    if (this.selectedValue === 4) {
      return 'Expert';
    }
    return 'Novato';
  }

  private updateValue(value: number): void {
    const emitValue: string = this.getEmitValue(value);
    this.$emit('input', emitValue);
  }

  private getEmitValue(value: number): string {
    if (value === 2) {
      return 'PRACTICING';
    }
    if (value === 3) {
      return 'PROFESSIONAL';
    }
    if (value === 4) {
      return 'EXPERT';
    }
    return 'NOVICE';
  }
}
