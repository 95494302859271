
















import {
  Prop, Component, Mixins,
} from 'vue-property-decorator';
import { VbSnackbarService } from 'bag-of-holding-library';
import Modal from '@/shared/modal/modal.component.vue';
import ModalOptions from '@/shared/modal/modal-options.entity';
import ModalMixin from '@/shared/modal/modal.mixin';
import Reason from '@/domain/schedule/activitie/reason.entity';
import EmployeeService from '@/domain/schedule/employee/employee.service';
import FormRestartSchedule from './form-restart-schedule.component.vue';

@Component({
  components: {
    'modal-component': Modal,
    'form-restart-schedule': FormRestartSchedule,
  },
})
export default class ModalRestartSchedule extends Mixins(ModalMixin) {
  private get restartScheduleModalOptions(): ModalOptions {
    return {
      title: 'Reiniciar Cronograma',
      description: 'Ao clicar em reiniciar, a alteração será imediatamente concluída. Antes de salvar,<strong> verifique se a informação está correta.</strong>',
      showOk: true,
      disabledOk: this.disableRestartButton,
      labelOk: 'Reiniciar',
      showCancel: true,
      labelCancel: 'Cancelar',
      width: 700,
    };
  }

  private reasonObject: Reason = new Reason('');

  private handleTypeReasonEvent(value: string): void {
    this.reasonObject.reason = value;
  }

  private restartSchedule(): void {
    EmployeeService
      .restartEmployeeSchedule(this.employeeId, this.reasonObject)
      .then(() => this.closeModal())
      .catch((error: any) => VbSnackbarService.handleHttpError(error));
  }

  private get disableRestartButton(): boolean {
    return this.reasonObject.reason === '';
  }
}
