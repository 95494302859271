




























































































































































































































import {
  Vue, Component, Prop,
} from 'vue-property-decorator';
import { VbSnackbarService } from 'bag-of-holding-library';
import PaginationComponent from '@/shared/pagination.component.vue';
import LabelSlot from '@/shared/slots/label-slot.component.vue';
import MainButton from '@/shared/buttons/main-button.component.vue';
import MobileHandler from '@/shared/utils/mobile-handler';
import splitContent from '@/shared/directive/split-content';
import DateHandler from '@/shared/utils/date-format';
import FilteredEmployeeActivity from '@/domain/employees/entity/filtered-employee-activity.entity';
import EmployeeService from '@/domain/employees/service/employee.service';
import Response from '@/domain/employees/entity/response.entity';
import AuthorityStore from '@/domain/security/authority.store';
import TalentLevelLabel from '@/shared/talent-level-label.component.vue'
import AuthorityEnum from '@/domain/security/authority.enum';
import Header from './header.json';
import ObservationModal from './observation-modal.component.vue';
import ActivityHappinessResponseModal from './acticity-happiness-response-modal.component.vue';

@Component({
  components: {
    PaginationComponent,
    LabelSlot,
    MainButton,
    ObservationModal,
    TalentLevelLabel,
    ActivityHappinessResponseModal,
  },
  filters: {
    splitContent,
    formatDate(value: string) {
      return DateHandler.timestampToLocal(value.split('T')[0]);
    },
  },
})
export default class EmployeeActivitiesTableComponent extends Vue {
  @Prop() employeeId!: string;

  @Prop({
    type: Boolean,
    default: false,
  })
  isLoggedUserProfile!: boolean;

  @Prop({
    type: Boolean,
    default: false,
  })
  showActivityButtons!: boolean;

  activities: FilteredEmployeeActivity[] = [];

  header = Header;

  loading: boolean = false;

  isMobile: boolean = false;

  showObservationModal: boolean = false;

  observation: string = '';

  hasTalentLevelTableColumnRead: boolean = false;

  showResponsesModal: boolean = false;

  activitySelectedId: string = '';

  activitySelectedName: string = '';

  pagination: any = {
    itemsPerPage: 10,
    page: 1,
    total: 0,
    pageCount: 0,
  }

  hasReadActivityHappiness: boolean = false;

  created() {
    this.getActivities();
    this.validateAuthorities();
    this.getHasTalentLevelTableColumnRead().then(() => this.filterHeader(this.header));
  }

  openObservationModal(observation: string) {
    this.observation = observation;
    this.showObservationModal = true;
  }

  updatePagination(pagination: any): void {
    if (this.pagination.itemsPerPage < pagination.itemsPerPage) {
      pagination.page = 1;
    }
    this.pagination = pagination;
    this.getActivities();
  }

  onResize() {
    this.isMobile = MobileHandler.isMobile();
  }

  getActivities() {
    this.loading = true;
    const id = this.$route.params.id!!
    EmployeeService.getEmployeeActivities(this.employeeId,
      this.pagination.page, this.pagination.itemsPerPage)
      .then((response: Response) => {
        this.activities = FilteredEmployeeActivity.ofList(response.content);
        this.pagination.total = response.totalElements || 0;
        this.pagination.pageCount = response.totalPages;
      })
      .catch((error: any) => VbSnackbarService.handleHttpError(error))
      .finally(() => this.loading = false);
  }

  redirectToHappinessView(activityId: string) {
    if (!this.isLoggedUserProfile) return;
    const url = `/activity/${activityId}/happiness?lang=PT_BR`;
    const routeData = this.$router.resolve({
      path: url,
    });
    window.open(routeData.href, '_blank');
  }

  openActivity(value: string, description: string): void {
    if (this.isPDI(description)) {
      this.$router.push({
        path: `/activity/${value}/pdi`,
      })
      return;
    }

    if (this.isFeed(description)) {
      this.$router.push({
        path: `/activity/${value}/feedback`,
      })
      return;
    }

    this.$router.push({
      path: `/score-schedule/${value}/${this.employeeId}`,
    })
  }

  isScore(description: string): boolean {
    return description === 'Contagem de Pontos';
  }

  isPDI(description: string): boolean {
    return description === 'PDI de Entrada' || description === 'Revisão de PDI' || description === 'ADM';
  }

  isFeed(description: string): boolean {
    return description === 'Feedback Efetivação' || description === 'Feed 45';
  }

  pdiFeedOrScore(description: string): boolean {
    return this.isPDI(description) || this.isScore(description) || this.isFeed(description);
  }

  verifyDisabled(item: FilteredEmployeeActivity): boolean {
    return !(this.pdiFeedOrScore(item.type.description) && item.hasActivity);
  }

  async getHasTalentLevelTableColumnRead() {
    this.hasTalentLevelTableColumnRead = (await AuthorityStore.isAdministrator()
    || await AuthorityStore.isManager()
    || await AuthorityStore.isBusinnesPartner()) && !this.isLoggedUserProfile
  }

  filterHeader(header: any) {
    this.header = header.filter((column) => {
      if (column.text === 'Nível de Talento') {
        return this.hasTalentLevelTableColumnRead;
      }
      return true;
    })
  }

  openHappinessModal(activityId: string, activityName: string) {
    this.activitySelectedId = activityId;
    this.activitySelectedName = activityName;
    this.showResponsesModal = true;
  }

  validateAuthorities() {
    AuthorityStore.hasAuthority([AuthorityEnum.READ_ACTIVITY_HAPPINESS_RESPONSE])
      .then(response => this.hasReadActivityHappiness = response);
  }

  get readActivityHappiness() {
    return this.hasReadActivityHappiness;
  }
}
