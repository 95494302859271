
















































































import { Vue, Component, Prop } from 'vue-property-decorator';
import { VbSnackbarService, VbTooltipSlot } from 'bag-of-holding-library';
import ModalComponent from '@/shared/modal/modal.component.vue';
import ModalOptions from '@/shared/modal/modal-options.entity';
import LabelSlot from '@/shared/slots/label-slot.component.vue';
import UpdateAtribute from '@/domain/employees/entity/update-atribute.entity';
import Rules from '@/shared/rules/rules';
import ResignationStaff from '@/domain/resignation-staff/entity/resignation-staff.entity';
import ResignationStaffService from '@/domain/resignation-staff/service/resignation-staff.service';
import StaffList from '@/domain/resignation-staff/entity/staff-list.entity';
import CareerService from '@/domain/employees/service/career.service';
import StaffListRequest from '@/domain/resignation-staff/entity/staff-list-request.entity';

  @Component({
    components: { ModalComponent, LabelSlot, VbTooltipSlot },
  })

export default class ResignationStaffModal extends Vue {
  @Prop() show!: boolean;

  @Prop() showResignationStaffModal!: boolean;

  valid: boolean = false;

  rules: Rules = new Rules();

  role: any = '';

  loading = false;

  resignationStaffList: StaffList = StaffList.of({});

  resultCenters: UpdateAtribute[] = [];

  close() {
    this.$emit('close');
  }

  private get optionsHandled(): ModalOptions {
    return {
      title: 'Dados de desligamento',
      titleSize: '25px',
      disabledOk: !this.valid,
      description: '',
      showOk: true,
      labelOk: 'Salvar',
      okColor: 'tabBlue',
      showCancel: !this.loading,
      labelCancel: 'Cancelar',
      width: this.hasAnyResultCenter() ? 1350 : 650,
      loadingOk: this.loading,
    };
  }

  created() {
    this.getStaff();
  }

  addStaffEmail() {
    this.resignationStaffList.addNewStaffEmail();
  }

  hasAnyResultCenter() {
    return this.resignationStaffList.staffList.some(el => el.hasResultCenter)
  }

  removeStaff(item: ResignationStaff) {
    const index = this.resignationStaffList.staffList.indexOf(item);
    this.resignationStaffList.staffList.splice(index, 1);
  }

  getStaff() {
    ResignationStaffService.findStaffEmails()
      .then((resp: any) => {
        this.resignationStaffList.staffList = resp;
        this.fetchPossibleResultCenters();
      });
  }

  fetchPossibleResultCenters() {
    this.resignationStaffList.staffList.forEach((el: ResignationStaff) => {
      el.resultCenters.forEach((resultCenter:UpdateAtribute) => {
        if (resultCenter.name) {
          this.getResultCenters(resultCenter.name, el);
        }
      })
    })
  }


  getResultCenters(value: string, staff: ResignationStaff) {
    if (value && value.length >= 3 && value !== staff.lastFilteredName) {
      CareerService.getResultCentersByName(value)
        .then((resp: UpdateAtribute[]) => {
          staff.addResultCenters(resp)
          staff.lastFilteredName = value;
        });
    }
  }

  checkResultCenter(staff: ResignationStaff) {
    staff.checkHasResultCenterState();
  }

  save() {
    if (!this.resignationStaffList.validateDuplicatedStaff()) {
      VbSnackbarService.showError('Existem e-mails duplicados!')
      return;
    }
    this.loading = true;
    const request = StaffListRequest.of(this.resignationStaffList);
    ResignationStaffService.saveStaff(request)
      .then((resp: any) => {
        VbSnackbarService.showSuccess('E-mails salvos com sucesso!');
        this.close();
      })
      .catch((err: any) => VbSnackbarService.handleHttpError(err))
      .finally(() => this.loading = false);
  }
}
