




































import { Vue, Component, Prop } from 'vue-property-decorator';
import SelectionComponent from '@/shared/fields/selection.componet.vue';
import LabelSlot from '@/shared/slots/label-slot.component.vue';
import DrdService from '@/domain/drd/service/drd.service';
import Assignment from '@/domain/drd/entity/assignment.entity';

@Component({
  components: {
    SelectionComponent,
    LabelSlot,
  },
})
export default class AssignmentFilter extends Vue {
  @Prop({
    type: String,
    default: 'Papel',
  }) label!: string;

  @Prop({
    type: String,
    default: 'Digite o nome...',
  }) placeholder!: string;

  @Prop({
    type: String,
    default: 'Nenhum papel foi encontrado...',
  }) noDataText!: string;

  assignmentsSelected: string[] = [];

  foundAssignments: Assignment[] = [];

  loading: boolean = false;

  lastFilterAssignment: string = '';

  assignmentName: string = '';

  selected() {
    this.$emit('selected', this.assignmentsSelected);
  }

  findAssignments(value: string) {
    if ((this.lastFilterAssignment !== value) && value) {
      this.lastFilterAssignment = value;
      if (value.length > 2) {
        this.loading = true;
        DrdService.getAssigments(value)
          .then(data => this.foundAssignments = data)
          .finally(() => this.loading = false);
      }
    }
  }
}
