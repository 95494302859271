
























































import { Vue, Component } from 'vue-property-decorator';
import PerformanceDescriptionTableReadHeader from './performance-description-table-read-header.component.vue';
import TabsComponent from '../commons/performance-description-tabs.component.vue';
import IndicatorsComponent from './indicators/index.vue';
import AreaKeyComponent from './area-key/index.vue';
import BehavioralSkillComponent from './behavioral-skill/index.vue';
import TechnicalKnowledgeComponent from './technical-knowledge/index.vue';
import FormationComponent from './formation/index.vue';

@Component({
  components: {
    AreaKeyComponent,
    BehavioralSkillComponent,
    FormationComponent,
    IndicatorsComponent,
    PerformanceDescriptionTableReadHeader,
    TabsComponent,
    TechnicalKnowledgeComponent,
  },
})
export default class PerformanceDescriptionReadComponent extends Vue {
  performanceDescriptionId: string = '';

  selectedTab: number = 0;

  goToPreviousTab(): void {
    if (this.selectedTab === 0) {
      this.goToListPage();
      return;
    }
    this.selectedTab -= 1;
  }

  goToNextTab(): void {
    if (this.isLastTab()) {
      this.goToListPage();
      return;
    }
    this.selectedTab += 1;
  }

  get forwardButtonLabel(): string {
    if (this.isLastTab()) return 'Fechar';
    return 'Avançar';
  }

  isLastTab(): boolean {
    return this.selectedTab === 4;
  }

  goToListPage(): void {
    this.$router.push({ path: '/performance-description-list' });
  }

  created(): void {
    this.$destroy();
    this.performanceDescriptionId = this.$route.params.id;
  }
}
