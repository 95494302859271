export default class PlanType {
  value: string;

  description: string;

  active: boolean;

  constructor(obj: any = {}) {
    this.value = obj.value || '';
    this.description = obj.description || '';
    this.active = obj.active || '';
  }
}
