var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{staticClass:"indicator-list__data-table",attrs:{"items":_vm.indicators,"headers":_vm.headers,"loading":_vm.loading,"page":_vm.pagination.page,"sort-by":_vm.pagination.sortBy,"sort-desc":_vm.pagination.sortDesc,"items-per-page":_vm.pagination.itemsPerPage,"hide-default-header":_vm.isMobile,"page-count":_vm.pagination.pageCount,"hide-default-footer":"","no-data-text":"Nenhum indicador encontrado...","loading-text":"Carregando..."},on:{"update:sortBy":function($event){return _vm.$set(_vm.pagination, "sortBy", $event)},"update:sort-by":[function($event){return _vm.$set(_vm.pagination, "sortBy", $event)},_vm.updateSortBy],"update:sortDesc":function($event){return _vm.$set(_vm.pagination, "sortDesc", $event)},"update:sort-desc":[function($event){return _vm.$set(_vm.pagination, "sortDesc", $event)},_vm.updateSortDesc],"update:itemsPerPage":function($event){return _vm.$set(_vm.pagination, "itemsPerPage", $event)},"update:items-per-page":function($event){return _vm.$set(_vm.pagination, "itemsPerPage", $event)},"update:pageCount":function($event){return _vm.$set(_vm.pagination, "pageCount", $event)},"update:page-count":function($event){return _vm.$set(_vm.pagination, "pageCount", $event)},"page-count":function($event){_vm.pagination.pageCount=$event}},scopedSlots:_vm._u([{key:"item.goalMeasurementUnit",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.goalMeasumentTypes[item.goalMeasurementUnit])+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"indicator-list__actions-container d-flex align-center justify-center"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"black","icon":""},on:{"click":function($event){return _vm.showReadModal(item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-eye-outline")])],1)]}}],null,true)},[_c('span',[_vm._v("Visualizar")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary","icon":"","disabled":!_vm.hasUpdateIndicatorDefault},on:{"click":function($event){return _vm.openUpdateModal(item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-pencil-outline")])],1)]}}],null,true)},[_c('span',[_vm._v("Editar")])]),_c('vb-tooltip-slot',{attrs:{"content":item.actve ? 'Ativar' : 'Inativar'}},[_c('v-checkbox',{staticClass:"mt-0",attrs:{"color":"success","hide-details":"","disabled":!_vm.hasInactiveIndicatorDefault},on:{"change":function($event){return _vm.showModalWarning(item)}},model:{value:(item.active),callback:function ($$v) {_vm.$set(item, "active", $$v)},expression:"item.active"}})],1),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"warning","icon":"","oncontextmenu":"return false;"},on:{"contextmenu":function($event){return _vm.copyToClipboard(item.linkBpg)},"click":function($event){return _vm.openLink(item.linkBpg)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-open-in-new")])],1)]}}],null,true)},[_c('span',[_vm._v("Abrir link BPG "),_c('br'),_vm._v(" Clique com o botão direito para copiar")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"purple","icon":"","oncontextmenu":"return false;"},on:{"contextmenu":function($event){return _vm.copyToClipboard(item.linkGmo)},"click":function($event){return _vm.openLink(item.linkGmo)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-open-in-new")])],1)]}}],null,true)},[_c('span',[_vm._v("Abrir link GMO "),_c('br'),_vm._v(" Clique com o botão direito para copiar")])])],1)]}}],null,true)}),_c('pagination-component',{staticClass:"mt-8 ml-2",attrs:{"pagination":_vm.pagination},on:{"input":_vm.updateTable}}),_c('create-or-update-indicator-default-modal',{attrs:{"openModal":_vm.openModal,"indicator":_vm.indicatorUpdated},on:{"closeModal":_vm.closeModal}}),_c('read-indicator-default-modal',{attrs:{"readIndicator":_vm.readIndicator,"openModal":_vm.openReadModal},on:{"closeModal":_vm.closeReadModal}}),_c('modal-warning',{attrs:{"value":_vm.openModalWarning},on:{"ok":_vm.activeOrInactive,"cancel":_vm.closeModalWarningWithCancelBtn}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }