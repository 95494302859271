













import { Vue, Prop, Component } from 'vue-property-decorator';

@Component
export default class HomeCaption extends Vue {
  @Prop({
    required: true,
  })
  private name!: string;

  @Prop({
    required: true,
  })
  private colaborators!: number;

  @Prop({
    required: true,
  })
  private color!: string;
}
