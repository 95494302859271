



































































































































































import {
  Vue,
  Component,
  Prop,
} from 'vue-property-decorator';
import DatePickerField from '@/shared/date-picker/date-picker-field.component.vue';
import LabelSlot from '@/shared/slots/label-slot.component.vue';
import EmployeeSummary from '@/domain/schedule/employee/employee-summary.entity';
import { VbSnackbarService } from 'bag-of-holding-library';
import Rules from '@/shared/rules/rules';
import EmployeeService from '@/domain/schedule/employee/employee.service';
import VacationPersist from '@/domain/vacation/entity/vacation-persist.entity'
import TooltipIcon from '@/shared/tooltip-icon.component.vue';
import VacationService from '@/domain/vacation/service/vacation.service';
import VacationType from './vacation-type.json'

@Component({
  components: {
    LabelSlot, DatePickerField, TooltipIcon,
  },
})
export default class VacationModal extends Vue {
  @Prop({
    type: Boolean,
    default: false,
  })
  private value!: boolean;

  @Prop({
    type: Boolean,
    default: false,
  })
  private isUpdate!: boolean;

  @Prop({
    type: String,
    default: '',
  })
  private employeeName?: string

  @Prop({
    type: VacationPersist,
  })
  private vacation!: VacationPersist;

  rules: Rules = new Rules();

  loading = false;

  refs: any = this.$refs

  vacationType = VacationType;

  private employees: EmployeeSummary[] = [];

  private lastFilterEmployee: string = '';

  persist() {
    if (this.isUpdate) {
      this.update();
      return;
    }
    this.save();
  }

  save() {
    if (this.refs.form.validate()) {
      this.loading = true;
      VacationService.newVacation(this.vacation)
        .then(() => {
          VbSnackbarService.showSuccess('Férias salvas com sucesso!');
          this.closeDialogAndRefresh();
        })
        .catch((err: any) => VbSnackbarService.handleHttpError(err))
        .finally(() => this.loading = false)
    }
  }

  update() {
    if (this.refs.form.validate()) {
      this.loading = true;
      VacationService.updateVacation(this.vacation.id, this.vacation.period, this.vacation)
        .then(() => {
          VbSnackbarService.showSuccess('Férias atualizadas com sucesso!');
          this.loading = false;
          this.closeDialogAndRefresh();
        })
        .catch((err: any) => VbSnackbarService.handleHttpError(err))
    }
  }

  closeDialog(): void {
    this.$emit('input', false);
  }

  private closeDialogAndRefresh(): void {
    this.closeDialog();
    this.$emit('refresh');
  }

  setDaysToZero() {
    this.vacation.days = null;
  }

  findEmployeesByName(value: string) {
    if ((this.lastFilterEmployee !== value) && value) {
      this.lastFilterEmployee = value;
      if (value.length > 2) {
        EmployeeService.findEmployeesByName(value)
          .then((resp: EmployeeSummary[]) => this.employees = resp);
      }
    }
  }
}

