import { render, staticRenderFns } from "./error.component.vue?vue&type=template&id=f1ecee84&scoped=true&"
import script from "./error.component.vue?vue&type=script&lang=ts&"
export * from "./error.component.vue?vue&type=script&lang=ts&"
import style0 from "./error.component.vue?vue&type=style&index=0&id=f1ecee84&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f1ecee84",
  null
  
)

export default component.exports