import axios from 'axios';
import Assignment from '../entity/assignment.entity';
import FilterAssignment from '../entity/filter.assignment.entity';

export default class AssignmentService {
  public static async getAssignment(filter: FilterAssignment): Promise<Assignment[]> {
    return axios.get('/api/assignments', {
      params: {
        ...filter,
      },
    }).then(({ data }: any) => data);
  }
}
