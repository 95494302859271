























import { Vue, Component, Prop } from 'vue-property-decorator';
import LabelSlot from '@/shared/slots/label-slot.component.vue';
import Rules from '@/shared/rules/rules';
import SkillTypes from '../skill-types.json'

@Component({
  components: {
    LabelSlot,
  },
})
export default class SelectSkillType extends Vue {
  @Prop({
    required: true,
  }) skillType!: string | string[];

  @Prop({
    type: Boolean,
    default: false,
  }) multiple!: boolean;

  @Prop() rules!: Rules;

  skillTypes = SkillTypes;

  update(typeSelected: string) {
    this.$emit('update', typeSelected);
  }
}
