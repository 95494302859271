import RouteCheckerTo from '@/views/route-checker';
import ScoreSchedule from './score-schedule.view.vue';

export default [
  {
    path: '/score-schedule/:activityId/:employeeId',
    component: ScoreSchedule,
    name: 'Acordo Salarial',
    beforeEnter: RouteCheckerTo.hasAccessReadEmployeeRemuneration,
  },
];
