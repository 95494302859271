












import { Vue, Component, Prop } from 'vue-property-decorator';
import HappinessLevelEntity from '@/domain/happiness-level/entity/happiness-level.entity';
import { VbTooltipSlot } from 'bag-of-holding-library';

@Component({ components: { VbTooltipSlot } })
export default class HappinessSelector extends Vue {
  @Prop() happinessLevel!: HappinessLevelEntity;

  @Prop() hasSelected!: boolean;

  click(): void {
    this.$emit('click', this.happinessLevel.value)
  }

  getEmojiUrl(emoji) {
    // eslint-disable-next-line import/no-dynamic-require, global-require
    return require(`@/assets/images/${emoji}.svg`);
  }
}
