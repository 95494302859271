import ActivityDetailed from '../activitie/activity-detailed.entity';
import DateFormat from '../../../shared/utils/date-format';

export default class EmployeeDateActivities {
  private id: string;

  private name: string;

  private referenceDate: string;

  private activities: ActivityDetailed[];

  constructor(obj: any = {}) {
    this.id = obj.id || '';
    this.name = obj.name || '';
    this.referenceDate = obj.referenceDate || '';
    this.activities = obj.activities || '';
  }

  public static of(obj: any): EmployeeDateActivities {
    return new EmployeeDateActivities(obj || {});
  }

  public get firstNameLastName(): string {
    const name: string[] = this.name.split(' ');
    return `${name[0]} ${name[name.length - 1]}`;
  }

  public get formattedDate(): string {
    return DateFormat.monthYear(this.referenceDate);
  }

  public get listOfActivities(): ActivityDetailed[] {
    return this.activities;
  }

  public set listOfActivities(list: ActivityDetailed[]) {
    this.activities = list;
  }
}
