






























































































import { Vue, Component, Prop } from 'vue-property-decorator';
import ModalComponent from '@/shared/modal/modal.component.vue';
import LabelSlot from '@/shared/slots/label-slot.component.vue';
import DefaultIndicator from '@/domain/drd/entity/default-indicator.entity';
import ModalOptions from '@/shared/modal/modal-options.entity';
import CopyComponent from '@/shared/copy.component.vue';

@Component({
  components: {
    ModalComponent,
    LabelSlot,
    CopyComponent,
  },
})
export default class ReadIndicatorDefaultModal extends Vue {
  @Prop() readIndicator!: DefaultIndicator

  @Prop({
    type: Boolean,
  }) openModal!: boolean;

  get options(): ModalOptions {
    return {
      title: 'Indicador Organizacional',
      titleSize: '20px',
      description: '',
      showOk: false,
      showCancel: true,
      labelCancel: 'Fechar',
      width: 800,
    };
  }

  closeModal() {
    this.$emit('closeModal')
  }
}
