export default class DrdFilter {
    role?: string;

    assignment?: string;

    ecosystem?: string;

    activeAssignment: boolean;

    constructor(obj: any) {
      this.role = obj.role;
      this.assignment = obj.assignment;
      this.ecosystem = obj.ecosystem;
      this.activeAssignment = obj.activeAssignment || true;
    }

    public static of(value: any): DrdFilter {
      return new DrdFilter(value || {});
    }

    public static ofList(response: any): DrdFilter[] {
      return response.data.map(this.of);
    }

    public equals(obj: DrdFilter) {
      return this.assignment === obj.assignment
        && this.ecosystem === obj.ecosystem
        && this.role === obj.role
        && this.activeAssignment === obj.activeAssignment;
    }
}
