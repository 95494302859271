import RouteCheckerTo from '@/views/route-checker';
import Table from './table/wages-table.view.vue';

export default [
  {
    path: '/wages',
    component: Table,
    children: [
      {
        path: '/',
        name: 'Tabelas Salariais',
        component: Table,
        beforeEnter: RouteCheckerTo.hasAccessWagasTable,
      },
    ],
  },
];
