import CandidatePersonalityResult from './candidate-personality-result.entity';
import CandidateReasoningResult from './candidate-reasoning-result.entity';

export default class CandidateResult {
  name: string;

  personalityResults: CandidatePersonalityResult[];

  reasoningResults: CandidateReasoningResult[];

  constructor(obj: any = {}) {
    this.name = obj.name || '';
    this.personalityResults = obj.personalityResults || [];
    this.reasoningResults = obj.reasoningResults || [];
  }
}
