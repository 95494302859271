import { Vue, Component, Prop } from 'vue-property-decorator';

@Component
export default class MenuMixin extends Vue {
  @Prop({ type: Number, default: 1 })
  level!: number;

  private get leftMarginStyle(): string {
    return `margin-left: ${this.level * 4 + 8}px;`;
  }
}
