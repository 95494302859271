







































import { Vue, Component } from 'vue-property-decorator';
import CardTitle from '@/shared/card/card-title.component.vue';
import Employee from '@/domain/employees/entity/employee.entity';
import SkillService from '@/domain/employees/service/skill.service';
import Skill from '@/domain/employees/entity/skill.entity';
import SkillType from '@/domain/employees/entity/skill-type.entity';
import OrderBy from '@/domain/employees/entity/order-by.entity';
import TableComponent from './skill-map-table.component.vue';
import FilterComponent from './filter/filter.component.vue';
import EmployeeSummary from '../../domain/schedule/employee/employee-summary.entity';
import Filter from '../../domain/employees/entity/filter.entity';
import CareerService from '../../domain/employees/service/career.service';
import UpdateAtribute from '../../domain/employees/entity/update-atribute.entity';
import HeaderComponent from './skill-map-table-header.component.vue';
import RegisterSkillModal from './modal/register-skill-modal.component.vue';
import EditSkillModal from './modal/edit-skill-modal.component.vue';

@Component({
  name: 'SkillMapTableView',
  components: {
    FilterComponent, CardTitle, TableComponent, HeaderComponent, RegisterSkillModal, EditSkillModal,
  },
})
export default class EmployeesTableView extends Vue {
  employees: Employee[] = [];

  filterEmployees: EmployeeSummary[] = [];

  roles: UpdateAtribute[] = [];

  hardSkils: Skill[] = [];

  frameworks: Skill[] = [];

  businessExps: Skill[] = [];

  certifications: Skill[] = [];

  lastFilterManager: string = '';

  lastFilterHardSkill: string = '';

  lastFilterFramework: string = '';

  lastBusinessExp: string = '';

  lastCertification: string = '';

  tableLoading: boolean = false;

  lastFilter: Filter = new Filter({});

  showResignationModal: boolean = false;

  extraColumns: any[] = []

  showEditSkill: boolean = false;

  showRegisterSkill: boolean = false;

  skills: Skill[] = [];

  skillSelected: Skill = new Skill();

  hasAccessToCreate: boolean = false;

  sortBy: Array<string> = ['description'];

  sortDesc: boolean[] = [false];

  pagination: any = {
    itemsPerPage: 10,
    page: 1,
    sortBy: this.sortBy,
    sortDesc: this.sortDesc,
    total: 0,
    pageCount: 0,
  }

  filter: Filter = Filter.of({});

  managers: EmployeeSummary[] = [];

  created() {
    CareerService.getRoles().then((resp: any) => this.roles = resp.data);
    this.filter.orderBy = new OrderBy({ field: 'description', descending: false });
    this.getAllSkills();
  }

  setFilterValues(value: any) {
    this.filter.orderBy.field = value.sortBy.toString();
    this.filter.orderBy.descending = value.sortDesc.toString();
    if (this.pagination.itemsPerPage < value.itemsPerPage) {
      value.page = 1;
    }
    this.pagination = value;
    this.getAllSkills();
  }

  findHardSkillByType(value: string) {
    if ((this.lastFilterHardSkill !== value) && value) {
      this.lastFilterHardSkill = value;
      if (value.length > 2) {
        SkillService.getAllByType(value, SkillType.HARD_SKILLS)
          .then((response: any) => {
            this.mergeHardSkills(response);
          });
      }
    }
  }

  findFrameworkByType(value: string) {
    if ((this.lastFilterFramework !== value) && value) {
      this.lastFilterFramework = value;
      if (value.length > 2) {
        SkillService.getAllByType(value, SkillType.FRAMEWORKS_OR_METHODOLOGIES)
          .then((response: any) => {
            this.mergeFrameworks(response);
          });
      }
    }
  }

  findBusinessExpsByType(value: string) {
    if ((this.lastBusinessExp !== value) && value) {
      this.lastBusinessExp = value;
      if (value.length > 2) {
        SkillService.getAllByType(value, SkillType.BUSINESS_EXPERIENCE)
          .then((response: any) => {
            this.mergeBusinessExp(response);
          });
      }
    }
  }

  findCertificationByType(value: string) {
    if ((this.lastCertification !== value) && value) {
      this.lastCertification = value;
      if (value.length > 2) {
        SkillService.getAllByType(value, SkillType.CERTIFICATIONS)
          .then((response: any) => {
            this.mergeCertification(response);
          });
      }
    }
  }

  mergeHardSkills(skills: Skill[]): void {
    const addedIds: string[] = this.hardSkils.map((skill: Skill) => skill.id);

    skills.forEach((skill: Skill) => {
      if (!addedIds.includes(skill.id)) {
        this.hardSkils.push(skill);
      }
    })
  }

  mergeFrameworks(skills: Skill[]): void {
    const addedIds: string[] = this.frameworks.map((skill: Skill) => skill.id);

    skills.forEach((skill: Skill) => {
      if (!addedIds.includes(skill.id)) {
        this.frameworks.push(skill);
      }
    })
  }

  mergeBusinessExp(skills: Skill[]): void {
    const addedIds: string[] = this.businessExps.map((skill: Skill) => skill.id);

    skills.forEach((skill: Skill) => {
      if (!addedIds.includes(skill.id)) {
        this.businessExps.push(skill);
      }
    })
  }

  mergeCertification(skills: Skill[]): void {
    const addedIds: string[] = this.certifications.map((skill: Skill) => skill.id);

    skills.forEach((skill: Skill) => {
      if (!addedIds.includes(skill.id)) {
        this.certifications.push(skill);
      }
    })
  }

  mergeFilterSkills() {
    const allSkills: string[] = [];

    this.filter.hardSkill.forEach((value) => {
      allSkills.push(value)
    });

    this.filter.frameworks.forEach((value) => {
      allSkills.push(value)
    });

    this.filter.businessExps.forEach((value) => {
      allSkills.push(value)
    });

    this.filter.certifications.forEach((value) => {
      allSkills.push(value)
    });

    this.filter.allSkillFilter = allSkills;
  }

  getAllSkills() {
    this.tableLoading = true;
    this.mergeFilterSkills();
    SkillService.getAllActive(this.filter, this.pagination.page, this.pagination.itemsPerPage)
      .then((response: any) => {
        this.skills = response.content;
        this.pagination.total = response.totalElements || 0;
        this.pagination.pageCount = response.totalPages;
      })
      .finally(() => this.tableLoading = false);
  }

  updateTable(value: Filter) {
    if (value.equals(this.filter)) {
      this.filter = value;
      this.pagination.page = 1;
    }
    sessionStorage.setItem('storageFilter', JSON.stringify(this.filter));

    this.getAllSkills();
  }

  setExtraColumns(extraColumns: any[]): void {
    this.extraColumns = extraColumns
  }

  closeEditSkillMapModal(): void {
    this.showEditSkill = false;
    this.getAllSkills();
  }

  openEditSkillMapModal(item: Skill): void {
    this.skillSelected = new Skill(item);
    this.showEditSkill = true;
  }

  closeRegisterSkillModal(): void {
    this.showRegisterSkill = false;
    this.getAllSkills();
  }

  openRegisterSkillModal(): void {
    this.showRegisterSkill = true;
  }
}
