



































import { Vue, Component, Prop } from 'vue-property-decorator';
import EmployeeSummary from '@/domain/schedule/employee/employee-summary.entity';
import EmployeeService from '@/domain/employees/service/employee.service';
import SelectionComponent from '@/shared/fields/selection.componet.vue';
import LabelSlot from '@/shared/slots/label-slot.component.vue';

@Component({
  components: {
    SelectionComponent,
    LabelSlot,
  },
})
export default class ManagerFilter extends Vue {
  @Prop({
    type: String,
    default: 'Gestor',
  }) label!: string;

  @Prop({
    type: String,
    default: 'Digite o nome...',
  }) placeholder!: string;

  @Prop({
    type: String,
    default: 'Nenhum gestor encontrado...',
  }) noDataText!: string;

  managersSelected: string[] = [];

  foundManagers: EmployeeSummary[] = [];

  managerName: string = '';

  loading: boolean = false;

  lastFilterManager: string = '';

  selected() {
    this.$emit('selected', this.managersSelected);
  }

  findManagers(value: string) {
    if ((this.lastFilterManager !== value) && value) {
      this.lastFilterManager = value;
      if (value.length > 2) {
        this.loading = true;
        EmployeeService.findManagersByName(value)
          .then(data => this.foundManagers = data)
          .finally(() => this.loading = false);
      }
    }
  }
}
