

















import { Vue, Prop, Component } from 'vue-property-decorator';
import History from '@/domain/schedule/employee/history.entity';
import EmployeeHistoryListItem from './employee-history-list-item.component.vue';

const headers: any = require('./employee-history-header.json');

@Component({
  components: {
    'list-item': EmployeeHistoryListItem,
  },
})
export default class EmployeeHistoryList extends Vue {
  @Prop({
    required: true,
  })
  private items!: History[];

  @Prop({
    type: Boolean,
  }) private hasActiveOrInactiveActivityHistory!: boolean;

  private headers: any = headers;
}
