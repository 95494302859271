import axios from 'axios';
import PersistSkill from '../entity/persist-skill.entity';
import SkillFilter from '../entity/skill-filter.entity';
import SkillGroup from '../entity/skill-group.entity';

export default class SkillService {
  public static create(data: PersistSkill) {
    return axios.post('/api/skill', data);
  }

  public static update(data: PersistSkill, id: string) {
    return axios.put(`/api/skill/${id}`, data);
  }

  public static search(
    filter: SkillFilter,
    page: number,
    size: number,
  ) {
    return axios.get('/api/skill', {
      params: {
        what: filter.hasWhat ? filter.what : null,
        types: filter.hasTypes ? filter.types.toString() : null,
        keyAreaName: filter.hasKeyAreaName ? filter.keyAreaName : null,
        assignments: filter.hasAssignments ? filter.assignments.toString() : null,
        groups: filter.hasGroups ? filter.groups.toString() : null,
        page: page - 1,
        size,
      },
    });
  }

  public static disable(skillId: string) {
    return axios.patch(`/api/skill/${skillId}`);
  }

  public static findAllGroups() {
    return axios.get('/api/skill/groups');
  }

  public static createGroup(name: string) {
    return axios.post('/api/skill/group', { name });
  }

  public static updateGroup(group: SkillGroup) {
    return axios.put(`/api/skill/group/${group.id}`, { name: group.name });
  }

  public static deleteGroup(id: string) {
    return axios.delete(`/api/skill/group/${id}`);
  }
}
