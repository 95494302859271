
export default class EmployeeRemunerationFilter {
  employeeId?: string;

  roleId?: string;

  seniorityId?: string;

  managerId?: string;

  resultCenterId?: string;

  businessUnitId?: string;

  salaryRange: Array<string> = [''];

  belowRange: boolean = true;

  onRange: boolean = true;

  aboveRange: boolean = true;

  undefined: boolean = true;

  indirectSubordinates: boolean = true;

  constructor(obj: any) {
    this.employeeId = obj.employeeId;
    this.roleId = obj.roleId;
    this.seniorityId = obj.seniorityId;
    this.managerId = obj.managerId;
    this.resultCenterId = obj.resultCenterId;
    this.businessUnitId = obj.businessUnitId;
    this.salaryRange = obj.salaryRange || [''];
  }

  public static of(value: any): EmployeeRemunerationFilter {
    return new EmployeeRemunerationFilter(value || {});
  }

  public static ofList(response: any): EmployeeRemunerationFilter[] {
    return response.data.map(this.of);
  }

  public prepareToSend(): void {
    this.salaryRange = [];
    if (this.belowRange) {
      this.salaryRange.push('BELOW_RANGE');
    }
    if (this.onRange) {
      this.salaryRange.push('ON_RANGE');
    }
    if (this.aboveRange) {
      this.salaryRange.push('ABOVE_RANGE');
    }
    if (this.undefined) {
      this.salaryRange.push('UNDEFINED');
    }
    if (this.salaryRange.length === 0) {
      this.salaryRange = [''];
    }
  }
}
