import axios from 'axios';
import FormFeedback from '../entity/form-feedback.entity';

export default class FormFeedbackService {
  public static save(request: FormFeedback, activityId: string) {
    return axios.post(`/api/activity/${activityId}/feedback`, request);
  }

  public static update(request: FormFeedback, activityId: string) {
    return axios.put(`/api/activity/${activityId}/feedback`, request);
  }

  public static getByActivityId(activityId: string): Promise<FormFeedback> {
    return axios.get(`/api/activity/${activityId}/feedback`)
      .then(({ data }) => new FormFeedback(data));
  }
}
