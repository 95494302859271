







































































import {
  Vue,
  Component,
} from 'vue-property-decorator';
import DrdService from '@/domain/drd/service/drd.service';
import PerformanceDescriptionEntity from '@/domain/drd/entity/performance-description.entity';
import TabButton from '@/shared/tab-button.component.vue';
import IndicatorsComponent from '@/views/drd/form/tabs/indicators/indicators.component.vue';
import AreaKeyComponent from '@/views/drd/form/tabs/area-key/index.vue';
import BehavioralSkillComponent from '@/views/drd/form/tabs/behavioral-skill/index.vue';
import TechnicalKnowledgeComponent from '@/views/drd/form/tabs/technical-knowledge/index.vue';
import FormationComponent from '@/views/drd/form/tabs/formation/index.vue';
import Authority from '@/domain/security/authority.entity';
import AuthorityStore from '@/domain/security/authority.store';
import PerformanceDescriptionTableHeader from './performance-description-table-edit-header.component.vue';
import TabComponent from '../commons/performance-description-tabs.component.vue';

@Component({
  components: {
    AreaKeyComponent,
    BehavioralSkillComponent,
    FormationComponent,
    PerformanceDescriptionTableHeader,
    IndicatorsComponent,
    TabComponent,
    TabButton,
    TechnicalKnowledgeComponent,
  },
})
export default class PerformanceDescriptionComponent extends Vue {
  performanceDescriptionId: string = '';

  performanceDescription = PerformanceDescriptionEntity.of({});

  tab: number = 0;

  rootTab: boolean = false;

  loading = false;

  authority: Authority = new Authority([]);

  updateDrd: boolean = false;

  async created() {
    this.$destroy();
    this.performanceDescriptionId = this.$route.params.id;
    this.getPerformanceDescriptionData();
    await this.validationAuthority();
  }

  getPerformanceDescriptionData() {
    DrdService.getPerformanceDescription(this.performanceDescriptionId)
      .then((resp: PerformanceDescriptionEntity) => {
        this.performanceDescription = resp;
      });
  }

  saveAndNext(): void {
    if (this.tab === 0) {
      const { indicatorsComponent }: any = this.$refs;
      this.setLoading(true);
      indicatorsComponent.save()
        .then(this.selectNextTab)
        .catch(() => {})
        .finally(() => this.setLoading(false));
      return;
    }
    if (this.tab === 1) {
      const { areaKeyComponent }: any = this.$refs;
      this.setLoading(true);
      areaKeyComponent.save()
        .then(this.selectNextTab)
        .catch(() => {})
        .finally(() => this.setLoading(false));
      return;
    }
    if (this.tab === 2) {
      const { behavioralSkillComponent }: any = this.$refs;
      this.setLoading(true);
      behavioralSkillComponent.save()
        .then(this.selectNextTab)
        .catch(() => {})
        .finally(() => this.setLoading(false));
      return;
    }
    if (this.tab === 3) {
      const { technicalKnowledgeComponent }: any = this.$refs;
      this.setLoading(true);
      technicalKnowledgeComponent.save()
        .then(this.selectNextTab)
        .catch(() => {})
        .finally(() => this.setLoading(false));
      return;
    }
    if (this.tab === 4) {
      const { formationComponent }: any = this.$refs;
      this.setLoading(true);
      formationComponent.save()
        .then(this.goToDrdList)
        .catch(() => {})
        .finally(() => this.setLoading(false));
    }
  }

  goToDrdList(): void {
    this.$router.push({
      path: '/performance-description-list',
    });
  }

  setLoading(value: boolean): void {
    this.loading = value;
  }

  selectNextTab(): void {
    this.tab = this.tab + 1;
  }

  selectPreviousTab(): void {
    if (this.tab === 0) {
      this.$router.back();
      this.$destroy();
      return;
    }
    this.tab = this.tab - 1;
  }

  async validationAuthority() {
    await this.getAuthority();
    this.updateDrd = this.authority.hasUpdateDrds();
  }

  async getAuthority() {
    await AuthorityStore.fetchedAuthority().then(authority => this.authority = authority);
  }

  back() {
    window.history.go(-1);
  }
}
