

































import { Vue, Component, Prop } from 'vue-property-decorator';
import TechnicalKnowledge from '@/domain/drd/entity/technical-knowledge.entity';
import SeniorityLevel from '@/views/drd/commons/seniority-level.component.vue';
import DrdPanel from '../common-components/drd-panel.component.vue';

@Component({
  components: { DrdPanel, SeniorityLevel },
})
export default class TechnicalKnowledgeComponent extends Vue {
  @Prop({
    type: TechnicalKnowledge,
    required: true,
  })
  private technicalKnowledge!: TechnicalKnowledge;
}
