













































import { Vue, Component } from 'vue-property-decorator'
import { VbSnackbarService } from 'bag-of-holding-library';
import KeyArea from '@/domain/drd-2.0/entity/key-area.entity';
import KeyAreaService from '@/domain/drd-2.0/service/key-area.service';
import Level from '@/domain/drd-2.0/entity/level.entity';
import CreateKeyAreaDTO from '@/domain/drd-2.0/entity/create-key-area.entity';
import Loading from '@/shared/loading/loading.component.vue';
import FormComponent from './components/form.component.vue';
import SkillTypes from '../skill/skill-types.json';

@Component({
  components: {
    FormComponent,
    Loading,
  },
})
export default class UpdateKeyAreaView extends Vue {
  keyArea: KeyArea = new KeyArea();

  openSelectSkillDialog: boolean = false;

  levels: Level[] = [];

  loading: boolean = false;

  loadingSave: boolean = false;

  skillTypes = SkillTypes;

  created() {
    this.$destroy();
    this.getById();
  }

  disableOrActiveLevel({ skill, level }) {
    const skillFiltered = this.keyArea.skills.filter(it => it.skill.id === skill.skill.id);
    if (skillFiltered.length === 0) return;
    const levelFiltered = skillFiltered[0].levels.filter(it => it.id === level.id)
    if (levelFiltered.length === 0) return;
    levelFiltered[0].active = !levelFiltered[0].active;
  }

  save() {
    const { formComponent } = this.$refs as any;
    const { form } = formComponent.$refs;
    if (!form.validate()) return;
    this.loadingSave = true;
    const data: CreateKeyAreaDTO = new CreateKeyAreaDTO(this.keyArea);
    KeyAreaService.update(this.$route.params.id, data).then(() => {
      this.getById();
      VbSnackbarService.showSuccess('Area Chave atualizada com sucesso!');
    }).catch((error) => {
      VbSnackbarService.showError(error.response.data.messages.toString());
    }).finally(() => this.loadingSave = false);
  }

  getById() {
    this.loading = true;
    KeyAreaService.getById(this.$route.params.id)
      .then(({ data }) => {
        this.keyArea = new KeyArea(data);
      }).finally(() => this.loading = false);
  }

  redirectList() {
    this.$router.push('/keyarea/list');
  }
}
