import axios from 'axios';
import VacationPersist from '../entity/vacation-persist.entity';
import VacationPeriod from '../entity/vacation-period.entity';
import FilterVacationBalance from '../entity/filter-vacation-balance.entity';

export default class VacationService {
  public static getVacations(period: string, pagination: any, employees: string[]): Promise<any> {
    const params: any = {
      period,
      employees: employees.length !== 0
        && employees !== undefined
        && employees !== null ? employees.toString() : null,
      page: pagination.page - 1,
      size: pagination.itemsPerPage,
    };
    if (pagination.sortBy[0]) {
      params.orderBy = `${pagination.sortBy[0]}|${pagination.sortDesc[0] ? 'desc' : 'asc'}`;
    }
    return axios.get('/api/vacations', { params });
  }

  public static getVacationPeriod(employeeId: string, year: number): Promise<VacationPeriod> {
    const params: any = {
      year,
    };
    return axios.get(`/api/employee/${employeeId}/vacation-period`, { params })
      .then(resp => new VacationPeriod(resp.data));
  }

  public static newVacation(vacation: VacationPersist): Promise<void> {
    return axios.post('/api/vacations', vacation)
      .then((resp: any) => resp);
  }

  public static searchVacationsBalance(filter: FilterVacationBalance,
    pagination: any): Promise<any> {
    const params: any = {
      employee: filter.employee,
      manager: filter.manager,
      businessUnit: filter.businessUnit,
      indirectSubordinates: filter.indirectSubordinates,
      page: pagination.page - 1,
      size: pagination.itemsPerPage,
    };
    if (pagination.sortBy[0]) {
      params.orderBy = `${pagination.sortBy[0]}|${pagination.sortDesc[0] ? 'desc' : 'asc'}`;
    }
    return axios.get('/api/vacation-balance', { params });
  }

  public static getVacationBalanceDetails(filter: FilterVacationBalance) {
    const params: any = {
      employee: filter.employee,
      manager: filter.manager,
      businessUnit: filter.businessUnit,
      indirectSubordinates: filter.indirectSubordinates,
    }
    return axios.get('/api/vacation-balance/details', { params });
  }

  public static getEmployeeVacationPeriods(employeeId: string): Promise<any> {
    return axios.get(`/api/employee/${employeeId}/vacations`);
  }

  public static updateVacation(id: string, period: number, vacation: VacationPersist)
  : Promise<VacationPersist> {
    const params: any = {
      period,
    };
    return axios.put(`/api/vacations/${id}`, vacation, { params })
      .then((resp: any) => resp);
  }

  public static deleteVacation(id: string): Promise<void> {
    return axios.delete(`/api/vacations/${id}`)
      .then((resp: any) => resp)
  }
}
