



























import { Vue, Component, Prop } from 'vue-property-decorator';
import { PropType } from 'vue';
import InitialScore from '@/domain/initial-score/entity/initial-score.entity';
import LabelSlot from 'bag-of-holding-library/src/slot/label-slot.component.vue';

@Component({
  components: {
    LabelSlot,
  },
})
export default class InitialScoreObservationComponent extends Vue {
  @Prop({
    type: InitialScore as PropType<InitialScore>,
  }) score!: InitialScore;
}
