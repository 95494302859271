


















































































import {
  Vue, Component, Prop,
} from 'vue-property-decorator';
import { VbSnackbarService, VbTooltipSlot } from 'bag-of-holding-library';
import ModalComponent from '@/shared/modal/modal.component.vue';
import ModalOptions from '@/shared/modal/modal-options.entity';
import LabelSlot from '@/shared/slots/label-slot.component.vue';
import Rules from '@/shared/rules/rules';
import EmployeeService from '@/domain/schedule/employee/employee.service';
import EmployeeSummary from '@/domain/schedule/employee/employee-summary.entity';
import DatePickerField from '@/shared/date-picker/date-picker-field.component.vue';
import AttendanceService from '@/domain/attendance/service/attendance.service';
import Attendance from '@/domain/attendance/entity/attendance.entity';
import EmployeeAttendanceDTO from '@/domain/attendance/entity/employee-attendance-dto';
import AuthorityStore from '@/domain/security/authority.store';
import Authority from '@/domain/security/authority.entity';
import RequestReasonJson from './requestReason.json';

  @Component({
    components: {
      ModalComponent, LabelSlot, VbTooltipSlot, DatePickerField,
    },
  })

export default class ResignationModal extends Vue {
  @Prop() show!: boolean;

  @Prop() showResignationModal!: boolean;

  private attendance = Attendance.of({});

  valid: boolean = false;

  rules: Rules = new Rules();

  role: any = '';

  loading = false;

  refs: any = this.$refs

  private requestReason = RequestReasonJson;

  private employees: EmployeeSummary[] = [];

  private lastFilterEmployee: string = '';

  private requesters: EmployeeSummary[] = []

  private lastFilterRequester: string = '';

  private isAdministrator: boolean = false;

  private isBusinessPartner: boolean = false;

  private get optionsHandled(): ModalOptions {
    return {
      title: 'Adicionar novo acompanhamento',
      titleSize: '25px',
      description: '',
      showOk: true,
      labelOk: 'Salvar',
      disabledOk: !this.valid || this.loading,
      okColor: 'tabBlue',
      showCancel: !this.loading,
      labelCancel: 'Cancelar',
      width: 880,
    };
  }

  async save() {
    if (this.refs.form.validate()) {
      this.loading = true;
      const { id } = this.attendance.employee;
      await this.setAttendanceEmployee(id);
      await this.createAttendance();
    }
  }

  async setAttendanceEmployee(employeeId: string) {
    await EmployeeService.getEmployeeById(employeeId)
      .then((response) => {
        this.attendance.employee = EmployeeAttendanceDTO.of(response);
      }).catch((error: any) => {
        VbSnackbarService.handleHttpError(error);
        this.loading = false;
      })
  }

  async createAttendance() {
    await AttendanceService.newAttendance(this.attendance)
      .then(() => {
        VbSnackbarService.showSuccess('Atendimento salvo com sucesso!');
        this.close();
      }).catch((err: any) => {
        VbSnackbarService.handleHttpError(err);
      }).finally(() => this.loading = false);
  }

  close() {
    if (!this.loading) {
      this.clear();
      this.$emit('close');
    }
  }

  clear() {
    this.role = '';
  }

  created() {
    AuthorityStore.fetchedAuthority().then((response: Authority) => {
      this.isAdministrator = response.isAdministrator();
      this.isBusinessPartner = response.isBusinnesPartner();
    });
  }

  findEmployeesByName(value: string) {
    if ((this.lastFilterEmployee !== value) && value) {
      this.lastFilterEmployee = value;
      if (value.length > 2) {
        if (!this.isAdministrator && this.isBusinessPartner) {
          EmployeeService.findEmployeesByNameExcludeBPs(value).then(this.setEmployees);
          return;
        }
        EmployeeService.findEmployeesByName(value).then(this.setEmployees);
      }
    }
  }

  private setEmployees(resp: EmployeeSummary[]) {
    this.employees = resp;
  }

  findRequesterByName(value: string) {
    if ((this.lastFilterRequester !== value) && value) {
      this.lastFilterRequester = value;
      if (value.length > 2) {
        EmployeeService.findEmployeesByName(value)
          .then((resp: EmployeeSummary[]) => this.requesters = resp);
      }
    }
  }
}
