interface DateObject {
  year: string
  month: string
  day: string
}

export default class DateFormat {
  public static dateObject(date: string): DateObject {
    const dateArray: string[] = date.split('-');
    return {
      year: dateArray[0] || '',
      month: dateArray[1] || '',
      day: dateArray[2] || '',
    };
  }

  public static standardDate(date: string): string {
    if (!date || date === '') {
      return '';
    }
    const dateObject: any = this.dateObject(date);
    return `${dateObject.day}/${dateObject.month}/${dateObject.year}`;
  }

  public static fullMonth(monthNumber: string): string {
    switch (monthNumber) {
      case '01':
        return 'Janeiro';
      case '02':
        return 'Fevereiro';
      case '03':
        return 'Março';
      case '04':
        return 'Abril';
      case '05':
        return 'Maio';
      case '06':
        return 'Junho';
      case '07':
        return 'Julho';
      case '08':
        return 'Agosto';
      case '09':
        return 'Setembro';
      case '10':
        return 'Outubro';
      case '11':
        return 'Novembro';
      case '12':
        return 'Dezembro';
      default:
        return '';
    }
  }

  public static monthYear(date: string): string {
    if (!date || date === '') {
      return '';
    }
    const dateObject: any = this.dateObject(date);
    dateObject.month = this.fullMonth(dateObject.month);
    return `${dateObject.month}, ${dateObject.year}`;
  }

  public static fullDate(date: string): string {
    if (!date || date === '') {
      return '';
    }
    const dateObject: any = this.dateObject(date);
    dateObject.month = this.fullMonth(dateObject.month);
    return `${dateObject.day} de ${dateObject.month} de ${dateObject.year}`;
  }

  public static fullDateFormatMonth(date: string): string {
    if (!date || date === '') {
      return '';
    }
    const dateObject: any = this.dateObject(date);
    dateObject.month = this.fullMonth(dateObject.month);
    return `${dateObject.day} - ${dateObject.month.slice(0, 3)} - ${dateObject.year}`;
  }

  public static monthNumberYear(date: string): string {
    if (!date || date === '') {
      return '';
    }
    const dateObject: any = this.dateObject(date);
    return `${dateObject.month}/${dateObject.year}`;
  }

  public static dateTimeFormated(value: string): string {
    if (value) {
      const dateSplited = value.substr(0, 10).split('-');
      const date = `${dateSplited[2]}/${dateSplited[1]}/${dateSplited[0]}`;
      const time = value.substr(11, 8);
      return `${date} ${time}`;
    }
    return value;
  }

  public static monthYearToFormated(value: string): string {
    if (value && value.length === 7) {
      const splited = value.split('-');
      return `${splited[1]}/${splited[0]}`;
    }
    return '';
  }

  public static monthYearFromFormated(value: string): string {
    if (value && value.length === 7) {
      const splited = value.split('/');
      return `${splited[1]}-${splited[0]}`;
    }
    return '';
  }

  public static splitTimestamp(date: string): string[] {
    return date.split('-');
  }

  public static splitLocal(date: string): string[] {
    return date.split('/');
  }

  public static timestampToLocal(date: string): string {
    if (date.includes('-')) {
      const [year, month, day] = this.splitTimestamp(date);
      return `${day}/${month}/${year}`;
    }
    return date;
  }

  public static localToTimestamp(date: string): string {
    if (date.includes('/')) {
      const [day, month, year] = this.splitLocal(date);
      return `${year}-${month}-${day}`;
    }
    return date;
  }

  public static splitTime(time: string): string[] {
    return time.split(':');
  }

  public static fullDateAndTime(date: string): string {
    if (date.includes('-')) {
      const [year, month, day] = this.splitTimestamp(date.substr(0, 10));
      const [hour, minutes] = this.splitTime(date.substr(11, 5));
      return `${day} de ${this.fullMonth(month)} de ${year} ${hour}:${minutes}`;
    }
    return date;
  }
}
