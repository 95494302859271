



































































































import { Vue, Component, Prop } from 'vue-property-decorator';
import LabelSlot from '@/shared/slots/label-slot.component.vue';
import RoleFilter from '@/domain/drd/entity/role-filter.entity';
import RoleDrdList from '@/domain/drd/entity/roleDrdList.entity';
import SelectionComponent from '@/shared/fields/selection.componet.vue';
import Assignment from '@/domain/drd/entity/assignment.entity';
import CheckBox from '@/shared/checkbox/checkbox.component.vue';
import CardTitle from '@/shared/card/card-title.component.vue';
import ListHeader from '../commons/list-header.component.vue';

@Component({
  components: {
    LabelSlot,
    SelectionComponent,
    CheckBox,
    CardTitle,
    ListHeader,
  },
})
export default class RoleFilterComponent extends Vue {
  @Prop() filter!: RoleFilter;

  @Prop() rolesFiltered!: RoleDrdList;

  @Prop() assignmentFiltered!: Assignment;

  findRoles(value: string) {
    this.filter.roleName = value;
    this.$emit('findRolesByName', value);
  }

  findAssignment(value: string) {
    this.filter.assignmentDescription = value;
    this.$emit('findAssignment', value);
  }

  sendFilter() {
    this.$emit('sendFilter', this.filter);
  }

  updateCbo(values: string[]) {
    if (values.length <= this.filter.cbo.length) {
      this.filter.cbo = values;
      this.sendFilter();
      return;
    }
    const splitedLastItem = values[values.length - 1].split(',');
    splitedLastItem.forEach(item => this.filter.cbo.push(item));
    this.sendFilter();
  }

  getRolesItemText(item: any) {
    return `${item.name} - ${item.active ? 'ativo' : 'inativo'}`;
  }

  updateActive(value: boolean) {
    this.filter.active = value;
    this.sendFilter();
  }
}
