export default class EmployeeAttendanceDTO {
    id: string;

    name: string;

    username: string;

    email: string;

    constructor(obj: any) {
      this.id = obj.id || '';
      this.name = obj.name || '';
      this.username = obj.username || '';
      this.email = obj.email;
    }

    public static of(obj: any): EmployeeAttendanceDTO {
      return new EmployeeAttendanceDTO(obj || {});
    }

    public static ofList(obj: any[]): EmployeeAttendanceDTO[] {
      return obj.map(this.of);
    }
}
