


































import { Vue, Component, Prop } from 'vue-property-decorator';
import AssignmentSingleFilter from '@/shared/filters/assignment/assignment-single-filter.component.vue';
import DrdFilter from '@/domain/drd-2.0/entity/drd-filter';
import RoleSingleFilter from '@/shared/filters/roles/role-single-filter.component.vue';
import EcosystemSingleFilter from '@/shared/filters/ecosystem/ecosystem-single-filter.component.vue';

@Component({
  components: {
    AssignmentSingleFilter,
    RoleSingleFilter,
    EcosystemSingleFilter,
  },
})
export default class DrdFilterComponent extends Vue {
  @Prop({
    required: true,
  }) filter!: DrdFilter;

  updateFilter(value: any, field: string) {
    this.$emit('updateFilter', { value, field });
  }

  sendFilter() {
    this.$emit('sendFilter');
  }
}
