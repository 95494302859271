import KeyArea from './key-area.entity';

export default class PersistAssignmentKeyArea {
  keyAreaId: string;

  keyAreaName: string;

  order: number = 0;

  active: boolean;

  isDeletable: boolean = false;

  constructor(obj: any = {}) {
    this.keyAreaId = obj.keyAreaId;
    this.keyAreaName = obj.keyAreaName;
    this.order = obj.order;
    this.active = obj.active;
    this.isDeletable = obj.isDeletable || false;
  }

  public static of(keyArea: KeyArea) {
    return new PersistAssignmentKeyArea({
      keyAreaId: keyArea.id,
      keyAreaName: keyArea.name,
      active: true,
      isDeletable: true,
      order: 0,
    })
  }
}
