import Vue from 'vue';
import Vuetify from 'vuetify/lib';

Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    iconfont: 'mdi',
  },
  theme: {
    themes: {
      light: {
        primary: '#417aab',
        secondary: '#424242',
        accent: '#44acae',
        error: '#FF5252',
        info: '#2196F3',
        success: '#4CAF50',
        warning: '#FFC107',
        appBackgroundColor: '#f3f6fa',
        'main-red': '#D86277',
        'main-green': '#22BE3B',
        'main-yellow': '#F2994A',
        'plus-box-color': '#26A5A5',
        'minus-box-color': '#6CA9DC',
        greenPagination: '#26A5A5',
        darkGrey: '#4F4F4F',
        secundaryBlue: '#00335E',
        tabBlue: '#0099B7',
        lineColor: '#bdbdbd',
        backgroundGrey: '#EFEFEF',
        alertIcon: '#FF7A00',
        darkBlue: '#006d99',
        redPagination: '#F31313',
        orangePagination: '#EC9717',
      },
      dark: {
        'main-red': '#D86277',
        'main-green': '#22BE3B',
        'main-yellow': '#F2994A',
        'plus-box-color': '#26A5A5',
        'minus-box-color': '#6CA9DC',
        'dark-gray': '#7E8282',
      },
    },
  },
});
