


































import { Vue, Component, Prop } from 'vue-property-decorator';
import AreaKeyItem from '@/domain/drd/entity/area-key/area-key-item.entity';
import SeniorityLevel from '@/views/drd/commons/seniority-level.component.vue';

@Component({
  components: { SeniorityLevel },
})
export default class AreaKeyItemComponent extends Vue {
  @Prop({
    type: AreaKeyItem,
    required: true,
  })
  private areaKeyItem!: AreaKeyItem;
}
