import axios from 'axios';
import AdmissionDateAnniversary from './admission-date-anniversary.entity';

export default class AdmissionDateAnniversaryService {
  public static getAdmissionDateAnniversarys(): Promise<AdmissionDateAnniversary[]> {
    return axios.get('api/personal/admissionDateAnniversaries')
      .then((response: any) => AdmissionDateAnniversary.ofList(response.data));
  }

  public static getNextAdmissionDateAnniversarys(): Promise<AdmissionDateAnniversary[]> {
    return axios.get('api/personal/nextAdmissionDateAnniversaries')
      .then((response: any) => AdmissionDateAnniversary.ofList(response.data))
  }
}
