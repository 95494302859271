























import { Vue, Component, Prop } from 'vue-property-decorator';
import ResultCenter from '@/domain/result-center/entity/result-center.entity';
import Rules from '../rules/rules';
import LabelSlot from '../slots/label-slot.component.vue';

@Component({
  components: {
    LabelSlot,
  },
})
export default class ResultCenterSelect extends Vue {
  @Prop({
    type: String,
    default: 'Centro de custo',
  }) label!: string;

  @Prop() rules!: Rules;

  @Prop() resultCenter!: any;

  @Prop({
    type: Boolean,
    default: false,
  }) returnObject!: boolean;

  @Prop({
    type: Boolean,
    default: false,
  }) clearable!: boolean;

  @Prop() resultCenters!: ResultCenter[];

  @Prop({
    type: Boolean,
    default: false,
  }) loading!: boolean;

  @Prop({
    type: String,
    default: 'Nenhum centro de custo encontrado.',
  }) noDataText!: string;

  update(resultCenterSelected: any) {
    this.$emit('update', resultCenterSelected);
  }
}
