













































import {
  Vue, Component, Prop, Watch,
} from 'vue-property-decorator';
import LabelSlot from '@/shared/slots/label-slot.component.vue';
import DateHandlerService from '@/shared/utils/date-format';

@Component({
  components: {
    'label-slot': LabelSlot,
  },
})
export default class DatePickerField extends Vue {
  @Prop() inputLabel?: string;

  @Prop() label?: string;

  @Prop() rules?: any;

  @Prop() locale!: string;

  @Prop({
    required: false, default: false,
  }) disabled!: boolean;

  @Prop()
  private date!: any;

  @Prop({
    required: false, default: '',
  })
  labelColor?: string;

  @Prop({
    required: false, default: '',
  })
  labelClass?: string;

  @Prop({
    required: false, default: false,
  })
  hideDetails!: boolean;

  private menu: boolean = false;

  private selectedDate: any = this.date;

  formatedDate = '';

  inputedDate = '';

  private inputDate(value: string): void {
    this.menu = false;
    if (value.length === 10) {
      this.selectedDate = DateHandlerService.localToTimestamp(value);
      this.send();
    }
  }

  @Watch('date')
  setSelected() {
    if (this.date) {
      this.selectedDate = new Date(this.date).toISOString().substr(0, 10);
      this.$emit('date', this.selectedDate);
    }
  }

  send() {
    this.formatedDate = DateHandlerService.localToTimestamp(this.selectedDate);
    this.$emit('date', this.formatedDate);
  }

  get formatDate(): string {
    return DateHandlerService.timestampToLocal(this.selectedDate);
  }

  clear() {
    this.$emit('clear');
  }
}

