









import {
  Vue, Component, Prop,
} from 'vue-property-decorator';
import CardTitle from '@/shared/card/card-title.component.vue';
import LabelSlot from '@/shared/slots/label-slot.component.vue';
import TooltipIcon from '@/shared/tooltip-icon.component.vue';

@Component({
  components: {
    LabelSlot,
    CardTitle,
    TooltipIcon,
  },
})
export default class AgreementHeaderComponent extends Vue {
  @Prop() title!: string
}
