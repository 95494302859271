










































































































































import { Vue, Component } from 'vue-property-decorator';
import moment from 'moment';
import { VbSnackbarService } from 'bag-of-holding-library';
import VueToExcel from 'vue-json-excel';
import LabelSlot from '@/shared/slots/label-slot.component.vue';
import MonthYearSelect from '@/shared/month-year-picker/month-year-select.component.vue';
import Vacation from '@/domain/vacation/entity/vacation.entity';
import VacationService from '@/domain/vacation/service/vacation.service';
import DateFormat from '@/shared/utils/date-format';
import PaginationComponent from '@/shared/pagination.component.vue';
import VacationPersist from '@/domain/vacation/entity/vacation-persist.entity';
import ModalWarning from '@/shared/modal/modal-warning.component.vue';
import AuthorityStore from '@/domain/security/authority.store';
import Authority from '@/domain/security/authority.entity';
import EmployeeService from '@/domain/schedule/employee/employee.service';
import EmployeeSummary from '@/domain/schedule/employee/employee-summary.entity';
import headers from './vacation-administrator-header.json';
import VacationModal from './vacation-modal.component.vue'

const SORT_BY_MAPPING = {
  employeeName: 'vacationPeriod.employee.name',
  period: 'vacationPeriod.startPeriod',
  start: 'startDate',
  end: 'endDate',
  days: 'days',
};

@Component({
  components: {
    MonthYearSelect,
    LabelSlot,
    PaginationComponent,
    VueToExcel,
    VacationModal,
    ModalWarning,
  },
  filters: {
    toDate: (date: string) => DateFormat.standardDate(date),
  },
})
export default class ScoreComponent extends Vue {
  private period: string = '';

  private employeeNameFiltered: string = '';

  private employeesFiltered: string[] = [];

  private lastFilterEmployee: string = '';

  private employees: EmployeeSummary[] = [];

  private vacations: Vacation[] = [];

  private vacationPersist = new VacationPersist();

  private loading: boolean = true;

  private loadingXls: boolean = false;

  private headers: any = headers;

  private showModal = false;

  private renderModal = true;

  private vacationId: string = '';

  private warningModal: boolean = false;

  private isUpdate = false;

  isUpdateVacation: boolean = false;

  isCreateVacation: boolean = false;

  isDeleteVacation: boolean = false;

  private pagination: any = {
    itemsPerPage: 10,
    page: 1,
    sortBy: ['employeeName'],
    sortDesc: [false],
    total: 0,
    pageCount: 0,
  };

  private xlsFields: any = {
    Colaborador: 'employeeName',
    'Período aquisitivo': 'period',
    'Início das férias': 'start',
    'Término das férias': 'end',
    'Quantidade de dias': 'days',
  };

  loadingFindEmployees: boolean = false;

  private openVacationModalForAddVacation(): void {
    this.vacationPersist = new VacationPersist();
    this.isUpdate = false;
    this.openVacationModal();
  }

  private openVacationModal(): void {
    this.resetModal();
    this.showModal = true;
  }

  private resetModal(): void {
    this.renderModal = false;
    this.$nextTick(() => {
      this.renderModal = true;
    });
  }

  private openWarningModal(id: string): void {
    this.vacationId = id;
    this.warningModal = true;
  }

  private closeWarningModal(): void {
    this.warningModal = false;
  }

  editVacation(vacation: Vacation): void {
    this.vacationPersist = new VacationPersist(vacation);
    this.isUpdate = true;
    this.openVacationModal();
  }

  updatePage(pagination: any): void {
    if (this.pagination.itemsPerPage < pagination.itemsPerPage) {
      pagination.page = 1;
    }
    this.pagination = pagination;
    this.loadVacations();
  }

  updatePeriod(value: string): void {
    this.period = value;
    this.loadVacations();
  }

  updateEmployeesFiltered(value: string[]) {
    this.employeesFiltered = value;
    this.loadVacations();
  }

  updateSortBy(sortBy: any): void {
    this.pagination.sortBy = sortBy;
    this.loadVacations();
  }

  updateSortDesc(sortDesc: any): void {
    this.pagination.sortDesc = sortDesc;
    this.loadVacations();
  }

  exportToXLS(): Promise<Vacation[]> {
    this.loadingXls = true;
    const period = DateFormat.monthYearFromFormated(this.period);
    const pagination = {
      ...this.pagination,
      itemsPerPage: this.pagination.total,
      page: 1,
      sortBy: this.formattedSortBy,
    };
    return VacationService.getVacations(
      period,
      pagination,
      this.employeesFiltered,
    )
      .then(({ data }: any) => {
        const vacations = data.content.map(it => new Vacation(it));
        vacations.forEach((it) => {
          it.start = DateFormat.standardDate(it.start);
          it.end = DateFormat.standardDate(it.end);
        });
        return vacations;
      }).catch(VbSnackbarService.handleHttpError)
      .finally(() => {
        this.loadingXls = false;
      });
  }

  loadVacations(): void {
    const period = DateFormat.monthYearFromFormated(this.period);
    this.loading = true;
    const pagination: any = {
      ...this.pagination,
      sortBy: this.formattedSortBy,
    };
    VacationService.getVacations(
      period,
      pagination,
      this.employeesFiltered,
    ).then(({ data }: any) => {
      this.pagination.total = data.totalElements;
      this.pagination.pageCount = data.totalPages;
      this.vacations = data.content.map(it => new Vacation(it));
    }).catch(VbSnackbarService.handleHttpError)
      .finally(() => {
        this.loading = false;
      });
  }

  get formattedSortBy(): String[] {
    if (!this.pagination.sortBy || !this.pagination.sortBy.length) {
      return [SORT_BY_MAPPING.employeeName];
    }
    return [SORT_BY_MAPPING[this.pagination.sortBy]];
  }

  created(): void {
    this.$destroy();
    this.period = moment().format('MM/YYYY');
    this.getVacationPermissions();
    this.loadVacations();
  }

  private deleteVacation() {
    this.closeWarningModal();
    this.loading = true;

    VacationService.deleteVacation(this.vacationId)
      .then(() => {
        VbSnackbarService.showSuccess('Férias excluída com sucesso!');
        this.loadVacations();
      })
      .catch((err: any) => {
        VbSnackbarService.handleHttpError(err)
      }).finally(() => {
        this.loading = false;
      });
  }

  getVacationPermissions(): void {
    AuthorityStore.fetchedAuthority()
      .then((response: Authority) => {
        if (!response.isReadVacation()) {
          this.$router.push('/');
          return;
        }
        this.isCreateVacation = response.isCreateVacation();
        this.isUpdateVacation = response.isUpdateVacation();
        this.isDeleteVacation = response.isDeleteVacation();
      })
  }

  findEmployees(value: string) {
    this.employeeNameFiltered = value;
    if (this.lastFilterEmployee === value || value === null) return;
    this.lastFilterEmployee = value;
    if (value.length > 2) {
      this.loadingFindEmployees = true;
      EmployeeService.findEmployeesByName(value)
        .then(response => this.employees = response)
        .finally(() => this.loadingFindEmployees = false);
    }
  }
}
