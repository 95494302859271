



















import {
  Vue,
  Prop,
  Component,
  Emit,
} from 'vue-property-decorator';
import LabelSlot from '@/shared/slots/label-slot.component.vue';

@Component({
  components: {
    'label-slot': LabelSlot,
  },
})
export default class PercentageTextField extends Vue {
  @Prop({
    required: true,
  })
  private label!: string;

  @Prop({
    required: true,
    default: 0,
  })
  private value!: number;

  rules = [
    v => !!v || 'Campo obrigatório',
    v => v <= 100 || 'Valor máximo permitido 100%.',
    v => v >= 0 || 'Não é permitido valores negativos.',
  ]

  @Emit('input')
  private sendInput(percentage: number): number {
    return percentage;
  }
}
