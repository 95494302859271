export default class RiskAttendanceFilter {
  employee?: string;

  manager?: string;

  businessUnit?: string;

  requestDate: string;

  status: any;

  active: boolean = true;

  concluded: boolean = true;

  notConcluded: boolean = true;

  inProgress: boolean = true;

  shutDown: boolean = false;

  indirectSubordinates: boolean = true;

  limitDate: string;

  mainResignationReason?: string;

  responsible?: string;

  isManager: boolean = false;

  typesValues: string[] = [];

  types: any;

  sourcesValues: string[] = [];

  sources: any;

  constructor(obj: any) {
    this.employee = obj.employee;
    this.manager = obj.manager;
    this.businessUnit = obj.businessUnit;
    this.requestDate = obj.requestDate || '';
    this.status = obj.status;
    this.limitDate = obj.limitDate || '';
    this.mainResignationReason = obj.mainResignationReason;
    this.responsible = obj.responsible;
    this.types = obj.types;
    this.sources = obj.sources;
  }

  public static of(value: any): RiskAttendanceFilter {
    return new RiskAttendanceFilter(value || {});
  }

  public static ofList(response: any): RiskAttendanceFilter[] {
    return response.data.map(this.of);
  }

  public prepareToSend(): void {
    this.status = [];
    if (this.notConcluded) {
      this.status.push('NOT_RESOLVED');
    }
    if (this.concluded) {
      this.status.push('RESOLVED');
    }
    if (this.inProgress) {
      this.status.push('PROGRESS');
    }
    if (this.shutDown) {
      this.status.push('SHUTDOWN_VOLUNTARY');
      this.status.push('SHUTDOWN_INVOLUNTARY');
    }
    this.status = this.status.join(',');

    if (this.typesValues) {
      this.types = this.typesValues.join(',');
    }
    if (this.sourcesValues) {
      this.sources = this.sourcesValues.join(',');
    }
  }

  applyShutDown() {
    this.shutDown = true;
  }

  public equals(obj: RiskAttendanceFilter) {
    return this.businessUnit === obj.businessUnit
      && this.employee === obj.employee
      && this.manager === obj.manager
      && this.requestDate === obj.requestDate
      && this.active === obj.active
      && this.limitDate === obj.limitDate
      && this.mainResignationReason === obj.mainResignationReason
      && this.responsible === obj.responsible
  }
}
