






















































































































































































































































































































import { Vue, Component, Prop } from 'vue-property-decorator';
import LabelSlot from '@/shared/slots/label-slot.component.vue';
import Filter from '@/domain/employees/entity/filter.entity';
import SelectionComponent from '@/shared/fields/selection.componet.vue';
import Skill from '@/domain/employees/entity/skill.entity';
import EmployeeSummary from '@/domain/schedule/employee/employee-summary.entity';
import Assignment from '@/domain/drd/entity/assignment.entity';
import UpdateAtribute from '../../../../domain/employees/entity/update-atribute.entity';

@Component({
  components: {
    LabelSlot,
    SelectionComponent,
  },
})
export default class FilterComponent extends Vue {
  @Prop() hardSkils!: Skill[];

  @Prop() frameworks!: Skill[];

  @Prop() businessExps!: Skill[];

  @Prop() certifications!: Skill[];

  @Prop() employees!: EmployeeSummary;

  @Prop() managers!: EmployeeSummary;

  @Prop() lastFilter?: Filter;

  @Prop() businessUnits!: UpdateAtribute;

  @Prop() resultCenters!: UpdateAtribute;

  @Prop() assignments!: Assignment[];

  @Prop() roles!: UpdateAtribute;

  filter: Filter = new Filter({});

  resultCenterKey: number = 0;

  selectedAssignment: Assignment = new Assignment({});

  showCustomFilter(): boolean {
    return (this.filter.hardSkill && this.filter.hardSkill.length) > 0
        || (this.filter.frameworks && this.filter.frameworks.length > 0)
        || (this.filter.businessExps && this.filter.businessExps.length > 0)
        || (this.filter.certifications && this.filter.certifications.length > 0);
  }

  created() {
    this.getLastFilter();
    this.sendFilter();
    this.$forceUpdate();
  }

  findAssignments(value: string) {
    this.filter.assignmentInput = value
    this.$emit('findAssignments', value);
  }

  getLastFilter() {
    if (this.lastFilter) {
      this.filter = this.lastFilter;
    }
  }

  sendFilter() {
    this.filter.employeeInput = ''
    this.filter.managerInput = ''
    this.filter.businessUnitInput = ''
    this.filter.roleInput = ''
    this.filter.hardSkillInput = '';
    this.filter.frameworkInput = '';
    this.filter.certificationInput = '';
    this.$emit('filter', this.filter);
  }

  findHardSkillByType(value: string) {
    this.filter.hardSkillInput = value;
    this.$emit('findHardSkillByType', value);
  }

  findFrameworkByType(value: string) {
    this.filter.frameworkInput = value;
    this.$emit('findFrameworkByType', value);
  }

  findBusinessExpsByType(value: string) {
    this.filter.businessExpInput = value;
    this.$emit('findBusinessExpsByType', value);
  }

  findCertificationByType(value: string) {
    this.filter.certificationInput = value;
    this.$emit('findCertificationByType', value);
  }

  findManagers(value: string) {
    this.filter.managerInput = value
    this.$emit('findManagers', value);
  }

  findEmployees(value: string) {
    this.filter.employeeInput = value
    this.$emit('findEmployees', value);
  }

  findResultCenter(value: string) {
    if (!value) return;
    this.filter.businessUnit = value;
    this.$emit('findResultCenter', value);
    this.sendFilter()
  }

  updateActive(active: boolean) {
    this.filter.active = active;
    this.sendFilter();
  }

  clearResultCenterFilter() {
    this.filter.resultCenter = [];
    this.resultCenterKey += 1;
    this.sendFilter();
  }

  clearBusinessUnit() {
    this.filter.businessUnit = '';
    this.clearResultCenterFilter();
  }

  setRoleInput(value: string): void {
    this.filter.roleInput = value
  }

  getName(items, id) {
    const itemName = items.find(i => i.id === id);
    return itemName ? itemName.description : '';
  }

  removeItem(prop, index) {
    this.filter[prop].splice(index, 1);
    this.sendFilter();
  }

  clearFilters() {
    this.filter.hardSkill.splice(0, this.filter.hardSkill.length);
    this.filter.frameworks.splice(0, this.filter.frameworks.length);
    this.filter.businessExps.splice(0, this.filter.businessExps.length);
    this.filter.certifications.splice(0, this.filter.certifications.length);
    this.sendFilter();
  }
}
