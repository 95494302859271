








import { Vue, Component, Prop } from 'vue-property-decorator';

@Component
export default class TalentLevelLabel extends Vue {
  @Prop({
    type: String,
    required: true,
  })
  name!: string;

  @Prop({
    type: String,
    required: true,
  })
  color!: string;

  @Prop({
    type: Boolean,
    default: true,
  })
  active!: boolean;

  getColor(): string {
    return this.active ? this.color : '#AAAAAA';
  }

  getClasses() {
    const nearToWhiteRegex: RegExp = /^#[e-fE-F][a-fA-F0-9][e-fE-F][a-fA-F0-9][e-fE-F][a-fA-F0-9]$/;
    return {
      'talent-level-label': true,
      'font-weight-black': true,
      'talent-level-label__outlined': nearToWhiteRegex.test(this.color) && this.active,
    };
  }
}
