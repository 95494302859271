

















import { Vue, Component, Prop } from 'vue-property-decorator';
import SkillTypeOption from '@/domain/assessment/entity/skill/entity/skill-type-option';

@Component
export default class SkillOptionsDetails extends Vue {
  @Prop() options!: SkillTypeOption;
}
