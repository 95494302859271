






































































































































































import {
  Vue, Component, Prop,
} from 'vue-property-decorator';
import DatePickerField from '@/shared/date-picker/date-picker-field.component.vue'
import CardTitle from '@/shared/card/card-title.component.vue';
import LabelSlot from '@/shared/slots/label-slot.component.vue';
import splitContent from '@/shared/directive/split-content';
import DateHandler from '@/shared/utils/date-format';
import RiskAttendance from '@/domain/risk-attendance/entity/risk-attendance.entity';
import Rules from '@/shared/rules/rules';
import ResignationReason from '@/views/attendance/form-attendances/resignation-reason.json';

@Component({
  components: {
    LabelSlot, DatePickerField, CardTitle,
  },
  filters: {
    splitContent,
    formatDate(value: string) {
      return DateHandler.timestampToLocal(value.split('T')[0]);
    },
  },
})
export default class FormRiskAttendanceBodyComponent extends Vue {
  @Prop() riskAttendance!: RiskAttendance;

  @Prop({
    type: Boolean,
    required: false,
    default: false,
  }) hasUpdateRiskAttendance!: boolean;

  @Prop({
    type: Boolean,
    required: false,
    default: false,
  }) hasCreateRiskAttendance!: boolean;

  riskAttendanceAux: RiskAttendance = RiskAttendance.of({});

  valid: boolean = false;

  private loading = false;

  rules: Rules = new Rules();

  lastEmployeeFilter: string = '';

  resignationReason = ResignationReason.sort((reason1, reason2) => {
    if (reason1.description < reason2.description) return -1;
    if (reason1.description > reason2.description) return 1;
    return 0
  });

  private findResponsible(value: string) {
    this.$emit('findManagers', value);
  }

  resignationReasonDisabled(item: any) {
    return !item.active;
  }
}

