import isNullOrUndefined from '@/shared/utils/check-null-or-undefined';

export default class InitialScoreFilter {
  employeeName: string;

  registration: string;

  admissionDate: string;

  constructor(obj: any = {}) {
    this.employeeName = obj.employeeName || '';
    this.registration = obj.registration || '';
    this.admissionDate = obj.admissionDate || '';
  }

  get hasEmployeeName(): boolean {
    return !isNullOrUndefined(this.employeeName);
  }

  get hasRegistration(): boolean {
    return !isNullOrUndefined(this.registration);
  }

  get hasAdmissionDate(): boolean {
    return !isNullOrUndefined(this.admissionDate);
  }
}
