import ActionPlan from '@/domain/activity/entity/action-plan.entity';

export default class EmployeeAssessmentResultDetails {
  assessmentItemId: string;

  whatPTBR: string;

  whatEN: string;

  whatES: string;

  type: AssessmentResultSkillType;

  isAdherence: number;

  response: string;

  responseDetails: string;

  expected: string;

  expectedDetails: string;

  fetched: boolean = false;

  pdiPlan: ActionPlan;

  formPlanId: string;

  constructor(obj: any = {}) {
    this.assessmentItemId = obj.assessmentItemId || '';
    this.whatPTBR = obj.whatPTBR || '';
    this.whatEN = obj.whatEN || '';
    this.whatES = obj.whatES || '';
    this.type = obj.type;
    this.isAdherence = obj.isAdherence;
    this.response = obj.response || '';
    this.responseDetails = obj.responseDetails || '';
    this.expected = obj.expected || '';
    this.expectedDetails = obj.expectedDetails || '';
    this.formPlanId = obj.formPlanId || '';
    this.pdiPlan = obj.pdiPLan ? obj.pdiPlan : new ActionPlan(
      {
        id: obj.formPlanId ? obj.formPlanId : '',
        what: this.whatPTBR,
        planType: obj.type ? obj.type.value : '',
        assessmentItemId: this.assessmentItemId,
      },
    );
  }
}

export interface AssessmentResultSkillType {
  description: string;
  isBoolean: boolean;
  color: string;
  value: string;
}
