import axios from 'axios';
import InitialScoreFilter from '../entity/initial-score-filter.entity';
import InitialScore from '../entity/initial-score.entity';

export default class InitialScoreService {
  public static filter(
    filter: InitialScoreFilter,
    page: number,
    size: number,
    sortBy: string,
  ) {
    return axios.get('/api/initial-score', {
      params: {
        employeeName: filter.hasEmployeeName ? filter.employeeName : null,
        registration: filter.hasRegistration ? filter.registration : null,
        admissionDate: filter.admissionDate ? filter.admissionDate : null,
        page: page - 1,
        size,
        orderBy: sortBy,
      },
    });
  }

  public static getById(id: string): Promise<InitialScore> {
    return axios.get(`/api/initial-score/${id}`)
      .then(({ data }) => InitialScore.of(data));
  }

  public static create(initialScore: InitialScore) {
    return axios.post('/api/initial-score', initialScore);
  }

  public static update(initialScore: InitialScore) {
    return axios.put('/api/initial-score', initialScore);
  }

  public static delete(id: string) {
    return axios.delete(`/api/initial-score/${id}`);
  }
}
