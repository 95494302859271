export default class EnumObject {
  name: string;

  description: string;

  constructor(obj: any = {}) {
    this.name = obj.name || '';
    this.description = obj.description || '';
  }

  public static of(obj: any): EnumObject {
    return new EnumObject(obj);
  }

  public static ofList(obj: any[] = []): EnumObject[] {
    return obj.map(this.of)
  }

  equals(result: EnumObject): boolean {
    return this.name === result.name
            && this.description === result.description;
  }
}
