import { VbSnackbarService } from 'bag-of-holding-library';

export default class CopyClipboardService {
  public static copyToClipboard(text: string): void {
    navigator.clipboard.writeText(text)
      .then(() => {
        VbSnackbarService.showSuccess('Copiado!');
      }).catch(() => {
        VbSnackbarService.showError('Não foi possível copiar o link.');
      });
  }
}
