var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',{attrs:{"justify":"end"}},[_c('v-col',{attrs:{"cols":"6","sm":"4","lg":"3","xl":"2","align-self":"center"}},[_c('v-btn',{attrs:{"color":"primary","block":"","disabled":!_vm.isCreateVacation},on:{"click":_vm.openVacationModalForAddVacation}},[_vm._v(" Adicionar ")])],1),_c('v-col',{attrs:{"cols":"6","sm":"4","lg":"3","xl":"2","align-self":"center"}},[_c('vue-to-excel',{attrs:{"fetch":_vm.exportToXLS,"fields":_vm.xlsFields,"name":"Férias.xls","worksheet":"Férias"}},[_c('v-btn',{attrs:{"color":"primary","block":"","loading":_vm.loadingXls}},[_c('v-icon',{staticClass:"mr-1"},[_vm._v("mdi-cloud-upload")]),_vm._v(" Exportar XLS ")],1)],1)],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"4","lg":"2"}},[_c('label-slot',{attrs:{"label":"Mês de início das férias"}},[_c('month-year-select',{attrs:{"value":_vm.period,"clearable":false},on:{"input":_vm.updatePeriod}})],1)],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('label-slot',{attrs:{"label":'Colaborador'}},[_c('v-autocomplete',{attrs:{"items":_vm.employees,"item-text":"name","item-value":"id","placeholder":"Digite o nome do colaborador...","no-data-text":"Nenhum colaborador encontrado","outlined":"","dense":"","clearable":"","multiple":"","chips":"","small-chips":"","deletable-chips":"","hide-details":"","search-input":_vm.employeeNameFiltered,"loading":_vm.loadingFindEmployees},on:{"update:search-input":_vm.findEmployees,"input":_vm.updateEmployeesFiltered},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('selection-component',{attrs:{"length":_vm.employees.length,"item":item,"index":index}})]}}]),model:{value:(_vm.employeesFiltered),callback:function ($$v) {_vm.employeesFiltered=$$v},expression:"employeesFiltered"}})],1)],1)],1),_c('v-data-table',{staticClass:"vacation-administrator-list__data-table mt-3",attrs:{"items":_vm.vacations,"headers":_vm.headers,"no-data-text":"Não existem registros de férias para esse mês.","loading":_vm.loading,"loading-text":"Carregando...","hide-default-footer":"","sort-by":_vm.pagination.sortBy,"sort-desc":_vm.pagination.sortDesc,"items-per-page":_vm.pagination.itemsPerPage},on:{"update:sort-by":_vm.updateSortBy,"update:sort-desc":_vm.updateSortDesc},scopedSlots:_vm._u([{key:"item.start",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("toDate")(item.start))+" ")]}},{key:"item.end",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("toDate")(item.end))+" ")]}},{key:"item.editAndDelete",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"disabled":!_vm.isUpdateVacation,"icon":""},on:{"click":function($event){return _vm.editVacation(item)}}},[_c('v-icon',[_vm._v(" mdi-pencil-outline ")])],1),_c('v-btn',{attrs:{"icon":"","disabled":!_vm.isDeleteVacation},on:{"click":function($event){return _vm.openWarningModal(item.id)}}},[_c('v-icon',{attrs:{"color":"red"}},[_vm._v(" mdi-trash-can ")])],1)]}}],null,true)}),_c('pagination-component',{staticClass:"mt-8 ml-2",attrs:{"pagination":_vm.pagination},on:{"input":_vm.updatePage}}),(_vm.renderModal)?_c('vacation-modal',{attrs:{"vacation":_vm.vacationPersist,"isUpdate":_vm.isUpdate},on:{"refresh":_vm.loadVacations},model:{value:(_vm.showModal),callback:function ($$v) {_vm.showModal=$$v},expression:"showModal"}}):_vm._e(),_c('modal-warning',{attrs:{"value":_vm.warningModal},on:{"ok":_vm.deleteVacation,"cancel":_vm.closeWarningModal}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }