


























































































































































































import GodfatherFilter from '@/domain/employees/entity/godfather-filter.entity';
import { VbSnackbarService, VbTooltipSlot } from 'bag-of-holding-library';
import { Vue, Component } from 'vue-property-decorator';
import DatePickerField from '@/shared/date-picker/date-picker-field.component.vue'
import LabelSlot from '@/shared/slots/label-slot.component.vue';
import VueToExcel from 'vue-json-excel';
import GodfatherService from '@/domain/employees/service/godfather.service';
import FilteredGodfather from '@/domain/employees/entity/filtered-godfather.entity';
import CareerService from '@/domain/employees/service/career.service';
import UpdateAtribute from '@/domain/employees/entity/update-atribute.entity';
import CardTitle from '@/shared/card/card-title.component.vue';
import EmployeeSummary from '@/domain/schedule/employee/employee-summary.entity';
import ManagerService from '@/domain/employees/service/employee.service';
import EmployeeService from '@/domain/schedule/employee/employee.service';
import Authority from '@/domain/security/authority.entity';
import AuthorityStore from '@/domain/security/authority.store';

@Component({
  components: {
    LabelSlot, CardTitle, VbTooltipSlot, VueToExcel, DatePickerField,
  },
})
export default class GodfatherExport extends Vue {
  filter: GodfatherFilter = GodfatherFilter.of({});

  managers: EmployeeSummary[] = [];

  employees: EmployeeSummary[] = [];

  roles: UpdateAtribute[] = [];

  businessUnits: UpdateAtribute[] = [];

  personalGodfather: FilteredGodfather[] = [];

  lastFilter: GodfatherFilter = GodfatherFilter.of({});

  loading = false;

  businessUnitInput = '';

  valid = true;

  lastFilterManager: string = '';

  lastFilterEmployee: string = '';

  authority: Authority = new Authority([]);

  exportGodfatherData: boolean = false;

  get exportTooltip() {
    return this.personalGodfather.length === 0
      ? 'Dados não encontrados para esta pesquisa'
      : 'Gerar CSV'
  }

  csvLabel = {
    Colaborador: 'name',
    Gestor: 'manager',
    Cargo: 'role',
    'Sede em que atua': 'workPlace',
    'Modo de atuação': 'operationModality',
    'Unidade de negócio': 'businessUnit',
    'Centro de resultado': 'resultCenter',
    Senioridade: 'seniority',
    Observações: 'observation',
    'Na db1 desde': 'admissionDate',
    'Precisa de Treinamento': 'valueNeedTraining',
    'Padrinho/Madrinha': 'valueStatus',
    'Último treinamento': 'trainingDate',
  }

  back() {
    this.$router.back();
  }

  businessUnitRule() {
    const errors: string[] = [];
    if (!this.filter.businessUnit && this.businessUnitInput) {
      errors.push('Favor informar uma unidade válida');
    }
    return errors;
  }

  created() {
    CareerService.getRoles().then((resp: any) => this.roles = resp.data);
    CareerService.getBusinessUnit().then(resp => this.businessUnits = resp);
    this.getGodfather();
    this.validationAuthorities();
  }

  getGodfather() {
    this.loading = true;
    GodfatherService.exportGodfather(this.filter)
      .then((resp: any) => this.personalGodfather = FilteredGodfather.ofList(resp))
      .catch((err: any) => VbSnackbarService.handleHttpError(err))
      .finally(() => this.loading = false);
  }

  findEmployees(value: string) {
    if ((this.lastFilterEmployee !== value) && value) {
      this.lastFilterEmployee = value;
      if (value.length > 2) {
        sessionStorage.setItem('employeeAttendanceStore', value)
        EmployeeService.findEmployeesByName(value)
          .then((resp: EmployeeSummary[]) => this.employees = resp);
      }
    }
  }

  findManagers(value: string) {
    if ((this.lastFilterManager !== value) && value) {
      this.lastFilterManager = value;
      if (value.length > 2) {
        sessionStorage.setItem('managerAttendanceStore', value)
        ManagerService.findManagersByName(value)
          .then((resp: EmployeeSummary[]) => this.managers = resp);
      }
    }
  }

  applyInitialPeriodFilter(value: string) {
    this.filter.initialTrainingDate = value;
    this.getGodfather();
  }

  applyFinalPeriodFilter(value: string) {
    this.filter.finalTrainingDate = value;
    this.getGodfather();
  }

  clearInitialPeriodFilter() {
    this.filter.initialTrainingDate = ''
    this.getGodfather();
  }

  clearFinalPeriodFilter() {
    this.filter.finalTrainingDate = ''
    this.getGodfather();
  }

  async validationAuthorities() {
    await this.getAuthority();
    this.exportGodfatherData = this.authority.hasExportGodfatherData();
  }

  async getAuthority() {
    await AuthorityStore.fetchedAuthority().then(authority => this.authority = authority);
  }
}
