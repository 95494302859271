















































import { Component, Vue, Prop } from 'vue-property-decorator';

@Component
export default class EmployeeModalColumns extends Vue {
  @Prop({ type: Boolean, default: false })
  private value!: boolean;

  private hasSeniority: boolean = false

  private hasWorkPlace: boolean = false

  private hasModality: boolean = false

  private hasResultCenter: boolean = false

  private hasObservation: boolean = false

  private hasSquad: boolean = false

  private closeDialog(value: boolean): void {
    this.$emit('input', value)
  }

  private setVisibleColumns():void {
    const columns: any[] = this.formatColumns()
    this.$emit('setVisibleColumns', columns)
    this.closeDialog(false)
  }

  private formatColumns(): any[] {
    const columns: any[] = []
    if (this.hasSeniority) {
      columns.push({
        text: 'Senioridade',
        value: 'seniority',
      })
    }

    if (this.hasWorkPlace) {
      columns.push({
        text: 'Local de trabalho',
        value: 'workPlace',
      })
    }

    if (this.hasModality) {
      columns.push({
        text: 'Modalidade',
        value: 'operationModality',
      })
    }

    if (this.hasResultCenter) {
      columns.push({
        text: 'Centro de custo',
        value: 'resultCenter',
      })
    }

    if (this.hasObservation) {
      columns.push({
        text: 'Observação',
        value: 'observation',
      })
    }

    if (this.hasSquad) {
      columns.push({
        text: 'Squad/Team',
        value: 'squad',
      })
    }

    return columns.map(column => ({
      ...column,
      class: 'data-table-header',
    }))
  }
}
