
















































import { Vue, Component } from 'vue-property-decorator';
import DrdService from '@/domain/drd/service/drd.service';
import Response from '@/domain/drd/entity/response.entity';
import Assignment from '@/domain/drd/entity/assignment.entity';
import Authority from '@/domain/security/authority.entity';
import CareerService from '@/domain/employees/service/career.service';
import Ecosystem from '@/domain/drd/entity/ecosystem.entity';
import AssignmentFilter from '@/domain/drd/entity/filter-assignment.entity';
import UpdateAtribute from '@/domain/employees/entity/update-atribute.entity';
import AuthorityStore from '@/domain/security/authority.store';
import FilterComponent from './table/filter/filter-assignment.component.vue';
import AssignmentList from './table/assignment-list.component.vue';
import AssignmentDialog from './form/assignment-dialog.component.vue';
import HeaderComponent from './commons/list-header.component.vue';

@Component({
  components: {
    AssignmentList,
    AssignmentDialog,
    FilterComponent,
    HeaderComponent,
  },
})
export default class AssignmentComponent extends Vue {
  showDialog: boolean = false;

  renderDialog: boolean = true;

  filterAssignments: Assignment[] = [];

  assignments: Assignment[] = [];

  editingAssignmentId: string | null = null;

  loadingAssignments: boolean = false;

  roles: UpdateAtribute[] = [];

  ecosystems: Ecosystem[] = [];

  lastFilterAssignment: string = '';

  sortBy: Array<string> = ['description'];

  sortDesc: boolean[] = [false];

  direction: string = 'asc';

  sort: string = '';

  pagination: any = {
    itemsPerPage: 10,
    page: 1,
    sortBy: this.sortBy,
    sortDesc: this.sortDesc,
    total: 0,
    pageCount: 0,
  }

  createAssignments: boolean = false;

  updateAssignments: boolean = false;

  inactiveAssignments: boolean = false;

  filter: AssignmentFilter = AssignmentFilter.of({});

  authority: Authority = new Authority([]);

  openAssignmentDialog(): void {
    this.resetDialog();
    this.showDialog = true;
  }

  resetDialog(): void {
    this.renderDialog = false;
    this.$nextTick(() => {
      this.renderDialog = true;
    });
  }

  openDialogForNewAssignment(): void {
    this.editingAssignmentId = null;
    this.openAssignmentDialog();
  }

  editAssignment(assignmentId: string): void {
    this.editingAssignmentId = assignmentId;
    this.openAssignmentDialog();
  }

  inactivateAssignment(assignmentId: string): void {
    DrdService.disableAssignment(assignmentId).then(this.findAssignments);
  }

  handleUpdateAssignments(value: AssignmentFilter) {
    if (value.equals(this.filter)) {
      this.filter = value;
      this.pagination.page = 1;
    }
    sessionStorage.setItem('storageFilter', JSON.stringify(this.filter));
    this.findAssignments();
  }

  findAssignments(): void {
    this.getPaginationFields();
    DrdService.findAssignments(this.filter, this.pagination.page, this.pagination.itemsPerPage)
      .then((response: Response) => {
        this.assignments = response.content;
        this.pagination.total = response.totalElements || 0;
        this.pagination.pageCount = response.totalPages;
      })
      .finally(() => {
        this.loadingAssignments = false;
      });
  }

  setFilterValues(value: any) {
    if (this.pagination.itemsPerPage < value.itemsPerPage) {
      value.page = 1;
    }
    this.pagination = value;
    this.findAssignments();
  }

  created(): void {
    this.findAssignments();
    this.validationAuthorities();
    this.getRoles();
    this.getEcosystems();
  }

  getPaginationFields() {
    this.direction = this.pagination.sortDesc[0] ? 'desc' : 'asc';
    this.loadingAssignments = true;
    this.sort = this.pagination.sortBy.length < 1 ? '' : this.pagination.sortBy[0];
  }

  async validationAuthorities() {
    await this.getAuthority();
    this.createAssignments = this.authority.hasCreateAssignments();
    this.updateAssignments = this.authority.hasUpdateAssignments();
    this.inactiveAssignments = this.authority.hasInactiveAssignments();
  }

  getRoles() {
    CareerService.getRoles().then((resp: any) => this.roles = resp.data);
  }

  getEcosystems() {
    DrdService.getAllEcosystem().then((resp: any) => this.ecosystems = resp);
  }

  findAssignmentsByName(value: string) {
    if ((this.lastFilterAssignment !== value) && value) {
      this.lastFilterAssignment = value;
      if (value.length > 2) {
        sessionStorage.setItem('assignmentStore', value)
        DrdService.getAssigments(value)
          .then(this.mergeAssignments);
      }
    }
  }

  mergeAssignments(assignments: Assignment[]): void {
    const addedIds: string[] = this.filterAssignments.map((assignment: Assignment) => assignment.id)
    assignments.forEach((assignment: Assignment) => {
      if (!addedIds.includes(assignment.id)) {
        this.filterAssignments.push(assignment);
      }
    })
  }

  async getAuthority() {
    await AuthorityStore.fetchedAuthority().then(authority => this.authority = authority);
  }
}

