

































































import {
  Vue,
  Component,
  Prop,
} from 'vue-property-decorator';
import { VbSnackbarService } from 'bag-of-holding-library';
import PaginationComponent from '@/shared/pagination.component.vue';
import KeyArea from '@/domain/drd-2.0/entity/key-area.entity';
import KeyAreaService from '@/domain/drd-2.0/service/key-area.service';
import KeyAreaFilter from '@/domain/drd-2.0/entity/key-area-filter.entity';
import PersistAssignmentKeyArea from '@/domain/drd-2.0/entity/persist-assignment-keyarea.entity';
import SelectKeyAreaHeader from './select-keyarea-header.json';

@Component({
  components: { PaginationComponent },
})
export default class SelectKeyAreasDialog extends Vue {
  @Prop({ type: Boolean, default: false })
  value!: boolean;

  @Prop({ type: Array, default: () => ([]) })
  selectedKeyAreas!: PersistAssignmentKeyArea[];

  loading: boolean = false;

  allKeyAreas: KeyArea[] = [];

  search: string = '';

  headers = SelectKeyAreaHeader;

  pagination: any = {
    page: 1,
    itemsPerPage: 10,
    sortBy: '',
    sortDesc: false,
    pageCount: 0,
    total: 0,
  };

  noResultsText: string = 'Nenhuma área chave foi encontrada nessa página com o filtro buscado, procure nas próximas. Você também pode aumentar o tamanho da paginação.';

  keyAreaFilter: KeyAreaFilter = new KeyAreaFilter();

  get selected(): KeyArea[] {
    return this.selectedKeyAreas
      .map(it => new KeyArea({ id: it.keyAreaId, name: it.keyAreaName }));
  }

  itemSelected({ item, value }: any): void {
    this.$emit('itemSelected', item, value);
  }

  inputDialog(val: boolean): void {
    this.$emit('input', val);
  }

  updatePage(pagination: any): void {
    if (this.pagination.itemsPerPage < pagination.itemsPerPage) {
      pagination.page = 1;
    }
    this.pagination = pagination;
    this.loadKeyAreas();
  }

  loadKeyAreas(): void {
    this.loading = true;
    KeyAreaService.search(
      this.keyAreaFilter,
      this.pagination.page,
      this.pagination.itemsPerPage,
    ).then(({ data }: any) => {
      this.allKeyAreas = data.content.map(this.mapKeyArea);
      this.pagination.pageCount = data.totalPages;
      this.pagination.total = data.totalElements;
    }).catch(VbSnackbarService.handleHttpError)
      .finally(() => this.loading = false);
  }

  mapKeyArea(value: any): KeyArea {
    return new KeyArea({
      ...value,
      isSelectable: !this.selectedKeyAreas.map(it => it.keyAreaId).includes(value.id),
    });
  }

  created(): void {
    this.loadKeyAreas();
  }
}
