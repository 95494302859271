





















import { Vue, Component, Prop } from 'vue-property-decorator';
import CareerService from '@/domain/employees/service/career.service';
import UpdateAtribute from '@/domain/employees/entity/update-atribute.entity';
import Rules from '../rules/rules';
import LabelSlot from '../slots/label-slot.component.vue';

@Component({
  components: { LabelSlot },
})
export default class BusinessUnitSelect extends Vue {
  @Prop({
    type: String,
    default: 'Unidade de Negócio',
  }) label!: string;

  @Prop({
    type: Boolean,
    default: false,
  }) returnObject!: boolean;

  @Prop() rules!: Rules;

  @Prop() businessUnit!: any;

  @Prop({
    type: String,
    default: '#0099B7',
  }) labelColor!: string;

  @Prop({
    type: Boolean,
    default: false,
  }) clearable!: boolean;


  businessUnits: UpdateAtribute[] = [];

  created() {
    this.getBusinessUnits();
  }

  getBusinessUnits() {
    CareerService.getBusinessUnit()
      .then(response => this.businessUnits = response);
  }

  change(value: any) {
    this.$emit('change', value);
  }
}
