


























import {
  Vue,
  Component,
} from 'vue-property-decorator';
import { hasAdministratorAuthority } from '@/shared/directive/has-authority.directive';
import ScoreList from '@/domain/employees/entity/score-list.entity'
import ScoreService from '@/domain/employees/service/score.service';
import ScoreFilterEntity from '@/domain/drd/entity/filter-score.entity'
import ScoreFilter from './table/filter/filter-score.component.vue';
import ScoreHeader from './table/score-header.component.vue';
import ScoreTableComponent from './table/score-table.component.vue';

@Component({
  name: 'ScoreView',
  components: {
    ScoreHeader,
    ScoreFilter,
    ScoreTableComponent,
  },
  directives: {
    hasAdministratorAuthority,
  },
})
export default class ScoreView extends Vue {
  filter: any = new ScoreFilterEntity({})

  loading = false

  scoreList: ScoreList[] = []

  applyFilter(filter: ScoreFilter) {
    this.filter = filter;
  }

  updateField(field: string, value: any) {
    this.filter[field] = value;
    this.filter.prepareToSend();
    this.findScore();
  }

  pagination: any = {
    itemsPerPage: 10,
    page: 1,
    sortBy: ['employee'],
    sortDesc: [false],
    total: 0,
    pageCount: 0,
  }

  findScore() {
    this.loading = true;
    this.filter.prepareToSend();
    ScoreService.findScore(this.pagination,
      this.pagination.sortBy[0], this.filter)
      .then((response: any) => {
        this.scoreList = ScoreList.ofList(response.content);
        this.pagination.total = response.totalElements || 0;
        this.pagination.pageCount = response.totalPages;
      })
      .finally(() => this.loading = false);
  }

  setFilterValues(value: any) {
    if (this.pagination.itemsPerPage < value.itemsPerPage) {
      value.page = 1;
    }
    this.pagination = value;
    this.findScore();
  }

  created() {
    this.$destroy();
    this.filter.competence = sessionStorage.getItem('competence');
    this.findScore();
  }
}
