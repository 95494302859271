






























import Assignment from '@/domain/drd/entity/assignment.entity';
import DrdService from '@/domain/drd/service/drd.service';
import {
  Vue, Component, Prop, Watch,
} from 'vue-property-decorator';
import LabelSlot from '../../slots/label-slot.component.vue';
import SelectionComponent from '../../fields/selection.componet.vue';
import Debounce from '../../utils/debounce';

@Component({
  components: {
    LabelSlot,
    SelectionComponent,
  },
})
export default class FilterAssignmentDebounce extends Vue {
  @Prop({
    default: '',
  }) value!: string | Array<String>;

  @Prop({
    type: String,
    default: 'Papel',
  }) label!: string;

  @Prop({
    type: String,
    default: 'Degite o papel...',
  }) placeholder!: string;

  @Prop({
    type: Boolean,
    default: false,
  }) multiple!: boolean;

  assignments: Assignment[] = [];

  timeId: number = -1;

  search: string = '';

  loading: boolean = false;

  @Watch('search')
  searchAssignments(description: string) {
    if (!description || description.length <= 3) return;

    Debounce.debounce(() => this.fetchAssignments(description))
  }

  fetchAssignments(description: string) {
    this.loading = true;
    DrdService.getAssigments(description)
      .then((assignments: Assignment[]) => {
        this.assignments = assignments
      }).finally(() => this.loading = false);
  }

  assignmentText(assignment: Assignment) {
    return assignment.inactive ? `${assignment.description} - inativo` : assignment.description
  }
}
