import ActionPlan from './action-plan.entity';

export default class FormPDI {
  id: string;

  activityId: string;

  activityType: string;

  plans: ActionPlan[];

  constructor(obj: any = {}) {
    this.id = obj.id || null;
    this.activityId = obj.activityId || null;
    this.activityType = obj.activityType || '';
    this.plans = obj.plans ? obj.plans.map(it => new ActionPlan(it)) : [];
  }
}
