







































import {
  Component, Prop, Vue,
} from 'vue-property-decorator';
import { VbSnackbarService } from 'bag-of-holding-library';
import LabelSlot from '@/shared/slots/label-slot.component.vue';
import PersistSkill from '@/domain/drd-2.0/entity/persist-skill.entity'
import SkillService from '@/domain/drd-2.0/service/skill.service';
import FormSkillComponent from '../form/form-skill.component.vue'

@Component({
  components: {
    LabelSlot,
    FormSkillComponent,
  },
})
export default class CreateSkillModal extends Vue {
  @Prop({
    type: Boolean,
    default: false,
  }) open!: boolean;

  skill: PersistSkill = new PersistSkill();

  loading: boolean = false;

  closeModal() {
    this.skill = new PersistSkill();
    this.$emit('close');
  }

  save() {
    const { formComponent } = this.$refs as any;
    const { form } = formComponent.$refs;
    if (!form.validate()) return;
    this.loading = true;
    SkillService.create(this.skill)
      .then(() => {
        VbSnackbarService.showSuccess('Competência criada com sucesso!')
        this.closeModal();
      })
      .catch((error) => {
        VbSnackbarService.showError(error.response.data.messages.toString());
      })
      .finally(() => this.loading = false);
  }

  update(event: any) {
    this.skill[event.field] = event.value;
  }
}
