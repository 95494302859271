





















































































import {
  Vue, Component, Prop,
} from 'vue-property-decorator';
import { VbSnackbarService, VbTooltipSlot } from 'bag-of-holding-library';
import ModalComponent from '@/shared/modal/modal.component.vue';
import PercentageTextField from '@/shared/fields/percentage-text-field.component.vue';
import LabelSlot from '@/shared/slots/label-slot.component.vue';
import MonthYearSelect from '@/shared/month-year-picker/month-year-select.component.vue';
import SalaryReadjustmentParametrization from '@/domain/salary-reajustment-parametrizations/entity/salary-readjustment-parametrization.entity';
import SalaryReadjustmentParametrizationService from '@/domain/salary-reajustment-parametrizations/service/salary-readjustment-parametrization.service';

@Component({
  components: {
    PercentageTextField,
    ModalComponent,
    LabelSlot,
    VbTooltipSlot,
    MonthYearSelect,
  },
})
export default class SalaryReadjustmentParametrizationModal extends Vue {
  @Prop() showSalaryReadjustmentModal!: boolean;

  @Prop() salaryReadjustmentId!: string;

  salaryReadjustment: SalaryReadjustmentParametrization =
    new SalaryReadjustmentParametrization();

  loadingSaveButton = false;

  loading: boolean = false;

  validSinceInputRules = [
    value => !!value || 'Campo obrigatório',
  ]

  save() {
    const { form }: any = this.$refs;
    if (form.validate()) {
      if (this.salaryReadjustmentId) {
        this.update();
        return;
      }
    }
    this.createParametrization();
  }

  close() {
    this.$emit('close');
  }

  async created() {
    if (this.salaryReadjustmentId) {
      this.loading = true;
      await this.getSalaryParamentrization();
    }
  }

  createParametrization() {
    this.loadingSaveButton = true;
    SalaryReadjustmentParametrizationService
      .createParametrization(this.salaryReadjustment)
      .then(() => {
        VbSnackbarService.showSuccess('Parametrização de Reajuste Salarial criada com sucesso!');
        this.close();
      })
      .catch(VbSnackbarService.handleHttpError)
      .finally(() => this.loadingSaveButton = false);
  }

  update() {
    this.loadingSaveButton = true;
    SalaryReadjustmentParametrizationService
      .updateParametrization(this.salaryReadjustment)
      .then(() => VbSnackbarService.showSuccess('Parametrização de Reajuste Salarial salva com sucesso!'))
      .catch(VbSnackbarService.handleHttpError)
      .finally(this.close);
  }

  async getSalaryParamentrization() {
    await SalaryReadjustmentParametrizationService
      .findParametrizationById(this.salaryReadjustmentId)
      .then((response) => {
        this.salaryReadjustment = new SalaryReadjustmentParametrization(response);
      }).finally(() => this.loading = false);
  }
}

