










































import { Vue, Component, Prop } from 'vue-property-decorator';
import avatarUser from '@/assets/images/avatar-user.png';
import AdmissionDateAnniversary from '@/domain/admission-date-anniversary/admission-date-anniversary.entity';
import EmployeeAvatar from '../birthday/employee-avatar/employee-avatar.component.vue';


@Component({
  components: {
    EmployeeAvatar,
  },
})
export default class AdmissionDateAnniversaryComponent extends Vue {
    @Prop({
      type: Array,
      required: true,
    })
    admissionDateAnniversaries!: AdmissionDateAnniversary[];

    @Prop({
      type: String,
      required: true,
    })
    title!: string;

    yearNow = new Date().getFullYear();

    createAvatarUrl(avatarBase64: string): string {
      if (!avatarBase64) {
        return avatarUser
      }
      return `data:image/png;charset=utf-8;base64,${avatarBase64}`
    }
}
