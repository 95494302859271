import IndicatorData from './indicators.entity';

export default class CreatePerformanceDescriptionRequest {
  public assignmentId: string;

  public description: string;

  constructor(obj: any = {}) {
    this.assignmentId = obj.assignmentId;
    this.description = obj.description || '';
  }

  public static of(performanceDescription: any): CreatePerformanceDescriptionRequest {
    return new CreatePerformanceDescriptionRequest(performanceDescription);
  }
}
