







import { Vue, Component, Prop } from 'vue-property-decorator';
import SkillTypes from '../skill-types.json';

@Component
export default class SkillTypeChip extends Vue {
  @Prop({
    required: true,
  }) type!: string;

  skillTypes = SkillTypes;

  findType() {
    return this.skillTypes.filter(it => it.value === this.type)[0];
  }
}
