import HealthPlanDTO from '@/domain/health-plan/entity/health-plan-DTO';
import ActivityDTO from '@/domain/schedule/activitie/activity-dto.entity';
import DateFormat from '@/shared/utils/date-format';
import moment from 'moment';
import Agreement from './agreement.entity';
import EmployeeDTO from './employee-dto';
import ScoreDependentBenefits from './score-dependent-benefits';
import { Currency } from './professional-data.entity';

export default class Score {
  id: string;

  employee: EmployeeDTO;

  responsible: EmployeeDTO;

  countDate: string;

  nextCountDate: string;

  readjustmentPeriod: string;

  healthPlanEmployee: boolean;

  dentalPlanEmployee: boolean;

  lifeInsuranceEmployee: boolean;

  agreementList: Agreement[];

  healthPlanEmployeePayedByDB1: boolean;

  dentalPlanEmployeePayedByDB1: boolean;

  dependentBenefits: ScoreDependentBenefits[];

  activity: ActivityDTO | null;

  status: string;

  benefitObservation: string;

  healthPlan: HealthPlanDTO;

  observation: string;

  unscheduledScore: boolean = false;

  registration?: string;

  currency!: Currency;

  operationModalityId: string;

  constructor(obj: any) {
    this.id = obj.id || '';
    this.employee = this.createEmployee(obj.employee);
    this.responsible = this.createEmployee(obj.responsible);
    this.countDate = obj.countDate || '';
    this.nextCountDate = obj.nextCountDate || '';
    this.readjustmentPeriod = this.formatYearMonth(obj.readjustmentPeriod);
    this.healthPlanEmployee = obj.healthPlanEmployee || false;
    this.dentalPlanEmployee = obj.dentalPlanEmployee || false;
    this.lifeInsuranceEmployee = obj.lifeInsuranceEmployee || false;
    this.agreementList = this.createListeAgreement(obj.agreementList) || [];
    this.healthPlanEmployeePayedByDB1 = obj.healthPlanEmployeePayedByDB1 || false;
    this.dentalPlanEmployeePayedByDB1 = obj.dentalPlanEmployeePayedByDB1 || false;
    this.activity = this.createActivity(obj.activity)
    this.status = obj.status
    this.dependentBenefits = obj.dependentBenefits
      ? ScoreDependentBenefits.ofList(obj.dependentBenefits)
      : []
    this.benefitObservation = obj.benefitObservation || ''
    this.healthPlan = this.createHealthPlan(obj.healthPlan);
    this.observation = obj.observation || '';
    this.unscheduledScore = obj.unscheduledScore || false;
    this.registration = obj.registration || '';
    this.operationModalityId = obj.operationModalityId || '';
  }

  createListeAgreement(agreementList: any[]): Agreement[] {
    if (!agreementList || agreementList.length === 0) return Agreement.createInitialAgreements();
    return Agreement.ofList(agreementList)
  }

  public static of(obj: any): Score {
    return new Score(obj || {})
  }

  public static ofRead(obj: any): Score {
    const score = new Score(obj);
    if (!obj.agreementList || obj.agreementList.length === 0) {
      score.agreementList = []
    }
    return score;
  }

  public static ofList(obj: any[]): Score[] {
    return obj.map(this.of);
  }

  private createEmployee(employee): EmployeeDTO {
    if (employee === null) return EmployeeDTO.of({});
    return EmployeeDTO.of(employee);
  }

  private createActivity(activity): ActivityDTO | null {
    if (activity === undefined || activity === null) return null
    return ActivityDTO.of(activity);
  }

  private createHealthPlan(healthPlan): HealthPlanDTO {
    if (healthPlan) return HealthPlanDTO.of(healthPlan)
    return HealthPlanDTO.of({});
  }

  toRequest() {
    return {
      ...this,
      readjustmentPeriod: DateFormat.monthYearFromFormated(this.readjustmentPeriod),
      healthPlan: this.healthPlanEmployee && this.healthPlanEmployeePayedByDB1
        ? this.healthPlan.id
        : null,
    };
  }

  formatYearMonth(value) {
    if (value === undefined || value === null) return '';
    return DateFormat.monthYearToFormated(value)
  }

  agreementListWithoutTotal(): Agreement[] {
    return this.agreementList.filter(agreement => agreement.description !== 'Total')
  }

  agreementTotal(): Agreement[] {
    return this.agreementList.filter(agreement => agreement.description === 'Total')
  }

  get previousTotal(): number {
    return this.agreementListWithoutTotal()
      .filter(agreement => agreement.numericField)
      .reduce((sum, agreement) => sum + agreement.previousAgreementValue, 0)
  }

  get newAgreementTotal(): number {
    return this.agreementListWithoutTotal()
      .filter(agreement => agreement.numericField)
      .reduce((sum, agreement) => sum + agreement.newAgreementValue, 0)
  }

  total(): number {
    const totalAgreement = this.agreementTotal()
    totalAgreement[0].newAgreement = this.newAgreementTotal.toFixed(2).toString();
    totalAgreement[0].previousAgreement = this.previousTotal.toFixed(2).toString();
    const persentTotal = totalAgreement[0].persentReadjustment
    return parseFloat(persentTotal);
  }

  get formatCountDate() {
    return moment(this.countDate).format('DD/MM/YYYY');
  }
}
