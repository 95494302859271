import RouteCheckerTo from '@/views/route-checker';
import ScoreDescriptionRead from './read/score-description-read.view.vue'
import ScoreView from './score.view.vue';

export default [
  {
    path: '/score',
    name: 'Acordos',
    component: ScoreView,
    beforeEnter: RouteCheckerTo.hasAccessReadAgreement,
  },
  {
    path: '/score/:id',
    name: 'Visualizar Acordos',
    component: ScoreDescriptionRead,
    beforeEnter: RouteCheckerTo.hasAccessReadAgreement,
  },
];
