











import { Vue, Component, Prop } from 'vue-property-decorator';

@Component
export default class ErrorMessageComponent extends Vue {
  @Prop({
    type: String,
    default: '',
  }) message!: string;
}
