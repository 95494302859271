






































































import MobileHandler from '@/shared/utils/mobile-handler';
import { Vue, Component, Prop } from 'vue-property-decorator';
import PaginationComponent from '@/shared/pagination.component.vue';
import DrdFiltered from '@/domain/drd-2.0/entity/drd-filtered.entity';
import Headers from './header.json';

@Component({
  components: {
    PaginationComponent,
  },
})
export default class DrdTableComponent extends Vue {
  @Prop() items!: DrdFiltered[];

  @Prop({
    type: Boolean,
    default: false,
  }) loading!: boolean;

  @Prop() page!: any;

  @Prop({
    type: Boolean,
    default: false,
  }) hasUpdateDRD!: boolean;

  isMobile = false;

  headers = Headers;

  updateTable(pagination: any): void {
    this.$emit('update', pagination);
  }

  onResize() {
    this.isMobile = MobileHandler.isMobile();
  }

  updateDrd(id: string) {
    this.$router.push(`/drd/${id}`);
  }

  readDrd(assignmentId: string) {
    this.$router.push(`/drd/${assignmentId}/read`);
  }
}
