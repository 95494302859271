export default class Activity {
  value: string;

  description: string;

  private constructor(obj: any) {
    this.value = obj.value;
    this.description = obj.name;
  }

  public static of(obj: any): Activity {
    return new Activity(obj || {});
  }

  public static ofList(list: any): Activity[] {
    return list.map((value: any) => this.of(value));
  }
}
