export default class GodfatherFilter {
  employee?: string;

  role?: string;

  manager?: string;

  initialTrainingDate: string;

  finalTrainingDate: string;

  businessUnit?: string;

  needTraining: boolean = true;

  status: boolean = true;

  constructor(obj: any) {
    this.employee = obj.employee;
    this.role = obj.role;
    this.manager = obj.mananger;
    this.businessUnit = obj.businessUnit;
    this.initialTrainingDate = obj.initialTrainingDate || '';
    this.finalTrainingDate = obj.finalTrainingDate || '';
  }

  public static of(obj: any): GodfatherFilter {
    return new GodfatherFilter(obj || {})
  }

  public static ofList(obj: any[]): GodfatherFilter[] {
    return obj.map(this.of);
  }
}
