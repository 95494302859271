import EmployeeSchedule from '@/domain/schedule/employee/employee-schedule.entity';
import EmployeeScheduleItem from '@/domain/schedule/employee/employee-schedule-item.entity';
import DateFormat from '../../shared/utils/date-format';

export default class ScheduleDataTable {
  private _employees: EmployeeSchedule[];

  constructor(_employees: EmployeeSchedule[]) {
    this._employees = _employees;
  }

  get employees() {
    return this._employees;
  }

  get headers() {
    return [
      {
        text: 'Colaborador',
        value: 'name',
        class: 'data-table-header',
        sortable: false,
      },
    ];
  }

  get headersMonth() {
    if (this._employees && this._employees.length > 0) {
      return this._employees[0].schedule.map(item => this.parseColumnMonth(item));
    }
    return [];
  }

  parseColumnMonth(item: EmployeeScheduleItem) {
    return {
      text: this.monthLabel(item.date),
      sortable: false,
      width: '250px',
      class: this.getMonthClass(item.date),
      align: 'center',
    };
  }

  monthLabel(date: string) {
    const splited = date.split('-');
    return `${this.fullMonth(splited[1])} ${splited[0]}`;
  }

  fullMonth(value: string) {
    return DateFormat.fullMonth(value);
  }

  private getMonthClass(date: string): string {
    const nowYear = new Date().getFullYear();
    const nowMonth: number = new Date().getMonth() + 1;
    const month: string = nowMonth.toString().length === 1 ? `0${nowMonth}` : `${nowMonth}`;
    const currentDate = `${nowYear}-${month}`;
    return date === currentDate ? 'data-table-header-current' : 'data-table-header';
  }
}
