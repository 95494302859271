









































































































































































































































































import {
  Vue, Component, Prop,
} from 'vue-property-decorator';
import { VMoney } from 'v-money';
import DatePickerField from '@/shared/date-picker/date-picker-field.component.vue'
import CardTitle from '@/shared/card/card-title.component.vue';
import LabelSlot from '@/shared/slots/label-slot.component.vue';
import splitContent from '@/shared/directive/split-content';
import DateHandler from '@/shared/utils/date-format';
import Attendance from '@/domain/attendance/entity/attendance.entity';
import AuthorityStore from '@/domain/security/authority.store';
import State from '@/domain/states/entity/state.entity';
import City from '@/domain/states/entity/city.entity';
import Rules from '@/shared/rules/rules';
import StateService from '@/domain/states/service/states.service';
import StringHandler from '@/shared/utils/string-handler';
import CorreiosAddress from '@/domain/correios-address/entity/correios-address.entity';
import AttendanceService from '@/domain/attendance/service/attendance.service';
import AuthenticationService from '@/domain/security/authentication.service';
import ResignationReason from '../resignation-reason.json';

@Component({
  components: { LabelSlot, DatePickerField, CardTitle },
  filters: {
    splitContent,
    formatDate(value: string) {
      return DateHandler.timestampToLocal(value.split('T')[0]);
    },
  },
  directives: {
    money: VMoney,
  },
})
export default class AttendanceResignationComponent extends Vue {
  @Prop() attendance!: Attendance;

  @Prop() isRegularAttendance!: boolean

  @Prop() isFollowUpInterview!: boolean

  @Prop() availableEmails!: string[];

  isAdministrator: boolean = false;

  isCurrentUserOrAdministrator: boolean = false;

  private loading = false;

  state: State = State.of({});

  states: State[] = [];

  statesOrder: string[] = [];

  liveCities: City[] = [];

  companyCities: City[] = [];

  rules: Rules = new Rules();

  correiosAddress: CorreiosAddress = CorreiosAddress.of({});

  cityNewCompanySearch: string = '';

  cityLiveSearch: string = '';

  private isCriticalTypes = [
    {
      value: false,
      description: 'Não',
    },
    {
      value: true,
      description: 'Sim',
    },
  ];

  money: object = {
    decimal: ',',
    thousands: '.',
    precision: 2,
    prefix: 'R$ ',
    masked: false,
    max: 9999.99,
    min: 0.01,
  };

  private resignationReason = ResignationReason.sort((reason1, reason2) => {
    if (reason1.description < reason2.description) return -1;
    if (reason1.description > reason2.description) return 1;
    return 0
  });

  attendanceAux: Attendance = Attendance.of({});

  refs: any = this.$refs

  private created() {
    this.getInitialData();
  }

  getInitialData() {
    StateService.findStates()
      .then((resp: any) => {
        this.states = resp;
        this.getPersonalData();
      });
    if (this.attendance.stateNewCompany) {
      this.getCompanyCities()
    }
    if (this.attendance.stateWhereWillLive) {
      this.getLiveCities()
    }
  }

  getPersonalData() {
    AttendanceService.getAttendance(this.attendance.id)
      .then((resp: any) => {
        this.attendanceAux = Attendance.of(resp);
        this.initIsAdministrator();
      }).finally(() => {
        this.checkIsCurrentUserOrAdministrator();
      });
  }

  checkIsCurrentUserOrAdministrator() {
    const availableEmails = this.availableEmails.map(el => el.toLowerCase());
    this.isCurrentUserOrAdministrator = availableEmails.includes(
      AuthenticationService.userLoggedIn().userName.toLowerCase(),
    ) || this.isAdministrator;
  }

  initIsAdministrator() {
    AuthorityStore.isAdministrator()
      .then((response: any) => this.isAdministrator = response);
  }

  getCompanyCities() {
    StateService.findCities(this.attendance.stateNewCompany)
      .then((resp: any) => {
        this.companyCities = resp;
        if (this.correiosAddress.localidade) {
          const viaCepCity = this.companyCities
            .find((city: City) => StringHandler
              .equalsNormalized(city.name, this.correiosAddress.localidade));
          if (viaCepCity) {
            this.attendance.cityNewCompany = viaCepCity.id;
          }
        }
      });
  }

  getLiveCities() {
    StateService.findCities(this.attendance.stateWhereWillLive)
      .then((resp: any) => {
        this.liveCities = resp;
        if (this.correiosAddress.localidade) {
          const viaCepCity = this.liveCities
            .find((city: City) => StringHandler
              .equalsNormalized(city.name, this.correiosAddress.localidade));
          if (viaCepCity) {
            this.attendance.cityWhereWillLive = viaCepCity.id;
          }
        }
      });
  }

  clearAndLoadLiveCity(value: string) {
    if (this.attendance.stateWhereWillLive !== value) {
      this.attendance.cityWhereWillLive = null
      this.cityLiveSearch = ''
    }

    this.attendance.stateWhereWillLive = value;

    if (value !== '' && value !== null) {
      this.getLiveCities();
    }
  }

  clearAndLoadCompanyCity(value: string) {
    if (this.attendance.stateNewCompany !== value) {
      this.attendance.cityNewCompany = null;
      this.cityNewCompanySearch = '';
    }

    this.attendance.stateNewCompany = value;

    if (value !== '' && value !== null) {
      this.getCompanyCities();
    }
  }

  resignationReasonDisabled(item: any) {
    return !item.active;
  }
}

