import AreaKeyItem from './area-key-item.entity';

export default class AreaKey {
  public id: string;

  public description: string;

  public objective: string;

  public active: boolean;

  public items: AreaKeyItem[];

  constructor(obj: any = {}) {
    this.id = obj.id || null;
    this.description = obj.description || '';
    this.objective = obj.objective || '';
    this.active = obj.active === undefined ? true : obj.active;
    this.items = obj.items === undefined ? [] : obj.items.map(it => new AreaKeyItem(it));
  }

  public pushNewItem(): void {
    this.items.push(new AreaKeyItem());
  }

  public deleteItem(index: number): void {
    this.items.splice(index, 1);
  }
}
