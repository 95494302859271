import SkillGroup from './skill-group.entity';

export default class Skill {
  id: string;

  whatPTBR: string;

  whatES: string;

  whatEN: string;

  group: SkillGroup;

  type: string;

  createdBy: string;

  createdDate: string;

  modifiedBy: string;

  lastModifiedDate: string;

  isSelectable: boolean = false;

  constructor(obj: any = {}) {
    this.id = obj.id || '';
    this.whatPTBR = obj.whatPTBR || '';
    this.whatES = obj.whatES || '';
    this.whatEN = obj.whatEN || '';
    this.group = obj.group || { id: '', name: '' };
    this.type = obj.type || '';
    this.createdBy = obj.createdBy || '';
    this.createdDate = obj.createdDate || '';
    this.modifiedBy = obj.modifiedBy || '';
    this.lastModifiedDate = obj.lastModifiedDate || '';
    this.isSelectable = obj.isSelectable || false;
  }
}
