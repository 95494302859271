export default class ActivitySummary {
    id: string;

    name: string;

    expectedDate: string;

    constructor(obj: any = {}) {
      this.id = obj.id || '';
      this.name = obj.name || '';
      this.expectedDate = obj.expectedDate || '';
    }

    public static of(obj: any): ActivitySummary {
      return new ActivitySummary(obj || {});
    }

    public static ofList(obj: any): ActivitySummary[] {
      return obj.map((value:any) => this.of(value));
    }
}
