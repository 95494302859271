import Indicators from '@/domain/drd/entity/indicators.entity';
import GoalAchievementTypes from '../form/tabs/indicators/goal-achievement-types.json';
import GoalMaskTypes from '../form/tabs/indicators/goal-mask-types';
import GoalMeasurementTypes from '../form/tabs/indicators/goal-measurement-types.json';

export default class IndicatorsUtils {
  goalAchievementTypes = GoalAchievementTypes;

  goalMaskTypes = GoalMaskTypes;

  getSymbol(achievementType: string): string {
    if (!achievementType) return '';
    const type: any = GoalAchievementTypes.find(it => it.value === achievementType);
    return type.symbol;
  }

  getMeasurementUnitLabel(measurementType: string): string {
    if (!measurementType) return '';
    const type: any = GoalMeasurementTypes.find(it => it.value === measurementType);
    return type.text;
  }

  showGoalFullAchievement(indicator: Indicators): boolean {
    if (
      indicator
      && indicator.indicator
      && indicator.goalAchievementType
      && indicator.goalValue
      && indicator.goalMaskType
      && indicator.goalMeasurementUnit
    ) {
      return true;
    }
    return false;
  }

  formatIndicatorMinGoal(indicator: Indicators) {
    return this.formatIndicator(indicator, indicator.goalValue);
  }

  formatIndicatorMinGoalWithoutName(indicator: Indicators) {
    return this.formatIndicatorWithoutName(indicator, indicator.goalValue);
  }

  showMaxGoalFullAchievement(indicator: Indicators): boolean {
    if (
      indicator
      && indicator.indicator
      && indicator.goalAchievementType
      && indicator.goalMaxValue
      && indicator.goalMaskType
      && indicator.goalMeasurementUnit
    ) {
      return true;
    }
    return false;
  }

  formatIndicatorMaxGoal(indicator: Indicators) {
    return this.formatIndicator(indicator, indicator.goalMaxValue);
  }

  addMaskToGoal(goal: number, mask: string): string {
    if (goal === null || goal === undefined || mask === null || mask === undefined) return '';
    const foundMask = GoalMaskTypes.find(it => it.value === mask);
    if (!foundMask) return `${goal}`;
    return foundMask.format(goal);
  }

  private formatIndicator(indicator: Indicators, value: number): string {
    return `${indicator.indicator}
      ${this.getSymbol(indicator.goalAchievementType)}
      ${this.addMaskToGoal(value, indicator.goalMaskType)}
      /${this.getMeasurementUnitLabel(indicator.goalMeasurementUnit)}`;
  }

  private formatIndicatorWithoutName(indicator: Indicators, value: number): string {
    return `${this.getSymbol(indicator.goalAchievementType)}
      ${this.addMaskToGoal(value, indicator.goalMaskType)}
      /${this.getMeasurementUnitLabel(indicator.goalMeasurementUnit)}`;
  }
}
