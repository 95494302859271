import DateFormat from '@/shared/utils/date-format';

export default class PerformanceDescriptionFiltered {
  id: string;

  roleName: string;

  assignmentName: string;

  ecosystemName: string;

  cbo: string;

  createdDate: string;

  lastModifiedDate: string;

  constructor(obj: any = {}) {
    this.id = obj.id;
    this.roleName = obj.roleName || '';
    this.assignmentName = obj.assignmentName || '';
    this.ecosystemName = obj.ecosystemName || '';
    this.cbo = obj.cbo || '-';
    this.createdDate = DateFormat.dateTimeFormated(obj.createdDate) || '-'
    this.lastModifiedDate = DateFormat.dateTimeFormated(obj.lastModifiedDate) || '-'
  }

  public static of(performanceDescriptionFiltered: any): PerformanceDescriptionFiltered {
    return new PerformanceDescriptionFiltered(performanceDescriptionFiltered);
  }

  public static ofList(obj: any[]): PerformanceDescriptionFiltered[] {
    return obj.map(this.of);
  }

  get title(): string {
    return `DRD - ${this.roleName} - CBO ${this.cbo}`;
  }
}
