


























import MobileHandler from '@/shared/utils/mobile-handler';
import { Vue, Component, Prop } from 'vue-property-decorator'
import CandidateApplicationHeader from './candidate-application-headers.json';

@Component
export default class CanidateApplicationTable extends Vue {
  @Prop() applications!: any[];

  headers = CandidateApplicationHeader

  loading: boolean = false;

  sortBy: Array<string> = [];

  sortDesc: boolean[] = [true];

  direction: string = 'desc';

  sort: string = '';

  page: any = {
    itemsPerPage: 10,
    page: 1,
    sortBy: this.sortBy,
    sortDesc: this.sortDesc,
    total: 0,
    pageCount: 0,
  }

  private isMobile = false;

  private onResize() {
    this.isMobile = MobileHandler.isMobile();
  }
}

