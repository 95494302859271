















































































































































import moment from 'moment';
import { Vue, Component, Prop } from 'vue-property-decorator';
import Agreement from '@/domain/employees/entity/agreement.entity';
import LabelSlot from '@/shared/slots/label-slot.component.vue';
import Score from '@/domain/employees/entity/score.entity';
import DateFormat from '@/shared/utils/date-format';
import healthPlanRegions from '@/views/employees/profile/tab-components/score/health-plan-type.json';
import { Currency } from '@/domain/employees/entity/professional-data.entity';
import EmployeeService from '@/domain/employees/service/employee.service';
import AgreementsTable from './agreements-table.component.vue';
import DependentBenefits from './dependent-benefits-table.component.vue';

@Component({
  components: {
    AgreementsTable,
    LabelSlot,
    DependentBenefits,
  },
})
export default class ScoreDescriptionReadComponent extends Vue {
  @Prop({
    type: [Score, Object],
    required: true,
  })
  score!: Score;

  @Prop({
    type: Agreement,
    default: undefined,
  })
  totalAgreement!: Agreement;

  @Prop({
    type: Boolean,
    default: false,
  })
  hideVerifyConclusion!: boolean;

  currency: Currency | null = null;

  formatFullDate(fullDate: string): string {
    if (!fullDate) return '-';
    return DateFormat.timestampToLocal(fullDate);
  }

  formatYearMonth(yearMonth: string): string {
    if (!yearMonth) return '-';
    moment.locale('pt-br')
    return moment(yearMonth, 'MM/YYYY').format('MMMM/YYYY');
  }

  formatBoolean(boolValue: boolean): string {
    if (boolValue === null || boolValue === undefined) return '-';
    return boolValue ? 'Sim' : 'Não';
  }

  formatString(value: string): string {
    if (!value) return '-';
    return value;
  }

  formatHealthPlanRegion(region: string): string {
    const matchingRegion: any = healthPlanRegions.find(it => it.value === region);
    return matchingRegion ? matchingRegion.description : '-';
  }

  verifyConclusion(): void {
    this.$emit('verifyConclusion');
  }

  created() {
    EmployeeService.getCurrencyByEmployeeId(this.score.employee.id).then((currency: Currency) => {
      this.currency = currency;
    });
  }
}
