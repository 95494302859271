import axios from 'axios';
import FilteredPersonalData from '../entity/filtered-personal-data.entity';
import PersonalDataFilter from '../entity/personal-data-filter.entity';
import PersonalData from '../entity/personal-data.entity';
import PersonalDataRequest from '../entity/personal-data-request.entity';

export default class PersonalDataService {
  public static findData(id: string): Promise<PersonalData> {
    return axios.get(`/api/employees/${id}/personal`)
      .then((response: any) => PersonalData.of(response.data));
  }

  public static saveData(id: string, el: PersonalDataRequest): Promise<void> {
    return axios.post(`/api/employees/${id}/personal`, el)
      .then((resp: any) => resp);
  }

  public static exportPersonalData(filter: PersonalDataFilter): Promise<FilteredPersonalData[]> {
    return axios.get('/api/personal/export', {
      params: {
        state: filter.state,
        city: filter.city,
        businessUnit: filter.businessUnit,
        workplace: filter.workplace,
        gender: filter.gender,
        maritalStatusType: filter.maritalStatus,
        hasChildren: filter.hasChildren,
        active: filter.active,
        concludedForm: filter.concludedForm,
      },
    })
      .then((resp: any) => FilteredPersonalData.ofList(resp.data));
  }
}
