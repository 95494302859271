
export default class WageFilter {
  role?: string;

  seniority?: string;

  constructor(obj: any) {
    this.role = obj.role;
    this.seniority = obj.seniority;
  }

  public static of(value: any): WageFilter {
    return new WageFilter(value || {});
  }

  public static ofList(response: any): WageFilter[] {
    return response.data.map(this.of);
  }
}
