











































































import { Vue, Component, Prop } from 'vue-property-decorator';
import PaginationComponent from '@/shared/pagination.component.vue';
import ModalWarning from '@/shared/modal/modal-warning.component.vue';
import RoleDrdList from '@/domain/drd/entity/roleDrdList.entity';
import MobileHandler from '@/shared/utils/mobile-handler';
import RoleAssignment from '@/domain/drd/entity/role.assignment.entity';

@Component({ components: { PaginationComponent, ModalWarning } })
export default class RoleList extends Vue {
  @Prop({
    type: Array,
    default: () => ([]),
  })
  roles!: RoleDrdList[];

  @Prop({
    type: Boolean,
    default: false,
  })
  loading!: boolean;

  @Prop() page!: any;

  @Prop() readRoles!: boolean;

  @Prop() inactiveRoles!: boolean;

  showDeleteConfirmationDialog: boolean = false;

  inactivateRoleId: string | null = null;

  isMobile = false;

  formatAssignments(assignments: RoleAssignment[]): string {
    return assignments.map((el: any) => el.description).join(', ');
  }

  headers: any[] = [
    {
      value: 'name',
      text: 'Cargo',
      align: 'left',
      width: '40%',
    },
    {
      value: 'assignments',
      text: 'Papel',
      width: '40%',
      sortable: false,
    },
    {
      value: 'cbo',
      text: 'CBO',
      align: 'center',
      width: '10%',
    },
    {
      value: 'active',
      text: 'Ativo?',
      align: 'center',
      width: '10%',
    },
    {
      value: 'actions',
      text: 'Ações',
      align: 'center',
      sortable: false,
    },
  ];

  onResize() {
    this.isMobile = MobileHandler.isMobile();
  }

  updateSortBy(sortBy: any): void {
    this.updateTable({
      ...this.page,
      sortBy,
    });
  }

  updateSortDesc(sortDesc: any): void {
    this.updateTable({
      ...this.page,
      sortDesc,
    });
  }

  updateTable(page: any): void {
    this.$emit('update', page);
  }

  editItem(role: RoleDrdList): void {
    this.$emit('edit', role.id);
  }

  openDeleteDialog(role: RoleDrdList): void {
    if (!role.active) return;
    this.inactivateRoleId = role.id;
    this.showDeleteConfirmationDialog = true;
  }

  closeDeleteConfirmationDialog(): void {
    this.inactivateRoleId = null;
    this.showDeleteConfirmationDialog = false;
  }

  deleteItem(): void {
    if (this.inactivateRoleId === null) return;
    this.$emit('inactivate', this.inactivateRoleId);
    this.closeDeleteConfirmationDialog();
  }

  checkInactiveItem(item: RoleDrdList): boolean {
    if (!this.inactiveRoles) {
      return true;
    }
    return !item.active;
  }
}
