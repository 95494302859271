




































































































































































































import {
  Vue, Prop, Component, Emit, Watch,
} from 'vue-property-decorator';
import { VbLoadingService, VbSnackbarService, VbTooltipSlot } from 'bag-of-holding-library';
import FilePickerButton from '@/shared/buttons/file-picker-button.component.vue'
import EnumObject from '@/domain/enum-object.entity';
import LabelSlot from '@/shared/slots/label-slot.component.vue';
import ActivityService from '@/domain/schedule/activitie/activity.service';
import { hasManagerAuthority, hasAdministratorOrManagerAuthority, hasAdministratorAuthority } from '@/shared/directive/has-authority.directive';
import DatePickerField from '@/shared/date-picker/date-picker-field.component.vue';
import DateFormat from '@/shared/utils/date-format';
import TextArea from '@/shared/fields/text-area.component.vue';
import ActivityDetailed from '@/domain/schedule/activitie/activity-detailed.entity';
import TalentLevelService from '@/domain/talent-level/service/talent-level.service';
import TalentLevel from '@/domain/talent-level/entity/talent-level.entity';
import TalentLevelLabel from '@/shared/talent-level-label.component.vue';
import EmployeeTalentLevelService from '@/domain/employees/service/employee-talent-level.service';
import ManageActivityListItemActionButtons from './manage-activity-list-item-action-buttons.component.vue';
import FileList from './manage-activity-file-list.component.vue';
import UpdateStatusModal from './update-activity-status-modal/update-activity-status-modal.component.vue';

@Component({
  components: {
    'text-area': TextArea,
    'action-buttons': ManageActivityListItemActionButtons,
    FilePickerButton,
    FileList,
    LabelSlot,
    DatePickerField,
    UpdateStatusModal,
    TalentLevelLabel,
    VbTooltipSlot,
  },
  directives: {
    hasManagerAuthority,
    hasAdministratorOrManagerAuthority,
    hasAdministratorAuthority,
  },
})
export default class ManageActivityListItemContents extends Vue {
  @Prop({
    required: true,
  })
  activity!: ActivityDetailed;

  @Prop() deleteLoading!: boolean;

  @Prop({
    type: Boolean,
    default: false,
  })
  isUpdateActivity!: boolean;

  @Prop({
    type: Boolean,
    default: false,
  })
  isUploadFiles!: boolean;

  @Prop({
    type: Boolean,
    default: false,
  })
  isDeleteActivity!: boolean;

  @Prop({
    type: Boolean,
    default: false,
  })
  isUpdateActivityStatus!: boolean;

  @Prop({ type: Boolean, default: false })
  isReadEmployeeTalentLevel!: boolean;

  @Prop({
    type: String,
    required: true,
  }) employeeId!: string;

  @Prop({
    type: String,
    required: true,
  }) username!: string;

  newActivity: ActivityDetailed = new ActivityDetailed(this.activity);

  talentLevels: TalentLevel[] = [];

  loadingTalentLevels: boolean = true;

  observation = '';

  selectFile = null;

  executionRules = [];

  updateStatusState = false;

  currencyName!: string;

  actualTalentLevel: TalentLevel = new TalentLevel();

  disableActivityMessage: string = 'Informe o nível de talento do colaborador'

  updateActivityStatus() {
    this.updateStatusState = true;
  }

  closeUpdateModal() {
    this.updateStatusState = false;
  }

  @Emit('redBorder')
  redBorder(value: boolean): boolean {
    return value;
  }

  @Watch('activity')
  activityWatch() {
    this.newActivity = new ActivityDetailed(this.activity);
  }

  created() {
    this.activity.applyResult();
    if (!this.activity.isApproved()) {
      this.saveResult(this.activity.result);
    }
    this.getEmployeeIdByActivity();
    if (this.isReadEmployeeTalentLevel) {
      this.loadTalentLevels();
      this.getActualTalentLevel();
    }
  }

  getEmployeeIdByActivity() {
    ActivityService.getActivityById(this.activity.id).then((response) => {
      this.employeeId = response.employee.id;
    });
  }

  refresh() {
    this.$emit('refresh')
  }

  get status() {
    return this.newActivity.statusValue;
  }

  get showTalentLevelSelect() {
    return this.activity.name !== 'PDI de Entrada' && this.activity.name !== 'Feed 45'
  }

  rejectActivity(): void {
    if (!this.observation) {
      this.redBorder(true);
      VbSnackbarService.showError('Por favor, informe um motivo para recusar a atividade.');
      return;
    }
    this.$emit('reject', this.observation);
  }

  approveActivity(): void {
    this.redBorder(false);
    this.$emit('approve', this.observation);
  }

  deleteFile(fileId: string) {
    this.activity.removeFile(fileId);
  }

  deleteActivity() {
    this.$emit('deleteActivity', this.observation)
  }

  saveResult(result: EnumObject) {
    if (!this.activity.isApproved() && !this.activity.result.equals(result)) {
      VbLoadingService.show();
      ActivityService.saveResult(this.activity.id, result.name)
        .then((response: any) => this.activity.result = result)
        .catch((err: any) => VbSnackbarService.handleHttpError(err))
        .finally(() => VbLoadingService.hide());
    }
  }

  isTalentLevelDisabled(talentLevel: TalentLevel): boolean {
    return !talentLevel.active;
  }

  saveActivityTalentLevel(talentLevel: TalentLevel) {
    this.loadingTalentLevels = true;
    EmployeeTalentLevelService.update(this.employeeId, this.activity.id, talentLevel.id)
      .then(() => {
        this.actualTalentLevel = talentLevel;
        VbSnackbarService.showSuccess('Nível de talento atualizado com sucesso!');
      })
      .catch((err: any) => VbSnackbarService.handleHttpError(err))
      .finally(() => {
        this.loadingTalentLevels = false;
      });
  }

  loadTalentLevels(): void {
    this.loadingTalentLevels = true;
    TalentLevelService.getAllTalentLevels()
      .then((talentLevels: TalentLevel[]) => {
        this.talentLevels = talentLevels;
      })
      .catch(VbSnackbarService.handleHttpError)
      .finally(() => {
        this.loadingTalentLevels = false;
      });
  }

  getActualTalentLevel() {
    TalentLevelService.getTalentLevelByEmployeeId(this.employeeId)
      .then((response: TalentLevel) => {
        this.actualTalentLevel = response;
      }).catch((error) => {
        this.actualTalentLevel.name = 'Nível de talento não encontrado';
      });
  }

  saveExecution(executionDate: string) {
    if (!this.activity.isApproved()) {
      if (this.activity.isExecutionDateValid(executionDate)) {
        VbLoadingService.show();
        ActivityService.saveExecution(this.activity.id, executionDate)
          .then((response: any) => this.activity.executionDate = executionDate)
          .catch((err: any) => VbSnackbarService.handleHttpError(err))
          .finally(() => VbLoadingService.hide());
      } else {
        this.executionRules = this.activity.executionDateRules(executionDate);
      }
    }
  }

  openScore(activityId: string, employeeId: string): void {
    this.$router.push({
      path: `/score-schedule/${activityId}/${employeeId}`,
    });
  }

  openPDI(value: string): void {
    this.$router.push({
      path: `/activity/${value}/pdi`,
    });
  }

  openFeedback(value: string): void {
    this.$router.push({
      path: `/activity/${value}/feedback`,
    });
  }

  openEmployeeSkillMapTab(username: string): void {
    this.$router.push({
      path: `/profile/${username}?tab=skill-map`,
    });
  }

  get activityNotApproved(): boolean {
    return this.activity.status !== 'Aprovada';
  }

  formatDate(value: string): string {
    return DateFormat.fullDate(value);
  }

  executedDateMessage(): string {
    if (this.activity.name === 'ADM') {
      return 'A data de execução será preenchida automaticamente após o upload do arquivo'
    }
    return 'A data de execução será preenchida após a conclusão da atividade'
  }
}
