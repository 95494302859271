import RouteCheckerTo from '@/views/route-checker';
import CreateKeyAreaView from './create-key-area.view.vue';
import KeyAreaView from './key-area.view.vue';
import UpdateKeyAreaView from './update-key-area.view.vue';

export default [
  {
    path: '/keyarea/list',
    name: 'Listagem de Area Chave',
    component: KeyAreaView,
    beforeEnter: RouteCheckerTo.hasAccessReadKeyAreasList,
  },
  {
    path: '/keyarea/create',
    name: 'Nova Area Chave',
    component: CreateKeyAreaView,
    beforeEnter: RouteCheckerTo.hasAccessCreateKeyAreas,
  },
  {
    path: '/keyarea/:id',
    name: 'Atualizar Area Chave',
    component: UpdateKeyAreaView,
    beforeEnter: RouteCheckerTo.hasAccessUpdateKeyAreas,
  },
]
