

















































































import { Vue, Component, Prop } from 'vue-property-decorator';
import PaginationComponent from '@/shared/pagination.component.vue';
import MobileHandler from '@/shared/utils/mobile-handler';
import InitialScoreFiltered from '@/domain/initial-score/entity/initial-score-filtered.entity';
import InitialScoreService from '@/domain/initial-score/service/initial-score.service';
import { VbSnackbarService } from 'bag-of-holding-library';
import ModalOptions from '@/shared/modal/modal-options.entity';
import ModalWarning from '@/shared/modal/modal-warning.component.vue';
import CopyComponent from '@/shared/copy.component.vue';
import DateFormat from 'bag-of-holding-library/src/utils/date-format';
import Headers from './initial-score-table-headers.json';

@Component({
  components: {
    PaginationComponent,
    ModalWarning,
    CopyComponent,
  },
})
export default class InitialScoreTable extends Vue {
  @Prop() initialScores!: InitialScoreFiltered[];

  @Prop({
    type: Boolean,
  }) loading!: boolean;

  @Prop() page!: any;

  headers = Headers;

  openModal: boolean = false;

  keyItemSelected: number = -1;

  isMobile = false;

  showModalWarning: boolean = false;

  loadingDelete: boolean = false;

  initialScoreDeleteId: string = '';

  get modalWarningOptions(): ModalOptions {
    return {
      title: 'Atenção!',
      titleSize: '20px',
      description: 'Ao clicar em continuar todos os dados seram deletados e não poderam ser recuperados. Deseja realmente excluir esse acordo inicial?',
      showOk: true,
      disabledOk: false,
      loadingOk: this.loadingDelete,
      labelOk: 'Sim, continuar',
      okColor: 'tabBlue',
      showCancel: true,
      labelCancel: 'Cancelar',
      width: 800,
    };
  }

  closeModal(event: any) {
    this.openModal = event;
  }

  onResize() {
    this.isMobile = MobileHandler.isMobile();
  }

  updateSortBy(sortBy: any): void {
    this.updateTable({
      ...this.page,
      sortBy,
    });
  }

  updateSortDesc(sortDesc: any): void {
    this.updateTable({
      ...this.page,
      sortDesc,
    });
  }

  updateTable(pagination: any) {
    this.$emit('update', pagination)
  }

  updateInitialScore(id: string) {
    this.$router.push(`/initial-score/${id}`);
  }

  deleteInitialScore() {
    this.loadingDelete = true
    InitialScoreService.delete(this.initialScoreDeleteId).then(() => VbSnackbarService.showSuccess('Acordo inicial deletado com sucesso!'))
      .catch(error => VbSnackbarService.handleHttpError(error))
      .finally(() => {
        this.loadingDelete = false
        this.$emit('sendFilter');
        this.closeModalWarning();
      })
  }

  closeModalWarning() {
    this.showModalWarning = false;
  }

  openModalWarning(id: string) {
    this.initialScoreDeleteId = id;
    this.showModalWarning = true;
  }
}
