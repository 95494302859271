import axios from 'axios';
import Candidate from '../entity/candidate.entity';

export default class CandidateService {
  public static findCandidateByName(name: string): Promise<Candidate[]> {
    return axios.get(`/api/candidate?name=${name}`)
      .then(({ data }) => data.map(it => new Candidate(it)));
  }

  public static existsByEmail(email: string) {
    return axios.get(`/api/public/candidate/exists/${email}`);
  }
}
