import Option from '@/shared/form/radio/option.entity';
import SkillTypeOption from '../skill/entity/skill-type-option';
import Skill from '../skill/entity/skill.entity';

export default class KeyAreaSkillType {
  name: string;

  description: string;

  isBoolean: boolean;

  options: SkillTypeOption[];

  skills: Skill[];

  constructor(obj: any = {}) {
    this.name = obj.name || '';
    this.description = obj.description || '';
    this.isBoolean = obj.isBoolean;
    this.options = obj.options.map((option: SkillTypeOption) => new SkillTypeOption(option));
    this.skills = obj.skills.map((skill: Skill) => new Skill(skill));
  }

  get optionsByType(): Option[] {
    if (this.isBoolean) {
      return [new Option({ text: 'Não', value: 0 }), new Option({ text: 'Sim', value: 1 }), new Option({ text: 'Não se aplica', value: -1 })]
    }
    return this.options
      .map((option: SkillTypeOption) => new Option({ text: option.name, value: option.value }))
  }
}
