import axios from 'axios'
import AssessmentForm from '../entity/assignment/assessment-form.entity'
import AssessmentResponse from '../entity/emp-assessment-response';
import AssessmentResultDetails from '../entity/emp-assessment-result-details';
import EmployeeAssessmentResult from '../entity/employee-assessment-result';
import AssessmentFilter from '../entity/assessment-filter.entity';

export default class AssessmentService {
  public static async getFormTemplateByEmployeeId(employeeId: string): Promise<AssessmentForm> {
    const { data } = await axios.get(`/api/assessment/form-template/${employeeId}`);
    return new AssessmentForm(data);
  }

  public static async getFormTemplateByAssignmentId(assignmentId: string): Promise<AssessmentForm> {
    const { data } = await axios.get(`/api/assessment/form-template/assignment/${assignmentId}`);
    return new AssessmentForm(data);
  }

  public static create(employeeId: string, assessmentResponse: AssessmentResponse): Promise<void> {
    return axios.post(`/api/assessment/${employeeId}`, assessmentResponse);
  }

  public static async createByAssignmentId(
    assignmentId: string,
    assessmentResponse: AssessmentResponse,
  ): Promise<void> {
    return axios.post(`/api/assessment/assignment/${assignmentId}`, assessmentResponse);
  }

  public static async getResultByEmployeeId(employeeId: string): Promise<EmployeeAssessmentResult> {
    return axios.get(`/api/employee/${employeeId}/assessment/result`)
      .then(response => new EmployeeAssessmentResult(response.data))
  }

  public static async getResultDetails(
    employeeId: string,
    assessmentId: string,
  ): Promise<AssessmentResultDetails[]> {
    return axios.get(`/api/employee/${employeeId}/assessment/${assessmentId}/result-details`)
      .then(response => response.data.map(it => new AssessmentResultDetails(it)));
  }

  public static async getResultsAndAdherence(
    filter: AssessmentFilter,
    page: number,
    size: number,
  ): Promise<any> {
    return axios.get('/api/assessment/results', {
      params: {
        employees: filter.hasEmployees ? filter.employees.toString() : null,
        managers: filter.hasManagers ? filter.managers.toString() : null,
        resultCenters: filter.hasResultCenters ? filter.resultCenters.toString() : null,
        businessUnits: filter.hasBusinessUnits ? filter.businessUnits.toString() : null,
        assignments: filter.hasAssignments ? filter.assignments.toString() : null,
        indirectSubordinates: filter.indirectSubordinates,
        alreadySent: filter.alreadySent,
        page: page - 1,
        size,
      },
    });
  }
}
