import RouteCheckerTo from '@/views/route-checker';
import Employees from './employees.view.vue';
import Table from './table/employee-table.view.vue';
import OrganizationalChart from './organizational-chart/organizational-chart.view.vue'
import Profile from './profile/employee-profile.view.vue';
import PersonalDataExport from './personal-data-export/personal-data-export.component.vue';
import VacationPayslip from './vacation-payslip/vacation-pavslip.view.vue';
import GodfatherExport from './godfather-export/godfather-export.component.vue';
import ResignationsListIndex from './resignations/list/index.vue';
import EmployeesSkillMap from './employees-skill-map/employee-skill-map-table.view.vue';

export default [
  {
    path: '/colaboradores',
    component: Employees,
    children: [
      {
        path: '/',
        name: 'Colaboradores',
        component: Table,
      },
      {
        path: '/estrutura-organizacional',
        name: 'Colaboradores/Estrutura Organizacional',
        component: OrganizationalChart,
      },
      {
        path: '/employees-skill-map',
        name: 'Skill Map',
        component: EmployeesSkillMap,
        beforeEnter: RouteCheckerTo.hasAccessSkillMap,
      },
      {
        path: '/profile/:username',
        name: 'Colaboradores/Perfil',
        component: Profile,
      },
      {
        path: '/personal-data-export',
        name: 'Colaboradores/DadosPessoais',
        component: PersonalDataExport,
        beforeEnter: RouteCheckerTo.hasAccessPersonalDataExport,
      },
      {
        path: '/vacation-payslip',
        name: 'Colaboradores/HoleriteFerias',
        component: VacationPayslip,
        beforeEnter: RouteCheckerTo.hasAccessImportVacationPayslip,
      },
      {
        path: '/godfather-export',
        name: 'Colaboradores/Apadrinhamento',
        component: GodfatherExport,
        beforeEnter: RouteCheckerTo.hasAccessGodfatherExport,
      },
      {
        path: '/resignations',
        name: 'Colaboradores/Desligamentos',
        component: ResignationsListIndex,
        beforeEnter: RouteCheckerTo.hasAccessResignations,
      },
    ],
  },
];
