





















































import { Vue, Component } from 'vue-property-decorator';
import CardTitle from '@/shared/card/card-title.component.vue';
import RiskAttendanceService from '@/domain/risk-attendance/service/risk-attendance.service';
import ManagerService from '@/domain/employees/service/employee.service';
import EmployeeService from '@/domain/schedule/employee/employee.service';
import FilteredRiskAttendance from '@/domain/risk-attendance/entity/filtered-risk-attendance';
import RiskAttendance from '@/domain/risk-attendance/entity/risk-attendance.entity';
import AuthorityStore from '@/domain/security/authority.store';
import AuthenticationService from '@/domain/security/authentication.service';
import { VbTooltipSlot } from 'bag-of-holding-library';
import TableComponent from './risk-attendance-table.component.vue';
import FilterComponent from './filter/filter.component.vue';
import EmployeeSummary from '../../../domain/schedule/employee/employee-summary.entity';
import Filter from '../../../domain/risk-attendance/entity/filter.entity';
import CareerService from '../../../domain/employees/service/career.service';
import UpdateAtribute from '../../../domain/employees/entity/update-atribute.entity';
import HeaderComponent from './risk-attendance-table-header.component.vue';

@Component({
  components: {
    FilterComponent, CardTitle, TableComponent, HeaderComponent, VbTooltipSlot,
  },
})
export default class RiskAttendances extends Vue {
  private attendances: FilteredRiskAttendance[] = [];

  private filterEmployees: EmployeeSummary[] = [];

  private businessUnit: UpdateAtribute[] = [];

  private lastFilterManager: string = '';

  private lastFilterEmployee: string = '';

  private lastFilterResponsible: string = '';

  private tableLoading: boolean = false;

  private filter: Filter = new Filter({});

  private attendance = RiskAttendance.of({});

  private isFirstFilter = true;

  sortDesc: boolean[] = [false];

  private pagination: any = {
    itemsPerPage: 10,
    page: 1,
    sortDesc: this.sortDesc,
    total: 0,
    pageCount: 0,
  }

  private managers: EmployeeSummary[] = [];

  private responsibles: EmployeeSummary[] = [];

  isManager: boolean = false;

  isAdministrator: boolean = false;

  hasDeleteRiskAttendance: boolean = false;

  managerName: string = '';

  hasCreateRiskAttendance: boolean = false;

  private setFilterValues(value: any) {
    if (this.pagination.itemsPerPage < value.itemsPerPage) {
      value.page = 1;
    }
    this.pagination = value;
    this.findRiskAttendance();
  }

  private created() {
    CareerService.getBusinessUnit().then(resp => this.businessUnit = resp);
    this.getHasCreateRiskAttendance();
    this.getHasDeleteRiskAttendance();
    this.isManagerAuth();
    this.getIsAdministrator();
  }

  private updateRiskAttendances(value: Filter) {
    this.isManagerAuth()
    this.initIsAdministrator()
    this.searchManagerName(value)
    if (value.equals(this.filter)) {
      this.filter = value;
      this.pagination.page = 1;
    }
    sessionStorage.setItem('storageRiskAttendanceFilter', JSON.stringify(this.filter));
    this.findRiskAttendance();
  }

  isManagerAuth() {
    return AuthorityStore.isManager()
      .then((response) => {
        this.isManager = response;
        this.applyInitialFilter();
      })
  }

  getIsAdministrator() {
    return AuthorityStore.isAdministrator()
      .then((response) => {
        this.isAdministrator = response;
        if (!this.isManager) {
          this.findRiskAttendance();
        }
      })
  }

  applyInitialFilter() {
    if (this.isManager) {
      this.searchManagerByName();
    }
  }

  searchManagerByName() {
    if (this.isManager && this.managerName === '') {
      this.managerName = AuthenticationService.userLoggedIn().name;
      this.findManagers(this.managerName);
    }
  }


  initIsAdministrator() {
    AuthorityStore.isAdministrator()
      .then((response: any) => this.isAdministrator = response);
  }

  getHasCreateRiskAttendance() {
    return AuthorityStore.hasCreateRiskAttendance()
      .then((response) => {
        this.hasCreateRiskAttendance = response;
      })
  }

  getHasDeleteRiskAttendance() {
    return AuthorityStore.hasDeleteRiskAttendance()
      .then((response) => {
        this.hasDeleteRiskAttendance = response;
      })
  }

  searchManagerName(value: Filter) {
    if (this.isManager && !this.isAdministrator && this.managerName === '') {
      ManagerService.getLoggedUserEmployeeId().then((response: string) => {
        value.manager = response;
      })
    }
  }

  private findRiskAttendance() {
    this.tableLoading = true;
    this.filter.prepareToSend();
    RiskAttendanceService.findRiskAttendance(this.pagination.page,
      this.pagination.itemsPerPage, this.filter)
      .then((response: any) => {
        this.attendances = FilteredRiskAttendance.ofList(response.content);
        this.pagination.total = response.totalElements || 0;
        this.pagination.pageCount = response.totalPages;
      })
      .finally(() => this.tableLoading = false);
  }

  private findManagers(value: string) {
    if ((this.lastFilterManager !== value) && value) {
      this.lastFilterManager = value;
      if (value.length > 2) {
        sessionStorage.setItem('managerRiskAttendanceStore', value)
        ManagerService.findManagersByName(value)
          .then((resp: EmployeeSummary[]) => {
            this.managers = resp;
          });
      }
    }
  }

  private findResponsibles(value: string) {
    if ((this.lastFilterResponsible !== value) && value) {
      this.lastFilterResponsible = value;
      if (value.length > 2) {
        sessionStorage.setItem('responsibleRiskAttendanceStore', value)
        EmployeeService.findEmployeesByName(value)
          .then((resp: EmployeeSummary[]) => this.responsibles = resp);
      }
    }
  }

  private findEmployeesByName(value: string) {
    if ((this.lastFilterEmployee !== value) && value) {
      this.lastFilterEmployee = value;
      if (value.length > 2) {
        sessionStorage.setItem('employeeRiskAttendanceStore', value)
        EmployeeService.findEmployeesByName(value)
          .then((resp: EmployeeSummary[]) => this.filterEmployees = resp);
      }
    }
  }

  private newRiskAttendance(): void {
    this.$router.push({
      path: '/new-risk-attendance/',
    })
  }
}
