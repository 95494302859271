import axios from 'axios';
import ResignationStaff from '../entity/resignation-staff.entity';
import StaffListRequest from '../entity/staff-list-request.entity';

export default class ResignationStaffService {
  public static findStaffEmails(): Promise<ResignationStaff[]> {
    return axios.get('/api/resignation/staff')
      .then((response: any) => ResignationStaff.ofList(response.data));
  }

  public static saveStaff(staff: StaffListRequest): Promise<void> {
    return axios.put('/api/resignation/staff', staff)
      .then((resp: any) => resp);
  }
}
