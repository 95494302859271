import DateFormat from '@/shared/utils/date-format';
import CandidateCultureGradeDetails from './candidate-culture-grade-details.entity';
import CandidateCultureGradeQuestionDetails from './candidate-culture-grade-details-question.entity';

export default class ExportCultureGradeDetails {
  candidateName: string;

  total: number;

  totalPercent: string;

  locusOfControlGrade: number;

  locusOfControlGradePercent: string;

  beFairDoRightDontRideGrade: number;

  beFairDoRightDontRideGradePercent: string;

  responseDate: string;

  questionPosition: number;

  questionTitle: string;

  value: string;

  comments: string;

  grade: number;

  maxGrade: number;

  constructor(
    answer: CandidateCultureGradeQuestionDetails,
    cultureGradeDetails: CandidateCultureGradeDetails,
  ) {
    this.candidateName = cultureGradeDetails.candidateName;
    this.total = cultureGradeDetails.total;
    this.totalPercent = this.toPorcentageFormat(cultureGradeDetails.totalPercent);
    this.locusOfControlGrade = cultureGradeDetails.locusOfControlGrade;
    this.locusOfControlGradePercent = this
      .toPorcentageFormat(cultureGradeDetails.locusOfControlGradePercent);
    this.beFairDoRightDontRideGrade = cultureGradeDetails.beFairDoRightDontRideGrade;
    this.beFairDoRightDontRideGradePercent = this
      .toPorcentageFormat(cultureGradeDetails.beFairDoRightDontRideGradePercent);
    this.responseDate = DateFormat.dateTimeFormated(cultureGradeDetails.responseDate);
    this.questionPosition = answer.questionPosition;
    this.questionTitle = answer.questionTitle;
    this.value = answer.value;
    this.comments = answer.comments;
    this.grade = answer.grade;
    this.maxGrade = answer.maxGrade;
  }

  toPorcentageFormat(value: number): string {
    return `${value.toFixed(2)}%`;
  }
}
