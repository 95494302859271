




















import {
  Component,
  Prop,
  Watch,
  Mixins,
} from 'vue-property-decorator';
import MenuMixin from './menu.mixin';

@Component
export default class MenuItem extends Mixins(MenuMixin) {
  @Prop({ type: String, required: true })
  private routerTo!: string;

  @Prop({ type: String, required: true })
  private icon!: string;

  @Prop({ type: String, required: true })
  private text!: string;

  @Prop({ type: String, default: 'green' })
  private color!: string;

  private active: boolean = false;

  private goToPage(): void {
    if (this.routerTo.indexOf('http') !== -1) {
      window.open(this.routerTo, '_blank');
      return;
    }
    this.$emit('selected');
    this.$router.push(this.routerTo);
  }

  private setActive(): void {
    this.active = this.routerTo === this.$route.fullPath;
  }

  private get iconColor(): string {
    return this.active ? '#00FFF0' : 'white'
  }

  private get textStyle(): string {
    return `color: ${this.iconColor};`;
  }

  @Watch('$route', { immediate: true, deep: true })
  private onRouteChange(): void {
    this.setActive();
  }

  private created(): void {
    this.setActive();
  }
}
