






















import { Vue, Component, Prop } from 'vue-property-decorator';
import LabelSlot from '@/shared/slots/label-slot.component.vue';
import Rules from '@/shared/rules/rules';

@Component({
  components: { LabelSlot },
})
export default class RadioComponent extends Vue {
  @Prop() alternatives!: any[];

  @Prop() position!: number;

  @Prop() title!: string;

  @Prop() type!: string;

  @Prop() required!: boolean;

  rules = new Rules();

  radioGroup: any = '';

  update() {
    this.$emit('update', this.position, this.radioGroup)
  }
}
