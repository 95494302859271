export interface EmployeeChartResponse {
  content: EmployeeOrganization;
  totalElements: number;
}

export interface EmployeeOrganization {
  id: string;
  name: string;
  businessUnit: string;
  resultCenter: string;
  roleName: string;
  children: EmployeeOrganization[];
}

export class ChartFilter {
  employeeId: string;

  constructor(obj: any) {
    this.employeeId = obj.employeeId || null;
  }
}
