































































































































import ModalOptions from '@/shared/modal/modal-options.entity';
import ModalMixin from '@/shared/modal/modal.mixin';
import Modal from '@/shared/modal/modal.component.vue';
import { Component, Mixins, Prop } from 'vue-property-decorator';
import ActionPlan from '@/domain/activity/entity/action-plan.entity';
import DateFormat from 'bag-of-holding-library/src/utils/date-format';
import LabelSlot from '@/shared/slots/label-slot.component.vue';
import PlanPriorityLegendComponent from '@/shared/plan-priority/plan-priority.component.vue';
import Priority from '@/domain/activity/entity/priority.entity';


@Component({
  components: {
    Modal,
    LabelSlot,
    PlanPriorityLegendComponent,
  },
})
export default class AllPlansModal extends Mixins(ModalMixin) {
  @Prop() items!: ActionPlan[];

  @Prop() priorityItems!: Priority[];

  planStateFilterSelected: string = 'OPEN';

  private get options(): ModalOptions {
    return {
      title: 'Todos os Planos',
      showCancel: true,
      labelCancel: 'Fechar',
      width: 700,
    };
  }

  panel = [];

  closeModal() {
    this.$emit('close');
  }

  changePlanState() {
    this.$emit('changePlanStateFilter', this.planStateFilterSelected);
  }

  planState = {
    OPEN: 'Aberto',
    DONE: 'Concluido',
    CANCELLED: 'Cancelado',
  }

  formatDate(date: string) {
    return DateFormat.fullDate(date)
  }
}
