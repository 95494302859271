








































































































































import { Vue, Component, Prop } from 'vue-property-decorator';
import { VbSnackbarService } from 'bag-of-holding-library';
import FormRadioComponent from '@/shared/form/radio/form-radio.component.vue';
import Skill from '@/domain/assessment/entity/skill/entity/skill.entity';
import RadioData from '@/shared/form/radio/radio-data.entity';
import LabelSlot from '@/shared/slots/label-slot.component.vue';
import AssessmentResponse from '@/domain/assessment/entity/emp-assessment-response';
import SkillResponse from '@/domain/assessment/entity/skill/entity/skill-response';
import AssessmentForm from '@/domain/assessment/entity/assignment/assessment-form.entity';
import Loading from '@/shared/loading/loading.component.vue';
import Rules from '@/shared/rules/rules';
import AssessmentService from '@/domain/assessment/service/assessment.service'
import SelectLanguageComponent from '@/views/survey/select-language.component.vue';
import KeyAreaSkillType from '@/domain/assessment/entity/assignment/key-area-skill-type';
import AssessmentIndicator from './components/indicator/assessment-indicator.component.vue';
import SkillOptionsDetails from './skill-options-details.component.vue';


@Component({
  components: {
    FormRadioComponent,
    LabelSlot,
    AssessmentIndicator,
    SkillOptionsDetails,
    Loading,
    SelectLanguageComponent,
  },
})
export default class AssessmentFormComponent extends Vue {
  @Prop({
    type: [AssessmentForm],
  }) assessmentForm!: AssessmentForm;

  @Prop({
    type: Boolean,
    default: false,
  }) loading!: boolean;

  @Prop({
    type: String,
    required: true,
  }) employeeId!: string;

  @Prop({
    type: String,
    required: true,
  }) assignmentId!: string;

  step: number = 1;

  assessmentResponse: AssessmentResponse = new AssessmentResponse();

  rules: Rules = new Rules();

  loadingSave: boolean = false;

  mandatoryFieldsMessageError: string = 'Por favor, responda todas as perguntas!';

  formsError: boolean = false;

  showAbout: boolean = false;

  languages = [
    {
      text: 'Brasil - pt-br',
      acronym: 'PT_BR',
      acronymFlag: 'BRA',
    },
    {
      text: 'Espanhol - es',
      acronym: 'ES',
      acronymFlag: 'ES',
    },
    {
      text: 'English - en',
      acronym: 'EN',
      acronymFlag: 'US',
    },
  ];

  languageSelectedAcronym: string = 'PT_BR';

  created() {
    this.mountSkillResponses();
  }

  get languageSelected() {
    return this.languageSelectedAcronym.replace('_', '');
  }

  createRadioData(skillType: KeyAreaSkillType) {
    return skillType.skills.map((skill: Skill) => new RadioData({
      position: skill.order,
      title: skill[`what${this.languageSelected}`],
      required: true,
      options: skillType.optionsByType,
      id: skill.keyAreaSkillId,
    }))
  }

  addSkillResponse(radioData: RadioData, value: any) {
    this.assessmentResponse.skillResponses
      .filter(skill => skill.keyAreaSkillId === radioData.id)[0].response = value;
  }

  mountSkillResponses() {
    this.assessmentForm.skillsType
      .forEach((type) => {
        this.assessmentResponse.skillResponses
          .push(...type.skills
            .map(skill => new SkillResponse({ keyAreaSkillId: skill.keyAreaSkillId })))
      })
  }

  nextStep(formRef: string, index: number = 0) {
    const form: any = this.getFormToValidate(formRef, index);
    if (!form.validate()) {
      VbSnackbarService.showError(this.mandatoryFieldsMessageError);
      return;
    }
    this.step = this.step + 1;
    this.moveToTop();
  }

  getFormToValidate(formRef: string, index: number) {
    if (formRef === 'form-2') {
      return this.$refs[formRef][index];
    }
    return this.$refs[formRef];
  }

  async create() {
    await this.validateAllForms();
    if (this.formsError) return;
    this.loadingSave = true;
    AssessmentService.createByAssignmentId(this.assignmentId, this.assessmentResponse)
      .then(() => {
        VbSnackbarService.showSuccess('Respostas envidas com sucesso!');
        this.$router.push({ path: `/assessment/${this.employeeId}/result`, query: { lang: 'PT_BR' } });
      }).catch(error => VbSnackbarService.handleHttpError(error))
      .finally(() => {
        this.loadingSave = false;
      })
  }

  moveToTop() {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }

  async validateAllForms() {
    const form1: any = this.$refs['form-1'];
    if (form1.validate()) return;
    VbSnackbarService.showError(this.mandatoryFieldsMessageError);
    this.formsError = true;
    const forms: any = this.$refs['form-2'];
    forms.forEach((form: any) => {
      if (form.validate()) return;
      VbSnackbarService.showError(this.mandatoryFieldsMessageError);
      this.formsError = true;
    })
  }
}
