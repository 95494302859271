












































































































































































import { Vue, Component, Prop } from 'vue-property-decorator';
import { VbSnackbarService } from 'bag-of-holding-library';
import DrdService from '@/domain/drd-2.0/service/drd.service';
import Drd from '@/domain/drd-2.0/entity/drd.entity';
import CardTitle from '@/shared/card/card-title.component.vue';
import LabelSlot from '@/shared/slots/label-slot.component.vue';
import KeyAreaSkill from '@/domain/drd-2.0/entity/key-area-skill.entity';
import KeyArea from '@/domain/drd-2.0/entity/key-area.entity';
import Loading from '@/shared/loading/loading.component.vue';
import DrdReviewCheckboxComponent from '@/shared/checkbox/drd-review-checkbox.component.vue';
import EmployeeService from '@/domain/employees/service/employee.service';
import SkillTypes from './skill/skill-types.json';
import SelectLanguageComponent from '../survey/select-language.component.vue';
import IndicatorsUtils from '../drd/commons/indicators-utils';

@Component({
  components: {
    CardTitle,
    LabelSlot,
    Loading,
    SelectLanguageComponent,
    DrdReviewCheckboxComponent,
  },
})
export default class ReadDrdView extends Vue {
  @Prop({
    type: Boolean,
    default: true,
  }) showPrintButton!: boolean;

  drd: Drd = new Drd();

  skillTypes = SkillTypes;

  loading: boolean = false;

  languages = [
    {
      text: 'Brasil - pt-br',
      acronym: 'PT_BR',
      acronymFlag: 'BRA',
    },
    {
      text: 'Espanhol - es',
      acronym: 'ES',
      acronymFlag: 'ES',
    },
    {
      text: 'English - en',
      acronym: 'EN',
      acronymFlag: 'US',
    },
  ];

  languageSelectedAcronym: string = 'PT_BR';

  indicatorUtils: IndicatorsUtils = new IndicatorsUtils();

  existsDrdReview: boolean = false;

  get selectedLanguage() {
    return this.languageSelectedAcronym.replace('_', '');
  }

  async created() {
    this.loading = true;
    await this.getData();
    setTimeout(() => {
      this.$emit('finish');
    }, 1000);
  }

  async getData() {
    DrdService.getByAssignmentId(this.$route.params.id)
      .then((data) => {
        this.drd = data;
      }).catch((error) => {
        VbSnackbarService.showError(error.response.data.messages.toString());
      }).finally(() => {
        this.loading = false;
      })
    this.existsDrdReview = await EmployeeService.checkExistsDrdReviewOpen(this.$route.params.id);
  }

  expandKeyArea(id: string) {
    const keyAreaFiltered = this.drd.keyAreas.filter(it => it.id === id);
    if (keyAreaFiltered.length === 0) return;
    keyAreaFiltered[0].selected = !keyAreaFiltered[0].selected
  }

  getHeaders(keyArea: KeyArea) {
    return [
      {
        text: 'Item',
        width: '60%',
        value: '',
      },
      ...keyArea.levels.map(level => ({ text: level.name, value: '' })),
    ]
  }

  getExpected(item: KeyAreaSkill, header) {
    const expectedFiltered = item.levels.filter(it => it.name === header.text)[0];
    if (!expectedFiltered) return 'indefinido';
    const type = this.skillTypes.filter(it => it.value === item.skill.type)[0];
    const option = type.options.filter(it => it.value === expectedFiltered.expected)[0];
    return option.name;
  }

  removeFirstElement(value: any[]) {
    return value.slice(1);
  }

  print() {
    window.open(`/drd/${this.$route.params.id}/print`, 'blank');
  }

  getSkillByType(keyArea: KeyArea, type: string) {
    return keyArea.skillsActive.filter(it => it.skill.type === type)
      .sort((n1, n2) => n1.order - n2.order);
  }

  redirectToList() {
    this.$router.push('/drd');
  }

  getOptionsByType(type: any) {
    return type.options.filter(it => it.isSelected);
  }

  get showDrdReview() {
    return this.existsDrdReview && !this.$route.path.includes('print');
  }
}
