

































import { Vue, Component, Prop } from 'vue-property-decorator';
import { VbSnackbarService } from 'bag-of-holding-library';
import PanelScore from '@/views/drd/read/common-components/drd-panel.component.vue';
import ScoreService from '@/domain/employees/service/score.service';
import Score from '@/domain/employees/entity/score.entity';
import Agreement from '@/domain/employees/entity/agreement.entity';
import ScoreDescriptionRead from '@/views/score/read/score-description-read.component.vue';
import EmployeeRevenueComponent from './employee-revenue.component.vue';

@Component({
  components: {
    PanelScore,
    ScoreDescriptionRead,
    EmployeeRevenueComponent,
  },
})
export default class EmployeeWagesComponent extends Vue {
  @Prop({
    type: String,
  }) employeeId!: string;

  @Prop({
    type: String,
    default: '',
  }) badgeMessage!: string;

  loading: boolean = true;

  scores: Score[] = [];

  created(): void {
    ScoreService.getLoggedUserScores(this.employeeId).then((data: Score[]) => {
      this.scores = data;
    }).catch((err) => {
      VbSnackbarService.handleHttpError(err);
    }).finally(() => this.loading = false);
  }

  setEmployeeRevenueViewed() {
    this.$emit('setEmployeeRevenueViewed');
  }
}
