























































































import { Vue, Component, Prop } from 'vue-property-decorator';
import LabelSlot from '@/shared/slots/label-slot.component.vue';
import DateFormat from 'bag-of-holding-library/src/utils/date-format';
import InitialScoreFilter from '@/domain/initial-score/entity/initial-score-filter.entity';

@Component({
  components: {
    LabelSlot,
  },
})
export default class InitialScoreFilterComponent extends Vue {
  admissionDateMenu: boolean = false;

  filter: InitialScoreFilter = new InitialScoreFilter();

  get admissionDateText() {
    return DateFormat.standardDate(this.filter.admissionDate)
  }

  sendFilter() {
    this.$emit('sendFilter', this.filter)
  }

  clearFilter() {
    this.filter = new InitialScoreFilter();
    this.sendFilter();
  }
}
