import Candidate from '../candidate.entity';
import AssessmentGrade from './assessment-grade.entity';

export default class Assessment {
  id: string;

  link: string;

  linkDescription: string;

  mentalGrade: number | string;

  motivationalGrade: number | string;

  socialGrade: number | string;

  cultureGrade: number | string;

  candidate: Candidate;

  constructor(obj: any = {}) {
    this.id = obj.id || '';
    this.link = obj.link || '';
    this.linkDescription = obj.linkDescription || '';
    this.mentalGrade = obj.mentalGrade || '';
    this.motivationalGrade = obj.motivationalGrade || '';
    this.socialGrade = obj.socialGrade || '';
    this.cultureGrade = obj.cultureGrade || '';
    this.candidate = obj.candidate || {};
  }
}
