export default class CurrentCareerUpdate {
  employeeName: string;

  role: string;

  seniority: string;

  resultCenter: string;

  businessUnit: string;

  nextBuild: string;

  manager: string;

  observation = '';

  constructor(obj: any) {
    this.employeeName = obj.employeeName || '';
    this.role = obj.role || '';
    this.seniority = obj.seniority || '';
    this.resultCenter = obj.resultCenter || '';
    this.businessUnit = obj.businessUnit || '';
    this.nextBuild = obj.nextBuild || '';
    this.manager = obj.manager || '';
  }

  public static of(obj: any): CurrentCareerUpdate {
    return new CurrentCareerUpdate(obj);
  }

  public static ofList(obj: any[]): CurrentCareerUpdate[] {
    return obj.map(this.of);
  }
}
