export default class KeyAreaFiltered {
  id: string;

  name: string;

  lastModifiedDate: string;

  modifiedBy: string;

  constructor(obj: any = {}) {
    this.id = obj.id || '';
    this.name = obj.name || '';
    this.lastModifiedDate = obj.lastModifiedDate || '';
    this.modifiedBy = obj.modifiedBy || '';
  }
}
