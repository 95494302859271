var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"filter"},[_c('label-slot',{staticClass:"filter__select mr-3",attrs:{"label":'Colaborador'}},[_c('v-autocomplete',{attrs:{"items":_vm.employees,"search-input":_vm.filter.employeeInput,"item-value":"id","item-text":"name","placeholder":"Digite o nome...","no-data-text":"Informe o nome do colaborador","outlined":"","dense":"","clearable":"","multiple":"","hide-details":""},on:{"input":_vm.sendFilter,"update:search-input":_vm.findEmployees,"click:clear":_vm.sendFilter},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('selection-component',{attrs:{"length":_vm.filter.employee.length,"item":item,"index":index}})]}}]),model:{value:(_vm.filter.employee),callback:function ($$v) {_vm.$set(_vm.filter, "employee", $$v)},expression:"filter.employee"}})],1),_c('label-slot',{staticClass:"filter__select mr-3",attrs:{"label":'Gestor'}},[_c('v-autocomplete',{attrs:{"search-input":_vm.filter.managerInput,"items":_vm.managers,"item-value":"id","item-text":"name","placeholder":"Selecione um gestor...","hide-no-data":"","outlined":"","dense":"","clearable":"","multiple":"","chips":"","small-chips":"","deletable-chips":"","hide-details":""},on:{"input":_vm.sendFilter,"click:clear":_vm.sendFilter,"update:search-input":_vm.findManagers},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('selection-component',{attrs:{"length":_vm.filter.manager.length,"item":item,"index":index}})]}}]),model:{value:(_vm.filter.manager),callback:function ($$v) {_vm.$set(_vm.filter, "manager", $$v)},expression:"filter.manager"}})],1),_c('label-slot',{staticClass:"filter__select mr-3",attrs:{"label":'Unidade'}},[_c('v-autocomplete',{attrs:{"placeholder":"Digite a unidade","item-value":"id","item-text":"name","items":_vm.businessUnits,"outlined":"","dense":"","clearable":"","hide-details":""},on:{"change":_vm.findResultCenter,"click:clear":_vm.clearBusinessUnit},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('selection-component',{attrs:{"length":_vm.filter.businessUnit.length,"item":item,"index":index}})]}}]),model:{value:(_vm.filter.businessUnit),callback:function ($$v) {_vm.$set(_vm.filter, "businessUnit", $$v)},expression:"filter.businessUnit"}})],1),_c('label-slot',{staticClass:"filter__select mr-3",attrs:{"label":'Centro de custo'}},[_c('v-autocomplete',{key:_vm.resultCenterKey,attrs:{"placeholder":"Selecione o centro de custo...","disabled":!_vm.filter.businessUnit,"no-data-text":"Informe o centro de custo","items":_vm.resultCenters,"locale":'pt-br',"item-value":"id","item-text":"name","outlined":"","dense":"","hide-details":"","multiple":"","clearable":""},on:{"click:clear":_vm.clearResultCenterFilter,"input":_vm.sendFilter},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('selection-component',{attrs:{"length":_vm.filter.resultCenter.length,"item":item,"index":index}})]}}]),model:{value:(_vm.filter.resultCenter),callback:function ($$v) {_vm.$set(_vm.filter, "resultCenter", $$v)},expression:"filter.resultCenter"}})],1)],1),_c('div',{staticClass:"filter"},[_c('label-slot',{staticClass:"filter__select mr-3",attrs:{"label":'Hard skills'}},[_c('v-autocomplete',{attrs:{"placeholder":"Selecione...","items":_vm.hardSkils,"locale":'pt-br',"search-input":_vm.filter.hardSkillInput,"item-value":"id","item-text":"description","outlined":"","dense":"","clearable":"","multiple":"","chips":"","small-chips":"","deletable-chips":"","hide-details":""},on:{"input":_vm.sendFilter,"update:search-input":_vm.findHardSkillByType,"click:clear":_vm.clearFilters},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('selection-component',{attrs:{"length":_vm.filter.hardSkill.length,"item":item,"index":index}})]}}]),model:{value:(_vm.filter.hardSkill),callback:function ($$v) {_vm.$set(_vm.filter, "hardSkill", $$v)},expression:"filter.hardSkill"}})],1),_c('label-slot',{staticClass:"filter__select mr-3",attrs:{"label":'Frameworks / Metodologias'}},[_c('v-autocomplete',{attrs:{"placeholder":"Selecione...","items":_vm.frameworks,"locale":'pt-br',"search-input":_vm.filter.frameworkInput,"item-value":"id","item-text":"description","outlined":"","dense":"","clearable":"","multiple":"","chips":"","small-chips":"","deletable-chips":"","hide-details":""},on:{"input":_vm.sendFilter,"update:search-input":_vm.findFrameworkByType,"click:clear":_vm.clearFilters},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('selection-component',{attrs:{"length":_vm.filter.frameworks.length,"item":item,"index":index}})]}}]),model:{value:(_vm.filter.frameworks),callback:function ($$v) {_vm.$set(_vm.filter, "frameworks", $$v)},expression:"filter.frameworks"}})],1),_c('label-slot',{staticClass:"filter__select mr-3",attrs:{"label":'Experiência de negócios'}},[_c('v-autocomplete',{attrs:{"placeholder":"Selecione...","items":_vm.businessExps,"locale":'pt-br',"search-input":_vm.filter.businessExpsInput,"item-value":"id","item-text":"description","outlined":"","dense":"","clearable":"","multiple":"","chips":"","small-chips":"","deletable-chips":"","hide-details":""},on:{"input":_vm.sendFilter,"update:search-input":_vm.findBusinessExpsByType,"click:clear":_vm.clearFilters},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('selection-component',{attrs:{"length":_vm.filter.frameworks.length,"item":item,"index":index}})]}}]),model:{value:(_vm.filter.businessExps),callback:function ($$v) {_vm.$set(_vm.filter, "businessExps", $$v)},expression:"filter.businessExps"}})],1),_c('label-slot',{staticClass:"filter__select mr-3",attrs:{"label":'Certificações'}},[_c('v-autocomplete',{attrs:{"placeholder":"Selecione...","items":_vm.certifications,"locale":'pt-br',"search-input":_vm.filter.certificationInput,"item-value":"id","item-text":"description","outlined":"","dense":"","clearable":"","multiple":"","chips":"","small-chips":"","deletable-chips":"","hide-details":""},on:{"input":_vm.sendFilter,"update:search-input":_vm.findCertificationByType,"click:clear":_vm.clearFilters},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('selection-component',{attrs:{"length":_vm.filter.certifications.length,"item":item,"index":index}})]}}]),model:{value:(_vm.filter.certifications),callback:function ($$v) {_vm.$set(_vm.filter, "certifications", $$v)},expression:"filter.certifications"}})],1)],1),_c('div',{staticClass:"filter"},[_c('label-slot',{staticClass:"filter__select mr-3",attrs:{"label":"Papel"}},[_c('v-autocomplete',{attrs:{"items":_vm.assignments,"item-value":"id","item-text":"description","placeholder":"Digite o nome do papel...","no-data-text":"Informe o nome do papel","outlined":"","dense":"","clearable":"","multiple":"","chips":"","small-chips":"","deletable-chips":"","hide-details":"","search-input":_vm.filter.assignmentInput},on:{"update:search-input":_vm.findAssignments,"input":_vm.sendFilter},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('selection-component',{attrs:{"length":_vm.filter.assignment.length,"item":item,"index":index,"chipField":'description'}})]}}]),model:{value:(_vm.filter.assignment),callback:function ($$v) {_vm.$set(_vm.filter, "assignment", $$v)},expression:"filter.assignment"}})],1),_c('label-slot',{staticClass:"filter__select mr-3",attrs:{"label":'Cargo'}},[_c('v-autocomplete',{attrs:{"placeholder":"Digite o cargo...","items":_vm.roles,"locale":'pt-br',"item-value":"id","item-text":"name","outlined":"","dense":"","clearable":"","multiple":"","chips":"","small-chips":"","deletable-chips":"","hide-details":"","search-input":_vm.filter.roleInput},on:{"update:search-input":_vm.setRoleInput,"click:clear":_vm.sendFilter,"input":_vm.sendFilter},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('selection-component',{attrs:{"length":_vm.filter.role.length,"item":item,"index":index}})]}}]),model:{value:(_vm.filter.role),callback:function ($$v) {_vm.$set(_vm.filter, "role", $$v)},expression:"filter.role"}})],1)],1),_c('div',{staticClass:"filter-item-list"},[_vm._l((_vm.filter.hardSkill),function(item){return _c('div',{key:item.description,staticClass:"filter-item"},[_c('span',[_vm._v(_vm._s(_vm.getName(_vm.hardSkils, item)))]),_c('v-icon',{staticClass:"mr-1",on:{"click":function($event){return _vm.removeItem('hardSkill')}}},[_vm._v("mdi-close-circle")])],1)}),_vm._l((_vm.filter.frameworks),function(item){return _c('div',{key:item.description,staticClass:"filter-item"},[_c('span',[_vm._v(_vm._s(_vm.getName(_vm.frameworks, item)))]),_c('v-icon',{staticClass:"mr-1",on:{"click":function($event){return _vm.removeItem('frameworks')}}},[_vm._v("mdi-close-circle")])],1)}),_vm._l((_vm.filter.businessExps),function(item){return _c('div',{key:item.description,staticClass:"filter-item"},[_c('span',[_vm._v(_vm._s(_vm.getName(_vm.businessExps, item)))]),_c('v-icon',{staticClass:"mr-1",on:{"click":function($event){return _vm.removeItem('businessExps')}}},[_vm._v("mdi-close-circle")])],1)}),_vm._l((_vm.filter.certifications),function(item){return _c('div',{key:item.description,staticClass:"filter-item"},[_c('span',[_vm._v(_vm._s(_vm.getName(_vm.certifications, item)))]),_c('v-icon',{staticClass:"mr-1",on:{"click":function($event){return _vm.removeItem('certifications')}}},[_vm._v("mdi-close-circle")])],1)}),(_vm.showCustomFilter())?_c('div',[_c('v-btn',{staticClass:"filter-item",attrs:{"color":"primary","depressed":""},on:{"click":_vm.clearFilters}},[_vm._v(" LIMPAR FILTROS ")])],1):_vm._e()],2)])}
var staticRenderFns = []

export { render, staticRenderFns }