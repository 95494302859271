
















import { Vue, Component, Prop } from 'vue-property-decorator'
import Assignment from '@/domain/drd/entity/assignment.entity';
import AssignmentService from '@/domain/drd/service/assignment.service';
import FilterAssignment from '@/domain/drd/entity/filter.assignment.entity';
import AutocompleteComponent from '../fields/autocomplete-component.vue';

@Component({
  components: {
    AutocompleteComponent,
  },
})
export default class SelectSingleAssignmentComponent extends Vue {
  @Prop({
    type: String,
    default: '',
  }) value?: string;

  @Prop({
    type: Boolean,
    default: null,
  }) filterByContainsDRD!: boolean;

  assignments: Assignment[] = [];

  loading: boolean = false;

  created() {
    this.getAllAssignments();
  }

  async getAllAssignments() {
    this.loading = true;
    const filter = new FilterAssignment({ containsDRD: this.filterByContainsDRD })
    this.assignments = await AssignmentService.getAssignment(filter);
    this.loading = false;
  }
}
