





















import { Vue, Component } from 'vue-property-decorator';
import AuthorityProfile from '@/domain/security/authority-profile.entity';
import AuthorityService from '@/domain/security/authority.service';

@Component
export default class SecurityComponent extends Vue {
  authorityProfiles: AuthorityProfile = new AuthorityProfile();

  loading: boolean = true;

  async created() {
    await this.getAuthorityProfiles();
    this.loading = false;
  }

  async getAuthorityProfiles() {
    await AuthorityService.getAuthorityProfiles()
      .then(response => this.authorityProfiles = response);
  }

  toPageGroup(authorityName: string) {
    this.$router.push(`/security/authority/${authorityName}`);
  }
}
