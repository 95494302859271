







import { Component, Prop, Vue } from 'vue-property-decorator';
import CardTitle from '@/shared/card/card-title.component.vue';
import { VbTooltipSlot } from 'bag-of-holding-library';
import FilteredAttendance from '@/domain/attendance/entity/filtered-attendance'

@Component({
  components: { CardTitle, VbTooltipSlot },
})

export default class ScheduleHeader extends Vue {
  @Prop() typeAttendance!: string;
}

