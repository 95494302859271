






















































import {
  Vue,
  Component,
  Watch,
  Prop,
} from 'vue-property-decorator';

@Component
export default class PerformanceDescriptionTabsComponent extends Vue {
  @Prop({
    type: Number,
    default: 0,
  })
  private value!: number;

  emitTabValue(value: number): void {
    this.$emit('input', value);
  }
}

