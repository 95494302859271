export default class HealthPlanDTO {
  id: string;

  healthPlanType: string;

  discount: string;

  constructor(obj: any) {
    this.id = obj.id || null
    this.healthPlanType = obj.healthPlanType || ''
    this.discount = obj.discount || '0.00'
  }

  public static of(obj: any): HealthPlanDTO {
    return new HealthPlanDTO(obj || {});
  }

  public static ofList(list: any[]): HealthPlanDTO[] {
    return list.map(this.of);
  }
}
