









import { Vue, Component, Prop } from 'vue-property-decorator';

@Component
export default class NotFoundComponent extends Vue {
  @Prop({
    type: String,
    default: 'Nenhum dado foi encontrado!',
  }) message!: string;
}
