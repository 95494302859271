import CandidateCultureGradeQuestionDetails from './candidate-culture-grade-details-question.entity';

export default class CandidateCultureGradeDetails {
  candidateName: string;

  total: number;

  totalPercent: number;

  locusOfControlGrade: number;

  locusOfControlGradePercent: number;

  beFairDoRightDontRideGrade: number;

  beFairDoRightDontRideGradePercent: number;

  responseDate: string;

  answers: CandidateCultureGradeQuestionDetails[];

  constructor(obj: any = {}) {
    this.candidateName = obj.candidateName;
    this.total = obj.total;
    this.totalPercent = obj.totalPercent;
    this.locusOfControlGrade = obj.locusOfControlGrade;
    this.locusOfControlGradePercent = obj.locusOfControlGradePercent;
    this.beFairDoRightDontRideGrade = obj.beFairDoRightDontRideGrade;
    this.beFairDoRightDontRideGradePercent = obj.beFairDoRightDontRideGradePercent;
    this.responseDate = obj.responseDate;
    this.answers = obj.answers;
  }

  public static of(obj: any): CandidateCultureGradeDetails {
    return new CandidateCultureGradeDetails(obj || {});
  }

  public static ofList(obj: any[]): CandidateCultureGradeDetails[] {
    return obj.map(this.of)
  }
}
