import axios from 'axios';

export default class GraphService {
  public static getMembers(groupId: String) {
    return axios.get(`https://graph.microsoft.com/v1.0/groups/${groupId}/members`)
  }

  public static getGroupOwners(groupId: String) {
    return axios.get(`https://graph.microsoft.com/v1.0/groups/${groupId}/owners`)
  }
}
