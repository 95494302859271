import Reason from './reason.entity';

export default class ActivityUpdateStatus {
  status: string;

  reason: string;

  constructor(obj: any) {
    this.status = obj.status;
    this.reason = obj.reason;
  }
}
