


































































































































































































import PersonalDataFilter from '@/domain/employees/entity/personal-data-filter.entity';
import { VbSnackbarService, VbTooltipSlot } from 'bag-of-holding-library';
import { Vue, Component } from 'vue-property-decorator';
import LabelSlot from '@/shared/slots/label-slot.component.vue';
import StateService from '@/domain/states/service/states.service';
import VueToExcel from 'vue-json-excel';
import State from '@/domain/states/entity/state.entity';
import PersonalDataService from '@/domain/employees/service/personal-data.service';
import FilteredPersonalData from '@/domain/employees/entity/filtered-personal-data.entity';
import City from '@/domain/states/entity/city.entity';
import CareerService from '@/domain/employees/service/career.service';
import UpdateAtribute from '@/domain/employees/entity/update-atribute.entity';
import CardTitle from '@/shared/card/card-title.component.vue';
import Authority from '@/domain/security/authority.entity';
import AuthorityStore from '@/domain/security/authority.store';
import GenderTypes from './gender-types.json';
import MaritalStatus from './marital-status.json';
import HasChildrenOptions from './hasChildren-option.json';
import ActiveStatus from './active-status.json';
import ConcludedForm from './concluded-form-option.json';

@Component({
  components: {
    LabelSlot, CardTitle, VbTooltipSlot, VueToExcel,
  },
})
export default class PersonalDataExport extends Vue {
  filter: PersonalDataFilter = PersonalDataFilter.of({});

  states: State[] = [];

  cities: City[] = [];

  businessUnits: UpdateAtribute[] = [];

  workplaces: UpdateAtribute[] = [];

  genderTypes = GenderTypes;

  maritalStatusTypes = MaritalStatus;

  hasChildrenOptions = HasChildrenOptions;

  activeStatus = ActiveStatus;

  concludedForm = ConcludedForm;

  loading = false;

  stateInput = '';

  cityInput = '';

  businessUnitInput = '';

  workplaceInput = '';

  valid = true;

  authorities!: Authority;

  exportPersonalData: boolean = false;

  csvLabel = {
    Colaborador: 'name',
    Ativo: 'valueActive',
    'Email DB1': 'companyEmail',
    'Email pessoal': 'privateEmail',
    'Data de Nascimento': 'valueBirthDate',
    Logradouro: 'address',
    Numero: 'addressNumber',
    Complemento: 'complement',
    Bairro: 'district',
    Cidade: 'city',
    Estado: 'state',
    País: 'countryName',
    CEP: 'postalCode',
    Genero: 'gender',
    Religião: 'religion',
    'Religião Específica': 'specifiedReligion',
    'Contato de emergência': 'emergencyName',
    'Telefone de emergência': 'emergencyPhone',
    'Telefone Celular': 'cellPhone',
    'Telefone residencial': 'residentialPhone',
    'Parentesco emergência': 'emergencyRelationship',
    Gestor: 'manager',
    Cargo: 'role',
    'Sede em que atua': 'workplace',
    'Modo de atuação': 'operationModality',
    'Unidade de negócio': 'businessUnit',
    'Centro de resultado': 'resultCenter',
    Filhos: 'children',
    Observações: 'observation',
    'Utiliza IFood na sua cidade?': 'valueUsesIfood',
    'Tamanho de camisa': 'shirtSize',
    'Consome bebida alcoólica?': 'valueConsumeAlcoholicDrink',
    'Tem alergias alimentares?': 'foodAllergies',
    'Opções de comida e bebida que não gosta': 'foodDoNotLike',
    'Datas que quero comemorar junto com a DB1': 'valueCommemorativeDates',
    Acompanhado: 'valueAccompanied',
    Documento: 'document',
    'Data de admissão': 'admissionDateFormated',
    Senioridade: 'seniorityName',
  }

  authority: Authority = new Authority([]);

  back() {
    this.$router.back();
  }

  stateRule() {
    const errors: string[] = [];
    if (!this.filter.state && this.stateInput) {
      errors.push('Favor informar um estado válido');
    }
    return errors;
  }

  cityRule() {
    const errors: string[] = [];
    if (!this.filter.city && this.cityInput) {
      errors.push('Favor informar uma cidade válida');
    }
    return errors;
  }

  businessUnitRule() {
    const errors: string[] = [];
    if (!this.filter.businessUnit && this.businessUnitInput) {
      errors.push('Favor informar uma unidade válida');
    }
    return errors;
  }

  workplaceRule() {
    const errors: string[] = [];
    if (!this.filter.workplace && this.workplaceInput) {
      errors.push('Favor informar uma sede válida');
    }
    return errors;
  }

  created() {
    this.initiStates();
    CareerService.getBusinessUnit().then(resp => this.businessUnits = resp);
    CareerService.getWorkplace().then((resp: any) => this.workplaces = resp.data);
    this.validationAuthorities();
  }

  initiStates() {
    StateService.findStates()
      .then((resp: any) => this.states = resp);
  }

  getCities() {
    StateService.findCities(this.filter.state)
      .then((resp: any) => this.cities = resp);
  }

  getPersonalData() {
    this.loading = true;
    if (!this.valid || !this.exportPersonalData) return {};
    return PersonalDataService.exportPersonalData(this.filter)
      .then((resp: any) => {
        if (resp.length === 0) {
          VbSnackbarService.showError('Nenhum colaborador encontrado para exportar dados')
        }
        return FilteredPersonalData.ofList(resp)
      })
      .catch((err: any) => VbSnackbarService.handleHttpError(err))
      .finally(() => this.loading = false);
  }

  async validationAuthorities() {
    await this.getAuthority();
    this.exportPersonalData = this.authority.hasExportPersonalData();
  }

  async getAuthority() {
    await AuthorityStore.fetchedAuthority().then(authority => this.authority = authority);
  }
}
