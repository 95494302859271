































import { Vue, Component, Prop } from 'vue-property-decorator';
import LabelSlot from '@/shared/slots/label-slot.component.vue';
import ModalComponent from '@/shared/modal/modal.component.vue';
import ModalOptions from '@/shared/modal/modal-options.entity';
import Assessment from '@/domain/selective-process/entity/assessment/assessment.entity';
import SelectiveProcessCandidateDetails from './selective-process-candidate-datails.component.vue';
import CandidateApplicationTable from './candidate-application-table.component.vue';

@Component({
  components: {
    LabelSlot,
    SelectiveProcessCandidateDetails,
    CandidateApplicationTable,
    ModalComponent,
  },
})
export default class SelectiveProcessModal extends Vue {
  @Prop() item!: Assessment;

  @Prop() keyItem!: number;

  @Prop() openModal!: boolean;

  private get optionsHandled(): ModalOptions {
    return {
      title: 'Detalhes do candidato',
      titleSize: '20px',
      description: '',
      showOk: false,
      disabledOk: false,
      showCancel: true,
      labelCancel: 'fechar',
      width: 1100,
    };
  }

  closeModal(event: any) {
    this.$emit('closeModal', event);
  }
}
