


















import { Component, Vue, Prop } from 'vue-property-decorator';
import CardTitle from '@/shared/card/card-title.component.vue';
import { VbTooltipSlot } from 'bag-of-holding-library';
import UpdateAtribute from '@/domain/employees/entity/update-atribute.entity';
import EditRemunerationModal from './modal/edit-remuneration-modal.component.vue';

@Component({
  components: { CardTitle, VbTooltipSlot, EditRemunerationModal },
})
export default class WageTableHeader extends Vue {
  @Prop() roles!: UpdateAtribute[];

  @Prop({
    type: Boolean,
    default: false,
  }) isUpdateTableSalary!: boolean;
}
