






















































































































































































































































import { Vue, Component } from 'vue-property-decorator';
import { VbSnackbarService } from 'bag-of-holding-library';
import FormFeedback from '@/domain/activity/entity/form-feedback.entity';
import FormFeedbackPoint from '@/domain/activity/entity/form-feedback-point.entity';
import ActionPlan from '@/domain/activity/entity/action-plan.entity';
import AddButton from '@/shared/buttons/add-button.component.vue';
import ActivityHappiness from '@/domain/happiness-level/entity/activity-happiness.entity';
import HappinessLevelService from '@/domain/happiness-level/service/happiness-level.service';
import FormFeedbackService from '@/domain/activity/service/form-feedback.service';
import Employee from '@/domain/employees/entity/employee.entity';
import EmployeeService from '@/domain/employees/service/employee.service';
import ModalWarning from '@/shared/modal/modal-warning.component.vue';
import ActivityScheduleService from '@/domain/schedule/activitie/activity.service';
import PdiService from '@/domain/pdi/pdi.service';
import Priority from '@/domain/activity/entity/priority.entity';
import PlanType from '@/domain/activity/entity/plan-types.entity';
import CopyClipboardService from '@/shared/utils/copy-clipboard.service';
import PlanPriorityLegendComponent from '@/shared/plan-priority/plan-priority.component.vue';
import { PlanState } from '@/domain/pdi/entity/plan-state';
import NotFoundComponent from '@/shared/not-found/not-found.component.vue';
import ModalOptions from '@/shared/modal/modal-options.entity';
import FeedbackComponent from './feedback.component.vue';
import ActionPlanComponent from '../common/action-plan.component.vue';
import ActivityUtils from '../common/activity-utils';
import ActivityHeaderComponent from '../common/activity-header.component.vue';
import AllPlansModal from '../common/all-plan-modal.component.vue';
import PdiPlanList from '../common/pdi-plan-list.component.vue';
import FormPlanModal from '../common/form-plan-modal.component.vue';
import FeedbackReadComponent from './feedback-read.component.vue';

@Component({
  components: {
    ActionPlanComponent,
    AddButton,
    FeedbackComponent,
    ModalWarning,
    ActivityHeaderComponent,
    AllPlansModal,
    PdiPlanList,
    FormPlanModal,
    NotFoundComponent,
    PlanPriorityLegendComponent,
    FeedbackReadComponent,
  },
})
export default class FeedbackCreateComponent extends Vue {
  formFeedback: FormFeedback = new FormFeedback();

  positivePoints: String[] = [];

  negativePoints: String[] = [];

  activity: ActivityHappiness = new ActivityHappiness();

  loadingSaveFeedback: boolean = false;

  employee: Employee = new Employee({});

  loadingConclude: boolean = false;

  showModalWarning: boolean = false;

  activityUtils: ActivityUtils = new ActivityUtils();

  loading: boolean = false;

  openAllPlansModal: boolean = false;

  allPlans: ActionPlan[] = [];

  planState = PlanState;

  planStateFilterSelected: string = this.planState.OPEN;

  priorityItems: Priority[] = [];

  planTypes: PlanType[] = [];

  openPdiList: boolean = true;

  openFeedbackList: boolean = true;

  loadingCreatePlan: boolean = false;

  laodingUpdatePlan: boolean = false;

  planIndex: number = 0;

  openFormPlanModal: boolean = false;

  activityId: string = this.$route.params.activityIdOfFeedback;

  loadingAllPlans: boolean = false;

  showBackWarningModal: boolean = false;

  get modalWarningOptions(): ModalOptions {
    return {
      title: 'Atenção',
      description: 'Após finalizar a atividade não será possível criar/atualizar feedbacks e novos planos de PDI. Deseja continuar?',
      showOk: true,
      disabledOk: false,
      labelOk: 'Sim, continuar',
      loadingOk: this.loadingConclude,
      okColor: 'tabBlue',
      showCancel: true,
      labelCancel: 'Cancelar',
      width: 400,
    };
  }

  addPositivePoint(): void {
    this.positivePoints.push('');
  }

  addNegativePoint(): void {
    this.negativePoints.push('');
  }

  deletePositivePoint(index: number): void {
    this.positivePoints.splice(index, 1);
  }

  deleteNegativePoint(index: number): void {
    this.negativePoints.splice(index, 1);
  }

  updatePositivePoint(update: any): void {
    this.positivePoints[update.index] = update.value;
  }

  updateNegativePoint(update: any): void {
    this.negativePoints[update.index] = update.value;
  }

  async loadActivity() {
    await HappinessLevelService.getActivity(this.$route.params.activityIdOfFeedback)
      .then((activity: ActivityHappiness) => {
        this.activity = activity;
      }).catch(VbSnackbarService.handleHttpError);
  }

  save(): void {
    const { form }: any = this.$refs;
    if (!form.validate()) return
    this.loadingSaveFeedback = true;
    this.formFeedback.points = this.positivePoints.map(
      description => new FormFeedbackPoint({ positive: true, description }),
    );
    const negativePoints: FormFeedbackPoint[] = this.negativePoints.map(
      description => new FormFeedbackPoint({ positive: false, description }),
    );
    this.formFeedback.points = this.formFeedback.points.concat(negativePoints);
    if (this.formFeedback.id === null) {
      FormFeedbackService.save(this.formFeedback, this.activityId).then(({ data }) => {
        VbSnackbarService.showSuccess('Feedbacks salvo com sucesso!');
        this.formFeedback = data;
      }).catch((error) => {
        VbSnackbarService.handleHttpError(error);
      }).finally(() => {
        this.loadingSaveFeedback = false;
      });
      return;
    }
    FormFeedbackService.update(this.formFeedback, this.activityId).then(({ data }) => {
      this.formFeedback = data;
      VbSnackbarService.showSuccess('Feedbacks atualizado com sucesso!');
    }).catch(VbSnackbarService.handleHttpError)
      .finally(() => {
        this.loadingSaveFeedback = false;
      });
  }

  loadFeedback(isApproved: boolean): void {
    FormFeedbackService.getByActivityId(this.$route.params.activityIdOfFeedback)
      .then((formFeedback: FormFeedback) => {
        this.formFeedback = formFeedback;
        this.addFeedbackPoints(formFeedback)
      }).catch((error) => {
        VbSnackbarService.handleHttpError(error);
        this.$router.push({ path: this.getOnSaveRedirectPath() });
      })
  }

  addFeedbackPoints(formFeedback: FormFeedback) {
    formFeedback.points.filter(point => point.positive === true)
      .forEach(feedbackPoint => this.positivePoints.push(feedbackPoint.description));
    formFeedback.points.filter(point => point.positive === false)
      .forEach(feedbackPoint => this.negativePoints.push(feedbackPoint.description));
  }

  copyLink() {
    CopyClipboardService.copyToClipboard(`${process.env.VUE_APP_REDIRECT_URL}activity/${this.$route.params.activityIdOfFeedback}/feedback/new`);
  }

  conclude(): void {
    this.loadingConclude = true;
    this.save()
    ActivityScheduleService.approveAndSetExecutionDate(this.activity.id, { reason: 'Aprovação automática' })
      .then((response) => {
        this.activity = response;
        VbSnackbarService.showSuccess('Atividade finalizada!');
      })
      .catch(VbSnackbarService.handleHttpError)
      .finally(() => {
        this.loadingConclude = false;
        this.closeModalWarning();
      });
  }

  async created() {
    this.$destroy();
    this.loading = true;
    await this.loadActivity();
    await this.getEmployee();
    this.activityUtils = new ActivityUtils(this.employee);
    const havePerssionAccess = await this.activityUtils.checkUserAuthority();
    if (!havePerssionAccess) {
      this.$router.push('/denied');
      return;
    }
    this.loadFeedback(this.activity.status === 'Aprovada');
    this.loading = false;
    this.getAllPriority();
    this.getPlanTypes();
    this.getAllPlansByState();
  }

  getOnSaveRedirectPath(): string {
    return this.activityUtils.isLoggedUserProfile ? '/' : '/schedule';
  }

  redirectUserToSchedule(): void {
    this.$router.push({ path: '/schedule' });
  }

  async getEmployee() {
    await EmployeeService.getEmployeeByUsername(this.activity.employee.username)
      .then((response: Employee) => {
        this.employee = response;
      })
      .catch(error => VbSnackbarService.handleHttpError(error));
  }

  getAllPlansByState() {
    this.loadingAllPlans = true;
    PdiService.getAllPlansByEmployeeUsername(
      this.activity.employee.username,
      this.planStateFilterSelected,
    )
      .then(response => this.allPlans = response)
      .finally(() => this.loadingAllPlans = false);
  }

  showAllPlansModal() {
    this.openAllPlansModal = true;
  }

  changePlanStateFilter(value: string) {
    this.planStateFilterSelected = value;
    this.getAllPlansByState();
  }

  getAllPriority() {
    PdiService.getAllPriority().then(response => this.priorityItems = response);
  }

  getPlanTypes() {
    PdiService.getPlanTypes().then(response => this.planTypes = response);
  }

  get alreadySaved() {
    return this.formFeedback.id === null || this.formFeedback.id === undefined
  }

  updatePlanField(eventData: any) {
    this.allPlans[eventData.index][eventData.field] = eventData.value;
  }

  openFormPlanModalEditMode(plan: ActionPlan) {
    this.planIndex = this.allPlans.indexOf(plan);
    this.openFormPlanModal = true;
  }

  showFormPlanModal() {
    this.openFormPlanModal = true;
  }

  newPlan() {
    this.allPlans.push(ActionPlan.ofActivity(this.activity.id));
    this.planIndex = this.allPlans.length - 1;
    this.showFormPlanModal();
  }

  createPlan(plan: ActionPlan) {
    this.loadingCreatePlan = true;
    PdiService.create(plan, this.activity.employee.username)
      .then(() => {
        VbSnackbarService.showSuccess('Plano criado com sucesso!')
        this.openFormPlanModal = false;
        this.loadingCreatePlan = false;
        this.getAllPlansByState();
      })
      .catch((error) => {
        VbSnackbarService.handleHttpError(error)
        this.loadingCreatePlan = false;
      })
  }

  updatePlan(plan: ActionPlan) {
    this.laodingUpdatePlan = true;
    PdiService.update(plan, this.activity.employee.username)
      .then(() => {
        this.laodingUpdatePlan = false;
        VbSnackbarService.showSuccess('Plano atualizado com sucesso!');
        this.closeFormPlanModal();
      })
      .catch((error) => {
        VbSnackbarService.handleHttpError(error)
        this.laodingUpdatePlan = false;
      });
  }

  closeFormPlanModal() {
    this.getAllPlansByState()
    this.openFormPlanModal = false;
  }

  redirectUser() {
    this.$router.push({ path: this.getOnSaveRedirectPath() });
  }

  openModalWarning() {
    this.showModalWarning = true;
  }

  closeModalWarning() {
    this.showModalWarning = false;
  }
}
