

































import {
  Vue, Component, Prop,
} from 'vue-property-decorator';
import PaginationComponent from '@/shared/pagination.component.vue';
import LabelSlot from '@/shared/slots/label-slot.component.vue';
import MobileHandler from '@/shared/utils/mobile-handler';
import splitContent from '@/shared/directive/split-content';
import DateHandler from '@/shared/utils/date-format';
import Header from './header.json';

@Component({
  components: { PaginationComponent, LabelSlot },
  filters: {
    splitContent,
    formatDate(value: string) {
      return DateHandler.timestampToLocal(value.split('T')[0]);
    },
  },
})
export default class TableComponent extends Vue {
  @Prop() items!: any[];

  @Prop() loading!: boolean;

  @Prop() page!: any;

  @Prop({ type: Array, default: () => ([]) })
  private extraColumns!: any[];

  private header = Header;

  private isMobile = false;

  private get headersAndExtraColumns(): any[] {
    return this.header.concat(this.extraColumns);
  }

  private updateTable(pagination: any): void {
    this.$emit('update', pagination);
  }

  private onResize() {
    this.isMobile = MobileHandler.isMobile();
  }

  private select(item: any): void {
    this.$router.push({
      path: `/profile/${item.username}/`,
    })
  }
}
