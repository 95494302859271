






































































































































































import { Vue, Component, Prop } from 'vue-property-decorator';
import LabelSlot from '@/shared/slots/label-slot.component.vue';
import TooltipIcon from '@/shared/tooltip-icon.component.vue';
import CardTitle from '@/shared/card/card-title.component.vue';
import BooleanValuesOption from './boolean-values-option';
import HealthPlanTypes from './health-plan-type.json';


@Component({
  name: 'ScoreEmployeBenefitsComponent',
  components: {
    LabelSlot,
    TooltipIcon,
    CardTitle,
  },
})
export default class ScoreBenefitsEmployeComponent extends Vue {
  @Prop({
    type: Boolean,
    required: true,
  }) healthPlanEmployee!: boolean;

  @Prop({
    type: Boolean,
    required: true,
  }) healthPlanEmployeePayedByDB1!: boolean;

  @Prop({
    type: String,
    required: true,
  }) healthPlanType!: string;

  @Prop({
    type: Boolean,
    required: true,
  }) dentalPlanEmployee!: boolean;

  @Prop({
    type: Boolean,
    required: true,
  }) dentalPlanEmployeePayedByDB1!: boolean;

  @Prop({
    type: Boolean,
    required: true,
  }) lifeInsuranceEmployee!: boolean;

  @Prop({
    type: String,
    required: true,
  }) benefitObservation!: string;

  healthPlanTypes = HealthPlanTypes;

  booleanValuesOption = BooleanValuesOption;

  updateHealthPlanEmployee() {
    this.$emit('updateHealthPlanEmployee', this.healthPlanEmployee);
  }

  updateHealthPlanEmployeePayedByDB1() {
    this.$emit('updateHealthPlanEmployeePayedByDB1', this.healthPlanEmployeePayedByDB1);
  }

  healthPLan() {
    this.$emit('healthPLan', this.healthPlanType);
  }

  updateDentalPlanEmployee() {
    this.$emit('updateDentalPlanEmployee', this.dentalPlanEmployee);
  }

  updateDentalPlanEmployeePayedByDB1() {
    this.$emit('updateDentalPlanEmployeePayedByDB1', this.dentalPlanEmployeePayedByDB1);
  }

  updateLifeInsuranceEmployee() {
    this.$emit('updateLifeInsuranceEmployee', this.lifeInsuranceEmployee);
  }

  updateBenefitObservation() {
    this.$emit('updateBenefitObservation', this.benefitObservation);
  }
}
