





















































import { Vue, Component } from 'vue-property-decorator';
import { VbSnackbarService } from 'bag-of-holding-library';
import AuthorityStore from '@/domain/security/authority.store';
import SalaryReadjustmentParametrization from '@/domain/salary-reajustment-parametrizations/entity/salary-readjustment-parametrization.entity';
import ParametrizationService from '@/domain/salary-reajustment-parametrizations/service/salary-readjustment-parametrization.service';
import ModalWarning from '@/shared/modal/modal-warning.component.vue';
import SalaryReadjustmentParametrizationList from './salary-readjustment-parametrization-list.component.vue';
import SalaryReadjustmentParametrizationModal from './salary-readjustment-parametrization-modal.vue';

@Component({
  components: {
    SalaryReadjustmentParametrizationList,
    SalaryReadjustmentParametrizationModal,
    ModalWarning,
  },
})
export default class SalaryReadjustmentParametrizationsView extends Vue {
  private parametrizations: SalaryReadjustmentParametrization[] = [];

  private pagination: any = {
    page: 1,
    size: 10,
    sort: 'validSince,desc',
    totalPages: 0,
    totalElements: 0,
  };

  private loading: boolean = false;

  hasCreateSalaryReadjustmentParametrization: boolean = false;

  hasUpdateSalaryReadjustimentParamentrization: boolean = false;

  showSalaryReadjustmentParametrizationModal: boolean = false;

  parametrizationId: string | null = null;

  hasDeleteSalaryReadjustmentParametrization: boolean = false;

  deletingParametrizationId: string = '';

  showDeleteConfirmationDialog: boolean = false;

  modalKey: number = 0

  renderModal: boolean = false;

  loadParametrizations(): void {
    this.loading = true;
    ParametrizationService.findParametrizations(
      this.pagination.page,
      this.pagination.size,
      this.pagination.sort,
    ).then(({ data }: any) => {
      this.parametrizations = data.content.map(it => new SalaryReadjustmentParametrization(it));
      this.pagination.totalElements = data.totalElements;
      this.pagination.totalPages = data.totalPages;
    }).catch(VbSnackbarService.handleHttpError)
      .finally(() => this.loading = false);
  }

  getPermissions() {
    return AuthorityStore.fetchedAuthority()
      .then((response) => {
        this.hasCreateSalaryReadjustmentParametrization = response
          .hasCreateSalaryReadjustmentParametrization();
        this.hasDeleteSalaryReadjustmentParametrization = response
          .hasDeleteSalaryReadjustmentParametrization();
        this.hasUpdateSalaryReadjustimentParamentrization = response
          .hasUpdateSalaryReadjustmentParamentrization();
      })
  }

  openNewParametrizationModal() {
    this.parametrizationId = null;
    this.openModal();
  }

  openModal() {
    this.showSalaryReadjustmentParametrizationModal = true;
    this.renderModal = false;
    this.$nextTick(() => this.renderModal = true)
  }

  close() {
    this.showSalaryReadjustmentParametrizationModal = false;
    this.modalKey += 1;
    this.loadParametrizations();
  }

  updatePage(page: number) {
    this.pagination.page = page;
  }

  updateSize(size: number) {
    if (this.pagination.size < size) {
      this.pagination.page = 1;
    }
    this.pagination.size = size;
  }

  updateSort(sort: string) {
    this.pagination.sort = sort;
    this.loadParametrizations();
  }

  editSalaryReadjustment(id: string) {
    this.parametrizationId = id;
    this.openModal();
  }

  openDeleteDialog(parametrizationId: string) {
    this.deletingParametrizationId = parametrizationId;
    this.showDeleteConfirmationDialog = true;
  }

  closeDeleteConfirmationDialog() {
    this.showDeleteConfirmationDialog = false;
    this.deletingParametrizationId = '';
  }

  deleteParametrization() {
    this.loading = true;
    ParametrizationService.deleteParametrization(this.deletingParametrizationId)
      .then(this.loadParametrizations)
      .catch((error) => {
        VbSnackbarService.handleHttpError(error);
        this.loading = false;
      });
    this.closeDeleteConfirmationDialog();
  }

  created(): void {
    this.loadParametrizations();
    this.getPermissions();
  }
}
