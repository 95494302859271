export default [
  {
    value: false,
    description: 'Não',
  },
  {
    value: true,
    description: 'Sim',
  },
]
