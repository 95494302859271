export default class CreateKeyAreaSkillLevelDTO {
  id: string;

  expected: number;

  active: boolean;

  constructor(obj: any = {}) {
    this.id = obj.id || '';
    this.expected = obj.expected;
    this.active = obj.active;
  }
}
