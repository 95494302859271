import axios from 'axios';
import EmployeeSummary from '@/domain/schedule/employee/employee-summary.entity';
import Employee from '../entity/employee.entity';
import CareerUpdate from '../entity/career-update.entity';
import EmployeeAmount from '../entity/employee-amount.entity';
import EmployeeAccompaniment from '../entity/employee-accompaniment.entity';
import Response from '../entity/response.entity';
import ProfessionalData, { Currency } from '../entity/professional-data.entity';
import EmployeeWage from '../entity/employee-wage.entity';
import EmployeeDTO from '../entity/employee-dto';
import TechLead from '../entity/tech-lead.entity';

export default class EmployeeService {
  public static getEmployee(id: string): Promise<Employee> {
    return axios.get(`/api/employees/${id}/profile`)
      .then((response: any) => Employee.of(response.data));
  }

  public static getEmployeeByUsername(username: string): Promise<Employee> {
    return axios.get(`/api/employees/${username}/profileByUsername`)
      .then((response: any) => Employee.of(response.data));
  }

  public static getUpdates(id: string): Promise<CareerUpdate[]> {
    return axios.get(`/api/employees/${id}/career-updates`)
      .then((response: any) => CareerUpdate.ofList(response.data));
  }

  static findManagersByName(name: string): Promise<EmployeeSummary[]> {
    return axios.get(`/api/employees/managers?name=${name}`)
      .then((response: any) => EmployeeSummary.ofList(response.data));
  }

  static findTechLeadsByName(name: string): Promise<TechLead[]> {
    return axios.get('/api/employees/tech-leads', { params: { name } })
      .then((response: any) => response.data.map(it => new TechLead(it)));
  }

  static getEmployeeAmount(): Promise<EmployeeAmount> {
    return axios.get('/api/details')
      .then((response: any) => EmployeeAmount.of(response.data));
  }

  static updateAccompaniment(
    id: string, employeeAccompaniment: EmployeeAccompaniment,
  ): Promise<void> {
    return axios.put(`/api/employees/${id}/accompanied`, employeeAccompaniment);
  }

  public static getEmployeeActivities(employeeId: string,
    page: number, size: number): Promise<Response> {
    return axios.get(`/api/employees/${employeeId}/activities?page=${page - 1}&size=${size}`)
      .then((response: any) => Response.of(response.data));
  }

  public static getEmployeePhoto(employeeId: string): Promise<Response> {
    return axios.get(`/api/employees/${employeeId}/photo`)
      .then((response: any) => response.data);
  }

  public static getEmployeePhotoBinary(employeeId: string): Promise<any> {
    return axios.get(`/api/employees/${employeeId}/photo-binary`, { responseType: 'blob' });
  }

  public static async getProfessionalData(employeeId: string): Promise<ProfessionalData> {
    return axios.get(`/api/employees/${employeeId}/professional`)
      .then(({ data }) => new ProfessionalData(data));
  }

  public static postProfessionalData(
    professionalData: ProfessionalData,
  ): Promise<void> {
    return axios.post(`/api/employees/${professionalData.employeeId}/professional`, professionalData);
  }

  public static getLoggedUserEmployeeId(): Promise<string> {
    return axios.get('/api/employees/me/id').then(({ data }) => data);
  }

  public static getLoggedUserEmployee(): Promise<EmployeeDTO> {
    return axios.get('/api/employees/me').then(({ data }) => data);
  }

  public static getLoggedUserWage(employeeId: string): Promise<EmployeeWage> {
    return axios.get(`/api/employees/${employeeId}/salary`).then(({ data }) => new EmployeeWage(data));
  }

  public static getAllSubordinates(): Promise<string[]> {
    return axios.get('/api/employees/subordinates').then(({ data }) => data);
  }

  public static getCurrencyByEmployeeId(employeeId: string): Promise<Currency> {
    return axios.get(`/api/employees/${employeeId}/professional/currency`).then(({ data }) => data);
  }

  public static async checkExistsDrdReviewOpen(assignmentId: string): Promise<boolean> {
    return axios.get(`/api/employees/me/drd-review/check-exists-open/${assignmentId}`)
      .then(({ data }) => data);
  }

  public static async concludeReview(): Promise<void> {
    return axios.put('/api/employees/me/drd-review/conclude');
  }
}
