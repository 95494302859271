export default class City {
  id: string;

  name: string;

  constructor(obj: any) {
    this.id = obj.id || '';
    this.name = obj.name || '';
  }

  public static of(obj: any): City {
    return new City(obj || {})
  }

  public static ofList(obj: any[]): City[] {
    return obj.map(this.of);
  }
}
