import axios from 'axios';
import Birthday from './birthday.entity';

export default class BirthdayService {
  public static getBirthdays(): Promise<Birthday[]> {
    return axios.get('api/personal/birthdays', { params: { loadPhotos: false } })
      .then((response: any) => Birthday.ofList(response.data));
  }

  public static getNextBirthdays(): Promise<Birthday[]> {
    return axios.get('api/personal/nextBirthdays', { params: { loadPhotos: false } })
      .then((response: any) => Birthday.ofList(response.data))
  }
}
