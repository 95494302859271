


























































































































import { Vue, Component, Prop } from 'vue-property-decorator'
import { VbSnackbarService, VbTooltipSlot } from 'bag-of-holding-library';
import PaginationComponent from '@/shared/pagination.component.vue';
import MobileHandler from '@/shared/utils/mobile-handler'
import Categories from '@/views/drd/form/tabs/indicators/indicator-category.json'
import DefaultIndicator from '@/domain/drd/entity/default-indicator.entity';
import Utilities from '@/views/drd/form/tabs/indicators/utility-types.json'
import IndicatorService from '@/domain/drd/service/indicator/indicator.service';
import ModalWarning from '@/shared/modal/modal-warning.component.vue';
import ClipBoardSerivce from '@/shared/utils/copy-clipboard.service'
import Headers from './header.json'
import CreateOrUpdateIndicatorDefaultModal from './create-update-indicator-default-modal.component.vue';
import ReadIndicatorDefaultModal from './read-indicator-default-modal.component.vue';

@Component({
  components: {
    PaginationComponent,
    CreateOrUpdateIndicatorDefaultModal,
    ReadIndicatorDefaultModal,
    ModalWarning,
    VbTooltipSlot,
  },
})
export default class IndicatorDefaultTableComponent extends Vue {
  @Prop() indicators!: DefaultIndicator[]

  headers = Headers

  @Prop() pagination!: any;

  @Prop({
    type: Boolean,
  }) loading!: boolean;

  @Prop({
    type: Boolean,
  }) hasUpdateIndicatorDefault!: boolean;

  @Prop({
    type: Boolean,
  }) hasInactiveIndicatorDefault!: boolean;

  isMobile = false;

  categories = Categories;

  utilities = Utilities;

  goalMeasumentTypes = {
    DAY: 'Dia',
    WEEK: 'Semana',
    MONTH: 'Mês',
    QUARTER: 'Quarter',
    SEMESTER: 'Semestre',
    YEAR: 'Ano',
  }

  openModal: boolean = false;

  openReadModal: boolean = false;

  indicatorUpdated!: DefaultIndicator;

  readIndicator!: DefaultIndicator;

  openModalWarning: boolean = false;

  indicatorInactivated!: DefaultIndicator;

  onResize() {
    this.isMobile = MobileHandler.isMobile();
  }

  updateSortBy(sortBy: any): void {
    this.updateTable({
      ...this.pagination,
      sortBy,
    });
  }

  updateSortDesc(sortDesc: any): void {
    this.updateTable({
      ...this.pagination,
      sortDesc,
    });
  }

  updateTable(page: any): void {
    this.$emit('update', page);
  }

  openUpdateModal(indicator: DefaultIndicator) {
    this.indicatorUpdated = indicator
    this.openModal = true;
  }

  closeModal() {
    this.openModal = false;
    this.updateTable(this.pagination)
  }

  showReadModal(indicator: DefaultIndicator) {
    this.readIndicator = indicator
    this.openReadModal = true;
  }

  showModalWarning(indicator: DefaultIndicator) {
    this.indicatorInactivated = indicator;
    this.openModalWarning = true;
  }

  closeModalWarning() {
    this.openModalWarning = false;
  }

  closeModalWarningWithCancelBtn() {
    this.indicatorInactivated.active = !this.indicatorInactivated.active;
    this.openModalWarning = false;
  }

  closeReadModal() {
    this.openReadModal = false
  }

  activeOrInactive() {
    this.loading = true;
    this.closeModalWarning();
    IndicatorService.deactiveIndicatorDefault(this.indicatorInactivated)
      .then(() => VbSnackbarService.showSuccess('Indicador atualizado!'))
      .catch(error => VbSnackbarService.handleHttpError(error))
      .finally(() => {
        this.loading = false;
        this.updateTable(this.pagination);
      })
  }

  openLink(link: string) {
    window.open(link, '_blank')
  }

  copyToClipboard(text: string): void {
    ClipBoardSerivce.copyToClipboard(text);
  }
}
