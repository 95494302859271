import axios from 'axios';
import PlanAgeGroupDTO from '../entity/plan-age-group-DTO';

export default class HealthPlanService {
  public static getHealthPLanList(): Promise<any> {
    return axios.get('/api/health-plan/discount/all')
      .then((response: any) => response.data);
  }

  public static getPlanAgeGroupByBirthDate(birthDate: string): Promise<PlanAgeGroupDTO> {
    return axios.get(`/api/health-plan/plan-age/${birthDate}`)
      .then((response: any) => PlanAgeGroupDTO.of(response.data));
  }
}
