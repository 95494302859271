












































































import { Vue, Component, Prop } from 'vue-property-decorator';
import { VbSnackbarService } from 'bag-of-holding-library';
import LabelSlot from '@/shared/slots/label-slot.component.vue';
import ModalComponent from '@/shared/modal/modal.component.vue';
import ModalOptions from '@/shared/modal/modal-options.entity';
import SelectiveProcessService from '@/domain/selective-process/service/selective-process.service';
import Department from '@/domain/selective-process/entity/job/department.entity';
import MobileHandler from '@/shared/utils/mobile-handler';
import Header from './departments-table-header.json';

@Component({
  components: {
    LabelSlot,
    ModalComponent,
  },
})
export default class DepartmentsModal extends Vue {
  @Prop() openModal!: boolean;

  departments: Department[] = [];

  isMobile: boolean = false;

  headers = Header;

  loading: boolean = false;

  loadingSync: boolean = false;

  created() {
    this.findAll();
  }

  get optionsHandled(): ModalOptions {
    return {
      title: 'Departamentos',
      titleSize: '20px',
      description: '',
      showOk: false,
      disabledOk: false,
      showCancel: true,
      labelCancel: 'fechar',
      width: 800,
    };
  }

  async findAll() {
    this.loading = true;
    this.departments = await SelectiveProcessService.findAllDepartment();
    this.loading = false;
  }

  closeModal(event: any) {
    this.$emit('closeModal', event);
  }

  onResize() {
    this.isMobile = MobileHandler.isMobile();
  }

  edit(department: Department) {
    department.editing = true;
  }

  updateThreShold(department: Department) {
    SelectiveProcessService.updateDepartmentThreshold(department)
      .then(() => {
        VbSnackbarService.showSuccess('Departamento atualizado com sucesso!');
      }).catch((error) => {
        VbSnackbarService.showError(error.repsonse.data.messages.toString());
      })
    department.editing = false;
  }

  async sync() {
    this.loadingSync = true;
    await SelectiveProcessService.importAllDepartment();
    this.loadingSync = false;
    this.findAll();
  }
}
