
































import {
  Prop, Component, Mixins,
} from 'vue-property-decorator';
import Modal from '@/shared/modal/modal.component.vue';
import { VbSnackbarService } from 'bag-of-holding-library';
import ModalMixin from '@/shared/modal/modal.mixin';
import LabelSlot from '@/shared/slots/label-slot.component.vue';
import Rules from '@/shared/rules/rules';
import ActivityDetailed from '@/domain/schedule/activitie/activity-detailed.entity';
import ActivityUpdateStatus from '@/domain/schedule/activitie/activity-update-status.entity';
import TextArea from '@/shared/fields/text-area.component.vue';
import ActivityService from '@/domain/schedule/activitie/activity.service';
import ModalOptions from '@/shared/modal/modal-options.entity';
import Status from './status.json';

@Component({
  components: {
    'modal-component': Modal,
    LabelSlot,
    TextArea,
  },
})
export default class UpdateActivityModal extends Mixins(ModalMixin) {
  @Prop({
    required: true,
  })
  private activity!: ActivityDetailed;

  status = Status;

  loading = false;

  rules = new Rules()

  updateStatus = new ActivityUpdateStatus({})

  refs: any = this.$refs;

  valid = false;

  get statusItems() {
    const statusList = this.status.filter((el: any) => el.vale !== this.activity.status)
    return this.activity.status
      ? statusList
      : statusList.filter((el:any) => el.value !== 'PENDING')
  }

  save() {
    this.loading = true;
    ActivityService.updateStatus(this.activity.id, this.updateStatus)
      .then((resp: any) => {
        VbSnackbarService.showSuccess('Status Alterado com sucesso!');
        this.updateStatus = new ActivityUpdateStatus({});
        this.$emit('refresh');
      })
      .catch((err: any) => VbSnackbarService.handleHttpError(err))
      .finally(() => {
        this.loading = false;
        this.closeModal();
      })
  }

  closeModal() {
    this.refs.form.reset();
    this.$emit('close')
  }

  private get manageActivityModalOptions(): ModalOptions {
    return {
      title: 'Alteração de status',
      showOk: true,
      labelOk: 'Salvar',
      disabledOk: !this.valid || this.loading,
      loadingOk: this.loading,
      width: 500,
    };
  }
}
