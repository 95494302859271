








































































import { Vue, Component } from 'vue-property-decorator';
import VueToExcel from 'vue-json-excel';
import CardTitle from '@/shared/card/card-title.component.vue';
import Employee from '@/domain/employees/entity/employee.entity';
import EmployeeSkill from '@/domain/employees/entity/employee-skill.entity';
import EmployeeService from '@/domain/schedule/employee/employee.service';
import ManagerService from '@/domain/employees/service/employee.service';
import AuthorityStore from '@/domain/security/authority.store';
import Skill from '@/domain/employees/entity/skill.entity';
import SkillType from '@/domain/employees/entity/skill-type.entity';
import Assignment from '@/domain/drd/entity/assignment.entity';
import DrdService from '@/domain/drd/service/drd.service';
import TableComponent from './employee-skill-map-table.component.vue';
import FilterComponent from './filter/filter.component.vue';
import EmployeeSummary from '../../../domain/schedule/employee/employee-summary.entity';
import Filter from '../../../domain/employees/entity/filter.entity';
import CareerService from '../../../domain/employees/service/career.service';
import UpdateAtribute from '../../../domain/employees/entity/update-atribute.entity';
import HeaderComponent from './employee-skill-map-table-header.component.vue';
import SkillService from '../../../domain/employees/service/skill.service';

@Component({
  name: 'EmployeesTableView',
  components: {
    FilterComponent, CardTitle, TableComponent, HeaderComponent, VueToExcel,
  },
})
export default class EmployeesTableView extends Vue {
  employees: Employee[] = [];

  employeesSkills: EmployeeSkill[] = [];

  filterEmployees: EmployeeSummary[] = [];

  businessUnit: UpdateAtribute[] = [];

  roles: UpdateAtribute[] = [];

  hardSkils: Skill[] = [];

  frameworks: Skill[] = [];

  businessExps: Skill[] = [];

  certifications: Skill[] = [];

  resultCenters: UpdateAtribute[] = [];

  lastFilterManager: string = '';

  lastFilterEmployee: string = '';

  tableLoading: boolean = false;

  lastFilter: Filter = new Filter({});

  showResignationModal: boolean = false;

  extraColumns: any[] = []

  lastFilterHardSkill: string = '';

  lastFilterFramework: string = '';

  lastBusinessExp: string = '';

  lastCertification: string = '';

  lastFilterAssignment: string = '';

  loadingXls: boolean = false;

  isManager: boolean = false;

  sortBy: Array<string> = ['username'];

  sortDesc: boolean[] = [false];

  filterAssignments: Assignment[] = [];

  xlsFields: any = {
    Colaborador: 'name',
    Cargo: 'role',
    'Hard Skills': 'hardSkillsToXls',
    'Framework / Metodologias': 'frameworksOrMethodologyToXls',
    'Experiência de negócios': 'businessExperienceToXls',
    Certificações: 'certificationsToXls',
  };

  pagination: any = {
    itemsPerPage: 10,
    page: 1,
    sortBy: this.sortBy,
    sortDesc: this.sortDesc,
    total: 0,
    pageCount: 0,
  }

  filter: Filter = Filter.of({});

  managers: EmployeeSummary[] = [];

  findAssignmentsByName(value: string) {
    if ((this.lastFilterAssignment !== value) && value) {
      this.lastFilterAssignment = value;
      if (value.length > 2) {
        sessionStorage.setItem('assignmentStore', value)
        DrdService.getAssigments(value)
          .then(this.mergeAssignments);
      }
    }
  }

  setFilterValues(value: any) {
    this.filter.orderBy.field = value.sortBy.toString();
    this.filter.orderBy.descending = value.sortDesc.toString();
    if (this.pagination.itemsPerPage < value.itemsPerPage) {
      value.page = 1;
    }
    this.pagination = value;
    this.findEmployees();
  }

  getLastFilter() {
    const lastFilter = sessionStorage.getItem('storageFilter');
    if (lastFilter) {
      this.filter = Filter.of(JSON.parse(lastFilter));
      if (this.filter.manager) {
        const manager: any = sessionStorage.getItem('managerStore');
        this.findManagers(manager);
      }
      if (this.filter.employee) {
        const employee: any = sessionStorage.getItem('employeeStore');
        this.findEmployeesByName(employee);
      }
    }
  }

  created() {
    this.isManagerAuth();
    CareerService.getRoles().then((resp: any) => this.roles = resp.data);
    CareerService.getBusinessUnit().then(resp => this.businessUnit = resp);
    this.findEmployees();
  }

  findEmployees() {
    this.tableLoading = true;
    this.mergeFilterSkills();

    SkillService.getAllWithEmployee(this.filter, this.pagination.page, this.pagination.itemsPerPage)
      .then((response: any) => {
        this.employeesSkills = [];
        this.employeesSkills = response.content;
        this.pagination.total = response.totalElements || 0;
        this.pagination.pageCount = response.totalPages;
      })
      .finally(() => this.tableLoading = false);
  }

  findManagers(value: string) {
    if ((this.lastFilterManager !== value) && value) {
      this.lastFilterManager = value;
      if (value.length > 2) {
        sessionStorage.setItem('managerStore', value)
        ManagerService.findManagersByName(value)
          .then(this.mergeManagers);
      }
    }
  }

  findEmployeesByName(value: string) {
    if ((this.lastFilterEmployee !== value) && value) {
      this.lastFilterEmployee = value;
      if (value.length > 2) {
        sessionStorage.setItem('employeeStore', value)
        EmployeeService.findEmployeesByName(value)
          .then(this.mergeEmployees);
      }
    }
  }

  updateTable(value: Filter) {
    if (value.equals(this.filter)) {
      this.filter = value;
      this.pagination.page = 1;
    }
    sessionStorage.setItem('storageFilter', JSON.stringify(this.filter));

    this.findEmployees();
  }

  mergeEmployees(employees: EmployeeSummary[]): void {
    const addedIds: string[] = this.filterEmployees.map((employee: EmployeeSummary) => employee.id)
    employees.forEach((employee: EmployeeSummary) => {
      if (!addedIds.includes(employee.id)) {
        this.filterEmployees.push(employee);
      }
    })
  }

  mergeManagers(employees: EmployeeSummary[]): void {
    const addedIds: string[] = this.managers.map((employee: EmployeeSummary) => employee.id)
    employees.forEach((employee: EmployeeSummary) => {
      if (!addedIds.includes(employee.id)) {
        this.managers.push(employee);
      }
    })
  }

  findHardSkillByType(value: string) {
    if ((this.lastFilterHardSkill !== value) && value) {
      this.lastFilterHardSkill = value;
      if (value.length > 2) {
        this.tableLoading = true;
        SkillService.getAllByType(value, SkillType.HARD_SKILLS)
          .then((response: any) => {
            this.mergeHardSkills(response);
          })
          .finally(() => this.tableLoading = false);
      }
    }
  }

  findFrameworkByType(value: string) {
    if ((this.lastFilterFramework !== value) && value) {
      this.lastFilterFramework = value;
      if (value.length > 2) {
        this.tableLoading = true;
        SkillService.getAllByType(value, SkillType.FRAMEWORKS_OR_METHODOLOGIES)
          .then((response: any) => {
            this.mergeFrameworks(response);
          })
          .finally(() => this.tableLoading = false);
      }
    }
  }

  findBusinessExpsByType(value: string) {
    if ((this.lastBusinessExp !== value) && value) {
      this.lastBusinessExp = value;
      if (value.length > 2) {
        this.tableLoading = true;
        SkillService.getAllByType(value, SkillType.BUSINESS_EXPERIENCE)
          .then((response: any) => {
            this.mergeBusinessExp(response);
          })
          .finally(() => this.tableLoading = false);
      }
    }
  }

  findCertificationByType(value: string) {
    if ((this.lastCertification !== value) && value) {
      this.lastCertification = value;
      if (value.length > 2) {
        this.tableLoading = true;
        SkillService.getAllByType(value, SkillType.CERTIFICATIONS)
          .then((response: any) => {
            this.mergeCertification(response);
          })
          .finally(() => this.tableLoading = false);
      }
    }
  }

  mergeHardSkills(skills: Skill[]): void {
    const addedIds: string[] = this.hardSkils.map((skill: Skill) => skill.id);

    skills.forEach((skill: Skill) => {
      if (!addedIds.includes(skill.id)) {
        this.hardSkils.push(skill);
      }
    })
  }

  mergeFrameworks(skills: Skill[]): void {
    const addedIds: string[] = this.frameworks.map((skill: Skill) => skill.id);

    skills.forEach((skill: Skill) => {
      if (!addedIds.includes(skill.id)) {
        this.frameworks.push(skill);
      }
    })
  }

  mergeBusinessExp(skills: Skill[]): void {
    const addedIds: string[] = this.businessExps.map((skill: Skill) => skill.id);

    skills.forEach((skill: Skill) => {
      if (!addedIds.includes(skill.id)) {
        this.businessExps.push(skill);
      }
    })
  }

  mergeCertification(skills: Skill[]): void {
    const addedIds: string[] = this.certifications.map((skill: Skill) => skill.id);

    skills.forEach((skill: Skill) => {
      if (!addedIds.includes(skill.id)) {
        this.certifications.push(skill);
      }
    })
  }

  getResultCenter() {
    CareerService.getResultCenter(this.filter.businessUnit)
      .then((resp: any) => this.resultCenters = resp)
  }

  setExtraColumns(extraColumns: any[]): void {
    this.extraColumns = extraColumns
  }

  isManagerAuth() {
    return AuthorityStore.isManager()
      .then((response) => {
        this.isManager = response;
      })
  }

  mergeFilterSkills() {
    const allSkills: string[] = [];

    this.filter.hardSkill.forEach((value) => {
      allSkills.push(value)
    });

    this.filter.frameworks.forEach((value) => {
      allSkills.push(value)
    });

    this.filter.businessExps.forEach((value) => {
      allSkills.push(value)
    });

    this.filter.certifications.forEach((value) => {
      allSkills.push(value)
    });

    this.filter.allSkillFilter = allSkills;
  }

  mergeAssignments(assignments: Assignment[]): void {
    const addedIds: string[] = this.filterAssignments.map((assignment: Assignment) => assignment.id)
    assignments.forEach((assignment: Assignment) => {
      if (!addedIds.includes(assignment.id)) {
        this.filterAssignments.push(assignment);
      }
    })
  }

  exportToXLS(): Promise<EmployeeSkill[]> {
    this.loadingXls = true;

    return SkillService.getAllWithEmployee(this.filter, this.pagination.page, this.pagination.total)
      .then((response: any) => {
        this.employeesSkills = [];
        this.employeesSkills = response.content;
        this.convertSkillToXls();
        return this.employeesSkills;
      })
      .finally(() => this.loadingXls = false);
  }

  convertSkillToXls(): void {
    this.employeesSkills.forEach((item) => {
      item.hardSkillsToXls = item.allKnowledgeDTO.hardSkills
        .map(skill => skill.description);
      item.frameworksOrMethodologyToXls = item.allKnowledgeDTO.frameworksOrMethodologies
        .map(skill => skill.description);
      item.businessExperienceToXls = item.allKnowledgeDTO.businessExperience
        .map(skill => skill.description);
      item.certificationsToXls = item.allKnowledgeDTO.certifications
        .map(skill => skill.description);
    });
  }
}
