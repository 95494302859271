import axios from 'axios';
import Survey from './entity/survey.entity';

export default class SurveyService {
  public static getSurveyByAcronymLanguage(acronymlanguage: string) {
    return axios.get(`/api/public/survey/${acronymlanguage}`);
  }

  public static getSurveyByTypeAndAcronymLanguage(type: string, acronymlanguage: string) {
    return axios.get(`/api/public/survey/${type}/${acronymlanguage}`)
      .then(({ data }) => new Survey(data));
  }
}
