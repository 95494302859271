import Template from './template.entity';

export default class Survey {
  id: string;

  name: string;

  description: string;

  template: Template;

  constructor(obj: any = {}) {
    this.id = obj.id || '';
    this.name = obj.name || '';
    this.description = obj.description || '';
    this.template = obj.template || '';
  }
}
