

































import { Vue, Component, Prop } from 'vue-property-decorator';

@Component
export default class ExpansionPanel extends Vue {
  @Prop({
    type: String,
    default: '',
  }) title!: string;

  clickExpand() {
    this.$emit('clickExpand');
  }
}
