import DateFormat from '@/shared/utils/date-format';
import moment from 'moment';

export default class SalaryReadjustmentParametrization {
  id: string;

  inpc: number;

  minimalReadjustment: number;

  validSince: string;

  constructor(obj: any = {}) {
    this.id = obj.id || null;
    this.inpc = obj.inpc;
    this.minimalReadjustment = obj.minimalReadjustment;
    this.validSince = this.formatYearMonth(obj.validSince);
  }

  public static of(salaryReadjustmentParametrization: any): SalaryReadjustmentParametrization {
    return new SalaryReadjustmentParametrization(salaryReadjustmentParametrization || {});
  }

  dateValidSinceSuggestion() {
    return moment().format('MM/YYYY')
  }

  formatYearMonth(value) {
    if (value === undefined || value === null) return this.dateValidSinceSuggestion();
    return DateFormat.monthYearToFormated(value)
  }
}
