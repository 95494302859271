



















import { Vue, Component, Prop } from 'vue-property-decorator';
import Rules from '../rules/rules';
import LabelSlot from '../slots/label-slot.component.vue';

@Component({
  components: {
    LabelSlot,
  },
})
export default class PhoneComponent extends Vue {
  @Prop({
    type: String,
    default: 'Telefone Celular',
  }) label!: string;

  @Prop({
    type: Boolean,
    default: false,
  }) readonly!: boolean;

  @Prop({
    type: Boolean,
    default: true,
  }) countryIsBrazil!: boolean;

  @Prop() rules!: Rules;

  @Prop({
    type: Boolean,
    default: false,
  }) disabled!: boolean;

  @Prop({
    type: String,
  }) phone!: string;

  update(value: string) {
    this.$emit('update', value);
  }
}
