export default class FilterVacationBalance {
    employee: string;

    manager: string;

    indirectSubordinates: boolean = true;

    businessUnit?: string;

    constructor(obj: any) {
      this.employee = obj.employee || '';
      this.manager = obj.manager || '';
      this.indirectSubordinates = obj.indirectSubordinates || true;
      this.businessUnit = obj.businessUnit || '';
    }

    public static of(value: any): FilterVacationBalance {
      return new FilterVacationBalance(value || {});
    }

    public static ofList(response: any): FilterVacationBalance[] {
      return response.data.map(this.of);
    }

    public equals(obj: FilterVacationBalance) {
      return this.manager === obj.manager
      && this.employee === obj.employee
      && this.businessUnit === obj.businessUnit;
    }
}
