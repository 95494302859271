














import {
  Vue, Component, Prop,
} from 'vue-property-decorator';
import { VbDateFormat } from 'bag-of-holding-library';
import ModalComponent from '@/shared/modal/modal.component.vue';
import ModalOptions from '@/shared/modal/modal-options.entity';
import LabelSlot from '@/shared/slots/label-slot.component.vue';

  @Component({
    components: { ModalComponent, LabelSlot },
    filters: {
      formatDate(value: string) {
        return VbDateFormat.monthYearToFormated(value);
      },
    },
  })

export default class ObservationModal extends Vue {
  @Prop() show!: boolean;

  @Prop() observation!: string;

  loading = false;

  employeeArray: any = [];

  private get optionsHandled(): ModalOptions {
    return {
      title: 'Observação',
      titleSize: '20px',
      description: '',
      showOk: false,
      disabledOk: true,
      width: 1200,
    };
  }

  close() {
    this.$emit('close');
  }
}
