import Reason from './reason.entity';

export default class ActivityUpdate {
  id: string;

  reason: Reason;

  constructor(id: string, reason: string) {
    this.id = id;
    this.reason = new Reason(reason);
  }
}
