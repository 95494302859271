

































































import { Vue, Component, Prop } from 'vue-property-decorator';
import LabelSlot from '@/shared/slots/label-slot.component.vue';

@Component({
  components: {
    LabelSlot,
  },
})
export default class SelectiveProcessCandidateDetails extends Vue {
  @Prop({
    type: String,
  }) candidateId!: string;

  @Prop({
    type: String,
  }) name!: string;

  @Prop({
    type: String,
  }) email!: string;

  @Prop({
    type: String,
  }) phone!: string;

  @Prop({
    type: String,
  }) linkedin!: string;

  @Prop({
    type: String,
  }) assessmentLink!: string;

  @Prop({
    type: String,
  }) assessmentLinkDescription!: string;

  @Prop({
    type: Number,
  }) cultureGrade!: number;

  openFit(candidateId: string) {
    this.$emit('closeModal');
    this.$router.push(`/selective-process/candidate-culture-grade-details/${this.candidateId}`);
  }
}
