

































































import { Vue, Component, Prop } from 'vue-property-decorator';
import BehavioralSkill from '@/domain/drd/entity/behavioral-skill.entity';
import LabelSlot from '@/shared/slots/label-slot.component.vue';
import Rules from '@/shared/rules/rules';
import ActiveCheckboxField from '@/views/drd/form/tabs/common-components/active-checkbox-field.component.vue';
import FieldsArea from '@/views/drd/form/tabs/common-components/fields-area.component.vue';
import SeniorityLevelComponent from '@/views/drd/commons/seniority-level.component.vue';

@Component({
  components: {
    ActiveCheckboxField,
    FieldsArea,
    LabelSlot,
    SeniorityLevelComponent,
  },
})
export default class BehavioralSkillComponent extends Vue {
  @Prop({
    type: BehavioralSkill,
    required: true,
  })
  private behavioralSkill!: BehavioralSkill;

  private rules: Rules = new Rules();

  private updateField(field: string, value: any): void {
    this.$emit(`update:${field}`, value);
  }

  private deleteBehavioralSkill(): void {
    this.$emit('delete');
  }
}
