

















































import { Vue, Component, Prop } from 'vue-property-decorator';

@Component
export default class HandleCreateHistoryModal extends Vue {
  @Prop({
    type: Boolean,
    default: false,
  }) showCreateHistoryModal!: boolean;

  @Prop({
    type: Boolean,
    default: false,
  }) loadingSave!: boolean;

  save(createHistory: boolean) {
    this.$emit('save', createHistory);
  }
}
