







































































import { Vue, Component, Prop } from 'vue-property-decorator';
import ModalOptions from '../modal/modal-options.entity';
import ModalComponent from '../modal/modal.component.vue';

@Component({
  components: { ModalComponent },
})
export default class TraslateModal extends Vue {
  @Prop({
    type: Boolean,
  }) openModal!: boolean;

  @Prop({
    type: String,
  }) source!: string;

  closeModal() {
    this.openModal = false;
    this.$emit('closeModal');
  }

  private get optionsHandled(): ModalOptions {
    return {
      title: 'Como traduzir - Como traducir - How to translate',
      titleSize: '20px',
      description: '',
      showOk: false,
      disabledOk: false,
      labelOk: '',
      okColor: 'tabBlue',
      showCancel: true,
      labelCancel: 'Fechar',
      width: 800,
    };
  }
}
