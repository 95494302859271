import RouteCheckerTo from '@/views/route-checker';
import SeniorityRemuneration from './seniority-remuneration.view.vue';
import Table from './table/seniority-remuneration-table.view.vue';

export default [
  {
    path: '/seniority-remuneration',
    component: SeniorityRemuneration,
    children: [
      {
        path: '/',
        name: 'Remuneração',
        component: Table,
        beforeEnter: RouteCheckerTo.hasAccessWages,
      },
    ],
  },
];
