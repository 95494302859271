export default class ActivityResultRequest {
  result: string;

  constructor(result: string) {
    this.result = result;
  }

  public static of(result: string) {
    return new ActivityResultRequest(result);
  }
}
