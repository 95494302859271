var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{staticClass:"salary-readjustment-param-list__data-table",attrs:{"items":_vm.parametrizations,"headers":_vm.headers,"loading":_vm.loading,"sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc,"no-data-text":"Não foi encontrado nenhum registro.","loading-text":"Carregando...","hide-default-footer":""},on:{"update:sort-by":_vm.updateSortBy,"update:sort-desc":_vm.updateSortDesc},scopedSlots:_vm._u([{key:"item.inpc",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.inpc)+"% ")]}},{key:"item.minimalReadjustment",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.minimalReadjustment)+"% ")]}},{key:"item.validSince",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.validSince)+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"disabled":!_vm.hasUpdateSalaryReadjusment,"color":"primary","icon":""},on:{"click":function($event){return _vm.openEditDialog(item.id)}}},[_c('v-icon',[_vm._v("mdi-pencil-outline")])],1),_c('v-btn',{attrs:{"disabled":!_vm.hasDeletePermission,"color":"error","icon":""},on:{"click":function($event){return _vm.openDeleteDialog(item.id)}}},[_c('v-icon',[_vm._v("mdi-trash-can-outline")])],1)]}}],null,true)}),_c('pagination-component',{staticClass:"mt-8 ml-2",attrs:{"pagination":{ itemsPerPage: _vm.size, total: _vm.totalElements, page: _vm.page }},on:{"input":_vm.updateTable}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }