


































































































































import {
  Vue, Component, Prop, Watch,
} from 'vue-property-decorator';
import { VbTooltipSlot } from 'bag-of-holding-library';
import CardTitle from '@/shared/card/card-title.component.vue';
import ModalComponent from '@/shared/modal/modal.component.vue';
import ModalOptions from '@/shared/modal/modal-options.entity';
import LabelSlot from '@/shared/slots/label-slot.component.vue';
import Rules from '@/shared/rules/rules';
import EmployeeService from '@/domain/schedule/employee/employee.service';
import EmployeeSummary from '@/domain/schedule/employee/employee-summary.entity';
import DatePickerField from '@/shared/date-picker/date-picker-field.component.vue';
import RiskAttendance from '@/domain/risk-attendance/entity/risk-attendance.entity';
import RequestReason from '@/domain/risk-attendance/request-reason';
import ManagerService from '@/domain/employees/service/employee.service';
import AuthorityStore from '@/domain/security/authority.store';
import StatusRiskAttendance from '../../form-risk-attendance/status.json';
import RiskAttendanceType from '../../risk-attendance-type.json';

  @Component({
    components: {
      ModalComponent, LabelSlot, VbTooltipSlot, DatePickerField, CardTitle,
    },
  })

export default class ResignationModal extends Vue {
  @Prop() riskAttendance!: RiskAttendance;

  @Prop() hasUpdateRiskAttendance!: boolean;

  @Prop() hasCreateRiskAttendance!: boolean;

  valid: boolean = false;

  rules: Rules = new Rules();

  role: any = '';

  loading = false;

  refs: any = this.$refs

  responsibles: EmployeeSummary[] = [];

  private employees: EmployeeSummary[] = [];

  private lastFilterEmployee: string = '';

  private lastFilterResponsible: string = '';

  private requesters: EmployeeSummary[] = []

  private lastFilterRequester: string = '';

  private status = StatusRiskAttendance;

  managerId: string = ''

  isAdm: boolean = false;

  isBP: boolean = false;

  isManager: boolean = false;

  riskAttendanceType = RiskAttendanceType;

  private get optionsHandled(): ModalOptions {
    return {
      title: 'Adicionar novo risco',
      titleSize: '25px',
      description: '',
      showOk: true,
      labelOk: 'Salvar',
      disabledOk: !this.valid || this.loading,
      okColor: 'tabBlue',
      showCancel: !this.loading,
      labelCancel: 'Cancelar',
      width: 700,
    };
  }

  created() {
    this.searchManagerName();
    this.isAdministrator();
    this.isBusinnesPartner();
    this.getIsManager();
  }

  private readonly requestReason = RequestReason;

  clear() {
    this.role = '';
  }

  findEmployeesByName(value: string) {
    if ((this.lastFilterEmployee !== value) && value) {
      this.lastFilterEmployee = value;
      if (value.length > 2) {
        if (!this.isAdm && this.isBP) {
          EmployeeService.findEmployeesByNameExcludeBPs(value).then(this.setEmployees);
          return;
        }
        if (!this.isAdm && this.isManager) {
          EmployeeService.findEmployeesByNameExcludeNotManaged(value).then(this.setEmployees);
          return;
        }
        EmployeeService.findEmployeesByName(value).then(this.setEmployees);
      }
    }
  }

  setEmployees(resp: EmployeeSummary[]) {
    this.employees = resp;
  }

  searchManagerName() {
    ManagerService.getLoggedUserEmployeeId().then((response: string) => {
      this.managerId = response;
    })
  }

  isAdministrator() {
    AuthorityStore.isAdministrator()
      .then((response: any) => {
        this.isAdm = response
      })
  }

  isBusinnesPartner() {
    AuthorityStore.isBusinnesPartner()
      .then((response: any) => {
        this.isBP = response
      })
  }

  getIsManager() {
    AuthorityStore.isManager()
      .then((response: any) => {
        this.isManager = response
      });
  }

  findResponsibleByName(value: string) {
    if ((this.lastFilterResponsible !== value) && value) {
      this.lastFilterResponsible = value;
      if (value.length > 2) {
        EmployeeService.findEmployeesByNameAndIsReponsible(value, true)
          .then((resp: EmployeeSummary[]) => this.responsibles = resp);
      }
    }
  }

  @Watch('valid')
  emitValid() {
    this.$emit('isValid', this.valid);
  }

  statusIsDisabled(status: any): boolean {
    return !status.active
  }
}
