

























import { Vue, Component, Prop } from 'vue-property-decorator';
import ScoreDependentBenefits from '@/domain/employees/entity/score-dependent-benefits';
import healthPlanRegions from '@/views/employees/profile/tab-components/score/health-plan-type.json';
import headers from './dependent-benefits-table-header.json';

@Component
export default class DependentBenefitsTableComponent extends Vue {
  @Prop({
    type: Array,
    required: true,
  })
  private dependents!: ScoreDependentBenefits[];

  private headers: any[] = headers;

  formatBoolean(boolValue: boolean): string {
    if (boolValue === null || boolValue === undefined) return '-';
    return boolValue ? 'Sim' : 'Não';
  }

  formatHealthPlanRegion(region: string): string {
    const matchingRegion: any = healthPlanRegions.find(it => it.value === region);
    return matchingRegion ? matchingRegion.description : '-';
  }
}
