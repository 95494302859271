


























import { Component, Prop, Vue } from 'vue-property-decorator';
import CardTitle from '@/shared/card/card-title.component.vue';
import MobileHandler from '@/shared/utils/mobile-handler';
import EmployeeFilter from '@/domain/employees/entity/filter.entity';
import AuthorityStore from '@/domain/security/authority.store';

@Component({
  components: {
    CardTitle,
  },
})
export default class ScheduleHeader extends Vue {
  @Prop() filter!: EmployeeFilter;

  hasAccessCreate = true;

  isMobile = false;

  onResize() {
    this.isMobile = MobileHandler.isLessThan(768);
  }

  private openRegisterSkillModal(): void {
    this.$emit('openRegisterSkill');
  }

  created() {
    AuthorityStore.isSkillMapCreate()
      .then((response) => {
        this.hasAccessCreate = response;
      })
  }
}
