





































import { Vue, Component } from 'vue-property-decorator';
import CardTitle from '@/shared/card/card-title.component.vue';
import AttendanceService from '@/domain/attendance/service/attendance.service';
import ManagerService from '@/domain/employees/service/employee.service';
import EmployeeService from '@/domain/schedule/employee/employee.service';
import FilteredAttendance from '@/domain/attendance/entity/filtered-attendance';
import Attendance from '@/domain/attendance/entity/attendance.entity';
import Authority from '@/domain/security/authority.entity';
import AuthorityStore from '@/domain/security/authority.store';
import TableComponent from './attendance-table.component.vue';
import FilterComponent from './filter/filter.component.vue';
import EmployeeSummary from '../../../domain/schedule/employee/employee-summary.entity';
import Filter from '../../../domain/attendance/entity/filter.entity';
import CareerService from '../../../domain/employees/service/career.service';
import UpdateAtribute from '../../../domain/employees/entity/update-atribute.entity';
import HeaderComponent from './attendance-table-header.component.vue';

@Component({
  components: {
    FilterComponent, CardTitle, TableComponent, HeaderComponent,
  },
})
export default class Attendances extends Vue {
  attendances: FilteredAttendance[] = [];

  filterEmployees: EmployeeSummary[] = [];

  businessUnit: UpdateAtribute[] = [];

  roles: UpdateAtribute[] = [];

  lastFilterManager: string = '';

  lastFilterEmployee: string = '';

  lastFilterResponsible: string = '';

  lastFilterResultCenter: string = '';

  tableLoading: boolean = false;

  filter: Filter = new Filter({});

  attendance = Attendance.of({});

  isFirstFilter = true;

  sortBy: Array<string> = ['statusAttendance'];

  sortDesc: boolean[] = [false];

  createAttendance: boolean = false;

  updateAttendance: boolean = false;

  readAttendance: boolean = false;

  deleteAttendance: boolean = false;

  pagination: any = {
    itemsPerPage: 10,
    page: 1,
    sortBy: this.sortBy,
    sortDesc: this.sortDesc,
    total: 0,
    pageCount: 0,
  }

  managers: EmployeeSummary[] = [];

  responsibles: EmployeeSummary[] = [];

  resultCenters: UpdateAtribute[] = [];

  authority: Authority = new Authority([]);

  getLastFilter() {
    const lastFilter = sessionStorage.getItem('storageAttendanceFilter');
    if (lastFilter) {
      this.filter = Filter.of(JSON.parse(lastFilter));
      if (this.filter.manager) {
        const manager: any = sessionStorage.getItem('managerAttendanceStore');
        this.findManagers(manager);
      }
      if (this.filter.employee) {
        const employee: any = sessionStorage.getItem('employeeAttendanceStore');
        this.findEmployeesByName(employee);
      }
      if (this.filter.responsible) {
        const responsible: any = sessionStorage.getItem('responsibleAttendanceStore');
        this.findResponsible(responsible);
      }
      if (this.filter.businessUnit) {
        this.getResultCenter();
      }
    }
  }

  setFilterValues(value: any) {
    if (this.pagination.itemsPerPage < value.itemsPerPage) {
      value.page = 1;
    }
    this.pagination = value;
    this.findAttendance();
  }

  created() {
    CareerService.getRoles().then((resp: any) => this.roles = resp.data);
    CareerService.getBusinessUnit().then(resp => this.businessUnit = resp);
    this.getLastFilter();
    this.findAttendance();
    this.validationAuthorities();
  }

  updateAttendances(value: Filter) {
    if (value.equals(this.filter)) {
      this.filter = value;
      this.pagination.page = 1;
    }
    sessionStorage.setItem('storageAttendanceFilter', JSON.stringify(this.filter));
    this.findAttendance();
  }

  findAttendance() {
    this.tableLoading = true;
    this.filter.prepareToSend();
    AttendanceService.findAttendance(this.pagination.page,
      this.pagination.itemsPerPage, this.filter)
      .then((response: any) => {
        this.attendances = FilteredAttendance.ofList(response.content);
        this.pagination.total = response.totalElements || 0;
        this.pagination.pageCount = response.totalPages;
      })
      .finally(() => this.tableLoading = false);
  }

  findManagers(value: string) {
    if ((this.lastFilterManager !== value) && value) {
      this.lastFilterManager = value;
      if (value.length > 2) {
        sessionStorage.setItem('managerAttendanceStore', value)
        ManagerService.findManagersByName(value)
          .then((resp: EmployeeSummary[]) => this.managers = resp);
      }
    }
  }

  findEmployeesByName(value: string) {
    if ((this.lastFilterEmployee !== value) && value) {
      this.lastFilterEmployee = value;
      if (value.length > 2) {
        sessionStorage.setItem('employeeAttendanceStore', value)
        EmployeeService.findEmployeesByName(value)
          .then((resp: EmployeeSummary[]) => this.filterEmployees = resp);
      }
    }
  }

  findResponsible(value: string) {
    if ((this.lastFilterResponsible !== value) && value) {
      this.lastFilterResponsible = value;
      if (value.length > 2) {
        sessionStorage.setItem('responsibleAttendanceStore', value)
        EmployeeService.findEmployeesByName(value)
          .then((resp: EmployeeSummary[]) => this.responsibles = resp);
      }
    }
  }

  getResultCenter() {
    CareerService.getResultCenter(this.filter.businessUnit)
      .then((resp: any) => this.resultCenters = resp)
  }

  async validationAuthorities() {
    await this.getAuthority();
    this.readAttendance = this.authority.hasReadAttedance();
    this.createAttendance = this.authority.hasCreateAttedance();
    this.updateAttendance = this.authority.hasUpdateAttendance();
    this.deleteAttendance = this.authority.hasDeleteAttedance();
  }

  async getAuthority() {
    await AuthorityStore.fetchedAuthority().then(authority => this.authority = authority);
  }
}
