















import { Vue, Component, Prop } from 'vue-property-decorator';

@Component
export default class SelectionComponent extends Vue {
  @Prop({ type: Number, default: 0 })
  length!: number;

  @Prop({ type: Object, required: true })
  item!: any;

  @Prop({ type: Number, default: 0 })
  index!: number;

  @Prop({ type: String, default: 'name' })
  chipField!: string;

  @Prop({ type: String, default: '' })
  lengthText?: string;

  get containsSlot() {
    return !!this.$slots.content;
  }
}
