var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{directives:[{name:"resize",rawName:"v-resize",value:(_vm.onResize),expression:"onResize"}],staticClass:"data-table",attrs:{"hide-default-footer":"","loading-text":"Carregando...","no-data-text":"Nenhuma competência encontrada...","headers":_vm.headers,"items":_vm.items,"loading":_vm.loading,"page":_vm.page.page,"sort-by":_vm.page.sortBy,"sort-desc":_vm.page.sortDesc,"items-per-page":_vm.page.itemsPerPage,"page-count":_vm.page.pageCount},on:{"update:sortBy":function($event){return _vm.$set(_vm.page, "sortBy", $event)},"update:sort-by":[function($event){return _vm.$set(_vm.page, "sortBy", $event)},_vm.updateTable],"update:sortDesc":function($event){return _vm.$set(_vm.page, "sortDesc", $event)},"update:sort-desc":[function($event){return _vm.$set(_vm.page, "sortDesc", $event)},_vm.updateTable],"update:itemsPerPage":function($event){return _vm.$set(_vm.page, "itemsPerPage", $event)},"update:items-per-page":function($event){return _vm.$set(_vm.page, "itemsPerPage", $event)},"update:pageCount":function($event){return _vm.$set(_vm.page, "pageCount", $event)},"update:page-count":function($event){return _vm.$set(_vm.page, "pageCount", $event)},"update":_vm.updateTable},scopedSlots:_vm._u([{key:"item.whatPTBR",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"text-justify"},[_vm._v(" "+_vm._s(item.whatPTBR)+" ")])]}},{key:"item.type",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"pa-2",style:({background: _vm.getType(item.type).color, 'border-radius': '20px'})},[_vm._v(" "+_vm._s(_vm.getType(item.type).description)+" ")])]}},{key:"item.lastModifiedDate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.lastModifiedDate))+" ")]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-row',{attrs:{"justify":"center","no-gutters":""}},[_c('v-col',{attrs:{"cols":"auto"}},[_c('v-btn',{attrs:{"color":"primary","icon":""},on:{"click":function($event){return _vm.showEditModal(item)}}},[_c('v-icon',[_vm._v("mdi-pencil-outline")])],1)],1),_c('v-col',{attrs:{"cols":"auto"}},[_c('v-btn',{attrs:{"color":"black","icon":""},on:{"click":function($event){return _vm.showViewSkillModal(item)}}},[_c('v-icon',[_vm._v("mdi-eye-outline")])],1)],1),_c('v-col',{attrs:{"cols":"auto"}},[_c('v-btn',{attrs:{"color":"error","icon":""},on:{"click":function($event){return _vm.showModalWarning(item)}}},[_c('v-icon',[_vm._v("mdi-delete")])],1)],1)],1)]}}],null,true)}),_c('pagination-component',{staticClass:"mt-8 ml-md-2",attrs:{"pagination":_vm.page,"totalVisible":_vm.isMobile ? 5 : 8},on:{"input":_vm.updateTable}})],1)],1),(_vm.openEditSkillModal)?_c('update-skill-modal',{attrs:{"open":_vm.openEditSkillModal,"skillId":_vm.skillEditedId,"skill":_vm.skillEdited},on:{"update":_vm.updateSkill,"close":_vm.closeEditModal}}):_vm._e(),_c('view-skill-modal',{attrs:{"open":_vm.openViewSkillModal,"skill":_vm.skillView},on:{"close":function($event){_vm.openViewSkillModal = false}}}),_c('modal-warning',{attrs:{"value":_vm.openModalWarning,"options":_vm.modalWarningOptions},on:{"ok":_vm.disableSkill,"cancel":_vm.closeModalWarning}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }