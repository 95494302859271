
























































import { Vue, Component, Prop } from 'vue-property-decorator';
import AuthorityStore from '@/domain/security/authority.store';
import ModalWarning from '@/shared/modal/modal-warning.component.vue';
import PaginationComponent from '@/shared/pagination.component.vue';
import splitContent from '@/shared/directive/split-content';
import DateHandler from '@/shared/utils/date-format';
import Skill from '@/domain/employees/entity/skill.entity';
import SkillService from '@/domain/employees/service/skill.service';
import { VbSnackbarService } from 'bag-of-holding-library';
import Header from './header.json';

@Component({
  components: { PaginationComponent, ModalWarning },
  filters: {
    splitContent,
    formatDate(value: string) {
      return DateHandler.timestampToLocal(value.split('T')[0]);
    },
  },
})
export default class TableComponent extends Vue {
  @Prop() items!: any[];

  @Prop() loading!: boolean;

  @Prop() page!: any;

  @Prop({ type: Array, default: () => [] })

  private extraColumns!: any[];

  private header = Header;

  private showDeleteConfirmationDialog: boolean = false;

  private deletinglId: string | null = null;

  private hasAccessToEdit = false;

  private hasAccessToDelete = false;

  created() {
    this.checkAccess();
  }

  private checkAccess() {
    AuthorityStore.isSkillMapUpdate()
      .then((response) => {
        this.hasAccessToEdit = response;
      });

    AuthorityStore.isSkillMapDelete()
      .then((response) => {
        this.hasAccessToDelete = response;
      })
  }

  private closeDeleteConfirmationDialog(): void {
    this.deletinglId = null;
    this.showDeleteConfirmationDialog = false;
  }

  private deleteItem(): void {
    if (this.deletinglId === null) return;

    this.loading = true;
    SkillService.deleteSkillById(this.deletinglId)
      .then((response: any) => {
        VbSnackbarService.showSuccess('Skill deletada com sucesso');
      })
      .catch((err: any) => VbSnackbarService.handleHttpError(err))
      .finally(() => {
        this.loading = false;
        this.closeDeleteConfirmationDialog();
      });
  }

  private openDeleteDialog(item: Skill): void {
    if (!item.active) return;
    this.deletinglId = item.id;
    this.showDeleteConfirmationDialog = true;
  }

  private get headersAndExtraColumns(): any[] {
    return this.header.concat(this.extraColumns);
  }

  private updateTable(pagination: any): void {
    this.$emit('update', pagination);
  }

  private select(item: any): void {
    this.$router.push({
      path: `/profile/${item.username}/`,
    });
  }

  private openEditSkillModal(item: Skill): void {
    this.$emit('openEditSkill', item);
  }

  private selectStatus = {
    APPROVED: 'Aprovado',
    PENDING: 'Pendente',
    REJECTED: 'Rejeitado',
  }

  private selectSkills = {
    HARD_SKILLS: 'Hard Skills',
    FRAMEWORKS_OR_METHODOLOGIES: 'Frameworks / Metedologias',
    BUSINESS_EXPERIENCE: 'Experiência de negócios',
    CERTIFICATIONS: 'Certificações',
  }
}
