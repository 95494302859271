














































































































































































import { Vue, Component, Prop } from 'vue-property-decorator';
import LabelSlot from '@/shared/slots/label-slot.component.vue';
import SelectionComponent from '@/shared/fields/selection.componet.vue';
import DatePickerField from '@/shared/date-picker/date-picker-field.component.vue'
import Filter from '@/domain/risk-attendance/entity/filter.entity';
import MonthYearSelect from '@/shared/month-year-picker/month-year-select.component.vue';
import ResignationReasonJson from '@/views/attendance/form-attendances/resignation-reason.json';
import RiskAttendanceTypes from '../../risk-attendance-type.json';
import RiskAttendanceSources from '../../risk-attendance-source.json';
import EmployeeSummary from '../../../../domain/schedule/employee/employee-summary.entity';
import UpdateAtribute from '../../../../domain/employees/entity/update-atribute.entity';

@Component({
  components: {
    LabelSlot,
    MonthYearSelect,
    DatePickerField,
    SelectionComponent,
  },
})
export default class FilterAttendanceRiskComponent extends Vue {
  @Prop() filter!: Filter;

  @Prop() managers!: EmployeeSummary;

  @Prop() employees!: EmployeeSummary;

  @Prop() responsibles!: EmployeeSummary;

  @Prop() businessUnits!: UpdateAtribute;

  @Prop() lastFilter?: Filter;

  @Prop() isManager!: boolean;

  @Prop() isAdministrator!: boolean;

  riskAttendanceTypes = RiskAttendanceTypes;

  riskAttendanceSources = RiskAttendanceSources;

  private readonly resignationReasonJson = ResignationReasonJson.sort((reason1, reason2) => {
    if (reason1.description < reason2.description) return -1;
    if (reason1.description > reason2.description) return 1;
    return 0
  });

  private created() {
    this.sendFilter()
  }

  applyRequestDateFilter(date: string) {
    this.filter.requestDate = date;
    this.sendFilter()
  }

  clearRequestDate() {
    this.filter.requestDate = ''
    this.sendFilter()
  }

  applyLimitDateFilter(date: string) {
    this.filter.limitDate = date;
    this.sendFilter()
  }

  clearLimitDate() {
    this.filter.limitDate = ''
    this.sendFilter()
  }

  private sendFilter() {
    this.$emit('filter', this.filter);
  }

  private findManagers(value: string) {
    this.$emit('findManagers', value);
  }

  private findResponsible(value: string) {
    this.$emit('findResponsibles', value);
  }

  private findEmployees(value: string) {
    this.$emit('findEmployees', value);
  }

  removeTypeChip(chip: string): void {
    this.filter.typesValues.splice(this.filter.typesValues.indexOf(chip), 1);
    this.sendFilter()
  }

  updateTypesValues(typesValues: string[]) {
    this.filter.typesValues = typesValues;
    this.sendFilter()
  }

  removeSourceChip(chip: string): void {
    this.filter.sourcesValues.splice(this.filter.sourcesValues.indexOf(chip), 1);
    this.sendFilter()
  }

  updateSourcesValues(sourcesValues: string[]) {
    this.filter.sourcesValues = sourcesValues;
    this.sendFilter()
  }
}
