































import { Vue, Component, Prop } from 'vue-property-decorator';

@Component
export default class AssessmentResultCard extends Vue {
  @Prop({
    type: Number,
    default: 0,
  }) value!: number;

  @Prop({
    type: String,
    required: true,
  }) text!: string;

  @Prop({
    type: String,
    default: 'primary',
  }) color!: string;

  @Prop({
    type: Boolean,
    default: false,
  }) loading!: boolean;

  @Prop({
    type: String,
    default: null,
  }) message!: string;
}
