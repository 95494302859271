





























import { Vue, Component, Prop } from 'vue-property-decorator';
import LabelSlot from '@/shared/slots/label-slot.component.vue';
import Indicators from '@/domain/drd/entity/indicators.entity';
import IndicatorsUtils from '@/views/drd/commons/indicators-utils';
import Rules from '@/shared/rules/rules';

@Component({
  components: {
    LabelSlot,
  },
})
export default class AssessmentIndicator extends Vue {
  @Prop({
    type: Number,
  }) index!: number

  @Prop({
    type: String,
  }) id!: string;

  @Prop({
    required: true,
  }) indicator!: Indicators

  value: number | null = null;

  indicatorUtils: IndicatorsUtils = new IndicatorsUtils();

  rules: Rules = new Rules();

  change() {
    this.$emit('change', this.id, this.value);
  }
}
