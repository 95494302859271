export default class ResultCenter {
  id: string;

  name: string;

  constructor(obj: any = {}) {
    this.id = obj.id || '';
    this.name = obj.name || '';
  }
}
