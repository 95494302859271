
























import { Vue, Component, Prop } from 'vue-property-decorator';
import LabelSlot from '@/shared/slots/label-slot.component.vue';
import Rules from '@/shared/rules/rules';
import SkillService from '@/domain/drd-2.0/service/skill.service';
import SkillGroup from '@/domain/drd-2.0/entity/skill-group.entity';

@Component({
  components: {
    LabelSlot,
  },
})
export default class SelectSkillGroup extends Vue {
  @Prop({
    required: true,
  }) group!: string | string[];

  @Prop({
    type: Boolean,
    default: false,
  }) multiple!: boolean;

  @Prop() rules!: Rules;

  loading: boolean = false;

  groups: SkillGroup[] = [];

  created() {
    this.findAllGroups();
  }

  update(groupSelected: string) {
    this.$emit('update', groupSelected);
  }

  findAllGroups() {
    this.loading = true;
    SkillService.findAllGroups()
      .then(response => this.groups = response.data)
      .finally(() => this.loading = false);
  }
}
