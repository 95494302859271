import SeniorityRemunerationRequest from './seniority-remuneration-request.entity';

export default class PersistSeniorityRemuneration {
  createHistory: boolean;

  seniorityRemunerations: SeniorityRemunerationRequest[];

  constructor(obj: any = {}) {
    this.createHistory = obj.createHistory;
    this.seniorityRemunerations = obj.seniorityRemunerations || [];
  }
}
