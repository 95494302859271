
























import { Vue, Component } from 'vue-property-decorator';
import EmployeeService from '@/domain/schedule/employee/employee.service';
import OrganizationalChartService from '@/domain/employees/service/organizational-chart.service';
import OrganizationalChartFilter from './organizational-chart-filter.component.vue';
import OrganizationalChartHeader from './organizational-chart-header.component.vue';
import OrganizationalChartComponent from './organizational-chart.component.vue';
import EmployeeSummary from '../../../domain/schedule/employee/employee-summary.entity';
import { EmployeeOrganization } from '../../../domain/employees/entity/employee-chart.entity';

@Component({
  name: 'OrganizationalChartView',
  components: {
    OrganizationalChartHeader,
    OrganizationalChartFilter,
    OrganizationalChartComponent,
  },
})
export default class OrganizationalChartView extends Vue {
  employees: EmployeeSummary[] = [];

  employeeOrganization!: EmployeeOrganization;

  loading: boolean = false;

  created() {
    this.findChartData();
  }

  findEmployees(employeeName: string) {
    EmployeeService.findEmployeesByName(employeeName)
      .then((response: any) => {
        this.employees = response;
      })
  }

  findChartData(employeeId: string = '') {
    this.loading = true;
    OrganizationalChartService.getOrganizationData(employeeId).then((data) => {
      this.employeeOrganization = data.content;
      this.loading = false;
    });
  }

  clearFilter() {
    this.employees = [];
    this.findChartData('');
  }
}
