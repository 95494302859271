








































































import { Vue, Component, Prop } from 'vue-property-decorator';
import { VbSnackbarService } from 'bag-of-holding-library';
import Indicators from '@/domain/drd/entity/indicators.entity';
import IndicatorCategories from '@/views/drd/form/tabs/indicators/indicator-category.json';
import IndicatorWeights from '@/views/drd/form/tabs/indicators/weight-categories.json';
import IndicatorUtilities from '@/views/drd/form/tabs/indicators/utility-types.json';
import IndicatorLink from '@/views/drd/form/tabs/indicators/indicator-link.component.vue';
import DrdPanel from '../common-components/drd-panel.component.vue';
import IndicatorsUtils from '../../commons/indicators-utils';

@Component({
  components: { DrdPanel, IndicatorLink },
})
export default class IndicatorComponent extends Vue {
  @Prop({
    type: Indicators,
    required: true,
  })
  indicator!: Indicators;

  indicatorsUtils: IndicatorsUtils = new IndicatorsUtils();

  formatCategory(category: string): string {
    const correspondingCategory: any = IndicatorCategories.find(
      indicatorCategory => indicatorCategory.value === category,
    );
    if (!correspondingCategory) return '';
    return correspondingCategory.name;
  }

  formatWeight(weight: string): string {
    const correspondingWeight: any = IndicatorWeights.find(
      weightCategory => weightCategory.value === weight,
    );
    if (!correspondingWeight) return '-';
    return correspondingWeight.text;
  }

  formatUtility(utility: string): string {
    const correspondingUtility: any = IndicatorUtilities.find(
      it => it.value === utility,
    );
    if (!correspondingUtility) return '-';
    return correspondingUtility.text;
  }

  checkNullOrUndefined(value: any): string {
    if (value === null || value === undefined) return '-'
    return value;
  }
}
