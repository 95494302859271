import DateFormat from '../../../shared/utils/date-format';

export default class ScheduleFilter {
  employeeId: any = '';

  managerId = '';

  periodStart = '';

  activity = '';

  active = true;

  audit = false;

  indirectSubordinates: boolean = false;

  constructor(obj: any) {
    this.employeeId = obj.employeeId;
    this.managerId = obj.managerId;
    this.periodStart = obj.periodStart;
    this.activity = obj.activity;
  }


  set date(value: string) {
    this.periodStart = DateFormat.monthYearFromFormated(value);
  }

  get date(): string {
    return DateFormat.monthYearToFormated(this.periodStart);
  }

  equals(obj: ScheduleFilter) {
    return this.employeeId === obj.employeeId
           && this.managerId === obj.managerId
           && this.periodStart === obj.periodStart
           && this.activity === obj.activity
           && this.active === obj.active
           && this.audit === obj.audit
           && this.indirectSubordinates === obj.indirectSubordinates;
  }

  public static of(value: any): ScheduleFilter {
    return new ScheduleFilter(value || {});
  }
}
