












































































































































import {
  Vue,
  Component,
  Prop,
} from 'vue-property-decorator';
import Rules from '@/shared/rules/rules';
import { VbSnackbarService } from 'bag-of-holding-library';
import LabelSlot from '@/shared/slots/label-slot.component.vue';
import DrdService from '@/domain/drd/service/drd.service';
import RoleDrd from '@/domain/drd/entity/roleDrd.entity';
import Assignment from '@/domain/drd/entity/assignment.entity';
import SeniorityList from '@/views/drd/form/seniority-list.component.vue'
import TextAreaComponent from '@/shared/fields/text-area.component.vue';
import roleTypeJson from './role-type.json';

@Component({ components: { LabelSlot, SeniorityList, TextAreaComponent } })
export default class RoleDialog extends Vue {
  @Prop({
    type: Boolean,
    default: false,
  })
  private value!: boolean;

  @Prop({
    type: String,
    default: null,
  })
  private roleId!: string;

  @Prop({
    type: Boolean,
  }) private hasUpdateRoles!: boolean;

  @Prop({
    type: Boolean,
  }) private hasInactiveRoles!: boolean;

  private role: RoleDrd = new RoleDrd();

  private rules: Rules = new Rules();

  private loadingSave: boolean = false;

  private loadingGet: boolean = false;

  private assignments: Assignment[] = [];

  private selectedAssignments: Assignment[] = [];

  private roleType = roleTypeJson;

  private closeDialog(): void {
    this.$emit('input', false);
  }

  private validateAndSaveRole(): void {
    const { form }: any = this.$refs;
    if (form.validate()) {
      this.role.assignmentIds = this.selectedAssignments
        ? this.selectedAssignments.map(el => el.id)
        : [];
      if (this.roleId) {
        this.updateRole();
        return;
      }
      this.createRole();
    }
  }

  private createRole(): void {
    this.loadingSave = true;
    DrdService.createRole(new RoleDrd(this.role))
      .then(this.closeDialogAndRefresh)
      .catch(VbSnackbarService.handleHttpError)
      .finally(() => {
        this.loadingSave = false;
      });
  }

  private updateRole(): void {
    this.loadingSave = true;
    DrdService.updateRole(new RoleDrd(this.role))
      .then(this.closeDialogAndRefresh)
      .catch(VbSnackbarService.handleHttpError)
      .finally(() => {
        this.loadingSave = false;
      });
  }

  private closeDialogAndRefresh(): void {
    this.closeDialog();
    this.$emit('refresh');
  }

  private created() {
    this.role = new RoleDrd();
    this.role.active = true;
    if (this.roleId) {
      this.loadingGet = true;
      DrdService.getRoleById(this.roleId).then((role: RoleDrd) => {
        this.role = role;
      }).finally(() => {
        this.getAllAssignments();
      });
      return;
    }
    this.getAllAssignments();
  }

  private getAllAssignments() {
    this.loadingGet = true;
    DrdService.getActiveAssignments().then((assignments: Assignment[]) => {
      this.assignments = assignments;
      const found = assignments
        .filter(assignment => this.role.assignmentIds.includes(assignment.id));
      this.selectedAssignments = found.length === 0 ? this.selectedAssignments : found;
    }).finally(() => {
      this.loadingGet = false;
    });
  }

  private get checkInactiveRoles() {
    return this.hasUpdateRoles || this.hasInactiveRoles;
  }
}

