




























































































import { Component, Prop, Vue } from 'vue-property-decorator';
import CardTitle from '@/shared/card/card-title.component.vue';
import {
  hasEmployeeAdministratorAuthority,
  hasManagerAuthority,
  hasAdministratorAuthority,
  hasFacilitiesOrEmployeeAdministratorAuthority,
} from '@/shared/directive/has-authority.directive';
import { VbTooltipSlot } from 'bag-of-holding-library';
import AttendanceFilter from '@/domain/attendance/entity/filter.entity'
import Attendance from '@/domain/attendance/entity/attendance.entity';
import AttendanceModal from './modal/new-attendance-modal.component.vue';

@Component({
  components: { CardTitle, VbTooltipSlot, AttendanceModal },
  directives: {
    hasEmployeeAdministratorAuthority,
    hasManagerAuthority,
    hasAdministratorAuthority,
    hasFacilitiesOrEmployeeAdministratorAuthority,
  },
})
export default class ScheduleHeader extends Vue {
  @Prop() filter!: AttendanceFilter;

  @Prop() attendance!: Attendance;

  @Prop() createAttendance!: boolean;

  renderModal: boolean = true;

  showNewAttendanceModal: boolean = false;

  modalKey: number = 0;

  close() {
    this.showNewAttendanceModal = false;
    // eslint-disable-next-line no-unused-expressions
    this.modalKey += 1;
    this.$emit('refresh');
  }

  applyFilter() {
    this.$emit('filter', this.filter);
  }

  openModal() {
    this.showNewAttendanceModal = true;
    this.renderModal = false;
    this.$nextTick(() => this.renderModal = true)
  }

  private select(): void {
    this.$router.push({
      path: '/risk-attendance/',
    })
  }
}
