import Attendance from './attendance.entity';

export default class Response {
  totalElements: number;

  totalPages: number;

  content: Attendance[];

  constructor(obj: any) {
    this.totalElements = obj.totalElements || '';
    this.totalPages = obj.totalPages || '';
    this.content = obj.content || '';
  }

  public static of(value: any): Response {
    return new Response(value || {});
  }
}
