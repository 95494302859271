export default class Godfather {
  id: string;

  status: boolean = false;

  trainingDate: string;

  needTraining: boolean = false;

  private lastTrainingDate: string;

  private lastStatus: boolean;

  constructor(obj: any) {
    this.id = obj.id || '';
    this.status = obj.status;
    this.trainingDate = obj.trainingDate || '';
    this.needTraining = obj.needTraining
    this.lastTrainingDate = this.trainingDate;
    this.lastStatus = this.status;
  }

  public static of(obj: any = {}): Godfather {
    return new Godfather(obj);
  }

  get needTrainingText(): string {
    return this.status
      ? 'Necessita reciclagem'
      : 'Necessita capacitação'
  }

  get verifyChangeTrainingDate(): boolean {
    return this.lastTrainingDate !== this.trainingDate;
  }

  updateLastTrainingDate() {
    this.lastTrainingDate = this.trainingDate
  }

  revertTrainingDateAndStatus() {
    this.trainingDate = this.lastTrainingDate
    this.status = this.lastStatus
  }
}
