

















import {
  Vue,
  Component,
  Prop,
} from 'vue-property-decorator';
import CopyComponent from '@/shared/copy.component.vue';

@Component({
  components: { CopyComponent },
})
export default class IndicatorLinkComponent extends Vue {
  @Prop({ type: String, default: '' })
  link!: string;
}
