






















import {
  Vue, Component, Prop,
} from 'vue-property-decorator';
import { VbTooltipSlot } from 'bag-of-holding-library';
import ModalComponent from '@/shared/modal/modal.component.vue';
import ModalOptions from '@/shared/modal/modal-options.entity';
import LabelSlot from '@/shared/slots/label-slot.component.vue';
import DatePickerField from '@/shared/date-picker/date-picker-field.component.vue';
import Group from '@/domain/graph/group.entity';
import MemberGroup from '@/domain/graph/member-group.entity';
import GraphService from '@/domain/graph/graph.service';

  @Component({
    components: {
      ModalComponent, LabelSlot, VbTooltipSlot, DatePickerField,
    },
  })

export default class MembersModal extends Vue {
  @Prop() show!: boolean;

  @Prop() showMembersModal!: boolean;

  @Prop() groupId!: string;

  group: Group = new Group();

  loading = false;

  private async created() {
    await this.createGroup();
  }

  async createGroup() {
    this.loading = true;
    await this.getMembers();
    this.loading = false;
    await this.getOwners();
    this.removeOwnersOnMembers();
  }

  async getOwners() {
    const response = await GraphService.getGroupOwners(this.groupId);
    response.data.value.forEach(it => this.group.owners.push(it.displayName));
  }

  async getMembers() {
    const response = await GraphService.getMembers(this.groupId);
    response.data.value.forEach((it) => {
      this.group.members.push(new MemberGroup(it.displayName));
    });
  }

  removeOwnersOnMembers() {
    for (let i = 0; i < this.group.owners.length; i += 1) {
      for (let x = 0; x < this.group.members.length; x += 1) {
        if (this.group.members[x].name === this.group.owners[i]) {
          this.group.members[x].isOwner = true;
        }
      }
    }
  }


  get optionsHandled(): ModalOptions {
    return {
      title: 'Membros',
      titleSize: '25px',
      description: '',
      showOk: false,
      showCancel: !this.loading,
      labelCancel: 'Fechar',
      width: 880,
    };
  }

  close() {
    if (!this.loading) {
      this.$emit('close');
    }
  }
}

