export default class Option {
  text: string;

  value: string;

  constructor(obj: any = {}) {
    this.text = obj.text || '';
    this.value = obj.value || '';
  }
}
