






















import { Vue, Prop, Component } from 'vue-property-decorator';

@Component
export default class HomeGraph extends Vue {
  @Prop({
    type: Number,
    required: true,
  })
  private total!: number;

  @Prop({
    required: true,
  })
  private sections!: object[];

  private get graphSections(): object[] {
    if (this.sections.length > 6) {
      return this.cutArray;
    }
    return this.sections;
  }

  private get cutArray(): object[] {
    const newSectionList: object[] = this.sections.slice(0, 6);
    const sum: number = newSectionList
      .map((section: any) => section.value)
      .reduce((a: any, b: any) => a + b);
    const value: number = this.total - sum;
    newSectionList.push({ label: 'Outros', value, color: 'rgba(114, 114, 114, 0.5)' });
    return newSectionList;
  }
}
