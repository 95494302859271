











































































































































































import {
  Vue, Component, Prop,
} from 'vue-property-decorator';
import { VbTooltipSlot } from 'bag-of-holding-library';
import PaginationComponent from '@/shared/pagination.component.vue';
import LabelSlot from '@/shared/slots/label-slot.component.vue';
import MobileHandler from '@/shared/utils/mobile-handler';
import splitContent from '@/shared/directive/split-content';
import CareerService from '@/domain/employees/service/career.service';
import CurrentCareerUpdate from '@/domain/employees/entity/current-career-update.entity';
import DateHandler from '@/shared/utils/date-format';
import FilteredSeniorityRemumeration from '@/domain/seniority-remuneration/entity/filtered-seniority-remuneration.entity';
import CurrencyFormater from '@/shared/utils/currency-formater';
import Header from './header.json';
import EmployeeInfoModal from './modal/employee-info-modal.component.vue';

@Component({
  components: {
    PaginationComponent,
    LabelSlot,
    EmployeeInfoModal,
    VbTooltipSlot,
  },
  filters: {
    splitContent,
    formatDate(value: string) {
      return DateHandler.timestampToLocal(value.split('T')[0]);
    },
    toCurrency: CurrencyFormater.toCurrency,
  },
})
export default class TableComponent extends Vue {
  @Prop() items!: FilteredSeniorityRemumeration[];

  @Prop() loading!: boolean;

  @Prop() page!: any;

  private header = Header;

  private isMobile = false;

  showEmployeeModal = false;

  employeeInfo: CurrentCareerUpdate = CurrentCareerUpdate.of({});

  closeEmployeeModal() {
    this.showEmployeeModal = false
    this.employeeInfo = CurrentCareerUpdate.of({});
  }

  private updateTable(pagination: any): void {
    this.$emit('update', pagination);
  }

  private onResize() {
    this.isMobile = MobileHandler.isMobile();
  }

  openEmployeeModal(id: string, observation: string) {
    this.showEmployeeModal = true;
    this.getEmployeeCareerInfo(id, observation);
  }

  getEmployeeCareerInfo(id: string, observation: string) {
    CareerService.getCurrentCareerUpdate(id)
      .then((resp: any) => {
        this.employeeInfo = CurrentCareerUpdate.of(resp);
        this.employeeInfo.observation = observation;
      });
  }
}
