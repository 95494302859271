import axios from 'axios';
import ActivityReassign from '@/domain/schedule/activitie/activity-reassign.entity';
import ActivityHappiness from '@/domain/happiness-level/entity/activity-happiness.entity';
import Activity from './activity.entity';
import Reason from './reason.entity';
import EmployeeActivityFile from './activity-file.entity';
import ActivityResultRequest from './activity-result.request';
import ActivityUpdateStatus from './activity-update-status.entity';

export default class ActivityService {
  static getAll(): Promise<Activity[]> {
    return axios.get('/api/activities')
      .then(response => Activity.ofList(response.data))
  }

  static reassignActivity(id: string, obj: ActivityReassign): Promise<any> {
    const path: string = `/api/activities/${id}/reschedule`;
    return axios.put(path, obj)
      .then(response => response.data);
  }

  static updateStatus(id: string, obj: ActivityUpdateStatus): Promise<any> {
    return axios.put(`/api/activities/${id}/update-status`, obj)
      .then(response => response.data);
  }

  static approveActivity(id: string, reason: Reason): Promise<void> {
    return axios.put(`/api/activities/${id}/approve`, reason)
      .then((response: any) => response.data);
  }

  static approveAndSetExecutionDate(id: string, reason: Reason): Promise<ActivityHappiness> {
    return axios.put(`/api/activities/${id}/approve-and-set-execution-date`, reason)
      .then((response: any) => ActivityHappiness.of(response.data));
  }

  static rejectActivity(id: string, reason: Reason): Promise<void> {
    return axios.put(`/api/activities/${id}/refuse`, reason)
      .then((response: any) => response.data);
  }

  static saveFile(activityId: string, file: any):Promise<EmployeeActivityFile> {
    const formData = new FormData();
    formData.append('file', file);
    return axios.post(`/api/activities/${activityId}/files`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
      .then((response: any) => EmployeeActivityFile.of(response.data));
  }

  static deleteFile(activityId: string, fileId: string): Promise<void> {
    return axios.delete(`/api/activities/${activityId}/files/${fileId}`)
  }

  static getFile(activityId: string, fileId: string) {
    return axios.get(`/api/activities/${activityId}/files/${fileId}`, { responseType: 'arraybuffer' })
      .then((response: any) => response);
  }

  static saveResult(activityId: string, result: string) {
    return axios.put(`/api/activities/${activityId}/result`, ActivityResultRequest.of(result))
      .then((response: any) => response.data)
  }

  static saveExecution(activityId: string, executionDate: string) {
    return axios.put(`/api/activities/${activityId}/execution/${executionDate}`)
      .then((response: any) => response.data)
  }

  static deleteActivity(activityId: string, reason: Reason) {
    return axios.delete(`/api/activities/${activityId}?reason=${reason.reason}`)
      .then((response: any) => response);
  }

  public static getActivityById(activityId: string) {
    return axios.get(`/api/activities/${activityId}`).then(({ data }) => data);
  }

  public static updateActivityTalentLevel(
    activityId: string,
    talentLevelId: string,
  ): Promise<void> {
    return axios.patch(`/api/activity/${activityId}/talent-level/${talentLevelId}`);
  }
}
