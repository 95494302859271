
































































import PersistAssignmentKeyArea from '@/domain/drd-2.0/entity/persist-assignment-keyarea.entity';
import { createSwapy } from 'swapy';
import { Vue, Component, Prop } from 'vue-property-decorator';

@Component
export default class KeyAreaSwapComponent extends Vue {
  @Prop() keyAreas!: PersistAssignmentKeyArea[];

  mounted() {
    const swapContainer = document.querySelector('.swapContainer');
    const swap = createSwapy(swapContainer);
    swap.onSwap((event) => {
      this.$emit('swap', event.data.array)
    })
  }
}
