import EmployeeAttendanceDTO from '@/domain/attendance/entity/employee-attendance-dto';
import moment from 'moment';
import RequestReasons from '../request-reason';

export default class RiskAttendance {
  id: string;

  employee: EmployeeAttendanceDTO;

  responsible: EmployeeAttendanceDTO;

  source: string;

  requestDate: string;

  status: string;

  attendanceDate: string;

  requestReason: string

  report: string;

  mappedRisk: string;

  actionPlan: string;

  actionPlanStatus: string;

  observation: string;

  limitDate: string;

  mainResignationReason: string;

  secondResignationReason: string;

  riskAttendanceType: string;

  private readonly reasons = RequestReasons;

  get reason(): string {
    return this.reasons
      .filter(el => el.value === this.requestReason)
      .map(el => el.description)
      .toString();
  }

  get sourceType(): string {
    if (this.source === 'ADAPTS') return 'Adapta';
    if (this.source === 'FOCUS') return 'Enfoque';
    if (this.source === 'NEW_ATTENDANCE') return 'Acompanhamento';
    return 'Adapta Mudança';
  }

  get statusRisk(): string {
    return this.status === 'RESOLVED' ? 'Concluído' : 'Aberto';
  }

  constructor(riskAttendance: any) {
    this.id = riskAttendance.id || '';
    this.employee = this.createEmployee(riskAttendance.employee);
    this.responsible = this.createEmployee(riskAttendance.responsible);
    this.report = riskAttendance.report || null;
    this.attendanceDate = riskAttendance.attendanceDate || this.dateAttendanceSuggestion()
    this.status = riskAttendance.status || 'NOT_RESOLVED';
    this.requestReason = riskAttendance.requestReason || null;
    this.actionPlan = riskAttendance.actionPlan || null;
    this.actionPlanStatus = riskAttendance.actionPlanStatus || null;
    this.mappedRisk = riskAttendance.mappedRisk || null;
    this.observation = riskAttendance.observation || null;
    this.source = riskAttendance.source || 'NEW_ATTENDANCE';
    this.requestDate = riskAttendance.requestDate || this.dateAttendanceSuggestion();
    this.limitDate = riskAttendance.limitDate || moment().add(30, 'days').format('YYYY-MM-DD');
    this.mainResignationReason = riskAttendance.mainResignationReason || 'NOT_APPLICABLE'
    this.secondResignationReason = riskAttendance.secondResignationReason || 'NOT_APPLICABLE'
    this.riskAttendanceType = riskAttendance.riskAttendanceType || '';
  }

  public static of(riskAttendance: any): RiskAttendance {
    return new RiskAttendance(riskAttendance);
  }

  createEmployee(employee): EmployeeAttendanceDTO {
    if (employee === null) return EmployeeAttendanceDTO.of({});
    return EmployeeAttendanceDTO.of(employee);
  }

  dateAttendanceSuggestion() {
    return moment().format('YYYY-MM-DD')
  }

  equals(attendance: RiskAttendance): boolean {
    return (
      this.id === attendance.id
        && this.employee === attendance.employee
        && this.responsible === attendance.responsible
        && this.source === attendance.source
        && this.requestDate === attendance.requestDate
        && this.status === attendance.status
        && this.attendanceDate === attendance.attendanceDate
        && this.requestReason === attendance.requestReason
        && this.report === attendance.report
        && this.mappedRisk === attendance.mappedRisk
        && this.actionPlan === attendance.actionPlan
        && this.actionPlanStatus === attendance.actionPlanStatus
        && this.observation === attendance.observation
        && this.limitDate === attendance.limitDate
        && this.mainResignationReason === attendance.mainResignationReason
        && this.secondResignationReason === attendance.secondResignationReason
        && this.riskAttendanceType === attendance.riskAttendanceType
    );
  }
}
