import axios from 'axios';
import CorreiosAddress from '../entity/correios-address.entity';

export default class CorreiosService {
  public static getAddress(cep: String): Promise<CorreiosAddress> {
    const newAxios = axios.create();

    return newAxios.get(`https://viacep.com.br/ws/${cep}/json`)
      .then((res: any) => CorreiosAddress.of(res.data));
  }
}
