






































import Priority from '@/domain/activity/entity/priority.entity';
import { Vue, Component, Prop } from 'vue-property-decorator';

@Component
export default class PlanPriorityLegendComponent extends Vue {
  @Prop() priorityItems!: Priority[];

  @Prop({
    type: String,
    default: '12',
  }) cols!: string;

  @Prop({
    default: '4',
  }) sm!: string;

  @Prop({
    type: String,
    default: '2',
  }) md!: string;

  @Prop({
    type: String,
  }) lg!: string;
}
