import { HappinessLevel } from './happiness-level.enum'

export default class HappinessLevelRequest {
  level: HappinessLevel;

  observation?: string;

  type: string = '';

  isAnonymous: boolean = false;

  activityId?: string;

  talkAbout: boolean = false;

  reasonType: string | null = null;

  constructor(obj: any) {
    this.level = obj.level || '';
    this.observation = obj.observation || '';
    this.reasonType = obj.reasonType || null;
  }

  public static of(obj: any = {}) {
    return new HappinessLevelRequest(obj)
  }

  applyType(type: string) {
    this.type = type;
  }

  applyActivityId(activityId: string) {
    this.activityId = activityId;
  }

  hasLevelSelected() {
    return this.level.toString() === '';
  }
}
