import DateFormat from '@/shared/utils/date-format';

export default class FilterScore {
  employees: string[] = Array.of();

  managers: string[] = Array.of();

  countDate: string = ' ';

  status: any;

  validationDate?: string;

  competence: string;

  concluded: boolean = true;

  realized: boolean = true;

  pending: boolean = true;

  retroactive: boolean = true;

  constructor(obj: any) {
    this.employees = obj.employees || [];
    this.managers = obj.managers || [];
    this.countDate = obj.countDate || '';
    this.status = obj.status || [];
    this.validationDate = obj.validationDate || '';
    this.competence = obj.competence || '';
  }

  public static of(value: any): FilterScore {
    return new FilterScore(value || {});
  }

  public static ofList(response: any): FilterScore[] {
    return response.data.map(this.of);
  }

  public equals(obj: FilterScore) {
    return this.managers === obj.managers
      && this.countDate === obj.countDate
      && this.employees === obj.employees;
  }

  public prepareToSend(): void {
    this.status = [];
    if (this.concluded) {
      this.status.push('VALIDATION_COMPLETED');
    }
    if (this.realized) {
      this.status.push('PERFORMED');
    }
    if (this.pending) {
      this.status.push('NOT_PERFORMED');
    }
    if (this.retroactive) {
      this.status.push('PERFORMED_RETROACTIVE');
    }
    this.status = this.status.join(',');
  }

  get competenceRequestFormat() {
    return this.competence ? DateFormat.monthYearFromFormated(this.competence) : '';
  }
}
