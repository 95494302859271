var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"table"},[_c('v-data-table',{directives:[{name:"resize",rawName:"v-resize",value:(_vm.onResize),expression:"onResize"}],attrs:{"headers":_vm.header,"items":_vm.items,"loading":_vm.loading,"page":_vm.page.page,"sort-by":_vm.page.sortBy,"sort-desc":_vm.page.sortDesc,"items-per-page":_vm.page.itemsPerPage,"hide-default-header":_vm.isMobile,"page-count":_vm.page.pageCount,"hide-default-footer":""},on:{"update:sortBy":function($event){return _vm.$set(_vm.page, "sortBy", $event)},"update:sort-by":[function($event){return _vm.$set(_vm.page, "sortBy", $event)},_vm.updateTable],"update:sortDesc":function($event){return _vm.$set(_vm.page, "sortDesc", $event)},"update:sort-desc":[function($event){return _vm.$set(_vm.page, "sortDesc", $event)},_vm.updateTable],"update:itemsPerPage":function($event){return _vm.$set(_vm.page, "itemsPerPage", $event)},"update:items-per-page":function($event){return _vm.$set(_vm.page, "itemsPerPage", $event)},"update:pageCount":function($event){return _vm.$set(_vm.page, "pageCount", $event)},"update:page-count":function($event){return _vm.$set(_vm.page, "pageCount", $event)},"update":_vm.updateTable,"click:row":_vm.select},scopedSlots:_vm._u([{key:"item.minWage",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm._f("toCurrency")(item.minWage)))])]}},{key:"item.mediumWage",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm._f("toCurrency")(item.mediumWage)))])]}},{key:"item.maxWage",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm._f("toCurrency")(item.maxWage)))])]}},{key:"item.benefit",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm._f("toCurrency")(item.benefit)))])]}}],null,true)}),_c('pagination-component',{staticClass:"mt-8 ml-2",attrs:{"pagination":_vm.page},on:{"input":_vm.updateTable}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }