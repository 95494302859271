import axios from 'axios';
import EmployeeTalentLevel from '../entity/employee-talent-level.entity';

export default class EmployeeTalentLevelService {
  public static async getTalentLevelByEmployeeId(employeeId: string) {
    return axios.get(`/api/employee/${employeeId}/talent-level`)
      .then(({ data }) => new EmployeeTalentLevel(data));
  }

  public static async update(employeeId: string, activityId: string | null, talentLevelId: string) {
    return axios.patch(`/api/employee/${employeeId}/talent-level`, { talentLevelId, activityId });
  }

  public static async getHistory(employeeId: string, page: number, size: number) {
    return axios.get(`/api/employee/${employeeId}/talent-level/history`, {
      params: {
        page,
        size,
      },
    })
  }
}
