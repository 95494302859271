export default class FormFeedbackPoint {
  positive: boolean;

  description: string;

  constructor(obj: any = {}) {
    this.positive = Boolean(obj.positive);
    this.description = obj.description || '';
  }
}
