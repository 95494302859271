import EmployeeSchedule from './employee-schedule.entity';

export default class EmployeeSchedulePage {
  totalElements = 0;

  totalPages = 0;

  content: EmployeeSchedule[] = [];

  constructor(totalElements: number, totalPages: number, content: EmployeeSchedule[]) {
    this.totalElements = totalElements;
    this.totalPages = totalPages;
    this.content = content;
  }

  public static of(data: any) {
    const content = data && data.content ? EmployeeSchedule.ofList(data.content) : [];
    return new EmployeeSchedulePage(data.totalElements, data.totalPages, content);
  }
}
