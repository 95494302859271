import RouteCheckerTo from '@/views/route-checker';
import AssessmentView from './assessment.view.vue';
import EmployeeAssessmentResultView from './employee-result/assessment-result.view.vue';
import AssessmentResultsView from './results/assessment-results.view.vue';


export default [
  {
    path: '/assessment/results',
    component: AssessmentResultsView,
    name: 'Resultados Gerais Assessment',
    beforeEnter: RouteCheckerTo.hasAccessReadAssessmentResult,
  },
  {
    path: '/assessment/:employeeId/result',
    component: EmployeeAssessmentResultView,
    name: 'Resultado Assessment',
  },
  {
    path: '/assessment/:employeeId',
    component: AssessmentView,
    name: 'Assessment',
  },
]
