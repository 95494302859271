

































































































































































import { Vue, Component, Prop } from 'vue-property-decorator';
import ActionPlan from '@/domain/activity/entity/action-plan.entity';
import LabelSlot from '@/shared/slots/label-slot.component.vue';
import Rules from '@/shared/rules/rules';
import DatePickerField from '@/shared/date-picker/date-picker-field.component.vue';
import StateItems from '@/views/activity/common/stateItems.json';
import PlanType from '@/domain/activity/entity/plan-types.entity';
import Priority from '@/domain/activity/entity/priority.entity';

@Component({ components: { LabelSlot, DatePickerField } })
export default class ActionPlanComponent extends Vue {
  @Prop({ type: ActionPlan, required: true })
  private actionPlan!: ActionPlan;

  @Prop({ type: Number, default: 0 })
  private index!: number;

  @Prop({ type: Boolean, default: true })
  private showDeleteBtn!: boolean ;

  @Prop({ type: Boolean, default: true })
  private editable!: boolean ;

  @Prop({ type: Boolean })
  private isApproved!: boolean;

  @Prop() hasEditPermission!: boolean

  @Prop() planTypes!: PlanType[];

  @Prop() priorityItems!: Priority[];

  private stateItems = StateItems;

  private rules: Rules = new Rules();

  private deleteActionPlan(): void {
    this.$emit('delete');
  }

  private updateField(field: string, value: any): void {
    this.$emit(`update:${field}`, value);
  }

  getDueDateRules() {
    return this.actionPlan.priority.position === 0
      || this.actionPlan.priority.position === 1 ? this.rules.empty : [];
  }

  priorityItemText(priority: any) {
    return `${priority.position} - ${priority.description}`
  }

  priorityDisable(priority) {
    return !priority.active;
  }
}
