import DateFormat from '@/shared/utils/date-format';
import moment from 'moment';

export default class WageHistory {
  seniorityName: string;

  maxWage: number;

  mediumWage: number;

  minWage: number;

  benefit: number;

  lastModifiedDate: string;

  constructor(obj: any = {}) {
    this.seniorityName = obj.seniorityName || '';
    this.maxWage = obj.maxWage || 0;
    this.mediumWage = obj.mediumWage || 0;
    this.minWage = obj.minWage || 0;
    this.benefit = obj.benefit || 0;
    this.lastModifiedDate = obj.lastModifiedDate ? moment(obj.lastModifiedDate).format('MM/YYYY') : '';
  }
}
