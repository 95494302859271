


















































































import { Vue, Component } from 'vue-property-decorator';
import { VbSnackbarService } from 'bag-of-holding-library';
import CardTitle from '@/shared/card/card-title.component.vue';
import SurveyService from '@/domain/survey/survey.service';
import Survey from '@/domain/survey/entity/survey.entity';
import SurveyType from '@/domain/survey/entity/survey-type.enum';
import TextAreaComponent from '@/views/survey/components/text-area-component.vue';
import RadioComponent from '@/views/survey/components/radio-component.vue';
import ActivityHappinessAnswer from '@/domain/activity/entity/activity-happiness-answer.entity';
import replaceVariableOnText from '@/shared/utils/replace-variable-on-text';
import ActivityScheduleService from '@/domain/schedule/activitie/activity.service';
import ActivityDTO from '@/domain/schedule/activitie/activity-dto.entity';
import Loading from '@/shared/loading/loading.component.vue';
import SuccessCardComponent from '@/views/survey/success-card.component.vue';
import LottieAnimation from 'lottie-web-vue';
import CheckAnitionJSON from '@/assets/check-animation.json';
import SelectLanguageComponent from '@/views/survey/select-language.component.vue';
import ActivityService from '@/domain/activity/service/activity.service';

@Component({
  components: {
    CardTitle,
    Loading,
    SuccessCardComponent,
    LottieAnimation,
    SelectLanguageComponent,
  },
})
export default class ActivityHappinessView extends Vue {
  survey: Survey = new Survey();

  activityHappinessAnswers: ActivityHappinessAnswer = new ActivityHappinessAnswer();

  activity: ActivityDTO = ActivityDTO.of({});

  loading: boolean = false;

  sended: boolean = false;

  checkAnitionJSON = CheckAnitionJSON

  languages = [
    {
      text: 'Brasil - pt-br',
      acronym: 'PT_BR',
      acronymFlag: 'BRA',
    },
    {
      text: 'Espanhol - es',
      acronym: 'ES',
      acronymFlag: 'ES',
    },
    {
      text: 'English',
      acronym: 'EN',
      acronymFlag: 'US',
    },
  ]

  language: string | null = null;

  errorGetSurvey: boolean = false;

  componentsModel = {
    TEXT_AREA: TextAreaComponent,
    RADIO: RadioComponent,
  }

  loadingSend: boolean = false;

  getActivity() {
    ActivityScheduleService.getActivityById(this.$route.params.activityId)
      .then((data) => {
        this.activity = ActivityDTO.of(data);
        this.survey.description = replaceVariableOnText(
          this.survey.description,
          { activityName: this.activity.name },
        );
      }).finally(() => this.loading = false);
  }

  getSurvey() {
    if (!this.language) return;
    this.loading = true;
    SurveyService.getSurveyByTypeAndAcronymLanguage(SurveyType.QUALITATIVE_HAPPINESS, this.language)
      .then((data) => {
        this.survey = data;
        this.activityHappinessAnswers = ActivityHappinessAnswer.createInitial(
          this.survey.template.questions,
          this.$route.params.activityId, this.survey.id,
        );
        this.getActivity();
        this.errorGetSurvey = false;
      })
      .catch((error) => {
        this.errorGetSurvey = true;
        this.loading = false;
        VbSnackbarService.handleHttpError(error);
      });
  }

  setAnswerValue(position: number, value: string) {
    const answerFiltered = this.activityHappinessAnswers.answers
      .filter(it => it.questionPosition === position)[0];
    answerFiltered.value = value;
  }

  get employeeFirstName() {
    const words = this.activity.employee.name.split(' ');
    return words[0];
  }

  setLanguage(acronymlanguage: string) {
    this.language = acronymlanguage;
    this.getSurvey();
  }

  get sendButtonText() {
    return this.language === 'EN' ? 'Send' : 'Enviar';
  }

  get title() {
    if (this.language === 'EN') return `Hi ${this.employeeFirstName},`;
    if (this.language === 'ES') return `Hola ${this.employeeFirstName},`;
    return `Olá ${this.employeeFirstName},`;
  }

  send() {
    this.loadingSend = true;
    ActivityService.sendActivityHappiness(this.activityHappinessAnswers)
      .then(() => {
        this.sended = true;
      }).catch((error) => {
        VbSnackbarService.handleHttpError(error);
      }).finally(() => {
        this.loadingSend = false;
      })
  }
}
