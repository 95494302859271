import Score from '@/domain/employees/entity/score.entity';
import axios from 'axios';

export default class ScoreScheduleService {
  public static getScoreByActivityId(id: string): Promise<Score> {
    return axios.get(`/api/activities/${id}/score`)
      .then((response: any) => Score.ofRead(response.data));
  }

  public static getActivityScore(id: string): Promise<any> {
    return axios.get(`/api/activities/${id}`)
      .then((response: any) => response.data);
  }
}
