export default class KeyAreaFilter {
  name: string;

  skill: string;

  constructor(obj: any = {}) {
    this.name = obj.name || '';
    this.skill = obj.skill || '';
  }

  get hasName() {
    return this.name.length > 0;
  }

  get hasSkill() {
    return this.skill.length > 0;
  }
}
