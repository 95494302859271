export default class CareerUpdateHistory {
  id: string;

  date: string;

  username: string;

  status: string;

  role: string;

  seniority: string;

  workplace: string;

  operationModality: string;

  businessUnit: string;

  resultCenter: string;

  manager: string;

  techLead: string;

  observation: string;

  assignment: string;

  pastModifiedDate: string;

  constructor(obj: any) {
    this.id = obj.id || '';
    this.date = obj.date || '';
    this.username = obj.username || '';
    this.status = obj.status || '';
    this.seniority = obj.seniority || '';
    this.workplace = obj.workplace || '';
    this.operationModality = obj.operationModality || '';
    this.businessUnit = obj.businessUnit || '';
    this.role = obj.role || '';
    this.manager = obj.manager || '';
    this.techLead = obj.techLead || '';
    this.resultCenter = obj.resultCenter || '';
    this.observation = obj.observation || '';
    this.assignment = obj.assignment || '';
    this.pastModifiedDate = obj.pastModifiedDate || '';
  }

  public static of(obj: any): CareerUpdateHistory {
    return new CareerUpdateHistory(obj || {});
  }

  public static ofList(obj: any[]): CareerUpdateHistory[] {
    return obj.map(this.of);
  }
}
