






































import { Vue, Component } from 'vue-property-decorator';
import CardTitle from '@/shared/card/card-title.component.vue';
import WageService from '@/domain/wages/services/wages.service';
import Wages from '@/domain/wages/entity/wages.entity';
import Authority from '@/domain/security/authority.entity';
import AuthorityStore from '@/domain/security/authority.store';
import AuthorityEnum from '@/domain/security/authority.enum';
import TableComponent from './wages-table.component.vue';
import FilterComponent from './filter/filter.component.vue';
import Filter from '../../../domain/wages/entity/filter.entity';
import HeaderComponent from './wages-table-header.component.vue';
import UpdateAtribute from '../../../domain/employees/entity/update-atribute.entity';
import CareerService from '../../../domain/employees/service/career.service';
import EditRemunerationModal from './modal/edit-remuneration-modal.component.vue';

@Component({
  components: {
    FilterComponent,
    CardTitle,
    TableComponent,
    HeaderComponent,
    EditRemunerationModal,
  },
})
export default class WagesTableView extends Vue {
  wages: Wages[] = [];

  roles: UpdateAtribute[] = [];

  tableLoading: boolean = false;

  showEditModal: boolean = false;

  isUpdateTableSalary: boolean = false;

  pagination: any = {
    itemsPerPage: 10,
    page: 1,
    total: 0,
    pageCount: 0,
  }

  filter: Filter = Filter.of({});

  authority: Authority = new Authority([]);

  seniority: any[] = [];

  selectedRoleName: string = '';

  hasReadHistory: boolean = false;

  async created() {
    await this.findPermissions();
    this.findSeniorities();
    this.findRoles();
    this.findWages();
  }

  findWages() {
    this.tableLoading = true;
    WageService.findWages(this.pagination.page, this.pagination.itemsPerPage,
      this.filter.role, this.filter.seniority)
      .then((response: any) => {
        this.wages = Wages.ofList(response.content);
        this.pagination.total = response.totalElements || 0;
        this.pagination.pageCount = response.totalPages;
      })
      .finally(() => this.tableLoading = false);
  }

  applyWagesFilter(wagesFilter: Filter) {
    this.filter = Filter.of(wagesFilter);
    this.findWages();
  }

  setFilterValues(value: any) {
    if (this.pagination.itemsPerPage < value.itemsPerPage) {
      value.page = 1;
    }
    this.pagination = value;
    this.findWages();
  }

  async findPermissions() {
    await this.getAuthority();
    if (!this.authority.isReadTableSalary()) {
      this.$router.push('/');
      return;
    }
    this.isUpdateTableSalary = this.authority.isUpdateTableSalary();
    this.hasReadHistory = this.authority
      .hasAuthorities([AuthorityEnum.READ_SENIORITY_REMUNERATION_HISTORY])
  }

  async getAuthority() {
    await AuthorityStore.fetchedAuthority().then(authority => this.authority = authority);
  }

  openEditModal(roleName: string = '') {
    this.selectedRoleName = roleName;
    this.showEditModal = true;
  }

  findRoles() {
    CareerService.getRoles().then((resp: any) => this.roles = resp.data);
  }

  findSeniorities() {
    CareerService.getSeniority().then((resp: any) => this.seniority = resp.data);
  }

  closeEditModal(refresh: boolean) {
    this.showEditModal = false;
    if (!refresh) return;
    this.findWages();
  }
}
