





















































































import { Vue, Component } from 'vue-property-decorator';
import ExpansionPanel from '@/shared/expansion-panel/expansion-panel.component.vue';
import Loading from '@/shared/loading/loading.component.vue';
import VueApexCharts from 'vue-apexcharts';
import CandidateResult from '@/domain/selective-process/entity/candidate/candidate-result.entity';
import SelectiveProcessService from '@/domain/selective-process/service/selective-process.service';
import ErrorComponent from '@/shared/error/error.component.vue';
import CardTitle from '@/shared/card/card-title.component.vue';
import PersonalityTableHeaders from './personality-table-header.json';
import ReasoningHeaders from './reasoning-table-header.json';

@Component({
  components: {
    ExpansionPanel,
    VueApexCharts,
    ErrorComponent,
    Loading,
    CardTitle,
  },
})
export default class CandidateResultDetails extends Vue {
  candidateId: string = this.$route.params.candidateId;

  personalityHeaders = PersonalityTableHeaders;

  reasoningHeaders = ReasoningHeaders;

  candidateResult: CandidateResult = new CandidateResult();

  personalityResultChartSeries: any[] = [{
    data: [],
  }]

  reasoningResultChartSeries: any[] = [{
    data: [],
  }]

  personalityFetchErrorMessage: string = '';

  personalityResultChartOptions = {
    chart: {
      type: 'bar',
    },
    plotOptions: {
      bar: {
        borderRadius: 4,
        borderRadiusApplication: 'end',
        horizontal: true,
      },
    },
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      categories: [''],
      min: 0,
      max: 100,
      labels: {
        style: {
          fontSize: '14px',
          fontFamily: 'Roboto',
          fontWeight: 400,
        },
      },
      title: {
        text: 'Percentil',
        style: {
          fontSize: '16px',
          fontFamily: 'Roboto, sans-serif',
          fontWeight: 500,
        },
      },
    },
    yaxis: {
      labels: {
        style: {
          fontSize: '14px',
          fontFamily: 'Roboto, sans-serif',
          fontWeight: 400,
        },
      },
    },
    tooltip: {
      followCursor: true,
      y: {
        formatter: value => `${value}%`,
        title: {
          formatter: () => '',
        },
      },
    },
  }

  reasoningResultChartOptions = {
    chart: {
      type: 'bar',
    },
    plotOptions: {
      bar: {
        borderRadius: 4,
        borderRadiusApplication: 'end',
        horizontal: true,
      },
    },
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      categories: [''],
      min: 0,
      max: 100,
      labels: {
        style: {
          fontSize: '14px',
          fontFamily: 'Roboto',
          fontWeight: 400,
        },
      },
      title: {
        text: 'Percentil',
        style: {
          fontSize: '16px',
          fontFamily: 'Roboto, sans-serif',
          fontWeight: 500,
        },
      },
    },
    yaxis: {
      labels: {
        style: {
          fontSize: '14px',
          fontFamily: 'Roboto, sans-serif',
          fontWeight: 400,
        },
      },
    },
    tooltip: {
      followCursor: true,
      y: {
        formatter: value => `${value}%`,
        title: {
          formatter: () => '',
        },
      },
    },
  }

  loading: boolean = false;

  async created() {
    this.$destroy()
    this.loading = true;
    await this.getPersonalityResult()
    this.loading = false;
  }

  async getPersonalityResult() {
    await SelectiveProcessService
      .getPersonalityResult(this.candidateId)
      .then(data => this.candidateResult = data)
      .catch((error) => {
        this.personalityFetchErrorMessage = error.response.data.messages.toString()
      })
    this.setChartData()
  }

  setChartData() {
    this.personalityResultChartSeries[0].data = this.candidateResult
      .personalityResults.map(it => it.percent)
    this.personalityResultChartOptions.xaxis.categories = this.candidateResult
      .personalityResults.map(it => it.factorName)

    this.reasoningResultChartSeries[0].data = this.candidateResult
      .reasoningResults.map(it => it.percent)
    this.reasoningResultChartOptions.xaxis.categories = this.candidateResult
      .reasoningResults.map(it => it.name)
  }
}
