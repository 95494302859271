

































import { Vue, Component, Prop } from 'vue-property-decorator';

@Component
export default class DrdPanel extends Vue {
  @Prop({
    type: String,
    default: '',
  }) title!: string;

  @Prop({
    type: Number,
    default: 0,
  }) value!: number;
}

