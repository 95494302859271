export default class EmployeeAssessmentResultBySkillType {
  type: string;

  typeColor: string;

  description: string;

  total: number;

  constructor(obj: any = {}) {
    this.type = obj.type || '';
    this.typeColor = obj.typeColor || '';
    this.description = obj.description || '';
    this.total = obj.total === null ? 100.00 : obj.total;
  }
}
