export default class SkillTypeOption {
  id: string;

  name: string;

  details: string;

  value: number;

  constructor(obj: any = {}) {
    this.id = obj.id || '';
    this.name = obj.name || '';
    this.details = obj.details || '';
    this.value = obj.value;
  }
}
