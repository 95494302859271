



































import { Vue, Component, Prop } from 'vue-property-decorator';
import MenuSidebar from './menu-sidebar.component.vue';

@Component({ components: { MenuSidebar } })
export default class MenuTopbar extends Vue {
  @Prop({
    type: Array,
    default: () => ([]),
  })
  private authorities!: string[];

  private showMenu: boolean = false;

  private goToHomepage(): void {
    this.$router.push('/');
  }

  private openMenu(): void {
    this.showMenu = true;
  }
}
