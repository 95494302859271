






import { Vue, Component } from 'vue-property-decorator';
import ReadDrdView from './read-drd.view.vue';

@Component({
  components: {
    ReadDrdView,
  },
})
export default class PrintDrdView extends Vue {
  print() {
    window.print();
  }
}
