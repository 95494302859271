
















import { Component, Vue } from 'vue-property-decorator';
import CardTitle from '@/shared/card/card-title.component.vue';
import MobileHandler from '@/shared/utils/mobile-handler';
import EmployeeModalColumns from './modal/employee-modal-columns.component.vue';

@Component({
  components: {
    CardTitle,
    EmployeeModalColumns,
  },
})
export default class ScheduleHeader extends Vue {
  showColumnsModal: boolean = false;

  isMobile = false;

  onResize() {
    this.isMobile = MobileHandler.isLessThan(768);
  }

  setVisibleColumns(columns: any[]): void {
    this.$emit('setExtraColumns', columns);
  }
}
