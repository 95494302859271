


























































import { Vue, Component, Prop } from 'vue-property-decorator';
import LabelSlot from '@/shared/slots/label-slot.component.vue';
import AreaKey from '@/domain/drd/entity/area-key/area-key.entity';
import Rules from '@/shared/rules/rules';
import FieldsArea from '@/views/drd/form/tabs/common-components/fields-area.component.vue';
import ActiveCheckboxField from '@/views/drd/form/tabs/common-components/active-checkbox-field.component.vue';
import AddButton from '@/shared/buttons/add-button.component.vue';
import AreaKeyItemComponent from '@/views/drd/form/tabs/area-key/area-key-item.component.vue';

@Component({
  components: {
    ActiveCheckboxField,
    AddButton,
    AreaKeyItemComponent,
    FieldsArea,
    LabelSlot,
  },
})
export default class AreaKeyComponent extends Vue {
  @Prop({
    type: AreaKey,
    required: true,
  })
  private areaKey!: AreaKey;

  private rules: Rules = new Rules();

  private updateField(field: string, value: any): void {
    this.$emit(`update:${field}`, value);
  }

  private addNewAreaKeyItem(): void {
    this.$emit('pushAreaKeyItem');
  }

  private updateAreaKeyItemField(index: number, field: string, value: any): void {
    this.$emit(`updateAreaKeyItemField:${field}`, {
      index,
      value,
    });
  }

  private deleteAreaKey(): void {
    this.$emit('deleteAreaKey');
  }

  private deleteAreaKeyItem(index: number): void {
    this.$emit('deleteAreaKeyItem', index);
  }
}
