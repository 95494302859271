
















import { Vue, Component, Prop } from 'vue-property-decorator';

@Component
export default class ScheduleDataTableActivityStatusComponent extends Vue {
  @Prop() show!: boolean;

  @Prop() color!: string;

  @Prop() text!: string;
}
