import History from './history.entity';

export default class EmployeeHistory {
  private name: string;

  private admissionDate: string;

  private nextActivity: string;

  private history: History[];

  constructor(obj: any = {}) {
    this.name = obj.name || '';
    this.admissionDate = obj.admissionDate || '';
    this.nextActivity = obj.nextActivity || '';
    this.history = obj.history || [];
  }

  public get admissionDateValue(): string {
    return this.admissionDate;
  }

  public get firstNameLastName(): string {
    const name: string[] = this.name.split(' ');
    return `${name[0]} ${name[name.length - 1]}`;
  }

  public static of(obj: any): EmployeeHistory {
    return new EmployeeHistory(obj || {});
  }
}
