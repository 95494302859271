

















import { Vue, Component, Prop } from 'vue-property-decorator';
import ErrorLottieJSON from '@/assets/images/error-lottie.json'
import LottieAnimation from 'lottie-web-vue';


@Component({
  components: {
    LottieAnimation,
  },
})
export default class ErrorComponent extends Vue {
  @Prop({
    type: String,
    default: 'Occoreu um erro inesperado, tenta novamente mais tarde',
  }) message!: string;

  errorJson = ErrorLottieJSON
}
