import RouteCheckerTo from '@/views/route-checker';
import Attendance from './attendance.view.vue';
import Table from './table/attendance-table.view.vue';
import FormAttendance from './form-attendances/form-attendances.view.vue';

export default [
  {
    path: '/attendance',
    component: Attendance,
    children: [
      {
        path: '/',
        name: 'Atendimentos',
        component: Table,
        beforeEnter: RouteCheckerTo.hasAccessAttendance,
      },
      {
        path: '/attendance/:id',
        name: 'Formulário atendimentos',
        component: FormAttendance,
        beforeEnter: RouteCheckerTo.hasAccessAttendance,
      },
    ],
  },
];
