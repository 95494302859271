export default class EmployeeRestartSchedule {
  private id: string;

  private name: string;

  private admissionDate: string;

  private lastActivity: string;

  private approvalDate: string;

  constructor(obj: any = {}) {
    this.id = obj.id || '';
    this.name = obj.name || '';
    this.admissionDate = obj.admissionDate || '';
    this.lastActivity = obj.lastActivity || '';
    this.approvalDate = obj.approvalDate || '';
  }

  public static of(obj: any = {}): EmployeeRestartSchedule {
    return new EmployeeRestartSchedule(obj);
  }

  public get admissionDateValue(): string {
    return this.admissionDate;
  }

  public get approvalDateValue(): string {
    return this.approvalDate;
  }

  public get firstNameLastName(): string {
    const name: string[] = this.name.split(' ');
    return `${name[0]} ${name[name.length - 1]}`;
  }
}
