






















import {
  Prop, Component, Mixins,
} from 'vue-property-decorator';
import Modal from '@/shared/modal/modal.component.vue';
import ModalMixin from '@/shared/modal/modal.mixin';
import ModalOptions from '@/shared/modal/modal-options.entity';
import ManageActivity from './manage-activity.component.vue';

@Component({
  components: {
    'modal-component': Modal,
    'manage-activity': ManageActivity,
  },
})
export default class ModalManageActivity extends Mixins(ModalMixin) {
  @Prop({
    required: true,
  })
  private date!: string;

  @Prop({
    type: Boolean,
    default: false,
  })
  private isUpdateActivity!: boolean;

  @Prop({
    type: Boolean,
    default: false,
  })
  private isUploadFiles!: boolean;

  @Prop({
    type: Boolean,
    default: false,
  })
  private isDeleteActivity!: boolean;

  @Prop({
    type: Boolean,
    default: false,
  })
  private isUpdateActivityStatus!: boolean;

  @Prop({
    type: Boolean,
    default: false,
  })
  private isReadActivity!: boolean;

  @Prop({ type: Boolean, default: false })
  isReadEmployeeTalentLevel!: boolean;

  @Prop({
    required: true,
  })
  private username!: string;

  private get manageActivityModalOptions(): ModalOptions {
    return {
      title: 'Gerenciamento de Atividades',
      showOk: true,
      labelOk: 'Fechar',
      width: 700,
    };
  }
}
