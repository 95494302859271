export default class AdmissionDateAnniversary {
  name: string;

  employeeId: string;

  businessUnit: string;

  admissionDateAnniversary: string;

  admissionYear: string;

  photo: string;

  constructor(obj: any = {}) {
    this.name = obj.name || '';
    this.employeeId = obj.employeeId || '';
    this.businessUnit = obj.businessUnit || '';
    this.admissionDateAnniversary = obj.admissionDateAnniversary || '';
    this.admissionYear = obj.admissionYear || '';
    this.photo = obj.photo || '';
  }

  public static of(obj: any): AdmissionDateAnniversary {
    return new AdmissionDateAnniversary(obj || {});
  }

  public static ofList(obj: any[]): AdmissionDateAnniversary[] {
    return obj.map(this.of);
  }
}
