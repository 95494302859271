













import { Vue, Component, Prop } from 'vue-property-decorator';
import { VbSnackbarService } from 'bag-of-holding-library';
import DrdService from '@/domain/drd/service/drd.service';
import Indicators from '@/domain/drd/entity/indicators.entity';
import Loader from '@/views/drd/commons/loader.component.vue';
import IndicatorComponent from './indicator.component.vue';

@Component({
  components: { Loader, IndicatorComponent },
})
export default class IndicatorsIndex extends Vue {
  @Prop({
    type: String,
    required: true,
  }) performanceDescriptionId!: string;

  indicators: Indicators[] = [];

  loading: boolean = true;

  loadIndicators(): void {
    this.loading = true;
    DrdService.getIndicators(this.performanceDescriptionId).then((indicators) => {
      this.indicators = Indicators.ofList(indicators).filter(it => it.active);
    }).catch(VbSnackbarService.handleHttpError)
      .finally(() => {
        this.loading = false;
      });
  }

  created(): void {
    this.loadIndicators();
  }
}
