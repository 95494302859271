import { render, staticRenderFns } from "./assessment-table.component.vue?vue&type=template&id=ba7e7a9c&scoped=true&"
import script from "./assessment-table.component.vue?vue&type=script&lang=ts&"
export * from "./assessment-table.component.vue?vue&type=script&lang=ts&"
import style0 from "./assessment-table.component.vue?vue&type=style&index=0&id=ba7e7a9c&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ba7e7a9c",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VDataTable } from 'vuetify/lib/components/VDataTable';
installComponents(component, {VDataTable})


/* vuetify-loader */
import installDirectives from "!../../../../node_modules/vuetify-loader/lib/runtime/installDirectives.js"
import Resize from 'vuetify/lib/directives/resize'
installDirectives(component, {Resize})
