




















import { Vue, Component, Prop } from 'vue-property-decorator';
import LabelSlot from '@/shared/slots/label-slot.component.vue';
import Rules from '@/shared/rules/rules';
import OperationSystems from './operational-system.json';

@Component({
  components: {
    LabelSlot,
  },
})
export default class OperationalSystemSelect extends Vue {
  @Prop({
    type: String,
    default: 'Sistema Operacional',
  }) label!: string;

  @Prop({
    type: Boolean,
    default: false,
  }) disabled!: boolean;

  @Prop() rules!: Rules;

  @Prop({
    type: String,
  }) operationalSystem!: string;

  options = OperationSystems;

  update(value: string) {
    this.$emit('update', value)
  }
}
