















































import { Vue, Component, Prop } from 'vue-property-decorator';
import DrdService from '@/domain/drd/service/drd.service';
import PerformanceDescriptionEntity from '@/domain/drd/entity/performance-description.entity';
import EmployeeService from '@/domain/employees/service/employee.service';
import DrdReviewCheckboxComponent from '@/shared/checkbox/drd-review-checkbox.component.vue';
import Loader from '../commons/loader.component.vue';

@Component({
  components: {
    Loader,
    DrdReviewCheckboxComponent,
  },
})
export default class PerformanceDescriptionTableReadHeaderComponent extends Vue {
  @Prop({
    type: String,
    required: true,
  }) performanceDescriptionId!: string;

  performanceDescription: PerformanceDescriptionEntity = new PerformanceDescriptionEntity();

  existsReviewOpen: boolean = false;

  async loadPerformanceDescription() {
    await DrdService.getPerformanceDescription(this.performanceDescriptionId)
      .then((resp: PerformanceDescriptionEntity) => {
        this.performanceDescription = resp;
      });
    this.checkExistsReviewOpen();
  }

  async checkExistsReviewOpen() {
    this.existsReviewOpen = await EmployeeService
      .checkExistsDrdReviewOpen(this.performanceDescription!.assignmentId);
  }

  created(): void {
    this.loadPerformanceDescription();
  }
}
