






















import EmployeeSummary from '@/domain/schedule/employee/employee-summary.entity';
import EmployeeService from '@/domain/schedule/employee/employee.service';
import { Vue, Component, Prop } from 'vue-property-decorator';
import Rules from '../rules/rules';
import LabelSlot from '../slots/label-slot.component.vue';

@Component({
  components: {
    LabelSlot,
  },
})
export default class EmployeeSelect extends Vue {
  @Prop({
    type: String,
    default: 'Colaborador',
  }) label!: string;

  @Prop({
    required: true,
  }) employee!: EmployeeSummary;

  @Prop() rules!: Rules;

  @Prop({
    type: Boolean,
    default: false,
  }) returnObject!: boolean;

  employees: EmployeeSummary[] = [];

  lastFilterEmployee: string = '';

  loading: boolean = false;

  created() {
    if (this.employee.id) {
      this.employees.push(this.employee);
    }
  }

  findEmployees(value: string) {
    if ((this.lastFilterEmployee !== value) && value) {
      this.lastFilterEmployee = value;
      if (value.length > 3) {
        this.loading = true;
        EmployeeService.findEmployeesByName(value)
          .then(response => this.employees = response)
          .finally(() => this.loading = false);
      }
    }
  }

  update(employeeSelected: EmployeeSummary) {
    this.$emit('update', employeeSelected);
  }
}
