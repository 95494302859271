



















































import {
  Vue, Component, Prop,
} from 'vue-property-decorator';
import { VbTooltipSlot } from 'bag-of-holding-library';
import DateHandler from '@/shared/utils/date-format';
import Employee from '@/domain/employees/entity/employee.entity';
import CareerUpdate from '@/domain/employees/entity/career-update.entity';
import UpdateContent from './career-update-list-content.component.vue';

@Component({
  components: { UpdateContent, VbTooltipSlot },
  filters: {
    formatDate(value: string) {
      return DateHandler.timestampToLocal(value.split('T')[0]);
    },
  },
})
export default class CareerUpdateList extends Vue {
  @Prop() update!: CareerUpdate;

  @Prop() selected!: boolean;

  @Prop() position!: number;

  @Prop() employee!: Employee;

  @Prop() unlockUpdateCareerUpdate!: boolean;

  @Prop() unlockDeleteCareerUpdate!: boolean;

  @Prop() unlockDeletePastCareerUpdate!: boolean;

  @Prop({
    type: Boolean,
  }) hasUpdatePastCareerUpdateEvent!: boolean;

  editUpdate() {
    this.$emit('editUpdate', this.update);
  }

  select() {
    this.$emit('select');
    this.update.select();
    this.$forceUpdate();
  }

  deleteUpdate() {
    this.$emit('deleteUpdate', this.update);
  }

  isCurrentCareerUpdate() {
    return this.selected && this.position === 0;
  }

  get unlockDeletion() {
    return (this.isCurrentCareerUpdate() && this.unlockDeleteCareerUpdate)
    || (!this.isCurrentCareerUpdate() && this.unlockDeletePastCareerUpdate);
  }

  get unlockUpdate() {
    if (this.unlockUpdateCareerUpdate && !this.hasUpdatePastCareerUpdateEvent) {
      return this.isCurrentCareerUpdate();
    }
    return this.hasUpdatePastCareerUpdateEvent;
  }

  get hasInconsistency() {
    return this.update.hasInconsistency && this.position === 0 ? 'hasInconsistency' : '';
  }
}
