import Answer from '@/domain/survey/entity/answer.entity';
import Question from '@/domain/survey/entity/question.entity';

export default class ActivityHappinessAnswer {
  activityId: string;

  answers: Answer[];

  surveyId: string;

  constructor(obj: any = {}) {
    this.activityId = obj.activityId || '';
    this.surveyId = obj.surveyId || '';
    this.answers = obj.answers || [];
  }

  public static createInitial(
    questions: Question[],
    activityId: string,
    surveyId: string,
  ): ActivityHappinessAnswer {
    const fitCultural = new ActivityHappinessAnswer();
    fitCultural.activityId = activityId;
    fitCultural.surveyId = surveyId;
    questions.forEach((it) => {
      fitCultural.answers.push(Answer.of(it));
    })
    return fitCultural;
  }
}
