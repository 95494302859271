












































































































import { Vue, Component, Prop } from 'vue-property-decorator';
import LabelSlot from '@/shared/slots/label-slot.component.vue';
import Filter from '@/domain/seniority-remuneration/entity/filter.entity';
import YearMonthPicker from '@/shared/month-year-picker/month-year-select.component.vue';
import { hasAdministratorAuthority, hasManagerAuthority } from '@/shared/directive/has-authority.directive';
import EmployeeSummary from '../../../../domain/schedule/employee/employee-summary.entity';
import UpdateAtribute from '../../../../domain/employees/entity/update-atribute.entity';

@Component({
  components: { LabelSlot, YearMonthPicker },
  directives: { hasAdministratorAuthority, hasManagerAuthority },
})
export default class FilterComponent extends Vue {
  @Prop() filter!: Filter;

  @Prop() managers!: EmployeeSummary;

  @Prop() employees!: EmployeeSummary;

  @Prop() roles!: UpdateAtribute;

  @Prop() businessUnits!: UpdateAtribute;

  @Prop() lastFilter?: Filter;

  @Prop() seniorities?: any[];

  @Prop() resultCenters?: any[];

  @Prop() isManager!: boolean;

  resultCenterKey: number = 0;

  sendFilter() {
    this.$emit('filter', this.filter);
  }

  findManagers(value: string) {
    this.$emit('findManagers', value);
  }

  findEmployees(value: string) {
    this.$emit('findEmployees', value);
  }

  findResultCenter(value: string) {
    this.$emit('findResultCenter', value);
  }

  clearBusinessUnitIdFilter() {
    this.filter.resultCenterId = undefined;
    this.resultCenterKey += 1;
    this.sendFilter();
  }

  get resultCenterHintMessage() {
    return !this.filter.businessUnitId ? 'Filtre por uma unidade para filtrar um centro de custo' : '';
  }
}
