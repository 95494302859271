





















import { Vue, Component, Prop } from 'vue-property-decorator';
import { Currency } from '@/domain/employees/entity/professional-data.entity';
import LabelSlot from '@/shared/slots/label-slot.component.vue';
import currencyTypes from '@/views/employees/profile/tab-components/score/currency-type.json';

@Component({
  components: {
    LabelSlot,
  },
})
export default class CurrencySelect extends Vue {
  @Prop() currency!: Currency | string;

  @Prop({
    type: Boolean,
    default: false,
  }) returnObject!: boolean;

  @Prop({
    type: Boolean,
    default: false,
  }) disabled!: boolean;

  change(value: Currency | string) {
    this.$emit('change', value);
  }

  @Prop({
    type: String,
    default: '',
    required: false,
  }) selectStyles!: string;

  currencyList = currencyTypes;
}
