




































































































import MobileHandler from '@/shared/utils/mobile-handler';
import { Vue, Component, Prop } from 'vue-property-decorator';
import { VbSnackbarService } from 'bag-of-holding-library';
import Skill from '@/domain/drd-2.0/entity/skill.entity';
import DateFormat from 'bag-of-holding-library/src/utils/date-format';
import PaginationComponent from '@/shared/pagination.component.vue';
import PersistSkill from '@/domain/drd-2.0/entity/persist-skill.entity';
import ModalWarning from '@/shared/modal/modal-warning.component.vue';
import ModalOptions from '@/shared/modal/modal-options.entity';
import SkillService from '@/domain/drd-2.0/service/skill.service';
import Headers from './table-header.json';
import SkillTypes from '../skill-types.json';
import UpdateSkillModal from '../modal/update-skill-modal.component.vue';
import ViewSkillModal from '../modal/view-skill-modal.component.vue';

@Component({
  components: {
    PaginationComponent,
    UpdateSkillModal,
    ModalWarning,
    ViewSkillModal,
  },
})
export default class SkillTableComponent extends Vue {
  @Prop() items!: Skill[];

  @Prop({
    type: Boolean,
    default: false,
  }) loading!: boolean;

  @Prop() page!: any;

  isMobile = false;

  headers = Headers;

  skillTypes = SkillTypes;

  openEditSkillModal: boolean = false;

  skillEdited: PersistSkill = new PersistSkill();

  skillEditedId: string = '';

  skillDisableId: string = '';

  loadingDeleteSkill: boolean = false;

  openModalWarning: boolean = false;

  openViewSkillModal: boolean = false;

  skillView: Skill | null = null;

  get modalWarningOptions(): ModalOptions {
    return {
      title: 'Atenção',
      description: 'Você está excluindo uma competência. Deseja mesmo continuar?',
      showOk: true,
      disabledOk: false,
      labelOk: 'Sim, continuar',
      loadingOk: this.loadingDeleteSkill,
      okColor: 'tabBlue',
      showCancel: true,
      labelCancel: 'Cancelar',
      width: 400,
    };
  }

  updateTable(pagination: any): void {
    this.$emit('update', pagination);
  }

  onResize() {
    this.isMobile = MobileHandler.isMobile();
  }

  getType(type: string) {
    return this.skillTypes.filter(it => it.value === type)[0];
  }

  formatDate(value: string) {
    return DateFormat.timestampToLocal(value.split('T')[0]);
  }

  showEditModal(item: Skill) {
    this.skillEdited = new PersistSkill({
      ...item,
      groupId: item.group.id,
    });
    this.skillEditedId = item.id;
    this.openEditSkillModal = true;
  }

  updateSkill(event: any) {
    this.skillEdited[event.field] = event.value;
  }

  closeEditModal() {
    this.openEditSkillModal = false;
    this.updateTable(this.page);
  }

  showModalWarning(item: Skill) {
    this.skillDisableId = item.id;
    this.openModalWarning = true;
  }

  closeModalWarning() {
    this.openModalWarning = false;
  }

  disableSkill() {
    this.loadingDeleteSkill = true;
    SkillService.disable(this.skillDisableId)
      .then(() => {
        VbSnackbarService.showSuccess('Competência excluída com sucesso!');
        this.updateTable(this.page);
      }).catch((error) => {
        VbSnackbarService.showError(error.response.data.messages[0]);
      }).finally(() => {
        this.loadingDeleteSkill = false;
        this.closeModalWarning();
        this.skillDisableId = '';
      })
  }

  showViewSkillModal(skill: Skill) {
    this.skillView = skill;
    this.openViewSkillModal = true;
  }
}
