









































































import { Vue, Component, Prop } from 'vue-property-decorator';
import Formation from '@/domain/drd/entity/formation.entity';
import LabelSlot from '@/shared/slots/label-slot.component.vue';
import Rules from '@/shared/rules/rules';
import ActiveCheckboxField from '@/views/drd/form/tabs/common-components/active-checkbox-field.component.vue';
import FieldsArea from '@/views/drd/form/tabs/common-components/fields-area.component.vue';
import FormationCategory from './formation-category.component.vue';

@Component({
  components: {
    ActiveCheckboxField,
    FieldsArea,
    FormationCategory,
    LabelSlot,
  },
})
export default class FormationComponent extends Vue {
  @Prop({
    type: Formation,
    required: true,
  })
  formation!: Formation;

  rules: Rules = new Rules();

  updateField(field: string, value: any): void {
    this.$emit(`update:${field}`, value);
  }

  deleteFormation(): void {
    this.$emit('delete');
  }
}
