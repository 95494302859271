



















import { Vue, Component, Prop } from 'vue-property-decorator';
import LabelSlot from '@/shared/slots/label-slot.component.vue';

@Component({
  components: { LabelSlot },
})
export default class TextAreaComponent extends Vue {
  @Prop() title!: string;

  @Prop() position!: string;

  @Prop() type!: string;

  value: string = '';

  update(event: any) {
    this.value = event;
    this.$emit('update', this.position, this.value)
  }
}
