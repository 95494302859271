import VacationPeriod from './vacation-period.entity';

export default class ListVacationBalance {
  id: string;

  employee: string;

  manager: string;

  role: string;

  businessUnit: string;

  admissionDate: string;

  balance: number;

  vacationPeriodsLoaded: boolean = false;

  vacationPeriods: VacationPeriod[] = [];

  constructor(obj: any) {
    this.id = obj.employeeId || '';
    this.employee = obj.employeeName || '';
    this.manager = obj.managerName || '';
    this.businessUnit = obj.businessUnit || '';
    this.role = obj.role || '';
    this.admissionDate = obj.employeeAdmissionDate || '';
    this.balance = obj.balance === null || obj.balance === undefined ? 0 : obj.balance;
  }

  public static of(obj: any): ListVacationBalance {
    return new ListVacationBalance(obj || {})
  }

  public static ofList(obj: any[]): ListVacationBalance[] {
    return obj.map(this.of);
  }
}
