var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{staticClass:"data-table",attrs:{"hide-default-footer":"","loading-text":"Carregando...","no-data-text":"Nenhum dado encontrado...","disable-pagination":"","headers":_vm.resultDetailsHeader,"items":_vm.resultDetails,"loading":_vm.loading},scopedSlots:_vm._u([{key:"item.what",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex justify-space-between align-center"},[_c('span',{staticClass:"text-justify"},[_vm._v(" "+_vm._s(item[("what" + _vm.languageAcronymFieldFormat)])+" ")]),_c('span',{staticClass:"ml-4 py-1 px-2 caption",style:(_vm.getItemTypeStyle(item.type))},[_vm._v(" "+_vm._s(item.type.description)+" ")])])]}},{key:"item.expected",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({},on),[_vm._v(" "+_vm._s(item.expected)+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.expectedDetails))])])]}},{key:"item.response",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({},on),[_vm._v(" "+_vm._s(item.response)+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.responseDetails))])])]}},{key:"item.isAdherence",fn:function(ref){
var item = ref.item;
return [_c('span',{class:("font-weight-bold " + (_vm.getResultColorClass(item.isAdherence)))},[_vm._v(" "+_vm._s(item.isAdherence === 1 ? 'Aderente' : (item.isAdherence === 0 ? 'Não aderente' : 'Não se aplica'))+" ")])]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-row',{attrs:{"no-gutters":"","justify":"center"}},[(!item.isAdherence && !item.formPlanId && (_vm.isCurrent || _vm.simulation))?_c('v-col',{attrs:{"cols":"auto"}},[_c('v-btn',{attrs:{"elevation":"0","small":"","rounded":"","icon":""},on:{"click":function($event){return _vm.openPDImodal(item)}}},[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-plus-box-outline")])],1)],1):_vm._e(),(!item.isAdherence && item.formPlanId)?_c('v-col',[_c('v-btn',{attrs:{"elevation":"0","small":"","icon":""},on:{"click":_vm.redirectToProfile}},[_c('v-icon',{attrs:{"color":"black"}},[_vm._v("mdi-eye-arrow-right-outline")])],1)],1):_vm._e()],1)]}}],null,true)}),_c('form-plan-modal-component',{attrs:{"openPlanModal":_vm.openPlanModal,"priorityItems":_vm.priorityItems,"plan":_vm.itemSelected.pdiPlan,"planIndex":0,"loading":_vm.loadingPdi,"disablePlanType":true},on:{"updatePlanField":_vm.updatePlanField,"createPlan":_vm.createPlan,"updatePlan":_vm.updatePlan,"close":_vm.closePdiModal}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }