import RouteCheckerTo from '@/views/route-checker';
import Security from './security.view.vue';
import SecurityComponent from './security-component.vue';
import AuthorityComponent from './authority-component.vue';

export default [
  {
    path: '/security',
    component: Security,
    name: 'Security',
    beforeEnter: RouteCheckerTo.hasAccessSecurity,
    children: [
      {
        path: '/',
        name: 'Perfis de segurança',
        component: SecurityComponent,
        beforeEnter: RouteCheckerTo.hasAccessSecurity,
      },
      {
        path: '/security/authority/:authorityName',
        name: 'Editar perfil de segurança',
        component: AuthorityComponent,
        beforeEnter: RouteCheckerTo.hasAccessSecurity,
      },
    ],
  },
];
