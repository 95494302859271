









































































import {
  Vue,
  Component,
  Prop,
} from 'vue-property-decorator';
import { VbSnackbarService } from 'bag-of-holding-library';
import PaginationComponent from '@/shared/pagination.component.vue';
import Skill from '@/domain/drd-2.0/entity/skill.entity';
import SkillService from '@/domain/drd-2.0/service/skill.service';
import SkillFilter from '@/domain/drd-2.0/entity/skill-filter.entity';
import SelectSkillHeader from './select-skill-header.json';
import SkillTypes from '../../skill/skill-types.json';

@Component({
  components: { PaginationComponent },
})
export default class SelectSkillsDialog extends Vue {
  @Prop({ type: Boolean, default: false })
  value!: boolean;

  @Prop({ type: Array, default: () => ([]) })
  selectedSkills!: Skill[];

  loading: boolean = false;

  allSkills: Skill[] = [];

  search: string = '';

  headers = SelectSkillHeader;

  pagination: any = {
    page: 1,
    itemsPerPage: 10,
    sortBy: '',
    sortDesc: false,
    pageCount: 0,
    total: 0,
  };

  noResultsText: string = 'Nenhuma competência foi encontrada nessa página com o filtro buscado, procure nas próximas. Você também pode aumentar o tamanho da paginação.';

  skillFilter: SkillFilter = new SkillFilter();

  skillTypes = SkillTypes;

  get selected(): Skill[] {
    return this.selectedSkills;
  }

  itemSelected({ item, value }: any): void {
    this.$emit('itemSelected', item, value);
  }

  inputDialog(val: boolean): void {
    this.$emit('input', val);
  }

  updatePage(pagination: any): void {
    if (this.pagination.itemsPerPage < pagination.itemsPerPage) {
      pagination.page = 1;
    }
    this.pagination = pagination;
    this.loadSkills();
  }

  loadSkills(): void {
    this.loading = true;
    SkillService.search(
      this.skillFilter,
      this.pagination.page,
      this.pagination.itemsPerPage,
    ).then(({ data }: any) => {
      this.allSkills = data.content.map(this.mapSkill);
      this.pagination.pageCount = data.totalPages;
      this.pagination.total = data.totalElements;
    }).catch(VbSnackbarService.handleHttpError)
      .finally(() => this.loading = false);
  }

  getType(type: string) {
    return this.skillTypes.filter(it => it.value === type)[0];
  }

  mapSkill(value: any): Skill {
    return new Skill({
      ...value,
      isSelectable: !this.selectedSkills.map(it => it.id).includes(value.id),
    });
  }

  created(): void {
    this.loadSkills();
  }
}
