export default class ActivityHappinessResponse {
  questionPosition: number;

  questionTitle: string;

  response: string;

  constructor(obj: any = {}) {
    this.questionPosition = obj.questionPosition;
    this.questionTitle = obj.questionTitle || '';
    this.response = obj.response || '';
  }
}
