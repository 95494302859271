





































































































































































import { Vue, Component, Prop } from 'vue-property-decorator';
import { VbSnackbarService } from 'bag-of-holding-library';
import Rules from '@/shared/rules/rules';
import Survey from '@/domain/survey/entity/survey.entity';
import FitCultural from '@/domain/survey/entity/fit-cultural.entity';
import SurveyService from '@/domain/survey/survey.service';
import LabelSlot from '@/shared/slots/label-slot.component.vue';
import CandidateService from '@/domain/selective-process/service/candidate.service';
import SurveyType from '@/domain/survey/entity/survey-type.enum';
import FitCulturalService from '@/domain/survey/service/fit-cultural.service';
import TextAreaComponent from './components/text-area-component.vue';
import RadioComponent from './components/radio-component.vue';
import SelectLanguageComponent from './select-language.component.vue';

@Component({
  components: {
    LabelSlot,
    SelectLanguageComponent,
  },
})
export default class SurveyFormComponent extends Vue {
  @Prop() survey!: Survey

  @Prop({
    type: Boolean,
    default: false,
  }) getSurveyLoading!: boolean;

  acronymLanguageSelected: string = '';

  step: number = 1;

  componentsModel = {
    TEXT_AREA: TextAreaComponent,
    RADIO: RadioComponent,
  }

  processDataAuthorize: boolean = false;

  rules: Rules = new Rules();

  loadingSend: boolean = false;

  loadingNextButton: boolean = false;

  sent: boolean = false;

  errorMessage: string = '';

  candidateExists: boolean = false;

  surveyAnswer: FitCultural = new FitCultural();


  initialQuestionsLabels = {
    PT_BR: {
      nameLabel: 'Seu nome: ',
      namePlaceholder: 'Digite seu nome...',
      emailLabel: 'Seu email (email que você utilizou para se candidatar): ',
      emailPlaceholder: 'Digite seu email...',
      processPersonalDataAuthorizeLabel: 'Autorize-nos a processar seus dados pessoais',
    },
    ES: {
      nameLabel: 'Tu nombre: ',
      namePlaceholder: 'Escriba su nombre...',
      emailLabel: 'Tu dirección de correo electrónico (la misma que utilizas para solicitar una vacante de empleo):',
      emailPlaceholder: 'Escribe tu correo electrónico...',
      processPersonalDataAuthorizeLabel: 'Autorícenos a procesar sus datos personales',
    },
  }

  get languageIsSelected() {
    return this.acronymLanguageSelected !== '' || !!this.acronymLanguageSelected;
  }

  get questionsLabels() {
    return this.initialQuestionsLabels[this.acronymLanguageSelected];
  }

  setAnswerValue(position: number, value: string) {
    this.surveyAnswer.answers
      .filter(it => it.questionPosition === position)[0].value = value;
  }

  sendSurveyAnswer() {
    const { endForm }: any = this.$refs;
    if (!endForm.validate()) return;
    this.loadingSend = true;
    this.surveyAnswer.document.replace(/[.-]/g, '')
    FitCulturalService
      .send(this.surveyAnswer)
      .then(() => {
        this.sent = true;
        this.$emit('sent');
      })
      .catch((error) => {
        // eslint-disable-next-line prefer-destructuring
        this.errorMessage = error.response.data.messages[0];
      })
      .finally(() => {
        this.loadingSend = false;
      })
  }

  get tooltipMessage() {
    return this.surveyAnswer.authorizeManipulatePersonalData ? '' : 'Autorize-nos a processar os seus dados pessoais para continuar'
  }

  async next() {
    const { startForm }: any = this.$refs;
    if (!startForm.validate()) return;
    await this.validateExistCandidateByEmail(this.surveyAnswer.email);
    if (!this.candidateExists) return;
    this.step = 2;
  }

  async validateExistCandidateByEmail(email: string) {
    if (this.candidateExists) return;
    this.loadingNextButton = true;
    await CandidateService.existsByEmail(email)
      .then(() => {
        this.candidateExists = true;
        this.errorMessage = '';
      })
      .catch((error) => {
        // eslint-disable-next-line prefer-destructuring
        this.errorMessage = error.response.data.messages[0];
        this.candidateExists = false;
      })
      .finally(() => this.loadingNextButton = false);
  }

  getSurveyByAcronymLanguage(acronymLanguage: string) {
    this.getSurveyLoading = true;
    SurveyService.getSurveyByTypeAndAcronymLanguage(SurveyType.FIT_CULTURAL, acronymLanguage)
      .then((data) => {
        this.survey = data;
        this.surveyAnswer = FitCultural.createInitial(
          this.survey.template.questions,
          this.survey.id,
        );
      }).catch(VbSnackbarService.handleHttpError)
      .finally(() => {
        this.getSurveyLoading = false;
      })
  }

  languageSelected(acronymLanguage: string) {
    this.acronymLanguageSelected = acronymLanguage;
    this.getSurveyByAcronymLanguage(acronymLanguage);
  }
}
