






























import { Vue, Component } from 'vue-property-decorator';
import TalentLevelService from '@/domain/talent-level/service/talent-level.service';
import TalentLevel from '@/domain/talent-level/entity/talent-level.entity';
import AuthorityStore from '@/domain/security/authority.store';
import Authority from '@/domain/security/authority.entity';
import TalentLevelList from './table/talent-level-list.component.vue';
import TalentLevelDialog from './form/talent-level-dialog.component.vue';

@Component({ components: { TalentLevelList, TalentLevelDialog } })
export default class TalentLevelComponent extends Vue {
  private showDialog: boolean = false;

  private renderDialog: boolean = true;

  private talentLevels: TalentLevel[] = [];

  private editingTalentLevelId: string | null = null;

  private loadingTalentLevels: boolean = false;

  isRead: boolean = false;

  isUpdate: boolean = false;

  isCreate: boolean = false;

  isInactive: boolean = false;

  private openTalentLevelDialog(): void {
    this.resetDialog();
    this.showDialog = true;
  }

  private resetDialog(): void {
    this.renderDialog = false;
    this.$nextTick(() => {
      this.renderDialog = true;
    });
  }

  private openDialogForNewTalentLevel(): void {
    this.editingTalentLevelId = null;
    this.openTalentLevelDialog();
  }

  private editTalentLevel(talentLevelId: string): void {
    this.editingTalentLevelId = talentLevelId;
    this.openTalentLevelDialog();
  }

  private deleteTalentLevel(talentLevelId: string): void {
    TalentLevelService.deleteTalentById(talentLevelId).then(this.loadTalentLevels);
  }

  private loadTalentLevels(): void {
    this.loadingTalentLevels = true;
    TalentLevelService.getAllTalentLevels()
      .then((talentLevels: TalentLevel[]) => {
        this.talentLevels = talentLevels;
      })
      .finally(() => {
        this.loadingTalentLevels = false;
      });
  }

  getTalentLevelPermissions(): void {
    AuthorityStore.fetchedAuthority().then((response: Authority) => {
      if (!response.isReadTalentLevel) {
        this.$router.push('/');
        return
      }
      this.isRead = response.isReadTalentLevel();
      this.isCreate = response.isCreateTalentLevel();
      this.isUpdate = response.isUpdateTalentLevel();
      this.isInactive = response.isInactiveTalentLevel();
    })
  }

  private created(): void {
    this.loadTalentLevels();
    this.getTalentLevelPermissions();
  }
}

