























































































import { Vue, Component, Prop } from 'vue-property-decorator';
import ModalWarning from '@/shared/modal/modal-warning.component.vue';
import TalentLevelLabel from '@/shared/talent-level-label.component.vue';
import TalentLevel from '@/domain/talent-level/entity/talent-level.entity';
import TalentLevelColor from '../talent-level-color.component.vue';

@Component({ components: { TalentLevelColor, ModalWarning, TalentLevelLabel } })
export default class TalentLevelList extends Vue {
  @Prop({
    type: Array,
    default: () => ([]),
  })
  private talentLevels!: TalentLevel[];

  @Prop({
    type: Boolean,
    default: false,
  })
  private loading!: boolean;

  @Prop() isInactive!: boolean;

  @Prop() isUpdate!: boolean;

  private showDeleteConfirmationDialog: boolean = false;

  private deletingTalentLevelId: string | null = null;

  private headers: any[] = [
    {
      value: 'name',
      text: 'Tipo do talento',
      align: 'center',
      width: 140,
    },
    {
      value: 'description',
      text: 'Critérios',
    },
    {
      value: 'color',
      text: 'Cor',
      align: 'center',
      width: 70,
    },
    {
      value: 'active',
      text: 'Ativo?',
      align: 'center',
      width: 90,
    },
    {
      value: 'actions',
      text: 'Ações',
      align: 'center',
      sortable: false,
    },
  ];

  private editItem(talentLevel: TalentLevel): void {
    this.$emit('edit', talentLevel.id);
  }

  private openDeleteDialog(talentLevel: TalentLevel): void {
    if (!talentLevel.active) return;
    this.deletingTalentLevelId = talentLevel.id;
    this.showDeleteConfirmationDialog = true;
  }

  private closeDeleteConfirmationDialog(): void {
    this.deletingTalentLevelId = null;
    this.showDeleteConfirmationDialog = false;
  }

  private deleteItem(): void {
    if (this.deletingTalentLevelId === null) return;
    this.$emit('delete', this.deletingTalentLevelId);
    this.closeDeleteConfirmationDialog();
  }
}
