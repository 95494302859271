import Birthday from './birthday.view.vue';

export default [
  {
    path: '/birthday',
    component: Birthday,
    name: 'Birthday',
    meta: {
      hideMenus: true,
    },
  },
];
