export default class NewFeatureHandler {
  public static async create(featureName: string): Promise<void> {
    const newFeature = localStorage.getItem(`feat-${featureName}`)
    if (newFeature) return;
    localStorage.setItem(`feat-${featureName}`, JSON.stringify({ viewed: false }))
  }

  public static setViewed(featureName: string): void {
    const newFeature = localStorage.getItem(`feat-${featureName}`);
    if (!newFeature) return;
    const newFeatureParsed = JSON.parse(newFeature);
    if (newFeatureParsed.viewed) return;
    localStorage.setItem(`feat-${featureName}`, JSON.stringify({ viewed: true }));
  }

  public static getFeatureViewed(featureName: string): boolean {
    const newFeature = localStorage.getItem(`feat-${featureName}`);
    if (!newFeature) return true;
    const newFeatureParsed = JSON.parse(newFeature);
    return newFeatureParsed.viewed;
  }
}
