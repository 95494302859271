import { render, staticRenderFns } from "./career-update-list.component.vue?vue&type=template&id=05f64b98&scoped=true&"
import script from "./career-update-list.component.vue?vue&type=script&lang=ts&"
export * from "./career-update-list.component.vue?vue&type=script&lang=ts&"
import style0 from "./career-update-list.component.vue?vue&type=style&index=0&id=05f64b98&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "05f64b98",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VExpandTransition } from 'vuetify/lib/components/transitions';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';
installComponents(component, {VDivider,VExpandTransition,VIcon,VSpacer})
