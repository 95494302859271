export default class AreaKeyItem {
  public id: string;

  public description: string;

  public active: boolean;

  public junior: string;

  public plain: string;

  public senior: string;

  constructor(obj: any = {}) {
    this.id = obj.id || null;
    this.description = obj.description || '';
    this.active = obj.active === undefined ? true : obj.active;
    this.junior = obj.junior || 'NOVICE';
    this.plain = obj.plain || 'NOVICE';
    this.senior = obj.senior || 'NOVICE';
  }
}
