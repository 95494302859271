import axios from 'axios';
import MessageFilterRequest from '../entity/message-filter.request';
import MessageRequest from '../entity/message.request';

export default class MicrosoftService {
  public static sendMessage(message: string, filter: MessageFilterRequest): Promise<void> {
    return axios.post('/api/microsoft-graph/message', new MessageRequest(message), {
      timeout: Infinity,
      params: {
        toManagers: filter.toManagers,
        indirectSubordinates: filter.indirectSubordinates,
        managers: filter.hasField('managers') ? filter.managers.toString() : null,
        businessUnits: filter.hasField('businessUnits') ? filter.businessUnits.toString() : null,
        resultCenters: filter.hasField('resultCenters') ? filter.resultCenters.toString() : null,
        roles: filter.hasField('roles') ? filter.roles.toString() : null,
        workplaces: filter.hasField('workplaces') ? filter.workplaces.toString() : null,
        gender: filter.hasField('gender') ? filter.gender : null,
        cities: filter.hasField('cities') ? filter.cities.toString() : null,
        states: filter.hasField('states') ? filter.states.toString() : null,
        countries: filter.hasField('country') ? filter.country.toString() : null,
        hasChildren: filter.hasChildren,
        foreignState: filter.hasField('foreignState') ? filter.foreignState : null,
      },
    })
  }
}
