import { Currency } from '@/domain/employees/entity/professional-data.entity';
import DateFormat from '@/shared/utils/date-format';
import { VbDirectives } from 'bag-of-holding-library';
import isNullOrUndefined from '@/shared/utils/check-null-or-undefined';

export default class FilteredSeniorityRemumeration {
  employeeId: string;

  name: string;

  role: string;

  seniority: string;

  maxWage: number;

  mediumWage: number;

  minWage: number;

  benefit: number;

  salaryRange: string;

  salary: number;

  observation: string;

  currency: Currency;

  ducz: number;

  duczSyndicate: number;

  countDate: string;

  constructor(obj: any) {
    this.employeeId = obj.employeeId || '';
    this.name = obj.name || '';
    this.role = obj.role || '';
    this.seniority = obj.seniority || '';
    this.maxWage = obj.maxWage || '';
    this.mediumWage = obj.mediumWage || '';
    this.minWage = obj.minWage || '';
    this.benefit = isNullOrUndefined(obj.benefit) ? 0 : obj.benefit;
    this.salaryRange = obj.salaryRange || '';
    this.salary = isNullOrUndefined(obj.salary) ? 0 : obj.salary;
    this.observation = obj.observation || '';
    this.currency = obj.currency || null;
    this.ducz = isNullOrUndefined(obj.ducz) ? 0 : obj.ducz;
    this.duczSyndicate = isNullOrUndefined(obj.duczSyndicate) ? 0 : obj.duczSyndicate;
    this.countDate = obj.countDate || '';
  }

  public static of(obj: any): FilteredSeniorityRemumeration {
    return new FilteredSeniorityRemumeration(obj || {});
  }

  public static ofList(obj: any[]): FilteredSeniorityRemumeration[] {
    return obj.map(this.of);
  }

  public getTotalRemuneration(): number {
    return Number(this.salary + this.benefit);
  }

  public returnManagementAnalysis(): Object {
    if (this.salaryRange === 'ABOVE_RANGE') {
      const diference = this.getTotalRemuneration() - this.maxWage;
      return {
        text: `${VbDirectives.toCurrency(diference)} acima do indicado`,
        color: 'rgba(236,151,23,1)',
      }
    }
    if (this.salaryRange === 'BELOW_RANGE') {
      const diference = this.minWage - this.getTotalRemuneration();
      return {
        text: `${VbDirectives.toCurrency(diference)} abaixo do indicado`,
        color: 'rgba(243,19,19,1)',
      }
    }
    if (this.salaryRange === 'ON_RANGE') {
      return {
        text: 'Conforme indicado',
        color: 'green',
      }
    }
    return {
      text: 'Sem análise',
      color: 'rgba(11,138,207,1)',
    }
  }

  get countDateFormated() {
    return DateFormat.splitLocal(this.countDate);
  }

  get total() {
    return this.salary + this.benefit;
  }
}
