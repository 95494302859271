import axios from 'axios';
import Filter from '../entity/filter.entity';
import Response from '../entity/response.entity';

export default class FilterService {
  public static findEmployees(filter: Filter, page: number, size: number): Promise<Response> {
    return axios.get('/api/employees', {
      params: {
        employee: filter.hasEmployee ? filter.employee.toString() : null,
        manager: filter.hasManager ? filter.manager.toString() : null,
        role: filter.hasRole ? filter.role.toString() : null,
        assignments: filter.hasAssignment ? filter.assignment.toString() : null,
        businessUnit: filter.hasBusinessUnit ? filter.businessUnit.toString() : null,
        active: filter.active,
        isAccompanied: filter.isAccompanied,
        indirectSubordinates: filter.indirectSubordinates,
        orderBy: filter.orderBy,
        page: page - 1,
        size,
      },
    })
      .then((response: any) => Response.of(response.data));
  }
}
