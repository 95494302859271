import City from '@/domain/states/entity/city.entity';
import State from '@/domain/states/entity/state.entity';
import ChildrenDataRequest from './children-data-request.entity';

export default class PersonalDataRequest {
  employee: string;

  companyEmail: string;

  privateEmail: string;

  birthDate: string;

  cellPhone: string;

  residentialPhone: string;

  address: string;

  addressNumber: string;

  district: string;

  complement: string;

  city: string;

  state: string;

  country: string;

  postalCode: string;

  gender: any;

  genderDetail: string;

  maritalStatus: any;

  hasChildren: boolean = false;

  children: ChildrenDataRequest[];

  observation: string;

  emergencyName: string;

  emergencyPhone: string;

  emergencyRelationship: string;

  nickname: string;

  usesIfood: boolean = true;

  shirtSize: string;

  consumeAlcoholicDrink: boolean;

  foodAllergies: string;

  foodDoNotLike: string;

  commemorativeDates : string[];

  foreignState: string;

  foreignCity: string;

  religion: string;

  specifiedReligion: string;

  constructor(obj: any) {
    this.employee = obj.employee || '';
    this.companyEmail = obj.companyEmail || '';
    this.privateEmail = obj.privateEmail || '';
    this.birthDate = obj.birthDate || '';
    this.cellPhone = obj.cellPhone || '';
    this.residentialPhone = obj.residentialPhone || '';
    this.address = obj.address || '';
    this.addressNumber = obj.addressNumber || '';
    this.district = obj.district || '';
    this.complement = obj.complement || '';
    this.city = obj.city || City.of({});
    this.state = obj.state || State.of({});
    this.country = obj.country || null;
    this.postalCode = obj.postalCode || '';
    this.gender = obj.gender || '';
    this.genderDetail = obj.genderDetail ? obj.genderDetail : null;
    this.maritalStatus = obj.maritalStatus || '';
    this.hasChildren = obj.hasChildren ? obj.hasChildren : false;
    this.children = obj.children || [];
    this.observation = obj.observation || '';
    this.emergencyName = obj.emergencyName || '';
    this.emergencyPhone = obj.emergencyPhone || '';
    this.emergencyRelationship = obj.emergencyRelationship || '';
    this.nickname = obj.nickname || '';
    this.usesIfood = obj.usesIfood;
    this.shirtSize = obj.shirtSize || '';
    this.consumeAlcoholicDrink = obj.consumeAlcoholicDrink;
    this.foodAllergies = obj.foodAllergies || 'NO_RESTRICTIONS';
    this.foodDoNotLike = obj.foodDoNotLike || '';
    this.commemorativeDates = obj.commemorativeDates || 'ALL';
    this.foreignState = obj.foreignState || '';
    this.foreignCity = obj.foreignCity || '';
    this.religion = obj.religion || null;
    this.specifiedReligion = obj.specifiedReligion || '';
  }

  public static of(obj: any): PersonalDataRequest {
    return new PersonalDataRequest(obj || {});
  }

  public static ofList(obj: any[]): PersonalDataRequest[] {
    return obj.map(this.of);
  }
}
