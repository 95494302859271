



























































import { Vue, Component, Prop } from 'vue-property-decorator';
import { VbSnackbarService } from 'bag-of-holding-library';
import CountryFlag from 'vue-country-flag';
import Country from '@/domain/countries/entity/country.entity';
import CountryService from '@/domain/countries/service/country.service';
import Rules from './rules/rules';
import LabelSlot from './slots/label-slot.component.vue';
import SelectionComponent from './fields/selection.componet.vue';

@Component({
  components: {
    LabelSlot,
    CountryFlag,
    SelectionComponent,
  },
})
export default class CountrySelect extends Vue {
  @Prop({
    type: String,
    default: 'País',
  }) label!: string;

  @Prop({
    type: Boolean,
    default: true,
  }) isClearable!: boolean;

  @Prop({
    type: Boolean,
    default: false,
  }) readonly!: boolean;

  @Prop({
    type: Boolean,
    default: false,
  }) isMandatory!: boolean;

  @Prop({
    required: true,
  }) country!: any;

  @Prop({
    type: Boolean,
    default: true,
  }) returnObject!: boolean;

  @Prop({ type: Boolean, default: false })
  disabled!: boolean;

  @Prop({
    type: Boolean,
    default: false,
  }) multiple!: boolean;

  @Prop({
    type: Boolean,
    default: false,
  }) hideDetails!: boolean;

  rules: Rules = new Rules();

  countries: Country[] = [];

  loadingCountries: boolean = false;

  created() {
    this.getCountries();
  }

  updateCountry(country: any) {
    this.$emit('updateCountry', country);
  }

  clearCountry() {
    this.updateCountry(new Country());
  }

  getCountries() {
    this.loadingCountries = true;
    CountryService.findCountries()
      .then(countries => this.countries = countries)
      .catch(error => VbSnackbarService.handleHttpError(error))
      .finally(() => {
        this.loadingCountries = false;
        this.countriesFetched();
      });
  }

  countriesFetched() {
    this.$emit('countriesFetched', this.countries);
  }

  get getRules() {
    if (!this.isMandatory) return [];
    return [this.rules.emptyObject].flat();
  }
}
