export default class ScoreDependentBenefits {
  healthPlanDependent: boolean

  dentalPlanDependent: boolean

  lifeInsuranceDependent: boolean

  healthPlanDependentPayedByDB1: boolean

  dentalPlanDependentPayedByDB1: boolean

  dependentName: string;

  relationship: string;

  birthDate: string;

  type: string;

  constructor(obj: any) {
    this.healthPlanDependent = obj.healthPlanDependent || false;
    this.dentalPlanDependent = obj.dentalPlanDependent || false;
    this.lifeInsuranceDependent = obj.lifeInsuranceDependent || false;
    this.healthPlanDependentPayedByDB1 = obj.healthPlanDependentPayedByDB1 || false;
    this.dentalPlanDependentPayedByDB1 = obj.dentalPlanDependentPayedByDB1 || false;
    this.dependentName = obj.dependentName || '';
    this.relationship = obj.relationship || '';
    this.birthDate = obj.birthDate || '';
    this.type = obj.type || 'REGION_INFIRMARY';
  }

  public static of(obj: any): ScoreDependentBenefits {
    return new ScoreDependentBenefits(obj || {})
  }

  public static ofList(obj: any[]): ScoreDependentBenefits[] {
    return obj.map(this.of);
  }

  public static createDependent(): ScoreDependentBenefits[] {
    return ScoreDependentBenefits.ofList([{}])
  }
}
