import { DirectiveOptions } from 'vue';
import AuthorityStore from '@/domain/security/authority.store';
import AuthorityEnum from '@/domain/security/authority.enum';

function applyAuthorities(element: any, authorities: string[]) {
  element.style.display = 'none';
  AuthorityStore.hasAuthority(authorities).then((response) => {
    if (response) {
      element.style.display = 'block';
    }
  });
}

export const hasAuthority: DirectiveOptions = {
  inserted(element, binding) {
    const authorities = binding && binding.expression
      ? binding.expression.replace(/'/g, '').split(',')
      : [];
    applyAuthorities(element, authorities);
  },
};

export const hasCreateDrd: DirectiveOptions = {
  inserted(element) {
    applyAuthorities(element, [AuthorityEnum.CREATE_DRD])
  },
};

export const hasReadWages: DirectiveOptions = {
  inserted(element) {
    applyAuthorities(element, [AuthorityEnum.READ_WAGES])
  },
}

export const hasManagerAuthority: DirectiveOptions = {
  inserted(element) {
    applyAuthorities(element, [AuthorityEnum.MANAGER])
  },
};

export const hasAdministratorAuthority: DirectiveOptions = {
  inserted(element) {
    applyAuthorities(element, [AuthorityEnum.ADMINISTRATOR]);
  },
};

export const hasEmployeeAdministratorAuthority: DirectiveOptions = {
  inserted(element) {
    applyAuthorities(element, [AuthorityEnum.EMPLOYEE_ADMINISTRATOR]);
  },
};

export const hasBusinessPartnerAuthority: DirectiveOptions = {
  inserted(element) {
    applyAuthorities(element, [AuthorityEnum.BUSINESS_PARTNER]);
  },
};

export const hasPersonalDepartmentAuthority: DirectiveOptions = {
  inserted(element) {
    applyAuthorities(element, [AuthorityEnum.PERSONAL_DEPARTMENT]);
  },
};
export const hasAdministratorOrManagerAuthority: DirectiveOptions = {
  inserted(element) {
    applyAuthorities(element, ['ADMINISTRATOR', 'MANAGER']);
  },
};

export const hasFacilitiesOrEmployeeAdministratorAuthority: DirectiveOptions = {
  inserted(element) {
    applyAuthorities(element, ['ADMINISTRATOR', 'FACILITIES_ADMINISTRATOR', 'EMPLOYEE_ADMINISTRATOR']);
  },
};

export const hasTrainingAdministratorAdministratorOrManagerAuthority: DirectiveOptions = {
  inserted(element) {
    applyAuthorities(element, ['ADMINISTRATOR', 'TRAINING_ADMINISTRATOR', 'MANAGER']);
  },
};
