import axios from 'axios';
import VacationPayslipListItem from '../entity/vacation-payslip-list-item.entity';
import VacationPayslip from '../entity/vacation-payslip.entity';

export default class VacationPayslipService {
  public static save(vacation: VacationPayslip): Promise<void> {
    const formData = new FormData();
    formData.append('file', vacation.file);
    return axios.post(`/api/vacation-payslip?competence=${vacation.date}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      timeout: Infinity,
    })
  }

  public static getVacationPayslips(): Promise<VacationPayslipListItem[]> {
    return axios.get('/api/vacation-payslip/me')
      .then(({ data }) => data.map(it => new VacationPayslipListItem(it)));
  }

  public static downloadVacationPayslip(fileId: string): Promise<any> {
    return axios.get(`/api/vacation-payslip/${fileId}`).then(({ data }) => data);
  }
}
