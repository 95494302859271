

































import { Vue, Component, Prop } from 'vue-property-decorator';
import BehavioralSkill from '@/domain/drd/entity/behavioral-skill.entity';
import SeniorityLevel from '@/views/drd/commons/seniority-level.component.vue';
import DrdPanel from '../common-components/drd-panel.component.vue';

@Component({
  components: { DrdPanel, SeniorityLevel },
})
export default class BehavioralSkillComponent extends Vue {
  @Prop({
    type: BehavioralSkill,
    required: true,
  })
  private behavioralSkill!: BehavioralSkill;
}
