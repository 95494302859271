import { HappinessLevel } from './happiness-level.enum'

export default class HappinessLevelEntity {
  value: HappinessLevel

  description: string;

  emoji: string;

  selected: boolean = false;

  constructor(obj: any = {}) {
    this.value = obj.value || '';
    this.description = obj.description || '';
    this.emoji = obj.emoji || '';
  }

  public static of(obj: any = {}): HappinessLevelEntity {
    return new HappinessLevelEntity(obj)
  }

  public static ofList(obj: any[] = []): HappinessLevelEntity[] {
    return obj.map(this.of)
  }
}
