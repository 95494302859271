














import { Vue, Component } from 'vue-property-decorator';
import AdmissionDateAnniversaryService from '@/domain/admission-date-anniversary/admission-date-anniversary.service';
import AdmissionDateAnniversary from '@/domain/admission-date-anniversary/admission-date-anniversary.entity';
import AdmissionDateAnniversaryComponent from './admission-date-anniversary-component.vue';

@Component({ components: { AdmissionDateAnniversaryComponent } })
export default class AdmissionDateAnniversaries extends Vue {
  admissionDateAnniversaries: AdmissionDateAnniversary[] = []

  nextAdmissionDateAnniversaries: AdmissionDateAnniversary[] = [];

  private getPersonalAdmissionDateAnniversary() {
    AdmissionDateAnniversaryService.getAdmissionDateAnniversarys()
      .then((response: AdmissionDateAnniversary[]) => this.admissionDateAnniversaries = response)
  }

  private getNextAdmissionDateAnniversaries() {
    AdmissionDateAnniversaryService.getNextAdmissionDateAnniversarys()
      .then(
        (response: AdmissionDateAnniversary[]) => this.nextAdmissionDateAnniversaries = response,
      )
  }

  created():void {
    this.getPersonalAdmissionDateAnniversary()
    this.getNextAdmissionDateAnniversaries()
  }
}

