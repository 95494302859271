

























import { Vue, Prop, Component } from 'vue-property-decorator';

@Component
export default class ScoreMessage extends Vue {
  @Prop() isUnscheduledScore!: boolean;
}
