


























































































































































import { Vue, Component, Prop } from 'vue-property-decorator'
import { VbSnackbarService, VbTooltipSlot } from 'bag-of-holding-library';
import { PlanState } from '@/domain/pdi/entity/plan-state';
import LabelSlot from '@/shared/slots/label-slot.component.vue'
import ActionPlan from '@/domain/activity/entity/action-plan.entity'
import ModalWarning from '@/shared/modal/modal-warning.component.vue';
import ModalOptions from '@/shared/modal/modal-options.entity';
import PdiService from '@/domain/pdi/pdi.service';
import formatEmail from '@/shared/utils/format-email';
import DateFormat from '@/shared/utils/date-format';
import StateItems from './stateItems.json';
import PlanTypes from './plan-types.json';


@Component({
  components: {
    LabelSlot,
    VbTooltipSlot,
    ModalWarning,
  },
  filters: {
    formatDate(value: string) {
      return DateFormat.timestampToLocal(value.split('T')[0]);
    },
  },
})
export default class PdiPlanList extends Vue {
  @Prop() plan!: ActionPlan;

  @Prop({
    type: Boolean,
    default: false,
  }) hasUpdatePdiPlan!: boolean;

  @Prop({
    type: Boolean,
    default: false,
  }) hasDeletePdiPlan!: boolean;

  @Prop({
    type: Boolean,
    default: false,
  }) isLoggedUserProfile!: boolean;

  planTypes = PlanTypes;

  loadingDeletePlan: boolean = false;

  loadingUpdateStatePlan: boolean = false;

  stateItems = StateItems;

  selected: boolean = false;

  showModalWarning: boolean = false;

  planState = PlanState;

  get modalWarningOptions(): ModalOptions {
    return {
      title: 'Atenção',
      description: 'Excluir este item resultará na perda permanente dos dados. Deseja continuar?',
      showOk: true,
      disabledOk: false,
      labelOk: 'Sim, continuar',
      loadingOk: this.loadingDeletePlan,
      okColor: 'tabBlue',
      showCancel: true,
      labelCancel: 'Cancelar',
      width: 400,
    };
  }

  updatePlan() {
    this.$emit('updatePlan', this.plan);
  }

  planTitleFormat(planTitle: string): string {
    if (planTitle.length > 100) {
      return planTitle.substring(0, 100).concat('...');
    }
    return planTitle
  }

  get planStateDescription() {
    if (!this.plan.state) return '';
    return this.stateItems.filter(item => item.value === this.plan.state)[0].description;
  }

  get planTypeDescription() {
    if (!this.plan.planType) return '';
    return this.planTypes.filter(type => type.value === this.plan.planType)[0].description;
  }

  updateStateTooltipMessage = {
    DRAFT: 'Mover para abertos',
    OPEN: 'Concluir',
  }

  closeModalWarning() {
    this.showModalWarning = false;
  }

  openModalWarning() {
    this.showModalWarning = true;
  }

  updateState(state: PlanState) {
    this.loadingUpdateStatePlan = true;
    PdiService.updateStatePlan(this.plan.planId, state)
      .then(() => VbSnackbarService.showSuccess('Status do plano atualizado com sucesso!'))
      .catch(error => VbSnackbarService.handleHttpError(error))
      .finally(() => {
        this.loadingUpdateStatePlan = false;
        this.$emit('updateState');
      });
  }

  deletePlan() {
    this.loadingDeletePlan = true;
    PdiService.deletePlan(this.plan.planId)
      .then(() => {
        VbSnackbarService.showSuccess('Plano deletado com sucesso!')
        this.$emit('deletePlan');
      })
      .catch(error => VbSnackbarService.handleHttpError(error))
      .finally(() => {
        this.loadingDeletePlan = false;
        this.closeModalWarning();
      })
  }

  formatEmail(value: string) {
    return formatEmail(value);
  }

  formatFullDate(date: string) {
    return DateFormat.fullDate(date)
  }

  formatDate(value: string) {
    return DateFormat.timestampToLocal(value.split('T')[0]);
  }

  formatDateTime(date: string) {
    return DateFormat.fullDateAndTime(date);
  }

  get getPlanSubText() {
    if (this.plan.state === this.planState.DONE) {
      return `Concluído em: ${this.formatDateTime(this.plan.closedDate)}`;
    }
    if (this.plan.dueDate) {
      return `Prazo: ${this.formatFullDate(this.plan.dueDate)}`
    }
    return ''
  }
}
