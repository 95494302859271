import attendanceType from '@/views/attendance/table/filter/attendanceType.json'
import statusAttendance from './statusAttendance.json'

export default class FilteredAttendance {
  id: string;

  name: string;

  role: string;

  manager: string;

  businessUnit: string;

  admissionDate: string;

  attendanceType: string;

  responsible: string;

  expectedDate: string;

  statusAttendance: string;

  critical: string;

  private readonly types = attendanceType;

  private readonly status = statusAttendance;

  constructor(obj: any) {
    this.id = obj.id || '';
    this.name = obj.name || '';
    this.role = obj.role || '';
    this.manager = obj.manager || '';
    this.businessUnit = obj.businessUnit || '';
    this.admissionDate = obj.admissionDate || '';
    this.attendanceType = obj.attendanceType || '';
    this.responsible = obj.responsible || '-';
    this.expectedDate = obj.expectedDate || '';
    this.statusAttendance = obj.statusAttendance || '';
    this.critical = obj.critical || ''
  }

  public static of(obj: any): FilteredAttendance {
    return new FilteredAttendance(obj || {});
  }

  public static ofList(obj: any[]): FilteredAttendance[] {
    return obj.map(this.of);
  }

  statusDescription(): string {
    return this.verifyValue(this.status, this.statusAttendance, (it => it.description));
  }

  statusColor(): string {
    return this.verifyValue(this.status, this.statusAttendance, (it => it.color));
  }

  private verifyValue(collections, comparisonKey, mappedValue): string {
    return collections
      .filter(collection => collection.value === comparisonKey)
      .map(mappedValue)
      .toString()
  }

  typeDescription(): string {
    return this.verifyValue(this.types, this.attendanceType, (it => it.description));
  }

  get responsibleName() {
    return this.responsible
  }
}
