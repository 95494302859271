











































import { Vue, Component, Prop } from 'vue-property-decorator';
import KeyAreaFilter from '@/domain/drd-2.0/entity/key-area-filter.entity';
import LabelSlot from '@/shared/slots/label-slot.component.vue';

@Component({
  components: {
    LabelSlot,
  },
})
export default class KeyAreaFilterComponent extends Vue {
  @Prop({
    required: true,
  }) filter!: KeyAreaFilter;

  update(value: string, field: string) {
    this.$emit('update', { value, field });
  }

  sendFilter() {
    this.$emit('sendFilter');
  }
}
