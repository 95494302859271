import RouteCheckerTo from '@/views/route-checker';
import SelectiveProcessView from './selective-process.view.vue'
import CandidateCultureGradeDetailsView from './candidate/candiate-culture-grade-details.view.vue';
import CandidateResultDetails from './candidate/candidate-result-datails.view.vue';

export default [
  {
    path: '/selective-process',
    component: SelectiveProcessView,
    name: 'Processo Seletivo',
    beforeEnter: RouteCheckerTo.hasAccessSelectiveProcess,
  },
  {
    path: '/selective-process/candidate-culture-grade-details/:candidateId',
    component: CandidateCultureGradeDetailsView,
    name: 'Detalhes notas Fit Cultural',
    beforeEnter: RouteCheckerTo.hasAccessSelectiveProcess,
  },
  {
    path: '/selective-process/candidate/:candidateId/results',
    component: CandidateResultDetails,
    name: 'Detalhes resultados candidato',
    beforeEnter: RouteCheckerTo.hasAccessSelectiveProcess,
  },
];
