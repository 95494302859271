















































































































































import { Component, Prop, Vue } from 'vue-property-decorator';
import CardTitle from '@/shared/card/card-title.component.vue';
import {
  hasEmployeeAdministratorAuthority,
  hasManagerAuthority,
  hasAdministratorAuthority,
  hasFacilitiesOrEmployeeAdministratorAuthority,
} from '@/shared/directive/has-authority.directive';
import { VbTooltipSlot } from 'bag-of-holding-library';
import RiskAttendanceFilter from '@/domain/risk-attendance/entity/filter.entity'
import AddButton from '@/shared/buttons/add-button.component.vue';

@Component({
  components: { CardTitle, VbTooltipSlot, AddButton },
  directives: {
    hasEmployeeAdministratorAuthority,
    hasManagerAuthority,
    hasAdministratorAuthority,
    hasFacilitiesOrEmployeeAdministratorAuthority,
  },
})
export default class RiskAttendanceHeader extends Vue {
  @Prop() filter!: RiskAttendanceFilter;

  applyFilter() {
    this.$emit('filter', this.filter);
  }
}
