var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticClass:"vacation-period-list",attrs:{"loading":_vm.loading,"items":_vm.periods,"hide-default-footer":"","no-data-text":"Não há períodos de férias cadastrados para esse colaborador.","loading-text":"Carregando...","headers":_vm.headers,"show-expand":"","disable-pagination":""},scopedSlots:_vm._u([{key:"item.startPeriod",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.startPeriod))+" ")]}},{key:"item.limitVacation",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.limitVacation))+" ")]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{staticClass:"px-0",attrs:{"colspan":headers.length}},[_c('vacation-employee-list-component',{staticClass:"vacation-period-list__vacation-employee-datatable",attrs:{"vacations":item.vacations}})],1)]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }