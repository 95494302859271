


































import Formation from '@/domain/drd/entity/formation.entity';
import { Vue, Component, Prop } from 'vue-property-decorator';
import FormationCategory from '@/views/drd/form/tabs/formation/formation-category.component.vue';
import DrdPanel from '../common-components/drd-panel.component.vue';

@Component({
  components: { DrdPanel, FormationCategory },
})
export default class FormationComponent extends Vue {
  @Prop({
    type: Formation,
    required: true,
  })
  private formation!: Formation;
}
