export default class Skill {
  id: string;

  keyAreaSkillId: string;

  whatPTBR: string;

  whatES: string;

  whatEN: string;

  details: string;

  order: number;

  constructor(obj: any = {}) {
    this.id = obj.id || '';
    this.keyAreaSkillId = obj.keyAreaSkillId || '';
    this.whatPTBR = obj.whatPTBR || '';
    this.whatES = obj.whatES || '';
    this.whatEN = obj.whatEN || '';
    this.details = obj.details || '';
    this.order = obj.order;
  }
}
