






























import { Vue, Component, Prop } from 'vue-property-decorator';
import InitialScore from '@/domain/initial-score/entity/initial-score.entity';
import ContractTypes from '@/shared/contract-type/contract-type.json';

@Component
export default class ExportDocumentsMenu extends Vue {
  @Prop() score!: InitialScore;

  @Prop() businessUnit!: any;

  @Prop() salary!: number;

  @Prop() roleName!: string;

  @Prop() ducz!: number;

  @Prop({
    type: String,
    default: '',
  }) seniority!: string;

  @Prop({
    type: Number,
  }) educationSubsidy!: number;

  @Prop() businessUnitBankAccountDocument!: any;

  contractTypes = ContractTypes;

  exportScoreInitialToPDF() {
    const contractType = this.getContractTypeDescription()
    const initialScorePDFUrl = this.$router.resolve({
      name: 'ScoreInitialPDF',
      query: {
        textColor: this.businessUnit.textColor,
        labelsBackgroundColor: this.businessUnit.labelsBackgroundColor,
        labelsTextColor: this.businessUnit.labelsTextColor,
        employeeName: this.score.employeeName,
        salary: this.salary.toString(),
        startDate: this.score.admissionDate,
        roleName: this.roleName,
        workJourne: this.score.workJourney,
        contractType,
        businessUnitName: this.businessUnit.text,
        ducz: this.ducz.toString(),
        educationSubsidy: this.educationSubsidy.toString(),
        titleColor: this.businessUnit.titleColor,
        subtitleColor: this.businessUnit.subtitleColor,
        subtitleLineColor: this.businessUnit.subtitleLineColor,
        logoWidth: this.businessUnit.logoWidth,
        logoHeight: this.businessUnit.logoHeight,
        bannerWidth: this.businessUnit.bannerWidth,
        bannerHeight: this.businessUnit.bannerHeight,
        currencySymbol: this.score.currency.symbol,
        operationModality: this.score.operationModality.name,
        observation: this.score.observationTemplate,
        seniority: this.seniority,
      },
    })

    window.open(initialScorePDFUrl.href, '_blank');
  }

  getContractTypeDescription() {
    const contractTypeFound = this.contractTypes
      .find(contractType => contractType.name === this.score.contractType);
    if (!contractTypeFound) return '';
    return contractTypeFound.description;
  }

  exportBankAccountDocument() {
    const bankAccountDocumentTemplateUrl = this.$router.resolve({
      name: 'Banck Account Document',
      query: {
        employeeName: this.score.employeeName,
        salary: this.salary.toString(),
        businessUnitName: this.businessUnitBankAccountDocument.name,
        address: this.score.address.address,
        addressNumber: this.score.address.addressNumber,
        district: this.score.address.district,
        city: this.score.address.city.name,
        stateAcronym: this.score.address.state.acronym,
        cnpj: this.businessUnitBankAccountDocument.cnpj,
        businessUnitFullName: this.businessUnitBankAccountDocument.fullName,
        logo: this.businessUnitBankAccountDocument.logo,
      },
    })
    window.open(bankAccountDocumentTemplateUrl.href, '_blank');
  }
}
