





































































































































































import {
  Vue, Component, Prop,
} from 'vue-property-decorator';
import { VbSnackbarService } from 'bag-of-holding-library';
import PaginationComponent from '@/shared/pagination.component.vue';
import LabelSlot from '@/shared/slots/label-slot.component.vue';
import MobileHandler from '@/shared/utils/mobile-handler';
import ModalWarning from '@/shared/modal/modal-warning.component.vue';
import AttendanceService from '@/domain/attendance/service/attendance.service';
import splitContent from '@/shared/directive/split-content';
import DateHandler from '@/shared/utils/date-format';
import FilteredAttendance from '@/domain/attendance/entity/filtered-attendance';
import Header from './header.json';

@Component({
  components: { PaginationComponent, LabelSlot, ModalWarning },
  filters: {
    splitContent,
    formatDate(value: string) {
      return DateHandler.timestampToLocal(value.split('T')[0]);
    },
  },
})
export default class TableComponent extends Vue {
  @Prop() items!: FilteredAttendance[];

  @Prop() loading!: boolean;

  @Prop() page!: any;

  @Prop() critical!: boolean;

  @Prop() updateAttendance!: boolean;

  @Prop() hasDeleteAttendance!: boolean;

  @Prop() readAttendance!: boolean;

  private header = Header;

  private isMobile = false;

  private warningModal: boolean = false;

  private attendanceId: string = '';

  private closeWarningModal(): void {
    this.warningModal = false;
  }

  private openWarningModal(id: string): void {
    this.attendanceId = id;
    this.warningModal = true;
  }

  private updateTable(pagination: any): void {
    this.$emit('update', pagination);
  }

  private onResize() {
    this.isMobile = MobileHandler.isMobile();
  }

  private select(value: string): void {
    this.$router.push({
      path: `/attendance/${value}/`,
    })
  }

  private deleteAttendance() {
    this.closeWarningModal();
    this.loading = true;

    AttendanceService.deleteAttendance(this.attendanceId).then(() => {
      VbSnackbarService.showSuccess('Atendimento excluído com sucesso!');
      this.$emit('refresh');
    }).catch((err: any) => {
      VbSnackbarService.handleHttpError(err);
    }).finally(() => {
      this.loading = false;
    });
  }
}

