export default class PersonalDataFilter {
  city: string;

  state: string;

  gender: any;

  maritalStatus: any;

  hasChildren: boolean;

  businessUnit: string;

  workplace: string;

  active: boolean = true;

  concludedForm: boolean;

  constructor(obj: any) {
    this.city = obj.city ? obj.city.id : '';
    this.state = obj.state ? obj.state.id : '';
    this.gender = obj.gender || '';
    this.maritalStatus = obj.maritalStatus || '';
    this.hasChildren = obj.hasChildren || '';
    this.workplace = obj.workplace || '';
    this.businessUnit = obj.businessUnit || '';
    this.active = obj.active || '';
    this.concludedForm = obj.concludedForm || '';
  }

  public static of(obj: any): PersonalDataFilter {
    return new PersonalDataFilter(obj || {})
  }

  public static ofList(obj: any[]): PersonalDataFilter[] {
    return obj.map(this.of);
  }
}
