import PlanTypes from '@/views/employees/profile/tab-components/score/health-plan-type.json'
import { FixedAgreement } from '@/views/employees/profile/tab-components/score/score-utils';
import Agreement from './agreement.entity';
import ScoreDependentBenefits from './score-dependent-benefits';
import { Currency } from './professional-data.entity';

const formatBoolean = (value: boolean): string => (value ? 'Sim' : 'Não')
export default class ScoreList {
  id: string;

  previousScoreId: string;

  employee: string;

  manager: string;

  previousScoreResponsible: string;

  countDate: string;

  previousScoreCountDate: string;

  status: string;

  previousScoreStatus: string;

  businessUnit: string;

  validationDate: string;

  previousScoreValidationDate: string;

  dateToCount: string;

  previousScoreCompetence: string;

  healthPlanEmployee: boolean;

  previousScoreHealthPlanEmployee: boolean;

  healthPlanEmployeePayedByDB1: boolean;

  previousScoreHealthPlanEmployeePayedByDB1: boolean;

  dentalPlanEmployee: boolean;

  previousScoreDentalPlanEmployee: boolean;

  dentalPlanEmployeePayedByDB1: boolean;

  previousScoreDentalPlanEmployeePayedByDB1: boolean;

  lifeInsuranceEmployee: boolean;

  previousScoreLifeInsuranceEmployee: boolean;

  benefitObservation: string;

  previousScoreBenefitObservation: string;

  observation: string;

  previousScoreObservation: string

  planType: string;

  previousScorePlanType: string;

  agreements: Agreement[];

  dependentBenefits: ScoreDependentBenefits[];

  previousScoreDependentBenefits: ScoreDependentBenefits[];

  currency: Currency;

  constructor(obj: any) {
    this.id = obj.id || '';
    this.previousScoreId = obj.previousScoreId || '';
    this.employee = obj.employee || '';
    this.manager = obj.responsible || '';
    this.previousScoreResponsible = obj.previousScoreResponsible || '';
    this.countDate = obj.countDate || '';
    this.previousScoreCountDate = obj.previousScoreCountDate || '';
    this.status = obj.status || '';
    this.previousScoreStatus = obj.previousScoreStatus || '';
    this.businessUnit = obj.businessUnit || '';
    this.validationDate = obj.validationDate || '';
    this.previousScoreValidationDate = obj.previousScoreValidationDate || '';
    this.dateToCount = obj.dateToCount || '';
    this.previousScoreCompetence = obj.previousScoreCompetence || '';
    this.healthPlanEmployee = obj.healthPlanEmployee || '';
    this.previousScoreHealthPlanEmployee = obj.previousScoreHealthPlanEmployee || '';
    this.healthPlanEmployeePayedByDB1 = obj.healthPlanEmployeePayedByDB1 || '';
    this.previousScoreHealthPlanEmployeePayedByDB1 = obj.previousScoreHealthPlanEmployeePayedByDB1 || '';
    this.dentalPlanEmployee = obj.dentalPlanEmployee || '';
    this.previousScoreDentalPlanEmployee = obj.previousScoreDentalPlanEmployee || '';
    this.dentalPlanEmployeePayedByDB1 = obj.dentalPlanEmployeePayedByDB1 || '';
    this.previousScoreDentalPlanEmployeePayedByDB1 = obj.previousScoreDentalPlanEmployeePayedByDB1 || '';
    this.lifeInsuranceEmployee = obj.lifeInsuranceEmployee || '';
    this.previousScoreLifeInsuranceEmployee = obj.previousScoreLifeInsuranceEmployee || '';
    this.benefitObservation = obj.benefitObservation || '';
    this.previousScoreBenefitObservation = obj.previousScoreBenefitObservation || '';
    this.observation = obj.observation || '';
    this.previousScoreObservation = obj.previousScoreObservation || '';
    this.planType = obj.planType || '';
    this.previousScorePlanType = obj.previousScorePlanType || '';
    this.agreements = Agreement.ofList(obj.agreements);
    this.dependentBenefits = ScoreDependentBenefits.ofList(obj.dependentBenefits);
    this.previousScoreDependentBenefits = obj.previousScoreDependentBenefits
      ? ScoreDependentBenefits.ofList(obj.previousScoreDependentBenefits) : [];
    this.currency = obj.currency || '';
  }

  public static of(obj: any): ScoreList {
    return new ScoreList(obj || {})
  }

  public static ofList(obj: any[]): ScoreList[] {
    return obj.map(this.of);
  }

  public get roleAgreement(): Agreement {
    return this.agreements.find(el => el.description === FixedAgreement.ROLE)!!
  }

  public get seniorityAgreement(): Agreement {
    return this.agreements.find(el => el.description === FixedAgreement.SENIORITY)!!
  }

  public get salaryAgreement(): Agreement {
    return this.agreements.find(el => el.description === FixedAgreement.SALARY_IN_CLT)!!
  }

  public get duczAgreement(): Agreement {
    return this.agreements.find(el => el.description === FixedAgreement.DUCZ)!!
  }

  public get duczSyndicateAgreement(): Agreement {
    return this.agreements
      .find(el => el.description === FixedAgreement.DUCZ_SYNDICATE_FREE)!!
  }

  public get educationSubsidyAssistence(): Agreement {
    return this.agreements
      .find(el => el.description === FixedAgreement.EDUCATION_SUBSIDY_ASSISTANCE)!!
  }

  public get totalAgreement(): Agreement {
    return this.agreements
      .find(el => el.description === FixedAgreement.TOTAL)!!
  }

  public get hasDependentBenefits(): string {
    return this.dependentBenefits.length > 0 ? 'Sim' : 'Não';
  }

  public get hasPreviousScoreDependentBenefits(): string {
    return this.previousScoreDependentBenefits.length > 0 ? 'Sim' : 'Não';
  }

  public get formattedPlanType(): string {
    if (!this.planType) return ''
    const matchingPlanType = PlanTypes.find(it => it.value === this.planType)
    return matchingPlanType ? matchingPlanType.description : ''
  }

  public get formattedPreviousScorePlanType(): string {
    if (!this.planType) return ''
    const matchingPlanType = PlanTypes.find(it => it.value === this.previousScorePlanType)
    return matchingPlanType ? matchingPlanType.description : ''
  }

  public get hasHealthPlanEmployee(): string {
    return formatBoolean(this.healthPlanEmployee);
  }

  public get hasPreviousHealthPlanEmployee(): string {
    return formatBoolean(this.previousScoreHealthPlanEmployee);
  }

  public get hasHealthPlanEmployeePayedByDB1(): string {
    return formatBoolean(this.healthPlanEmployeePayedByDB1);
  }

  public get hasPreviousHealthPlanEmployeePayedByDB1(): string {
    return formatBoolean(this.previousScoreHealthPlanEmployeePayedByDB1);
  }

  public get hasDentalPlanEmployee(): string {
    return formatBoolean(this.dentalPlanEmployee);
  }

  public get hasPreviousScoreDentalPlanEmployee(): string {
    return formatBoolean(this.previousScoreDentalPlanEmployee);
  }

  public get hasDentalPlanEmployeePayedByDB1(): string {
    return formatBoolean(this.dentalPlanEmployeePayedByDB1);
  }

  public get hasPreviousScoreDentalPlanEmployeePayedByDB1(): string {
    return formatBoolean(this.previousScoreDentalPlanEmployeePayedByDB1);
  }

  public get hasLifeInsuranceEmployee(): string {
    return formatBoolean(this.lifeInsuranceEmployee);
  }

  public get hasPreviousScoreLifeInsuranceEmployee(): string {
    return formatBoolean(this.previousScoreLifeInsuranceEmployee);
  }

  public get hasVariableAgreements(): string {
    const fixedAgreements = ['Cargo', 'Senioridade', 'Salário em CLT', 'Ducz', 'Total'];
    const variableAgreement: boolean = this.agreements
      .some(agreement => !fixedAgreements.includes(agreement.description));
    return formatBoolean(variableAgreement);
  }
}
