





















































































































































































import { Vue, Component, Prop } from 'vue-property-decorator';
import { VbSnackbarService } from 'bag-of-holding-library';
import EmployeeService from '@/domain/employees/service/employee.service';
import LabelSlot from '@/shared/slots/label-slot.component.vue';
import TalentLevelLabel from '@/shared/talent-level-label.component.vue';
import ProfessionalData, { Currency } from '@/domain/employees/entity/professional-data.entity';
import currencyTypes from '@/views/employees/profile/tab-components/score/currency-type.json';
import Country from '@/domain/countries/entity/country.entity';
import CountrySelect from '@/shared/country-select.component.vue';
import ContractTypeComponent from '@/shared/contract-type/contract-type.component.vue';
import AuthorityStore from '@/domain/security/authority.store';
import ProfessionalDataService from '@/domain/employees/service/professional-data.service';

@Component({
  components: {
    LabelSlot,
    TalentLevelLabel,
    CountrySelect,
    ContractTypeComponent,
  },
})
export default class ProfessionalDataComponent extends Vue {
  @Prop({
    type: String,
    required: true,
  }) employeeId!: string;

  @Prop() hasUpdateProfessionalData!: boolean;

  professionalData: ProfessionalData = new ProfessionalData();

  loadingProfessionalDataGet: boolean = false;

  loadingProfessionalDataPost: boolean = false;

  currencyTypes: Currency[] = currencyTypes;

  currencyName!: string;

  countries: Country[] = [];

  hasUpdateContractType: boolean = false;

  booleaSelectOptions = [
    {
      text: 'Sim',
      value: true,
    },
    {
      text: 'Não',
      value: false,
    },
  ]

  showUnlockBenefitPercentMessage: boolean = false;

  hasUnlockBenefitPercent: boolean = false;

  saveUnlockBenefitPercentLoading: boolean = false;

  saveProfessionalData(): void {
    this.loadingProfessionalDataPost = true;
    EmployeeService.postProfessionalData(this.professionalData)
      .then(() => {
        VbSnackbarService.showSuccess('Dados salvos com sucesso!');
      })
      .catch(VbSnackbarService.handleHttpError)
      .finally(() => {
        this.loadingProfessionalDataPost = false;
      });
  }

  loadProfessionalData(): void {
    this.loadingProfessionalDataGet = true;
    EmployeeService.getProfessionalData(this.employeeId)
      .then((professionalData: ProfessionalData) => {
        this.professionalData = professionalData;
        this.currencyName = professionalData.currency.name;
      })
      .catch(VbSnackbarService.handleHttpError)
      .finally(() => {
        this.loadingProfessionalDataGet = false;
      });
  }

  created() {
    this.verifyAuthority();
    this.loadProfessionalData();
  }

  updateCountry(countryId: string) {
    this.professionalData.contractCountryId = countryId;
  }

  async verifyAuthority() {
    AuthorityStore.fetchedAuthority().then((authority) => {
      this.hasUpdateContractType = authority.hasUpdateContractType()
      this.hasUnlockBenefitPercent = authority.hasUnlockBenefitPercent()
    })
  }

  handleUnlockBenefitPercent() {
    this.saveUnlockBenefitPercentLoading = true;
    ProfessionalDataService
      .unlockBenefitPercent(this.employeeId, this.professionalData.unlockBenefitPercent)
      .then(() => VbSnackbarService.showSuccess('Informação salva com sucesso!'))
      .catch((error) => {
        this.professionalData.unlockBenefitPercent = !this.professionalData.unlockBenefitPercent;
        VbSnackbarService.showError(error.response.data.messages.toString());
      }).finally(() => {
        this.saveUnlockBenefitPercentLoading = false;
      })
  }
}
