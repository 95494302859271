














import {
  Mixins, Component,
} from 'vue-property-decorator';
import { VbSnackbarService } from 'bag-of-holding-library';
import Modal from '@/shared/modal/modal.component.vue';
import ModalMixin from '@/shared/modal/modal.mixin';
import ModalOptions from '@/shared/modal/modal-options.entity';
import ActivityService from '@/domain/schedule/activitie/activity.service';
import ActivityReassign from '@/domain/schedule/activitie/activity-reassign.entity';
import FormReassignActivity from './form-reassign-activity.component.vue';


@Component({
  components: {
    modal: Modal,
    'form-reassign-activity': FormReassignActivity,
  },
})
export default class ModalReassignActivity extends Mixins(ModalMixin) {
  private newActivity: ActivityReassign = ActivityReassign.of({});

  private activityId: string = '';

  private loadingSave: boolean = false;

  private get reassignModalOptions(): ModalOptions {
    return {
      title: 'Alterar Data Atividade',
      description: 'Ao clicar em salvar, a alteração será imediatamente concluída. Antes de salvar,<strong> verifique se a informação está correta</strong>',
      showOk: true,
      disabledOk: this.disableSaveButton,
      labelOk: 'Salvar',
      showCancel: true,
      labelCancel: 'Cancelar',
      width: 700,
      loadingOk: this.loadingSave,
    };
  }

  private get disableSaveButton(): boolean {
    return this.newActivity.newDate === '' || this.newActivity.reason === '';
  }

  private updateActivityId(id: string): void {
    this.activityId = id;
  }

  private updateActivityToReassign(newActivity: ActivityReassign): void {
    this.newActivity = newActivity;
  }

  private ok(): void {
    if (this.newActivity.newDate && this.newActivity.reason) {
      this.loadingSave = true;
      ActivityService.reassignActivity(this.activityId, this.newActivity)
        .then(() => this.closeModal())
        .catch(error => VbSnackbarService.handleHttpError(error))
        .finally(() => {
          this.loadingSave = false;
        });
    }
  }
}
