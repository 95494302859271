import axios from 'axios';
import GodfatherFilter from '../entity/godfather-filter.entity';
import FilteredGodfather from '../entity/filtered-godfather.entity';
import Godfather from '../entity/godfather.entity';
import GodfatherRequest from '../entity/godfather-request.entity';


export default class GodfatherService {
  public static exportGodfather(filter: GodfatherFilter): Promise<FilteredGodfather[]> {
    return axios.get('/api/employees/godfather/export', {
      params: {
        employee: filter.employee,
        role: filter.role,
        manager: filter.manager,
        businessUnit: filter.businessUnit,
        initialTrainingPeriod: filter.initialTrainingDate,
        finalTrainingPeriod: filter.finalTrainingDate,
        needTraining: filter.needTraining,
        status: filter.status,
      },
    })
      .then((resp: any) => FilteredGodfather.ofList(resp.data));
  }

  public static updateGodfather(godfather: GodfatherRequest, employeeId: string):
    Promise<Godfather> {
    return axios.post(`/api/employees/${employeeId}/godfather`, godfather)
      .then((resp: any) => resp)
  }

  public static updateNeedTrainingGodfather(needTraining: Boolean, employeeId: string):
    Promise<Godfather> {
    return axios.patch(`/api/employees/${employeeId}/godfather?needTraining=${needTraining}`)
      .then((resp: any) => resp)
  }
}
