

























































import { Vue, Component, Prop } from 'vue-property-decorator';
import AreaKeyItem from '@/domain/drd/entity/area-key/area-key-item.entity';
import LabelSlot from '@/shared/slots/label-slot.component.vue';
import Rules from '@/shared/rules/rules';
import ActiveCheckboxField from '@/views/drd/form/tabs/common-components/active-checkbox-field.component.vue';
import FieldsArea from '@/views/drd/form/tabs/common-components/fields-area.component.vue';
import SeniorityLevelComponent from '@/views/drd/commons/seniority-level.component.vue';

@Component({
  components: {
    ActiveCheckboxField,
    FieldsArea,
    LabelSlot,
    SeniorityLevelComponent,
  },
})
export default class AreaKeyItemComponent extends Vue {
  @Prop({
    type: AreaKeyItem,
    required: false,
  })
  private areaKeyItem!: AreaKeyItem;

  private rules: Rules = new Rules();

  public updateField(field: string, value: any): void {
    this.$emit(`update:${field}`, value);
  }

  public deleteAreaKeyItem(): void {
    this.$emit('deleteAreaKeyItem');
  }
}
