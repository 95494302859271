import BusinessUnitEmployeeAmount from './business-unit-employee-amount.entity';

export default class EmployeeAmount {
  amount: number;

  businessUnitEmployeeAmountList: BusinessUnitEmployeeAmount[];

  constructor(obj: any = {}) {
    this.amount = obj.amount || 0;
    this.businessUnitEmployeeAmountList = BusinessUnitEmployeeAmount.ofList(
      obj.businessUnitEmployeeAmountList,
    ) || [];
  }

  public static of(value: any = {}): EmployeeAmount {
    return new EmployeeAmount(value || {});
  }
}
