





































































































































































import { Vue, Component, Prop } from 'vue-property-decorator';
import LabelSlot from '@/shared/slots/label-slot.component.vue';
import Filter from '@/domain/employees/entity/filter.entity';
import SelectionComponent from '@/shared/fields/selection.componet.vue';
import Skill from '@/domain/employees/entity/skill.entity';
import SkillType from '@/domain/employees/entity/skill-type.entity';
import SkillStatus from '@/domain/employees/entity/skill-type.entity copy';

@Component({
  components: {
    LabelSlot,
    SelectionComponent,
  },
})
export default class FilterComponent extends Vue {
  @Prop() hardSkils!: Skill[];

  @Prop() frameworks!: Skill[];

  @Prop() businessExps!: Skill[];

  @Prop() certifications!: Skill[];

  @Prop() lastFilter?: Filter;

  selectItems: any[] = [{
    text: 'APPROVED',
    value: SkillStatus.APPROVED,
  },
  {
    text: 'PENDING',
    value: SkillStatus.PENDING,
  },
  {
    text: 'REJECTED',
    value: SkillStatus.REJECTED,
  }];

  filter: Filter = new Filter({});

  showCustomFilter(): boolean {
    return (this.filter.hardSkill && this.filter.hardSkill.length) > 0
        || (this.filter.frameworks && this.filter.frameworks.length > 0)
        || (this.filter.businessExps && this.filter.businessExps.length > 0)
        || (this.filter.certifications && this.filter.certifications.length > 0);
  }

  created() {
    this.getLastFilter();
    this.sendFilter();
    this.$forceUpdate();
  }

  getLastFilter() {
    if (this.lastFilter) {
      this.filter = this.lastFilter;
    }
  }

  sendFilter() {
    this.$emit('filter', this.filter);
  }

  findHardSkillByType(value: string) {
    this.filter.hardSkillInput = value;
    this.$emit('findHardSkillByType', value);
  }

  findFrameworkByType(value: string) {
    this.filter.frameworkInput = value;
    this.$emit('findFrameworkByType', value);
  }

  findBusinessExpsByType(value: string) {
    this.filter.businessExpInput = value;
    this.$emit('findBusinessExpsByType', value);
  }

  findCertificationByType(value: string) {
    this.filter.certificationInput = value;
    this.$emit('findCertificationByType', value);
  }

  getName(items, id) {
    const itemName = items.find(i => i.id === id);
    return itemName ? itemName.description : '';
  }

  removeItem(prop, index) {
    this.filter[prop].splice(index, 1);
    this.sendFilter();
  }

  clearFilters() {
    this.filter.hardSkill.splice(0, this.filter.hardSkill.length);
    this.filter.frameworks.splice(0, this.filter.frameworks.length);
    this.filter.businessExps.splice(0, this.filter.businessExps.length);
    this.filter.certifications.splice(0, this.filter.certifications.length);
    this.sendFilter();
  }
}
