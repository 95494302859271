var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"filter"},[_c('label-slot',{staticClass:"filter__select",attrs:{"label":'Papel'}},[_c('v-autocomplete',{attrs:{"items":_vm.assignments,"item-text":_vm.getAssignmentText,"placeholder":"Digite o nome...","no-data-text":"Informe o nome do papel","outlined":"","dense":"","item-value":"id","clearable":"","multiple":"","chips":"","small-chips":"","deletable-chips":"","search-input":_vm.filter.assignmentInput,"hide-details":""},on:{"input":_vm.sendFilter,"update:search-input":_vm.findAssignments,"click:clear":_vm.sendFilter},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('selection-component',{attrs:{"length":_vm.filter.assignment.length,"item":item,"index":index,"chipField":'description'}})]}}]),model:{value:(_vm.filter.assignment),callback:function ($$v) {_vm.$set(_vm.filter, "assignment", $$v)},expression:"filter.assignment"}})],1),_c('label-slot',{staticClass:"filter__select",attrs:{"label":'Cargo'}},[_c('v-autocomplete',{attrs:{"placeholder":"Selecione o cargo...","items":_vm.roles,"locale":'pt-br',"item-text":_vm.getRoleText,"outlined":"","dense":"","item-value":"id","clearable":"","multiple":"","chips":"","small-chips":"","deletable-chips":"","search-input":_vm.filter.roleInput,"hide-details":""},on:{"input":_vm.sendFilter,"update:search-input":_vm.setRoleInput,"click:clear":_vm.sendFilter},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('selection-component',{attrs:{"length":_vm.filter.role.length,"item":item,"index":index}})]}}]),model:{value:(_vm.filter.role),callback:function ($$v) {_vm.$set(_vm.filter, "role", $$v)},expression:"filter.role"}})],1),_c('label-slot',{staticClass:"filter__select",attrs:{"label":'Ecossistema'}},[_c('v-autocomplete',{attrs:{"items":_vm.ecosystems,"item-text":"description","placeholder":"Selecione o ecossistema...","outlined":"","dense":"","item-value":"id","clearable":"","multiple":"","chips":"","small-chips":"","deletable-chips":"","search-input":_vm.filter.ecosystemInput,"hide-details":""},on:{"input":_vm.sendFilter,"update:search-input":_vm.setEcossystemInput,"click:clear":_vm.sendFilter},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('selection-component',{attrs:{"length":_vm.filter.ecosystem.length,"item":item,"index":index,"chipField":'description'}})]}}]),model:{value:(_vm.filter.ecosystem),callback:function ($$v) {_vm.$set(_vm.filter, "ecosystem", $$v)},expression:"filter.ecosystem"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }