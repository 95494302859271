



















import { Vue, Component, Prop } from 'vue-property-decorator';
import LabelSlot from '@/shared/slots/label-slot.component.vue';
import Rules from '@/shared/rules/rules';

@Component({
  components: {
    LabelSlot,
  },
})
export default class ProjectInput extends Vue {
  @Prop({
    type: String,
    default: 'Projeto',
  }) label!: string;

  @Prop({
    type: String,
    required: true,
  }) project!: string;

  @Prop() rules!: Rules;

  update(value: string) {
    this.$emit('update', value);
  }
}
