








































import {
  Vue, Prop, Component, Emit,
} from 'vue-property-decorator';
import { VbSnackbarService } from 'bag-of-holding-library';
import ReadOnlyTextField from '@/shared/fields/readonly-text-field.component.vue';
import TextArea from '@/shared/fields/text-area.component.vue';
import EmployeeRestartSchedule from '@/domain/schedule/employee/employee-restart-schedule.entity';
import EmployeeService from '@/domain/schedule/employee/employee.service';
import DateFormat from '@/shared/utils/date-format';

@Component({
  components: {
    'text-field': ReadOnlyTextField,
    'text-area': TextArea,
  },
})
export default class FormRestartSchedule extends Vue {
  @Prop({
    required: true,
  })
  private employeeId!: string;

  private employeeRestartScheduleInfo: EmployeeRestartSchedule = new EmployeeRestartSchedule();

  created() {
    this.fetchEmployeeRestartScheduleInfo();
  }

  private fetchEmployeeRestartScheduleInfo(): void {
    EmployeeService.getEmployeeRestartScheduleInfo(this.employeeId)
      .then((response: any) => this.employeeRestartScheduleInfo = response)
      .catch((error: any) => VbSnackbarService.handleHttpError(error));
  }

  @Emit('typeReason')
  private typeReason(reason: string) {
    return reason;
  }

  private get formattedAdmissionDate(): string {
    return DateFormat.fullDate(this.employeeRestartScheduleInfo.admissionDateValue);
  }

  private get formattedApprovalDate(): string {
    return DateFormat.monthYear(this.employeeRestartScheduleInfo.approvalDateValue);
  }

  private get firstNameLastName(): string {
    return this.employeeRestartScheduleInfo.firstNameLastName;
  }
}
