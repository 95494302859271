export default class UpdateAtribute {
  id?: string;

  description: string;

  code?: string;

  name?: string;

  defaultOperationModality?: UpdateAtribute;

  assignment: string;

  ecosystem: string;

  active: boolean;

  constructor(obj: any) {
    this.description = obj.description || '';
    this.id = obj.id || '';
    this.code = obj.code || '';
    this.name = obj.name || '';
    this.defaultOperationModality = obj.defaultOperationModality || '';
    this.assignment = obj.assignment || '';
    this.ecosystem = obj.ecosystem || '';
    this.active = obj.active || false;
  }

  public static of(obj: any): UpdateAtribute {
    return new UpdateAtribute(obj || {});
  }

  public static ofList(obj: any[]): UpdateAtribute[] {
    return obj.map(this.of);
  }
}
