import Indicators from '@/domain/drd/entity/indicators.entity';
import DateFormat from '@/shared/utils/date-format';
import KeyArea from './key-area.entity';

export default class Drd {
  assignmentId: string;

  assignmentDescription: string;

  assignmentObjective: string;

  keyAreas: KeyArea[];

  indicators: Indicators[];

  drdModifiedDate: string;

  drdCreatedDate: string;

  constructor(obj: any = {}) {
    this.assignmentId = obj.assignmentId || '';
    this.assignmentDescription = obj.assignmentDescription || '';
    this.assignmentObjective = obj.assignmentObjective || '';
    this.keyAreas = obj.keyAreas
      ? obj.keyAreas.map(it => new KeyArea(it))
      : [];
    this.indicators = obj.indicators
      ? obj.indicators.map(it => new Indicators(it))
      : [];
    this.drdModifiedDate = DateFormat.dateTimeFormated(obj.drdModifiedDate) || '-';
    this.drdCreatedDate = DateFormat.dateTimeFormated(obj.drdCreatedDate) || '-';
  }

  get keyAreasActive() {
    return this.keyAreas.filter(it => it.active);
  }

  get allIndicators() {
    return this.indicators.concat(...this.keyAreas.map(it => it.indicators))
  }
}
