







































import { Vue, Prop, Component } from 'vue-property-decorator';
import LabelSlot from '@/shared/slots/label-slot.component.vue';
import SelectionComponent from '@/shared/fields/selection.componet.vue';

@Component({
  components: {
    LabelSlot,
    SelectionComponent,
  },
})
export default class AutocompleteComponent extends Vue {
  @Prop({
    type: String,
    required: true,
  }) label!: string;

  @Prop() items!: any[];

  @Prop({
    required: true,
  }) value!: any[] | any;

  @Prop({
    type: String,
    required: true,
  }) placeholder!: string;

  @Prop({
    type: String,
    required: true,
  }) noDataText!: string;

  @Prop({
    type: Boolean,
    default: true,
  }) multiple!: boolean;

  @Prop({
    type: String,
    default: 'id',
  }) itemValue!: string

  @Prop({
    type: String,
    default: 'name',
  }) itemText!: string;

  @Prop({
    type: String,
    default: 'name',
  }) chipField!: string;

  @Prop({
    type: Boolean,
    default: false,
  }) loading!: boolean;

  @Prop({
    type: Boolean,
    default: true,
  }) hideDetails!: boolean;

  @Prop({
    type: String,
    default: '',
  }) hintMessage!: string;

  @Prop({
    type: Boolean,
    default: false,
  }) disabled!: boolean;

  searchInput: string = '';

  input(value: any) {
    this.$emit('input', value);
  }

  updateSearchInput(value: any) {
    this.$emit('searchInput', value);
  }

  clear() {
    this.$emit('clear');
  }
}
