var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('modal-warning',{attrs:{"value":_vm.showDeleteConfirmationDialog,"question":"Deseja mesmo inativar esse cargo?"},on:{"ok":_vm.deleteItem,"cancel":_vm.closeDeleteConfirmationDialog}}),_c('v-data-table',{staticClass:"role-list__data-table",attrs:{"items":_vm.roles,"headers":_vm.headers,"loading":_vm.loading,"page":_vm.page.page,"sort-by":_vm.page.sortBy,"sort-desc":_vm.page.sortDesc,"items-per-page":_vm.page.itemsPerPage,"hide-default-header":_vm.isMobile,"page-count":_vm.page.pageCount,"hide-default-footer":"","no-data-text":"Nenhum cargo foi cadastrado ainda","loading-text":"Carregando..."},on:{"update:sortBy":function($event){return _vm.$set(_vm.page, "sortBy", $event)},"update:sort-by":[function($event){return _vm.$set(_vm.page, "sortBy", $event)},_vm.updateSortBy],"update:sortDesc":function($event){return _vm.$set(_vm.page, "sortDesc", $event)},"update:sort-desc":[function($event){return _vm.$set(_vm.page, "sortDesc", $event)},_vm.updateSortDesc],"update:itemsPerPage":function($event){return _vm.$set(_vm.page, "itemsPerPage", $event)},"update:items-per-page":function($event){return _vm.$set(_vm.page, "itemsPerPage", $event)},"update:pageCount":function($event){return _vm.$set(_vm.page, "pageCount", $event)},"update:page-count":function($event){return _vm.$set(_vm.page, "pageCount", $event)},"page-count":function($event){_vm.page.pageCount=$event}},scopedSlots:_vm._u([{key:"item.assignments",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatAssignments(item.assignments))+" ")]}},{key:"item.active",fn:function(ref){
var item = ref.item;
return [_c('p',{staticClass:"text-center mb-0 mr-4"},[_vm._v(" "+_vm._s(item.active ? 'Sim' : 'Não')+" ")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"role-list__actions-container"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"disabled":!_vm.readRoles,"color":"primary","icon":""},on:{"click":function($event){return _vm.editItem(item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-pencil-outline")])],1)]}}],null,true)},[_c('span',[_vm._v("Editar")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-1",attrs:{"color":"error","icon":"","disabled":_vm.checkInactiveItem(item)},on:{"click":function($event){return _vm.openDeleteDialog(item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-cancel")])],1)]}}],null,true)},[_c('span',[_vm._v("Inativar")])])],1)]}}],null,true)}),_c('pagination-component',{staticClass:"mt-8 ml-2",attrs:{"pagination":_vm.page},on:{"input":_vm.updateTable}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }