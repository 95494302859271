






















































import {
  Vue, Prop, Component, Emit,
} from 'vue-property-decorator';
import { VbLoadingService, VbSnackbarService, VbTooltipSlot } from 'bag-of-holding-library';
import ActivityFile from '@/domain/schedule/activitie/activity-file.entity';
import LabelSlot from '@/shared/slots/label-slot.component.vue';
import ActivityService from '@/domain/schedule/activitie/activity.service';
import FilePickerButton from '@/shared/buttons/file-picker-button.component.vue'
import ActivityDetailed from '@/domain/schedule/activitie/activity-detailed.entity';

@Component({
  components: { LabelSlot, VbTooltipSlot, FilePickerButton },
})
export default class ManageActivityFileList extends Vue {
  @Prop({
    required: true,
  })
  files!: ActivityFile[];

  @Prop({
    required: true,
  })
  activity!: ActivityDetailed;

  @Prop({
    type: Boolean,
    default: false,
  })
  isUploadFiles!: boolean;

  disableDelete: boolean = false;

  filePickerKey = 0;

  isUploading: boolean = false;

  pdiRevisonFilled: boolean = false;

  haveFiles() {
    return this.files.length !== 0;
  }

  deleteFile(fileId: string) {
    VbLoadingService.show()
    this.disableDelete = true;
    ActivityService.deleteFile(this.activity.id, fileId)
      .then((response: any) => {
        this.$emit('deleteFile', fileId)
        VbSnackbarService.showSuccess('Arquivo deletado com sucesso')
      })
      .catch((err: any) => VbSnackbarService.handleHttpError(err))
      .finally(() => {
        VbLoadingService.hide();
        this.disableDelete = false;
      })
  }

  getFile(fileId: string) {
    VbLoadingService.show();
    ActivityService.getFile(this.activity.id, fileId)
      .then((response: any) => {
        const blob = new Blob([response.data], { type: '*/*' });
        const name = response.headers['file-name'];
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.target = '_blank';
        link.download = name;
        link.click();
      })
      .catch((error: any) => { VbSnackbarService.handleHttpError(error); })
      .finally(() => { VbLoadingService.hide(); })
  }

  get filePickerTooltip(): string {
    return this.activity.hasResult()
      ? 'Fazer upload de arquivo'
      : 'Favor preencher campo Resultado e Nível de talento'
  }

  onFileSelected(file: any) {
    VbLoadingService.show()
    this.isUploading = true;
    ActivityService.saveFile(this.activity.id, file)
      .then((response: ActivityFile) => {
        this.activity.files.push(response)
        const message = this.pdiRevisonFilled ? 'Arquivo salvo com sucesso' : 'Arquivo salvo com sucesso, mas a revisão de PDI ainda não foi realizada'
        VbSnackbarService.showSuccess(message)
        this.filePickerKey += 1;
      })
      .catch((err: any) => VbSnackbarService.handleHttpError(err))
      .finally(() => {
        VbLoadingService.hide();
        this.isUploading = false;
      })
  }
}
