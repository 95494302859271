


















































































import {
  Vue, Component, Prop,
} from 'vue-property-decorator';
import DatePickerField from '@/shared/date-picker/date-picker-field.component.vue'
import CardTitle from '@/shared/card/card-title.component.vue';
import LabelSlot from '@/shared/slots/label-slot.component.vue';
import splitContent from '@/shared/directive/split-content';
import DateHandler from '@/shared/utils/date-format';
import Attendance from '@/domain/attendance/entity/attendance.entity';
import AuthorityStore from '@/domain/security/authority.store';
import Rules from '@/shared/rules/rules';

@Component({
  components: { LabelSlot, DatePickerField, CardTitle },
  filters: {
    splitContent,
    formatDate(value: string) {
      return DateHandler.timestampToLocal(value.split('T')[0]);
    },
  },
})
export default class AttendanceRegularComponent extends Vue {
  @Prop() attendance!: Attendance;

  @Prop() isRegularAttendance!: boolean

  isAdministrator: boolean = false;

  private loading = false;

  rules: Rules = new Rules();

  private isCriticalTypes = [
    {
      value: false,
      description: 'Não',
    },
    {
      value: true,
      description: 'Sim',
    },
  ];

  private created() {
    this.initIsAdministrator();
  }

  initIsAdministrator() {
    AuthorityStore.isAdministrator()
      .then((response: any) => this.isAdministrator = response);
  }
}

