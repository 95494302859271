export default class SkillFilter {
  what: string;

  types: string[];

  keyAreaName: string;

  assignments: string[];

  groups: string[];

  constructor(obj: any = {}) {
    this.what = obj.what || '';
    this.types = obj.types || [];
    this.keyAreaName = obj.keyAreaName || '';
    this.assignments = obj.assignments || [];
    this.groups = obj.groups || [];
  }

  get hasWhat() {
    return this.what.length > 0;
  }

  get hasTypes() {
    return this.types.length > 0;
  }

  get hasKeyAreaName() {
    return this.keyAreaName.length > 0;
  }

  get hasAssignments() {
    return this.assignments.length > 0;
  }

  get hasGroups() {
    return this.groups.length > 0;
  }
}
