












































































































import { Vue, Component, Prop } from 'vue-property-decorator';
import { VbSnackbarService, VbTooltipSlot } from 'bag-of-holding-library';
import ModalComponent from '@/shared/modal/modal.component.vue';
import LabelSlot from '@/shared/slots/label-slot.component.vue';
import Rules from '@/shared/rules/rules';
import DatePickerField from '@/shared/date-picker/date-picker-field.component.vue';
import Attendance from '@/domain/attendance/entity/attendance.entity';
import ModalOptions from '@/shared/modal/modal-options.entity';
import SkillService from '@/domain/employees/service/skill.service';
import Skill from '@/domain/employees/entity/skill.entity';
import SkillType from '@/domain/employees/entity/skill-type.entity';
import SkillStatus from '@/domain/employees/entity/skill-type.entity copy';

@Component({
  components: {
    ModalComponent,
    LabelSlot,
    VbTooltipSlot,
    DatePickerField,
  },
})
export default class ResignationModal extends Vue {
  @Prop() show!: boolean;

  @Prop() showResignationModal!: boolean;

  private skill: Skill = new Skill();

  private attendance = Attendance.of({});

  valid: boolean = false;

  private rules: Rules = new Rules();

  role: any = '';

  loading = false;

  selectedIndex = 0;

  private selectSkills: any[] = [{
    text: 'Hard Skills',
    value: SkillType.HARD_SKILLS,
  },
  {
    text: 'Frameworks / Metodologias',
    value: SkillType.FRAMEWORKS_OR_METHODOLOGIES,
  },
  {
    text: 'Experiência de negócios',
    value: SkillType.BUSINESS_EXPERIENCE,
  },
  {
    text: 'Certificações',
    value: SkillType.CERTIFICATIONS,
  }];

  private selectStatus: any[] = [{
    text: 'Aprovado',
    value: SkillStatus.APPROVED,
  },
  {
    text: 'Pendente',
    value: SkillStatus.PENDING,
  },
  {
    text: 'Rejeitado',
    value: SkillStatus.REJECTED,
  }];

  private get optionsHandled(): ModalOptions {
    return {
      title: 'Mapeamento de skills',
      titleSize: '25px',
      description: '',
      showOk: true,
      labelOk: 'Cadastrar',
      disabledOk: !this.valid || this.loading,
      okColor: 'tabBlue',
      showCancel: !this.loading,
      labelCancel: 'Cancelar',
      width: 880,
    };
  }

  async save() {
    const { form }: any = this.$refs;

    if (form.validate()) {
      this.createSkill();
    }
  }

  close() {
    if (!this.loading) {
      this.clear();
      this.$emit('closeRegisterSkill');
    }
  }

  clear() {
    this.role = '';
    this.skill.description = '';
    this.skill.observation = '';
  }

  selectButton(index) {
    this.selectedIndex = index;
  }

  createSkill() {
    this.loading = true;
    this.skill.active = true;
    SkillService.createNewSkill(this.skill)
      .then((resp: any) => {
        VbSnackbarService.showSuccess('Skill salva com sucesso');
      })
      .catch((err: any) => VbSnackbarService.handleHttpError(err))
      .finally(() => {
        this.loading = false;
        this.close();
      });
  }
}
