


















import { Vue, Component } from 'vue-property-decorator';
import CreateSkillModal from '../modal/create-skill-modal.component.vue';

@Component({
  components: {
    CreateSkillModal,
  },
})
export default class CreateSkillButton extends Vue {
  openModal: boolean = false;
}
