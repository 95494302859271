export default class StringHandler {
  public static splitName(name: string): string {
    if (name.length > 0) {
      const fullName = name.split(' ');
      const firstName = fullName[0];
      const lastName = fullName[fullName.length - 1];
      return `${firstName} ${lastName}`;
    }
    return '';
  }

  public static equals(first: String, second: String): boolean {
    return first.toLowerCase() === second.toLowerCase();
  }

  public static normalizeString(value: String) {
    return value.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase();
  }

  public static equalsNormalized(first: String, second: String): boolean {
    return this.equals(this.normalizeString(first), this.normalizeString(second));
  }
}
