var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pa-2"},[_c('v-row',[_c('v-col',{attrs:{"cols":"auto"}},[_c('v-tooltip',{attrs:{"color":_vm.levelsSelected.length === 0 ? 'error' : 'primary',"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({},on),[_c('v-btn',{attrs:{"color":"primary","disabled":_vm.levelsSelected.length === 0},on:{"click":_vm.openAddSkillModal}},[_c('v-icon',[_vm._v("mdi-plus")])],1)],1)]}}])},[_c('span',[_vm._v(" "+_vm._s(_vm.levelsSelected.length === 0 ? 'Selecione os níveis que deseja para as competências logo acima' : 'Adicionar competências')+" ")])])],1)],1),_c('v-expansion-panels',{attrs:{"multiple":""}},_vm._l((_vm.skillTypes),function(type,typeIndex){return _c('v-expansion-panel',{key:typeIndex,staticClass:"mt-2"},[_c('v-expansion-panel-header',[_vm._v(_vm._s(type.description))]),_c('v-expansion-panel-content',_vm._l((_vm.keyArea.filterSkillsByType(type.value)),function(skill,index){return _c('v-card',{key:index,class:("" + (skill.active ? 'mt-2' : 'disabled mt-2')),attrs:{"cols":"12"}},[_c('v-row',{staticClass:"px-4"},[_c('v-col',{attrs:{"cols":"12"}},[_c('label-slot',{attrs:{"label":skill.skill.whatPTBR}})],1)],1),_c('v-row',{staticClass:"px-4",attrs:{"align":"center"}},[_vm._l((skill.levels),function(skillLevel,skillLevelIndex){return _c('v-col',{key:skillLevelIndex,staticClass:"d-flex align-center",attrs:{"cols":"4"}},[_c('label-slot',{attrs:{"label":skillLevel.name}},[_c('v-select',{attrs:{"outlined":"","dense":"","items":_vm.getOptionsBySkillType(skill.skill.type),"item-text":"name","item-value":"value","disabled":!skill.active || !skillLevel.active},scopedSlots:_vm._u([{key:"append-outer",fn:function(){return [_c('div',[(skillLevel.isDeletable)?_c('v-btn',{staticClass:"ml-2",attrs:{"color":"error","icon":"","disabled":!skill.active},on:{"click":function($event){return _vm.removeLevel(skill, skillLevel)}}},[_c('v-icon',[_vm._v("mdi-delete")])],1):_c('div',[(!skillLevel.active)?_c('v-tooltip',{attrs:{"color":"success","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-2",attrs:{"color":"success","icon":"","disabled":!skill.active},on:{"click":function($event){return _vm.disableOrActiveLevel(skill, skillLevel)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-power")])],1)]}}],null,true)},[_c('span',[_vm._v("Ativar")])]):_c('v-tooltip',{attrs:{"color":"error","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-2",attrs:{"color":"error","icon":"","disabled":!skill.active},on:{"click":function($event){return _vm.disableOrActiveLevel(skill, skillLevel)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-cancel")])],1)]}}],null,true)},[_c('span',[_vm._v("Inativar")])])],1)],1)]},proxy:true}],null,true),model:{value:(skillLevel.expected),callback:function ($$v) {_vm.$set(skillLevel, "expected", $$v)},expression:"skillLevel.expected"}})],1)],1)}),(skill.active)?_c('v-col',{attrs:{"cols":"auto"}},[_c('v-menu',{attrs:{"offset-x":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"elevation":"0"}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-plus")]),_vm._v(" Nível ")],1)]}}],null,true)},[_c('v-list',_vm._l((_vm.levels),function(level){return _c('v-list-item',{key:level.id,attrs:{"link":"","disabled":!skill.active},on:{"click":function($event){return _vm.addLevel(level, skill)}}},[_c('v-list-item-title',[_vm._v(_vm._s(level.name))])],1)}),1)],1)],1):_vm._e()],2),_c('v-row',{staticClass:"px-4",attrs:{"no-gutters":"","align":"center","justify":"space-between"}},[_c('v-col',{attrs:{"cols":"1"}},[_c('label-slot',{attrs:{"label":"Ordem"}},[_c('v-text-field',{attrs:{"dense":"","outlined":"","type":"number","disabled":!skill.active},model:{value:(skill.order),callback:function ($$v) {_vm.$set(skill, "order", $$v)},expression:"skill.order"}})],1)],1),_c('v-col',{attrs:{"cols":"auto"}},[(!skill.isDeletable)?_c('v-checkbox',{attrs:{"label":"Ativo","disabled":!_vm.isUpdate},model:{value:(skill.active),callback:function ($$v) {_vm.$set(skill, "active", $$v)},expression:"skill.active"}}):_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-2",attrs:{"color":"error","icon":"","disabled":!skill.active},on:{"click":function($event){return _vm.emitRemoveSkill(skill.skill.id)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-delete")])],1)]}}],null,true)},[_c('span',[_vm._v("Excluir")])])],1)],1)],1)}),1)],1)}),1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }