export default function formatNumberToExtension(value: number) {
  const units = ['', 'um', 'dois', 'três', 'quatro', 'cinco', 'seis', 'sete', 'oito', 'nove'];
  const spacials = ['', 'onze', 'doze', 'treze', 'catorze', 'quinze', 'dezesseis', 'dezessete', 'dezoito', 'dezenove'];
  const dozens = ['', 'dez', 'vinte', 'trinta', 'quarenta', 'cinquenta', 'sessenta', 'setenta', 'oitenta', 'noventa'];
  const hundreds = ['', 'cento', 'duzentos', 'trezentos', 'quatrocentos', 'quinhentos', 'seiscentos', 'setecentos', 'oitocentos', 'novecentos'];

  function formatDicimalPart(decimalValue: number) {
    const cents = Math.round(decimalValue * 100);
    if (cents === 0) {
      return '';
    } if (cents === 1) {
      return ' e um centavo';
    }
    return ` e ${formatNumberToExtension(cents)} centavos`;
  }

  if (value === 0) {
    return 'zero';
  }

  if (value < 0) {
    return `menos ${formatNumberToExtension(-value)}`;
  }

  const partInteger = Math.floor(value);
  const partDecimal = value - partInteger;

  let result = '';

  if (partInteger <= 9) {
    result += units[partInteger];
  } else if (partInteger >= 11 && partInteger <= 19) {
    result += spacials[partInteger - 10];
  } else if (partInteger <= 99) {
    result += dozens[Math.floor(partInteger / 10)];
    if (partInteger % 10 !== 0) {
      result += ` e ${units[partInteger % 10]}`;
    }
  } else if (partInteger <= 199) {
    result += (partInteger === 100 ? 'cem' : 'cento');
    if (partInteger % 100 !== 0) {
      result += ` e ${formatNumberToExtension(partInteger % 100)}`;
    }
  } else if (partInteger <= 999) {
    result += hundreds[Math.floor(partInteger / 100)];
    if (partInteger % 100 !== 0) {
      result += ` e ${formatNumberToExtension(partInteger % 100)}`;
    }
  } else if (partInteger <= 999999) {
    result += `${formatNumberToExtension(Math.floor(partInteger / 1000))} mil`;
    if (partInteger % 1000 !== 0) {
      result += ` e ${formatNumberToExtension(partInteger % 1000)} Reais`;
    }
  }

  result += formatDicimalPart(partDecimal);

  return result;
}
