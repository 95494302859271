import RouteCheckerTo from '@/views/route-checker';
import IndicatorDefaultView from './indicator-default.view.vue';

export default [
  {
    path: '/indicator/DEFAULT',
    name: 'Indicadores Organizacionais',
    component: IndicatorDefaultView,
    beforeEnter: RouteCheckerTo.hasAccessReadIndicatorDefaultManagement,
  },
]
