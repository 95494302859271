import JobApplication from './job/job-application.entity';

export default class Candidate {
  id: string;

  name: string;

  email: string;

  phone: string;

  linkedin: string;

  jobApplications: JobApplication[];

  constructor(obj: any = {}) {
    this.id = obj.id || '';
    this.name = obj.name || '';
    this.email = obj.email || '';
    this.phone = obj.phone || '';
    this.linkedin = obj.linkedin || '';
    this.jobApplications = obj.jobApplications || [];
  }
}
