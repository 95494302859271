import DateFormat from '@/shared/utils/date-format';

export default class AttendanceFilter {
  employee?: string;

  manager?: string;

  businessUnit?: string;

  admissionDate: string;

  type?: string;

  status: any;

  expectedDate: string = '';

  active: boolean | null = true;

  next: boolean = true;

  late: boolean = true;

  concluded: boolean = true;

  critical?: boolean;

  initialPeriod: string = '';

  finalPeriod: string = '';

  responsible?: string;

  resultCenter?: string;

  role?: string;

  set date(value: string) {
    this.expectedDate = DateFormat.monthYearFromFormated(value);
  }

  get date(): string {
    return DateFormat.monthYearToFormated(this.expectedDate);
  }

  constructor(obj: any) {
    this.employee = obj.employee;
    this.manager = obj.manager;
    this.businessUnit = obj.businessUnit;
    this.admissionDate = obj.admissionDate || '';
    this.type = obj.type;
    this.status = obj.status;
    this.expectedDate = obj.expectedDate || '';
    this.critical = obj.critical;
    this.initialPeriod = obj.initialPeriod || '';
    this.finalPeriod = obj.finalPeriod || '';
    this.responsible = obj.responsible;
    this.resultCenter = obj.resultCenter;
    this.role = obj.role;
    this.active = obj.active !== undefined ? obj.active : true;
  }

  public static of(value: any): AttendanceFilter {
    return new AttendanceFilter(value || {});
  }

  public static ofList(response: any): AttendanceFilter[] {
    return response.data.map(this.of);
  }

  public prepareToSend(): void {
    this.status = [];
    if (this.next) {
      this.status.push('NEXT_ATTENDANCE');
    }
    if (this.late) {
      this.status.push('LATE_ATTENDANCE');
    }
    if (this.concluded) {
      this.status.push('RESOLVED_ATTENDANCE');
      this.status.push('RESOLVED_WITH_DELAY');
    }
    this.status = this.status.join(',');
  }

  public equals(obj: AttendanceFilter) {
    return this.businessUnit === obj.businessUnit
      && this.employee === obj.employee
      && this.manager === obj.manager
      && this.admissionDate === obj.admissionDate
      && this.active === obj.active
      && this.expectedDate === obj.expectedDate
      && this.critical === obj.critical
      && this.initialPeriod === obj.initialPeriod
      && this.finalPeriod === obj.finalPeriod
      && this.responsible === obj.responsible
      && this.resultCenter === obj.resultCenter
      && this.role === obj.role;
  }
}
