import SkillResponse from './skill/entity/skill-response';

export default class EmployeeAssessmentResponse {
  levelId: string;

  skillResponses: SkillResponse[];

  constructor(obj: any = {}) {
    this.levelId = obj.levelId || '';
    this.skillResponses = [];
  }
}
