import {
  VuexModule, Module, Mutation, Action,
} from 'vuex-module-decorators';
import store from '@/store';
import Workplace from './entity/workplace.entity';
import CareerService from '../employees/service/career.service';

@Module({ dynamic: true, name: 'workplaceStore', store })
export default class WorkplaceStore extends VuexModule {
  private static workplaces: Workplace[] = [];

  private static fetched = false;

  static fetchedWorkplaces(): Promise<Workplace[]> {
    if (!this.fetched) {
      return CareerService
        .getWorkplace()
        .then(response => this.handleFetchWorkplace(response));
    }
    return Promise.resolve(this.workplaces);
  }

  private static handleFetchWorkplace(response: any) {
    this.fetched = true;
    this.workplaces = response.data.map(it => new Workplace(it))
    return this.workplaces;
  }
}
