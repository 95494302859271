

































































import { Vue, Component, Prop } from 'vue-property-decorator';
import TechnicalKnowledge from '@/domain/drd/entity/technical-knowledge.entity';
import LabelSlot from '@/shared/slots/label-slot.component.vue';
import Rules from '@/shared/rules/rules';
import SeniorityLevel from '@/views/drd/commons/seniority-level.component.vue';
import ActiveCheckboxField from '@/views/drd/form/tabs/common-components/active-checkbox-field.component.vue';
import FieldsArea from '@/views/drd/form/tabs/common-components/fields-area.component.vue';

@Component({
  components: {
    ActiveCheckboxField,
    FieldsArea,
    LabelSlot,
    SeniorityLevel,
  },
})
export default class TechnicalKnowledgeComponent extends Vue {
  @Prop({
    type: TechnicalKnowledge,
    required: true,
  })
  private technicalKnowledge!: TechnicalKnowledge;

  private rules: Rules = new Rules();

  private deleteTechnicalKnowledge(): void {
    this.$emit('delete');
  }

  private updateField(field: string, value: any): void {
    this.$emit(`update:${field}`, value);
  }
}
