





import { Vue, Component } from 'vue-property-decorator';

@Component({ name: 'RiskAttendanceView', components: { } })
export default class RiskAttendance extends Vue {
}
