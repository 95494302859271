
























import { Vue, Component, Prop } from 'vue-property-decorator';
import LabelSlot from '@/shared/slots/label-slot.component.vue';
import { ChartFilter } from '@/domain/employees/entity/employee-chart.entity';
import EmployeeSummary from '@/domain/schedule/employee/employee-summary.entity';

@Component({ components: { LabelSlot } })
export default class OrganizationalChartFilter extends Vue {
  @Prop() employees!: EmployeeSummary[];

  filter: ChartFilter = new ChartFilter({});

  employeeFilterBackup = '';

  private sendFilter(inputText: string) {
    if (!inputText || inputText.length < 3 || inputText === this.employeeFilterBackup) {
      return;
    }

    this.employeeFilterBackup = inputText;

    this.$emit('sendFilter', inputText);
  }

  private updateChartByEmployee() {
    if (!this.filter.employeeId) {
      return;
    }

    this.$emit('updateChart', this.filter.employeeId);
  }

  private clearFilter() {
    this.$emit('clearFilter');
  }
}

