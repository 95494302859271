



























































































































































































import { Vue, Component, Prop } from 'vue-property-decorator';
import LabelSlot from 'bag-of-holding-library/src/slot/label-slot.component.vue';
import KeyArea from '@/domain/drd-2.0/entity/key-area.entity';
import KeyAreaSkill from '@/domain/drd-2.0/entity/key-area-skill.entity';
import KeyAreaSkillLevel from '@/domain/drd-2.0/entity/key-area-skill-level.entity';
import Level from '@/domain/drd-2.0/entity/level.entity';
import KeyAreaService from '@/domain/drd-2.0/service/key-area.service';
import SkillTypes from '../../skill/skill-types.json';

@Component({
  components: {
    LabelSlot,
  },
})
export default class KeyAreaSkillForm extends Vue {
  @Prop() keyArea!: KeyArea;

  @Prop({
    type: Boolean,
    default: false,
  }) isUpdate!: boolean;

  @Prop({
    type: Array,
    default: () => [],
  }) levelsSelected!: Level[];

  skillTypes = SkillTypes;

  levels: Level[] = [];

  openSelectSkillDialog: boolean = false;

  created() {
    this.getAllLevels();
  }

  getOptionsBySkillType(type: string) {
    return this.skillTypes.filter(it => it.value === type)[0].options
      .filter(it => it.isSelected);
  }

  removeLevel(skill: KeyAreaSkill, level: KeyAreaSkillLevel) {
    this.$emit('removeLevel', { skill, level });
  }

  disableOrActiveLevel(skill: KeyAreaSkill, level: KeyAreaSkillLevel) {
    this.$emit('disableOrActiveLevel', { skill, level });
  }

  addLevel(level: Level, skill: KeyAreaSkill) {
    const skillFiltered = this.keyArea.skills
      .filter(it => it.skill.id === skill.skill.id)[0];
    const levelAlreadyAdded = skillFiltered.levels
      .filter(it => it.name === level.name);
    if (levelAlreadyAdded.length > 0) return;
    this.$emit('addLevel', { level, skillFiltered });
  }

  getAllLevels() {
    KeyAreaService.findAllLevels()
      .then(levels => this.levels = levels);
  }

  emitRemoveSkill(skillId: string) {
    this.$emit('removeSkill', skillId);
  }

  openAddSkillModal() {
    this.$emit('openAddSkillModal')
  }
}
