import CreateFeedback from './feedback/index.vue';
import CreatePDI from './pdi/index.vue';
import ActivityHappinessView from './happiness/activity-happiness.view.vue';

export default [
  {
    path: '/activity/:activityIdOfFeedback/feedback',
    name: 'Novo Feedback',
    component: CreateFeedback,
  },
  {
    path: '/activity/:activityIdOfPdi/pdi',
    name: 'Cadastro de PDI',
    component: CreatePDI,
  },
  {
    path: '/activity/:activityId/happiness',
    name: 'Formulário Qualitativo de Atividade',
    component: ActivityHappinessView,
  },
];
