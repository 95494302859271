export default class EmployeeAccompaniment {
  isAccompanied: boolean;

  constructor(obj: any) {
    this.isAccompanied = obj.isAccompanied;
  }

  public static of(obj: any = {}): EmployeeAccompaniment {
    return new EmployeeAccompaniment(obj);
  }
}
