








































import {
  Vue,
  Component,
  Prop,
} from 'vue-property-decorator';
import { VbSnackbarService } from 'bag-of-holding-library';
import ListVacationsBalance from '@/domain/vacation/entity/list-vacation-balance.entity';
import VacationPeriod from '@/domain/vacation/entity/vacation-period.entity';
import VacationService from '@/domain/vacation/service/vacation.service';
import DateFormat from '@/shared/utils/date-format';
import PaginationComponent from '@/shared/pagination.component.vue';
import VacationPeriodListComponent from './vacation-period-list.component.vue';
import headers from './vacation-balance-list-header.json';

const SORT_MAPPING = {
  employee: 'name',
  role: 'careerUpdates.current.role.name',
  manager: 'careerUpdates.current.manager.name',
  businessUnit: 'careerUpdates.current.businessUnit.name',
  admissionDate: 'admissionDate',
};

@Component({ components: { VacationPeriodListComponent, PaginationComponent } })
export default class VacationBalanceList extends Vue {
  @Prop({
    type: Array,
    required: true,
  })
  vacationBalances!: ListVacationsBalance[];

  @Prop({
    type: Boolean,
    default: false,
  })
  loading!: boolean;

  @Prop({ required: true })
  pagination!: any;

  @Prop({
    type: Array,
    required: true,
  })
  expanded!: any[];

  headers: any = headers;

  formatDate(date: string): string {
    return DateFormat.dateTimeFormated(date);
  }

  loadVacationPeriods({ item, value }: any): void {
    if (value && !item.vacationPeriodsLoaded) {
      VacationService.getEmployeeVacationPeriods(item.id).then(({ data }: any) => {
        item.vacationPeriods = data.map(it => new VacationPeriod(it));
      }).catch(VbSnackbarService.handleHttpError)
        .finally(() => {
          item.vacationPeriodsLoaded = true;
        });
    }
  }

  updateExpanded(values: any[]): void {
    this.$emit('update:expanded', values);
  }

  updatePagination(page: any): void {
    this.updatePage(page);
  }

  updatePage(page: any): void {
    this.$emit('updataPage', page);
  }

  updateSortBy(sortBy: string): void {
    const mappedSort = [SORT_MAPPING[sortBy]];
    this.updatePage({
      ...this.pagination,
      sortBy: mappedSort,
    });
  }

  updateSortDesc(sortDesc: string): void {
    this.updatePage({
      ...this.pagination,
      sortDesc,
    });
  }
}
