


































































































import LabelSlot from '@/shared/slots/label-slot.component.vue';
import { Vue, Component, Prop } from 'vue-property-decorator';
import FilterScore from '@/domain/drd/entity/filter-score.entity'
import EmployeeSummary from '@/domain/schedule/employee/employee-summary.entity';
import EmployeeService from '@/domain/schedule/employee/employee.service';
import DatePickerField from '@/shared/date-picker/date-picker-field.component.vue';
import MonthYearSelect from '@/shared/month-year-picker/month-year-select.component.vue';
import SelectionComponent from './selection.componet.vue';

@Component({
  components: {
    LabelSlot,
    DatePickerField,
    SelectionComponent,
    MonthYearSelect,
  },
})
export default class FilterScoreComponent extends Vue {
  @Prop() filter!: FilterScore;

  private employees: EmployeeSummary[] = [];

  private managers: EmployeeSummary[] = [];

  private lastFilterEmployee = '';

  private lastFilterManager = '';

  employeeInput = '';

  managerInput: string = '';

  private sendField(field: string, value: any) {
    if (field === 'competence') {
      sessionStorage.setItem(field, value);
    }
    this.$emit(`update:${field}`, value);
  }

  updateEmployees(value: string[]) {
    this.employeeInput = '';
    this.sendField('employees', value)
  }

  updateManagers(value: string[]) {
    this.managerInput = '';
    this.sendField('managers', value)
  }

  findEmployees(value: string) {
    this.employeeInput = value;
    if (this.lastFilterEmployee === value || value === null) return;
    this.lastFilterEmployee = value;
    if (value.length > 2) {
      EmployeeService.findEmployeesByName(value)
        .then(this.mergeEmployees);
    }
  }

  private findManagers(value: string) {
    this.managerInput = value;
    if ((this.lastFilterManager !== value) && value) {
      this.lastFilterManager = value;
      if (value.length > 2) {
        EmployeeService.findManagersByName(value)
          .then(this.mergeManagers);
      }
    }
  }

  private mergeEmployees(employees: EmployeeSummary[]): void {
    const addedIds: string[] = this.employees.map((employee: EmployeeSummary) => employee.id)
    employees.forEach((employee: EmployeeSummary) => {
      if (!addedIds.includes(employee.id)) {
        this.employees.push(employee);
      }
    })
  }

  private mergeManagers(employees: EmployeeSummary[]): void {
    const addedIds: string[] = this.managers.map((employee: EmployeeSummary) => employee.id)
    employees.forEach((employee: EmployeeSummary) => {
      if (!addedIds.includes(employee.id)) {
        this.managers.push(employee);
      }
    })
  }
}
