import RouteCheckerTo from '@/views/route-checker';
import SkillMapTableView from './skill-map-table.view.vue';

export default [
  {
    path: '/skill-map',
    name: 'Gerenciamento de Skills',
    component: SkillMapTableView,
    beforeEnter: RouteCheckerTo.hasAccessSkillManagement,
  },
];
