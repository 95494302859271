import Country from '../countries/entity/country.entity';
import City from '../states/entity/city.entity';
import State from '../states/entity/state.entity';

export default class Address {
  id: string;

  address: string;

  addressNumber: string;

  complement: string;

  district: string;

  city: City;

  state: State;

  postalCode: string;

  country: Country;

  foreignCity: string;

  foreignState: string;

  constructor(obj: any = {}) {
    this.id = obj.id || '';
    this.address = obj.address || '';
    this.addressNumber = obj.addressNumber || '';
    this.complement = obj.complement || '';
    this.district = obj.district || '';
    this.city = obj.city || new City({});
    this.state = obj.state || new State({});
    this.postalCode = obj.postalCode || '';
    this.country = obj.country ? obj.country : new Country({});
    this.foreignCity = obj.foreignCity || '';
    this.foreignState = obj.foreignState || '';
  }
}
