export default class MessageFilterRequest {
  toManagers: boolean = false;

  indirectSubordinates: boolean = false;

  managers: String[];

  businessUnits: String[];

  resultCenters: String[];

  roles: String[];

  workplaces: String[];

  gender: string;

  cities: string;

  states: string | string[];

  country: string;

  hasChildren: boolean | null;

  foreignState: string;

  foreignCity: string;

  constructor(obj: any) {
    this.toManagers = obj.toManagers || false;
    this.indirectSubordinates = obj.indirectSubordinates || false;
    this.managers = obj.managers || [];
    this.businessUnits = obj.businessUnits || [];
    this.resultCenters = obj.resultCenters || [];
    this.roles = obj.roles || [];
    this.workplaces = obj.workplaces || [];
    this.gender = obj.gender || '';
    this.cities = obj.cities || '';
    this.states = obj.states || [];
    this.country = obj.country || '';
    this.hasChildren = obj.hasChildren;
    this.foreignState = obj.foreignState || '';
    this.foreignCity = obj.foreignCity || '';
  }

  public static of(obj: any) {
    return new MessageFilterRequest(obj);
  }

  hasField(field: any) {
    return this[field] !== null
      && this[field] !== undefined
      && this[field].length > 0;
  }

  get warningMessage(): string {
    const values = [
      { name: 'toManagers', value: 'líderes selecionados' },
      { name: 'indirectSubordinates', value: 'liderados indiretos' },
      { name: 'managers', value: 'liderados dos gestores selecionados' },
      { name: 'businessUnits', value: 'unidades selecionadas' },
      { name: 'resultCenters', value: 'centros de resultados selecionados' },
      { name: 'roles', value: 'cargos selecionados' },
      { name: 'workplaces', value: 'sedes selecionadas' },
    ]
    const message = values
      .filter(el => this[el.name] !== false && this[el.name] !== null)
      .map(el => el.value)
      .join(', ')
    return `Olá humanos, ao utilizar essa função, você irá encaminhar a mensagem para todos ${message} em seus chats privados do Teams. Deseja continuar ?`
  }
}
