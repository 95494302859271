





import { Component, Vue } from 'vue-property-decorator';
import CardTitle from '@/shared/card/card-title.component.vue';

@Component({
  components: {
    CardTitle,
  },
})
export default class OrganizationalChartHeader extends Vue {
}
