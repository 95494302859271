













































import { Vue, Component, Prop } from 'vue-property-decorator';
import EmployeeScheduleItem from '../../domain/schedule/employee/employee-schedule-item.entity';
import DateFormat from '../../shared/utils/date-format';
import ActivityStatus from './schedule-data-table-activity-status.component.vue'


@Component({ components: { ActivityStatus } })
export default class ScheduleDataTableActivityComponent extends Vue {
  @Prop() activities!: EmployeeScheduleItem;

  @Prop({
    required: false,
    default: true,
  })
  private currentClass?: string;

  get date() {
    const splited = this.activities.date.split('-');
    return `${DateFormat.fullMonth(splited[1])} ${splited[0]}`;
  }

  detail() {
    this.$emit('detail');
  }
}
