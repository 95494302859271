export default class EmployeeHappiness {
  id: string;

  name: string;

  username: string;

  email: string;

  constructor(obj: any = {}) {
    this.id = obj.id || null;
    this.name = obj.name || '';
    this.username = obj.username || '';
    this.email = obj.email || '';
  }
}
