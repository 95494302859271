
























import {
  Vue,
  Component,
  Prop,
} from 'vue-property-decorator';

@Component
export default class CustomIndicatorAwarenessDialog extends Vue {
  @Prop({ type: Boolean, default: false })
  value!: boolean;

  input(val: boolean): void {
    this.$emit('input', val);
  }

  aware(): void {
    this.$emit('aware');
    this.input(false);
  }
}
