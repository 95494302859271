import StatusRiskJson from '@/views/risk-attendance/form-risk-attendance/status.json';
import AttendanceTypeJson from '@/views/attendance/table/filter/attendanceType.json';
import ResignationReasonJson from '@/views/attendance/form-attendances/resignation-reason.json';

export default class FilteredRiskAttendance {
  id: string;

  name: string;

  manager: string;

  businessUnit: string;

  responsible: string;

  source: string;

  requestDate: string;

  statusRisk: string;

  limitDate: string;

  mainResignationReason: string;

  private readonly sourceTypesJson = AttendanceTypeJson;

  private readonly statusJson = StatusRiskJson;

  private readonly resignationReasonJson = ResignationReasonJson;

  constructor(obj: any) {
    this.id = obj.id || '';
    this.name = obj.name || '';
    this.responsible = obj.responsible || '';
    this.manager = obj.manager || '';
    this.businessUnit = obj.businessUnit || '';
    this.requestDate = obj.requestDate || '';
    this.source = obj.source || '';
    this.statusRisk = obj.statusRisk || '';
    this.limitDate = obj.limitDate || '';
    this.mainResignationReason = obj.mainResignationReason || '';
  }

  public static of(obj: any): FilteredRiskAttendance {
    return new FilteredRiskAttendance(obj || {});
  }

  public static ofList(obj: any[]): FilteredRiskAttendance[] {
    return obj.map(this.of);
  }

  statusDescription(): string {
    return this.verifyValue(this.statusJson, this.statusRisk, (it => it.description));
  }

  statusColor(): string {
    return this.verifyValue(this.statusJson, this.statusRisk, (it => it.color));
  }

  private verifyValue(collections, comparisonKey, mappedValue): string {
    return collections
      .filter(collection => collection.value === comparisonKey)
      .map(mappedValue)
      .toString()
  }

  typeDescription(): string {
    return this.verifyValue(this.sourceTypesJson, this.source, (it => it.description));
  }

  resignationReasonDescription(): string {
    return this.verifyValue(this.resignationReasonJson, this.mainResignationReason,
      (it => it.description));
  }
}
