































import {
  Vue, Component, Prop,
} from 'vue-property-decorator';

import Rules from '@/shared/rules/rules';
import LabelSlot from '@/shared/slots/label-slot.component.vue';
import BackupTypesSelect from './backup-types-select.json';

@Component({
  components: {
    LabelSlot,
  },
})
export default class ResignationSelectBackupTypes extends Vue {
  @Prop({
    required: true,
  })
  private value!: string[];

  private backupTypesSelect = BackupTypesSelect;

  private rules: Rules = new Rules();

  verifyFields(value: string[]) {
    if (value.length > 1 && value[0] === 'NONE') {
      const emitValue: string[] = value.filter(it => it !== 'NONE');
      this.$emit('input', emitValue)
      return;
    }

    if (value.includes('NONE')) {
      this.$emit('input', ['NONE'])
      return;
    }
    this.$emit('input', value)
  }
}

