export default class DrdFilter {
  assignmentId: string;

  roleId: string;

  keyAreaId: string;

  ecosystemId: string;

  assignmentActive: boolean = true;

  constructor(obj: any = {}) {
    this.assignmentId = obj.assignmentId || '';
    this.roleId = obj.roleId || '';
    this.keyAreaId = obj.keyAreaId || '';
    this.ecosystemId = obj.ecosystemId || '';
    this.assignmentActive = obj.assignmentActive;
  }
}
