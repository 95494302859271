import DateFormat from '@/shared/utils/date-format';

export default class InitialScoreFiltered {
  id: string;

  employeeName: string;

  registration: string;

  admissionDate: string;

  employeeId: string;

  constructor(obj: any = {}) {
    this.id = obj.id || '';
    this.employeeName = obj.employeeName || '';
    this.registration = obj.registration || '';
    this.admissionDate = obj.admissionDate || '';
    this.employeeId = obj.employeeId || '';
  }

  get admissionDateFormated() {
    return DateFormat.standardDate(this.admissionDate);
  }

  get status() {
    return this.employeeId ? 'Linkado' : 'Não linkado';
  }
}
