











































































































































































































import { Vue, Component, Prop } from 'vue-property-decorator';
import LabelSlot from '@/shared/slots/label-slot.component.vue';
import DatePickerField from '@/shared/date-picker/date-picker-field.component.vue'
import Filter from '@/domain/attendance/entity/filter.entity';
import MonthYearSelect from '@/shared/month-year-picker/month-year-select.component.vue';
import EmployeeSummary from '../../../../domain/schedule/employee/employee-summary.entity';
import UpdateAtribute from '../../../../domain/employees/entity/update-atribute.entity';
import attendanceType from './attendanceType.json';

@Component({ components: { LabelSlot, MonthYearSelect, DatePickerField } })
export default class FilterComponent extends Vue {
  @Prop() filter!: Filter;

  @Prop() managers!: EmployeeSummary;

  @Prop() employees!: EmployeeSummary;

  @Prop() responsibles!: EmployeeSummary;

  @Prop() resultCenters!: UpdateAtribute;

  @Prop() businessUnits!: UpdateAtribute;

  @Prop() roles!: UpdateAtribute;

  @Prop() lastFilter?: Filter;

  private resultCenterKey: number = 0;

  types = attendanceType;

  private readonly isCriticalTypes = [
    {
      value: false,
      description: 'Não',
    },
    {
      value: true,
      description: 'Sim',
    },
  ];

  private readonly activeOptions: any[] = [
    { text: 'Todos', value: null },
    { text: 'Sim', value: true },
    { text: 'Não', value: false },
  ];

  private created() {
    this.getLastFilter();
  }

  private getLastFilter() {
    if (this.lastFilter) {
      this.filter = this.lastFilter;
    }
  }

  applyAdmissionDateFilter(date: string) {
    this.filter.admissionDate = date;
    this.sendFilter()
  }

  applyInitialPeriodFilter(date: string) {
    this.filter.initialPeriod = date;
    this.sendFilter()
  }

  applyFinalPeriodFilter(date: string) {
    this.filter.finalPeriod = date;
    this.sendFilter()
  }

  applyExpectedDateFilter(date: string) {
    this.filter.expectedDate = date;
    this.sendFilter()
  }

  clearInitialPeriodFilter() {
    this.filter.initialPeriod = ''
    this.sendFilter()
  }

  clearFinalPeriodFilter() {
    this.filter.finalPeriod = ''
    this.sendFilter()
  }


  clearAdmissionDate() {
    this.filter.admissionDate = ''
    this.sendFilter()
  }

  private sendFilter() {
    this.$emit('filter', this.filter);
  }

  private findManagers(value: string) {
    this.$emit('findManagers', value);
  }

  private findEmployees(value: string) {
    this.$emit('findEmployees', value);
  }

  private findResponsible(value: string) {
    this.$emit('findResponsible', value);
  }

  private findResultCenter(value: string) {
    this.$emit('findResultCenter', value);
  }

  private updateActive(active: boolean) {
    this.filter.active = active;
    this.sendFilter();
  }

  private clearResultCenterFilter() {
    this.filter.resultCenter = undefined;
    this.resultCenterKey += 1;
    this.sendFilter();
  }
}
