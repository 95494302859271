























import {
  Vue, Component, Prop,
} from 'vue-property-decorator';

@Component
export default class ScheduleDataTableName extends Vue {
  @Prop() name!: string;

  @Prop({
    type: Boolean,
    default: false,
  })
  private isResetEmployeeSchedule!: boolean;

  @Prop({
    type: Boolean,
    default: false,
  })
  private isReadActivityHistory!: boolean;

  @Prop({ type: Boolean, default: false })
  hasRescheculeActivity!: boolean;

  rescheduleActivity() {
    this.$emit('rescheduleActivity');
  }

  restartSchedule() {
    this.$emit('restartSchedule');
  }

  employeeHistory() {
    this.$emit('employeeHistory');
  }
}
