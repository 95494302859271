
























































































import { Vue, Component, Prop } from 'vue-property-decorator';
import LabelSlot from '@/shared/slots/label-slot.component.vue';
import MobileHandler from '@/shared/utils/mobile-handler';
import splitContent from '@/shared/directive/split-content';
import DateHandler from '@/shared/utils/date-format';
import CareerUpdateHistory from '@/domain/employees/entity/career-update-history.entity';
import Topics from './topic.json';

@Component({
  components: { LabelSlot },
  filters: {
    splitContent,
    formatDate(value: string) {
      return DateHandler.timestampToLocal(value.split('T')[0]);
    },
    formatDateTime(value: string) {
      return DateHandler.dateTimeFormated(value);
    },
  },
})
export default class TableHistoryComponent extends Vue {
  @Prop() items!: CareerUpdateHistory[];

  private isMobile = false;

  topics = Topics;

  private onResize() {
    this.isMobile = MobileHandler.isMobile();
  }

  private get hasContent() {
    return this.items.length > 0;
  }

  executionDateEvent(item: CareerUpdateHistory): string {
    return item.pastModifiedDate === '' ? item.date : item.pastModifiedDate
  }
}
