import AssessmentAdherenceTotalSkillType from './assessment-adherence-total-skill-type.entity';
import AssessmentResult from './assessment-result.entity';

export default class AssessmentAdherenceTotal {
  adherenceTotal: number;

  adherenceTotalSkillType: AssessmentAdherenceTotalSkillType[];

  resultsByEmployees: AssessmentResult[];

  constructor(obj: any = {}) {
    this.adherenceTotal = obj.adherenceTotal || 0;
    this.adherenceTotalSkillType = obj.adherenceTotalSkillType;
    this.resultsByEmployees = obj.resultsByEmployees
      ? obj.resultsByEmployees.map(it => new AssessmentResult(it))
      : [];
  }
}
