import EnumObject from '@/domain/enum-object.entity';
import TalentLevel from '@/domain/talent-level/entity/talent-level.entity';
import ActivityFile from './activity-file.entity';

export default class ActivityDetailed {
  id: string;

  name: string;

  defaultDate: string;

  expectedDate: string;

  status: string;

  observation?: string;

  locked: boolean;

  files: ActivityFile[];

  managerEmails: string[];

  possibleResults: EnumObject[];

  result: EnumObject;

  executionDate: string;

  hasScore: boolean;

  hasFeed: boolean;

  hasAdm: boolean;

  talentLevel: TalentLevel;

  constructor(obj: any = {}) {
    this.id = obj.id || '';
    this.name = obj.name || '';
    this.defaultDate = obj.defaultDate || '';
    this.expectedDate = obj.expectedDate || '';
    this.status = obj.status || '';
    this.locked = obj.locked;
    if (obj.observation) this.observation = obj.observation;
    this.files = obj.files ? ActivityFile.ofList(obj.files) : [];
    this.managerEmails = obj.managerEmails || [];
    this.possibleResults = obj.possibleResults ? EnumObject.ofList(obj.possibleResults) : [];
    this.result = obj.result ? EnumObject.of(obj.result) : EnumObject.of({});
    this.executionDate = obj.executionDate || '';
    this.hasScore = obj.hasScore;
    this.hasFeed = obj.hasFeed;
    this.hasAdm = obj.hasAdm;
    this.talentLevel = obj.talentLevel || '';
  }

  public static of(obj: any): ActivityDetailed {
    return new ActivityDetailed(obj || {});
  }

  public static ofList(obj: any): ActivityDetailed[] {
    return obj.map(this.of);
  }

  public get activityId(): string {
    return this.id || '';
  }

  public set activityId(id: string) {
    this.id = id;
  }

  public get statusValue(): string {
    return this.status || '';
  }

  public set statusValue(status: string) {
    this.status = status;
  }

  public get observationValue(): string {
    return this.observation || '';
  }

  public set observationValue(observation: string) {
    this.observation = observation;
  }

  removeFile(fileId: string) {
    this.files = this.files.filter((el: ActivityFile) => el.id !== fileId)
  }

  applyResult(): void{
    if (this.isEntrancePDI()) {
      this.result = EnumObject.of({
        name: 'NONE',
        description: 'Nenhum',
      })
    }
  }

  isEntrancePDI(): boolean {
    return this.name === 'PDI de Entrada';
  }

  hasResult(): boolean {
    return this.result.name !== '';
  }

  hasResultAndExecutionDate() {
    return this.result.name !== '' && this.executionDate !== '';
  }

  isApproved(): boolean {
    return this.status === 'Aprovada';
  }

  isExecutionInputEnabled() {
    return !this.isApproved() || !this.locked || !this.status
  }

  isExecutionDateValid(executionDate: string) {
    const executionDateTime = new Date(executionDate).getTime();
    const currentTime = new Date().getTime();
    return this.executionDate !== executionDate && executionDateTime <= currentTime;
  }

  executionDateRules(executionDate: string) {
    const errors: any = []
    const executionDateTime = new Date(executionDate).getTime();
    const currentTime = new Date().getTime();
    if (executionDateTime > currentTime) {
      errors.push('Data maior que a atual');
    }
    return errors;
  }
}
