export default class State {
  id: string;

  name: string;

  acronym: string;

  constructor(obj: any) {
    this.id = obj.id || '';
    this.name = obj.name || '';
    this.acronym = obj.acronym || '';
  }

  public static of(obj: any): State {
    return new State(obj || {})
  }

  public static ofList(obj: any[]): State[] {
    return obj.map(this.of);
  }
}
