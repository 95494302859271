import DefaultIndicator from '@/domain/drd/entity/default-indicator.entity';

export default class Indicators {
  public id: string;

  public indicator: string;

  public category: string;

  public description: string;

  public active: boolean;

  public weight: string;

  public goalValue: number;

  public goalAchievementType: string;

  public goalMeasurementUnit: string;

  public goalMaskType: string;

  public utility: string;

  public goalMaxValue: number;

  public linkGmo: string;

  public linkBpg: string;

  public indicatorType: string;

  public saved: boolean;

  loadingDelete: boolean;

  constructor(obj: any = {}) {
    this.id = obj.id || null;
    this.indicator = obj.indicator || '';
    this.category = obj.category || '';
    this.description = obj.description || '';
    this.active = obj.active === null || obj.active === undefined ? true : obj.active;
    this.weight = obj.weight || null;
    this.goalValue = obj.goalValue || null;
    this.goalAchievementType = obj.goalAchievementType || null;
    this.goalMeasurementUnit = obj.goalMeasurementUnit || null;
    this.goalMaskType = obj.goalMaskType || null;
    this.utility = obj.utility || null;
    this.goalMaxValue = obj.goalMaxValue || null;
    this.linkGmo = obj.linkGmo || null;
    this.linkBpg = obj.linkBpg || null;
    this.indicatorType = obj.indicatorType || 'CUSTOM';
    this.saved = obj.saved || false;
    this.loadingDelete = obj.loadingDelete;
  }

  public static of(indicators: any): Indicators {
    return new Indicators(indicators || {});
  }

  public static ofList(obj: any[]): Indicators[] {
    return obj.map(this.of);
  }

  public static ofDefault(defaultIndicator: DefaultIndicator): Indicators {
    return new Indicators({
      ...defaultIndicator,
      indicatorType: 'DEFAULT',
    });
  }

  equals(obj: Indicators): boolean {
    return this.id === obj.id
    && this.indicator === obj.indicator
    && this.category === obj.category
    && this.description === obj.description
    && this.active === obj.active
    && this.goalValue === obj.goalValue
    && this.goalAchievementType === obj.goalAchievementType
    && this.goalMeasurementUnit === obj.goalMeasurementUnit
    && this.goalMaskType === obj.goalMaskType;
  }
}
