
























































import {
  Vue,
  Component,
  Prop,
} from 'vue-property-decorator';
import { VbSnackbarService } from 'bag-of-holding-library';
import Indicators from '@/domain/drd/entity/indicators.entity';
import DrdService from '@/domain/drd/service/drd.service';
import AddButton from '@/shared/buttons/add-button.component.vue';
import Loader from '@/views/drd/commons/loader.component.vue';
import FieldsArea from '@/views/drd/form/tabs/common-components/fields-area.component.vue';
import DefaultIndicatorEntity from '@/domain/drd/entity/default-indicator.entity';
import CustomIndicatorAwarenessDialog from './custom-indicator-awareness-dialog.component.vue';
import CustomIndicators from './custom-indicators.component.vue';
import DefaultIndicators from './default-indicators.component.vue';
import DefaultIndicatorPickerDialog from './default-indicator-picker-dialog.component.vue';

@Component({
  components: {
    AddButton,
    CustomIndicatorAwarenessDialog,
    CustomIndicators,
    DefaultIndicatorPickerDialog,
    DefaultIndicators,
    FieldsArea,
    Loader,
  },
})
export default class IndicatorsComponent extends Vue {
  @Prop() performanceDescriptionId!: string;

  @Prop() saveCommand!: boolean

  loading = false;

  indicatorsData = Indicators.ofList([]);

  showIndicatorSelector: boolean = false;

  showIndicatorAwarenessDialog: boolean = false;

  customIndicatorAwared: boolean = false;

  showDefaultIndicatorPicker: boolean = false;

  private created() {
    this.getIndicatorData();
  }

  awareCustomIndicator(): void {
    this.customIndicatorAwared = true;
    this.addNewIndicator();
  }

  openDefaultIndicatorPicker(): void {
    this.showDefaultIndicatorPicker = true;
  }

  openIndicatorAwarenessDialog(): void {
    if (!this.customIndicatorAwared) {
      this.showIndicatorAwarenessDialog = true;
      return;
    }
    this.addNewIndicator();
  }

  openIndicatorSelector(): void {
    this.showIndicatorSelector = true;
  }

  get defaultSelectedIndicators(): Indicators[] {
    return this.indicatorsData.filter(it => it.indicatorType === 'DEFAULT');
  }

  defaultIndicatorSelected(defaultIndicator: DefaultIndicatorEntity, selected: boolean): void {
    if (selected) {
      this.indicatorsData.push(Indicators.ofDefault(defaultIndicator));
      return;
    }
    const deletingIndex = this.indicatorsData.findIndex(it => it.id === defaultIndicator.id);
    this.indicatorsData.splice(deletingIndex, 1);
  }

  getIndicatorData() {
    this.loading = true;
    DrdService.getIndicators(this.performanceDescriptionId)
      .then((resp: any) => {
        this.indicatorsData = Indicators.ofList(resp.map(it => ({ ...it, saved: true })));
      }).catch(VbSnackbarService.handleHttpError)
      .finally(() => {
        this.loading = false;
      });
  }

  addNewIndicator() {
    this.indicatorsData.push(Indicators.of({}));
  }

  deleteIndicator(index: number): void {
    const indicatorDelete = this.indicatorsData[index];
    if (indicatorDelete.saved) {
      indicatorDelete.loadingDelete = true;
      DrdService.delete(this.performanceDescriptionId, indicatorDelete.id)
        .then(() => {
          this.indicatorsData.splice(index, 1);
          VbSnackbarService.showSuccess('Indicador deletado com sucesso');
        }).catch(VbSnackbarService.handleHttpError)
        .finally(() => indicatorDelete.loadingDelete = false);
      return;
    }
    this.indicatorsData.splice(index, 1);
  }

  save(): Promise<void> {
    const { form }: any = this.$refs;
    return new Promise((resolve, reject) => {
      if (!form.validate()) {
        reject();
        return;
      }
      this.loading = true;
      DrdService.updateIndicators(this.performanceDescriptionId, this.indicatorsData)
        .then(({ data }) => {
          VbSnackbarService.showSuccess('Indicadores salvos com sucesso!');
          this.indicatorsData = data.map(it => Indicators.of({ ...it, saved: true }));
          resolve();
        }).catch((err) => {
          VbSnackbarService.handleHttpError(err);
          reject();
        }).finally(() => {
          this.loading = false;
        });
    });
  }
}
