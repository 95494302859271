






import { Vue, Component, Prop } from 'vue-property-decorator';

@Component
export default class TalentLevelColor extends Vue {
  @Prop({
    type: String,
    default: '#000000',
  })
  private color!: string;

  private get formattedColor(): string {
    const hexadecimalFormat: RegExp = /^#[0-9a-fA-F]{6}$/;
    if (!hexadecimalFormat.test(this.color)) {
      return '#FFFFFF';
    }
    return this.color;
  }
}

