import axios from 'axios';
import ScheduleFilter from './schedule-filter.entity';
import EmployeeSchedulePage from './employee-schedule-page.entity';

export default class EmployeeScheduleService {
  static get(pagination: any, filter: ScheduleFilter):Promise<EmployeeSchedulePage> {
    return axios
      .post(`/api/employees/schedules?page=${pagination.page - 1}&size=${pagination.itemsPerPage}`, filter)
      .then((response: any) => EmployeeSchedulePage.of(response.data));
  }
}
