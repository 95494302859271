

























































































import {
  Vue, Component, Prop,
} from 'vue-property-decorator';

import PaginationComponent from '@/shared/pagination.component.vue';
import { VbSnackbarService, VbTooltipSlot } from 'bag-of-holding-library';
import ScoreList from '@/domain/employees/entity/score-list.entity';
import DateHandler from '@/shared/utils/date-format';
import VueToExcel from 'vue-json-excel';
import ScoreService from '@/domain/employees/service/score.service';
import FilterScore from '@/domain/drd/entity/filter-score.entity';
import Header from './header.json';

const statusTypes: any = {
  VALIDATION_COMPLETED: 'Concluído',
  PERFORMED: 'Acordo realizado',
  NOT_PERFORMED: 'Pendente de acordo',
  PERFORMED_RETROACTIVE: 'Acordo retroativo',
}

@Component({
  components: { PaginationComponent, VbTooltipSlot, VueToExcel },
})
export default class ScoreTableComponent extends Vue {
  header: any[] = Header;

  @Prop() items!: ScoreList[];

  @Prop({
    type: Boolean,
    default: false,
  })
  loading!: boolean;

  @Prop() page!: any;

  @Prop() filter!: FilterScore;

  loadingExport: boolean = false;

  exportData: ScoreList[] = [];

  exportProgress: number = 0;

  updateTable(page: any): void {
    this.$emit('update', page);
  }

  goToScoreDetails(scoreId: string) {
    this.$router.push({
      path: `/score/${scoreId}`,
    })
  }

  formatStatusName(status: string): string {
    return statusTypes[status]
  }

  xlsFields: any = {
    Colaborador: 'employee',
    'Comptência acordo ant.': 'previousScoreCompetence',
    Competência: 'dateToCount',
    'Data realização do acordo ant.': 'previousScoreCountDate',
    'Data realização do acordo': 'countDate',
    'Gestor acordo ant.': 'previousScoreResponsible',
    Gestor: 'manager',
    Unidade: 'businessUnit',
    'Status acordo ant.': 'previousScoreStatus',
    Status: 'status',
    'Data Validação acordo ant.': 'previousScoreValidationDate',
    'Data Validação': 'validationDate',
    'Benefícios para dependentes acordo ant.': 'hasPreviousScoreDependentBenefits',
    'Benefícios para dependentes': 'hasDependentBenefits',
    'Plano de saúde acordo ant.': 'hasPreviousHealthPlanEmployee',
    'Plano de saúde': 'hasHealthPlanEmployee',
    'PS Pago pela DB1 acordo ant.': 'hasPreviousHealthPlanEmployeePayedByDB1',
    'PS Pago pela DB1': 'hasHealthPlanEmployeePayedByDB1',
    'Abrangência acordo ant.': 'formattedPreviousScorePlanType',
    Abrangência: 'formattedPlanType',
    'Observação plano acordo ant.': 'previousScoreBenefitObservation',
    'Observação plano': 'benefitObservation',
    'Plano odontológico acordo ant.': 'hasPreviousScoreDentalPlanEmployee',
    'Plano odontológico': 'hasDentalPlanEmployee',
    'PO Pago pela DB1 acordo ant.': 'hasPreviousScoreDentalPlanEmployeePayedByDB1',
    'PO Pago pela DB1': 'hasDentalPlanEmployeePayedByDB1',
    'Seguro de vida acordo ant.': 'hasPreviousScoreLifeInsuranceEmployee',
    'Seguro de vida': 'hasLifeInsuranceEmployee',
    'cargo anterior': 'roleAgreement.previousAgreement',
    'cargo novo': 'roleAgreement.newAgreement',
    'senioridade anterior': 'seniorityAgreement.previousAgreement',
    'senioridade nova': 'seniorityAgreement.newAgreement',
    'salário em CLT anterior': 'salaryAgreement.previousAgreement',
    'salário em CLT novo': 'salaryAgreement.newAgreement',
    'salário % reajuste': 'salaryAgreement.readjustment',
    'Ducz anterior': 'duczAgreement.previousAgreement',
    'Ducz novo': 'duczAgreement.newAgreement',
    'Ducz % reajuste': 'duczAgreement.readjustment',
    'Ducz (Sintinorp/livre) anterior': 'duczSyndicateAgreement.previousAgreement',
    'Ducz (Sintinorp/livre) novo': 'duczSyndicateAgreement.newAgreement',
    'Ducz (Sintinorp/livre) % reajuste': 'duczSyndicateAgreement.readjustment',
    'Subsídio/Auxílio para Educação anterior': 'educationSubsidyAssistence.previousAgreement',
    'Subsídio/Auxílio para Educação novo': 'educationSubsidyAssistence.newAgreement',
    'Subsídio/Auxílio para Educação % reajuste': 'educationSubsidyAssistence.readjustment',
    'Benefícios Variáveis': 'hasVariableAgreements',
    total: 'totalAgreement.newAgreement',
    'Observação acordo acordo ant.': 'previousScoreObservation',
    'Observação acordo': 'observation',
    Moeda: 'currency.name',
  }

  async exportToXLS() {
    this.loadingExport = true;
    await this.findData();
    this.loadingExport = false;
    return this.exportData;
  }

  async findData() {
    this.exportData = [];
    const pagination = ({
      ...this.page,
      itemsPerPage: 50,
      page: 1,
    });

    do {
      // eslint-disable-next-line no-await-in-loop
      await ScoreService.findScore(pagination, this.page.sortBy[0], this.filter)
        .then((response: any) => {
          this.exportData.push(...response.content.map(item => new ScoreList({
            ...item,
            status: this.formatStatusName(item.status),
            previousScoreStatus: this.formatStatusName(item.previousScoreStatus),
            dateToCount: this.formatMonthYear(item.dateToCount),
            previousScoreCompetence: this.formatMonthYear(item.previousScoreCompetence),
            countDate: this.formatToLocal(item.countDate),
            previousScoreCountDate: this.formatToLocal(item.previousScoreCountDate),
            validationDate: this.formatToLocal(item.validationDate),
            previousScoreValidationDate: this.formatToLocal(item.previousScoreValidationDate),
          })));
          pagination.total = response.totalElements;
          pagination.page += 1;
        }).catch(error => VbSnackbarService.handleHttpError(error));
      this.exportProgress = (this.exportData.length / pagination.total) * 100;
    } while (this.exportData.length < pagination.total);
  }

  formatMonthYear(value: string) {
    return DateHandler.monthYearToFormated(value);
  }

  formatToLocal(value: string) {
    if (!value) { return '' }
    return DateHandler.timestampToLocal(value);
  }

  updateSortBy(sortBy: string): void {
    this.updateTable({
      ...this.page,
      sortBy,
    });
  }

  updateSortDesc(sortDesc: string): void {
    this.updateTable({
      ...this.page,
      sortDesc,
    });
  }

  get exportTooltip() {
    return this.items.length === 0
      ? 'Dados não encontrados para esta pesquisa'
      : 'Gerar CSV'
  }
}
