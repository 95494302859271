




































































































import {
  Component, Prop, Vue,
} from 'vue-property-decorator';
import LabelSlot from '@/shared/slots/label-slot.component.vue';
import Skill from '@/domain/drd-2.0/entity/skill.entity';
import CopyComponent from '@/shared/copy.component.vue';
import DateFormat from 'bag-of-holding-library/src/utils/date-format';
import SkillTypeChip from '../components/skill-type-chip.component.vue';

@Component({
  components: {
    LabelSlot,
    SkillTypeChip,
    CopyComponent,
  },
})
export default class ViewSkillModal extends Vue {
  @Prop({
    type: Boolean,
    default: false,
  }) open!: boolean;

  @Prop({
    required: true,
  }) skill!: Skill;

  loading: boolean = false;

  closeModal() {
    this.$emit('close');
  }

  formatDate(date: string) {
    return DateFormat.dateTimeFormated(date);
  }
}
