
































































import { Vue, Component } from 'vue-property-decorator';
import SkillFilter from '@/domain/drd-2.0/entity/skill-filter.entity';
import LabelSlot from '@/shared/slots/label-slot.component.vue';
import AssignmentFilter from '@/shared/filters/assignment/assignment-filter.component.vue';
import SelectSkillType from './select-skill-type.component.vue';
import SelectSkillGroup from './select-skill-group.component.vue';

@Component({
  components: {
    LabelSlot,
    AssignmentFilter,
    SelectSkillType,
    SelectSkillGroup,
  },
})
export default class SkillFilterComponent extends Vue {
  filter: SkillFilter = new SkillFilter();

  sendFilter() {
    this.$emit('sendFilter', this.filter);
  }
}
