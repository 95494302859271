
















































































































import { Vue, Component } from 'vue-property-decorator';
import { HappinessLevel } from '@/domain/happiness-level/entity/happiness-level.enum'
import HappinessLevelEntity from '@/domain/happiness-level/entity/happiness-level.entity';
import TextAreaComponent from '@/shared/fields/text-area.component.vue';
import ActivityHappiness from '@/domain/happiness-level/entity/activity-happiness.entity';
import MainButton from '@/shared/buttons/main-button.component.vue';
import HappinessLevelRequest from '@/domain/happiness-level/entity/happiness-level.request';
import { VbLoadingService, VbSnackbarService } from 'bag-of-holding-library';
import HappinessLevelService from '@/domain/happiness-level/service/happiness-level.service';
import HappinessSelector from './happiness-selector.component.vue';
import HapppinessLevelJson from './happiness-level.json';

@Component({ components: { HappinessSelector, TextAreaComponent, MainButton } })
export default class HappinessLevelView extends Vue {
  happinessLevels = HappinessLevelEntity.ofList(HapppinessLevelJson);

  request: HappinessLevelRequest = HappinessLevelRequest.of({})

  success: boolean = false;

  textAreaLabel: string = 'Fale mais sobre como você está se sentindo e sobre como podemos te ajudar:';

  happinessType: string = '';

  happinessTitle = 'Olá, como você está se sentindo hoje?';

  activity: ActivityHappiness = ActivityHappiness.of({});

  showReason: boolean = false;

  isPersonal: boolean = false;

  isProfessional: boolean = false;

  isBoth: boolean = false;

  hasSelected: boolean = false;

  hasChecked: boolean = false;

  get successMessage() {
    return this.happinessType === 'ACTIVITY'
      ? 'Qualitativo enviado com sucesso!'
      : 'Felicitômetro enviado com sucesso!'
  }


  created() {
    this.handleHappinessTypeAndLevel();
  }

  setIsPersonalAndIsProfessional(event) {
    if (this.isPersonal === false || this.isProfessional === false) {
      this.isPersonal = event;
      this.isProfessional = event;
      this.isBoth = event;
      this.hasChecked = event;
    } else if (event === false) {
      this.isPersonal = event;
      this.isProfessional = event;
      this.isBoth = event;
      this.hasChecked = event;
    }
  }

  setIsBoth(event) {
    this.hasChecked = event;
    if (this.isPersonal && this.isProfessional) {
      this.isBoth = event;
      this.hasChecked = event;
    }
  }

  handleHappinessTypeAndLevel() {
    this.happinessType = this.$route.params.type;
    this.request.applyType(this.happinessType);
    if (this.$route.params.level) {
      this.handleLevel(this.$route.params.level as HappinessLevel);
    }
    if (this.happinessType === 'ACTIVITY') {
      this.request.applyActivityId(this.$route.params.id);
      this.getActivity();
    }
  }

  handleLevel(level: HappinessLevel) {
    this.hasSelected = true;
    this.request.level = level;
    this.showReason = true;
    this.happinessLevels.forEach((el: HappinessLevelEntity) => {
      if (this.request.level === el.value) {
        el.selected = true;
      } else {
        el.selected = false;
      }
    })
  }

  getReasonType(): string | null {
    if (this.isBoth) {
      return 'BOTH';
    }
    if (this.isPersonal) {
      return 'PERSONAL';
    }
    if (this.isProfessional) {
      return 'PROFESSIONAL';
    }
    return null;
  }

  checkReasonsType() {
    this.request.reasonType = this.getReasonType();
  }

  saveHappinessLevel() {
    VbLoadingService.show();
    this.checkReasonsType();
    HappinessLevelService.save(this.request)
      .then((response: any) => {
        this.success = true;
      })
      .catch((err: any) => {
        VbSnackbarService.handleHttpError(err);
      })
      .finally(() => VbLoadingService.hide());
  }

  getActivity() {
    if (this.request.activityId) {
      VbLoadingService.show();
      HappinessLevelService.getActivity(this.request.activityId)
        .then((response: ActivityHappiness) => {
          this.activity = response;
          this.applyActivityType();
        })
        .catch((err: any) => VbSnackbarService.handleHttpError(err))
        .finally(() => VbLoadingService.hide());
    }
  }

  applyActivityType() {
    this.happinessTitle = this.activity.buildActivityTitle();
  }
}

