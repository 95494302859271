


















































































































































































































import _ from 'lodash';
import {
  Vue, Component, Prop, Watch,
} from 'vue-property-decorator';
import LabelSlot from '@/shared/slots/label-slot.component.vue';
import MainButton from '@/shared/buttons/main-button.component.vue';
import Rules from '@/shared/rules/rules';
import CareerService from '@/domain/employees/service/career.service';
import EmployeeSummary from '@/domain/schedule/employee/employee-summary.entity';
import EmployeeService from '@/domain/employees/service/employee.service';
import UpdateAtribute from '@/domain/employees/entity/update-atribute.entity';
import ModalComponent from '@/shared/modal/modal.component.vue';
import Assignment from '@/domain/drd/entity/assignment.entity';
import DrdService from '@/domain/drd/service/drd.service';
import ModalWarning from '@/shared/modal/modal-warning.component.vue';
import TechLead from '@/domain/employees/entity/tech-lead.entity';
import CareerUpdate from '../../../../domain/employees/entity/career-update.entity';
import ModalOptions from '../../../../shared/modal/modal-options.entity';

@Component({
  components: {
    LabelSlot,
    MainButton,
    ModalComponent,
    ModalWarning,
  },
})
export default class CreateUpdateModal extends Vue {
  @Prop() showModal!: boolean;

  @Prop() current!: CareerUpdate;

  @Prop() edit!: boolean;

  @Prop() loadingSaveButton!: boolean;

  @Prop({
    type: Boolean,
    default: false,
  }) isAssigmentReadonly!: boolean;

  @Prop({
    type: Boolean,
    default: false,
  }) isSeniorityReadonly!: boolean;

  key: string = new Date().toISOString();

  careerUpdate = new CareerUpdate({});

  labelColor: string = '#0099B7';

  rules: Rules = new Rules();

  content: any = {};

  businessUnit: UpdateAtribute[] = [];

  resultCenter: UpdateAtribute[] = [];

  managers: EmployeeSummary[] = [];

  techLeads: TechLead[] = [];

  ref: any = this.$refs;

  showAlert: boolean = false;

  alertMessage = 'Deseja realmente prosseguir? Ao clicar no botão Sim, as alterações serão imediatamente descartadas.';

  lastFilterManager = '';

  lastFilterTechLead = '';

  lastBusinessUnit: any = '';

  resultCenterInput: string = '';

  operationModalityInput: string = '';

  loadingRoles: boolean = false;

  loadingTechLeads: boolean = false;

  get optionsHandled(): ModalOptions {
    return {
      title: 'Carreira na DB1 - Evento',
      titleSize: '20px',
      description: '',
      showOk: true,
      disabledOk: false,
      labelOk: 'Salvar',
      okColor: 'tabBlue',
      showCancel: true,
      labelCancel: 'Cancelar',
      width: 700,
      loadingOk: this.loadingSaveButton,
    };
  }

  get modalWarningOptions(): ModalOptions {
    return {
      title: 'Atenção!',
      description: 'Deseja realmente prosseguir? Ao clicar no botão Sim, as alterações serão imediatamente descartadas.',
      showOk: true,
      disabledOk: false,
      labelOk: 'Sim',
      okColor: 'tabBlue',
      showCancel: true,
      labelCancel: 'Não',
      width: 400,
    };
  }

  isRoleNotActive(role: any): boolean {
    return !role.active;
  }

  get disabled() {
    return Boolean(this.careerUpdate.workplace.defaultOperationModality);
  }

  created() {
    this.getSelectContent();
    this.setCurrent();
  }

  @Watch('showModal')
  setCurrent() {
    if (this.current) {
      this.careerUpdate = CareerUpdate.of(this.current);
      this.getInitialContent();
      if (!this.edit) {
        this.careerUpdate.observation = '';
      }
    }
  }

  getInitialContent() {
    if (this.careerUpdate.manager) {
      this.findManagers(this.careerUpdate.manager.name);
    }
    if (this.careerUpdate.techLead) {
      this.findTechLeads(this.careerUpdate.techLead.name);
    }
    if (this.careerUpdate.businessUnit) {
      this.getResultCenter(this.careerUpdate.businessUnit);
    }
    this.careerUpdate.setOperationModality();
  }

  getSelectContent() {
    const request: any = {};
    this.loadingRoles = true;
    CareerService.getRoles().then((resp: any) => request.roles = resp.data)
      .finally(() => this.loadingRoles = false);
    CareerService.getWorkplace().then((resp: any) => request.workplace = resp.data);
    CareerService.getSeniority().then((resp: any) => request.seniority = resp.data);
    CareerService.getOperationModality().then((resp: any) => request.operationModality = resp.data);
    CareerService.getBusinessUnit().then(resp => this.businessUnit = resp);
    DrdService.getAssigments().then(response => request.assignments = response);
    this.content = request;
  }

  disabledItems(items): boolean {
    return !items.active;
  }

  get operationModalities() {
    if (this.careerUpdate.workplace && this.careerUpdate.workplace.id !== 'a01acede-8a19-cd29-e053-90d0a8c00366') {
      return this.content.operationModality.filter(value => value.id !== 'a01acede-8a12-cd29-e053-90d0a8c00366');
    }
    return this.content.operationModality;
  }

  changeManager(): void {
    this.careerUpdate.setDefaultBusinessUnit();
    this.businessUnitChanged(this.careerUpdate.businessUnit);
  }

  businessUnitChanged(businessUnit: any) {
    this.resetResultCenter();
    this.getResultCenter(businessUnit);
  }

  get isResultCenterOnList(): boolean {
    return this.resultCenter.some((el: any) => el.id === this.careerUpdate.resultCenter.id);
  }

  findManagers(value: string) {
    if ((this.lastFilterManager !== value) && value) {
      this.lastFilterManager = value;
      if (value.length > 3) {
        EmployeeService.findManagersByName(value)
          .then((resp: EmployeeSummary[]) => this.managers = resp);
      }
    }
  }

  findTechLeads(value: string) {
    if ((this.lastFilterTechLead !== value) && value) {
      this.lastFilterTechLead = value;
      if (value.length > 3) {
        this.loadingTechLeads = true;
        EmployeeService.findTechLeadsByName(value)
          .then((resp: TechLead[]) => this.techLeads = resp)
          .finally(() => { this.loadingTechLeads = false; });
      }
    }
  }

  getResultCenter(businessUnit: any) {
    if (businessUnit && this.lastBusinessUnit !== businessUnit) {
      this.lastBusinessUnit = businessUnit;
      CareerService.getResultCenter(businessUnit.id)
        .then(resp => this.resultCenter = resp);
    }
  }

  alertClose() {
    this.showAlert = true;
  }

  close() {
    this.$emit('close');
  }

  save() {
    if (this.edit) {
      this.updateCareer();
      return;
    }
    this.newCareer();
  }

  resetResultCenter() {
    this.careerUpdate.resultCenter = UpdateAtribute.of({});
    this.resultCenterInput = '';
  }

  setOperationModality() {
    this.careerUpdate.setOperationModality();
    if (this.careerUpdate.hasNoOperationModality()) {
      this.operationModalityInput = '';
    }
  }

  newCareer() {
    this.$emit('save', this.careerUpdate);
  }

  updateCareer() {
    return this.current.equals(this.careerUpdate)
      ? this.close()
      : this.$emit('update', this.careerUpdate);
  }

  setFields() {
    this.careerUpdate.role = _.cloneDeep(this.careerUpdate.role);
    this.careerUpdate.seniority = _.cloneDeep(this.careerUpdate.seniority);
  }

  setRoleByAssignment(assignment: Assignment) {
    this.careerUpdate.assignment = assignment;
    this.careerUpdate.role.id = assignment.role.id;
    this.careerUpdate.role.name = assignment.role.name;
  }

  assigmentsInactive(assigment: Assignment) {
    return assigment.inactive;
  }
}
