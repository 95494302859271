import FilterUtils from '@/shared/utils/filters';
import moment from 'moment';

export default class Wages {
  role: string;

  seniority: any;

  maxWage: number;

  mediumWage: number;

  minWage: number;

  benefit: number;

  lastModifiedDate: string;

  constructor(obj: any) {
    this.role = obj.role || '';
    this.seniority = obj.seniority || {};
    this.maxWage = obj.maxWage ? obj.maxWage.toFixed(2) : '';
    this.mediumWage = obj.mediumWage ? obj.mediumWage.toFixed(2) : '';
    this.minWage = obj.minWage ? obj.minWage.toFixed(2) : '';
    this.benefit = obj.benefit ? obj.benefit.toFixed(2) : '';
    this.lastModifiedDate = obj.lastModifiedDate || '';
  }

  public static of(obj: any): Wages {
    return new Wages(obj || {});
  }

  public static ofList(obj: any[]): Wages[] {
    return obj.map(this.of);
  }

  public getMaxWage(): number {
    return Number(this.maxWage + this.benefit);
  }

  public getMinWage(): number {
    return Number(this.minWage + this.benefit);
  }

  public getMediumWage(): number {
    return Number((this.getMaxWage() + this.getMinWage()) / 2);
  }

  get lastModifiedDateFormated() {
    return this.lastModifiedDate ? moment(this.lastModifiedDate).format('MM/YYYY') : '-';
  }

  prepareToSave() {
    this.seniority = this.seniority.id;
    this.maxWage = Number(FilterUtils.formatValue(this.maxWage.toString()));
    this.mediumWage = Number(FilterUtils.formatValue(this.mediumWage.toString()))
    this.minWage = Number(FilterUtils.formatValue(this.minWage.toString()))
    this.benefit = Number(FilterUtils.formatValue(this.benefit.toString()))
  }
}
