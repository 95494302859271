











































import { Component, Vue, Prop } from 'vue-property-decorator';
import CardTitle from '@/shared/card/card-title.component.vue';
import PerformanceDescription from '@/domain/drd/entity/performance-description.entity';
import LabelSlot from '@/shared/slots/label-slot.component.vue';
import {
  hasEmployeeAdministratorAuthority,
  hasManagerAuthority,
  hasAdministratorAuthority,
  hasFacilitiesOrEmployeeAdministratorAuthority,
} from '@/shared/directive/has-authority.directive';
import { VbTooltipSlot } from 'bag-of-holding-library';

@Component({
  components: {
    CardTitle, VbTooltipSlot, LabelSlot,
  },
  directives: {
    hasEmployeeAdministratorAuthority,
    hasManagerAuthority,
    hasAdministratorAuthority,
    hasFacilitiesOrEmployeeAdministratorAuthority,
  },
})
export default class PerformanceDescriptionHeader extends Vue {
  @Prop() performanceDescription!: PerformanceDescription;
}
