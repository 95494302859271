export default function (value: string, size: number) {
  if (value) {
    const splitContent = () => {
      if (value.length > size) {
        return `${value.substring(0, size)}...`;
      }
      return value;
    };
    return splitContent();
  }
  return value;
}
