import Indicators from '@/domain/drd/entity/indicators.entity';
import CreateKeyAreaSkillDTO from './create-key-area-skill.entity';

export default class CreateKeyAreaDTO {
  name: string;

  details: string;

  type: string;

  skills: CreateKeyAreaSkillDTO[];

  indicators: Indicators[];

  constructor(obj: any = {}) {
    this.name = obj.name || '';
    this.details = obj.details || '';
    this.skills = obj.skills ? obj.skills.map(it => new CreateKeyAreaSkillDTO(
      {
        ...it.skill,
        levels: it.levels,
        active: it.active,
        order: it.order,
      },
    )) : []
    this.indicators = obj.indicators || [];
    this.type = obj.type || '';
  }
}
