import ResignationStaffRequest from './resignation-staff-request.entity';

export default class StaffListRequest {
  staffList: ResignationStaffRequest[];

  constructor(obj: any) {
    this.staffList = ResignationStaffRequest.ofList(obj.staffList) || [];
  }

  public static of(obj: any): StaffListRequest {
    return new StaffListRequest(obj || {})
  }

  public static ofList(obj: any[]): StaffListRequest[] {
    return obj.map(this.of);
  }
}
