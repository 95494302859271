import { ContractType } from '@/views/employees/profile/tab-components/score/contract-type.type';

export interface Currency {
  name: string,
  description: string,
  symbol: string
}

export default class ProfessionalData {
  employeeId: string;

  profile: string;

  combined: string;

  dreamAndGoals: string;

  motivationalFactors: string;

  comments: string;

  currency: Currency;

  contractCountryId: string;

  contractType: ContractType;

  unlockBenefitPercent: boolean;

  constructor(obj: any = {}) {
    this.employeeId = obj.employeeId || '';
    this.profile = obj.profile || '';
    this.combined = obj.combined || '';
    this.dreamAndGoals = obj.dreamAndGoals || '';
    this.motivationalFactors = obj.motivationalFactors || '';
    this.comments = obj.comments || '';
    this.currency = obj.currency || '';
    this.contractCountryId = obj.contractCountryId || '';
    this.contractType = obj.contractType || null;
    this.unlockBenefitPercent = obj.unlockBenefitPercent;
  }
}
