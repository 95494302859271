











































import { Component, Prop, Mixins } from 'vue-property-decorator';
import MenuItem from './menu-item.component.vue';
import MenuMixin from './menu.mixin';
import MenuAuthorityMixin from './menu-authority.mixin';

@Component({
  name: 'menu-item-or-group',
  components: { MenuItem },
})
export default class MenuItemOrGroup extends Mixins(MenuMixin, MenuAuthorityMixin) {
  @Prop({ type: Object, required: true })
  private menu!: any;

  @Prop({ type: Boolean, default: false })
  private selected: boolean = false;

  private getFilteredButtons(menus: any[]): any[] {
    const buttons: any[] = []

    menus.filter(this.hasAuthority).forEach((children) => {
      const have = buttons.some(it => it.text === children.text)
      if (!have) {
        buttons.push(children)
      }
    })
    return buttons
  }

  get color() {
    return this.level > 1 ? '#015b85' : '#00335E';
  }

  private onSelect(): void {
    this.$emit('select');
  }
}
