





















import {
  Vue, Prop, Component, Emit,
} from 'vue-property-decorator';
import LabelSlot from '@/shared/slots/label-slot.component.vue';

@Component({
  components: {
    'label-slot': LabelSlot,
  },
})
export default class Textarea extends Vue {
  @Prop({
    required: true,
  }) private label!: string;

  @Prop({
    required: false,
    default: false,
  }) private requiredField!: boolean;

  @Prop({
    required: false,
    default: '',
  }) private placeholder!: string;

  @Prop({
    required: false,
    default: false,
  }) private isReadonly!: boolean;

  private get requiredRules(): any {
    const rules: any = [(v: any) => (!!v && v.trim().length !== 0) || 'O campo é obrigatório'];

    if (this.requiredField) {
      return rules;
    }
    return [];
  }

  @Emit('input')
  private sendInput(text: string): string {
    return text;
  }
}
