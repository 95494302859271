


















import { Vue, Component, Prop } from 'vue-property-decorator';
import Rules from '../rules/rules';
import LabelSlot from '../slots/label-slot.component.vue';

@Component({
  components: {
    LabelSlot,
  },
})
export default class EmailInput extends Vue {
  @Prop({
    type: String,
    default: 'Email',
  }) label!: string;

  @Prop({
    type: String,
    default: 'Informe um email...',
  }) placeholder!: string;

  @Prop({
    type: String,
    required: true,
  }) email!: string;

  @Prop() rules!: Rules;

  update(value: string) {
    this.$emit('update', value);
  }
}
