











import { Vue, Component, Prop } from 'vue-property-decorator';
import EmployeeService from '@/domain/employees/service/employee.service';
import avatarUser from '@/assets/images/avatar-user.png';

@Component
export default class EmployeeAvatarComponent extends Vue {
  @Prop({ type: String, default: '' })
  employeeId!: string

  avatar: string = avatarUser;

  created(): void {
    EmployeeService.getEmployeePhotoBinary(this.employeeId).then((response) => {
      const url = URL.createObjectURL(response.data);
      this.avatar = url;
    }).catch(() => {
      this.avatar = avatarUser;
    });
  }
}
