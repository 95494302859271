import axios from 'axios';
import ActivityHappiness from '../entity/activity-happiness.entity';
import HappinessLevesRequest from '../entity/happiness-level.request';

export default class HappinessLevelService {
  public static save(request: HappinessLevesRequest): Promise<void> {
    return axios.post('/api/employees/happiness', {
      ...request,
      type: request.type.toUpperCase(),
    });
  }

  public static getActivity(activityId: string) {
    return axios.get(`/api/activities/${activityId}`)
      .then((response: any) => ActivityHappiness.of(response.data));
  }
}
