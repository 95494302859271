import axios from 'axios';
import DrdFilter from '../entity/drd-filter';
import PersistDrd from '../entity/persist-drd.entity';
import Drd from '../entity/drd.entity';

export default class DrdService {
  public static async filter(
    filter: DrdFilter,
    page: number,
    size: number,
  ) {
    return axios.get('/api/drd', {
      params: {
        ...filter,
        page,
        size,
      },
    });
  }

  public static async create(data: PersistDrd) {
    return axios.post('/api/drd', data);
  }

  public static async update(data: PersistDrd) {
    return axios.put('/api/drd', data);
  }

  public static async getByAssignmentId(id: string): Promise<Drd> {
    return axios.get(`/api/drd/${id}`)
      .then(response => new Drd(response.data));
  }

  public static async delete(assignmentId: string, indicatorId: string): Promise<void> {
    return axios.delete(`/api/drd/${assignmentId}/indicator/${indicatorId}`)
  }
}
