




















import { Vue, Component } from 'vue-property-decorator';
import CardTitle from '@/shared/card/card-title.component.vue';
import AttendanceService from '@/domain/attendance/service/attendance.service';
import ManagerService from '@/domain/employees/service/employee.service';
import Attendance from '@/domain/attendance/entity/attendance.entity';
import EmployeeSummary from '../../../domain/schedule/employee/employee-summary.entity';
import Filter from '../../../domain/attendance/entity/filter.entity';
import UpdateAtribute from '../../../domain/employees/entity/update-atribute.entity';
import HeaderComponent from './components/form-header.component.vue';
import DefaultComponent from './components/default-component.vue';

@Component({
  components: {
    CardTitle,
    HeaderComponent,
    DefaultComponent,
  },
})
export default class Attendances extends Vue {
  private attendance: Attendance = Attendance.of({});

  private filterEmployees: EmployeeSummary[] = [];

  private businessUnit: UpdateAtribute[] = [];

  private lastFilterManager: string = '';

  private lastFilterEmployee: string = '';

  private tableLoading: boolean = false;

  private isRegularAttendance: boolean = true;

  private isFollowUpInterview: boolean = false;

  private lastFilter: Filter = new Filter({});

  private filter: Filter = Filter.of({});

  private managers: EmployeeSummary[] = [];

  private typeDescription: string = ''

  private status: string = '';

  private created() {
    this.getDate();
  }

  private getDate() {
    if (this.$route.params.id) {
      AttendanceService.getAttendance(this.$route.params.id)
        .then((response: Attendance) => {
          this.attendance = response;
        });
    }
  }

  private findManagers(value: string) {
    if ((this.lastFilterManager !== value) && value) {
      this.lastFilterManager = value;
      if (value.length > 2) {
        ManagerService.findManagersByName(value)
          .then((resp: EmployeeSummary[]) => this.managers = resp);
      }
    }
  }
}

