import KeyAreaSkillLevel from './key-area-skill-level.entity';
import Skill from './skill.entity';

export default class KeyAreaSkill {
  skill: Skill;

  levels: KeyAreaSkillLevel[];

  active: boolean = true;

  order: number = 1;

  isDeletable: boolean = false;

  constructor(obj: any = {}) {
    this.skill = new Skill(obj.skill);
    this.levels = obj.levels || [];
    this.active = obj.active;
    this.order = obj.order;
    this.isDeletable = obj.isDeletable;
  }

  get skillWhatPTBR() {
    return this.skill.whatPTBR;
  }
}
