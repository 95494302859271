






























import { Vue, Component } from 'vue-property-decorator';
import DrdService from '@/domain/drd/service/drd.service';
import RoleResponse from '@/domain/drd/entity/roleResponse.entity';
import RoleDrdList from '@/domain/drd/entity/roleDrdList.entity';
import Authority from '@/domain/security/authority.entity';
import { VbSnackbarService } from 'bag-of-holding-library';
import LabelSlot from '@/shared/slots/label-slot.component.vue';
import RoleFilter from '@/domain/drd/entity/role-filter.entity';
import Assignment from '@/domain/drd/entity/assignment.entity';
import AuthorityStore from '@/domain/security/authority.store';
import RoleList from '../table/role-list.component.vue';
import RoleDialog from '../form/role-dialog.component.vue';
import FilterRole from './filter-role.component.vue';

@Component({
  components: {
    RoleList,
    RoleDialog,
    LabelSlot,
    FilterRole,
  },
})
export default class RoleComponent extends Vue {
  showDialog: boolean = false;

  renderDialog: boolean = true;

  roles: RoleDrdList[] = [];

  editingRoleId: string | null = null;

  loadingRoles: boolean = false;

  sortBy: Array<string> = ['name'];

  sortDesc: boolean[] = [false];

  direction: string = 'desc';

  sort: string = '';

  pagination: any = {
    itemsPerPage: 10,
    page: 1,
    sortBy: this.sortBy,
    sortDesc: this.sortDesc,
    total: 0,
    pageCount: 0,
  }

  authority: Authority = new Authority([]);

  updateRoles: boolean = false;

  inactiveRoles: boolean = false;

  readRoles: boolean = false;

  filter: RoleFilter = new RoleFilter();

  rolesFiltered: RoleDrdList[] = [];

  lastFilterRole: string = '';

  lastFilterAssigment: string = '';

  assignmentFiltered: Assignment[] = [];

  openDialog(): void {
    this.resetDialog();
    this.showDialog = true;
  }

  resetDialog(): void {
    this.renderDialog = false;
    this.$nextTick(() => {
      this.renderDialog = true;
    });
  }

  openDialogForNewRole(): void {
    this.editingRoleId = null;
    this.openDialog();
  }

  editRole(roleId: string): void {
    this.editingRoleId = roleId;
    this.openDialog();
  }

  inactivateRole(roleId: string): void {
    DrdService.disableRole(roleId)
      .then(() => {
        this.loadRole();
        VbSnackbarService.showSuccess('Cargo inativado com sucesso!');
      })
      .catch((error: any) => {
        VbSnackbarService.handleHttpError(error);
      });
  }

  loadRole(value: string = ''): void {
    this.getPaginationFields();
    DrdService.getAllRoles(
      this.pagination.page,
      this.pagination.itemsPerPage,
      this.sort,
      this.direction,
      this.filter,
    )
      .then((response: RoleResponse) => {
        this.roles = response.content;
        this.pagination.total = response.totalElements || 0;
        this.pagination.pageCount = response.totalPages;
      })
      .finally(() => {
        this.loadingRoles = false;
      });
  }

  setFilterValues(value: any) {
    if (this.pagination.itemsPerPage < value.itemsPerPage) {
      value.page = 1;
    }
    this.pagination = value;
    this.loadRole();
  }

  created(): void {
    this.loadRole();
    this.validationAuthorities();
  }

  getPaginationFields() {
    this.direction = this.pagination.sortDesc[0] ? 'desc' : 'asc';
    this.loadingRoles = true;
    this.sort = this.pagination.sortBy.length < 1 ? '' : this.pagination.sortBy[0];
    if (this.sort === 'assignment') this.sort = 'assignment.description';
  }

  async validationAuthorities() {
    await this.getAuthority();
    this.updateRoles = this.authority.hasUpdateRoles();
    this.inactiveRoles = this.authority.hasInactiveRoles();
    this.readRoles = this.authority.hasReadRoles();
  }

  async getAuthority() {
    await AuthorityStore.fetchedAuthority().then(authority => this.authority = authority);
  }

  findRolesByName(roleName: string) {
    if ((this.lastFilterRole !== roleName) && roleName) {
      this.lastFilterRole = roleName;
      if (roleName.length > 2) {
        DrdService.findRolesByName(roleName).then(response => this.rolesFiltered = response);
      }
    }
  }

  findAssignment(assignmentDescription: string) {
    if ((this.lastFilterAssigment !== assignmentDescription) && assignmentDescription) {
      this.lastFilterAssigment = assignmentDescription;
      if (assignmentDescription.length > 2) {
        DrdService.getAssigments(assignmentDescription)
          .then(response => this.assignmentFiltered = response);
      }
    }
  }

  sendFilter(filter: RoleFilter) {
    this.filter = filter;
    this.loadRole();
  }
}

