


































































































































































































import {
  Vue, Component, Prop, Watch,
} from 'vue-property-decorator';
import DatePickerField from '@/shared/date-picker/date-picker-field.component.vue'
import CardTitle from '@/shared/card/card-title.component.vue';
import LabelSlot from '@/shared/slots/label-slot.component.vue';
import splitContent from '@/shared/directive/split-content';
import DateHandler from '@/shared/utils/date-format';
import Attendance from '@/domain/attendance/entity/attendance.entity';
import EmployeeSummary from '@/domain/schedule/employee/employee-summary.entity';
import AuthorityStore from '@/domain/security/authority.store';
import Authority from '@/domain/security/authority.entity';
import EmployeeLoggedService from '@/domain/employees/service/employee.service'
import Rules from '@/shared/rules/rules';
import EmployeeAttendanceDTO from '@/domain/attendance/entity/employee-attendance-dto';
import RegularComponent from './regular-component.vue';
import ResignationComponent from './resignation-component.vue';
import FooterComponent from './form-footer.component.vue';

@Component({
  components: {
    LabelSlot, DatePickerField, CardTitle, RegularComponent, ResignationComponent, FooterComponent,
  },
  filters: {
    splitContent,
    formatDate(value: string) {
      return DateHandler.timestampToLocal(value.split('T')[0]);
    },
  },
})
export default class AttendanceDefaultComponent extends Vue {
  @Prop() managers!: EmployeeSummary[];

  @Prop() attendance!: Attendance;

  @Prop() attendanceId!: string;

  @Prop() availableEmails!: string[];

  valid: boolean = false;

  loading = false;

  rules: Rules = new Rules();

  isOpen: boolean = false;

  isCriticalTypes = [
    {
      value: false,
      description: 'Não',
    },
    {
      value: true,
      description: 'Sim',
    },
  ];

  requestReason = [
    {
      value: 'EMPLOYEE_REQUEST',
      description: 'Colaborador(a) solicitou',
    },
    {
      value: 'MANAGER_REQUEST',
      description: 'Gestor identificou a necessidade',
    },
    {
      value: 'TEAM_REQUEST',
      description: 'Equipe identificou a necessidade',
    },
    {
      value: 'BUSINESS_PARTNER_REQUEST',
      description: 'BP Identificou a necessidade',
    },
    {
      value: 'DISSATISFACTION',
      description: 'Visto insatisfação no felicitômetro',
    },
  ];

  responsibles: EmployeeSummary[] = [];

  managerName: string = '';

  lastEmployeeFilter: string = '';

  authority: Authority = new Authority([]);

  updateAttendance: boolean = false;

  deleteAttendance: boolean = false;

  async created() {
    await this.getAuthority();
    this.updateAttendance = this.authority.hasUpdateAttendance();
    this.deleteAttendance = this.authority.hasDeleteAttedance();
  }

  @Watch('attendance')
  initResponsible() {
    if (this.attendance.isOpen()) {
      this.isOpen = true;
      this.searchByLoggeedResponsibleId();
    }
  }

  searchByLoggeedResponsibleId() {
    EmployeeLoggedService.getLoggedUserEmployeeId().then((employeeId: string) => {
      EmployeeLoggedService.getEmployee(employeeId)
        .then(resp => this.attendance.responsible = EmployeeAttendanceDTO.of(resp))
    });
  }

  findResponsible(value: string) {
    this.$emit('findManagers', value);
  }

  async getAuthority() {
    await AuthorityStore.fetchedAuthority().then(authority => this.authority = authority);
  }
}

