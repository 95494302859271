


























import { Vue, Component } from 'vue-property-decorator'
import { VbSnackbarService } from 'bag-of-holding-library';
import LabelSlot from '@/shared/slots/label-slot.component.vue';
import KeyArea from '@/domain/drd-2.0/entity/key-area.entity';
import KeyAreaService from '@/domain/drd-2.0/service/key-area.service';
import Level from '@/domain/drd-2.0/entity/level.entity';
import CreateKeyAreaDTO from '@/domain/drd-2.0/entity/create-key-area.entity';
import FormComponent from './components/form.component.vue';
import SkillTypes from '../skill/skill-types.json';

@Component({
  components: {
    LabelSlot,
    FormComponent,
  },
})
export default class CreateKeyAreaView extends Vue {
  keyArea: KeyArea = new KeyArea();

  openSelectSkillDialog: boolean = false;

  levels: Level[] = [];

  loading: boolean = false;

  skillTypes = SkillTypes;

  save() {
    const { formComponent } = this.$refs as any;
    const { form } = formComponent.$refs;
    if (!form.validate()) return;
    this.loading = true;
    this.keyArea.type = 'DEFAULT';
    const data: CreateKeyAreaDTO = new CreateKeyAreaDTO(this.keyArea);
    KeyAreaService.create(data).then(() => {
      VbSnackbarService.showSuccess('Area Chave criada com sucesso!');
      this.$destroy();
      this.$router.push('/keyarea/list');
    }).catch((error) => {
      VbSnackbarService.showError(error.response.data.messages.toString());
    }).finally(() => this.loading = false);
  }
}
