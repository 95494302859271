import SeniorityDrd from './seniorityDrd.entity';

export default class AssignmentRole {
  public id: string;

  public name: string;

  constructor(obj: any = {}) {
    this.id = obj.id || '';
    this.name = obj.name || '';
  }
}
