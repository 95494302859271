export default {
  toNumber(value) {
    if (typeof value === 'number') {
      return value;
    }

    if (!value) {
      return null;
    }

    let valueWithoutCurrencySymbol = value;

    if (value.startsWith('R$ ')) {
      valueWithoutCurrencySymbol = value.substring(3);
    }

    if (!valueWithoutCurrencySymbol) {
      return null;
    }

    return parseFloat(valueWithoutCurrencySymbol);
  },
}
