import { render, staticRenderFns } from "./main-menu.component.vue?vue&type=template&id=86985b48&scoped=true&"
import script from "./main-menu.component.vue?vue&type=script&lang=ts&"
export * from "./main-menu.component.vue?vue&type=script&lang=ts&"
import style0 from "./main-menu.component.vue?vue&type=style&index=0&id=86985b48&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "86985b48",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VList } from 'vuetify/lib/components/VList';
installComponents(component, {VList})
