












import { Vue, Component } from 'vue-property-decorator'
import Survey from '@/domain/survey/entity/survey.entity';
import SurveyAnswer from '@/domain/survey/entity/fit-cultural.entity';
import SuccessCardComponent from './success-card.component.vue';
import SurveyFormComponent from './survey-form.component.vue';

@Component({
  components: {
    SuccessCardComponent,
    SurveyFormComponent,
  },
})
export default class Dinamics extends Vue {
  survey: Survey = new Survey();

  surveyAnswer: SurveyAnswer = new SurveyAnswer();

  getSurveyLoading: boolean = false;

  sent: boolean = false;

  acronymLanguageSelected: string = '';
}
