





































































import { Vue, Component, Prop } from 'vue-property-decorator';
import LabelSlot from '@/shared/slots/label-slot.component.vue';
import Rules from '@/shared/rules/rules';
import SeniorityDrd from '@/domain/drd/entity/seniorityDrd.entity';
import CareerService from '@/domain/employees/service/career.service';
import percentagesJson from './percentages.json';

@Component({ components: { LabelSlot } })
export default class SeniorityList extends Vue {
  @Prop({
    type: Array,
    default: () => ([]),
  })
  private seniorityList!: SeniorityDrd[];

  @Prop({
    type: Boolean,
  }) private updateRoles!: boolean;

  private created() {
    this.getSeniorities();
  }

  private seniorities: any = [];

   private rules: Rules = new Rules();

  private percentages = percentagesJson;

  private getSeniorities() {
    CareerService.getSeniority().then((resp: any) => this.seniorities = resp.data);
  }

  private addSeniority(): void {
    this.seniorityList.push(new SeniorityDrd());
  }
}
