

















import { Vue, Prop, Component } from 'vue-property-decorator';
import LabelSlot from '@/shared/slots/label-slot.component.vue';

@Component({
  components: {
    'label-slot': LabelSlot,
  },
})
export default class ReadOnlyTextField extends Vue {
  @Prop({
    required: true,
  })
  private label!: string;

  @Prop({
    required: true,
  })
  private value!: string;
}
