import FilterUtils from '@/shared/utils/filters';

export default class SeniorityRemunerationRequest {
  seniorityId: string;

  maxWage: number;

  mediumWage: number;

  minWage: number;

  benefit: number;

  constructor(obj: any) {
    this.seniorityId = obj.seniority.id;
    this.maxWage = Number(FilterUtils.formatValue(obj.maxWage.toString()));
    this.mediumWage = Number(FilterUtils.formatValue(obj.mediumWage.toString()));
    this.minWage = Number(FilterUtils.formatValue(obj.minWage.toString()));
    this.benefit = Number(FilterUtils.formatValue(obj.benefit.toString()));
  }

  public static of(obj: any): SeniorityRemunerationRequest {
    return new SeniorityRemunerationRequest(obj || {});
  }

  public static ofList(obj: any[]): SeniorityRemunerationRequest[] {
    return obj.map(this.of);
  }
}
