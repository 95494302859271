






import { Vue, Component } from 'vue-property-decorator';
import MenuHeaderUser from './menu-header-user.component.vue';
import TranslateMenuComponent from './translate-menu.component.vue';

@Component({ components: { MenuHeaderUser, TranslateMenuComponent } })
export default class MenuHeaderOptions extends Vue {
}
