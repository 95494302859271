












































































































































































































import {
  Vue, Component, Prop,
} from 'vue-property-decorator';
import { VbSnackbarService } from 'bag-of-holding-library';
import PaginationComponent from '@/shared/pagination.component.vue';
import LabelSlot from '@/shared/slots/label-slot.component.vue';
import MobileHandler from '@/shared/utils/mobile-handler';
import splitContent from '@/shared/directive/split-content';
import RiskAttendanceService from '@/domain/risk-attendance/service/risk-attendance.service';
import DateHandler from '@/shared/utils/date-format';
import FilteredRiskAttendance from '@/domain/risk-attendance/entity/filtered-risk-attendance';
import ModalWarning from '@/shared/modal/modal-warning.component.vue';
import Header from './header.json';

@Component({
  components: { PaginationComponent, LabelSlot, ModalWarning },
  filters: {
    splitContent,
    formatDate(value: string) {
      return DateHandler.timestampToLocal(value.split('T')[0]);
    },
  },
})
export default class TableRiskAttendanceComponent extends Vue {
  @Prop() items!: FilteredRiskAttendance[];

  @Prop() loading!: boolean;

  @Prop() page!: any;

  @Prop() hasDeleteRiskAttendance!: boolean;

  private header = Header;

  private isMobile = false;

  private warningModal: boolean = false;

  private attendanceId: string = '';

  private updateTable(pagination: any): void {
    this.$emit('update', pagination);
  }

  private closeWarningModal(): void {
    this.warningModal = false;
  }

  private openWarningModal(id: string): void {
    this.attendanceId = id;
    this.warningModal = true;
  }

  private onResize() {
    this.isMobile = MobileHandler.isMobile();
  }

  private select(value: string): void {
    this.$router.push({
      path: `/risk-attendance/${value}/`,
    })
  }

  private deleteRiskAttendance() {
    this.closeWarningModal();
    this.loading = true;

    RiskAttendanceService.deleteRiskAttendance(this.attendanceId)
      .then(() => {
        VbSnackbarService.showSuccess('Risco excluído com sucesso!');
        this.$emit('refresh');
      })
      .catch((err: any) => {
        VbSnackbarService.handleHttpError(err)
      }).finally(() => {
        this.loading = false;
      });
  }
}

