

























































































import { Vue, Component, Prop } from 'vue-property-decorator';
import { VbSnackbarService } from 'bag-of-holding-library';
import ResignationFilter from '@/domain/resignation/entity/resignation-filter.entity';
import LabelSlot from '@/shared/slots/label-slot.component.vue';
import DatePickerField from '@/shared/date-picker/date-picker-field.component.vue'
import EmployeeService from '@/domain/schedule/employee/employee.service';
import EmployeeSummary from '@/domain/schedule/employee/employee-summary.entity';
import CareerService from '@/domain/employees/service/career.service';
import UpdateAtribute from '@/domain/employees/entity/update-atribute.entity';

@Component({
  components: { LabelSlot, DatePickerField },
})
export default class ResignationsListFilterComponent extends Vue {
  @Prop({
    type: ResignationFilter,
    required: true,
  })
  private filters!: ResignationFilter;

  private employeeSearchText: string = '';

  private loadingEmployees: boolean = false;

  private managerSearchText: string = '';

  private loadingManagers: boolean = false;

  private employees: EmployeeSummary[] = [];

  private managers: EmployeeSummary[] = [];

  private businessUnits: UpdateAtribute[] = [];

  private loadingBusinessUnits: boolean = true;

  private updateField(field: string, value: any): void {
    this.$emit(`update:${field}`, value);
  }

  private searchEmployees(searchText: string): void {
    if (searchText == null || this.employeeSearchText === searchText) return;
    this.employeeSearchText = searchText;
    if (this.employeeSearchText.length > 2) {
      this.loadingEmployees = true;
      EmployeeService.findEmployeesByName(this.employeeSearchText)
        .then((employees: EmployeeSummary[]) => {
          this.employees = employees;
        }).catch(VbSnackbarService.handleHttpError)
        .finally(() => this.loadingEmployees = false);
    }
  }

  private searchManagers(searchText: string): void {
    if (searchText == null || this.managerSearchText === searchText) return;
    this.managerSearchText = searchText;
    if (this.managerSearchText.length > 2) {
      this.loadingManagers = true;
      EmployeeService.findManagersByName(this.managerSearchText)
        .then((managers: EmployeeSummary[]) => {
          this.managers = managers;
        }).catch(VbSnackbarService.handleHttpError)
        .finally(() => this.loadingManagers = false);
    }
  }

  private loadBusinessUnits(): void {
    this.loadingBusinessUnits = true;
    CareerService.getBusinessUnit().then((businessUnits: UpdateAtribute[]) => {
      this.businessUnits = businessUnits;
    }).catch(VbSnackbarService.handleHttpError)
      .finally(() => this.loadingBusinessUnits = false);
  }

  private created(): void {
    this.loadBusinessUnits();
  }
}
