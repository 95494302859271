





















































































































































































































































import { Vue, Component, Prop } from 'vue-property-decorator';
import ResignationService from '@/domain/resignation/service/resignation.service';
import ResignationDetails from '@/domain/resignation/entity/resignation-details.entity';
import DatePickerField from '@/shared/date-picker/date-picker-field.component.vue';
import LabelSlot from '@/shared/slots/label-slot.component.vue';
import { VbSnackbarService } from 'bag-of-holding-library';
import demissionTypes from '@/views/employees/profile/resignation/demission-types.json';
import DateFormat from '@/shared/utils/date-format';
import devolutionTypes from '@/views/employees/profile/resignation/devolution-types.json';
import Loader from '@/views/drd/commons/loader.component.vue';
import Rules from '@/shared/rules/rules';
import moment from 'moment';
import ResignationSelectBackupTypes from '@/views/employees/profile/resignation/resignation-select-backup-types.component.vue';

@Component({
  components: {
    DatePickerField, LabelSlot, Loader, ResignationSelectBackupTypes,
  },
})
export default class ResignationEditModal extends Vue {
 @Prop() dialog!: boolean;

 @Prop() resignationId!: string;

 @Prop() hasUpdateResignations!: boolean;

 private resignation: ResignationDetails = ResignationDetails.of({});

 private loading: boolean = true;

 private demissionTypes: any[] = demissionTypes;

 private rules: Rules = new Rules();

 private loadingSave: boolean = false;

 private get devolutionType() {
   const type:any = devolutionTypes.find(it => it.value === this.resignation.devolutionType);
   if (!type) return '';
   return type.description;
 }

 private get createdDate() {
   return DateFormat.dateTimeFormated(this.resignation.createdDate);
 }

 private save():void {
   const { form }:any = this.$refs;
   if (!form.validate()) { return; }
   this.loadingSave = true;
   ResignationService.updateResignation(this.resignation).then(() => {
     this.$emit('saved');
     this.closeDialog();
     VbSnackbarService.showSuccess('Desligamento alterado com sucesso. Por favor, verifique sua caixa de e-mail.');
   }).catch(VbSnackbarService.handleHttpError).finally(() => {
     this.loadingSave = false;
   })
 }

 private closeDialog():void {
   this.$emit('close');
 }

 isShutdownDateValid(): boolean | string {
   const shutdownDate = moment(this.resignation.shutdownDate);
   const requestDate = moment(this.resignation.requestDate);
   return requestDate.isSameOrBefore(shutdownDate) || 'Data deve ser maior ou igual à do pedido';
 }

 isAccessExpirationValid(): boolean | string {
   return this.resignation.shutdownTime > this.resignation.accessExpiration || 'Horário precisa ser maior que o horário de saída';
 }

 created() {
   this.loading = true;

   ResignationService.getResignationById(this.resignationId)
     .then(({ data }:any) => {
       this.resignation = new ResignationDetails(data);
     }).catch(VbSnackbarService.handleHttpError).finally(() => {
       this.loading = false;
     });
 }

 setBackupTypes(value: string[]) {
   this.resignation.backupTypes = value;
 }

 getBackupDescription(backupType: string) {
   const backupDescription = {
     EMAIL: 'Email',
     ONE_DRIVE: 'One drive',
     LOCAL_FILES: 'Arquivos locais',
     NONE: 'Nenhum',
   }

   return backupDescription[backupType];
 }

 resignationType(demissionType: string): string {
   return this.demissionTypes.filter(it => it.name === demissionType)[0].description;
 }

 dateFormat(date: string) {
   return moment(date).format('DD/MM/YYYY');
 }
}

