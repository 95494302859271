













import { Vue, Component, Prop } from 'vue-property-decorator';

@Component
export default class FeedbackReadComponent extends Vue {
  @Prop({ type: Array, required: true })
  private points!: string[];

  @Prop({ type: String, required: true })
  private title!: string;
}
