


























































import { Component, Prop, Vue } from 'vue-property-decorator';
import CardTitle from '@/shared/card/card-title.component.vue';
import {
  hasAdministratorAuthority,
  hasAdministratorOrManagerAuthority,
  hasManagerAuthority,
  hasBusinessPartnerAuthority,
  hasEmployeeAdministratorAuthority,
} from '@/shared/directive/has-authority.directive';
import ScheduleFilter from '@/domain/schedule/employee/schedule-filter.entity';
import EmployeeService from '@/domain/employees/service/employee.service';

@Component({
  components: {
    CardTitle,
  },
  directives: {
    hasAdministratorAuthority,
    hasAdministratorOrManagerAuthority,
    hasManagerAuthority,
    hasBusinessPartnerAuthority,
    hasEmployeeAdministratorAuthority,
  },
})
export default class ScheduleHeader extends Vue {
  @Prop() filter!: ScheduleFilter;

  @Prop() isManager!: Boolean;

  userLoggedInId!: string;

  applyFilter() {
    this.$emit('filter', this.filter);
  }

  created() {
    EmployeeService.getLoggedUserEmployeeId().then((employeeId: string) => {
      this.userLoggedInId = employeeId;
    });
  }
}
