

















































































































































































import { Vue, Component, Prop } from 'vue-property-decorator';
import { VMoney } from 'v-money';
import { VbSnackbarService } from 'bag-of-holding-library';
import { hasAdministratorAuthority } from '@/shared/directive/has-authority.directive';
import ModalComponent from '@/shared/modal/modal.component.vue';
import ModalOptions from '@/shared/modal/modal-options.entity';
import LabelSlot from '@/shared/slots/label-slot.component.vue';
import UpdateAtribute from '@/domain/employees/entity/update-atribute.entity';
import WageService from '@/domain/wages/services/wages.service';
import Wages from '@/domain/wages/entity/wages.entity';
import Rules from '@/shared/rules/rules';
import WageRequest from '@/domain/wages/entity/seniority-remuneration-request.entity';
import FilterUtils from '@/shared/utils/filters';
import AuthorityStore from '@/domain/security/authority.store';
import PersistSeniorityRemuneration from '@/domain/wages/entity/persist-seniority-remuneration.entity';
import WageHistory from '@/domain/wages/entity/wage-history.entity';
import HistoryComponent from './history.component.vue';
import HandleCreateHistoryModal from './handle-create-history-modal.component.vue';

@Component({
  components: {
    ModalComponent,
    LabelSlot,
    HistoryComponent,
    HandleCreateHistoryModal,
  },
  directives: {
    money: VMoney, hasAdministratorAuthority,
  },
})
export default class EditRemunerationModal extends Vue {
  @Prop() show!: boolean;

  @Prop() roles!: UpdateAtribute[];

  @Prop() seniority!: any[];

  @Prop({
    type: Boolean,
    default: false,
  }) isUpdateTableSalary!: boolean;

  @Prop({
    type: String,
    default: '',
  }) selectedRoleName!: string;

  @Prop({
    type: Boolean,
    default: false,
  }) hasReadHistory!: boolean;

  valid: boolean = false;

  rules: Rules = new Rules();

  role: any = '';

  seniorityRemunerations: Wages[] = [];

  loading = false;

  isAdministrator: boolean = false;

  money: object = {
    decimal: ',',
    thousands: '.',
    precision: 2,
    prefix: 'R$ ',
    masked: false,
    max: 9999.99,
    min: 0.01,
  };

  showCreateHistoryModal: boolean = false;

  loadingSave: boolean = false;

  history: WageHistory[] = [];

  showHistory: boolean = false;

  loadingHistory: boolean = false;

  wageValidation(maxWage: string, mediumWage: string, minWage: string) {
    const errors: string[] = [];
    if (!this.biggerOrEqualZero(mediumWage)) {
      errors.push('Valor Inválido');
    }
    if (this.formatValue(`${mediumWage}`) < this.formatValue(`${minWage}`)
      || this.formatValue(`${maxWage}`) < this.formatValue(`${mediumWage}`)
      || this.formatValue(`${maxWage}`) < this.formatValue(`${minWage}`)) {
      errors.push('Valor inválido')
    }
    return errors;
  }

  verifyAdministratorAuthority() {
    AuthorityStore.isAdministrator()
      .then((resp: any) => this.isAdministrator = resp);
  }

  validateDuplicatedSeniorities(): boolean {
    let valid: boolean = true;
    const existentSeniorities: string[] = [];
    this.seniorityRemunerations.forEach((el: Wages) => {
      if (!existentSeniorities.includes(el.seniority.name)) {
        existentSeniorities.push(el.seniority.name);
      } else {
        valid = false;
      }
    })
    return valid;
  }

  formatValue(value: string) {
    return Number(FilterUtils.formatValue(`${value}`));
  }

  biggerOrEqualZero(value: string): boolean {
    return Number(this.formatValue(value)) >= 0.0
  }

  close(refresh: boolean) {
    this.clear();
    this.showCreateHistoryModal = false;
    this.$emit('close', refresh);
  }

  created() {
    this.selectWage()
    this.verifyAdministratorAuthority();
  }

  selectWage() {
    const foundRole = this.roles.find(el => el.name === this.selectedRoleName)
    if (foundRole) {
      this.role = foundRole.id;
    }
    this.getAllWagesByRole();
  }

  clear() {
    this.role = '';
    this.seniorityRemunerations = [];
    this.clearHistory();
  }

  clearHistory() {
    this.history = [];
    this.showHistory = false;
  }

  getAllWagesByRole() {
    if (this.role) {
      this.clearHistory();
      this.loading = true;
      WageService.findWagesByRole(this.role)
        .then((resp: any) => {
          this.seniorityRemunerations = resp;
          this.loading = false;
        });
    }
  }

  save(createHistory: boolean) {
    if (!this.validateDuplicatedSeniorities()) {
      VbSnackbarService.showError('Existem senioridades duplicadas');
      return;
    }
    this.loadingSave = true;
    const request = new PersistSeniorityRemuneration(
      {
        createHistory,
        seniorityRemunerations: WageRequest.ofList(this.seniorityRemunerations),
      },
    );
    WageService.save(this.role, request)
      .then(() => {
        VbSnackbarService.showSuccess('Remunerações salvas com sucesso!');
        this.close(true);
      })
      .catch((err: any) => VbSnackbarService.handleHttpError(err))
      .finally(() => this.loadingSave = false);
  }

  get optionsHandled(): ModalOptions {
    return {
      title: 'Cadastro de Remuneração',
      titleSize: '20px',
      description: 'IMPORTANTE: O formato citado abaixo é referente à uma sugestão de composição da Remuneração Total do colaborador (divisão entre CLT e Cesta de Benefícios). O sistema sempre irá considerar a Remuneração Total (CLT + Cesta de Benefícios + Benefícios fixos) para realizar análises referentes à adequação salarial. A flexibilidade e autonomia de cada Gestor + Liderado (de forma que faça sentido para colaborador e DB1) em decidirem a divisão entre CLT e Cesta de Benefícios, sempre será um diferencial do Grupo DB1, no tocante à remuneração. Entenda Benefícios fixos como: Ajuda de custos Home office, Plano de saúde do colaborador e Gympass.',
      showOk: this.isUpdateTableSalary,
      disabledOk: !this.valid || !this.role,
      labelOk: 'Salvar',
      okColor: 'tabBlue',
      showCancel: true,
      labelCancel: 'Cancelar',
      width: 800,
    };
  }

  addSeniorityRemunerations() {
    this.seniorityRemunerations.push(Wages.of({}));
  }

  removeSeniorityRemunerations(item: Wages) {
    this.seniorityRemunerations = this.seniorityRemunerations
      .filter(el => el.seniority.id!! !== item.seniority.id!!)
  }

  async getHistory() {
    if (!this.showHistory && this.history.length > 0) {
      this.showHistory = true;
      return;
    }
    this.loadingHistory = true;
    WageService.getHistory(this.role).then((response) => {
      this.history = response;
    }).catch(error => VbSnackbarService.handleHttpError(error))
      .finally(() => {
        this.loadingHistory = false;
        if (this.history.length === 0) {
          VbSnackbarService.showError('Nenhum histórico encontrado!');
          return;
        }
        this.showHistory = true;
      });
  }
}
