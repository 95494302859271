


















import { Vue, Component, Prop } from 'vue-property-decorator';

const CURRENCY_REGEX = /^[0-9][0-9]?[0-9]?(\.[0-9][0-9]?[0-9])*,[0-9]{2}$/;

@Component
export default class CurrencyField extends Vue {
  @Prop({
    type: Number,
    required: true,
  }) value!: number;

  @Prop({
    type: Boolean,
    default: false,
  }) readonly!: boolean;

  @Prop({
    type: Boolean,
    default: false,
  }) disabled!: boolean;

  @Prop({
    type: String,
    default: null,
  }) placeholder!: string;

  @Prop({
    type: Array,
    default: () => ([]),
  }) rules!: Function[];

  @Prop({
    type: Number,
    default: null,
  }) maxLength!: number;

  @Prop({
    type: Boolean,
    default: false,
    required: false,
  }) error!: boolean;

  @Prop({
    type: Array,
    default: () => [],
    required: false,
  }) errorMessages!: string[];

  @Prop() currencySymbol!: string;

  @Prop({
    type: String,
    default: '',
  }) hintMessage!: string;

  @Prop({
    type: Boolean,
    default: false,
  }) persistentHint!: boolean;

  get allRules(): Function[] {
    return [
      ...this.rules,
      value => CURRENCY_REGEX.test(value) || 'Apenas valor monetário',
    ];
  }

  input(value: any): void {
    const onlyNumericValueRegex = /^[0-9.,]+$/;
    if (!onlyNumericValueRegex.test(value)) {
      this.$emit('input', this.value);
      return;
    }
    if (!value.includes(',') && value.length === 1) {
      const stringValue = `0.0${value}`;
      this.$emit('input', parseFloat(stringValue));
      return;
    }
    const valueWithoutSeparators: string = value.replaceAll('.', '').replaceAll(',', '');
    const valueWithDecimalSeparator: string = `${valueWithoutSeparators.substring(0, valueWithoutSeparators.length - 2)}.${valueWithoutSeparators.substring(valueWithoutSeparators.length - 2)}`;
    const numberValue = parseFloat(valueWithDecimalSeparator);
    this.$emit('input', numberValue);
  }

  get formattedValue(): string {
    if (Number.isNaN(this.value)) { return '' }
    return this.value.toLocaleString('pt-br', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
  }
}
