

































import { Vue, Component, Prop } from 'vue-property-decorator';
import SelectionComponent from '@/shared/fields/selection.componet.vue';
import LabelSlot from '@/shared/slots/label-slot.component.vue';
import CareerService from '@/domain/employees/service/career.service';
import UpdateAtribute from '@/domain/employees/entity/update-atribute.entity';

@Component({
  components: {
    SelectionComponent,
    LabelSlot,
  },
})
export default class BusinessUnitFilter extends Vue {
  @Prop({
    type: String,
    default: 'Unidade de Negócio',
  }) label!: string;

  @Prop({
    type: String,
    default: 'Digite o nome...',
  }) placeholder!: string;

  @Prop({
    type: String,
    default: 'Nenhuma unidade foi encontrada...',
  }) noDataText!: string;

  businessUnitSelected: string[] = [];

  foundBusinessUnits: UpdateAtribute[] = [];

  loading: boolean = false;

  selected() {
    this.$emit('selected', this.businessUnitSelected);
  }

  created() {
    this.findBusinessUnit();
  }

  findBusinessUnit() {
    this.loading = true;
    CareerService.getBusinessUnit()
      .then(data => this.foundBusinessUnits = data)
      .finally(() => this.loading = false);
  }
}
