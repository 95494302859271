export default class DefaultIndicator {
  id: string;

  indicator: string;

  description: string;

  category: string;

  categoryText: string;

  weight: string;

  goalValue: number;

  goalAchievementType: string;

  goalAchievementTypeText: string;

  goalMeasurementUnit: string;

  goalMeasurementUnitText: string;

  goalMaskType: string;

  goalMaskTypeText: string;

  utility: string;

  utilityText: string;

  goalMaxValue: number;

  linkGmo: string;

  linkBpg: string;

  isSelectable: boolean = true;

  active: boolean;

  constructor(obj: any = {}) {
    this.id = obj.id || '';
    this.indicator = obj.indicator || '';
    this.description = obj.description || '';
    this.category = obj.category || null;
    this.categoryText = obj.categoryText || '-';
    this.weight = obj.weight || null;
    this.goalValue = obj.goalValue || null;
    this.goalAchievementType = obj.goalAchievementType || null;
    this.goalAchievementTypeText = obj.goalAchievementTypeText || '-';
    this.goalMeasurementUnit = obj.goalMeasurementUnit || null;
    this.goalMeasurementUnitText = obj.goalMeasurementUnitText || '-';
    this.goalMaskType = obj.goalMaskType || null;
    this.goalMaskTypeText = obj.goalMaskTypeText || '-';
    this.utility = obj.utility || null;
    this.utilityText = obj.utilityText || '-';
    this.goalMaxValue = obj.goalMaxValue || null;
    this.linkGmo = obj.linkGmo || '';
    this.linkBpg = obj.linkBpg || '';
    this.isSelectable = obj.isSelectable == null || obj.isSelectable === undefined
      ? true
      : obj.isSelectable;
    this.active = obj.active;
  }

  public static fromIndicator(indicator: any): DefaultIndicator {
    return new DefaultIndicator(indicator);
  }
}
