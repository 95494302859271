import DateFormat from '@/shared/utils/date-format';

export default class DrdFiltered {
  assignmentId: string;

  assignmentDescription: String;

  roleName: string;

  ecosystemDescription: string;

  drdCreatedDate: string;

  drdModifiedDate: string;

  constructor(obj: any = {}) {
    this.assignmentId = obj.assignmentId || '';
    this.assignmentDescription = obj.assignmentDescription || '';
    this.roleName = obj.roleName || '';
    this.ecosystemDescription = obj.ecosystemDescription || '';
    this.drdCreatedDate = DateFormat.dateTimeFormated(obj.drdCreatedDate) || '-';
    this.drdModifiedDate = DateFormat.dateTimeFormated(obj.drdModifiedDate) || '-';
  }
}
