import CreateKeyAreaSkillLevelDTO from './create-key-area-skill-level.entity';

export default class CreateKeyAreaSkillDTO {
  id: string;

  order: number = 1;

  levels: CreateKeyAreaSkillLevelDTO[];

  active: boolean;

  constructor(obj: any = {}) {
    this.id = obj.id || '';
    this.order = obj.order;
    this.levels = obj.levels ? obj.levels.map(it => new CreateKeyAreaSkillLevelDTO(it)) : null;
    this.active = obj.active;
  }
}
