


































































































import { Vue, Component } from 'vue-property-decorator';
import formatNumberToExtension from '@/shared/utils/formatNumberForExtension';

@Component
export default class BankAccountDocumentTemplate extends Vue {
  employeeName: string = '';

  address: string = '';

  addressNumber: string = '';

  district: string = '';

  city: string = '';

  salary: number = 0;

  salaryExtension: string = '';

  businessUnitName: string = '';

  businessUnitFullName: string = '';

  cnpj: string = '';

  stateAcronym: string = '';

  logo: string = '';

  get currentDate() {
    return new Date().toLocaleDateString()
  }

  created() {
    this.employeeName = this.$route.query.employeeName.toString();
    this.salary = Number(this.$route.query.salary.toString());
    this.businessUnitName = this.$route.query.businessUnitName.toString();
    this.address = this.$route.query.address.toString();
    this.addressNumber = this.$route.query.addressNumber.toString();
    this.district = this.$route.query.district.toString();
    this.city = this.$route.query.city.toString();
    this.businessUnitFullName = this.$route.query.businessUnitFullName.toString();
    this.cnpj = this.$route.query.cnpj.toString();
    this.stateAcronym = this.$route.query.stateAcronym.toString();
    this.salaryExtension = formatNumberToExtension(this.salary);
    this.logo = this.$route.query.logo.toString();
    setTimeout(() => {
      window.print();
    }, 2000);
  }

  getLogoImage() {
    // eslint-disable-next-line import/no-dynamic-require, global-require
    return require(`@/assets/images/${this.logo}`);
  }

  get showCurrencyExtension() {
    return this.salaryExtension.includes('Reais') ? '' : 'Reais';
  }
}
