










































import { Vue, Component } from 'vue-property-decorator';
import AuthenticationService from '@/domain/security/authentication.service';
import EmployeeService from '@/domain/employees/service/employee.service';
import EmployeeDTO from '@/domain/employees/entity/employee-dto';

@Component
export default class MenuHeaderUser extends Vue {
  photo: any = '';

  hasImage = false;

  photoLoading = true;

  private employeeId: string | null = null;

  private username: string | null = null;

  created() {
    EmployeeService.getLoggedUserEmployee().then((employee: EmployeeDTO) => {
      this.username = employee.username;
      this.employeeId = employee.id;
      this.photoLoading = true;
      this.getPhoto(employee.id);
    });
  }

  getPhoto(employeeId: string) {
    EmployeeService.getEmployeePhoto(employeeId)
      .then((response: any) => {
        this.photo = `data:image/png;charset=utf-8;base64,${response}`;
        localStorage.setItem('photo', this.photo);
        this.hasImage = true;
      })
      .finally(() => {
        this.photoLoading = false;
      });
  }

  get name() {
    return AuthenticationService.userLoggedIn().name;
  }

  logout() {
    AuthenticationService.logout();
  }

  private goToMyEmployeePage(): void {
    if (!this.username) return;
    this.$router.push({
      path: `/profile/${this.username}`,
      replace: true,
    });
  }
}
