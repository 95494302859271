import isNullOrUndefined from '@/shared/utils/check-null-or-undefined';
import OrderBy from '../employees/entity/order-by.entity';

export default class SelectiveProcessFilter {
  mentalGrade: string;

  mentalGradePeriod: string[] = [];

  cultureGrade: string;

  cultureGradePeriod: string[] = [];

  candidatesEmails: string;

  candidateName: string = '';

  jobs: string = '';

  orderBy!: OrderBy;

  jobStatus: string[];

  constructor(obj: any = {}) {
    this.mentalGrade = obj.mentalGrade || '';
    this.mentalGradePeriod = obj.mentalGradePeriod || [];
    this.cultureGrade = obj.cultureGrade || '';
    this.cultureGradePeriod = obj.cultureGradePeriod || [];
    this.candidatesEmails = obj.candidatesEmails || '';
    this.jobs = obj.jobs || '';
    this.jobStatus = obj.jobStatus || [];
  }

  get hasJobs() {
    return !isNullOrUndefined(this.jobs) && this.jobs !== '';
  }

  get hasCandidatesEmails() {
    return !isNullOrUndefined(this.candidatesEmails) && this.candidatesEmails !== '';
  }

  get hasCandidateName() {
    return !isNullOrUndefined(this.candidateName) && this.candidateName !== '';
  }

  get hasMentalGradePeriod() {
    return this.mentalGradePeriod.length > 0;
  }

  get hasCultureGradePeriod() {
    return this.cultureGradePeriod.length > 0;
  }

  get hasMentalGrade() {
    return this.mentalGrade !== undefined
      && this.mentalGrade !== null
      && this.mentalGrade !== '';
  }

  get hasCultureGrade() {
    return this.cultureGrade !== undefined
      && this.cultureGrade !== null
      && this.cultureGrade !== '';
  }

  get hasJobStatus() {
    return this.jobStatus.length > 0;
  }
}
