import DateFormat from '../../../shared/utils/date-format';

export default class VacationPayslip {
  public date: string;

  public file: any;

  constructor(vacation: any) {
    this.file = vacation.file;
    this.date = vacation.date || '';
  }

  public static of(vacation: any) {
    return new VacationPayslip(vacation || {});
  }

  convertDateIsoFormat(date: string): any {
    date = DateFormat.dateTimeFormated(date);
    return date;
  }

  equals(vacation: VacationPayslip): boolean {
    return (
      this.file === vacation.file
        && this.date === vacation.date
    );
  }
}
