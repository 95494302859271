export default class Formation {
  id: string;

  description: string;

  hiring: string;

  junior: string;

  plain: string;

  senior: string;

  active: boolean;

  objective: boolean;

  constructor(obj: any = {}) {
    this.id = obj.id || null;
    this.description = obj.description || '';
    this.hiring = obj.hiring || 'RECOMMENDED';
    this.junior = obj.junior || 'RECOMMENDED';
    this.plain = obj.plain || 'RECOMMENDED';
    this.senior = obj.senior || 'RECOMMENDED';
    this.active = obj.active === undefined ? true : obj.active;
    this.objective = obj.objective || '';
  }
}
