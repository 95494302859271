import DateFormat from '@/shared/utils/date-format';
import axios from 'axios';
import { VbSnackbarService } from 'bag-of-holding-library';
import SalaryReadjustmentParametrization from '../entity/salary-readjustment-parametrization.entity';

export default class SalaryReadjustmentParametrizationService {
  public static findParametrizations(
    page: number, size: number, sort: string,
  ): Promise<any> {
    return axios.get('/api/salary-readjustment-parametrizations', {
      params: {
        page: page - 1,
        size,
        sort,
      },
    });
  }

  public static createParametrization(
    parametrization: SalaryReadjustmentParametrization,
  ): Promise<any> {
    return axios.post('/api/salary-readjustment-parametrizations', {
      inpc: parametrization.inpc,
      minimalReadjustment: parametrization.minimalReadjustment,
      validSince: DateFormat.monthYearFromFormated(parametrization.validSince),
    });
  }

  public static findParametrizationById(id: string): Promise<any> {
    return axios.get(`/api/salary-readjustment-parametrizations/${id}`)
      .then(response => response.data);
  }

  public static updateParametrization(
    parametrization: SalaryReadjustmentParametrization,
  ): Promise<any> {
    return axios.put('/api/salary-readjustment-parametrizations', {
      id: parametrization.id,
      inpc: parametrization.inpc,
      minimalReadjustment: parametrization.minimalReadjustment,
      validSince: DateFormat.monthYearFromFormated(parametrization.validSince),
    });
  }

  public static findCurrentParametrization(): Promise<any> {
    return axios.get('/api/salary-readjustment-parametrizations/current');
  }

  public static deleteParametrization(parametrizationId: string): Promise<void> {
    return axios.delete(`/api/salary-readjustment-parametrizations/${parametrizationId}`);
  }
}
