import AssignmentRole from './assignment.role.entity';
import Ecosystem from './ecosystem.entity';

export default class Assignment {
  id: string;

  description: string;

  ecosystem: Ecosystem;

  inactive: boolean;

  role: AssignmentRole;

  objective: string;

  techLead: boolean;

  constructor(obj: any = {}) {
    this.id = obj.id || '';
    this.description = obj.description || '';
    this.ecosystem = obj.ecosystem || new Ecosystem();
    this.inactive = obj.inactive === null || obj.inactive === undefined ? false : obj.inactive;
    this.role = obj.role ? new AssignmentRole(obj.role) : new AssignmentRole();
    this.objective = obj.objective || '';
    this.techLead = obj.techLead || false;
  }

  update(assignment: Assignment): void {
    this.id = assignment.id;
    this.description = assignment.description;
    this.ecosystem = assignment.ecosystem;
    this.inactive = assignment.inactive;
    this.role = assignment.role;
    this.objective = assignment.objective;
    this.techLead = assignment.techLead;
  }
}
