





















































import KeyArea from '@/domain/drd-2.0/entity/key-area.entity';
import { Vue, Component, Prop } from 'vue-property-decorator';
import CreateKeyAreaDTO from '@/domain/drd-2.0/entity/create-key-area.entity';
import KeyAreaService from '@/domain/drd-2.0/service/key-area.service';
import { VbSnackbarService } from 'bag-of-holding-library';
import Loading from '@/shared/loading/loading.component.vue';
import KeyAreaFormComponent from '../key-area/components/form.component.vue';

@Component({
  components: {
    KeyAreaFormComponent,
    Loading,
  },
})
export default class KeyAreaDrawerComponent extends Vue {
  @Prop({
    default: () => false,
  }) keyAreaDrawer!: boolean;

  @Prop({
    type: String,
    default: () => '',
  }) keyAreaId!: string;

  @Prop({
    default: () => false,
  }) isUpdate!: boolean;

  loadingSave: boolean = false;

  keyArea: KeyArea = new KeyArea();

  loading: boolean = false;

  created() {
    if (!this.isUpdate) {
      this.keyArea = new KeyArea();
      return;
    }
    this.getKeyArea();
  }

  getKeyArea() {
    this.loading = true;
    KeyAreaService.getById(this.keyAreaId)
      .then(({ data }) => this.keyArea = new KeyArea(data))
      .catch(({ response }) => {
        VbSnackbarService.showError(response.data.messages.toString());
      }).finally(() => {
        this.loading = false;
      })
  }

  closeDrawer() {
    this.$emit('closeDrawer', false)
  }

  async save() {
    const { formComponent } = this.$refs as any;
    const { form } = formComponent.$refs;
    if (!form.validate()) return;
    const data: CreateKeyAreaDTO = new CreateKeyAreaDTO({ ...this.keyArea, type: 'CUSTOM' });
    this.loadingSave = true;
    if (this.isUpdate) {
      this.update(this.keyArea.id, data);
      return;
    }
    await this.create(data);
  }

  async create(data: CreateKeyAreaDTO) {
    KeyAreaService.create(data).then((keyArea) => {
      VbSnackbarService.showSuccess('Area Chave criada com sucesso!');
      this.$emit('saved', keyArea);
    }).catch((error) => {
      VbSnackbarService.showError(error.response.data.messages.toString());
    }).finally(() => this.loadingSave = false);
  }

  async update(keyAreaId: string, data: CreateKeyAreaDTO) {
    KeyAreaService.update(keyAreaId, data).then((keyArea) => {
      VbSnackbarService.showSuccess('Area Chave atualizada com sucesso!');
      this.$emit('saved', keyArea);
    }).catch((error) => {
      VbSnackbarService.showError(error.response.data.messages.toString());
    }).finally(() => this.loadingSave = false);
  }
}
