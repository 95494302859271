































import { Vue, Prop, Component } from 'vue-property-decorator';
import { VbSnackbarService } from 'bag-of-holding-library';
import ReadOnlyTextField from '@/shared/fields/readonly-text-field.component.vue';
import DateFormat from '@/shared/utils/date-format';
import EmployeeHistory from '@/domain/schedule/employee/employee-history.entity';
import EmployeeService from '@/domain/schedule/employee/employee.service';
import AuthorityStore from '@/domain/security/authority.store';
import EmployeeHistoryList from './employee-history-list.component.vue';

@Component({
  components: {
    'text-field': ReadOnlyTextField,
    'history-list': EmployeeHistoryList,
  },
})
export default class EmployeeHistoryComponent extends Vue {
  @Prop({
    required: true,
  })
  private employeeId!: string;

  private employeeHistory: EmployeeHistory = new EmployeeHistory();

  private hasActiveOrInactiveActivityHistory: boolean = false;

  created() {
    this.fetchEmployeeHistory(this.employeeId);
    this.getHistoryAuthority();
  }

  private fetchEmployeeHistory(id: string): void {
    EmployeeService.getEmployeeHistory(this.employeeId)
      .then((response: any) => this.employeeHistory = response)
      .catch(error => VbSnackbarService.handleHttpError(error));
  }

  private get fullDateFormat(): string {
    return DateFormat.fullDate(this.employeeHistory.admissionDateValue);
  }

  private get firstNameLastName(): string {
    return this.employeeHistory.firstNameLastName;
  }

  private getHistoryAuthority() {
    AuthorityStore.hasActiveOrInactiveActivityHistory()
      .then(response => this.hasActiveOrInactiveActivityHistory = response);
  }
}
