export default class TechLead {
  id: string;

  name: string;

  constructor(obj: any = {}) {
    this.id = obj.id || null;
    this.name = obj.name || '';
  }
}
