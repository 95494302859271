



















































import { Vue, Component } from 'vue-property-decorator';
import EmployeeService from '@/domain/employees/service/employee.service';
import Graph from './home-graph.component.vue';
import EmployeeAmount from '../../domain/employees/entity/employee-amount.entity';
import ColorPicker from './color-picker';
import GraphCaptionList from './home-graph-caption-list.component.vue';

@Component({
  components: {
    Graph,
    GraphCaptionList,
  },
})
export default class Home extends Vue {
  private employeeAmount: EmployeeAmount = new EmployeeAmount();

  private displayText: boolean = false;

  private getGraphInfo(): void {
    EmployeeService.getEmployeeAmount()
      .then((response: any) => this.employeeAmount = response);
  }

  private get sections(): object[] {
    return this.employeeAmount.businessUnitEmployeeAmountList.map((unit: any) => ({
      label: unit.name,
      value: unit.amount,
      color: ColorPicker.generateRandomColor(),
    }));
  }

  created() {
    this.getGraphInfo();
  }
}
