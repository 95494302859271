import { PlanState } from '@/domain/pdi/entity/plan-state';
import Priority from './priority.entity';

export default class ActionPlan {
  planId: string;

  number: number;

  what: string;

  why: string;

  how: string;

  measurementMethod: string;

  dueDate: string;

  state: PlanState;

  priority: Priority;

  planType: string;

  createdByEmail: string;

  activityId: string;

  closedDate: string;

  assessmentItemId: string;

  assessmentAssignmentName: string;

  assessmentLevelName: string;

  assessmentDate: string;

  constructor(obj: any = {}) {
    this.planId = obj.planId || '';
    this.number = obj.number || 1;
    this.what = obj.what || '';
    this.why = obj.why || '';
    this.how = obj.how || '';
    this.measurementMethod = obj.measurementMethod || '';
    this.dueDate = obj.dueDate || '';
    this.state = obj.state || PlanState.DRAFT;
    this.priority = obj.priority || {};
    this.planType = obj.planType || '';
    this.createdByEmail = obj.createdByEmail || '';
    this.activityId = obj.activityId || '';
    this.closedDate = obj.closedDate || '';
    this.assessmentItemId = obj.assessmentItemId || '';
    this.assessmentAssignmentName = obj.assessmentAssignmentName || '';
    this.assessmentLevelName = obj.assessmentLevelName || '';
    this.assessmentDate = obj.assessmentDate || '';
  }

  public static ofActivity(activityId: string) {
    const newPlan = new ActionPlan();
    newPlan.activityId = activityId;
    return newPlan;
  }

  public isDone(): boolean {
    return this.state === PlanState.DONE;
  }

  public isDraft(): boolean {
    return this.state === PlanState.DRAFT;
  }
}
