


































import { Vue, Component } from 'vue-property-decorator';
import { VbSnackbarService } from 'bag-of-holding-library';
import Agreement from '@/domain/employees/entity/agreement.entity';
import Score from '@/domain/employees/entity/score.entity';
import ScoreService from '@/domain/employees/service/score.service';
import CardTitle from '@/shared/card/card-title.component.vue';
import ScoreDescriptionReadComponent from './score-description-read.component.vue';
import ScoreMessage from '../score-message.component.vue';

@Component({
  components: {
    ScoreDescriptionReadComponent, ScoreMessage, CardTitle,
  },
})
export default class ScoreDescriptionRead extends Vue {
  score?: Score;

  loading: boolean = true;

  totalAgreement?: Agreement;

  verifyConclusion(): void {
    ScoreService.verifyConclusion(this.$route.params.id).then(() => {
      VbSnackbarService.showSuccess('Contagem concluída!');
      this.$router.push({ path: '/score' });
    }).catch(VbSnackbarService.handleHttpError);
  }

  setTotalAgreement(): void {
    if (!this.score) return;
    this.totalAgreement = this.score.agreementList
      .find((agreement: Agreement) => agreement.description === 'Total');
    this.score.agreementList = this.score.agreementList
      .filter((agreement: Agreement) => agreement.description !== 'Total');
  }

  goToList(): void {
    this.$router.push({ path: '/score' });
  }

  created(): void {
    this.$destroy();
    ScoreService.getScoreById(this.$route.params.id).then((score: Score) => {
      this.score = score;
      this.setTotalAgreement();
    }).catch(VbSnackbarService.handleHttpError)
      .finally(() => {
        this.loading = false;
      });
  }
}
