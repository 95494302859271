import { VbSnackbarService } from 'bag-of-holding-library';
import Indicators from '@/domain/drd/entity/indicators.entity';
import SkillTypes from '@/views/drd-2.0/skill/skill-types.json';
// eslint-disable-next-line import/no-cycle
import KeyAreaService from '../service/key-area.service';
import KeyAreaSkillLevel from './key-area-skill-level.entity';
import KeyAreaSkill from './key-area-skill.entity';
import Level from './level.entity';

export default class KeyArea {
  id: string;

  name: string;

  details: string;

  skills: KeyAreaSkill[];

  levels: KeyAreaSkillLevel[];

  order: number;

  active: boolean;

  selected: boolean = true;

  indicators: Indicators[];

  isSelectable: boolean;

  type: string = 'DEFAULT'

  constructor(obj: any = {}) {
    this.id = obj.id || '';
    this.name = obj.name || '';
    this.details = obj.details || '';
    this.levels = obj.levels ? obj.levels.map(it => ({
      ...it,
      isDeletable: false,
    })) : [];
    this.skills = obj.skills ? obj.skills.map(it => new KeyAreaSkill(it)) : [];
    this.order = obj.order;
    this.active = obj.active;
    this.isSelectable = obj.isSelectable;
    this.indicators = obj.indicators
      ? obj.indicators.map(it => new Indicators({ ...it, saved: true })) : [];
    this.type = obj.type;
  }

  get skillsActive(): KeyAreaSkill[] {
    return this.skills.filter(it => it.active);
  }

  filterSkillsByType(type: string) {
    if (this.skills.length === 0) return [];
    return this.skills.filter(it => it.skill.type === type);
  }

  addSkill({ skill, levels }) {
    const greaterOrder = this.getGreaterOrder(skill.type);
    const isBoolean = this.checkTypeIsBoolean(skill.type);
    const newSkill = new KeyAreaSkill(
      {
        skill,
        levels: this.transformLevelsToKeyArea(levels, isBoolean),
        active: true,
        order: greaterOrder + 1,
      },
    );
    this.skills.push(newSkill);
  }

  removeSkill(skillId: string) {
    const deletingSkillIndex = this.skills
      .findIndex(it => it.skill.id === skillId);
    this.skills.splice(deletingSkillIndex, 1);
  }

  addLevel({ level, skillFiltered }) {
    this.skills
      .filter(it => it.skill.id === skillFiltered.skill.id)[0].levels.push(
        {
          id: level.id,
          expected: 0,
          name: level.name,
          active: true,
          isDeletable: true,
        },
      );
  }

  removeLevel({ skill, level }) {
    const skillFiltered = this.skills
      .filter(it => it.skill.id === skill.skill.id)[0];
    const levelIndex = skillFiltered.levels.findIndex(it => it.id = level.id);
    skillFiltered.levels.splice(levelIndex, 1);
  }

  private getGreaterOrder(type: string): number {
    const skillsByType = this.skills.filter(it => it.skill.type === type);
    if (skillsByType.length === 0) return 0;
    return skillsByType
      .reduce(
        (max, skill) => (skill.order > max && skill.skill.type === type
          ? skill.order : max),
        skillsByType[0].order,
      );
  }

  private checkTypeIsBoolean(type: string) {
    const typeFiltered = SkillTypes.filter(it => it.value === type);
    return typeFiltered.length > 0 ? typeFiltered[0].isBoolean : false;
  }

  private transformLevelsToKeyArea(levels: Level[], isBoolean: boolean) {
    return levels.map((level: Level) => ({
      id: level.id,
      expected: isBoolean ? 0 : 2,
      name: level.name,
      active: true,
      isDeletable: true,
    }))
  }

  deleteIndicator(index: number) {
    const keyareaIndicatorDelete = this.indicators[index];
    if (keyareaIndicatorDelete.saved) {
      keyareaIndicatorDelete.loadingDelete = true;
      KeyAreaService.deleteIndicator(this.id, keyareaIndicatorDelete.id)
        .then(() => {
          this.indicators.splice(index, 1);
          VbSnackbarService.showSuccess('Indicador deletado com sucesso!');
        }).catch(VbSnackbarService.handleHttpError)
        .finally(() => keyareaIndicatorDelete.loadingDelete = false);
      return;
    }
    this.indicators.splice(index, 1);
  }
}
