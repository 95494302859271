import EnumObject from '@/domain/enum-object.entity';
import DateHandler from '@/shared/utils/date-format';

interface TalentLevel {
  name: string | null;
  color: string | null;
}

export default class FilteredEmployeeActivity {
  id: string;

  type: EnumObject;

  status: EnumObject;

  executionDate: string;

  happinessLevel: EnumObject;

  observation: string;

  expectedDate: string;

  name: string

  hasActivity: boolean;

  talentLevel: TalentLevel;

  surveyAnswerId: string;

  constructor(obj: any) {
    this.id = obj.id || '';
    this.type = obj.type
      ? EnumObject.of(obj.type)
      : EnumObject.of({});
    this.executionDate = obj.executionDate || '';
    this.observation = obj.observation || '';
    this.expectedDate = obj.expectedDate || '';
    this.status = obj.status ? EnumObject.of(obj.status) : EnumObject.of({})
    this.happinessLevel = obj.happinessLevel
      ? EnumObject.of(obj.happinessLevel)
      : EnumObject.of({});
    this.name = obj.name || '';
    this.hasActivity = obj.hasActivity;
    this.talentLevel = obj.talentLevel;
    this.surveyAnswerId = obj.surveyAnswerId;
  }

  public static of(obj: any): FilteredEmployeeActivity {
    return new FilteredEmployeeActivity(obj);
  }

  public static ofList(obj: any[]): FilteredEmployeeActivity[] {
    return obj.map(this.of);
  }

  get executionDateOrElse(): string {
    return this.executionDate
      ? DateHandler.timestampToLocal(this.executionDate.split('T')[0])
      : '-'
  }

  get happinessOrElse(): string {
    if (this.happinessLevel.name) return this.happinessLevel.description;
    if (this.surveyAnswerId) return 'Avaliação enviada';
    return 'Aguardando avaliação';
  }

  get statusOptions(): Object {
    return {
      description: this.status.description,
      color: this.getStatusColor(),
    }
  }

  getStatusColor() {
    if (this.status.name === 'APPROVED') {
      return 'rgba(11,138,207,1)'
    }
    if (this.status.name === 'LATE') {
      return 'rgba(236,151,23,1)'
    }
    return 'rgba(243,19,19,1)';
  }

  get needEvaluate(): boolean {
    return this.happinessLevel.name === '' && this.isApproved;
  }

  get evaluateColor(): string {
    return this.isApproved ? '#239A9A' : '#7E8282'
  }

  get observationColor(): string {
    return this.needEvaluate ? '#7E8282' : '#239A9A'
  }

  get isApproved(): boolean {
    return this.status.name === 'APPROVED'
  }

  get observationIsEnabled(): boolean {
    return this.isApproved && this.happinessLevel.name !== ''
  }
}
