






import { Vue, Component } from 'vue-property-decorator';

@Component({ name: 'EmployeesView', components: { } })
export default class Employees extends Vue {
}
