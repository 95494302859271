import {
  VuexModule, Module, Mutation, Action,
} from 'vuex-module-decorators';
import store from '@/store';
import Authority from './authority.entity';
import AuthorityService from './authority.service';

@Module({ dynamic: true, name: 'authoritytStore', store })
export default class AuthorityStore extends VuexModule {
  private static authority = new Authority([]);

  private static fetched = false;

  static fetchedAuthority(): Promise<Authority> {
    if (!this.fetched) {
      return AuthorityService
        .authorities()
        .then(response => this.handleFetchAuthorities(response));
    }
    return Promise.resolve(this.authority);
  }

  static hasAuthority(authorities: string[]): Promise<boolean> {
    return this.fetchedAuthority()
      .then(authority => authority.hasAuthorities(authorities));
  }

  static hasCreateDrd(): Promise<boolean> {
    return this.fetchedAuthority()
      .then(authority => authority.hasCreateDrd());
  }

  static hasUpdateDrd(): Promise<boolean> {
    return this.fetchedAuthority()
      .then(authority => authority.hasUpdateDrd());
  }

  static hasAccessGodfatherExport(): Promise<boolean> {
    return this.fetchedAuthority().then(authority => authority.hasAccessGodfatherExport())
  }

  static hasAccessPersonalDataExport(): Promise<boolean> {
    return this.fetchedAuthority().then(authority => authority.hasAccessPersonalDataExport())
  }

  static hasAccessAttendance() {
    return this.fetchedAuthority().then(authority => authority.hasAccessAttendance())
  }

  static hasAccessResignations() {
    return this.fetchedAuthority().then(authority => authority.hasAccessResignations())
  }

  static hasAccessAssignments() {
    return this.fetchedAuthority().then(authority => authority.hasAccessAssignments())
  }

  static hasAccessRoles() {
    return this.fetchedAuthority().then(authority => authority.hasAccessRoles())
  }

  static hasAccessSecurity() {
    return this.fetchedAuthority().then(authority => authority.hasAccessSecurity());
  }

  static hasAccessTalentLevel() {
    return this.fetchedAuthority().then(authority => authority.hasAccessTalentLevel());
  }

  static hasAccessMessageDaisy() {
    return this.fetchedAuthority().then(authority => authority.hasAccessMessageDaisy());
  }

  static hasAccessImportVacationPayslip() {
    return this.fetchedAuthority().then(authority => authority.hasAccessImportVacationPayslip());
  }

  static isAdministrator(): Promise<boolean> {
    return this.fetchedAuthority()
      .then(authority => authority.isAdministrator());
  }

  static isManager(): Promise<boolean> {
    return this.fetchedAuthority()
      .then(authority => authority.isManager());
  }

  static isEmployeeAdministrator(): Promise<boolean> {
    return this.fetchedAuthority()
      .then(authority => authority.isEmployeeAdministrator());
  }

  static isFacilitiesAdministrator(): Promise<boolean> {
    return this.fetchedAuthority()
      .then(authority => authority.isFacilitiesAdministrator());
  }

  static isTrainingAdministrator(): Promise<boolean> {
    return this.fetchedAuthority()
      .then(authority => authority.isTrainingAdministrator());
  }

  static isBusinnesPartner(): Promise<boolean> {
    return this.fetchedAuthority()
      .then(authority => authority.isBusinnesPartner());
  }

  static isPersonalDepartment(): Promise<boolean> {
    return this.fetchedAuthority()
      .then(authority => authority.isPersonalDepartment());
  }

  static isSkillManagement(): Promise<boolean> {
    return this.fetchedAuthority()
      .then(authority => authority.isSkillManagement());
  }

  static isSkillMapCreate(): Promise<boolean> {
    return this.fetchedAuthority()
      .then(authority => authority.isSkillMapCreate());
  }

  static isSkillMapUpdate(): Promise<boolean> {
    return this.fetchedAuthority()
      .then(authority => authority.isSkillMapUpdate());
  }

  static isSkillMapDelete(): Promise<boolean> {
    return this.fetchedAuthority()
      .then(authority => authority.isSkillMapDelete());
  }

  static isSkillMap(): Promise<boolean> {
    return this.fetchedAuthority()
      .then(authority => authority.isSkillMap());
  }

  static isSkillMapTab(): Promise<boolean> {
    return this.fetchedAuthority()
      .then(authority => authority.isSkillMapTab());
  }

  static hasAccessRiskAttendance(): Promise<boolean> {
    return this.fetchedAuthority()
      .then(authority => authority.hasAccessRiskAttendance());
  }

  static hasCreateRiskAttendance(): Promise<boolean> {
    return this.fetchedAuthority()
      .then(authority => authority.hasCreateRiskAttendance());
  }

  static hasUpdateRiskAttendance(): Promise<boolean> {
    return this.fetchedAuthority()
      .then(authority => authority.hasUpdateRiskAttendance());
  }

  static hasDeleteRiskAttendance(): Promise<boolean> {
    return this.fetchedAuthority()
      .then(authority => authority.hasDeleteRiskAttendance());
  }

  static hasAccessWages(): Promise<boolean> {
    return this.fetchedAuthority().then(authority => authority.hasAccessWages());
  }

  static hasAccessWagesTable(): Promise<boolean> {
    return this.fetchedAuthority().then(authority => authority.isReadTableSalary());
  }

  static hasAccessVacationAdministrator(): Promise<boolean> {
    return this.fetchedAuthority().then(authority => authority.isReadVacation());
  }

  static hasAccessVacationBalance(): Promise<boolean> {
    return this.fetchedAuthority().then(authority => authority.isVacationBalance());
  }

  static hasAccessSalaryReadjustmentParametrization(): Promise<boolean> {
    return this.fetchedAuthority()
      .then(authority => authority.isReadSalaryReadjustmentParametrization());
  }

  static hasCreateSalaryReadjustmentParametrization(): Promise<boolean> {
    return this.fetchedAuthority()
      .then(authority => authority.hasCreateSalaryReadjustmentParametrization());
  }

  static hasReadEmployeeRemunaration(): Promise<boolean> {
    return this.fetchedAuthority().then(authority => authority.hasReadEmployeeRemunaration());
  }

  static hasCreateOrUpdateEmployeeScore(): Promise<boolean> {
    return this.fetchedAuthority().then(authority => authority.hasCreateOrUpdateEmployeeScore());
  }

  static hasReadAgreement(): Promise<boolean> {
    return this.fetchedAuthority().then(authority => authority.hasReadAgreement())
  }

  static hasUpdateSalaryReadjustmentParametrization(): Promise<boolean> {
    return this.fetchedAuthority()
      .then(authority => authority.hasUpdateSalaryReadjustmentParamentrization());
  }

  static hasActiveOrInactiveActivityHistory(): Promise<boolean> {
    return this.fetchedAuthority().then(authority => authority.hasActiveOrInactiveActivityHistory())
  }

  static hasAccessCreateInitialScore(): Promise<boolean> {
    return this.fetchedAuthority().then(authority => authority.hasAccessCreateInitialScore())
  }

  static hasAccessSelectiveProcess(): Promise<boolean> {
    return this.fetchedAuthority().then(authority => authority.hasAccessReadSelectiveProcess())
  }

  static hasAccessReadIndicatorDefaultManagement(): Promise<boolean> {
    return this.fetchedAuthority()
      .then(authority => authority.hasAccessReadIndicatorDefaultManagement())
  }

  static hasCreateIndicatorDefault(): Promise<boolean> {
    return this.fetchedAuthority()
      .then(authority => authority.hasCreateIndicatorDefault())
  }

  static hasUpdateIndicatorDefault(): Promise<boolean> {
    return this.fetchedAuthority()
      .then(authority => authority.hasUpdateIndicatorDefault())
  }

  static hasInactiveIndicatorDefault(): Promise<boolean> {
    return this.fetchedAuthority()
      .then(authority => authority.hasInactiveIndicatorDefault())
  }

  static hasReadAssessmentResult(): Promise<boolean> {
    return this.fetchedAuthority()
      .then(authority => authority.hasReadAssessmentResult())
  }

  static hasReadSkillKeyArea(): Promise<boolean> {
    return this.fetchedAuthority()
      .then(authority => authority.hasReadhasReadSkillKeyArea())
  }

  private static handleFetchAuthorities(response: string[]) {
    this.fetched = true;
    this.authority = new Authority(response);
    return this.authority;
  }
}
