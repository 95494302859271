





























import {
  Vue, Prop, Component,
} from 'vue-property-decorator';
import { VbSnackbarService } from 'bag-of-holding-library';
import ActivityUpdate from '@/domain/schedule/activitie/activity-update.entity';
import LabelSlot from '@/shared/slots/label-slot.component.vue';
import ActivityDetailed from '@/domain/schedule/activitie/activity-detailed.entity';
import ActivityService from '@/domain/schedule/activitie/activity.service';
import ManageActivityListItem from './manage-activity-list-item.component.vue';

@Component({
  components: {
    'label-slot': LabelSlot,
    'manage-activity-card': ManageActivityListItem,
  },
})
export default class ManageActivityList extends Vue {
  @Prop({
    required: true,
  })
  private listOfActivities!: ActivityDetailed[];

  @Prop({
    type: Boolean,
    default: false,
  })
  private isUpdateActivity!: boolean;

  @Prop({
    type: Boolean,
    default: false,
  })
  private isUploadFiles!: boolean;

  @Prop({
    type: Boolean,
    default: false,
  })
  private isDeleteActivity!: boolean;

  @Prop({
    type: Boolean,
    default: false,
  })
  private isUpdateActivityStatus!: boolean;

  @Prop({
    type: Boolean,
    default: false,
  })
  private isReadActivity!: boolean;

  @Prop({ type: Boolean, default: false })
  isReadEmployeeTalentLevel!: boolean;

  @Prop({
    type: String,
    required: true,
  }) private employeeId!: string;

  @Prop({
    type: String,
    required: true,
  }) private username!: string;

  deleteLoading: boolean = false;

  approveActivity(value: ActivityUpdate) {
    this.excecuteAction(ActivityService.approveActivity(value.id, value.reason));
  }

  rejectActivity(value: ActivityUpdate) {
    this.excecuteAction(ActivityService.rejectActivity(value.id, value.reason));
  }

  deleteActivity(value: ActivityUpdate) {
    this.deleteLoading = true;
    this.excecuteAction(ActivityService.deleteActivity(value.id, value.reason));
  }

  refresh() {
    this.$emit('refresh')
  }

  private excecuteAction(promise: Promise<void>) {
    promise
      .then(() => {
        this.$emit('updateList');
        VbSnackbarService.showSuccess('Informações atualizadas com sucesso')
      })
      .catch((error: any) => {
        VbSnackbarService.handleHttpError(error);
        this.deleteLoading = false;
      });
  }
}
