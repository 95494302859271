
























































































































































































































































































































































































































































































































































































































































































































































































import {
  Vue, Component, Prop,
} from 'vue-property-decorator';
import { VbSnackbarService } from 'bag-of-holding-library';
import moment from 'moment';
import CountryFlag from 'vue-country-flag';
import LabelSlot from '@/shared/slots/label-slot.component.vue';
import CardTitle from '@/shared/card/card-title.component.vue';
import DateHandler from '@/shared/utils/date-format';
import TabButton from '@/shared/tab-button.component.vue';
import ModalWarning from '@/shared/modal/modal-warning.component.vue';
import Rules from '@/shared/rules/rules';
import ModalComponent from '@/shared/modal/modal.component.vue';
import DatePickerField from '@/shared/date-picker/date-picker-field.component.vue';
import Country from '@/domain/countries/entity/country.entity';
import State from '@/domain/states/entity/state.entity';
import City from '@/domain/states/entity/city.entity';
import CountryService from '@/domain/countries/service/country.service';
import StateService from '@/domain/states/service/states.service';
import PersonalData from '@/domain/employees/entity/personal-data.entity';
import ChildrenData from '@/domain/employees/entity/children-data.entity';
import PersonalDataRequest from '@/domain/employees/entity/personal-data-request.entity';
import PersonalDataService from '@/domain/employees/service/personal-data.service';
import AuthenticationService from '@/domain/security/authentication.service';
import CorreiosService from '@/domain/correios-address/service/correios.service';
import CorreiosAddress from '@/domain/correios-address/entity/correios-address.entity';
import AuthorityStore from '@/domain/security/authority.store';
import StringHandler from '@/shared/utils/string-handler';
import EmployeeService from '@/domain/employees/service/employee.service';
import CountrySelect from '@/shared/country-select.component.vue';
import GenderTypes from './gender-types.json';
import MaritalStatus from './marital-status.json';
import ChildrenOption from './hasChildren-option.json';
import DatesCelebrate from './dates-celebrate.json';
import FoodAllergies from './food-allergies.json';
import ShirtSize from './shirt-size.json';
import Religions from './religions.json';

const BRAZIL_ACRONYM = 'BRA';

@Component({
  components: {
    TabButton,
    ModalWarning,
    CardTitle,
    LabelSlot,
    DatePickerField,
    ModalComponent,
    CountryFlag,
    CountrySelect,
  },
  filters: {
    formatDate(value: string) {
      return DateHandler.timestampToLocal(value.split('T')[0]);
    },
  },
})

export default class PersonalDataComponent extends Vue {
  @Prop() show!: boolean;

  @Prop() employeeId!: string;

  @Prop() availableEmails!: string[];

  personalData: PersonalData = PersonalData.of({});

  personalDataAux: PersonalData = PersonalData.of({});

  childrenData: ChildrenData = ChildrenData.of({});

  correiosAddress: CorreiosAddress = CorreiosAddress.of({});

  state: State = State.of({});

  valid: boolean = false;

  rules: Rules = new Rules();

  role: any = '';

  loadingSave = false;

  countries: Country[] = [];

  states: State[] = [];

  cities: City[] = [];

  loadingCountries: boolean = true;

  file: any = '';

  isAdministrator: boolean = false;

  isCurrentUserOrAdministrator: boolean = false;

  genderTypes = GenderTypes;

  maritalStatusTypes = MaritalStatus;

  religions = Religions;

  hasChildrenTypes = ChildrenOption;

  childGenderTypes = GenderTypes;

  yesOrNot = ChildrenOption;

  datesCelebrate = DatesCelebrate;

  foodAllergies = FoodAllergies;

  shirtSize = ShirtSize;

  refs: any = this.$refs

  subordinateList: string[] = [];

  showForeignAdress: boolean = false;

  showBrazilAdress: boolean = false;

  foreignAdressCharacterLimit: number = 64;

  loadingData: boolean = false;

  verifyFields(value: string[]) {
    if (value.includes('ALL') && value.includes('NONE')) {
      this.personalData.commemorativeDates.splice(0, 1)
    } else if (value.length > 1 && value.includes('NONE')) {
      this.personalData.commemorativeDates.splice(0, 1)
    }

    if (value.length === 10 && value.includes('ALL')) {
      const index = value.indexOf('ALL')
      value.splice(index, 1)
    }

    if (value.includes('ALL')) {
      this.datesCelebrate
        .filter(date => date.value !== 'NONE' && !this.personalData.commemorativeDates.includes(date.value))
        .map(el => this.personalData.commemorativeDates.push(el.value))
    }

    if (value.includes('NONE')) {
      this.personalData.commemorativeDates = []
      this.personalData.commemorativeDates.push('NONE')
    }
  }

  verifyFieldsFood(value: string[]) {
    if (value.length > 1 && value.indexOf('NO_RESTRICTIONS') === 0) {
      value.splice(0, 1)
    }
    if (value.includes('NO_RESTRICTIONS')) {
      this.personalData.foodAllergies = []
      this.personalData.foodAllergies.push('NO_RESTRICTIONS')
    }
  }

  getUserEmail() {
    if (this.personalData.companyEmail === '') {
      this.personalData.companyEmail = this.availableEmails[0].toLowerCase();
    }
  }

  get saveText() {
    return this.personalData.equals(this.personalDataAux)
      ? 'Confirmar' : 'Salvar';
  }

  checkIsSubordinate() {
    EmployeeService.getAllSubordinates().then(response => this.subordinateList = response);
    return this.subordinateList.includes(this.employeeId);
  }

  checkIsCurrentUserOrAdministrator() {
    const availableEmails = this.availableEmails.map(el => el.toLowerCase());
    const username = AuthenticationService.userLoggedIn().userName.toLowerCase()
    this.isCurrentUserOrAdministrator = availableEmails.includes(username)
    || this.isAdministrator
    || this.checkIsSubordinate();
  }

  created() {
    this.getInitialData();
    this.initIsAdministrator();
  }

  checkMissingData() {
    if (this.personalData.verifyMissingData()) {
      this.refs.form.validate();
    }
  }

  getAddressByPostalCode() {
    CorreiosService.getAddress(this.personalData.postalCode)
      .then((resp: any) => {
        this.correiosAddress = CorreiosAddress.of(resp)
        this.personalData.district = this.correiosAddress.bairro;
        this.personalData.address = this.correiosAddress.logradouro;
        this.findViaCepState()
      });
  }

  findViaCepState() {
    const viaCepState = this.states
      .find((state: State) => state.acronym === this.correiosAddress.uf);
    if (viaCepState) {
      if (!this.personalData.state.id || this.personalData.state.id !== viaCepState.id) {
        this.personalData.state.id = viaCepState.id;
        this.getCities();
      }
    }
  }

  async getInitialData() {
    this.loadingData = true;
    await this.findCountries();
    await this.getPersonalData();
    this.findStates();
    this.loadingData = false;
    if (this.countryIsBrazil()) {
      this.getCities();
      this.showViewBrazilAdress();
      return;
    }
    this.showViewForeignAdress();
  }

  async findCountries() {
    this.loadingCountries = true;
    await CountryService.findCountries().then((countries: Country[]) => {
      const brazilIndex = countries.findIndex(it => it.acronym === BRAZIL_ACRONYM);
      if (brazilIndex !== -1) {
        const brazil: Country[] = countries.splice(brazilIndex, 1);
        countries.unshift(brazil[0]);
      }
      this.countries = countries;
    }).catch(VbSnackbarService.handleHttpError)
      .finally(() => { this.loadingCountries = false; });
  }

  findStates() {
    StateService.findStates()
      .then((resp: any) => {
        this.states = resp;
      });
  }

  async getPersonalData() {
    await PersonalDataService.findData(this.employeeId)
      .then((resp: any) => {
        this.personalData = PersonalData.of(resp);
        this.personalDataAux = PersonalData.of(resp);
      }).finally(() => {
        this.getUserEmail();
        this.checkMissingData();
        this.checkIsCurrentUserOrAdministrator();
      });
  }

  getCities() {
    StateService.findCities(this.personalData.state.id)
      .then((resp: any) => {
        this.cities = resp;
        if (this.correiosAddress.localidade) {
          const viaCepCity = this.cities
            .find((city: City) => StringHandler
              .equalsNormalized(city.name, this.correiosAddress.localidade));
          if (viaCepCity) {
            this.personalData.city.id = viaCepCity.id;
          }
        }
      });
  }

  checkStateOption() {
    this.personalData.verifyStateChange();
  }

  addNewChild() {
    this.personalData.addNewChild();
  }

  removeChild(item: ChildrenData) {
    const index = this.personalData.children.indexOf(item);
    this.personalData.children.splice(index, 1);
  }

  checkHasChildren() {
    this.personalData.verifyHasChildrenOption();
  }

  save() {
    if (!this.refs.form.validate()) {
      VbSnackbarService.showError('Alguns campos obrigatórios não foram preenchidos')
      return
    }
    const request = PersonalDataRequest.of(this.personalData);
    this.loadingSave = true;
    PersonalDataService.saveData(this.employeeId, request)
      .then((resp: any) => {
        VbSnackbarService.showSuccess('Dados pessoais salvos com sucesso!');
      })
      .catch((err: any) => VbSnackbarService.handleHttpError(err))
      .finally(() => {
        this.loadingSave = false;
        this.personalDataAux = PersonalData.of(this.personalData);
      });
  }

  initIsAdministrator() {
    AuthorityStore.isAdministrator()
      .then((response: any) => this.isAdministrator = response);
  }

  setUsesIfood() {
    this.personalData.usesIfood = this.personalData.usesIfoodAux === 'Sim'
  }

  setConsumeAlcoholicDrink() {
    this.personalData.consumeAlcoholicDrink = this.personalData.consumeAlcoholicDrinkAux === 'Sim'
  }

  formatLastModifiedDate(yearMonth: string): string {
    if (!yearMonth) return 'Não há.';
    return moment(yearMonth, 'YYYY-MM-DD').format('DD/MM/YYYY');
  }

  updateCountry(selectedCountry: Country): void {
    this.personalData.country = selectedCountry.id;
    this.checkCountrySeleted();
  }

  checkCountrySeleted() {
    if (this.countryIsBrazil()) {
      this.personalData.foreignCity = '';
      this.personalData.foreignState = '';
      this.showViewBrazilAdress();
      this.personalData.state = this.personalDataAux.state;
      this.personalData.city = this.personalDataAux.city;
      return;
    }
    this.personalData.state = new State({});
    this.personalData.city = new City({});
    this.showViewForeignAdress();
  }

  clearCountry() {
    this.personalData.country = '';
    this.showBrazilAdress = false;
    this.showForeignAdress = false;
  }

  clearReligion() {
    this.personalData.religion = null;
    this.personalData.specifiedReligion = '';
  }

  showViewBrazilAdress() {
    this.showBrazilAdress = true;
    this.showForeignAdress = false;
  }

  showViewForeignAdress() {
    this.showBrazilAdress = false;
    this.showForeignAdress = true;
  }

  countryIsBrazil(): boolean {
    if (this.personalData.country === null || this.personalData.country === '') {
      return true;
    }
    const selectedCountry = this.filterCountryById(this.personalData.country);
    return selectedCountry.acronym === BRAZIL_ACRONYM;
  }

  filterCountryById(countryId: string) {
    return this.countries.filter(it => it.id === countryId)[0];
  }

  updateReligion(value: string) {
    if (value !== 'OTHER') {
      this.personalData.specifiedReligion = '';
    }
    this.personalData.religion = value;
  }

  checkIsClearable() {
    return this.isCurrentUserOrAdministrator;
  }

  checkItensDisable() {
    return !this.isCurrentUserOrAdministrator;
  }
}
