import OperationModality from '@/domain/operation-modality/entity/operation-modality.entity';

export default class Workplace {
  id: string;

  name: string;

  active: boolean;

  defaultOperationModality: OperationModality | null;

  constructor(obj: any = {}) {
    this.id = obj.id || '';
    this.name = obj.name || '';
    this.active = obj.active || '';
    this.defaultOperationModality = obj.defaultOperationModality
      ? new OperationModality(obj.defaultOperationModality) : null;
  }
}
