


















import { Mixins, Component } from 'vue-property-decorator';
import NavbarButtons from '@/nav-buttons.json';
import MenuItemOrGroup from './menu-item-or-group.component.vue';
import MenuAuthorityMixin from './menu-authority.mixin';

@Component({ components: { MenuItemOrGroup } })
export default class MainMenu extends Mixins(MenuAuthorityMixin) {
  private navButtons = NavbarButtons;

  private selectedMenuKey: number = -1;

  private get filteredButtons() {
    const buttons: any[] = []

    this.navButtons.filter(this.hasAuthority).forEach((button) => {
      const have = buttons.some(it => it.text === button.text)
      if (!have) {
        buttons.push(button)
      }
    })
    return buttons;
  }

  private onSelect(index: number): void {
    if (this.selectedMenuKey === index) {
      this.selectedMenuKey = -1;
      return;
    }
    this.selectedMenuKey = index;
  }
}
