





























import { Vue, Component, Prop } from 'vue-property-decorator'
import CountryFlag from 'vue-country-flag';
import LabelSlot from '@/shared/slots/label-slot.component.vue';

@Component({
  components: {
    CountryFlag,
    LabelSlot,
  },
})
export default class SelectLanguageComponent extends Vue {
  language: string = 'PT_BR';

  @Prop({
    default: [
      {
        text: 'Brasil - pt-br',
        acronym: 'PT_BR',
        acronymFlag: 'BRA',
      },
      {
        text: 'Espanhol - es',
        acronym: 'ES',
        acronymFlag: 'ES',
      },
    ],
  }) languages!: any[];

  created() {
    if (this.$route.query.lang) {
      this.language = this.$route.query.lang.toString();
    }
    this.languageSelected();
  }

  languageSelected() {
    const actualQuery = this.$route.query;
    this.$router.replace({ query: { ...actualQuery, lang: this.language } });
    this.$emit('languageSelected', this.language);
  }
}
