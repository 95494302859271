import DateFormat from '../../../shared/utils/date-format';

export default class ChildrenData {
  id: string;

  name: string;

  birthDate: string;

  genderType: any;

  constructor(obj: any) {
    this.id = obj.id || '';
    this.name = obj.name || '';
    this.birthDate = obj.birthDate || '';
    this.genderType = obj.genderType || '';
  }

  public static of(obj: any): ChildrenData {
    return new ChildrenData(obj || {})
  }

  public static ofList(obj: any[]): ChildrenData[] {
    return obj.map(this.of);
  }

  equals(obj: ChildrenData): boolean {
    return this.name === obj.name
    && this.birthDate === obj.birthDate
    && this.genderType === obj.genderType
  }
}
