import AssessmentResult from './emp-assessment-result.entity';

export default class EmployeeAssessmentResult {
  employeeName: string;

  employeeUsername: string;

  results: AssessmentResult[];

  constructor(obj: any = {}) {
    this.employeeName = obj.employeeName || '';
    this.employeeUsername = obj.employeeUsername || '';
    this.results = obj.results ? obj.results.map(it => new AssessmentResult(it)) : [];
  }

  filterResultBySimulation(isSimulation: boolean) {
    return this.results.filter(it => it.simulation === isSimulation);
  }
}
