import RouteCheckerTo from '@/views/route-checker';
import RiskAttendance from './risk-attendance.view.vue';
import Table from './table/risk-attendance-table.view.vue';
import FormRiskAttendance from './form-risk-attendance/form-risk-attendance.view.vue';
import AddRiskAttendance from './table/add-risk-attendance/add-risk-attendance.view.vue';

export default [
  {
    path: '/risk-attendance',
    component: RiskAttendance,
    children: [
      {
        path: '/',
        name: 'Risco',
        component: Table,
        beforeEnter: RouteCheckerTo.hasAccessRiskAttendance,
      },
      {
        path: '/risk-attendance/:id',
        name: 'Atualizar Risco',
        component: FormRiskAttendance,
        beforeEnter: RouteCheckerTo.hasAccessRiskAttendance,
      },
      {
        path: '/new-risk-attendance/',
        name: 'Novo Risco',
        component: AddRiskAttendance,
        beforeEnter: RouteCheckerTo.hasAccessRiskAttendance,
      },
    ],
  },
];
