export default class FilterAssignment {
  description: string = '';

  containsDRD: boolean | null = null;

  constructor(obj: any = {}) {
    this.description = obj.description || '';
    this.containsDRD = obj.containsDRD || false;
  }
}
